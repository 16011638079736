import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ErrorText } from "../";

const StyledSelect = styled.div`
    font-size: ${props => props.theme.text.heading4.sizeRem};
    padding: 0.5em 0px;
    width: 100%;
    border: ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.gray.hex)} 1px solid;
    color: ${props => props.theme.colours.flat.gray.hex};
    box-sizing: border-box;
    height: ${props => props.height || "auto"};
    max-height: ${props => props.maxHeight || "auto"};
    overflow-y: scroll;
`;

const StyledOption = styled.div`
    user-select: none;
    padding: 0.2em 0.5em;

    ${props =>
        props.selected
            ? `
        {
            background-color: ${props.theme.colours.flat.lightBlue.hex};
            color: white;
        }`
            : ""}
    ${props => props.disabled && `pointer-events: none;`};
`;

const Select = ({ options, onChange, values, errors, disabled, ...rest }) => {
    if (!Array.isArray(options) || options.length === 0) {
        return null;
    }

    const [selected, setSelectedState] = useState(values || []);

    function setSelection(selection) {
        setSelectedState(selection);

        if (typeof onChange == "function") {
            onChange(selection);
        }
    }

    function toggleSelection(value) {
        const currentSelection = [...selected];
        if (currentSelection.includes(value)) {
            setSelection(currentSelection.filter(val => val !== value));
        } else {
            setSelection([...currentSelection, value]);
        }
    }

    return (
        <>
            <StyledSelect errors={errors} {...rest}>
                {options.map(({ label, value }) => (
                    <StyledOption
                        selected={selected.includes(value)}
                        key={label}
                        onClick={() => toggleSelection(value)}
                        disabled={disabled}
                    >
                        {label}
                    </StyledOption>
                ))}
            </StyledSelect>
            {errors && <ErrorText>{errors}</ErrorText>}
        </>
    );
};

Select.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    values: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    errors: PropTypes.string,
    height: PropTypes.string,
    maxHeight: PropTypes.string,
    disabled: PropTypes.bool
};

export default React.memo(Select);
