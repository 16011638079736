import React from "react";
import PropTypes from "prop-types";
import FlexCardWithIcon, { InnerContainer } from "./FlexCardWithIcon";
import { withTheme } from "styled-components";
import Heading from "../Heading";

const Todo = ({ theme, title, tall, children, ...rest }) => (
    <FlexCardWithIcon
        {...rest}
        iconType={theme.icons.types.todo}
        iconBackground={theme.colours.flat.lightBlue.hex}
        reverse={true}
        tall={tall}
    >
        <InnerContainer>
            <div>
                {title && (
                    <Heading renderAs="h3" mb={"0.5em"}>
                        {title}
                    </Heading>
                )}
                {children}
            </div>
        </InnerContainer>
    </FlexCardWithIcon>
);

Todo.propTypes = {
    theme: PropTypes.any,
    title: PropTypes.string,
    tall: PropTypes.bool,
    children: PropTypes.any.isRequired
};

export default withTheme(Todo);
