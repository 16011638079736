import { multiply, sum } from "ramda";
import { normalBmi, normalSys, optimalBmi, optimalSys } from "./constants";

export default ({
    coeff10yrAge,
    getCoeff10yrSys,
    coeff10yrSmoke,
    coeff10yrBmi,
    coeff10yrDiab,
    coeff10yrSumProdConst,
    coeff10yrSysNoMed,
    haConst
}) => ({ age, systolic, bmi, isSmoker, hasDiabetes, isTakingBpMeds }) => {
    const ageLn = Math.log(age);
    const sysLn = Math.log(systolic);
    const bmiLn = Math.log(bmi);

    const calcRisk = tenYrSum => 1 - Math.pow(coeff10yrSumProdConst, Math.exp(tenYrSum - haConst));

    const tenYrSum = sum([
        multiply(ageLn, coeff10yrAge),
        multiply(sysLn, getCoeff10yrSys(isTakingBpMeds)),
        multiply(isSmoker, coeff10yrSmoke),
        multiply(bmiLn, coeff10yrBmi),
        multiply(hasDiabetes, coeff10yrDiab)
    ]);

    const tenYrSumOptimal = sum([
        multiply(ageLn, coeff10yrAge),
        multiply(Math.log(optimalSys), coeff10yrSysNoMed),
        multiply(Math.log(optimalBmi), coeff10yrBmi)
    ]);

    const tenYrSumNormal = sum([
        multiply(ageLn, coeff10yrAge),
        multiply(Math.log(normalSys), coeff10yrSysNoMed),
        multiply(Math.log(normalBmi), coeff10yrBmi)
    ]);

    return {
        tenYearCvdRisk: calcRisk(tenYrSum),
        tenYearCvdRiskNormal: calcRisk(tenYrSumNormal),
        tenYearCvdRiskOptimal: calcRisk(tenYrSumOptimal)
    };
};
