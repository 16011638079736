import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Next } from "../../Survey/Buttons";
import WYSIWYGContainer from "../../WYSIWYG/Container";
import { PrimaryButton } from "../../Survey/HRARestyle/Components/Buttons";

const StyledContainer = styled.div`
    text-align: center;
    align-self: center;
`;

class Finished extends Component {
    static displayName = "Finished";
    static propTypes = {
        isHra: PropTypes.bool,
        schema: PropTypes.object.isRequired,
        userJourneyPage: PropTypes.object.isRequired,
        onComplete: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.handleDone = this.handleDone.bind(this);
    }

    handleDone() {
        this.props.userJourneyPage.setResolved(true);
        this.props.onComplete();
    }

    render() {
        const { isHra } = this.props;
        return (
            <StyledContainer>
                <WYSIWYGContainer
                    dangerouslySetInnerHTML={{
                        __html: this.props.schema.message
                    }}
                />
                {isHra ? (
                    <PrimaryButton onClick={this.handleDone}>Next</PrimaryButton>
                ) : (
                    <Next onClick={this.handleDone}>Next &gt;</Next>
                )}
            </StyledContainer>
        );
    }
}

export default Finished;
