import BaseEndPoint from "../BaseEndPoint";

export default class SurveyResponse extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path = "") {
        if (path) {
            path = "/" + path;
        }
        return super.getUrlPath("/survey-responses" + path);
    }

    /**
     * Calls the create SurveyResponse API endpoint
     *
     * @param {Response} responseUtility
     * @return {Promise<Response>}
     */
    create(responseUtility) {
        responseUtility.incrementSequence();
        responseUtility.setIsFinished();

        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "POST",
            data: JSON.stringify(responseUtility.toJSON())
        };

        return this._send(this.getUrlPath(), settings, true);
    }

    /**
     * Calls the retrieve SurveyResponse API endpoint
     *
     * @param {String} uuid
     * @return {Promise<Response>}
     */
    retrieve(uuid) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(this.getUrlPath(uuid), settings, true);
    }
}
