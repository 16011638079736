import React from "react";
import PropTypes from "prop-types";
import FlexCardWithIcon, { InnerContainer } from "./FlexCardWithIcon";
import { withTheme } from "styled-components";
import Heading from "../Heading";

const Information = props => {
    const { title, theme, children, icon, color, ...rest } = props;
    const iconType = icon || theme.icons.types.recommendation;

    return (
        <FlexCardWithIcon {...rest} iconType={iconType} reverse={false} iconBackground={color}>
            <InnerContainer>
                <div>
                    {title && (
                        <Heading renderAs="h4" mb={"0.5em"}>
                            {title}
                        </Heading>
                    )}
                    {children}
                </div>
            </InnerContainer>
        </FlexCardWithIcon>
    );
};

Information.propTypes = {
    theme: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.any,
    color: PropTypes.string,
    link: PropTypes.string,
    children: PropTypes.any.isRequired
};

export default withTheme(Information);
