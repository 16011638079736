import { convertSisuTypographyToGrommet, convertSisuTypographyToRebass } from "../utils";
import baseTheme from "./baseTheme";
import stationTheme from "./stationTheme";

// Populate Grommet UI config based on SiSU config
baseTheme.grommet = {
    heading: convertSisuTypographyToGrommet(baseTheme.text)
};
stationTheme.grommet = {
    heading: convertSisuTypographyToGrommet(stationTheme.text)
};
stationTheme.grommet.global = baseTheme.global = {
    font: {
        size: "16px",
        height: "normal"
    }
};

// Populate Rebass Theme typography
baseTheme.fontSizes = convertSisuTypographyToRebass(baseTheme.text);
stationTheme.fontSizes = convertSisuTypographyToRebass(stationTheme.text);

export { baseTheme, stationTheme };
