import React from "react";
import PropTypes from "prop-types";
import "./core.css";
import ReactTable from "react-table";
import { Box } from "rebass";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "./fixed.css";

/**
 * Reuseable Table component
 * @param {Object} props
 * @param {Array<Object<string, any>>} props.data - Data input for the table
 * @param {Array<DataTableColumn>} props.columns - Columns definition of the table
 * @param {boolean} props.loading - Show loading status of the table
 */
const DataTable = ({ data, columns, loading, boxProps, ...rest }) => {
    return (
        <Box data-testid="sisu-table" width="100%" {...boxProps}>
            <ReactTable data={data} columns={columns} loading={loading} {...rest} />
        </Box>
    );
};

DataTable.propTypes = {
    ...ReactTable.propTypes,
    boxProps: PropTypes.shape(Box.propTypes)
};

DataTable.defaultProps = {
    data: [],
    columns: [],
    showPagination: false,
    sortable: false,
    resizable: false
};

export default DataTable;

const FixedColumnTable = withFixedColumns(DataTable);
export { FixedColumnTable };

/**
 * DataTableColumn type
 * @typedef {Object} DataTableColumn
 *
 * Custom Renderers
 * @property {(JSX.Element|string|function)} Cell
 * @property {(JSX.Element|string|function)} Header
 * @property {(JSX.Element|string|function)} Footer
 * @property {(JSX.Element|string|function)} Aggregated
 * @property {(JSX.Element|string|function)} Pivot
 * @property {(JSX.Element|string|function)} PivotValue
 * @property {(JSX.Element|string|function)} Expander
 * @property {(JSX.Element|function)} Filter
 *
 * All Columns
 * @property {boolean} sortable
 * @property {boolean} resizable
 * @property {boolean} filterable
 * @property {boolean} show
 * @property {number} minWidth
 * @property {number} minResizeWidth
 *
 * Cells only
 * @property {string} className
 * @property {Object<string, any>} style
 * @property {function} getProps
 *
 * Pivot only
 * @property {function} aggregate - Pivot only function
 *
 * Header only
 * @property {string} headerClassName
 * @property {Object<string, any>} headerStyle
 * @property {function} getHeaderProps
 *
 * Footer only
 * @property {string} footerClassName
 * @property {Object<string, any>} footerStyle
 * @property {function} getFooterProps
 * @property {function} filterMethod
 * @property {boolean} filterAll
 * @property {function} sortMethod
 */
