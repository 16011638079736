import React, { useRef, useImperativeHandle, forwardRef } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { StyledInput } from "../../../../Forms";
import { Flex, Box } from "rebass";

const Label = styled.div`
    font-size: ${props => props.theme.text.subText.sizeRem};
    margin-bottom: 0.9em;
`;

const Input = styled(StyledInput)`
    padding: 0;
    margin-top: 1em;
    margin-bottom: 0;
`;

const InputField = forwardRef(
    (
        {
            id,
            label = "",
            value = "",
            placeholder = "",
            hasErrors = false,
            autoFocus = false,
            onFocus = () => {},
            onChange = () => {},
            regex = null,
            onMatch = () => {},
            proportion = 1
        },
        ref
    ) => {
        const inputRef = useRef(null);
        useImperativeHandle(ref, () => inputRef.current);

        const handleFocus = () => {
            onFocus(inputRef);
        };

        const handleChange = e => {
            const value = e.target.value;
            onChange(value);
            if (regex && value && value.match(regex)) {
                onMatch(value, inputRef);
            }
        };

        return (
            <Box flex={`${proportion} 1 0`}>
                <Flex flexDirection="column" alignItems="flex-start" justifyContent={"flex-start"}>
                    <Input
                        hasErrors={hasErrors}
                        placeholder={placeholder}
                        id={id}
                        type={"text"}
                        autoFocus={autoFocus}
                        autoComplete={"off"}
                        ref={inputRef}
                        onFocus={e => handleFocus(e)}
                        onInput={e => handleChange(e)}
                        onChange={() => {}}
                        centreText={false}
                        value={value}
                        data-testid={`input-${id}`}
                    />
                    <Label>{label}</Label>
                </Flex>
            </Box>
        );
    }
);

InputField.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    hasErrors: PropTypes.bool,
    autoFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    regex: PropTypes.object,
    onMatch: PropTypes.func,
    proportion: PropTypes.number
};

export default withTheme(InputField);
