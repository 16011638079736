import buildSisuGuideline, { RiskCode, RiskName, GuidelineCode, GuidelineName, formatNumber } from "./SiSU";
import calcBMR from "./KatchMcArdle";
import calcTDEE from "./TotalDailyEnergyExpenditure";
import { UnitConverter } from "../../core";

/**
 * Calculates BMR, DEU, and the associated ratings in kJ and Calories
 * @param {number} weightKg
 * @param {number} bodyFatPercentage
 * @param {string} physicalActivityLevel The answer to the Physical Activity Questions, as listed in UserJourneyMap.Answers.PhysicalActivityLevel
 * @param {boolean} isLactating
 * @param {string} locale
 * @returns {{ratingKj: (*|(*&{ranges: {high: {riskLevel, rangeInfo: string, label: string, key: string}, low: {riskLevel, rangeInfo: string, label: string, key: string}, moderate: {riskLevel, rangeInfo: string, label: string, key: string}}})), bmrCals: *, dailyEnergyUseCals: *, ratingCals: (*|(*&{ranges: {high: {riskLevel, rangeInfo: string, label: string, key: string}, low: {riskLevel, rangeInfo: string, label: string, key: string}, moderate: {riskLevel, rangeInfo: string, label: string, key: string}}})), bmrKj: *, dailyEnergyUseKj: *}}
 */
const calculate = ({
    weightKg,
    bodyFatPercentage,
    physicalActivityLevel,
    isLactating = false,
    locale = "en-AU"
} = {}) => {
    const bmrKj = calcBMR(weightKg, bodyFatPercentage);
    const dailyEnergyUseKj = calcTDEE(bmrKj, physicalActivityLevel, isLactating);
    const ratingKj = buildSisuGuideline({ dailyEnergyUseKj, bmrKj, isLactating, inCalories: false, locale });
    const ratingCals = buildSisuGuideline({ dailyEnergyUseKj, bmrKj, isLactating, inCalories: true, locale });

    return {
        bmrKj,
        dailyEnergyUseKj,
        ratingKj,
        bmrCals: UnitConverter.kJToKcal(bmrKj),
        dailyEnergyUseCals: UnitConverter.kJToKcal(dailyEnergyUseKj),
        ratingCals,
        dailyEnergyUseRating: ratingKj.key
    };
};

export {
    calculate,
    buildSisuGuideline,
    formatNumber,
    calcBMR,
    calcTDEE,
    RiskCode,
    RiskName,
    GuidelineCode,
    GuidelineName
};
