import { userJourneyMap as codes } from "@sisuwellness/utilities";

export default class Recommendations {
    /**
     * Returns a recommendation based on the given ad slot code, criteria, and replacements.
     * @param {string} adSlotCode - The code for the ad slot.
     * @param {object} criteria - The criteria for the recommendation.
     * @returns {string} - The recommendation.
     */
    static getRecommendationFromAdSlotCode(adSlotCode, criteria) {
        const recommendation = {
            BMI_RESULTS_SCREEN_RECOMMENDATION: Recommendations._getBmiRecommendation(criteria),
            BP_RESULTS_SCREEN_RECOMMENDATION: Recommendations._getBpRecommendation(criteria),
            HR_RESULTS_SCREEN_RECOMMENDATION: Recommendations._getHrRecommendation(criteria),
            BF_RESULTS_SCREEN_RECOMMENDATION: Recommendations._getBodyFatRecommendation(criteria),
            DIABETES_RESULTS_SCREEN_RECOMMENDATION: Recommendations._getDiabetesRiskRecommendation(criteria),
            HRA_SMOKING: Recommendations._getSmokingRecommendation(criteria),
            HRA_WAIST_CIRCUMFERENCE: Recommendations._getWaistCircumferenceRecommendation(criteria),
            HRA_TOTAL_CHOLESTEROL: Recommendations._getTotalCholesterolRecommendation(criteria),
            HRA_FASTING_GLUCOSE: Recommendations._getFastingGlucoseRecommendation(criteria),
            NON_FASTING_GLUCOSE: Recommendations._getNonFastingGlucoseRecommendation(criteria),
            HDL_CHOLESTEROL: Recommendations._getHdlCholesterolRecommendation(criteria),
            [codes.adSlots.qriskResultPageRecommendations.code]: Recommendations._getQriskRecommendation(criteria),
            [codes.adSlots.alcoholResultPageRecommendations.code]: Recommendations._getAlcoholRecommendation(criteria),
            [codes.adSlots.activityResultPageRecommendations.code]: Recommendations._getActivityRecommendation(
                criteria
            ),
            [codes.adSlots.stressPSS4ResultPageRecommendations.code]: Recommendations._getStressPss4Recommendation(
                criteria
            ),
            [codes.adSlots.heartAgeResultPageRecommendations.code]: Recommendations._getHeartAgeRecommendation(
                criteria
            ),
            [codes.adSlots.dailyEnergyUseResultPageRecommendations
                .code]: Recommendations._getDailyEnergyUseRecommendation(criteria),
            [codes.adSlots.muscleMassResultPageRecommendations.code]: Recommendations._getMuscleMassRecommendation(
                criteria
            )
        };

        return recommendation[adSlotCode];
    }

    static getRecommendationFromRiskCode(riskCode, criteria) {
        const recommendation = {
            BODY_MASS_INDEX: Recommendations._getBmiRecommendation(criteria),
            BLOOD_PRESSURE: Recommendations._getBpRecommendation(criteria),
            HEART_RATE: Recommendations._getHrRecommendation(criteria),
            BODY_FAT: Recommendations._getBodyFatRecommendation(criteria),
            DIABETES: Recommendations._getDiabetesRiskRecommendation(criteria),
            SMOKING: Recommendations._getSmokingRecommendation(criteria),
            WAIST_CIRCUMFERENCE: Recommendations._getWaistCircumferenceRecommendation(criteria),
            TOTAL_CHOLESTEROL: Recommendations._getTotalCholesterolRecommendation(criteria),
            FASTING_GLUCOSE: Recommendations._getFastingGlucoseRecommendation(criteria),
            NON_FASTING_GLUCOSE: Recommendations._getNonFastingGlucoseRecommendation(criteria),
            HDL_CHOLESTEROL: Recommendations._getHdlCholesterolRecommendation(criteria),
            QRISK3: Recommendations._getQriskRecommendation(criteria),
            ALCOHOL_RISK: Recommendations._getAlcoholRecommendation(criteria),
            ACTIVITY: Recommendations._getActivityRecommendation(criteria),
            STRESS_PSS4: Recommendations._getStressPss4Recommendation(criteria),
            HEART_AGE: Recommendations._getHeartAgeRecommendation(criteria),
            DAILY_ENERGY_USE: Recommendations._getDailyEnergyUseRecommendation(criteria)
        };

        return recommendation[riskCode];
    }

    /**
     * BMI
     */
    static _getBmiRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.bmi.low"
            },
            normal: {
                recommendationText: "recommendations.bmi.normal"
            },
            elevated: {
                recommendationText: "recommendations.bmi.elevated"
            },
            high: {
                recommendationText: "recommendations.bmi.high"
            },
            veryHigh: {
                recommendationText: "recommendations.bmi.veryHigh"
            }
        }[criteria.rating];
    }

    /**
     * Blood Pressure
     */
    static _getBpRecommendation(criteria) {
        return {
            notSure: {
                recommendationText: "recommendations.bp.notSure"
            },
            optimal: {
                recommendationText: "recommendations.bp.optimal"
            },
            normal: {
                recommendationText: "recommendations.bp.normal"
            },
            highNormal: {
                recommendationText: "recommendations.bp.highNormal"
            },
            mildHypertension: {
                recommendationText: "recommendations.bp.mildHypertension"
            },
            moderateHypertension: {
                recommendationText: "recommendations.bp.moderateHypertension"
            },
            severeHypertension: {
                recommendationText: "recommendations.bp.severeHypertension"
            }
        }[criteria.rating];
    }

    /**
     * Heart Rate
     */
    static _getHrRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.hr.low"
            },
            normal: {
                recommendationText: "recommendations.hr.normal"
            },
            elevated: {
                recommendationText: "recommendations.hr.elevated"
            },
            high: {
                recommendationText: "recommendations.hr.high"
            },
            veryHigh: {
                recommendationText: "recommendations.hr.veryHigh"
            }
        }[criteria.rating];
    }

    /**
     * Body Fat
     */
    static _getBodyFatRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.bodyFat.low"
            },
            normal: {
                recommendationText: "recommendations.bodyFat.normal"
            },
            high: {
                recommendationText: "recommendations.bodyFat.high"
            },
            veryHigh: {
                recommendationText: "recommendations.bodyFat.veryHigh"
            }
        }[criteria.rating];
    }

    /**
     * Diabetes Risk
     */
    static _getDiabetesRiskRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.diabetesRisk.low"
            },
            intermediate: {
                recommendationText: "recommendations.diabetesRisk.intermediate"
            },
            high: {
                recommendationText: "recommendations.diabetesRisk.high"
            }
        }[criteria.rating];
    }

    /**
     * Smoking
     */
    static _getSmokingRecommendation(criteria) {
        const isSmokerRecommendation = {
            yes: {
                recommendationText: "recommendations.smoking.yes"
            },
            no: {
                recommendationText: "recommendations.smoking.no"
            }
        }[criteria.isSmoker];

        const smokingRatingRecommendation = {
            normal: {
                recommendationText: "recommendations.smoking.normal"
            },
            high: {
                recommendationText: "recommendations.smoking.high"
            }
        }[criteria.rating];

        // The API sometimes will not return a rating for smoking
        // Return the recommendation if it exists, otherwise return assume the user does not smoke
        return (
            isSmokerRecommendation ||
            smokingRatingRecommendation || { recommendationText: "recommendations.smoking.no" }
        );
    }

    /**
     * Waist Circumference
     */
    static _getWaistCircumferenceRecommendation(criteria) {
        return {
            high: {
                recommendationText: "recommendations.waistCircumference.high"
            }
        }[criteria.rating];
    }

    /**
     * Total Cholesterol
     */
    static _getTotalCholesterolRecommendation(criteria) {
        return {
            notSure: {
                recommendationText: "recommendations.totalCholesterol.notSure"
            },
            optimal: {
                recommendationText: "recommendations.totalCholesterol.optimal"
            },
            normal: {
                recommendationText: "recommendations.totalCholesterol.normal"
            },
            elevated: {
                recommendationText: "recommendations.totalCholesterol.elevated"
            },
            high: {
                recommendationText: "recommendations.totalCholesterol.high"
            }
        }[criteria.rating];
    }

    /**
     * Fasting Glucose
     */
    static _getFastingGlucoseRecommendation(criteria) {
        return {
            notSure: {
                recommendationText: "recommendations.fastingGlucose.notSure"
            },
            low: {
                recommendationText: "recommendations.fastingGlucose.low"
            },
            normal: {
                recommendationText: "recommendations.fastingGlucose.normal"
            },
            elevated: {
                recommendationText: "recommendations.fastingGlucose.elevated"
            },
            high: {
                recommendationText: "recommendations.fastingGlucose.high"
            }
        }[criteria.rating];
    }

    /**
     * Non Fasting Glucose
     */
    static _getNonFastingGlucoseRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.nonFastingGlucose.low"
            },
            normal: {
                recommendationText: "recommendations.nonFastingGlucose.normal"
            },
            elevated: {
                recommendationText: "recommendations.nonFastingGlucose.elevated"
            },
            high: {
                recommendationText: "recommendations.nonFastingGlucose.high"
            }
        }[criteria.rating];
    }

    /**
     * HDL Cholesterol
     */
    static _getHdlCholesterolRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.hdlCholesterol.low"
            },
            normal: {
                recommendationText: "recommendations.hdlCholesterol.normal"
            }
        }[criteria.rating];
    }

    /**
     * QRisk
     */
    static _getQriskRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.qrisk.low.text",
                recommendationTextPortal: "recommendations.qrisk.low.textPortal"
            },
            elevated: {
                recommendationText: "recommendations.qrisk.elevated.text",
                recommendationTextPortal: "recommendations.qrisk.elevated.textPortal",
                url: "recommendations.qrisk.elevated.url"
            }
        }[criteria.rating];
    }

    /**
     * Alcohol
     */
    static _getAlcoholRecommendation(criteria) {
        return {
            noRisk: {
                recommendationText: "recommendations.alcohol.noRisk.text",
                recommendationTextPortal: "recommendations.alcohol.noRisk.textPortal"
            },
            low: {
                recommendationText: "recommendations.alcohol.low.text",
                recommendationTextPortal: "recommendations.alcohol.low.textPortal",
                url: "recommendations.alcohol.low.url"
            },
            elevated: {
                recommendationText: "recommendations.alcohol.elevated.text",
                recommendationTextPortal: "recommendations.alcohol.elevated.textPortal",
                url: "recommendations.alcohol.elevated.url"
            },
            high: {
                recommendationText: "recommendations.alcohol.high.text",
                recommendationTextPortal: "recommendations.alcohol.high.textPortal",
                url: "recommendations.alcohol.high.url"
            },
            veryHigh: {
                recommendationText: "recommendations.alcohol.veryHigh.text",
                recommendationTextPortal: "recommendations.alcohol.veryHigh.textPortal",
                url: "recommendations.alcohol.veryHigh.url"
            }
        }[criteria.rating];
    }

    /**
     * Activity
     */
    static _getActivityRecommendation(criteria) {
        return {
            active: {
                recommendationText: "recommendations.activity.active.text",
                recommendationTextPortal: "recommendations.activity.active.textPortal",
                url: "recommendations.activity.active.url"
            },
            moderatelyActive: {
                recommendationText: "recommendations.activity.moderatelyActive.text",
                recommendationTextPortal: "recommendations.activity.moderatelyActive.textPortal",
                url: "recommendations.activity.moderatelyActive.url"
            },
            moderatelyInactive: {
                recommendationText: "recommendations.activity.moderatelyInactive.text",
                recommendationTextPortal: "recommendations.activity.moderatelyInactive.textPortal",
                url: "recommendations.activity.moderatelyInactive.url"
            },
            inactive: {
                recommendationText: "recommendations.activity.inactive.text",
                recommendationTextPortal: "recommendations.activity.inactive.textPortal",
                url: "recommendations.activity.inactive.url"
            }
        }[criteria.rating];
    }

    /**
     * Stress PSS4
     */
    static _getStressPss4Recommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.stressPss4.low.text",
                recommendationTextPortal: "recommendations.stressPss4.low.textPortal"
            },
            moderate: {
                recommendationText: "recommendations.stressPss4.moderate.text",
                recommendationTextPortal: "recommendations.stressPss4.moderate.textPortal"
            },
            high: {
                recommendationText: "recommendations.stressPss4.high.text",
                recommendationTextPortal: "recommendations.stressPss4.high.textPortal"
            }
        }[criteria.rating];
    }

    /**
     * Heart Age
     */
    static _getHeartAgeRecommendation(criteria) {
        return {
            optimal: {
                recommendationText: "recommendations.heartAge.optimal.text",
                recommendationTextPortal: "recommendations.heartAge.optimal.textPortal"
            },
            normal: {
                recommendationText: "recommendations.heartAge.normal.text",
                recommendationTextPortal: "recommendations.heartAge.normal.textPortal"
            },
            elevated: {
                recommendationText: "recommendations.heartAge.elevated.text",
                recommendationTextPortal: "recommendations.heartAge.elevated.textPortal"
            }
        }[criteria.rating];
    }

    /**
     * Daily Energy Use
     */
    static _getDailyEnergyUseRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.dailyEnergyUse.low.text",
                recommendationTextPortal: "recommendations.dailyEnergyUse.low.textPortal"
            },
            moderate: {
                recommendationText: "recommendations.dailyEnergyUse.moderate.text",
                recommendationTextPortal: "recommendations.dailyEnergyUse.moderate.textPortal"
            },
            high: {
                recommendationText: "recommendations.dailyEnergyUse.high.text",
                recommendationTextPortal: "recommendations.dailyEnergyUse.high.textPortal"
            }
        }[criteria.rating];
    }

    /**
     * Muscle Mass
     */
    static _getMuscleMassRecommendation(criteria) {
        return {
            low: {
                recommendationText: "recommendations.muscleMass.low.text",
                recommendationTextPortal: "recommendations.muscleMass.low.textPortal"
            },
            normal: {
                recommendationText: "recommendations.muscleMass.normal.text",
                recommendationTextPortal: "recommendations.muscleMass.normal.textPortal"
            },
            high: {
                recommendationText: "recommendations.muscleMass.high.text",
                recommendationTextPortal: "recommendations.muscleMass.high.textPortal"
            },
            veryHigh: {
                recommendationText: "recommendations.muscleMass.veryHigh.text",
                recommendationTextPortal: "recommendations.muscleMass.veryHigh.textPortal"
            }
        }[criteria.rating];
    }
}
