import UserJourneyPage from "./UserJourneyPage";

export default class UserJourney {
    constructor(schema) {
        this._schema = schema;
        this._response = null;

        // convert userJourneyPages in the schema to UserJourneyPage objects
        this._userJourneyPages = this._schema.userJourneyPages.map(pageSchema => new UserJourneyPage(pageSchema, this));
        this._userJourneyPages.sort(this.sortByPosition);
    }

    /**
     *
     * @returns {UserJourneyPage[]}
     */
    getUserJourneyPages() {
        return this._userJourneyPages;
    }

    /**
     *
     * @param {Response} response
     * @returns {UserJourney}
     */
    setResponse(response) {
        this._response = response;
        return this;
    }

    /**
     *
     * @returns {Response}
     */
    getResponse() {
        return this._response;
    }

    /**
     *
     * @returns {*}
     */
    getSchema() {
        return this._schema;
    }

    /**
     *
     * @returns {string}
     */
    getName() {
        return this.getSchema().name;
    }

    /**
     *
     * @returns {boolean}
     */
    get isHRA() {
        return this.getSchema().isCurrentHra;
    }

    /**
     *
     * @returns {UserJourneyPage}
     */
    getNextPage() {
        let nextPage = null;
        for (let page of this.getUserJourneyPages()) {
            if (!page.isResolved()) {
                nextPage = page;
                break;
            }
        }

        return nextPage;
    }

    /**
     *
     * @param surveyCode
     * @returns {Survey}
     */
    getSurveyByCode(surveyCode) {
        let survey = null;
        for (let page of this._userJourneyPages) {
            if (page.getSurvey() && page.getSurvey().getCode() === surveyCode) {
                survey = page.getSurvey();
                break;
            }
        }

        return survey;
    }

    /**
     *
     * @param userJourneyPageId
     * @returns {UserJourneyPage}
     */
    getPageByUserJourneyPageId(userJourneyPageId) {
        let page = null;
        for (let i = 0; i < this.getUserJourneyPages().length; i++) {
            let p = this.getUserJourneyPages()[i];
            if (p.getUserJourneyPageId() === userJourneyPageId) {
                page = p;
                break;
            }
        }

        return page;
    }

    /* istanbul ignore next */
    sortByPosition(a, b) {
        if (a.getPosition() === b.getPosition()) {
            return 0;
        } else {
            return a.getPosition() < b.getPosition() ? -1 : 1;
        }
    }

    toJSON() {
        let pojo = {};
        pojo.schema = this._schema;
        pojo.userJourneyPages = this._userJourneyPages.map(userJourneyPage => userJourneyPage.toJSON());

        return pojo;
    }
}
