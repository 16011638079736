/**
 * Houses all general purpose utility methods.
 */
export default class CoreUtils {
    /**
     * Gives a right padded string of a number.
     * @param number
     * @param multiplier
     * @param places
     * @return {string}
     */
    static numToPaddedString(number, multiplier, places) {
        return (number * multiplier)
            .toFixed()
            .toString()
            .padStart(places);
    }

    /**
     * Apply a threshold on the given number.
     * @param number
     * @param thresholds
     * @return {Number}
     */
    static limit(number, thresholds) {
        if (thresholds == null || thresholds.length !== 2) {
            throw new Error(`Thresholds cannot be null or undefined and should be of length two: ${thresholds}.`);
        }

        let min = thresholds[0];
        let max = thresholds[1];
        if (min > max) {
            throw new Error(`Minimum cannot be greater than maximum: Min: ${min}, Max: ${max}.`);
        }

        if (number == null || Number.isNaN(number)) {
            return min;
        }

        return number > max ? max : number < min ? min : number;
    }

    /**
     * Examines whether the given number is within the specified range.
     * @param {Number} number
     * @param {Number} min
     * @param {Number} max
     * @param {boolean} inclusiveMin
     * @param {boolean} inclusiveMax
     * @return {boolean}
     */
    static isWithin(number, min, max, inclusiveMin = true, inclusiveMax = true) {
        if (
            number === "" ||
            min === "" ||
            max === "" ||
            number == null ||
            min == null ||
            max == null ||
            Number.isNaN(Number(number)) ||
            Number.isNaN(Number(min)) ||
            Number.isNaN(Number(max))
        ) {
            throw new Error(`The input and range should be of numeric type, Number: ${number}, ${min}, ${max}`);
        }

        if (min > max) {
            throw new Error(`Minimum cannot be greater than maximum: Min: ${min}, Max: ${max}.`);
        }

        let resultMin = inclusiveMin ? number >= min : number > min;
        let resultMax = inclusiveMax ? number <= max : number < max;

        return resultMin && resultMax;
    }

    /**
     * A blocking wait
     *
     * @param milliseconds
     */
    static pause(milliseconds) {
        const now = new Date();

        while (new Date() - now < milliseconds) {
            // do nothing
        }
    }

    /**
     * Calculate age based on date of birth
     * @param dob Data of birth as Date object
     * @return {number}
     */
    static ageFromDob(dob) {
        return new Date().getFullYear() - dob.getFullYear();
    }
}
