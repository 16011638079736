import React, { useContext } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// ----------------------------------------------------------------------------

import { updateSetting } from "@sisuwellness/connected-components/actions/setting";
import APIClient from "utilities/APIClient";
import routes from "constants/routes";

import PreferenceCard from "./preferenceCard";
import { EditField, OptionField } from "./styled";
import GeneralCardForm from "./generalCardForm";
import { MessageContext } from "components/Context";
import {
    MESSAGE_ERROR,
    MESSAGE_SUCCESS,
    NO_NETWORK_ERROR_MESSAGE,
    REQUEST_FAILED_ERROR_MESSAGE
} from "constants/messages";
import { Margin } from "components/styled-components";
import { SETTINGS } from "constants/";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------

const GeneralCard = ({ forms }) => {
    const { unitMeasurement, energyUnit = "kilojoules" } = useSelector(state => state.settings);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const localisedRequestFailedErrorMessage = t(REQUEST_FAILED_ERROR_MESSAGE);

    const { handleMessage } = useContext(MessageContext);

    const history = useHistory();

    const optFields = [
        {
            main: t("words.measurement"),
            sub: t(SETTINGS.UNIT_MEASUREMENT[unitMeasurement])
        },
        {
            main: t("words.energy_system"),
            sub: t(SETTINGS.ENERGY_UNIT[energyUnit])
        }
    ];

    const handleSubmit = async ({ unitMeasurement, energyUnit }, { setSubmitting }) => {
        const updateOptions = { unitMeasurement, energyUnit };
        try {
            const response = await APIClient.users.updateSettings(updateOptions);
            if (response.succeeded()) {
                dispatch(updateSetting(updateOptions));
                history.replace("/my-account/preferences");
                handleMessage(MESSAGE_SUCCESS, t("members_portal_web.views.preferences.general_card.update_success"));
            } else if (response.isNetworkFailure()) {
                handleMessage(MESSAGE_ERROR, t(NO_NETWORK_ERROR_MESSAGE));
            } else handleMessage(MESSAGE_ERROR, localisedRequestFailedErrorMessage);
        } catch (error) {
            handleMessage(MESSAGE_ERROR, localisedRequestFailedErrorMessage);
        } finally {
            setSubmitting(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <Margin mTop={[forms ? "32px" : "16px"]}>
            <PreferenceCard heading={t("words.general")}>
                {!forms && <EditField to={routes.myAccount.preferencesGeneral}>{t("words.edit")}</EditField>}
                {!forms ? (
                    optFields.map(option => (
                        <OptionField key={option.main}>
                            <div className="field-main">{option.main}</div>
                            <div className="field-sub">{option.sub}</div>
                        </OptionField>
                    ))
                ) : (
                    <GeneralCardForm
                        onSubmit={handleSubmit}
                        energyUnit={energyUnit}
                        unitMeasurement={unitMeasurement}
                    />
                )}
            </PreferenceCard>
        </Margin>
    );
};

GeneralCard.propTypes = {
    forms: PropTypes.bool
};

GeneralCard.defaultProps = {
    forms: false
};

export default GeneralCard;
