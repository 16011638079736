import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";

// ----------------------------------------------------------------------------

import { MediaQuery } from "@sisuwellness/web-components";

// ----------------------------------------------------------------------------

import PageContainer from "../PageContainer";
import ThinHeader from "../ThinHeader";
import LanguageDropdown from "../LanguageDropdown";
import routes from "constants/routes";

import { PORTAL_IMAGE } from "constants/imagePath";
import { HeaderContainer, ItemsContainer, Button, MemberLogin, MobileLogo } from "./styled";
import styled from "styled-components";

import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------

const MobileLanguageWrapper = styled.div`
    position: fixed;
    left: 10px;
`;

const BaseMemberViewWithoutMenu = ({ children, margin, width, padding, loading }) => {
    const { t } = useTranslation();

    const currentRoute = useLocation().pathname.toLowerCase();
    const isHra = currentRoute.includes("health-risk-assessment")

    const content = (
        <>
            <MediaQuery devices={["desktop"]}>
                <HeaderContainer loading={loading}>
                    <ItemsContainer>
                        <img
                            data-testid="header-logo-without-menu"
                            src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`}
                            onClick={() => window.location.assign(routes.healthHub)}
                        />
                    </ItemsContainer>
                    <ItemsContainer>
                        <LanguageDropdown paddingRight="30px" />
                        <Button onClick={() => window.location.assign("/")}>
                            {t("members_portal_web.components.base_member_view.no_menu.member_sign_in")}
                        </Button>
                    </ItemsContainer>
                </HeaderContainer>
            </MediaQuery>
            <MediaQuery devices={["mobile", "tablet"]}>
                <MobileLogo>
                    <MobileLanguageWrapper>
                    {!isHra && <LanguageDropdown iconOnly openLeft={false} />}
                    </MobileLanguageWrapper>
                    <img
                        data-testid="mobile-header-logo"
                        src={`${PORTAL_IMAGE}/logo-mobile.svg`}
                        onClick={() => window.location.assign("/")}
                    />
                    <MemberLogin onClick={() => window.location.assign("/")}>
                        {t("members_portal_web.components.base_member_view.no_menu.member_sign_in")}
                    </MemberLogin>
                </MobileLogo>
            </MediaQuery>
        </>
    );
    return (
        <>
            <ThinHeader data-testid="base-member-without-menu">{content}</ThinHeader>
            <PageContainer id={"page-container"} margin={margin} width={width} padding={padding}>
                {children}
            </PageContainer>
        </>
    );
};

// ----------------------------------------------------------------------------

BaseMemberViewWithoutMenu.displayName = "Page Container Without Member Menu";
BaseMemberViewWithoutMenu.propTypes = {
    children: PropTypes.any,
    margin: PropTypes.string,
    width: PropTypes.string,
    padding: PropTypes.string,
    loading: PropTypes.oneOf([PropTypes.string, PropTypes.bool])
};

export default BaseMemberViewWithoutMenu;
