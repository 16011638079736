import LM from "ml-levenberg-marquardt";
import { geometricMean } from "./geometricMean";
import { coleModelMagnitude } from "./coleModelMagnitude";

export const MEASUREMENT_FREQUENCIES_KHZ = [5, 50, 100, 200];
export const MEASUREMENT_FREQUENCIES_RAD_PER_SEC = MEASUREMENT_FREQUENCIES_KHZ.map(v => v * 1000.0 * 2.0 * Math.PI);

export const calculateColeParameters = (impedanceVector, frequencyVector = MEASUREMENT_FREQUENCIES_RAD_PER_SEC) => {
    const data = {
        x: frequencyVector,
        y: impedanceVector
    };

    const yMax = Math.max(...impedanceVector);
    const yMin = Math.min(...impedanceVector);
    const xMean = geometricMean(frequencyVector);

    // [R0, Rinf, alpha, tau]
    const initialValues = [yMax + 5, yMin - 5, 1.0, 1 / xMean];
    const minValues = [yMax * 1.01, yMin / 1.1, 0.5, 0.0000001];
    const maxValues = [yMax * 1.4, yMin - 5, 999, 999];

    const options = {
        initialValues,
        minValues,
        maxValues,
        centralDifference: true,
        gradientDifference: 10e-8,
        damping: 5.0
    };

    return LM(data, coleModelMagnitude, options);
};
