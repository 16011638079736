import { Flex } from "rebass";
import styled from "styled-components";
import { media } from "@sisuwellness/web-components/themes";

export const EmptyHealthCheckContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;
    justify-content: center;
    height: 70vh;
    width: 100%;
`;

export const StyledCard = styled(Flex)`
    border-radius: 10px;
    object-fit: contain;
    flex-direction: row;
    box-shadow: 0 4px 8px 0 rgba(50, 50, 71, 0.06), 0 4px 4px 0 rgba(50, 50, 71, 0.08);

    & > * + * {
        margin-left: 48px;

        ${media.mobile`
            margin-left: 24px;
        `}
    }
`;

export const StyledPagination = styled.div`
    .react-pagination {
        padding: 0;
        display: flex;
        font-size: 15px;
        list-style: none;
        user-select: none;
        flex-direction: row;
        white-space: nowrap;
        justify-content: flex-start;

        a {
            font-family: Open Sans;
        }

        li {
            padding: 4px;
            height: 22px;
            min-width: 22px;
            margin-left: 4px;
            text-align: center;
            border-radius: 4px;
        }

        li.selected {
            color: white;
            background-color: #374a59;
        }
    }
`;
