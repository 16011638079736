import React, { useContext } from "react";

import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";

import { Redirect } from "react-router";
import { mergeRight } from "ramda";

// ----------------------------------------------------------------------

import { useAsyncData } from "@sisuwellness/utilities/hooks";
import { getHealthChecksAPI } from "actions/health-checks";
import { actualAgeRecordedAtTheTimeOfLastHealthCheck, consolidateMetricData } from "utilities/commonUtils";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import LoadingView from "components/LoadingView";
import { PremiumFeature } from "components/PremiumFeatures";
import CollapsableCard from "components/CollapsableCard";
import { HEART_AGE } from "constants/trend-charts";
import PageLayout from "components/PageLayout";
import BreadCrumbs from "views/BreadCrumbs";
import Flex from "components/Flex";
import FACTS from "constants/facts";
import routes from "constants/routes";

import UnlockedHeartAge from "./UnlockedHeartAge";
import LockedHeartAge from "./LockedHeartAge";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

const Context = React.createContext({});
export const Provider = Context.Provider;

const HeartAgeView = () => {
    const { t } = useTranslation();
    const citizen = useSelector(state => state.auth.citizen);
    const { healthChecks = [] } = useSelector(state => state.healthCheck);

    const dispatch = useDispatch();

    const [{ isStarted, isLoading }] = useAsyncData(() => isEmpty(healthChecks) && dispatch(getHealthChecksAPI()), []);

    if (!isStarted || isLoading) {
        return <LoadingView />;
    }

    const latestHealthCheck = mergeRight(consolidateMetricData(citizen, healthChecks), {
        age: actualAgeRecordedAtTheTimeOfLastHealthCheck(healthChecks)
    });
    const value = { latestHealthCheck, healthChecks };

    return (
        <Provider value={value}>
            <PageLayout>
                {/* Heart Age Navigation */}
                <BreadCrumbs mb="16px" />
                <Flex fd="column" gap="16px" mb="32px">
                    <HeadingLabel fs={["28px", "34px"]} fontWeight="intermediate">
                        {t("words.heart_age")}
                    </HeadingLabel>
                    <HeadingLabel fs={["13", "15px"]}>
                        {t("members_portal_web.views.heart_age.page.main.heading_label")}
                    </HeadingLabel>
                </Flex>

                {/* Heart Age Outcome page Locked or Unlocked */}
                <PremiumFeature>
                    {({ isPremium, canBePremium }) => {
                        if (!canBePremium) return <Redirect to={routes.healthHub} />;
                        return isPremium ? <UnlockedHeartAge /> : <LockedHeartAge />;
                    }}
                </PremiumFeature>

                {/* Heart Age FAQs */}
                <Flex fd="column" gap="32px" mt="32px">
                    <HeadingLabel fontSize={["22px", "34px"]} fontWeight="500">
                        {t("members_portal_web.views.heart_age.page.main.common_questions")}
                    </HeadingLabel>
                    <Flex fd="column" gap={["16px", "24px"]}>
                        {FACTS[HEART_AGE].data.map((facts, index) => (
                            <CollapsableCard mb="24px" {...facts} key={index} />
                        ))}
                    </Flex>
                </Flex>
            </PageLayout>
        </Provider>
    );
};

/**
 * Hook for user health check data
 * @return {{ latestHealthCheck: object, healthChecks: object[] }}
 */
export const useUserHealthCheckData = () => useContext(Context);
export default HeartAgeView;
