import codes from "../../UserJourney/UserJourneyMap";

const totalRiskLevel = 4;

const ranges = {
    inactive: {
        key: "inactive",
        label: codes.answers.PaiScores.inactive
    },
    moderatelyInactive: {
        key: "moderatelyInactive",
        label: codes.answers.PaiScores.moderatelyInactive
    },
    moderatelyActive: {
        key: "moderatelyActive",
        label: codes.answers.PaiScores.moderatelyActive
    },
    active: {
        key: "active",
        label: codes.answers.PaiScores.active
    }
};

const SisuActivityGuidelineDefinition = [
    {
        key: "active",
        label: codes.answers.PaiScores.active,
        rangeInfo: "",
        ranges,
        riskLevel: 1 / (totalRiskLevel + 1)
    },
    {
        key: "moderatelyActive",
        label: codes.answers.PaiScores.moderatelyActive,
        rangeInfo: "",
        ranges,
        riskLevel: 2 / (totalRiskLevel + 1)
    },
    {
        key: "moderatelyInactive",
        label: codes.answers.PaiScores.moderatelyInactive,
        rangeInfo: "",
        ranges,
        riskLevel: 3 / (totalRiskLevel + 1)
    },
    {
        key: "inactive",
        label: codes.answers.PaiScores.inactive,
        rangeInfo: "",
        ranges,
        riskLevel: 4 / (totalRiskLevel + 1)
    }
];

/**
 * Finds the guideline for the given score
 * @param paiScore
 * @returns {*}
 */
function GetGuideline(paiScore) {
    return SisuActivityGuidelineDefinition.find(def => def.label === paiScore);
}

const RiskName = "ACTIVITY";
const RiskCode = "ACTIVITY";
const GuidelineName = "SiSu Activity Guideline";
const GuidelineCode = "SISU_ACTIVITY";

export default { GetGuideline, RiskName, RiskCode, GuidelineName, GuidelineCode, SisuActivityGuidelineDefinition };
