import { REQUEST_FAILED_ERROR_MESSAGE, NO_NETWORK_ERROR_MESSAGE } from "../../constants/messages";

export async function handleResponse(response, safeValue) {
    if (response.succeeded()) {
        return await response.body();
    } else if (response.isNetworkFailure()) {
        throw new Error(NO_NETWORK_ERROR_MESSAGE);
    } else if (response.isNotAuthorised()) {
        window.location.assign("/?token_expired=true");
        return safeValue;
    } else {
        throw new Error(REQUEST_FAILED_ERROR_MESSAGE);
    }
}
