import React from "react";
import styled from "styled-components";
import { media } from "@sisuwellness/web-components/themes";
import { ResponsiveImage } from "@sisuwellness/web-components";
import { Flex } from "rebass";

const LogoContainer = styled(Flex)`
    background-color: ${props => props.theme.colours.flat.darkBlue.hex};
    color: ${props => props.theme.colours.flat.white.hex};
    border: 0;
    ${props => props.theme.shadowCss.buttonRest()};
    min-height: 40vh;

    ${media.mobile`
        min-height: 50vh;
    `}
`;

const LoginHeader = () => (
    <Flex flexDirection="column">
        <LogoContainer justifyContent="center" alignItems="center" px={"10%"} data-testid="login-header">
            <ResponsiveImage data-testid="login-header-logo" src="/icons/SiSUlogo-white.svg" alt="SiSU Logo" />
        </LogoContainer>
    </Flex>
);

LoginHeader.displayName = "LoginHeader";

export default LoginHeader;
