/**
 *  Hook that returns the functions for RudderStack analytics.
 *
 * @returns {{ groupEvent: (groupId: string) => any, identifyEvent: (userId: string) => void, pageEvent: (pageName: string) => any, trackEvent: (eventName: string, properties: object) => any }}
 */
const useRudderStack = () => {
    const client = window.rudderanalytics;
    const standardProperties = { platform: "portal" };

    /**
     * A function that tracks events with the given event name and additional properties.
     *
     * @param {string} eventName - The name of the event to track.
     * @param {object} properties - Additional properties to send along with the event.
     */
    const trackEvent = (eventName, properties) => client?.track(eventName, { ...standardProperties, ...properties });

    /**
     * A function that identifies a user by a userId.
     *
     * @param {string} userId - The id of the user to identify.
     */
    const identifyEvent = userId => {
        if (client) {
            client.reset();
            client.identify(userId);
        }
    };

    /**
     * A function that tracks group events.
     *
     * @param {string} groupId - The id of the group.
     */
    const groupEvent = groupId => client?.group(groupId, standardProperties);

    /**
     * A function that tracks page events.
     *
     * @param {string} pageName - The name of the page.
     */
    const pageEvent = pageName => client?.page(null, pageName, standardProperties);

    return { groupEvent, identifyEvent, pageEvent, trackEvent };
};

export default useRudderStack;
