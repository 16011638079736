import { PORTAL_IMAGE } from "constants/imagePath";

import TdeeAnimation from "assets/lottie/locked_tdee.json";
import HeartAgeAnimation from "assets/lottie/locked_heart_age.json";

const PREMIUM_UPGRADE = {
    HEADING: "members_portal_web.constants.scripts.premium_upgrade.heading",
    SUBHEADING: "members_portal_web.constants.scripts.premium_upgrade.subheading",
    LOGO: {
        SRC: `${PORTAL_IMAGE}/premium/status_plus_logo.svg`,
        ALT: "sisu_plus_logo.svg"
    }
};

export const LOCKED_HEART_AGE = {
    CTA: "members_portal_web.constants.scripts.locked_heart_age.cta",

    ANIMATION: HeartAgeAnimation,

    LOCKED_IMAGES: {
        imageDesktop: "locked_heart_age_desktop.svg",
        imageMobile: "locked_heart_age_mob.png"
    },

    UNDERSTANDING_IMPACT: {
        IMPACT_SECTION: {
            HEADING:
                "members_portal_web.constants.scripts.locked_heart_age.understanding_impact.impact_section.heading",
            SUBHEADING:
                "members_portal_web.constants.scripts.locked_heart_age.understanding_impact.impact_section.subheading",
            DESCRIPTION:
                "members_portal_web.constants.scripts.locked_heart_age.understanding_impact.impact_section.description",
            DESCRIPTION_POINTS:
                "members_portal_web.constants.scripts.locked_heart_age.understanding_impact.impact_section.description_points"
        },
        PREMIUM_UPGRADE,
        FOOTER: "members_portal_web.constants.scripts.locked_heart_age.understanding_impact.footer"
    }
};

export const LOCKED_TDEE = {
    CTA: "Unlock Daily Energy Use",

    ANIMATION: TdeeAnimation,

    LOCKED_IMAGES: {
        imageDesktop: "dailyEnergy/locked-desktop.svg",
        imageMobile: "dailyEnergy/locked-mobile.svg"
    },

    UNDERSTANDING_IMPACT: {
        IMPACT_SECTION: {
            HEADING: "members_portal_web.constants.scripts.locked_tdee.understanding_impact.impact_section.heading",
            SUBHEADING:
                "members_portal_web.constants.scripts.locked_tdee.understanding_impact.impact_section.subheading",
            DESCRIPTION:
                "members_portal_web.constants.scripts.locked_tdee.understanding_impact.impact_section.description",
            DESCRIPTION_POINTS:
                "members_portal_web.constants.scripts.locked_tdee.understanding_impact.impact_section.description_points"
        },
        PREMIUM_UPGRADE,
        FOOTER: "members_portal_web.constants.scripts.locked_tdee.footer"
    }
};

export const LOCKED_BODY_COMPOSITION = {
    CTA: "members_portal_web.constants.scripts.locked_body_composition.cta",

    LOCKED_IMAGES: {
        imageDesktop: "bodyComposition/locked-desktop.svg",
        imageMobile: "bodyComposition/locked-mobile.svg"
    },

    UNDERSTANDING_IMPACT: {
        IMPACT_SECTION: {
            HEADING:
                "members_portal_web.constants.scripts.locked_body_composition.understanding_impact.impact_section.heading",
            SUBHEADING:
                "members_portal_web.constants.scripts.locked_body_composition.understanding_impact.impact_section.subheading",
            DESCRIPTION:
                "members_portal_web.constants.scripts.locked_body_composition.understanding_impact.impact_section.description",
            DESCRIPTION_POINTS:
                "members_portal_web.constants.scripts.locked_body_composition.understanding_impact.impact_section.description_points"
        },
        PREMIUM_UPGRADE,
        FOOTER: "members_portal_web.constants.scripts.locked_body_composition.understanding_impact.footer"
    }
};
