import React from "react";
import { withTheme } from "styled-components";
import { Heading as RebassHeading } from "rebass";
import PropTypes from "prop-types";

export const HEADING_TYPES = ["h1", "h2", "h3", "h4", "h5", "h6"];

const mapHeadingToThemeSize = ({ typography, headingType }) => {
    const headingKey = headingType.replace("h", "heading");
    return typography[headingKey].sizeRem;
};

const Heading = ({ children, renderAs: as, size, theme: { text = {} }, ...rest }) => {
    if (!HEADING_TYPES.includes(as)) {
        throw new Error(`Invalid type of heading: ${as}. Heading should be in this list ${HEADING_TYPES}`);
    }

    return (
        <RebassHeading
            as={as}
            fontSize={size || mapHeadingToThemeSize({ typography: text, headingType: as })}
            {...rest}
        >
            {children}
        </RebassHeading>
    );
};

Heading.propTypes = {
    renderAs: PropTypes.oneOf(HEADING_TYPES),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    theme: PropTypes.object,
    children: PropTypes.node
};

export default withTheme(Heading);
