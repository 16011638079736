import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";

// -----------------------------------------------------------------------------

import APIClient from "utilities/APIClient";
import { REGISTRATION_TOKEN } from "constants/";
import { MESSAGE_ERROR } from "constants/messages";
import { useMessageBanner } from "components/ContextProviders";
import { getErrorMessage, modifyResposePromise } from "utilities/commonUtils";

// -----------------------------------------------------------------------------

/**
 * Hook to consume registration token
 */
const useConsumeRegistrationToken = () => {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const rtFromSearchParams = searchParams.get("rt");

    const token = rtFromSearchParams || window.localStorage.getItem(REGISTRATION_TOKEN);
    const { authenticated } = useSelector(state => state.auth);
    const { handleMessage } = useMessageBanner();

    const [isTokenConsumed, setIsTokenConsumed] = useState(false);
    const [isCorporateSubscriptionSuccessful, setIsCorporateSubscriptionSuccessful] = useState(false);

    const removeRegistrationToken = () => {
        if (rtFromSearchParams) {
            searchParams.delete("rt");
            history.replace({ search: searchParams.toString() });
        }
        window.localStorage.removeItem(REGISTRATION_TOKEN);
    };

    useEffect(() => {
        if (!authenticated || !token) return;

        // Consume Registration Token
        modifyResposePromise(APIClient.tokens.consumeToken(token))
            .then(() => setIsTokenConsumed(true))
            .catch(err =>
                window.Rollbar.critical(
                    `Failure to consume registration token [${token}] status code [${err.getRawStatusCode()}]`
                )
            );

        // Subscribe to Corporate Subscription
        modifyResposePromise(APIClient.tokens.validateToken(token, "campaign"))
            .then(({ hasCorporateSponsorship }) =>
                hasCorporateSponsorship ? APIClient.extractAuthData() : Promise.reject()
            ) // Check if Corporate Sponsorship
            .then(({ email, uuid }) =>
                modifyResposePromise(APIClient.campaigns.corporateSubscription({ uuid, email, token }))
            ) // Subscribe to Corporate registration
            .then(({ success }) =>
                success ? Promise.resolve() : Promise.reject(new Error("Corporate subscription failed"))
            ) // Handle only successful response
            .then(() => setIsCorporateSubscriptionSuccessful(true)) // On Successful Subscription
            .catch(err => err && handleMessage(MESSAGE_ERROR, getErrorMessage(err)))
            .finally(removeRegistrationToken); // Remove Registration Token from Local Storage
    }, [authenticated]);

    return [isCorporateSubscriptionSuccessful, isTokenConsumed];
};

export default useConsumeRegistrationToken;
