import PropTypes from "prop-types";

import { PrimaryButton } from "./styled";

PrimaryButton.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    color: PropTypes.string,
    borderRadius: PropTypes.string,
    borderColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    disabledColor: PropTypes.string,
    disabledBorderColor: PropTypes.string,
    disabledBackgroundColor: PropTypes.string
};

export default PrimaryButton;
