import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import UserProfile from "../components/UserProfile";
import { Header } from "@sisuwellness/web-components/components/Forms";
import LoadingView from "components/LoadingView";
import { withTranslation } from "react-i18next";

const StyledCompleteUserProfileView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
`;

const StyledHeader = styled(Header)`
    text-align: center;
`;

class CompleteUserProfileView extends Component {
    static displayName = "CompleteUserProfileView";

    static propTypes = {
        t: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };

        this.onUserProfileLoaded = this.onUserProfileLoaded.bind(this);
        this.onUserProfileCompleted = this.onUserProfileCompleted.bind(this);
    }

    onUserProfileLoaded() {
        this.setState({ loading: false });
    }

    onUserProfileCompleted() {
        this.setState({ loading: true });
        window.location.reload();
    }

    render() {
        let loading = null;
        if (this.state.loading) {
            loading = <LoadingView />;
        }

        return (
            <StyledCompleteUserProfileView>
                {loading}
                <StyledHeader>{this.props.t("members_portal_web.views.complete_profile.header")}</StyledHeader>
                <UserProfile
                    t={this.props.t}
                    onUserProfileLoaded={this.onUserProfileLoaded}
                    onUserProfileCompleted={this.onUserProfileCompleted}
                />
            </StyledCompleteUserProfileView>
        );
    }
}

export default withTranslation()(CompleteUserProfileView);
