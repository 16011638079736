import BaseEndPoint from "../BaseEndPoint";

export default class Campaigns extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    search(campaignCode) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("/campaigns/search") + "?marketingCode=" + campaignCode, settings);
    }

    corporateSubscription(data) {
        const settings = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            method: "POST",
            data: JSON.stringify(data)
        };

        return this._send(`${this.client.config.memberApiDomain}/subscriptions/corporate`, settings, false, false);
    }
}
