import React, { Component } from "react";
import styled from "styled-components";
import BaseCard from "../../Cards";
import PropTypes from "prop-types";
import ActionButton from "../../Station/buttons/actionButton";
import FullPageCard from "../Components/FullPageCard";
import { Back } from "../../Buttons";
import StyledRightContentContainer from "../../WYSIWYG/Container";

const viewStates = { consent: "consent", terms: "terms", policy: "policy" };

// Consent View
const StyledContainer = styled.div`
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    min-height: 100%;
    height: 100%;
`;

const StyledLeftContainer = styled(BaseCard)`
    position: relative;
    flex-grow: 3;
    flex-basis: 1em;
    margin-right: 1em;
    padding: 0;
    height: 100%;
    width: 100%;

    > .content {
        display: block;
        height: 100%;
    }
`;

const StyledLink = styled.a`
    display: flex;
    font-size: ${props => props.theme.text.heading6.sizeRem};
    color: ${props => props.theme.colours.flat.lightBlue.hex};
`;

const StyledImage = styled.div`
   background: transparent url("${props => props.source}") no-repeat 0 0;
   resize: both;
   background-size: cover;
   height: 100%;
   width: 100%;   
   border-radius: 5px 5px 0 0;
`;

const StyledRightContainer = styled(BaseCard)`
    position: relative;
    flex-grow: 5;
    flex-basis: 2em;
    margin-left: 1em;
    min-height: 100%;
    height: 785px;
    padding: 0;
`;

const StyledButtonContainer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 8em;
    border-top: 1px solid ${props => props.theme.colours.flat.lightGray.hex};
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    background: #fff;
`;

const StyledTermsPrivacyContainer = styled(StyledButtonContainer)`
    flex-direction: column;
`;

const StyledActionButton = styled(ActionButton)`
    text-align: center !important;
`;

// Terms and Policy
const StyledTermsContainer = styled(StyledContainer)`
    flex-direction: column;
    width: 100%;
    padding: 2.5em;
`;

// contains WYSIWYG content
const StyledTermsDetails = styled.div`
    margin-top: 1em;
    width: 100%;
    height: 670px;
    overflow: scroll;

    h2 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading2.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    h3 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading3.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    h4 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading4.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    p {
        font-size: ${props => props.theme.text.paragraph.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }
`;

const StyledVideo = styled.video`
    width: 100%;
    object-fit: contain;
    overflow: hidden;
    max-width: 368.5px;
    background-color: transparent;
`;

export default class TemplateOne extends Component {
    static propTypes = {
        theme: PropTypes.object,
        schema: PropTypes.object.isRequired,
        onAccepted: PropTypes.func.isRequired,
        onRejected: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            view: viewStates.consent
        };

        this.handleConsentClick = this.handleConsentClick.bind(this);
        this.handlePolicyClick = this.handlePolicyClick.bind(this);
        this.handleTermsClick = this.handleTermsClick.bind(this);
        this.handleAccepted = this.handleAccepted.bind(this);
        this.handleRejected = this.handleRejected.bind(this);
    }

    handleConsentClick(event) {
        event.preventDefault();

        this.setState({ view: viewStates.consent });
    }

    handleTermsClick(event) {
        event.preventDefault();

        this.setState({ view: viewStates.terms });
    }

    handlePolicyClick(event) {
        event.preventDefault();

        this.setState({ view: viewStates.policy });
    }

    handleAccepted() {
        this.props.onAccepted();
    }

    handleRejected() {
        this.props.onRejected();
    }

    compositeConsentView() {
        let {
            acceptButtonLabel,
            rejectButtonLabel,
            upSell,
            primaryImage,
            termsAndConditions,
            privacyPolicy
        } = this.props.schema;

        rejectButtonLabel = rejectButtonLabel ? rejectButtonLabel.trim() : "";

        let privacyPolicyLink;
        if (privacyPolicy) {
            privacyPolicyLink = <StyledLink onClick={this.handlePolicyClick}>{"Privacy Policy"}</StyledLink>;
        }

        let termsAndConditionsLink;
        if (termsAndConditions) {
            termsAndConditionsLink = <StyledLink onClick={this.handleTermsClick}>{"Terms of Use"}</StyledLink>;
        }

        const assetType = /(?:.mp4$)|(?:.webm$)|(?:.ogg$)/.test(primaryImage) ? "video" : "image";

        return (
            <StyledContainer>
                <StyledLeftContainer>
                    {assetType === "video" ? (
                        <StyledVideo autoPlay muted data-testid="video-asset">
                            <source src={primaryImage} type="video/mp4" />
                        </StyledVideo>
                    ) : (
                        <StyledImage source={primaryImage} data-testid="image-asset" />
                    )}

                    {(termsAndConditionsLink || privacyPolicyLink) && (
                        <StyledTermsPrivacyContainer data-testid="tc-container">
                            {termsAndConditionsLink}
                            {privacyPolicyLink}
                        </StyledTermsPrivacyContainer>
                    )}
                </StyledLeftContainer>
                <StyledRightContainer>
                    <StyledRightContentContainer dangerouslySetInnerHTML={{ __html: upSell }} />
                    <StyledButtonContainer>
                        {rejectButtonLabel && (
                            <StyledActionButton
                                styleType={"Secondary"}
                                label={rejectButtonLabel}
                                onClick={this.handleRejected}
                            />
                        )}
                        <StyledActionButton label={acceptButtonLabel} onClick={this.handleAccepted} />
                    </StyledButtonContainer>
                </StyledRightContainer>
            </StyledContainer>
        );
    }

    compositeTerms() {
        return this.compositeFullPage(this.props.schema.termsAndConditions);
    }

    compositePolicy() {
        return this.compositeFullPage(this.props.schema.privacyPolicy);
    }

    compositeFullPage(wysiwygContent) {
        return (
            <FullPageCard>
                <StyledTermsContainer>
                    <Back visible onClick={this.handleConsentClick}>
                        &lt; Back
                    </Back>
                    <StyledTermsDetails dangerouslySetInnerHTML={{ __html: wysiwygContent }} />
                </StyledTermsContainer>
            </FullPageCard>
        );
    }

    render() {
        let output;
        switch (this.state.view) {
            case viewStates.consent:
                output = this.compositeConsentView();
                break;

            case viewStates.terms:
                output = this.compositeTerms();
                break;

            case viewStates.policy:
                output = this.compositePolicy();
                break;
        }

        return output;
    }
}
