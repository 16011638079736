// REF: https://framinghamheartstudy.org/fhs-risk-functions/cardiovascular-disease-10-year-risk/

import { getGenderConstants } from "./constants";
import calcTenYearCvdRisk from "./calcTenYearCvdRisk";
import calcHeartAge from "./calcHeartAge";
import { compose, evolve, multiply, add, divide, __ } from "ramda";

export default ({ gender, age, systolic, bmi, isSmoker, hasDiabetes, isTakingBpMeds }) => {
    const genderConst = getGenderConstants(gender);
    const cvdRisk = calcTenYearCvdRisk(genderConst)({
        gender,
        age,
        systolic,
        bmi,
        isSmoker,
        hasDiabetes,
        isTakingBpMeds
    });
    const calcHA = calcHeartAge(genderConst);
    const heartAge = calcHA(cvdRisk.tenYearCvdRisk);
    const heartAgeOptimal = calcHA(cvdRisk.tenYearCvdRiskOptimal);
    return { heartAge, heartAgeOptimal, ...cvdRisk };
};

export const toPercentage = compose(
    divide(__, 10),
    Math.round,
    multiply(1000),
    add(Number.EPSILON)
);

export const withPercentage = evolve({
    tenYearCvdRisk: toPercentage,
    tenYearCvdRiskNormal: toPercentage,
    tenYearCvdRiskOptimal: toPercentage
});
