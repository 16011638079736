import BaseEndPoint from "../BaseEndPoint";

export default class Citizen extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/citizen/" + path);
    }

    trackResponse(responseId) {
        return super.post("track-response", { responseId });
    }
}
