import React from "react";
import PropTypes from "prop-types";

import { Flex } from "rebass";

// ------------------------------------------------------------------------

import { NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import Tooltip from "@sisuwellness/ui/src/components/Tooltip";

import { theme } from "@sisuwellness/web-components";
import { formatDate } from "utilities/commonUtils";

// ------------------------------------------------------------------------

/**
 * Getter for text color
 * @param {string} pointColor hex
 * @return {"#fff" | "#1a1a1a"}
 */
export const getTextColor = pointColor => {
    const { colours } = theme;
    return !pointColor ||
        pointColor === colours.hpDarkGreen.hex ||
        pointColor === colours.hpDarkPink.hex ||
        pointColor === colours.flat.primaryBlack.hex ||
        pointColor === colours.hpPrimaryPurple.hex
        ? colours.hpWhite.hex
        : colours.flat.lightBlack.hex;
};

export const TrendTooltip = ({ point, color, tooltipKey = "y" }) => {
    const textColor = getTextColor(color);

    return (
        <Tooltip bg={color}>
            <Flex
                p="10px"
                pb="20px"
                bg="transparent"
                minWidth="75px"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
            >
                <NormalLabel color={textColor} textAlign="center" fontWeight="bold">
                    {point?.data?.[tooltipKey]?.toLocaleString?.()} {point?.data?.unit}
                </NormalLabel>
                <NormalLabel color={textColor} textAlign="center" fontSize="16px">
                    {formatDate(point?.data?.dateCreated, "DD.MM.YY")}
                </NormalLabel>
            </Flex>
        </Tooltip>
    );
};

TrendTooltip.propTypes = {
    point: PropTypes.object,
    color: PropTypes.string,
    tooltipKey: PropTypes.string
};

export default TrendTooltip;
