import buildSisuGuideline, { RiskName, RiskCode, GuidelineName, GuidelineCode, buildGuidelineDefinition } from "./SiSU";
import { calculateMuscleMass } from "./calculateMuscleMass";
import calculateMuscleMassForAgeRange, {
    RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE,
    RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS,
    RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS
} from "./calculateMuscleMassForAgeRange";

const Calculate = ({ age, gender, heightCm, weightKg, impedanceVector } = {}) => {
    const { muscleMassPercentage } = calculateMuscleMass(impedanceVector, heightCm, weightKg, age, gender);

    const rating = buildSisuGuideline(muscleMassPercentage, gender, age);

    return { rating, muscleMassPercentage, muscleMassRating: rating.key };
};

export {
    Calculate,
    RiskName,
    RiskCode,
    GuidelineName,
    GuidelineCode,
    buildSisuGuideline,
    buildGuidelineDefinition,
    calculateMuscleMassForAgeRange,
    RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE,
    RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS,
    RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS
};
