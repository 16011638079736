import { pickAttribute } from "utilities/commonUtils";
import { withPercentage } from "@sisuwellness/utilities/Guidelines/HeartAge/Framingham";
import { calculateHeartAge } from "utilities/premium";

/**
 * Check if heart age is only indicative
 * @param {number} age
 * @return {boolean}
 */
export const isHeartAgeIndicative = age => age < 30 || age > 74;

/**
 *
 * @param {object} healthCheck
 * @return {{ age: number, lastMeasurementDate: string, heartAge: number, heartAgeRating: string, tenYearCvdRisk: number, tenYearCvdRiskNormal: number, tenYearCvdRiskOptimal: number, heartAgeOptimal: number, rating: object }}
 */
export const calculateHeartAgeParams = healthCheck => {
    const lastMeasurementDate = pickAttribute(["updatedAt"], healthCheck);

    const heartAgeParams = withPercentage(calculateHeartAge(healthCheck));

    return { ...healthCheck, ...heartAgeParams, lastMeasurementDate };
};
