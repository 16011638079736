import { SET_HEALTH_CHECKS } from "constants/actions";
import { createAction } from "redux-actions";
import APIClient from "utilities/APIClient";
import { handleResponse } from "@sisuwellness/utilities/front-end-utils/errors";

// Redux actions
export const setHealthChecks = createAction(SET_HEALTH_CHECKS);

// Redux thunk actions
export function getHealthChecksAPI() {
    return async dispatch => {
        const response = await APIClient.healthChecks.get(true);
        const result = await handleResponse(response, []);
        dispatch(setHealthChecks(result));
        return result;
    };
}
