import {
    SET_PAGINATION_STATS,
    SET_PAGINATION_HEALTH_CHECKS,
    SET_PAGINATION_HEALTH_CHECKS_FETCH_STATUS
} from "constants/actions";
import { createAction } from "redux-actions";
import { isEmpty } from "lodash-es";
import { HealthChecksFetchStatus } from "constants/";
import { fetchHealthChecksForPage, fetchStatsForUserId } from "utilities/resources";

// Redux actions
export const setPaginationStats = createAction(SET_PAGINATION_STATS);
export const setPaginationHealthChecks = createAction(SET_PAGINATION_HEALTH_CHECKS);
export const setPaginationHealthChecksFetchStatus = createAction(SET_PAGINATION_HEALTH_CHECKS_FETCH_STATUS);

/**
 * Redux thunk action to fetch health checks for given page.
 * @param {number} page
 * @param {number} limit
 */
export const setHealthChecksForPage = (page, limit) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { citizen: { userId } = {} } = state.auth;
        const { stats } = state.pagination;

        try {
            dispatch(setPaginationHealthChecksFetchStatus(HealthChecksFetchStatus.inProgress));

            if (isEmpty(stats) && userId) {
                const hcStats = await fetchStatsForUserId({ userId, limit });
                dispatch(setPaginationStats(hcStats));
            }

            const data = await fetchHealthChecksForPage({ page, limit });
            dispatch(setPaginationHealthChecks(data));
            dispatch(setPaginationHealthChecksFetchStatus(HealthChecksFetchStatus.completed));
        } catch (err) {
            dispatch(setPaginationHealthChecksFetchStatus(HealthChecksFetchStatus.error));
        }
    };
};
