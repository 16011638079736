import React from "react";
import PropTypes from "prop-types";

import { Box, Flex, Image } from "rebass";
import styled from "styled-components";

//-----------------------------------------------------------------------------

import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import UpgradeSiSUBtn from "components/CommonComponents/Button/UpgradeSiSUBtn";
import { useTranslation } from "react-i18next";

//-----------------------------------------------------------------------------

const ImapcatContainer = styled(Flex)`
    width: 100%;
    border-radius: 5px;
    background: ${props => props.theme.colours.gradientCSS.shadesOfPurple};
`;

const UnderstandingImpact = ({ metricType = {} }) => {
    const { t } = useTranslation();

    return (
        <ImapcatContainer
            width="100%"
            minHeight="390px"
            flexDirection="column"
            justifyContent="space-between"
            p={["24px", "40px", "56px 48px"]}
        >
            <Flex width="100%" flexDirection={["column", "", "row"]} justifyContent="space-between">
                <Flex mr={["24px", "40px", "40px"]} flexDirection="column">
                    <HeadingLabel fontSize={["20px", "22px", "28px"]} mb="32px">
                        {t(metricType.IMPACT_SECTION.HEADING)}
                    </HeadingLabel>
                    <NormalLabel fontSize={["17px", "17px", "20px"]} mb="1em">
                        {t(metricType.IMPACT_SECTION.SUBHEADING)}
                    </NormalLabel>
                    <NormalLabel fontSize={["15px", "15px", "17px"]}>
                        {t(metricType.IMPACT_SECTION.DESCRIPTION)}
                    </NormalLabel>
                    <Box ml="4px" mt="1em">
                        {t(metricType.IMPACT_SECTION.DESCRIPTION_POINTS).map((point, index) => (
                            <NormalLabel key={index} fontSize={["15px", "15px", "17px"]}>
                                {point}
                            </NormalLabel>
                        ))}
                    </Box>
                </Flex>
                <Flex
                    mt="40px"
                    with="100%"
                    alignItems="center"
                    mr={["0", "0", "23px"]}
                    flexDirection={["column", "row", "column"]}
                    alignSelf={["space-between", "space-between", "center"]}
                    justifyContent={["flex-start", "space-between", "flex-start"]}
                >
                    <Flex
                        justifyContent="center"
                        flexDirection="column"
                        width={["", "", "222px"]}
                        alignItems={["center", "flex-start", "center"]}
                    >
                        <HeadingLabel textAlign="center" fontSize={["20px", "20px", "22px"]}>
                            {t(metricType.PREMIUM_UPGRADE.HEADING)}
                        </HeadingLabel>
                        <UpgradeSiSUBtn my="16px" />
                        <NormalLabel textAlign="center" fontSize={"15px"}>
                            {t(metricType.PREMIUM_UPGRADE.SUBHEADING)}
                        </NormalLabel>
                    </Flex>
                    <Image
                        width="172px"
                        height="66px"
                        mt={["40px", "0", "40px"]}
                        src={metricType.PREMIUM_UPGRADE.LOGO.SRC}
                        alt={metricType.PREMIUM_UPGRADE.LOGO.ALT}
                    />
                </Flex>
            </Flex>
            <NormalLabel mt={["32px", "24px"]} fontSize={"13px"}>
                {t(metricType.FOOTER)}
            </NormalLabel>
        </ImapcatContainer>
    );
};

UnderstandingImpact.propTypes = {
    metricType: PropTypes.object
};

export default UnderstandingImpact;
