import BaseEndPoint from "../BaseEndPoint";

export default class Momentum extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path = "") {
        if (path) {
            path = "/" + path;
        }
        return super.getUrlPath("/momentum" + path);
    }

    /**
     * Calls the validate momentum API endpoint
     *
     * @param {string} responseId
     * @param {string} source
     * @return {Promise<Response>}
     */
    validateMomentumId(employeeId) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "POST",
            data: JSON.stringify({ employeeId })
        };

        return this._send(this.getUrlPath("validateMomentumId"), settings, true);
    }
}
