import { HRA_EMOTIONAL_SCORE, HRA_LIFESTYLE_SCORE, HRA_SOCIAL_SCORE } from "./trend-charts";

const getStaticRecommendations = type => {
    switch (type) {
        case HRA_SOCIAL_SCORE:
            return {
                text: "members_portal_web.constants.recommendations.hra_social_score"
            };

        case HRA_EMOTIONAL_SCORE:
            return {
                text: "members_portal_web.constants.recommendations.hra_emotional_score"
            };

        case HRA_LIFESTYLE_SCORE:
            return {
                text: "members_portal_web.constants.recommendations.hra_lifestyle_score"
            };

        default:
            return null;
    }
};

export default getStaticRecommendations;
