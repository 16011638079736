import React from "react";
import styled, { withTheme } from "styled-components";
import { FlexBase } from "../Cards";
import PropTypes from "prop-types";
import ResponsiveImage from "../ResponsiveImage";

const StyledFlexBase = styled(FlexBase)`
    background: ${props => props.background};
    align-items: center;
    justify-content: center;
`;

const StyledImage = styled(ResponsiveImage)`
    border-radius: ${props => props.theme.defaults.borderRadius};
`;

const ThinImageBanner = ({ schema: { background, src, alt }, theme: { colours = {} } }) => {
    const backgroundColour = background ? background : colours.flat.white.hex;

    return (
        <StyledFlexBase background={backgroundColour}>
            <StyledImage src={src} alt={alt} />
        </StyledFlexBase>
    );
};

ThinImageBanner.propTypes = {
    theme: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired
};

export default withTheme(ThinImageBanner);
