import * as yup from "yup";

// ---------------------------------------------------------------------------

import {
    genderValidation,
    stringNameValidation,
    dateOfBirthValidation,
    mobileValidationByCountryCode,
    emailValidation,
    postalCodeValidation
} from "@sisuwellness/utilities/Validation/UserProfileValidation";
import Password from "@sisuwellness/utilities/Auth/Password";
import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";
import { ERROR_MESSAGES } from "constants/messages";
import { t } from "i18next";

// ----------------------------------------------------------------

const PasswordUtil = new Password();

export const validateSchema = () =>
    yup.object().shape({
        firstName: stringNameValidation(),
        lastName: stringNameValidation(),
        email: emailValidation(),
        password: yup
            .string()
            .required(t("yup_messages.password.required"))
            .test("password-valid", Password.errors.requirement, function(val) {
                if (!val) {
                    return true;
                }
                return val ? Promise.resolve(PasswordUtil.isPasswordValid(val)) : false;
            }),
        dateOfBirth: dateOfBirthValidation().required(t("yup_messages.dob.required")),
        gender: genderValidation(["Male", "Female"]).required(t("yup_messages.gender.required")),
        countryCode: yup.string().required(t("yup_messages.country.required")),
        postalCode: yup.string().when("countryCode", countryCode => {
            if (countryCode) {
                return postalCodeValidation(false, countryCode);
            } else {
                return yup.string().nullable();
            }
        }),
        mobileNumber: yup.string().when("countryCode", countryCode => {
            const options = {
                message: {
                    [COUNTRIES.AUSTRALIA]: t(ERROR_MESSAGES.VALIDATION.MOBILE_NUMBER.AUSTRALIA),
                    [COUNTRIES.UNITED_KINGDOM]: t(ERROR_MESSAGES.VALIDATION.MOBILE_NUMBER.UNITED_KINGDOM)
                }
            };
            return mobileValidationByCountryCode(countryCode, options);
        }),
        termsAndConditions: yup
            .boolean()
            .required(t("yup_messages.terms.required"))
            .oneOf([true], t("yup_messages.terms.required"))
    });
