import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import * as R from "ramda";
import Paragraph from "../Paragraph";

function mapFlatColorFromTheme(theme, color) {
    const mappedColor = R.path(["colours", "flat", color], theme);
    return mappedColor ? mappedColor.hex : null;
}

const StyledPill = styled.span`
    background-color: ${props => mapFlatColorFromTheme(props.theme, props.backgroundColor) || props.backgroundColor};
    color: ${props => mapFlatColorFromTheme(props.theme, props.textColor) || props.textColor};
    height: ${props => `${props.height}px`};
    padding: ${props => props.padding};
    text-align: center;
    border-radius: ${props => `${props.borderRadius}px`};
    display: inline-flex;
    align-items: center;
    font-weight: ${props => props.fontWeight};
`;

const Pill = ({ backgroundColor, textColor, text, fontSize, ...rest }) => (
    <StyledPill backgroundColor={backgroundColor} textColor={textColor} {...rest}>
        <Paragraph size={fontSize}>{text}</Paragraph>
    </StyledPill>
);

Pill.propTypes = {
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    text: PropTypes.string.isRequired,
    borderRadius: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.any
};

Pill.defaultProps = {
    borderRadius: 10,
    height: 27,
    fontWeight: "normal",
    backgroundColor: "white",
    textColor: "black",
    padding: "0px 10px",
    fontSize: "inherit"
};

export default React.memo(Pill);
