import { Header, TextField } from "../../Forms";
import styled, { withTheme } from "styled-components";

import AnchorLink from "../../AnchorLink";
import PrimaryButton from "../../Buttons/primary";
import PropTypes from "prop-types";
import React from "react";
import { media } from "../../../themes";
import { useTranslation } from "react-i18next";

const StyledFormAndErrorsContainer = styled.div`
    min-height: 25vh;
    padding: 0.5em;

    ${media.mobile`
        min-height: 40vh;
    `}
`;

const StyledFormBox = styled.div`
    margin: 2em;
`;

const StyledHeader = styled(Header)`
    font-family: "Proxima Nova Semibold", Arial, sans-serif;
    text-align: center;
`;

const StyledPrimaryButton = styled.div`
    margin-top: 1.8em;

    button {
        width: 100%;
    }
`;

const StyledSubInputText = styled.div`
    position: relative;
    margin-top: -15px;
    text-align: right;
    margin-bottom: 15px;
    text-decoration: underline;

    a {
        color: ${props => props.theme.colours.flat.lightBlue.hex};
        text-decoration: none;
    }
`;

const PasswordForm = ({ theme, handleSubmit, handleForgotPassword, forwardedRef, isLoading, passwordReset }) => {
    const { t } = useTranslation();
    return (
        <StyledFormAndErrorsContainer>
            <StyledFormBox>
                <StyledHeader>{t("web_components.auth.password_form_header")}</StyledHeader>
                <form onSubmit={handleSubmit} data-testid="password-form">
                    <TextField
                        ref={forwardedRef}
                        textSize={theme.text.heading6.sizeRem}
                        name={"password"}
                        value={""}
                        fieldId={"password"}
                        className={"login-password"}
                        type={"password"}
                        autoFocus={true}
                        required
                    />
                    <StyledSubInputText>
                        {!passwordReset && (
                            <AnchorLink href="/" onClick={handleForgotPassword} disabled={isLoading}>
                                {t("web_components.auth.password_form.forgot_password")}
                            </AnchorLink>
                        )}
                    </StyledSubInputText>
                    <StyledPrimaryButton>
                        <PrimaryButton
                            label={t("buttons.log_in")}
                            type={"submit"}
                            disabled={isLoading}
                            borderRadius={"5px"}
                        />
                    </StyledPrimaryButton>
                </form>
            </StyledFormBox>
        </StyledFormAndErrorsContainer>
    );
};

PasswordForm.propTypes = {
    theme: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    passwordReset: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleForgotPassword: PropTypes.func.isRequired,
    forwardedRef: PropTypes.object
};

export default withTheme(React.forwardRef((props, ref) => <PasswordForm {...props} forwardedRef={ref} />));
