import { Link } from "react-scroll";
import { space, typography } from "styled-system";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import { mediaQueryForCss } from "utilities/mediaQuery";
import styled from "styled-components";
import { position } from "styled-system";
import { Box, Text } from "rebass";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";

export const LeftNavContainer = styled(Box)`
    outline: none;
    background: ${props => props.theme.colours.hpNavBackground.hex};
    box-sizing: border-box;
    border-right: ${props => `1px solid ${props.theme.colours.hpPurple100.hex}`};
    ${mediaQueryForCss({
        "border-radius": ["", "", "0px 20px 20px 0px"]
    })}
    ${position}
`;

export const StyledLink = styled(NavLink)`
    width: 100%;
    height: 100%;
    text-decoration: none;
    height: 48px;
    padding-left: 24px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    div div svg path {
        stroke: ${props => props.theme.colours.hpPrimaryPurple.hex};
    }
    :hover {
        background-color: ${props => props.theme.colours.hpPurple100.hex};
    }
    &.active {
        background: ${props => props.theme.colours.hpPrimaryPurple.hex};
        div p {
            color: ${props => props.theme.colours.flat.white.hex};
        }
        div div svg path {
            stroke: ${props => props.theme.colours.flat.white.hex};
        }
    }
    ${space}
`;

export const Title = styled(Text)`
    font-weight: 500;
    color: ${props => props.theme.colours.flat.blackTint.hex};
    margin: ${props => props.margin};
    ${mediaQueryForCss({
        "font-size": ["", "", "", "13px"],
        "font-family": ["", "", "Open Sans"]
    })}
`;

export const Button = styled(PrimaryButton)`
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0;
    border: none;
    padding: 0;
    background-color: transparent;
    :hover {
        background-color: #cedbe5;
    }
`;

export const Field = styled.div`
    display: flex;
    justify-content: space-between;
    vertical-align: middle;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaQueryForCss({})}
`;

export const FieldTitle = styled.p`
    width: 100%;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.14;
    text-align: left;
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    text-align: left;
    :active {
        color: #ffffff;
    }
    ${typography}
    ${mediaQueryForCss({
        "font-family": ["", "", "Open Sans"],
        "font-weight": ["", "", ""]
    })}
`;

export const LeftChild = styled(Box)`
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-top: ${props => props.top};
    display: flex;
    justify-content: center;
    align-items: center;
    ${mediaQueryForCss({
        width: ["", "", "24px", ""],
        height: ["", "", "24px", ""],
        "margin-right": ["", "", "16px"]
    })}
`;

export const StyledMenu = styled(Menu)`
    z-index: 5;
`;

export const UserContainer = styled.div`
    border-top: 1px solid #79a1b5;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px 0px 25px 0px;
    box-sizing: border-box;
`;

export const LogOutLink = styled(Link)`
    width: 100%;
    height: 100%;
    text-decoration: none;
    height: 48px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding-left: 24px;
    div div svg path {
        stroke: ${props => props.theme.colours.hpPrimaryPurple.hex};
    }
    :hover {
        background-color: #cedbe5;
    }
`;

export const StyledBox = styled(Box)`
    border-top: 2px solid ${props => props.theme.colours.hpPurple100.hex};

    & > * + * {
        padding-left: 28px;
    }
`;
