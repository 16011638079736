import React, { useCallback } from "react";
import PropTypes from "prop-types";

import styled, { withTheme } from "styled-components";
import { Box } from "rebass";
import { toPairs } from "ramda";
import chroma from "chroma-js";

// ----------------------------------------------------------------z

import FlexWithGap from "components/Flex";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------

const StyledHorizontalRow = styled(Box)`
    width: 100%;
    height: 34px;
    border-radius: 5px;
`;

const StyledContainer = styled(FlexWithGap)`
    width: 100%;
    height: 100%;
    cursor: pointer;
    border-radius: 3px;
    font-size: 15px;
    color: ${({ theme }) => theme.colours.hpPrimaryPurple.hex};
    background: ${({ isActive }) => (isActive ? "white" : "transparent")};
    box-shadow: ${({ isActive }) => (isActive ? "0 1px 4px 0 rgba(55, 33, 104, 0.25)" : "unset")};
`;

// ----------------------------------------------------------------

const TrendFilter = ({ filters, onChange, activeFilter, ...rest }) => {
    const { t } = useTranslation();

    const handleOnChangeFilter = useCallback(
        key => {
            if (key === activeFilter) return;
            onChange(key);
        },
        [activeFilter]
    );

    return (
        <StyledHorizontalRow
            py="3px"
            px="4px"
            data-testid="trend-filter"
            bg={chroma("#d7d3e1")
                .alpha(0.5)
                .hex()}
            {...rest}
        >
            <FlexWithGap size="100%" gap="4px">
                {toPairs(filters).map(([key, filter], ix) => (
                    <StyledContainer
                        key={ix}
                        ai="center"
                        jc="center"
                        color="hpPrimaryPurple"
                        isActive={key === activeFilter}
                        onClick={() => handleOnChangeFilter(key)}
                        data-testid={`${key === activeFilter ? "active-" : ""}filter`}
                    >
                        {t(filter)}
                    </StyledContainer>
                ))}
            </FlexWithGap>
        </StyledHorizontalRow>
    );
};

// ----------------------------------------------------------------

TrendFilter.propTypes = {
    filters: PropTypes.object,
    activeFilter: PropTypes.string,
    theme: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

// ----------------------------------------------------------------

export default withTheme(TrendFilter);
