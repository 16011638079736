import React, { useState, useEffect } from "react";

import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { Image, Text, Box } from "rebass";
import { useMediaQuery } from "react-responsive";

// ----------------------------------------------------------------------------

import PageLayout from "components/PageLayout";
import LoadingView from "components/LoadingView";
import { PORTAL_IMAGE } from "constants/imagePath";
import { PremiumFeature } from "components/PremiumFeatures";
import { PageTitle, PageDescription } from "components/CommonComponents";

import DataTable from "./DataTable";
import Pagination from "./pagination";
import { StyledCard } from "./styled";
import NoHealthCheckView from "./noHealthCheckView";
import { createQueryForMobile } from "./utilities";
import { setHealthChecksForPage } from "actions/pagination";
import { isHealthChecksFetchStatusInProgress } from "utilities/commonUtils";
import { RECORDS_LIMIT } from "constants/";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------

const HealthChecksView = () => {
    const { t } = useTranslation();
    const isMobileScreen = useMediaQuery(createQueryForMobile());

    const maxLimitToRenderRecords = isMobileScreen ? RECORDS_LIMIT.MOBILE : RECORDS_LIMIT.DESKTOP;

    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const {
        settings = {},
        pagination: { stats = {}, healthChecks: allRecords, healthChecksFetchStatus: status }
    } = state;

    const [currentPage, setCurrentPage] = useState(1);

    const healthChecks = allRecords[currentPage] ?? [];

    useEffect(() => {
        if (isEmpty(healthChecks)) {
            dispatch(setHealthChecksForPage(currentPage, maxLimitToRenderRecords));
        }
    }, [currentPage]);

    if (isHealthChecksFetchStatusInProgress(status)) {
        return <LoadingView title={t("members_portal_web.views.health_checks.loading_text")} />;
    }

    if (isEmpty(healthChecks)) {
        return <NoHealthCheckView />;
    }

    return (
        <PageLayout>
            <PageTitle>{t("members_portal_web.views.health_checks.title")}</PageTitle>
            <PageDescription>{t("members_portal_web.views.health_checks.description")}</PageDescription>

            <Box height={["340px", "397px"]} pb="10px" width="100%" marginTop="33px">
                <PremiumFeature forceRender>
                    <DataTable healthChecks={healthChecks} settings={settings} />
                </PremiumFeature>
            </Box>

            <Pagination
                setCurrentPage={setCurrentPage}
                limit={maxLimitToRenderRecords}
                pageProps={{ stats, currentPageIndex: currentPage, visibleRecords: healthChecks.length }}
            />

            <StyledCard height={["64px", "100px"]} alignItems="center" justifyContent="center" mt="32px">
                <Text fontSize={["15px", "28px"]} fontWeight={["400", "500"]} color="blackTints700">
                    {t("members_portal_web.views.health_checks.brought_to_you")}
                </Text>
                <Image width={["108px", "150px"]} src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`} />
            </StyledCard>
        </PageLayout>
    );
};

export default HealthChecksView;
