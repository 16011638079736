import React from "react";
import PropTypes from "prop-types";

import { Doughnut } from "react-chartjs-2";
import { Box } from "rebass";
import styled from "styled-components";

// ------------------------------------------------------------------------

import { roundedCornerPlugin } from "./plugins";

// ------------------------------------------------------------------------

const StyledContainer = styled(Box)`
    position: relative;

    canvas {
        z-index: 1;
        position: relative;
    }
`;

const DoughnutChart = ({ data = {}, options = {}, plugins = [roundedCornerPlugin], children, ...rest }) => (
    <StyledContainer width="98px" {...rest}>
        <Doughnut
            data={data}
            options={{
                cutout: "87%",
                borderWidth: 0,
                plugins: { tooltip: false },
                layout: { padding: 0 },
                chartArea: { width: "100%", height: "100%" },
                ...options
            }}
            plugins={plugins.filter(x => x)}
        />
        {children}
    </StyledContainer>
);

DoughnutChart.propTypes = {
    ...Box.propTypes,
    data: PropTypes.object,
    options: PropTypes.object,
    plugins: PropTypes.array,
    children: PropTypes.any
};

export default DoughnutChart;
