import "./utilities/InternetExplorerPolyfills";
import "./i18n";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ErrorBoundary from "./components/ErrorBoundary";
import { unregister as unregisterServiceWorker } from "./registerServiceWorker";
import App from "./App";
import { ThemeProvider } from "styled-components";
import { Loading, RefreshRouter } from "@sisuwellness/web-components";
import APIClient from "utilities/APIClient";
import theme from "@sisuwellness/web-components/themes";
import configureStore from "@sisuwellness/connected-components/store";
import { Provider } from "react-redux";
import rootReducer from "reducers";
import thunk from "redux-thunk";
import i18n from "./i18n";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";
import moment from "moment";

const store = configureStore({ rootReducer, middleware: [thunk] });

if (window.Cypress) {
    window.__store__ = store;
}

export const EntryPoint = () => {
    const [i18nLoaded, setI18nLoaded] = useState(false);

    // Wait for translations to load before rendering the app
    // Don't catch the error because we want the ErrorBoundary to catch it
    useEffect(() => {
        i18n.then(() => {
            setI18nLoaded(true);
            moment.locale(i18next.language.toLowerCase());
        });
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <I18nextProvider i18n={i18next}>
                    {i18nLoaded ? (
                        <RefreshRouter checkForUpdate={() => APIClient.appVersion.getMembersPortalAppVersion()}>
                            <App />
                        </RefreshRouter>
                    ) : (
                        <Loading />
                    )}
                </I18nextProvider>
            </ThemeProvider>
        </Provider>
    );
};

// eslint-disable-next-line
ReactDOM.render(
    <ErrorBoundary>
        <EntryPoint />
    </ErrorBoundary>,
    document.getElementById("root")
);

unregisterServiceWorker();
