import { combineReducers } from "redux";

// -------------------------------------------------------------------------

import auth from "@sisuwellness/connected-components/reducers/auth";
import settings from "@sisuwellness/connected-components/reducers/settings";
import healthCheck from "./health-check";
import healthStation from "./health-station";
import pagination from "./pagination";
import deviceLocation from "./device-location";

// -------------------------------------------------------------------------

export default combineReducers({
    healthCheck,
    healthStation,
    auth,
    settings,
    pagination,
    deviceLocation
});
