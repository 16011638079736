import BaseEndPoint from "@sisuwellness/api-client/src/BaseEndPoint";

export default class Programs extends BaseEndPoint {
    constructor(client) {
        super(client);
    }

    get = (url, queryStringParameters = null) => {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString(queryStringParameters);
        return this._send(this.getUrlPath(url) + queryString, settings);
    };

    /**
     * @param {string} value programName
     * @return {Promise} url to get single signOn Url
     */
    getSingleSignOnUrl = programName => this.get("/programs", { programName });
}
