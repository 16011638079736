import React from "react";
import PropTypes from "prop-types";

import { Box } from "rebass";
import { useSelector } from "react-redux";
import { isNil } from "ramda";

// ------------------------------------------------------------------------

import {
    BLOOD_PRESSURE,
    BODY_FAT,
    BODY_MASS_INDEX,
    DIABETES,
    HEART_AGE,
    HEART_RATE,
    QRISK3,
    WEIGHT
} from "constants/trend-charts";
import { AD_SLOT_CODES } from "@sisuwellness/utilities/CampaignAd";
import { MediaQuery } from "@sisuwellness/web-components";
import AdSlotView from "components/AdSlotView";
import HealthMetrics from "utilities/metrics";

import { usePremiumFeature } from "components/PremiumFeatures";
import HealthCard from "components/HealthCard";
import { HeartAgeOutcomeCard, BodyCompositionOutcomeCard } from "components/Templates/OutcomeCards";
import CardStack from "components/Cards/CardStack";
import MuscleMassOutcomeCard from "components/Templates/OutcomeCards/MuscleMass";

// ------------------------------------------------------------------------

const HealthCheckMetrics = ({ healthCheck, campaignId }) => {
    const { isPremium, isOnlyInterested } = usePremiumFeature();
    const { citizen: { data: { muscleMass } = {} } = {} } = useSelector(state => state.auth);

    let { hr, bmi, dia, sys, weight, bodyFatPercent, ausdriskScoreValue, qriskScore } = healthCheck;
    bmi = bmi ? parseFloat(bmi) : null;
    sys = sys ? parseFloat(sys) : null;
    dia = dia ? parseFloat(dia) : null;
    weight = weight ? parseFloat(weight) : null;
    ausdriskScoreValue = ausdriskScoreValue ? parseInt(ausdriskScoreValue, 10) : null;
    bodyFatPercent = bodyFatPercent ? parseFloat(bodyFatPercent) : null;

    const data = [];

    // Add metric cards for ordering
    if (isPremium) {
        data.push(index => (
            <HealthMetrics type={HEART_AGE} healthCheck={healthCheck} key={index}>
                {data => <HeartAgeOutcomeCard order={index} key={index} {...data} isPremium />}
            </HealthMetrics>
        ));
        if (!isNil(muscleMass)) {
            data.push(index => <BodyCompositionOutcomeCard value={healthCheck} order={index} key={index} isPremium />);
            data.push(index => (
                <MuscleMassOutcomeCard key={index} order={index} value={healthCheck} isPremium showRecommendations />
            ));
        }
    }

    if (weight) {
        data.push(index => (
            <HealthMetrics type={WEIGHT} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));
    }

    if (sys && dia)
        data.push(index => (
            <HealthMetrics type={BLOOD_PRESSURE} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));

    if (bmi)
        data.push(index => (
            <HealthMetrics type={BODY_MASS_INDEX} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));

    if (bodyFatPercent)
        data.push(index => (
            <HealthMetrics type={BODY_FAT} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));

    if (hr)
        data.push(index => (
            <HealthMetrics type={HEART_RATE} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));

    if (ausdriskScoreValue)
        data.push(index => (
            <HealthMetrics type={DIABETES} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));

    if (qriskScore)
        data.push(index => (
            <HealthMetrics type={QRISK3} healthCheck={healthCheck} key={index}>
                {data => <HealthCard order={index} metric={data} showRecommendations />}
            </HealthMetrics>
        ));

    if (isOnlyInterested) {
        data.push(index => <HeartAgeOutcomeCard order={index} key={index} />);
        if (!isNil(muscleMass)) {
            data.push(index => <BodyCompositionOutcomeCard order={index} key={index} />);
            data.push(index => <MuscleMassOutcomeCard key={index} order={index} value={healthCheck} />);
        }
    }

    return (
        <CardStack gap="40px">
            {data.map((item, index) => item(index))}
            <MediaQuery devices={["desktop"]}>
                <Box css={{ order: data.length > 1 ? 1 : data.length }}>
                    {/* TODO: Change ad slot views */}
                    <AdSlotView
                        adSlotCode={AD_SLOT_CODES.PORTAL_HEALTH_HUB_TOP_ROW}
                        campaignId={campaignId}
                        criteria={{}}
                    />
                </Box>
            </MediaQuery>
        </CardStack>
    );
};

// ------------------------------------------------------------------------

HealthCheckMetrics.propTypes = {
    healthCheck: PropTypes.shape({
        weight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        bmi: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        ausdriskScoreValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        dia: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        sys: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        bodyFatPercent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        hr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        qriskScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }).isRequired,
    campaignId: PropTypes.number,
    settings: PropTypes.object
};

export default HealthCheckMetrics;
