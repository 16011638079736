import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";

// -------------------------------------------------------------------

import APIClient from "utilities/APIClient";
import LoadingView from "components/LoadingView";
import { Errors } from "@sisuwellness/web-components/components/Forms";
import { usePremiumFeature, withPremium } from "components/PremiumFeatures";
import { USES_LEARN_WORLD } from "./ImproveCardData";
import { useTranslation } from "react-i18next";
// -------------------------------------------------------------------

const Providers = {
    AUTO: "auto",
    LEARNWORLDS: "learnworlds",
    DRUPAL: "drupal"
};

const ImproveLaunch = ({ wants = "", replace = false, provider = Providers.AUTO }) => {
    const { t } = useTranslation();
    const { isPremium } = usePremiumFeature();
    const [errorMessage, setErrorMessage] = useState("");
    const learnWorldsProgram = USES_LEARN_WORLD[wants];

    const getRequestPromise = useCallback(() => {
        if (isPremium && !!learnWorldsProgram) return APIClient.programs.getSingleSignOnUrl(learnWorldsProgram);
        if (!!learnWorldsProgram && provider === Providers.LEARNWORLDS)
            return APIClient.programs.getSingleSignOnUrl(learnWorldsProgram);
        return APIClient.auth.requestImproveUrl(wants);
    }, [isPremium, learnWorldsProgram, provider]);

    const ImproveLaunchStrings = {
        errors: {
            serverUnreachable: "members_portal_web.views.improve.launch_errors.server_unreachable",
            unexpectedError: "members_portal_web.views.improve.launch_errors.unexpected_error"
        }
    };

    useEffect(() => {
        getRequestPromise()
            .then(response => {
                if (response.succeeded()) return response.body();

                response.isNetworkFailure()
                    ? setErrorMessage(t(ImproveLaunchStrings.errors.serverUnreachable))
                    : setErrorMessage(t(ImproveLaunchStrings.errors.unexpectedError));

                return null;
            })
            .then(responseBody => {
                if (!responseBody) return;

                const shouldUseLearnWorld = isPremium && !!learnWorldsProgram;
                const redirectUrl = shouldUseLearnWorld
                    ? responseBody.singleSignOn && responseBody.singleSignOn.singleSignOnUrl
                    : responseBody.redirectUrl;

                if (redirectUrl && redirectUrl.includes("https://")) {
                    replace ? window.location.replace(redirectUrl) : window.location.assign(redirectUrl);
                } else {
                    setErrorMessage(t(ImproveLaunchStrings.errors.unexpectedError));
                }
            });
    }, [APIClient]);

    if (errorMessage) {
        return <Errors>{errorMessage}</Errors>;
    }

    return <LoadingView />;
};

ImproveLaunch.propTypes = {
    wants: PropTypes.string,
    replace: PropTypes.bool,
    provider: PropTypes.string
};

export default withPremium(ImproveLaunch);
