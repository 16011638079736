import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import Heading from "../Heading";
import Paragraph from "../Paragraph";
import { HeartBeat } from "../CSSAnimations";
import { ResponsiveFlex } from "../Layout";

const Container = styled(ResponsiveFlex)`
    color: ${props => props.theme.colours.flat.darkGray.hex};
`;

const StyledImage = styled.img`
    width: 30%;
    max-width: 120px;
    max-height: 120px;
    overflow: hidden;
    animation: ${HeartBeat} 2s ease infinite;
`;

const Loading = ({ title, message, height, ...rest }) => (
    <Container alignItems="center" justifyContent="center" flexDirection="column" height={height} pb="3em" {...rest}>
        <StyledImage src={"/icons/logo-sh.svg"} alt={"logo"} />
        <Heading renderAs="h2" textAlign="center">
            {title}
        </Heading>
        <Paragraph textAlign="center">{message}</Paragraph>
    </Container>
);

Loading.displayName = "Loading";
Loading.propTypes = {
    theme: PropTypes.any.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    height: PropTypes.string
};
Loading.defaultProps = {
    height: "100vh"
};

export default withTheme(Loading);
