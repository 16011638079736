const types = {
    survey: "SURVEY",
    finished: "FINISHED",
    consent: "CONSENT"
};

export default class UserJourneyPageType {
    constructor(schema) {
        this._schema = schema;
    }

    /**
     *
     * @returns {string}
     */
    getCode() {
        return this._schema.code;
    }

    /**
     *
     * @returns {boolean}
     */
    isTypeConsent() {
        return this.getCode() === types.consent;
    }

    /**
     *
     * @returns {boolean}
     */
    isTypeSurvey() {
        return this.getCode() === types.survey;
    }

    /**
     *
     * @returns {boolean}
     */
    isTypeFinished() {
        return this.getCode() === types.finished;
    }
}
