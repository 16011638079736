import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Image } from "rebass";

// ---------------------------------------------------------------------------------

import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import Flex from "components/Flex";
import OverviewCard from "components/Templates/Premium/OverviewCard";
import { hexToRgba } from "utilities/commonUtils";
import { PORTAL_IMAGE } from "constants/imagePath";
import ExtraBreakpointWrapper from "components/ExtraBreakpointWrapper";
import { media } from "@sisuwellness/web-components/themes";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------------------------------

const StyledImageContainer = styled(Flex)`
    z-index: 1;
    width: 117px;
    height: 117px;
    min-width: 117px;
    align-self: center;
    background: ${hexToRgba("white", 0.4)};

    ${media.tablet`
        display: none;
    `}
`;

const StyledHeadingTag = styled(HeadingLabel)`
    font-size: 13px;
    font-weight: bold;
    opacity: 0.7;
    text-align: right;

    ${media.mobile`
        display: none;
    `}
`;

// --------------------------------------------------------------------------------

const OverviewCardUI = ({ children, icon, heading, text, ...rest }) => {
    const { t } = useTranslation();

    return (
        <OverviewCard {...rest}>
            <StyledImageContainer mr="24px" ai="center" jc="center">
                <Image src={`${PORTAL_IMAGE}/premium/${icon}`} alt={icon} />
            </StyledImageContainer>
            <Flex fd="column" size="100%" jc="space-between" alignSelf="flex-start" style={{ zIndex: 1 }}>
                <Flex width="100%" jc="space-between">
                    <ExtraBreakpointWrapper screen="macbook" fontSize="24px">
                        <HeadingLabel fontSize={["20px", "", "28px"]} fontWeight="intermediate">
                            {heading}
                        </HeadingLabel>
                    </ExtraBreakpointWrapper>
                    <StyledHeadingTag>{t("words.premium")}</StyledHeadingTag>
                </Flex>
                <NormalLabel fontSize={["11px", "", "15px"]} mb="10px">
                    {text}
                </NormalLabel>
                {children}
            </Flex>
        </OverviewCard>
    );
};

OverviewCardUI.propTypes = {
    ...OverviewCard.propTypes,
    children: PropTypes.any,
    icon: PropTypes.string,
    heading: PropTypes.string,
    text: PropTypes.string
};

export default OverviewCardUI;
