import React from "react";
import PropTypes from "prop-types";

// ------------------------------------------------------------------------------------------------

import { baseTheme } from "@sisuwellness/web-components/theme";

// ------------------------------------------------------------------------------------------------

const CustomPointSymbol = ({ currentAge, datum }) => {
    return datum.x === currentAge ? <circle r={5} fill={baseTheme.colours.hpPrimaryPurple.hex} /> : <></>;
};

CustomPointSymbol.propTypes = {
    datum: PropTypes.object,
    currentAge: PropTypes.number
};

export default CustomPointSymbol;
