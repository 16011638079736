import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.colours.flat.white.hex};
    background: ${props => props.theme.colours.flat.rose.hex};
    visibility: ${props => (props.hasText ? "visible" : "hidden")};
    padding: 5px 20px;
    margin-bottom: 5px;
    border-radius: ${props => (props.rounded ? props.theme.defaults.borderRadius : 0)};
`;

const StyledError = styled.p`
    margin: 5px 0;
`;

const Errors = ({ children, rounded, ...rest }) => {
    let errors = [];
    if (Array.isArray(children)) {
        for (const [index, value] of children.entries()) {
            errors.push(<StyledError key={"error-" + index}>{value}</StyledError>);
        }
    } else {
        errors.push(<StyledError key={"error-1"}>{children}</StyledError>);
    }

    return (
        <StyledContainer hasText={children} rounded={rounded} data-testid={"error-container"} {...rest}>
            {errors}
        </StyledContainer>
    );
};

Errors.propTypes = {
    children: PropTypes.node.isRequired,
    rounded: PropTypes.bool
};

Errors.defaultProps = {
    rounded: false
};

export default Errors;
