import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Heading } from "@sisuwellness/web-components";

const StyledTitle = styled(Heading)`
    font-family: "Proxima Nova Semibold", Arial, sans-serif;
    color: ${props => props.theme.colours.flat.black.hex};
`;

const PageTitle = ({ theme, children, ...rest }) => (
    <StyledTitle
        data-printid="page-title"
        renderAs="h2"
        size={[theme.text.heading6.sizeRem, theme.text.heading4.sizeRem]}
        m={["20px 0px 10px", "30px 0px 10px"]}
        textAlign={["center", "left"]}
        {...rest}
    >
        {children}
    </StyledTitle>
);

PageTitle.displayName = "Page Title";
PageTitle.propTypes = {
    theme: PropTypes.object,
    children: PropTypes.node
};

export default withTheme(PageTitle);
