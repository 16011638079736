export const RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE = { VALUE: 0, ID: "No Resistance Training" };
export const RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS = { VALUE: 0.75, ID: "Resistance Training 1-2" };
export const RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS = { VALUE: 1.65, ID: "Resistance Training 2-4" };

const RATE_OF_DECREASE_PER_YEAR_FROM_AGE_0_TO_29 = 0;
const RATE_OF_DECREASE_PER_YEAR_FROM_AGE_30_TO_65 = 0.55;
const RATE_OF_DECREASE_PER_YEAR_FOR_AGE_GT_65 = 1.05;

/**
 * Calculate Muscle Mass for a given age
 * @param {number} age
 * @param {number} muscleMass
 * @param {number} rateOfMuscleIncreaseByTrainingSessions
 * @return {number}
 */
const calculateMuscleMassForGivenAge = (age, muscleMass, rateOfMuscleIncreaseByTrainingSessions) => {
    let rateOfMuscleDecreaseByAge = 0;
    if (age < 30) rateOfMuscleDecreaseByAge = RATE_OF_DECREASE_PER_YEAR_FROM_AGE_0_TO_29;
    else if (age >= 30 && age <= 65) rateOfMuscleDecreaseByAge = RATE_OF_DECREASE_PER_YEAR_FROM_AGE_30_TO_65;
    else rateOfMuscleDecreaseByAge = RATE_OF_DECREASE_PER_YEAR_FOR_AGE_GT_65;

    const muscleMassForGivenAge =
        muscleMass -
        RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE.VALUE -
        rateOfMuscleDecreaseByAge +
        rateOfMuscleIncreaseByTrainingSessions;

    if (muscleMassForGivenAge < 10) return 10;
    if (muscleMassForGivenAge > 50) return 50;

    return muscleMassForGivenAge;
};

/**
 * Muscle Mass Simulator Calculator
 * @param {number} age
 * @param {number} muscleMass
 * @param {number} rateOfMuscleIncreaseByTrainingSessions
 * @param {number} ageSpan Simulate muscle mass for x number of years
 * @return {{ age: number, muscleMass: number }[]}
 */
export default (age, muscleMass, rateOfMuscleIncreaseByTrainingSessions, ageSpan) => {
    const aggregator = [{ age, muscleMass }];

    for (let n = 1; n <= ageSpan; n++) {
        let tempAge = age + n;
        let tempMuscleMass = calculateMuscleMassForGivenAge(
            tempAge,
            aggregator[n - 1].muscleMass,
            rateOfMuscleIncreaseByTrainingSessions
        );

        aggregator.push({ age: tempAge, muscleMass: tempMuscleMass });
    }

    return aggregator;
};
