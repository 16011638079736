import routes from "constants/routes";

export default {
    myAccount: {
        path: `${routes.myAccount.home}`,
        title: "members_portal_web.components.left_nav_bar.my_account"
    },

    logOut: {
        path: `${routes.root}`,
        title: "words.log_out"
    }
};
