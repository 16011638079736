import React, { Component } from "react";
import PropTypes from "prop-types";
import QuestionTitle from "./Title";
import Help from "./Help";
import styled from "styled-components";
import { Errors } from "../../Forms";
import theme from "../../../themes";
import QuestionIcon from "./QuestionIcon";
import Date from "../../Forms/Date";
import { withTranslation } from "react-i18next";

const StyledContainer = styled.div`
    display: block;
`;

class DateOfBirthQuestion extends Component {
    static defaultProps = {
        touchKeyboard: false
    };

    constructor(props) {
        super(props);

        this.dayInputRef = React.createRef();
        this.monthInputRef = React.createRef();
        this.yearInputRef = React.createRef();

        this.onDayInputFocus = this.onDayInputFocus.bind(this);
        this.onMonthInputFocus = this.onMonthInputFocus.bind(this);
        this.onYearInputFocus = this.onYearInputFocus.bind(this);
    }

    componentDidMount() {
        if (this.props.autoFocus && this.props.onInputFocus) {
            this.props.onInputFocus(this.dayInputRef.current);
        }
    }

    onDayInputFocus() {
        if (this.props.onInputFocus && this.props.touchKeyboard) {
            this.props.onInputFocus(this.dayInputRef.current);
        }
    }

    onMonthInputFocus() {
        if (this.props.onInputFocus && this.props.touchKeyboard) {
            this.props.onInputFocus(this.monthInputRef.current);
        }
    }

    onYearInputFocus() {
        if (this.props.onInputFocus && this.props.touchKeyboard) {
            this.props.onInputFocus(this.yearInputRef.current);
        }
    }

    render() {
        let getIcon = this.props.model.getQuestionIcon();
        let hasErrors = this.props.errors && this.props.errors.length;

        let helpText = null;
        if (this.props.model.getHelpText()) {
            helpText = <Help>{this.props.t(this.props.model.getHelpText())}</Help>;
        }

        let validationErrors = null;
        if (hasErrors) {
            validationErrors = <Errors rounded={true}>{this.props.t(this.props.errors)}</Errors>;
        }

        return (
            <StyledContainer data-testid={"survey-question-dob"}>
                {validationErrors}
                <QuestionIcon type={theme.icons.types[getIcon]} />
                <QuestionTitle hasErrors={hasErrors} htmlFor={this.props.id}>
                    {this.props.t(this.props.model.getDisplayText())}
                </QuestionTitle>
                {helpText}
                <Date
                    id={this.props.id}
                    dayInputRef={this.dayInputRef}
                    monthInputRef={this.monthInputRef}
                    yearInputRef={this.yearInputRef}
                    onDayInputFocus={this.onDayInputFocus}
                    onMonthInputFocus={this.onMonthInputFocus}
                    onYearInputFocus={this.onYearInputFocus}
                    onValueChange={this.props.onValueChange}
                    value={this.props.value}
                />
            </StyledContainer>
        );
    }
}

DateOfBirthQuestion.displayName = "Date of birth";
DateOfBirthQuestion.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    touchKeyboard: PropTypes.bool,
    onInputFocus: PropTypes.func,
    value: PropTypes.string,
    errors: PropTypes.any,
    t: PropTypes.func
};

export default withTranslation()(DateOfBirthQuestion);
