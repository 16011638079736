import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { PrimaryButton, SecondaryButton } from "../Components/Buttons";
import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { isEmpty } from "ramda";
import { Text, Flex as Flexbox, Box } from "rebass";
import styled from "styled-components";
import { position } from "styled-system";
import { ProgressBar as Progress } from "react-bootstrap";
import { layout, space, color } from "styled-system";

import { HRAStateContext } from "@sisuwellness/members-portal-web/src/components/Context";
import { useTranslation } from "react-i18next";

const Container = styled(Box)(position);

const Flex = styled(Flexbox)(position);

const ProgressContainer = styled(Box)`
    position: relative;
`;

const ProgressBar = styled(Progress)`
    border-radius: 4px;
    box-shadow: none;
    ${layout}
    ${space}
    ${color}

    .progress-bar {
        box-shadow: none;
        background: linear-gradient(to right, #8cddcd, #b5e8f8) no-repeat;
    }
`;

const Label = styled(Text)`
    top: 45%;
    font-size: 12px;
    position: absolute;
    transform: translateY(-50%);
`;

const Navigation = ({ survey, children, onBackClick, onNextClick, backButtonDisabled, model }) => {
    const { t } = useTranslation();
    const [errors, setErrors] = useState({});

    const onSubmit = value => {
        onNextClick({ answer: value });
    };

    const userJourney = survey.getUserJourneyPage().getUserJourney();
    const currentQuestionId = model.getQuestionId();
    const { getHraState, setPreviousQuestion } = useContext(HRAStateContext);
    const { now, currentIndex } = getHraState(userJourney, currentQuestionId);

    const handleOnBackClick = () => {
        setPreviousQuestion(currentQuestionId);
        onBackClick();
    };

    return (
        <Container minHeight="80vh" position="relative" color={theme.colours.hpPrimaryPurple.hex} mb="160px">
            <Text fontSize={["17px", "20px"]} textAlign="left">
                {t("web_components.survey.hra_restyle.navigation.text")}
            </Text>
            <ProgressContainer>
                <ProgressBar now={now} height="20px" mt={["8px", "24px"]} bg="white" />
                <Label ml={["16px", "32px"]}>
                    {t("words.question")} {currentIndex}
                </Label>{" "}
            </ProgressContainer>
            <Flex flexDirection="column">
                <Container mt={["4px", "12px"]} minHeight="450px">
                    {children({ setErrors, onSubmit })}
                </Container>
                <Text fontSize="16px" fontWeight="500">
                    <Flex width="100%" px={["0px", "", "116px"]} justifySelf="flex-end" justifyContent="space-between">
                        <SecondaryButton
                            onClick={handleOnBackClick}
                            bg="transparent"
                            disabled={backButtonDisabled}
                            width={window.innerWidth < 800 ? "125px" : "316px"}
                        >
                            {t("words.back")}
                        </SecondaryButton>
                        <PrimaryButton
                            width={window.innerWidth < 800 ? "125px" : "316px"}
                            type="submit"
                            form="field-form"
                            disabled={!isEmpty(errors) && model.isRequired()}
                        >
                            {model.isRequired() ? "Next" : "Skip"}
                        </PrimaryButton>
                    </Flex>
                </Text>
            </Flex>
        </Container>
    );
};

Navigation.propTypes = {
    survey: PropTypes.shape({
        getDisplayName: PropTypes.func,
        getUserJourneyPage: PropTypes.func
    }),
    children: PropTypes.func,
    onBackClick: PropTypes.func,
    onNextClick: PropTypes.func,
    backButtonDisabled: PropTypes.bool,
    model: PropTypes.shape({
        getQuestionId: PropTypes.func,
        isRequired: PropTypes.func
    })
};

export default Navigation;
