import BaseEndPoint from "../BaseEndPoint";

export default class Root extends BaseEndPoint {
    constructor(client) {
        super(client);
    }

    public() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("/"), settings);
    }
}
