import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Flex } from "rebass";

const StyledBannerContainer = styled(Flex)`
    position: ${props => props.position};
    ${props => props.top && `top: ${props.top};`}
    color: ${props => props.theme.colours.flat.white.hex};
    border-radius: 5px;
`;

const ActionBanner = ({ children, ...rest }) => {
    return <StyledBannerContainer {...rest}>{children}</StyledBannerContainer>;
};

ActionBanner.propTypes = {
    children: PropTypes.node.isRequired
};

ActionBanner.defaultProps = {
    position: "relative",
    p: "2em",
    alignItems: "center",
    justifyContent: "center",
    mx: "auto",
    backgroundColor: "black"
};

export default ActionBanner;
