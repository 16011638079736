import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setAuthenticationStatus, setCitizen } from "../../actions/auth";
import { compose } from "ramda";
import { Redirect, withRouter } from "react-router-dom";
import { Loading } from "@sisuwellness/web-components";
import { curry, pathOr } from "ramda";

function withAuthentication(
    {
        APIClient,
        AuthUtil: Auth,
        authRoles = [],
        authRoutes = {
            root: "/",
            login: "/login"
        },
        defaultRoute = "/",
        extraRoutes = []
    },
    RouteComponent
) {
    async function verifyAuthStatus(authRoles) {
        const isAuthenticated = await Auth.isAuthenticated();
        const defaultState = {
            verified: false,
            authenticating: false,
            hasValidRole: null,
            citizen: {}
        };

        if (isAuthenticated) {
            const response = await APIClient.citizen.get();
            // Reject if roles is within specified roles
            if (response.succeeded()) {
                const citizen = await response.body();
                const isValidRole =
                    // Do not check for role if authRoles is empty
                    authRoles.length == 0 ||
                    (Array.isArray(citizen.roles) && authRoles.some(role => citizen.roles.includes(role)));

                if (isValidRole) {
                    return {
                        ...defaultState,
                        verified: true,
                        hasValidRole: true,
                        citizen
                    };
                } else {
                    return {
                        ...defaultState,
                        hasValidRole: false
                    };
                }
            }
            // Reject if failed to get citizen
            else {
                return defaultState;
            }
        }
        // Reject if is not authenticated
        else {
            return defaultState;
        }
    }

    return ({ authenticated, setAuthenticationStatus, setCitizen, location, match, ...rest }) => {
        function addLoginSearchParamBaseOnRole(hasValidRole) {
            const search = location.search;
            const loginSearchParam = hasValidRole == null ? "token_expired=true" : "invalid_access=true";
            return search ? `${search}&${loginSearchParam}` : `?${loginSearchParam}`;
        }

        const [authState, setAuthState] = useState({
            verified: authenticated,
            authenticating: !authenticated,
            hasValidRole: null
        });

        const { verified, authenticating, hasValidRole } = authState;

        // Clean up token and mark unauthenticated state
        function unauthenticate(authState) {
            Auth.unauthenticate();
            setAuthenticationStatus(false);
            setAuthState(authState);
        }

        useEffect(() => {
            async function verify() {
                if (!verified) {
                    const isAuthenticated = await verifyAuthStatus(authRoles);
                    if (isAuthenticated.verified) {
                        // Mark authentication status to authenticated in Redux, all other view/components will react to this
                        setCitizen(isAuthenticated.citizen);
                        setAuthenticationStatus(true);
                    } else {
                        unauthenticate(isAuthenticated);
                    }
                }
            }

            verify();
        }, []);

        if (authenticated || (verified && !authenticating)) {
            // Redirect to root view route if authenticated and enter login/identify view

            if (Object.values(authRoutes).includes(location.pathname)) {
                return (
                    <Redirect
                        to={
                            pathOr(null, ["state", "from"], location) || {
                                ...location,
                                pathname: defaultRoute
                            }
                        }
                    />
                );
            } else {
                return <RouteComponent {...rest} />;
            }
        } else if (!verified && authenticating) {
            return <Loading title="Logging in securely..." px="1em" />;
        } else {
            // Keep the route if the current route is auth route or Find health Station or if route is not-found
            if (
                Object.values(authRoutes).includes(location.pathname) ||
                extraRoutes.includes(location.pathname) ||
                pathOr(null, ["params", "notFound"], match) // return if not found
            ) {
                return <RouteComponent {...rest} />;
            } else {
                return (
                    <Redirect
                        to={{
                            state: {
                                from: location
                            },
                            pathname: authRoutes.root,
                            search: addLoginSearchParamBaseOnRole(hasValidRole)
                        }}
                    />
                );
            }
        }
    };
}

const mapStateToProps = ({ auth: { authenticated } }) => ({ authenticated });
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setAuthenticationStatus,
            setCitizen
        },
        dispatch
    );

export default curry(
    compose(
        withRouter,
        connect(
            mapStateToProps,
            mapDispatchToProps
        ),
        withAuthentication
    )
);
