import styled from "styled-components";
import { media } from "../../themes";

const defaultMargin = "0.25em";

const StyledChoice = styled.button`
    border: 3px solid ${props => props.theme.colours.flat.darkGray.hex};
    color: ${props => props.theme.colours.flat.darkGray.hex};
    background-color: ${props => props.theme.colours.flat.white.hex};
    margin: ${() => {
        return defaultMargin;
    }};
    padding: 0.8em 1.2em;
    font-size: ${props => props.fontSize || props.theme.text.heading4.sizeRem};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: ${props => props.theme.defaults.borderRadius};
    width: ${props => props.width};

    ${media.mobile`
        width: 90%;
    `}

    @media(hover: hover) {
        :hover {
            background: ${props => props.theme.colours.flat.blue.hex};
            color: #fff;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            border: 3px solid ${props => props.theme.colours.flat.blue.hex};
            width: ${props => props.width};
        }
    }

    :active {
        background: ${props => props.theme.colours.flat.darkBlue.hex};
        border: 3px solid ${props => props.theme.colours.flat.darkBlue.hex};
        color: ${props => props.theme.colours.flat.white.hex};
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
        width: ${props => props.width};
    }

    :focus {
        outline: none;
    }

    height: inherit;
`;

const StyledChoiceSelected = styled(StyledChoice)`
    background: ${props => props.theme.colours.flat.darkBlue.hex};
    color: ${props => props.theme.colours.flat.white.hex};
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
    ${media.mobile`
        width: 90%;
    `}
`;

export { StyledChoice, StyledChoiceSelected };
