import React from "react";
import { path } from "ramda";
import { Cell, Header } from "./commonElements";
import { t } from "i18next";

const handleCellConstruct = row => {
    const sys = path(["original", "sys"], row);
    const dia = path(["original", "dia"], row);
    const value = sys && dia ? `${sys}/${dia}` : null;

    return <Cell row={row} value={value} unit="mmHg" />;
};

export const bloodPressureDef = iconTypes => ({
    minWidth: 92,
    accessor: d => d,
    id: "bloodPressure",
    Cell: handleCellConstruct,
    Header: () => <Header label={t("words.blood_pressure")} iconType={iconTypes.bloodPressure} />
});
