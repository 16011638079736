import React from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Loading } from "@sisuwellness/web-components";
import BaseMemberViewWithoutMenu from "components/BaseMemberViewWithoutMenu";
import { useTranslation } from "react-i18next";

const LoadingViewWithoutMenu = ({ title, message }) => {
    const { t } = useTranslation();

    const loadingMessage = message || t("members_portal_web.components.loading_value.message");
    const loadingTitle = title || t("words.loading") + "...";

    return (
        <BaseMemberViewWithoutMenu loading="true">
            <Loading title={loadingTitle} message={loadingMessage} height={"80vh"} />
        </BaseMemberViewWithoutMenu>
    );
};

LoadingViewWithoutMenu.displayName = "LoadingViewWithoutMenu";
LoadingViewWithoutMenu.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
};

export default withTheme(LoadingViewWithoutMenu);
