import { Auth as BaseAuth } from "@sisuwellness/utilities";
import APIClient from "./APIClient";

export class Auth extends BaseAuth {
    /**
     * @param {Storage} storageInterface
     * @param {APIClient} APIClient
     */
    constructor(storageInterface, APIClient) {
        super(APIClient);
        this.storageInterface = storageInterface;
    }

    _setValue(key, value) {
        let result = this.storageInterface.setItem(key, value);
        return Promise.resolve(result);
    }

    _getValue(key) {
        let value = this.storageInterface.getItem(key);
        return Promise.resolve(value);
    }

    _removeValue(key) {
        let result = this.storageInterface.removeItem(key);
        return Promise.resolve(result);
    }
}

const auth = new Auth(window.localStorage, APIClient);
export default auth;
