import APIClient from "utilities/APIClient";
import { modifyResposePromise } from "utilities/commonUtils";

/**
 * Fetch Health Check Stats for the User Id
 *
 * @param {object} param
 * @param {number} param.userId
 * @param {number} param.limit
 * @return {Promise<{ total: number, pages: number }>}
 */
export const fetchStatsForUserId = ({ userId, limit }) =>
    modifyResposePromise(APIClient.healthChecks.getStats({ userId, limit }));

/**
 * Fetch Health checks for given page index
 *
 * @param {number} page
 * @return {Promise<{ page: number, healthChecks: [] }>}
 */
export const fetchHealthChecksForPage = async ({ page, limit }) => ({
    page,
    healthChecks: await modifyResposePromise(APIClient.healthChecks.get(true, { page, limit }))
});
