import React from "react";
import styled from "styled-components";
import { border, shadow, position, compose } from "styled-system";
import { Flex } from "rebass";
import { theme } from "../../theme";

const StyledBadge = styled(Flex)(
    compose(
        border,
        shadow,
        position
    )
);

export const Badge = ({ children, bg = theme.portalColor.hpPrimaryPurple.hex, size = "38px", ...rest }) => {
    return (
        <StyledBadge bg={bg} size={size} borderRadius="50%" {...rest}>
            {children}
        </StyledBadge>
    );
};

Badge.propTypes = {
    ...Flex.propTypes
};

export default Badge;
