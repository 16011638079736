import React from "react";
import { path, pathOr } from "ramda";
import { formatDate } from "utilities/commonUtils";
import { Header, LinkCell, routeToSpecificPHC } from "./commonElements";
import { t } from "i18next";

const handleCellConstruct = row => {
    const date = pathOr(path(["original", "createdAt"], row), ["value"], row);
    const value = formatDate(date, "DD MMM YYYY");
    return <LinkCell data-testid="table-cell-date" text={value} row={row} href={routeToSpecificPHC(row)} />;
};

export const placeAndDateDef = () => ({
    minWidth: 92,
    id: "left-side-row",
    Cell: handleCellConstruct,
    Header: () => <Header label={t("words.date_recorded")} />
});
