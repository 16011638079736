import BaseValidator from "./BaseValidator";

export default class NumberValidator extends BaseValidator {
    _getDefaultOptions() {
        return {
            min: null,
            max: null,
            numberTypesOnly: false
        };
    }

    /**
     *
     * @param value
     * @return {Array}
     */
    validate(value) {
        let errors = [];
        let valueAsNumber = Number(value);

        // empty array is converted to 0
        if (isNaN(valueAsNumber) || Array.isArray(value)) {
            errors.push(value + " is not a valid number");
        } else {
            if (this._options.numberTypesOnly && typeof value !== "number") {
                errors.push("must be a explicitly of type number");
            }

            if (this._options.min !== null && valueAsNumber < this._options.min) {
                errors.push("Please enter a number that is at least " + this._options.min);
            }

            if (this._options.max !== null && valueAsNumber > this._options.max) {
                errors.push("Please enter a number that is no more than " + this._options.max);
            }
        }

        return errors;
    }
}
