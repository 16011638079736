import styled from "styled-components";
import Flex from "components/Flex";

// ------------------------------------------------------------------------

export const SectionHeaders = styled(Flex).attrs({ ai: "center" })`
    border-radius: 5px;
    border: solid 1px ${({ theme }) => theme.colours.hpDarkPurpleTints700.hex};
    background-color: ${({ theme }) => theme.colours.hpDarkPurple.hex};
`;

// ------------------------------------------------------------------------

export * from "./DataCell";
export * from "./DifferenceLabel";
export * from "./InformationModal";
