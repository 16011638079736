import BaseRule from "../BaseRule";
import { NumberValidator, Validation } from "../../Validation";

export default class AnswerNumberRule extends BaseRule {
    /**
     * Checks to see if all answers are within the required number range
     */
    matches() {
        /** @type {Answer[]} */
        const answers = this._options.response.getAnswersForQuestionByCode(this._options.questionCode);

        const minimumComparison = this._options.minimum ? this._options.minimum : null;
        const maximumComparison = this._options.maximum ? this._options.maximum : null;

        let matches = false;

        let validation = new Validation();
        validation.addValidator(
            new NumberValidator({
                min: minimumComparison,
                max: maximumComparison
            })
        );

        if (answers !== null) {
            let passCount = 0;

            for (let answer of answers) {
                if (validation.isValid(answer.getRawAnswer())) {
                    passCount++;
                }
            }

            // all answers were valid
            matches = answers.length === passCount;
        }

        return matches;
    }
}
