import BaseEndPoint from "../BaseEndPoint";

export default class Tokens extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/tokens/" + path);
    }

    validateToken(token, context) {
        return this.post("validateToken", { token, context });
    }

    validateUserToken(token, context) {
        return this.post("validateUserToken", { token, context });
    }

    consumeToken(token) {
        return this.post("consumeToken", { token });
    }
}
