import InvalidArgumentError from "../Errors/InvalidArgumentError";
import { NumberValidator, Validation } from "../Validation";

export default class BodyMassIndex {
    /**
     * Returns the BMI for the given height and weight
     *
     * @param height in centimetres
     * @param weight in kilograms
     * @returns {number}
     */
    static getBMI(height, weight) {
        const heightValidation = new Validation();
        heightValidation.addValidator(new NumberValidator({ min: 1, numberTypesOnly: true }));
        if (heightValidation.validate(height, true).length) {
            throw new InvalidArgumentError("Height must be a valid number greater than 0");
        }

        const weightValidation = new Validation();
        weightValidation.addValidator(new NumberValidator({ min: 1, numberTypesOnly: true }));
        if (weightValidation.validate(weight, true).length) {
            throw new InvalidArgumentError("Weight must be a valid number greater than 0");
        }

        let heightInMetres = height / 100;
        return weight / (heightInMetres * heightInMetres);
    }

    /**
     * Returns a weight (in kg) for a given height and BMI
     * Useful for calculating ideal weight for desired BMI
     *
     * @param height
     * @param bmi
     * @returns {number}
     */
    static getWeightForHeightAndBMI(height, bmi) {
        const heightValidation = new Validation();
        heightValidation.addValidator(new NumberValidator({ min: 1, numberTypesOnly: true }));
        if (heightValidation.validate(height, true).length) {
            throw new InvalidArgumentError("Height must be a valid number greater than 0");
        }

        const bmiValidation = new Validation();
        bmiValidation.addValidator(new NumberValidator({ min: 1, numberTypesOnly: true }));
        if (bmiValidation.validate(bmi, true).length) {
            throw new InvalidArgumentError("BMI must be a valid number greater than 0");
        }

        let heightInMetres = height / 100;
        return bmi * (heightInMetres * heightInMetres);
    }
}
