import React from "react";
import {
    RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE,
    RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS,
    RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS
} from "@sisuwellness/utilities/Guidelines/MuscleMass";
import { theme } from "@sisuwellness/web-components";

const Line = ({ series, lineGenerator, xScale, yScale }) => {
    const stroke = theme.colours.hpAmethystSmoke.hex;

    const mapLineStyle = {
        [RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE.ID]: { stroke, strokeWidth: 3 },
        [RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS.ID]: {
            stroke: theme.colours.hpPrimaryPurple.hex,
            strokeDasharray: "24",
            strokeWidth: 1
        },
        [RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS.ID]: { stroke, strokeWidth: 2, strokeDasharray: "6" }
    };

    return series.map(({ id, data, color }) => (
        <path
            key={id}
            fill="none"
            stroke={color}
            d={lineGenerator(data.map(d => ({ x: xScale(d.data.x), y: yScale(d.data.y) })))}
            style={mapLineStyle[id]}
        />
    ));
};

export default Line;
