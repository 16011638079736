import CoreUtils from "../../core/coreUtils";
import { Answers } from "../../UserJourney/UserJourneyMap";

const totalRiskLevel = 5;

const ranges = {
    noRisk: {
        key: "noRisk",
        label: "ranges.no_risk",
        range: "0"
    },
    low: {
        key: "low",
        label: "ranges.low_risk",
        range: "1 - 4"
    },
    elevated: {
        key: "elevated",
        label: "ranges.elevated_risk",
        range: "5 - 7"
    },
    high: {
        key: "high",
        label: "ranges.high_risk",
        range: "8 - 10"
    },
    veryHigh: {
        key: "veryHigh",
        label: "ranges.very_high_risk",
        range: "11 - 12"
    }
};

const SisuAlcoholGuidelineDefinition = [
    {
        min: 11,
        max: 12,
        key: "veryHigh",
        label: "Very High Risk",
        rangeInfo: "11-12",
        ranges,
        riskLevel: 5 / (totalRiskLevel + 1)
    },
    {
        min: 8,
        max: 10,
        key: "high",
        label: "High Risk",
        rangeInfo: "8-10",
        ranges,
        riskLevel: 4 / (totalRiskLevel + 1)
    },
    {
        min: 5,
        max: 7,
        key: "elevated",
        label: "Elevated Risk",
        rangeInfo: "5-7",
        ranges,
        riskLevel: 3 / (totalRiskLevel + 1)
    },
    {
        min: 1,
        max: 4,
        key: "low",
        label: "Low Risk",
        rangeInfo: "1-4",
        ranges,
        riskLevel: 2 / (totalRiskLevel + 1)
    },
    {
        min: 0,
        max: 0,
        key: "noRisk",
        label: "No Risk",
        rangeInfo: "0",
        ranges,
        riskLevel: 1 / (totalRiskLevel + 1)
    }
];

/**
 * @param score
 * @return {{min: number, max: number, rangeInfo: string, label: string, key: string} | {min: number, max: number, rangeInfo: string, label: string, key: string} | {min: number, max: number, rangeInfo: string, label: string, key: string} | {min: number, max: number, rangeInfo: string, label: string, key: string} | {min: number, max: number, rangeInfo: string, label: string, key: string}}
 */
const getGuideline = score => SisuAlcoholGuidelineDefinition.find(gl => CoreUtils.isWithin(score, gl.min, gl.max));

const RiskName = "Alcohol Risk";
const RiskCode = "ALCOHOL_RISK";
const GuidelineName = "SiSU Alcohol Guideline";
const GuidelineCode = "SISU_ALCOHOL_GUIDELINE";

/**
 * A map of question/answer and a point attribution
 * @type {{alcoholTypical: [{answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}], alcoholBinge: [{answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}], alcoholFrequency: [{answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}, {answer: string, points: number}]}}
 */
const scoreMap = {
    alcoholFrequency: [
        { answer: Answers.AlcoholFrequency.never, points: 0 },
        { answer: Answers.AlcoholFrequency.monthlyOrLess, points: 1 },
        { answer: Answers.AlcoholFrequency.twoToFourPerMonth, points: 2 },
        { answer: Answers.AlcoholFrequency.twoToThreePerWeek, points: 3 },
        { answer: Answers.AlcoholFrequency.fourPlusPerWeek, points: 4 }
    ],
    alcoholTypical: [
        { answer: Answers.AlcoholTypical.oneOrTwo, points: 0 },
        { answer: Answers.AlcoholTypical.threeOrFour, points: 1 },
        { answer: Answers.AlcoholTypical.fiveOrSix, points: 2 },
        { answer: Answers.AlcoholTypical.sevenToNine, points: 3 },
        { answer: Answers.AlcoholTypical.tenOrMore, points: 4 }
    ],
    alcoholBinge: [
        { answer: Answers.AlcoholBinge.never, points: 0 },
        { answer: Answers.AlcoholBinge.lessThanMonthly, points: 1 },
        { answer: Answers.AlcoholBinge.monthly, points: 2 },
        { answer: Answers.AlcoholBinge.weekly, points: 3 },
        { answer: Answers.AlcoholBinge.daily, points: 4 }
    ]
};

/**
 * Utils wrapper for testing
 *
 * @type {{getPointsForAnswer: (function(String, String): *)}}
 */
const Utils = {
    /**
     * Given a question and its answer, returns the attributed points
     *
     * @param {String} question
     * @param {String} answer
     * @return {number|*}
     */
    getPointsForAnswer: (question, answer) => {
        const match = Array.isArray(scoreMap[question])
            ? scoreMap[question].find(possibleAnswer => possibleAnswer.answer === answer)
            : null;

        return match ? match.points : 0;
    }
};

/**
 * Generates a score given answers to Alcohol Survey questions
 *
 * @param {String} alcoholFrequencyAnswer
 * @param {String|null} alcoholTypicalAnswer
 * @param {String|null} alcoholBingeAnswer
 *
 * @return {Number|null}
 */
const generateScoreForAnswers = (alcoholFrequencyAnswer, alcoholTypicalAnswer, alcoholBingeAnswer) => {
    if (alcoholFrequencyAnswer == null && alcoholTypicalAnswer == null && alcoholBingeAnswer == null) {
        return null;
    }

    return (
        Utils.getPointsForAnswer("alcoholFrequency", alcoholFrequencyAnswer) +
        Utils.getPointsForAnswer("alcoholTypical", alcoholTypicalAnswer) +
        Utils.getPointsForAnswer("alcoholBinge", alcoholBingeAnswer)
    );
};

export default {
    getGuideline,
    SisuAlcoholGuidelineDefinition,
    RiskName,
    RiskCode,
    GuidelineCode,
    GuidelineName,
    Utils,
    generateScoreForAnswers
};
