import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButton = styled.button`
    font-size: ${props => props.theme.text.heading4.sizeRem};
    background-color: ${props => props.theme.colours.flat.gray.hex};
    color: ${props => props.theme.colours.flat.white.hex};
    border-radius: 0.2em;
    padding: 0.5em 1.2em;
    min-width: 6em;
    border: 0;

    :focus {
        outline: none;
    }
`;

class DisabledAction extends Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick(e) {
        e.preventDefault();
    }

    render() {
        return (
            <StyledButton onClick={this.handleOnClick} disabled={true}>
                {this.props.children}
            </StyledButton>
        );
    }
}

DisabledAction.displayName = "Disabled Action Button";
DisabledAction.propTypes = {
    children: PropTypes.string.isRequired
};

export default styled(DisabledAction)``;
