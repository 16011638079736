import React from "react";
import ReactTable from "react-table";
import styled from "styled-components";
import { Card } from "rebass";

// -------------------------------------------------------------------------------------------

import { Table as DataTable } from "@sisuwellness/web-components/components/Table";
import { media } from "@sisuwellness/web-components/themes";

// -------------------------------------------------------------------------------------------

const StyledTableContainer = styled(Card)`
    overflow: hidden;
    position: relative;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colours.hpPurple100.hex};

    ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    @media print {
        @page {
            size: landscape;
        }
    }

    .ReactTable {
        max-height: 397px;

        ${media.tablet`
            max-height: 340px;
        `}
    }
`;

const Table = props => (
    <StyledTableContainer data-testid="sisu-table">
        <ReactTable showPagination={false} sortable={false} resizable {...props} />
    </StyledTableContainer>
);

Table.propTypes = { ...DataTable.propTypes };

export default Table;
