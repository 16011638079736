import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Heading from "../../Heading";

const BoldTitle = styled(Heading)`
    font-size: ${props => props.theme.text.heading3.sizeRem};
    font-family: Proxima Nova Semibold, Helvetica, Arial, sans-serif;
    color: ${props => props.theme.colours.flat.darkGray.hex};
    font-weight: bold;
    text-align: center;
    user-select: none;
`;

const Title = ({ children, as = "h3", ...rest }) => {
    return (
        <BoldTitle renderAs={as} {...rest}>
            {children}
        </BoldTitle>
    );
};

Title.propTypes = {
    children: PropTypes.node,
    as: PropTypes.string
};

export default withTheme(Title);
