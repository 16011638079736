/**
 * Get feature offering list
 *
 * @param {boolean} isCountryUk is country UK flag
 *
 * @return {Array<{ core: boolean, premium: boolean, featureName: String }>}
 */

// eslint-disable-next-line no-unused-vars
export default (isCountryUk = false) =>
    [
        {
            core: true,
            premium: true,
            featureName:
                "members_portal_web.components.context_providers.conversion_modals.feature_list.health_measures"
        },
        {
            core: false,
            premium: true,
            featureName: "words.heart_age"
        },
        {
            core: false,
            premium: true,
            featureName:
                "members_portal_web.components.context_providers.conversion_modals.feature_list.weight_loss_program"
        },
        {
            core: false,
            premium: true,
            featureName: "members_portal_web.components.context_providers.conversion_modals.feature_list.better_sleep"
        },
        {
            core: false,
            premium: true,
            featureName: "members_portal_web.components.context_providers.conversion_modals.feature_list.more_programs"
        }
    ].filter(e => e);
