import {
    BLOOD_PRESSURE,
    BODY_MASS_INDEX,
    HEART_RATE,
    DIABETES,
    BODY_FAT,
    WEIGHT,
    QRISK3,
    ALCOHOL_RISK,
    ACTIVITY,
    STRESS_PSS4,
    TOTAL_CHOLESTEROL,
    HDL_CHOLESTEROL,
    NON_FASTING_GLUCOSE,
    HRA_LIFESTYLE_SCORE,
    HRA_EMOTIONAL_SCORE,
    HRA_SOCIAL_SCORE,
    SLEEP,
    SMOKING,
    HRA_HEALTH_SCORE,
    HEART_AGE,
    TEN_YEAR_CVD_RISK,
    TDEE,
    MUSCLE_MASS
} from "constants/trend-charts";
import { Guidelines } from "@sisuwellness/utilities";
import { theme } from "@sisuwellness/web-components";
import { BodyMassIndex } from "@sisuwellness/utilities/HealthCalculators";
import { calculateHeartAge, calculateTotalDailyEnergyExpenditure } from "utilities/premium";

export default function calculatePointColor(type, datum) {
    const defaultColor = theme.colours.flat.primaryBlack.hex;

    let guideline, guidelineColor, currentValue;

    switch (type) {
        case BLOOD_PRESSURE: {
            const { sys, dia } = datum;
            guideline =
                Number.isFinite(sys) && Number.isFinite(dia)
                    ? new Guidelines.BloodPressure.NationalHeartFoundationAustralia(sys, dia)
                    : null;

            if (guideline) {
                guidelineColor = theme.guidelines.NationalHeartFoundationAustralia[guideline.key];
                currentValue = `${sys}/${dia}`;
            }
            break;
        }
        case BODY_MASS_INDEX: {
            let { weight, height, bmi } = datum;
            if (!bmi && weight && height) bmi = BodyMassIndex.getBMI(height, weight);
            guideline = Number.isFinite(bmi) ? new Guidelines.BodyMassIndex.SiSU(bmi) : null;

            if (guideline) {
                guidelineColor = theme.guidelines.bodyMassIndex[guideline.key];
                currentValue = `${bmi}`;
            }
            break;
        }
        case HEART_RATE: {
            const { hr } = datum;
            guideline = Number.isFinite(hr) ? new Guidelines.HeartRate.SiSU(hr) : null;
            if (guideline) {
                guidelineColor = theme.guidelines.SiSUHeartRate[guideline.key];
                currentValue = `${hr}`;
            }
            break;
        }
        case DIABETES: {
            const { ausdriskScoreValue } = datum;
            const score = Number(ausdriskScoreValue);
            guideline = Number.isFinite(score) ? Guidelines.AustralianDiabetesRisk.getGuideLine(score) : null;

            if (guideline) {
                guidelineColor = theme.guidelines.AustralianDiabetesRisk[guideline.key];
                currentValue = `${ausdriskScoreValue}`;
            }
            break;
        }
        case BODY_FAT: {
            const { age, gender, bodyFatPercent } = datum;

            guideline =
                age && gender && bodyFatPercent && bodyFatPercent <= 100
                    ? new Guidelines.BodyFat.Guideline(age, gender, bodyFatPercent)
                    : null;

            if (guideline) {
                guidelineColor = theme.guidelines.bodyFat[guideline.key];
                currentValue = `${bodyFatPercent}`;
            }
            break;
        }
        case WEIGHT: {
            const { weight, height } = datum;
            guideline =
                Number.isFinite(weight) && Number.isFinite(height) ? new Guidelines.Weight.SiSU(weight, height) : null;

            if (guideline) {
                guidelineColor = theme.guidelines.weight[guideline.key];
                currentValue = `${weight}`;
            }
            break;
        }
        case QRISK3: {
            const { qriskScore } = datum;
            guideline = Number.isFinite(qriskScore) ? Guidelines.Qrisk.GetGuideline(qriskScore) : null;

            if (guideline) {
                guidelineColor = theme.guidelines.Qrisk[guideline.key];
                currentValue = `${qriskScore}`;
            }
            break;
        }

        case ALCOHOL_RISK: {
            const { alcoholScore } = datum;
            guideline = Number.isFinite(alcoholScore) ? Guidelines.Alcohol.getGuideline(alcoholScore) : null;

            if (alcoholScore && guideline) {
                guidelineColor = theme.guidelines.Alcohol[guideline.key];
                currentValue = `${alcoholScore}`;
            }
            break;
        }

        case ACTIVITY: {
            const { activityRating } = datum;
            guideline = activityRating ? Guidelines.Activity.GetGuideline(activityRating) : null;
            if (guideline) {
                guidelineColor = theme.guidelines.Activity[guideline.key];
                currentValue = activityRating;
            }
            break;
        }

        case STRESS_PSS4: {
            const { pss4Score } = datum;
            guideline = Number.isFinite(pss4Score) ? Guidelines.PSS4.SisuGuideline(pss4Score) : null;
            if (guideline) {
                guidelineColor = theme.guidelines["PSS4"][guideline.key];
                currentValue = pss4Score;
            }
            break;
        }

        case TOTAL_CHOLESTEROL: {
            const { totalCholesterol } = datum;
            guideline = totalCholesterol ? new Guidelines.TotalCholesterol.SiSU(totalCholesterol.value) : null;
            break;
        }

        case HDL_CHOLESTEROL: {
            const { hdlCholesterol } = datum;
            guideline = hdlCholesterol ? new Guidelines.HDLCholesterol.SiSU(hdlCholesterol.value) : null;
            break;
        }

        case NON_FASTING_GLUCOSE: {
            const { nonFastingGlucose } = datum;
            guideline = nonFastingGlucose ? new Guidelines.NonFastingGlucose.SiSU(nonFastingGlucose.value) : null;
            break;
        }

        case HRA_LIFESTYLE_SCORE: {
            const { HRALifestyleScore } = datum;
            guideline = Number.isFinite(HRALifestyleScore)
                ? new Guidelines.HRAIndex.Guideline(HRALifestyleScore)
                : null;
            break;
        }

        case HRA_EMOTIONAL_SCORE: {
            const { HRAEmotionalScore } = datum;
            guideline = Number.isFinite(HRAEmotionalScore)
                ? new Guidelines.HRAIndex.Guideline(HRAEmotionalScore)
                : null;
            break;
        }

        case HRA_SOCIAL_SCORE: {
            const { HRASocialScore } = datum;
            guideline = Number.isFinite(HRASocialScore) ? new Guidelines.HRAIndex.Guideline(HRASocialScore) : null;
            break;
        }

        case HRA_HEALTH_SCORE: {
            const { healthRiskScore } = datum;
            guideline = Number.isFinite(healthRiskScore)
                ? new Guidelines.HealthRiskAssessment.Guideline(healthRiskScore)
                : null;
            if (guideline) {
                guidelineColor = theme.guidelines["SiSUHealthRiskAssessment"][guideline.key];
                currentValue = healthRiskScore;
            }
            break;
        }

        case SLEEP: {
            const { ahiScore } = datum;
            guideline = ahiScore != null ? new Guidelines.Sleep.Guideline(ahiScore) : null;
            break;
        }

        case SMOKING: {
            const { isSmoker } = datum;
            guideline = typeof isSmoker === "boolean" ? new Guidelines.Smoking.SiSU(isSmoker) : null;
            break;
        }

        case TEN_YEAR_CVD_RISK:
        case HEART_AGE: {
            const { age } = datum;
            const { heartAge = null, heartAgeOptimal = "" } = calculateHeartAge(datum);
            guideline =
                heartAge && heartAgeOptimal && age
                    ? Guidelines.HeartAge.SisuHeartAgeGuideline(heartAge, heartAgeOptimal, age)
                    : null;
            if (guideline) {
                guidelineColor = theme.guidelines.HeartAge[guideline.key];
            }
            break;
        }

        case TDEE: {
            const { ratingKj } = calculateTotalDailyEnergyExpenditure(datum);
            guideline = ratingKj;
            break;
        }

        case MUSCLE_MASS: {
            const { muscleMass, gender, age } = datum;
            guideline =
                muscleMass && gender && age ? Guidelines.MuscleMass.buildSisuGuideline(muscleMass, gender, age) : null;

            if (guideline) {
                guidelineColor = theme.guidelines.muscleMass[guideline.key];
                currentValue = muscleMass;
            }
            break;
        }

        default: {
            return { pointColor: guidelineColor ? guidelineColor.sisuPortal.hex : defaultColor };
        }
    }

    return {
        guideline,
        currentValue,
        key: guideline ? guideline.key : "",
        riskLevel: guideline ? guideline.riskLevel : 0,
        rating: guideline ? guideline.label || guideline.getRatingName?.() : "",
        pointColor: guidelineColor ? guidelineColor.sisuPortal.hex : defaultColor
    };
}
