import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Redirect } from "react-router";

// ----------------------------------------------------------------------

import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { PremiumFeature } from "components/PremiumFeatures";
import CollapsableCard from "components/CollapsableCard";
import { MUSCLE_MASS } from "constants/trend-charts";
import PageLayout from "components/PageLayout";
import BreadCrumbs from "views/BreadCrumbs";
import Flex from "components/Flex";
import FACTS from "constants/facts";
import routes from "constants/routes";

import LockedMuscleMass from "./LockedMuscleMass";
import UnlockedMuscleMass from "./UnlockedMuscleMass";
import { withLatestHealthCheck } from "components/HOC";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------

export const Context = React.createContext({});
export const Provider = Context.Provider;

const MuscleMassView = ({ latestHealthCheck, healthChecks }) => {
    const { t } = useTranslation();
    const value = { latestHealthCheck, healthChecks };

    return (
        <Provider value={value}>
            <PageLayout>
                {/* Muscle Mass Navigation */}
                <BreadCrumbs mb="16px" />
                <Flex fd="column" gap="16px" mb="32px">
                    <HeadingLabel fs={["28px", "34px"]} fontWeight="intermediate">
                        {t("words.skeletal_muscle")}
                    </HeadingLabel>
                    <HeadingLabel fs={["13", "15px"]}>{t("members_portal_web.views.muscle_mass.heading")}</HeadingLabel>
                </Flex>

                {/* Muscle Mass Outcome page Locked or Unlocked */}
                <PremiumFeature>
                    {({ isPremium, canBePremium }) => {
                        if (!canBePremium) return <Redirect to={routes.healthHub} />;
                        return isPremium ? <UnlockedMuscleMass /> : <LockedMuscleMass />;
                    }}
                </PremiumFeature>

                {/* Muscle Mass FAQs */}
                <Flex fd="column" gap="32px" mt="32px">
                    <HeadingLabel fontSize={["22px", "34px"]} fontWeight="500">
                        {t("members_portal_web.views.muscle_mass.common_questions")}
                    </HeadingLabel>
                    <Flex fd="column" gap={["16px", "24px"]}>
                        {/* TODO: Change FAQs to Muscle Mass FAQs when ready */}
                        {FACTS[MUSCLE_MASS].data.map((facts, index) => (
                            <CollapsableCard mb="24px" {...facts} key={index} />
                        ))}
                    </Flex>
                </Flex>
            </PageLayout>
        </Provider>
    );
};

/**
 * Hook for user health check data
 * @return {{ latestHealthCheck: object, healthChecks: object[] }}
 */
export const useUserHealthCheckData = () => useContext(Context);

MuscleMassView.propTypes = {
    healthChecks: PropTypes.array,
    latestHealthCheck: PropTypes.object
};

export default withLatestHealthCheck(MuscleMassView);
