import React from "react";
import PropTypes from "prop-types";

import { Card } from "rebass";
import styled from "styled-components";
import { border } from "styled-system";

export const Container = styled(Card)(border);

const BannerCard = ({ children, ...rest }) => (
    <Container borderRadius="8px" p={["24px 16px", "24px"]} bg="#f4fefc" color="#1abc9b" {...rest}>
        {children}
    </Container>
);
BannerCard.propTypes = {
    children: PropTypes.any
};

export default BannerCard;
