import React from "react";

// ----------------------------------------------------------------

import FlexWithGap from "components/Flex";
import MuscleMassOverview from "./mmOverview";
import MuscleMassSimulator from "./mmSimulator";
import MuscleMassHistory from "./mmHistory";

// ----------------------------------------------------------------

const UnlockedMuscleMass = () => (
    <FlexWithGap fd="column" gap="32px">
        <MuscleMassOverview />
        <MuscleMassHistory />
        <MuscleMassSimulator />
    </FlexWithGap>
);

export default UnlockedMuscleMass;
