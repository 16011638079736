import * as yup from "yup";

import {
    genderValidation,
    stringNameValidation,
    dateOfBirthValidation
} from "@sisuwellness/utilities/Validation/UserProfileValidation";

export const validateSchema = () =>
    yup.object().shape({
        firstName: stringNameValidation(),
        lastName: stringNameValidation(),
        dateOfBirth: dateOfBirthValidation(),
        gender: genderValidation(["Male", "Female"])
    });
