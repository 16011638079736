import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import Paragraph from "../Paragraph";
import { ResponsiveBox, ResponsiveFlex } from "../Layout";
import Heading from "../Heading";
import Icon from "../Icons";
import { SecondaryButton } from "../Buttons";
import { media } from "../../themes";
import { t } from "i18next";

const StyledSecondaryButton = styled(SecondaryButton)`
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;

    ${media.tablet`
        padding-left: 0;
        padding-right: 0;
    `}

    span {
        vertical-align: middle;
    }
`;

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.textSpecs = {
            heading: t("web_components.error_boundary.text_specs.heading"),
            paragraph: t("web_components.error_boundary.text_specs.paragraph"),
            secondaryButton: t("words.retry")
        };
    }

    state = {
        hasError: false
    };

    componentDidCatch(error, info) {
        this.setState({ hasError: true });
        window.Rollbar.error(error, info);
    }

    render() {
        const { hasError } = this.state;
        const { children, theme } = this.props;
        const { icons = {}, colours = {} } = theme;

        if (hasError) {
            return (
                <ResponsiveFlex height="100vh" alignItems="center" justifyContent="center" flexDirection="column">
                    <ResponsiveBox mb="1em">
                        <Icon
                            size={icons.sizes.question}
                            type={icons.types.unavailable}
                            colour={colours.flat.darkGray.hex}
                        />
                    </ResponsiveBox>
                    <Heading renderAs={"h2"} mb="0.5em" textAlign="center">
                        {this.textSpecs.heading}
                    </Heading>
                    <Paragraph mb="1em">{this.textSpecs.paragraph}</Paragraph>
                    <ResponsiveBox>
                        <StyledSecondaryButton onClick={() => window.location.reload()}>
                            {this.textSpecs.secondaryButton}
                        </StyledSecondaryButton>
                    </ResponsiveBox>
                </ResponsiveFlex>
            );
        } else {
            return children;
        }
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object
};

export default withTheme(ErrorBoundary);
