import styled from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";

export const FieldContainer = styled.div`
    width: 50%;
    box-sizing: border-box;
    ${mediaQueryForCss({
        width: ["", "", "100%"]
    })}
`;
