import React from "react";
import { UnitConverter } from "@sisuwellness/utilities";
import { path } from "ramda";
import { Cell, Header } from "./commonElements";
import { t } from "i18next";

const handleCellConstruct = (row, settings) => {
    const weight = path(["value"], row);

    switch (settings.unitMeasurement) {
        case "imperial": {
            const weightInIm = UnitConverter.kgToStLbs(weight);
            return (
                <Cell
                    unit=""
                    row={row}
                    data-testid="weight"
                    value={
                        weight
                            ? `${weightInIm.stones} ${t("units.stone")} ${weightInIm.pounds.toFixed(1)} ${t(
                                  "units.pounds"
                              )}`
                            : null
                    }
                />
            );
        }
        default:
            return (
                <Cell
                    data-testid="weight"
                    row={row}
                    value={weight ? weight.toFixed(1) : null}
                    unit={t("units.kilograms")}
                />
            );
    }
};

export const weightDef = (iconTypes, settings) => ({
    minWidth: 92,
    accessor: "weight",
    Cell: row => handleCellConstruct(row, settings),
    Header: () => <Header label={t("words.weight")} iconType={iconTypes.weight} />
});
