import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { BrowserRouter } from "react-router-dom";
export const APP_VERSION_CHECK_INTERVAL = 5 * 60 * 1000;

const RefreshRouter = ({ children, checkForUpdate, interval, currentBuildVersion, excludeRoutes }) => {
    const [updateState, setUpdateState] = useState({
        key: new Date().valueOf(),
        shouldUpdate: false
    });

    // Persist state in setInterval scope
    const updateStateRef = useRef(updateState);
    updateStateRef.current = updateState;

    // Utilities function
    const shouldUpdateAppFor = versionResponse =>
        versionResponse != null &&
        versionResponse.buildVersion != null &&
        versionResponse.buildVersion !== currentBuildVersion;

    const shouldStartInterval = () =>
        !updateStateRef.current.shouldUpdate && !excludeRoutes.includes(location.pathname);

    useEffect(() => {
        async function checkForAppUpdate() {
            try {
                const response = await checkForUpdate();
                if (response.succeeded()) {
                    const result = await response.body();
                    if (shouldUpdateAppFor(result)) {
                        setUpdateState({
                            key: new Date().valueOf(),
                            shouldUpdate: true
                        });
                    }
                }
            } catch (e) {
                // ignore
            }
        }

        const createdInterval = setInterval(() => {
            if (shouldStartInterval()) {
                checkForAppUpdate();
            }
        }, interval);

        return () => {
            clearInterval(createdInterval);
        };
    }, [checkForUpdate]);

    const { key, shouldUpdate } = updateState;

    return (
        <BrowserRouter key={key} forceRefresh={shouldUpdate}>
            {children}
        </BrowserRouter>
    );
};

RefreshRouter.propTypes = {
    children: PropTypes.node.isRequired,
    checkForUpdate: PropTypes.func.isRequired,
    currentBuildVersion: PropTypes.string.isRequired,
    excludeRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
    interval: PropTypes.number.isRequired
};

RefreshRouter.defaultProps = {
    currentBuildVersion: window.REACT_APP_BUILD_VERSION,
    excludeRoutes: [],
    interval: APP_VERSION_CHECK_INTERVAL
};

export default RefreshRouter;
