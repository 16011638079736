/**
 * Houses all general unit conversion utility methods.
 */

const Units = {
    metric: {
        weight: {
            kilogram: "kg"
        },
        length: {
            centimeter: "cm"
        }
    },
    imperialUk: {
        weight: {
            stone: "st",
            pounds: "lbs"
        },
        length: {
            foot: "ft",
            inch: "in"
        }
    }
};

export default class UnitConverter {
    /**
     * Converts length in centimeters to inches.
     * @param {number} lengthInCm
     * @return {number}
     */
    static cmToInch(lengthInCm) {
        return lengthInCm / 2.54;
    }

    /**
     * Converts length in inches to centimeters.
     * @param {number} lengthInInches
     * @return {number}
     */
    static inchToCm(lengthInInches) {
        return lengthInInches * 2.54;
    }

    /**
     * Converts length in inches to feet and inches combination.
     * @param {number} lengthInInch
     * @return {{feet: number, inches: number}}
     */
    static inchToFtInch(lengthInInch) {
        let feet = Math.floor(lengthInInch / 12.0);
        let residual = lengthInInch - feet * 12;

        return { feet: feet, inches: residual };
    }

    /**
     * Converts length in centimeters to feet and inches combination.
     * @param {number} lengthInCm
     * @return {{feet: number, inches: number}}
     */
    static cmToFtInch(lengthInCm) {
        return this.inchToFtInch(this.cmToInch(lengthInCm));
    }

    /**
     * Converts length in centimeters to a string of feet and inches combination.
     * @param {number} lengthInCm
     * @return {string}
     */
    static cmToFtInchString(lengthInCm) {
        let resultFtInches = this.cmToFtInch(lengthInCm);
        return this.ftInchToFtInchString(resultFtInches);
    }

    /**
     * Converts feet and inches to centimeters
     * @param {number} feet
     * @param {number} inches
     * @returns {number}
     */
    static ftInchToCm(feet, inches) {
        return UnitConverter.inchToCm(feet * 12 + inches);
    }

    /**
     * Generates the Feet-Inches display string from the Feet-inches result
     * @param {{feet: number, inches: number}} ftInResult
     * @returns {string}
     */
    static ftInchToFtInchString(ftInResult) {
        let feet = Math.round(ftInResult.feet);
        let inches = Math.round(ftInResult.inches);
        let residual = Math.floor(inches / 12.0);

        feet += residual;
        inches = residual === 0 ? inches : 0;

        if (inches === 0) {
            return `${feet}${Units.imperialUk.length.foot}`;
        } else {
            return `${feet}${Units.imperialUk.length.foot} ${inches}${Units.imperialUk.length.inch}`;
        }
    }

    /**
     * Converts length in inches to a string of feet and inches combination.
     * @param {number} lengthInInches
     * @return {string}
     */
    static inchesToFtInchString(lengthInInches) {
        let result = this.inchToFtInch(lengthInInches);
        return this.ftInchToFtInchString(result);
    }

    /**
     * Converts weight in kilograms to pounds.
     * @param {number} weightInKg
     * @return {number}
     */
    static kgToPounds(weightInKg) {
        return weightInKg * 2.20462;
    }

    /**
     * Converts weight in pounds to kilograms.
     * @param {number} weightInPounds
     * @return {number}
     */
    static poundsToKgs(weightInPounds) {
        return weightInPounds / 2.20462;
    }

    /**
     * Converts weight in pounds to stones and pounds combination.
     * @param {number} weightInLbs
     * @return {{stones: number, pounds: number}}
     */
    static poundsToStLbs(weightInLbs) {
        let stones = Math.floor(weightInLbs / 14.0);
        let residual = weightInLbs - stones * 14.0;

        return { stones: stones, pounds: residual };
    }

    /**
     * Converts weight in kilograms to stones and pounds combination.
     * @param {number} weightInKg
     * @return {{stones: number, pounds: number}}
     */
    static kgToStLbs(weightInKg) {
        return this.poundsToStLbs(this.kgToPounds(weightInKg));
    }

    /**
     * Converts weight in kilograms to a string of stones and pounds combination.
     * @param {number} weightInKg
     * @return {string}
     */
    static kgToStLbsString(weightInKg) {
        let result = this.poundsToStLbs(this.kgToPounds(weightInKg));
        return this.stLbsToStLbsString(result);
    }

    /**
     * Converts weight in kilograms to a string of stones and pounds combination.
     * @param {number} weightInPounds
     * @return {string}
     */
    static poundsToStLbsString(weightInPounds) {
        let result = this.poundsToStLbs(weightInPounds);
        return this.stLbsToStLbsString(result);
    }

    /**
     * Converst stones and pounds compound weight to kilograms
     * @param {number} stones
     * @param {number} pounds
     * @returns {number}
     */
    static stLbsToKg(stones, pounds) {
        return UnitConverter.poundsToKgs(stones * 14 + pounds);
    }

    /**
     * Generates St-Lbs display string from St-Lbs result
     * @param {{stones: number, pounds: number}} resultStLbs
     * @returns {string}
     */
    static stLbsToStLbsString(resultStLbs) {
        let stones = Math.round(resultStLbs.stones);
        let pounds = Math.round(resultStLbs.pounds);
        let residual = Math.floor(pounds / 14.0);

        stones += residual;
        pounds = residual === 0 ? pounds : 0;

        if (pounds === 0) {
            return `${stones}${Units.imperialUk.weight.stone}`;
        } else {
            return `${stones}${Units.imperialUk.weight.stone} ${pounds}${Units.imperialUk.weight.pounds}`;
        }
    }

    /**
     * Attach the proper unit string to a number with the defined precision.
     * @param value
     * @param unit
     * @param digits
     * @return {string}
     */
    static toString(value, unit, digits = 0) {
        return `${Number(value).toFixed(digits)}${unit}`;
    }

    /**
     * Convert the given number to a readable string
     * @param num
     * @param locale
     * @returns {string}
     */
    static formatNumberToReadableFix(num, locale = "en-AU") {
        return num.toLocaleString(locale, { maximumFractionDigits: 0 });
    }

    /**
     * Convert kilocalories (known as Calories) to kilojoules
     * @param kcal
     * @returns {number}
     */
    static kCalToKJ(kcal) {
        return kcal * 4.184;
    }

    /**
     * Convert kilojoules to kilocalories
     * @param kj
     * @returns {number}
     */
    static kJToKcal(kj) {
        return kj / 4.184;
    }

    /**
     * Transform a number in kcal/day to the readable number and unit string
     * @param kcal
     * @returns {string}
     */
    static kCalPerDayToString(kcal) {
        return `${UnitConverter.formatNumberToReadableFix(kcal)} cal/day`;
    }

    /**
     * Transform a number in kJ/day to the readable number and unit string
     * @param kj
     * @returns {string}
     */
    static kJPerDayToString(kj) {
        return `${UnitConverter.formatNumberToReadableFix(kj)} kJ/day`;
    }
}

export { Units };
