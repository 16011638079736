import { createGlobalStyle } from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";

export default createGlobalStyle`
    [data-printid=print-header] {
        display: none;
    }

    .page-container-scripts {
        width: 100%;
        box-sizing: border-box;
        padding-right: 150px;
        padding-left: 150px;
        max-width: 1440px;

        .row {
            margin: 0
        }

        ${mediaQueryForCss({
            "padding-right": ["15px"],
            "padding-left": ["15px"]
        })}
    }

    @media print {
        [data-printid=base-member-menu-header], [data-printid=footer] {
            display: none;
        }
        a[href]::after {
            display: none;
        }
        [data-printid=print-header] {
            display: flex !important;
        }
        [data-printid=page-title] {
            position: fixed;
            right: 10px;
            top: 0px;
        }
        
        .page-container {
            margin-top: 10px;
        }

    }
    
    .page-break {
        page-break-inside: avoid;
    }
`;
