import { QUESTIONS_VALIDATION_SCHEMA } from "../Schemas/Questions";
import * as yup from "yup";

export default class Rule {
    /**
     * @return {*}
     */
    static get SCHEMA_VALIDATION() {
        return yup
            .object()
            .shape({
                ruleId: yup.number().required(),
                questionCode: yup.string().required(),
                comparisonValue: yup.string().required(),
                comparison: yup
                    .string()
                    .oneOf(this.SUPPORTED_COMPARISONS)
                    .required()
            })
            .required();
    }

    /**
     * Returns an array of supported comparisons
     *
     * @return {[String]}
     * @constructor
     */
    static get SUPPORTED_COMPARISONS() {
        return [Rule.COMPARISON_GTE, Rule.COMPARISON_GT, Rule.COMPARISON_EQ, Rule.COMPARISON_LTE, Rule.COMPARISON_LT];
    }

    /**
     * The "Greater than or equal to" comparison
     *
     * @return {String}
     * @constructor
     */
    static get COMPARISON_GTE() {
        return ">=";
    }

    /**
     * The "Greater than" comparison
     *
     * @return {String}
     * @constructor
     */
    static get COMPARISON_GT() {
        return ">";
    }

    /**
     * The "Equal to" comparison
     *
     * @return {String}
     * @constructor
     */
    static get COMPARISON_EQ() {
        return "=";
    }

    /**
     * The "Less than or equal to" comparison
     *
     * @return {String}
     * @constructor
     */
    static get COMPARISON_LTE() {
        return "<=";
    }

    /**
     * The "Less than" comparison
     *
     * @return {String}
     * @constructor
     */
    static get COMPARISON_LT() {
        return "<";
    }

    /**
     * Initialises a Rule with specific schema
     * @param {{ruleId: Number, questionCode:String, comparisonValue:String, comparison:String}} schema
     */
    constructor(schema) {
        Rule.SCHEMA_VALIDATION.validateSync(schema);

        this._ruleId = schema.ruleId;
        this._questionCode = schema.questionCode;
        this._comparisonValue = schema.comparisonValue;
        this._comparison = schema.comparison;
    }

    /**
     * @return {{comparison: String, comparisonValue: String, questionCode: String}}
     */
    get schema() {
        return {
            ruleId: this._ruleId,
            questionCode: this._questionCode,
            comparisonValue: this._comparisonValue,
            comparison: this._comparison
        };
    }

    /**
     * Validates the current rule against an array of answers
     *
     * @param {[{questionCode:String, answers:[{answer:String}]}]} questions
     * @return {{valid:Boolean, schema:Object, match:String}}
     * @throws Error
     */
    validate(questions) {
        QUESTIONS_VALIDATION_SCHEMA.validateSync(questions);

        let valid = false;
        let match = null;
        for (let question of questions) {
            if (question.questionCode === this._questionCode) {
                for (let answer of question.answers) {
                    const answerValue = answer.answer;

                    // validate using the configured comparison
                    switch (this._comparison) {
                        case Rule.COMPARISON_GTE:
                            valid = Number(answerValue) >= Number(this._comparisonValue);
                            break;
                        case Rule.COMPARISON_GT:
                            valid = Number(answerValue) > Number(this._comparisonValue);
                            break;
                        case Rule.COMPARISON_EQ:
                            valid = answerValue === this._comparisonValue;
                            break;
                        case Rule.COMPARISON_LTE:
                            valid = Number(answerValue) <= Number(this._comparisonValue);
                            break;
                        case Rule.COMPARISON_LT:
                            valid = Number(answerValue) < Number(this._comparisonValue);
                            break;
                    }

                    // store the match to report to consumer
                    if (valid) {
                        match = answerValue;
                        break;
                    }
                }

                break;
            }
        }

        return {
            valid,
            schema: this.schema,
            match
        };
    }
}
