import React from "react";
import styled from "styled-components";

const StyledImage = styled.img`
    width: ${props => (props.height ? "auto" : "100%")};
    height: ${props => props.height || "auto"};
    margin: auto;
`;

const ResponsiveImage = ({ ...props }) => <StyledImage {...props} />;

export default ResponsiveImage;
