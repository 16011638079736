import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(score) {
        this._validateValue(score);
        this._score = score;
    }

    /**
     * Returns the given score value
     * @returns {Number}
     */
    get() {
        return this._score;
    }

    /**
     * Validates the given score value. It is intended to be a positive number between 0-100
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(score) {
        if (!(typeof score === "number") || Number.isNaN(score) || score < 0 || score > 100) {
            throw new InvalidArgumentError("Score must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the score is in the 'excellent' range
     *
     * @returns {boolean}
     */
    isExcellent() {
        return this._score >= 80;
    }

    /**
     * Returns if the given score is in the 'good' range
     *
     * @returns {boolean}
     */
    isGood() {
        return this._score >= 70 && this._score < 80;
    }

    /**
     * Returns if the given score is in the 'moderate' range
     *
     * @returns {boolean}
     */
    isModerate() {
        return this._score >= 60 && this._score < 70;
    }

    /**
     * Returns if the given score is in the 'at risk' range
     *
     * @returns {boolean}
     */
    isAtRisk() {
        return this._score >= 50 && this._score < 60;
    }

    /**
     * Returns if the given score is in the 'increased risk' range
     *
     * @returns {boolean}
     */
    isIncreasedRisk() {
        return this._score < 50;
    }

    /**
     * Returns an object representing various data for each BMI range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            excellent: {
                key: "excellent",
                label: "ranges.excellent",
                range: "80 - 100"
            },

            good: {
                key: "good",
                label: "ranges.good",
                range: "70 - 79"
            },

            moderate: {
                key: "moderate",
                label: "ranges.moderate",
                range: "60 - 69"
            },

            atRisk: {
                key: "atRisk",
                label: "ranges.at_risk",
                range: "50 - 59"
            },

            increasedRisk: {
                key: "increasedRisk",
                label: "ranges.increased_risk",
                range: "0 - 49"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isExcellent():
                info = "80 - 100%";
                break;
            case this.isGood():
                info = "70 - 79%";
                break;
            case this.isModerate():
                info = "60 - 69%";
                break;
            case this.isAtRisk():
                info = "50 - 59%";
                break;
            case this.isIncreasedRisk():
                info = "< 50%";
                break;
        }

        return info;
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeLabel() {
        let info = "";
        switch (true) {
            case this.isExcellent():
                info = "ranges.excellent";
                break;
            case this.isGood():
                info = "ranges.good";
                break;
            case this.isModerate():
                info = "ranges.moderate";
                break;
            case this.isAtRisk():
                info = "ranges.at_risk";
                break;
            case this.isIncreasedRisk():
                info = "ranges.increased_risk";
                break;
        }

        return info;
    }

    /**
     * returns normalised 'key' about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isExcellent():
                info = "excellent";
                break;
            case this.isGood():
                info = "good";
                break;
            case this.isModerate():
                info = "moderate";
                break;
            case this.isAtRisk():
                info = "atRisk";
                break;
            case this.isIncreasedRisk():
                info = "increasedRisk";
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        return this.rangeLabel;
    }

    /**
     * Calculates a risk level number from 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        return this._score / 100;
    }
}
