import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "styled-components";

//------------------------------------------------------------------------------
import RatingTooltip from "./RatingTooltip";
import { BLOOD_PRESSURE, BODY_MASS_INDEX, WEIGHT } from "constants/trend-charts";
import { tooltipPosition } from "utilities/progressBar";
import { useTranslation } from "react-i18next";

const ProgressTooltips = ({ rating, label, unit, type, range, pointColor, value, target, index = 1, isUnitMetric }) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);

    const metricsWhichShowTargetValues = [BODY_MASS_INDEX, WEIGHT, BLOOD_PRESSURE];
    // target tooltip is shown for above array of metrics or else hidden
    const getIsTargetTooltipVisible = () =>
        metricsWhichShowTargetValues.includes(type) &&
        rating !== "Normal" &&
        rating !== "Optimal" &&
        label === "Normal";
    const checkIfLabelNotOptimal = label !== "Normal";

    const [gapBwRatings, setGapBwRatings] = useState(9999);

    useEffect(() => {
        const el = document.getElementById("mp-outcome-bar");
        const width = el.getBoundingClientRect().width;
        if (metricsWhichShowTargetValues.includes(type) && target) {
            const currentTooltipPos = tooltipPosition(index, value, range, type, width);
            const targetTooltipPos = tooltipPosition(index, target, range, type, width);
            setGapBwRatings(currentTooltipPos - targetTooltipPos);
        }
    }, [index, value, range, type]);

    return (
        <>
            {rating === label && (
                <RatingTooltip
                    unit={unit}
                    type={type}
                    range={range}
                    index={index}
                    title={t("words.current")}
                    bgColor={pointColor}
                    value={value}
                    gapBwRatings={gapBwRatings}
                    isUnitMetric={isUnitMetric}
                    isTragetVisible={metricsWhichShowTargetValues.includes(type) && checkIfLabelNotOptimal}
                />
            )}
            {getIsTargetTooltipVisible() && (
                <RatingTooltip
                    unit={unit}
                    type={type}
                    title={t("words.target")}
                    range={range}
                    bgColor={theme.colours.hpGreen.hex}
                    value={target}
                    isUnitMetric={isUnitMetric}
                    gapBwRatings={gapBwRatings}
                    isTragetVisible={true}
                />
            )}
        </>
    );
};

ProgressTooltips.propTypes = {
    unit: PropTypes.string,
    type: PropTypes.string,
    range: PropTypes.string,
    index: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    target: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    pointColor: PropTypes.string,
    label: PropTypes.string,
    rating: PropTypes.string,
    isUnitMetric: PropTypes.bool
};

export default ProgressTooltips;
