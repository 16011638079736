import React from "react";
import PropTypes from "prop-types";

import { Form, Formik } from "formik";
import InputField from "../../Components/InputField";
import { Text } from "rebass";
import { position } from "styled-system";
import styled from "styled-components";
import { FIELD_VALIDATION, validationSchema } from "../../utilities/validationSchemas";
import { isEmpty } from "ramda";
import MediaQuery from "../../../../MediaQuery";
import theme from "../../../../../themes";
import { useTranslation } from "react-i18next";

const CustomText = styled(Text)`
    transform: translateY(-50%);
    ${position};
`;

const FormForNumber = ({ onSubmit, value, setErrors, placeholder, isRequired }) => {
    const { t } = useTranslation();

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            initialValues={{ number: `${value}`, isRequired: isRequired }}
            validationSchema={validationSchema(FIELD_VALIDATION.METRIC.NUMBER)}
        >
            {({ values, handleBlur, handleSubmit, errors, touched, handleChange }) => {
                setErrors(!touched.number && isEmpty(errors) && !values.number ? "error" : errors);
                return (
                    <Form onSubmit={handleSubmit} id="field-form" data-testid="form">
                        <InputField
                            width={["346px", "432px"]}
                            inputProps={{
                                maxLength: 7,
                                name: "number",
                                placeholder: placeholder || "",
                                onBlur: handleBlur,
                                onChange: handleChange,
                                value: values.number
                            }}
                            error={errors.number}
                        >
                            <CustomText fontSize="13px" fontWeight="300" position="absolute" top="50%" left="22px">
                                {values.number}
                            </CustomText>
                        </InputField>
                        <MediaQuery devices={["desktop"]}>
                            <Text color={theme.colours.blackTints300.hex} fontSize="13px" textAlign="left">
                                {t("buttons.press_enter")} ↵
                            </Text>
                        </MediaQuery>
                    </Form>
                );
            }}
        </Formik>
    );
};

FormForNumber.propTypes = {
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    isRequired: PropTypes.bool
};

export { FormForNumber };
