import { Box } from "rebass";
import React from "react";
import styled from "styled-components";
import { MediaQuery } from "@sisuwellness/web-components";

const StyledContainer = styled(Box)`
    height: ${props => props.theme.defaults.headerHeight};
`;
const StyledHeader = styled.section`
    color: ${props => props.theme.colours.flat.white.hex};
    height: ${props => props.theme.defaults.headerHeight};
    background-color: white;
    width: 100%;
`;

const StyledContainerForMobile = styled(Box)`
    height: ${props => props.theme.membersPortal.mobileHeaderHeight};
`;
const StyledHeaderForMobile = styled.section`
    color: ${props => props.theme.colours.flat.white.hex};
    height: ${props => props.theme.membersPortal.mobileHeaderHeight};
    background-color: white;
    width: 100%;
`;

// eslint-disable-next-line react/prop-types
const ThinHeader = ({ children, ...rest }) => (
    <>
        <MediaQuery devices={["desktop", "tablet"]}>
            <StyledContainer {...rest}>
                <StyledHeader>{children}</StyledHeader>
            </StyledContainer>
        </MediaQuery>
        <MediaQuery devices={["mobile"]}>
            <StyledContainerForMobile {...rest}>
                <StyledHeaderForMobile>{children}</StyledHeaderForMobile>
            </StyledContainerForMobile>
        </MediaQuery>
    </>
);

export default ThinHeader;
