import React from "react";

import { Text } from "rebass";
import { theme } from "../../theme";

// ------------------------------------------------------------------------

const StatusLabel = ({ children, fs = 11, fontSize, ...rest }) => (
    <Text fontSize={fontSize || fs} fontWeight={theme.fontWeight.intermediate} color="white" {...rest}>
        {children}
    </Text>
);

StatusLabel.propTypes = {
    ...Text.propTypes
};

export default StatusLabel;
