import React from "react";
import PropTypes from "prop-types";
import Header from "../Header";
import Icon from "../../Icons";

const SortedHeader = ({ label, isDesc, ...rest }) => (
    <Header alignItems="flex-end" {...rest}>
        {label}
        {isDesc != null && <Icon type={isDesc ? "sortDown" : "sortUp"} size="1.5em" />}
    </Header>
);

SortedHeader.propTypes = {
    label: PropTypes.string.isRequired,
    isDesc: PropTypes.bool
};

export default React.memo(SortedHeader);
