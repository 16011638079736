import { Guidelines, UnitConverter } from "@sisuwellness/utilities";
import { fixedNumber } from "utilities/commonUtils";

import {
    BLOOD_PRESSURE,
    BODY_MASS_INDEX,
    HEART_RATE,
    DIABETES,
    BODY_FAT,
    WEIGHT,
    QRISK3,
    ALCOHOL_RISK,
    ACTIVITY,
    HRA_HEALTH_SCORE,
    MUSCLE_MASS
} from "constants/trend-charts";
import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { t } from "i18next";

//------------------------------------------------------------------------------
/**
 *
 * @param {String} riskType Type of Risk
 * @param {Object} guidelineRange Object of range of Guidelines
 * @return {array} Array of Object of color, ranges and labels for Guidelines
 */
export const progress = (riskType, guidelineRange, isMetric = true) => {
    const weightUnit = isMetric ? t("units.kilograms") : t("units.stones");

    switch (riskType) {
        case BODY_MASS_INDEX:
            return [
                {
                    color: theme.guidelines.bodyMassIndex.low.sisuPortal.hex,
                    label: "ranges.low",
                    range: guidelineRange.low.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_mass_index.low"
                },
                {
                    color: theme.guidelines.bodyMassIndex.normal.sisuPortal.hex,
                    label: "ranges.normal",
                    range: guidelineRange.normal.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_mass_index.normal"
                },
                {
                    color: theme.guidelines.bodyMassIndex.elevated.sisuPortal.hex,
                    label: "ranges.elevated",
                    range: guidelineRange.elevated.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_mass_index.elevated"
                },
                {
                    color: theme.guidelines.bodyMassIndex.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_mass_index.high"
                },
                {
                    color: theme.guidelines.bodyMassIndex.veryHigh.sisuPortal.hex,
                    label: "ranges.very_high",
                    range: guidelineRange.veryHigh.range,
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.body_mass_index.very_high"
                }
            ];

        case HEART_RATE:
            return [
                {
                    color: theme.guidelines.SiSUHeartRate.low.sisuPortal.hex,
                    label: "ranges.low",
                    range: guidelineRange.low.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.heart_rate.low"
                },
                {
                    color: theme.guidelines.SiSUHeartRate.normal.sisuPortal.hex,
                    label: "ranges.normal",
                    range: guidelineRange.normal.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.heart_rate.normal"
                },
                {
                    color: theme.guidelines.SiSUHeartRate.elevated.sisuPortal.hex,
                    label: "ranges.elevated",
                    range: guidelineRange.elevated.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.heart_rate.elevated"
                },
                {
                    color: theme.guidelines.SiSUHeartRate.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.heart_rate.high"
                },
                {
                    color: theme.guidelines.SiSUHeartRate.veryHigh.sisuPortal.hex,
                    label: "ranges.very_high",
                    range: guidelineRange.veryHigh.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.heart_rate.very_high"
                }
            ];

        case BODY_FAT:
            return [
                {
                    color: theme.guidelines.bodyFat.low.sisuPortal.hex,
                    label: "ranges.low",
                    range: guidelineRange.low.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_fat.low"
                },
                {
                    color: theme.guidelines.bodyFat.normal.sisuPortal.hex,
                    label: "ranges.normal",
                    range: guidelineRange.normal.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_fat.normal"
                },
                {
                    color: theme.guidelines.bodyFat.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_fat.high"
                },
                {
                    color: theme.guidelines.bodyFat.veryHigh.sisuPortal.hex,
                    label: "ranges.very_high",
                    range: guidelineRange.veryHigh.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.body_fat.very_high"
                }
            ];

        case DIABETES:
            return [
                {
                    color: theme.guidelines.AustralianDiabetesRisk.low.sisuPortal.hex,
                    label: "ranges.low",
                    range: guidelineRange.low.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.diabetes.low"
                },
                {
                    color: theme.guidelines.AustralianDiabetesRisk.intermediate.sisuPortal.hex,
                    label: "ranges.intermediate",
                    range: guidelineRange.intermediate.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.diabetes.intermediate"
                },
                {
                    color: theme.guidelines.AustralianDiabetesRisk.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.diabetes.high"
                }
            ];

        case Guidelines.PSS4.RiskCode:
            return [
                {
                    color: theme.guidelines.PSS4.low.sisuPortal.hex,
                    label: "ranges.low",
                    range: guidelineRange.low.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.stress.low"
                },
                {
                    color: theme.guidelines.PSS4.moderate.sisuPortal.hex,
                    label: "ranges.intermediate",
                    range: guidelineRange.intermediate.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.stress.moderate"
                },
                {
                    color: theme.guidelines.PSS4.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.stress.high"
                }
            ];

        case WEIGHT:
            return [
                {
                    color: theme.guidelines.weight.low.sisuPortal.hex,
                    label: "ranges.low",
                    range: guidelineRange.low.range + ` (${weightUnit})`,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.weight.low"
                },
                {
                    color: theme.guidelines.weight.normal.sisuPortal.hex,
                    label: "ranges.normal",
                    range: guidelineRange.normal.range + ` (${weightUnit})`,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.weight.normal"
                },
                {
                    color: theme.guidelines.weight.elevated.sisuPortal.hex,
                    label: "ranges.elevated",
                    range: guidelineRange.elevated.range + ` (${weightUnit})`,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.weight.elevated"
                },
                {
                    color: theme.guidelines.weight.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range + ` (${weightUnit})`,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.weight.high"
                },
                {
                    color: theme.guidelines.weight.veryHigh.sisuPortal.hex,
                    label: "ranges.very_high",
                    range: guidelineRange.veryHigh.range + ` (${weightUnit})`,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.weight.very_high"
                }
            ];

        case BLOOD_PRESSURE:
            return [
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.optimal.sisuPortal.hex,
                    range: guidelineRange.optimal.range,
                    label: "ranges.optimal",
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.blood_pressure.optimal"
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.normal.sisuPortal.hex,
                    label: "ranges.normal",
                    range: guidelineRange.normal.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.blood_pressure.normal"
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.highNormal.sisuPortal.hex,
                    range: guidelineRange.highNormal.range,
                    label: "ranges.high_normal",
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.blood_pressure.high_normal"
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.mildHypertension.sisuPortal.hex,
                    label: "ranges.mild_hypertension",
                    range: guidelineRange.mildHypertension.range,
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.blood_pressure.mild_hypertension"
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.moderateHypertension.sisuPortal.hex,
                    label: "ranges.moderate_hypertension",
                    range: guidelineRange.moderateHypertension.range,
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.blood_pressure.moderate_hypertension"
                },
                {
                    color: theme.guidelines.NationalHeartFoundationAustralia.severeHypertension.sisuPortal.hex,
                    range: guidelineRange.severeHypertension.range,
                    label: "ranges.severe_hypertension",
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.blood_pressure.severe_hypertension"
                }
            ];

        case QRISK3:
            return [
                {
                    color: theme.guidelines.Qrisk.low.sisuPortal.hex,
                    range: guidelineRange.low.range,
                    label: "ranges.low",
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.qrisk.low"
                },
                {
                    color: theme.guidelines.Qrisk.elevated.sisuPortal.hex,
                    range: guidelineRange.elevated.range,
                    label: "ranges.elevated",
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.qrisk.elevated"
                }
            ];

        case ALCOHOL_RISK:
            return [
                {
                    color: theme.guidelines.Alcohol.noRisk.sisuPortal.hex,
                    range: guidelineRange.noRisk.range,
                    label: "ranges.no_risk",
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.alcohol_risk.no_risk"
                },
                {
                    color: theme.guidelines.Alcohol.low.sisuPortal.hex,
                    range: guidelineRange.low.range,
                    label: "ranges.low",
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.alcohol_risk.low"
                },
                {
                    color: theme.guidelines.Alcohol.elevated.sisuPortal.hex,
                    label: "ranges.elevated",
                    range: guidelineRange.elevated.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.alcohol_risk.elevated"
                },
                {
                    color: theme.guidelines.Alcohol.high.sisuPortal.hex,
                    label: "ranges.high",
                    range: guidelineRange.high.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.alcohol_risk.high"
                },
                {
                    color: theme.guidelines.Alcohol.veryHigh.sisuPortal.hex,
                    label: "ranges.very_high",
                    range: guidelineRange.veryHigh.range,
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.alcohol_risk.no_risk.very_high"
                }
            ];

        case ACTIVITY:
            return [
                {
                    color: theme.guidelines.Activity.active.sisuPortal.hex,
                    label: "ranges.active"
                },
                {
                    color: theme.guidelines.Activity.moderatelyActive.sisuPortal.hex,
                    label: "ranges.moderately_inactive"
                },
                {
                    color: theme.guidelines.Activity.moderatelyInactive.sisuPortal.hex,
                    label: "ranges.moderately_inactive"
                },
                {
                    color: theme.guidelines.Activity.inactive.sisuPortal.hex,
                    label: "ranges.inactive"
                }
            ];

        case HRA_HEALTH_SCORE:
            return [
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["increasedRisk"].sisuPortal.hex,
                    label: "ranges.increased_risk",
                    range: guidelineRange.increasedRisk.range,
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.hra_health_score.increased_risk"
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["atRisk"].sisuPortal.hex,
                    label: "ranges.at_risk",
                    range: guidelineRange.atRisk.range,
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.hra_health_score.at_risk"
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["moderate"].sisuPortal.hex,
                    label: "ranges.moderate",
                    range: guidelineRange.moderate.range,
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.hra_health_score.moderate"
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["good"].sisuPortal.hex,
                    range: guidelineRange.good.range,
                    label: "ranges.good",
                    recommendation: "members_portal_web.utilities.progress_bar.recommendations.hra_health_score.good"
                },
                {
                    color: theme.guidelines["SiSUHealthRiskAssessment"]["excellent"].sisuPortal.hex,
                    range: guidelineRange.excellent.range,
                    label: "ranges.excellent",
                    recommendation:
                        "members_portal_web.utilities.progress_bar.recommendations.hra_health_score.excellent"
                }
            ];

        case MUSCLE_MASS:
            return [
                {
                    color: theme.guidelines.muscleMass.low.sisuPortal.hex,
                    range: guidelineRange.low.rangeInfo,
                    label: "ranges.low"
                },
                {
                    color: theme.guidelines.muscleMass.normal.sisuPortal.hex,
                    range: guidelineRange.normal.rangeInfo,
                    label: "ranges.normal"
                },
                {
                    color: theme.guidelines.muscleMass.high.sisuPortal.hex,
                    range: guidelineRange.high.rangeInfo,
                    label: "ranges.high"
                },
                {
                    color: theme.guidelines.muscleMass.veryHigh.sisuPortal.hex,
                    range: guidelineRange.veryHigh.rangeInfo,
                    label: "ranges.very_high"
                }
            ];

        default:
            return null;
    }
};

// Regex to match "< Number1" or "Number1 +" or "Number1 - Number2"
const NUMERIC_REGEXP = /<([\s\d.]+)|([\s\d.]+)[%]*[+]+|([\s\d.]+)[%\s]*-\s*([\s\d.]+)/;

/**
 *
 * @param {number} index index of range bucket
 * @param {number} currentValue Current value of Metric
 * @param {number} size total number of range buckets
 * @param {String} range Range of Guideline
 * @param {String} type Type of Guideline
 * @return {String} String to calculate left position of Tooltip
 */
export const tooltipPosition = (index, currentValue, range, type, width) => {
    const group = range ? range.match(NUMERIC_REGEXP) : [];

    const totalBarWidth = index * width;

    const defaultLeftPosition = totalBarWidth + width / 2;

    let lowerRange, higherRange;
    try {
        if (group[1]) {
            lowerRange = 0;
            higherRange = Number.parseFloat(group[1].trim());
        } else if (group[2]) {
            lowerRange = Number.parseFloat(group[2].trim());
            higherRange = 2 * currentValue;
        } else if (group[3] && group[4]) {
            lowerRange = Number.parseFloat(group[3].trim());
            higherRange = Number.parseFloat(group[4].trim());
        } else {
            return defaultLeftPosition;
        }

        if ([BLOOD_PRESSURE, ACTIVITY].includes(type)) return defaultLeftPosition;
    } catch (e) {
        return defaultLeftPosition;
    }

    const bucketRange = higherRange - lowerRange;
    const bucketValue = currentValue - lowerRange;
    const isCurrentValueGTHalfOfBucketSize = bucketValue > bucketRange / 2;

    const calcLeftPosition = totalBarWidth + (width / bucketRange) * bucketValue;
    const endPointsDiff = higherRange === currentValue ? -8 : lowerRange === currentValue ? 4 : 0;
    return calcLeftPosition + (isCurrentValueGTHalfOfBucketSize ? -4 : 1) + endPointsDiff;
};

/**
 * Returns a parsed range string for weight (in st-lbs)
 * @param {string} range
 * @returns {string}
 */
export const parseRangeForWeightInStLbs = range => {
    const group = range ? range.match(NUMERIC_REGEXP) : [];
    let lowerRange, higherRange, rangeStr;

    try {
        if (group[1]) {
            lowerRange = 0;
            higherRange = Number.parseFloat(group[1].trim());
            rangeStr = group[0].replace(group[1], getStLbsString(higherRange));
        } else if (group[2]) {
            lowerRange = Number.parseFloat(group[2].trim());
            higherRange = 2 * Number.parseFloat(group[2].trim());
            rangeStr = group[0].replace(group[2], getStLbsString(lowerRange));
        } else if (group[3] && group[4]) {
            lowerRange = Number.parseFloat(group[3].trim());
            higherRange = Number.parseFloat(group[4].trim());
            rangeStr = group[0].replace(group[3], getStLbsString(lowerRange));
            rangeStr = rangeStr.replace(group[4], getStLbsString(higherRange));
        } else return range;
    } catch (e) {
        return range;
    }
    return rangeStr;
};

/**
 * Returs converted value to st-lbs
 * @param {Number} value
 * @returns {string}
 */
const getStLbsString = value => {
    const { stones, pounds } = UnitConverter.poundsToStLbs(value);
    return `${stones} st ${fixedNumber(pounds)} lbs `;
};
