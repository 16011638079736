import React from "react";
import styled from "styled-components";
import { media } from "../../themes";
import { Flex } from "rebass";
import withPrintable from "../withPrintable";

const StyledCardBase = styled.div`
  display: flex;
  flex-grow: 1; // consume as  much space as given
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: stretch;
  align-content: flex-start;
  justify-content: space-between;
  min-height: ${props => (props.tall ? "220px" : "100px")};
  //min-width: ${props => props.theme.cards.width - 40 + "px"};
  background: ${props => props.theme.colours.flat.white.hex};
  ${props => !props.printable && props.theme.shadowCss.rest()};
  border-radius: ${props => props.theme.defaults.borderRadius};
  ${props => (props.printable ? `border: 1px ${props.printable && props.theme.colours.flat.gray.hex} solid;` : "")}
  
  ${media.mobile`
    // need adaptive height for cards in mobile view e.g. recommendations
    height: auto;
  `}
`;

// eslint-disable-next-line react/prop-types
const FlexBase = ({ children, ...rest }) => (
    <Flex flexDirection="column" width="100%">
        <StyledCardBase {...rest} style={{ ...rest }}>
            {children}
        </StyledCardBase>
    </Flex>
);

export default withPrintable(FlexBase);
