import Question from "./Question";

/**
 *
 */
export default class QuestionGroup {
    /**
     *
     * @param schema {Object}
     * @param survey {Survey}
     */
    constructor(schema, survey) {
        this._schema = schema;
        this._survey = survey;
        this._previousQuestionGroup = null;
        this._nextQuestionGroup = null;

        this._schema.surveyQuestions.sort(this.sortByPosition);

        // questions
        let previousQuestion = null;
        this._questions = this._schema.surveyQuestions.map(questionSchema => {
            let questionModel = new Question(questionSchema, this);
            if (previousQuestion) {
                questionModel.previousQuestion = previousQuestion;
            }

            previousQuestion = questionModel;
            return questionModel;
        });

        this._firstQuestion = this._questions.length > 0 ? this._questions[0] : /* istanbul ignore next */ null;
        this._lastQuestion =
            this._questions.length > 0 ? this._questions[this._questions.length - 1] : /* istanbul ignore next */ null;
    }

    /**
     *
     * @returns {Survey}
     */
    getSurvey() {
        return this._survey;
    }

    /**
     *
     * @returns {Array<Question>}
     */
    getQuestions() {
        return this._questions;
    }

    /**
     * Get the next question to be answered
     *
     * @returns {Question}
     */
    getNextUnansweredQuestion() {
        let nextQuestion = null;

        for (let question of this.getQuestions()) {
            if (!question.hasBeenAnswered() && question.canBeAsked()) {
                nextQuestion = question;
                break;
            }
        }

        return nextQuestion;
    }

    getQuestionByQuestionId(questionId) {
        let question = null;

        for (let i = 0; i < this.getQuestions().length; i++) {
            let searchQuestion = this.getQuestions()[i];
            if (searchQuestion.getQuestionId() === questionId) {
                question = searchQuestion;
                break;
            }
        }

        return question;
    }

    getQuestionByQuestionCode(questionCode) {
        let question = null;

        for (let i = 0; i < this.getQuestions().length; i++) {
            let searchQuestion = this.getQuestions()[i];
            if (searchQuestion.getCode() === questionCode) {
                question = searchQuestion;
                break;
            }
        }

        return question;
    }

    /**
     *
     * @param code
     * @returns {boolean}
     * @private
     */
    // _isDisplayType(code) {
    //     return this._schema.displayType.code === code;
    // }

    /**
     * TODO: replace with this._isDisplayType('SINGLE_QUESTION');
     *
     * @returns {boolean}
     */
    isDisplayTypeOneQuestionAtATime() {
        return true;
    }

    /**
     * There are no more questions to be asked
     *
     * @returns {boolean}
     */
    isComplete() {
        return this.getNextUnansweredQuestion() === null;
    }

    /**
     *
     * @returns {Number}
     */
    getQuestionGroupId() {
        return this._schema.surveyQuestionGroupId;
    }

    getPosition() {
        return this._schema.position;
    }

    toJSON() {
        let pojo = {
            schema: this._schema
        };

        pojo.questions = this.getQuestions().map(question => question.toJSON());

        return pojo;
    }

    /**
     * Sort questions by the position in their schema
     *
     * @param a <{}>
     * @param b <{}>
     * @returns {number}
     */
    // No point
    /* istanbul ignore next */
    sortByPosition(a, b) {
        if (a.position === b.position) {
            return 0;
        } else {
            return a.position < b.position ? -1 : 1;
        }
    }

    /**
     * @param {QuestionGroup} questionGroup
     */
    set previousQuestionGroup(questionGroup) {
        if (
            !this._previousQuestionGroup ||
            this._previousQuestionGroup.getQuestionGroupId() !== questionGroup.getQuestionGroupId()
        ) {
            this._previousQuestionGroup = questionGroup;
            questionGroup.nextQuestionGroup = this;
        }
    }

    /**
     *
     * @returns {QuestionGroup|null}
     */
    get previousQuestionGroup() {
        return this._previousQuestionGroup;
    }

    /**
     *
     * @param {QuestionGroup} questionGroup
     */
    set nextQuestionGroup(questionGroup) {
        if (
            !this._nextQuestionGroup ||
            this._nextQuestionGroup.getQuestionGroupId() !== questionGroup.getQuestionGroupId()
        ) {
            this._nextQuestionGroup = questionGroup;
            questionGroup.previousQuestionGroup = this;
        }
    }

    /**
     *
     * @returns {QuestionGroup|null}
     */
    get nextQuestionGroup() {
        return this._nextQuestionGroup;
    }

    /**
     *
     * @returns {Question|null}
     */
    get firstQuestion() {
        return this._firstQuestion;
    }

    /**
     *
     * @returns {Question|null}
     */
    get lastQuestion() {
        return this._lastQuestion;
    }
}
