import Payments from "./payments";
import { Ads } from "./ads";
import AppVersion from "./appVersion";
import Auth from "./auth";
import { Campaigns } from "./campaigns";
import Citizen from "./citizen/Citizen";
import ClientAddresses from "./clientAddresses/ClientAddresses";
import { Consents } from "./consents";
import CustomerRequests from "./customerRequests/CustomerRequests";
import { DoctorsOnDemand } from "./doctorsOnDemand";
import { HealthCheck } from "./healthChecks";
import { HealthStation } from "./healthStation";
import Momentum from "./momentum";
import { Recommendations } from "./recommendations";
import Response from "./response/Response";
import { Root } from "./root";
import ScriptsNow from "./scriptsNow";
import { Survey } from "./survey";
import SurveyResponses from "./surveyResponses";
import Tokens from "./tokens";
import UserJourney from "./userJourney/UserJourney";
import { UserSubscriptions } from "./subscriptions";
import Users from "./users";
import Ectosense from "./ectosense";
import Medication from "./medication";
import Programs from "./programs";
import jwtDecode from "jwt-decode";

export default class Client {
    constructor(config) {
        this._init();
        this.config = config;
        this.authToken = null;
    }

    static get Origins() {
        return {
            station: "station",
            portal: "portal",
            scripts: "scriptsnow",
            pharmacy: "pharmacy"
        };
    }

    static get ClientTypes() {
        return {
            USER: "USER",
            STATION: "STATION"
        };
    }

    _init() {
        this.ads = new Ads(this);
        this.auth = new Auth(this);
        this.campaigns = new Campaigns(this);
        this.citizen = new Citizen(this);
        this.consents = new Consents(this);
        this.healthChecks = new HealthCheck(this);
        this.healthStation = new HealthStation(this);
        this.recommendations = new Recommendations(this);
        this.response = new Response(this);
        this.surveys = new Survey(this);
        this.tokens = new Tokens(this);
        this.userJourneys = new UserJourney(this);
        this.userSubscriptions = new UserSubscriptions(this);
        this.dod = new DoctorsOnDemand(this);
        this.root = new Root(this);
        this.customerRequests = new CustomerRequests(this);
        this.clientAddresses = new ClientAddresses(this);
        this.appVersion = new AppVersion(this);
        this.surveyResponses = new SurveyResponses(this);
        this.users = new Users(this);
        this.scriptsNow = new ScriptsNow(this);
        this.momentum = new Momentum(this);
        this.ectosense = new Ectosense(this);
        this.payments = new Payments(this);
        this.medication = new Medication(this);
        this.programs = new Programs(this);
    }

    setAuthToken(token) {
        this.authToken = token;
        return this;
    }

    extractAuthData() {
        const payload = jwtDecode(this.authToken);
        return {
            uuid: payload["custom:uuid"],
            email: payload.email
        };
    }
}
