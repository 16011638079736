import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Flex, Box } from "rebass";
import theme from "../../themes";
import Icon from "../Icons";
import useGooglePlaces from "./useGooglePlaces";
import { pathOr } from "ramda";

const attachGoogleLib = apiKey => {
    if (typeof window === "undefined") {
        return;
    }

    const id = "google-places";
    if (!document.querySelector(`#${id}`)) {
        const url = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=Function.prototype`;
        const script = document.createElement("script");
        const parent = document.querySelector("head");
        script.setAttribute("async", "");
        script.setAttribute("id", id);
        script.src = url;
        parent.appendChild(script);
    }
};

// Note: the default api key is IP restricted and is only intended to be used for development
attachGoogleLib(process.env.REACT_APP_GOOGLE_API_KEY || "AIzaSyD-rWugDAkFTiN5-Kunx4GuI3dP9Dnk_c0");

const Main = styled(Autocomplete)`
    #google-places-autocomplete {
        font-family: Proxima Nova Semibold, Arial, sans-serif;
        font-size: ${props => props.fontSize};
    }
`;

const Option = styled(Flex)`
    font-family: Proxima Nova Regular, Arial, sans-serif;
`;

const MatchingOptionText = styled.span`
    font-family: Proxima Nova Semibold, Arial, sans-serif;
`;

const NormalOptionText = styled.span`
    font-family: Proxima Nova Regular, Arial, sans-serif;
`;

const GooglePlaces = forwardRef(
    (
        {
            onInputFocus = () => {},
            onInputChange = () => {},
            fontSizes = { input: "1.8rem", option: "1.6rem", optionSecondary: "0.9rem" },
            autoFocus = true
        },
        ref
    ) => {
        const [inputValue, setInputValue] = useState("");
        const [options, setOptions] = useState([]);
        const [selectedOption, setSelectedOptions] = useState(null);
        const [locationInfo, setLocationInfo] = useState(null);
        const inputRef = useRef(null);
        useImperativeHandle(ref, () => inputRef.current);

        const { refreshSessionToken, getPlacePredictions, getAddressComponent } = useGooglePlaces();

        const handleInput = event => {
            setInputValue(event.target.value);
        };

        const handleChange = (event, option) => {
            setInputValue(option ? option.description || "" : "");
            setSelectedOptions(option);
        };

        useEffect(() => {
            let inProgress = true;

            getPlacePredictions(inputValue, ["au"])
                .then(results => {
                    if (!inProgress) return;
                    setOptions(results);
                })
                .catch(() => setOptions([]));

            return () => (inProgress = false);
        }, [inputValue]);

        useEffect(() => {
            let inProgress = true;

            if (selectedOption == null || selectedOption.place_id == null) {
                setLocationInfo(null);
                return;
            }

            getAddressComponent(selectedOption.place_id)
                .then(results => {
                    const addressComponent = pathOr(null, ["address_components"], results);
                    if (!inProgress || addressComponent == null) return;

                    const info = {
                        placeId: selectedOption.place_id,
                        description: selectedOption.description,
                        address: results.address_components
                    };

                    setLocationInfo(info);
                    refreshSessionToken();
                })
                .catch(() => setLocationInfo(null));

            return () => (inProgress = false);
        }, [selectedOption]);

        useEffect(() => {
            onInputChange({ inputValue, selectedOption, locationInfo });

            return () => {};
        }, [inputValue, selectedOption, locationInfo]);

        const parseOption = option => {
            let matches, parts, secondary;
            switch (true) {
                case option == null || option.description == null:
                    parts = [];
                    secondary = "";
                    break;

                case option.structured_formatting == null:
                    matches = option.matched_substrings;
                    parts = parse(
                        option.description,
                        matches.map(match => [match.offset, match.offset + match.length])
                    );
                    secondary = "";
                    break;

                default:
                    matches = option.structured_formatting.main_text_matched_substrings;
                    parts = parse(
                        option.structured_formatting.main_text,
                        matches.map(match => [match.offset, match.offset + match.length])
                    );
                    secondary = option.structured_formatting.secondary_text;
            }

            return { parts, secondary };
        };

        const renderOption = option => {
            const { parts, secondary } = parseOption(option);
            return (
                <Option>
                    <Icon type={theme.icons.types.findStation} size={theme.icons.sizes.small} />
                    <Flex flexDirection={"column"}>
                        <Box fontSize={fontSizes.option}>
                            {parts.map((part, index) =>
                                part.highlight ? (
                                    <MatchingOptionText key={index}>{part.text}</MatchingOptionText>
                                ) : (
                                    <NormalOptionText key={index}>{part.text}</NormalOptionText>
                                )
                            )}
                        </Box>
                        <Box fontSize={fontSizes.optionSecondary}>{secondary}</Box>
                    </Flex>
                </Option>
            );
        };

        return (
            <Main
                id="google-places-autocomplete"
                getOptionLabel={option => (typeof option === "string" ? option : option.description)}
                getOptionSelected={(option, value) => {
                    return option.description === value;
                }}
                openOnFocus={true}
                filterOptions={x => x}
                options={options}
                autoComplete
                includeInputInList
                onInput={handleInput}
                onChange={handleChange}
                value={inputValue}
                selectOnFocus={true}
                fontSize={fontSizes.input}
                renderInput={params => (
                    <TextField
                        {...params}
                        label=""
                        placeholder={"Type your address"}
                        variant="standard"
                        fullWidth
                        inputRef={inputRef}
                        autoFocus={autoFocus}
                        onFocus={() => onInputFocus()}
                    />
                )}
                renderOption={renderOption}
            />
        );
    }
);

GooglePlaces.propTypes = {
    onInputFocus: PropTypes.func,
    onInputChange: PropTypes.func,
    fontSizes: PropTypes.object,
    autoFocus: PropTypes.bool
};

export { useGooglePlaces };
export default withTheme(GooglePlaces);
