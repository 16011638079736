import React, { useEffect } from "react";

import { Loading } from "@sisuwellness/web-components";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const LoadingView = ({ minimumDisplayTime, onMinimumDisplayTimeReached, title, message }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if (minimumDisplayTime) {
            setTimeout(() => onMinimumDisplayTimeReached(), minimumDisplayTime);
        }
    });

    const loadingMessage = message || t("members_portal_web.components.loading_value.message");
    const loadingTitle = title || t("words.loading") + "...";

    return <Loading title={loadingTitle} message={loadingMessage} height={"80vh"} />;
};

LoadingView.displayName = "LoadingView";
LoadingView.propTypes = {
    theme: PropTypes.any.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    minimumDisplayTime: PropTypes.number,
    onMinimumDisplayTimeReached: PropTypes.func
};

export default withTheme(LoadingView);
