import styled from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";

export const Header = styled.div`
    margin: 0px 0 32px 0;
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    font-size: ${props => props.theme.fontSize[34].px};
    ${props =>
        mediaQueryForCss({
            "font-size": ["", props.theme.fontSize[28].px]
        })}
`;
export const NavItem = styled.div`
    display: flex;
    height: ${props => (props.thin ? "90px" : "180px")};
    align-items: center;
    margin-bottom: 16px;
    border-radius: 10px;
    padding: 0 70px 0 ${props => (props.thin ? "20px" : "40px")};
    background-repeat: no-repeat;
    background-position-x: right;
    justify-content: space-between;
    box-shadow: ${props => props.theme.portalShadows.card2};
    background-image: ${props => props.item.backgroundImage};
    background-color: ${props => props.theme.colours.flat.white.hex};
    ${props =>
        mediaQueryForCss({
            height: [props.thin ? "90px" : "180px", props.thin ? "70px" : "120px"],
            padding: ["0 70px 0 40px", "0 15px 0 20px"],
            "background-image": [props.item.backgroundImage, props.item.backgroundImageMobile]
        })}
`;
export const NavHeading = styled.div`
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    font-size: ${props => props.theme.fontSize[props.thin ? 34 : 28].px};
    ${props =>
        mediaQueryForCss({
            "font-size": ["", props.theme.fontSize[20].px]
        })}
`;
export const NavText = styled.div`
    margin-top: 8px;
    font-weight: 300;
    font-size: ${props => props.theme.fontSize[17].px};
    color: ${props => props.theme.colours.flat.blackTint.hex};
    ${props =>
        mediaQueryForCss({
            "font-size": ["", props.theme.fontSize[13].px],
            "font-weight": ["", "", "normal"]
        })}
`;
export const NavImage = styled.img`
    width: ${props => (props.thin ? "75px" : "100px")};
    ${props =>
        mediaQueryForCss({
            width: [props.thin ? "75px" : "100px", props.thin ? "50px" : "60px"]
        })}
`;

export const DefaultStyle = styled.div`
    text-align: left;
    color: ${props => props.theme.colours.blackTints700.hex};
`;
