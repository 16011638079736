import UserJourneyPageType from "./UserJourneyPageType";
import Survey from "../Survey/Survey";
import Consent from "../Consent/Consent";
import RuleSet from "../Rules/Components/RuleSet";

export default class UserJourneyPage {
    /**
     *
     * @param schema
     * @param userJourney {UserJourney}
     */
    constructor(schema, userJourney) {
        this._schema = schema;
        this._userJourney = userJourney;
        this._resolved = false;

        this._pageType = new UserJourneyPageType(this._schema.userJourneyPageType, this);

        this._survey = null;
        this._consent = null;
        if (this._pageType.isTypeSurvey()) {
            this._survey = new Survey(this._schema.survey, this);
        }

        if (this._pageType.isTypeConsent()) {
            this._consent = new Consent(this._schema.consent, this);
        }

        this._preConditionEvaluationResult = {
            valid: true,
            schema: null,
            validRuleGroups: []
        };
    }

    /**
     *
     * @returns {UserJourney}
     */
    getUserJourney() {
        return this._userJourney;
    }

    /**
     *
     * @param resolved
     * @returns {UserJourneyPage}
     */
    setResolved(resolved) {
        this._resolved = resolved;
        return this;
    }

    /**
     *
     * @returns {boolean}
     */
    isResolved() {
        return this._resolved === true;
    }

    /**
     *
     * @returns {number}
     */
    getUserJourneyPageId() {
        return this._schema.userJourneyPageId;
    }

    /**
     *
     * @returns {UserJourneyPageType}
     */
    getPageType() {
        return this._pageType;
    }

    /**
     *
     * @returns {Survey|null}
     */
    getSurvey() {
        return this._survey;
    }

    /**
     *
     * @returns {null|Consent}
     */
    getConsent() {
        return this._consent;
    }

    /**
     *
     * @returns {Object|null}
     */
    getConfigurationForPageType() {
        return this._schema.configuration[
            this.getPageType()
                .getCode()
                .toLowerCase()
        ];
    }

    /**
     *
     * @returns {number}
     */
    getPosition() {
        return this._schema.position;
    }

    /**
     * Returns the pre-condition evaluation result, if exists
     * @return {Object|null}
     */
    getPreConditionEvaluationResult() {
        return this._preConditionEvaluationResult;
    }

    /**
     * Stores the pre-condition evaluation result
     * @param {Object|null} preConditionEvaluationResult
     */
    setPreConditionEvaluationResult(preConditionEvaluationResult) {
        this._preConditionEvaluationResult = preConditionEvaluationResult;
    }

    toJSON() {
        let pojo = {};
        pojo.schema = this._schema;
        pojo.resolved = this._resolved;
        pojo.survey = this._survey ? this._survey.toJSON() : null; // FIXME: suppressed coverage
        /* istanbul ignore next */ pojo.consent = this._consent ? this._consent.toJSON() : null;
        pojo.preConditionEvaluationResult = this._preConditionEvaluationResult;

        return pojo;
    }

    /**
     * Evaluates the current User Journey Page with the optional pre-condition rule set
     *
     * @return {{valid:Boolean, schema:Object, validRuleGroups:[{name:String, icon:string, validRules:Array}]}|null}
     */
    evaluatePreConditionRuleSet() {
        this._preConditionEvaluationResult = {
            valid: true,
            schema: null,
            validRuleGroups: []
        };

        if (this._schema.preConditionRuleSet) {
            const ruleSetUtility = new RuleSet(this._schema.preConditionRuleSet);
            const answersForPreFill = this._userJourney.getResponse().getAnswersForPreFill();

            // FIXME PLZ
            // We should normalise this when we move answers to the Response object (SU-578)
            const answers = [];
            for (let questionCode in answersForPreFill) {
                const answer = answersForPreFill[questionCode];

                answers.push({
                    questionCode,
                    answers: answer
                });
            }

            if (answers.length > 0) {
                this._preConditionEvaluationResult = ruleSetUtility.validate(answers);
            }
        }

        return this._preConditionEvaluationResult;
    }
}
