import BaseEndPoint from "../BaseEndPoint";

export default class Recommendations extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/recommendations/" + path);
    }

    /**
     * @param {number} bmi
     * @param {number} height
     * @returns {*}
     */
    bodyMassIndexRecommendation(bmi, height) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("bodyMassIndex") + this.generateQueryString({ bmi, height }), settings);
    }

    /**
     * @param {number} systolic
     * @param {number} diastolic
     * @returns {*}
     */
    bloodPressureRecommendation(systolic, diastolic) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(
            this.getUrlPath("bloodPressure") + this.generateQueryString({ systolic, diastolic }),
            settings
        );
    }

    /**
     * @param {number} heartRate
     * @returns {*}
     */
    heartRateRecommendation(heartRate) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("heartRate") + this.generateQueryString({ heartRate }), settings);
    }

    /**
     * @param {number} age
     * @param {string} gender
     * @param {number} bodyFatPercent
     * @returns {*}
     */
    bodyFatRecommendation(age, gender, bodyFatPercent) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(
            this.getUrlPath("bodyFat") + this.generateQueryString({ age, gender, bodyFatPercent }),
            settings
        );
    }

    /**
     * @param {object} answers
     * @returns {*}
     */
    diabetesRiskRecommendation(answers) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("diabetesRisk") + this.generateQueryString(answers), settings);
    }
}
