import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import VisibilitySensor from "react-visibility-sensor";
import { Flex } from "rebass";

const TimelineElement = ({ id, children, icon, iconOnClick, position, className, visibilitySensorProps, ...rest }) => {
    const [visible, setVisible] = useState(true);

    function onVisibilitySensorChange(isVisible) {
        if (isVisible) {
            setVisible(true);
        }
    }

    return (
        <div
            {...rest}
            id={id}
            className={classNames(className, "vertical-timeline-element", {
                "vertical-timeline-element--left": position === "left",
                "vertical-timeline-element--right": position === "right",
                "vertical-timeline-element--no-children": children === ""
            })}
        >
            <VisibilitySensor {...visibilitySensorProps} onChange={onVisibilitySensorChange}>
                <div>
                    <span
                        onClick={iconOnClick}
                        className={`vertical-timeline-element-icon ${visible ? "bounce-in" : "is-hidden"}`}
                    >
                        {icon}
                    </span>
                    <Flex
                        className={`vertical-timeline-element-content ${visible ? "bounce-in" : "is-hidden"}`}
                        alignItems="center"
                    >
                        {children}
                    </Flex>
                </div>
            </VisibilitySensor>
        </div>
    );
};

TimelineElement.propTypes = {
    id: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    icon: PropTypes.element,
    iconOnClick: PropTypes.func,
    position: PropTypes.string,
    visibilitySensorProps: PropTypes.shape({})
};

TimelineElement.defaultProps = {
    visibilitySensorProps: { partialVisibility: true, offset: { bottom: 80 } }
};

export default TimelineElement;
