import styled from "styled-components";
import { Box } from "rebass";

export const BreadCrumb = styled(Box)`
    display: flex;
    font-size: 12px;
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    font-weight: 500;
    flex-wrap: wrap;
`;

export const BreadCrumbItem = styled.div`
    width: 100%;
    white-space: nowrap;
    text-transform: uppercase;

    &:hover {
        text-decoration: underline;
    }
`;

export const BreadCrumbNav = styled.div`
    margin: 0 4px;
`;
