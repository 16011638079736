import React from "react";
import PropTypes from "prop-types";

import { Box } from "rebass";

// ------------------------------------------------------------------------

import { PieContainer, PieText, PieCircle, PieBackground } from "./styled";

// -------------------------------------------------------------------------

const Pie = ({
    textProps,
    size = 75,
    circleProps,
    progress = 0,
    children = "",
    strokeWidth = 4,
    backgroundColor,
    bg = "transparent",
    textColor = "#151522",
    progressFgColor = "red",
    progressBgColor = "#e9eff3"
}) => {
    const origin = size / 2;
    const strokeLinecap = "round";
    const circumference = 2 * Math.PI * (origin - strokeWidth);
    const offset = ((100 - progress) / 100) * circumference;

    return (
        <Box style={{ position: "relative" }} size={size}>
            <PieBackground size={size - 3 * strokeWidth} bg={backgroundColor || bg} />
            <PieContainer width={size} height={size}>
                <PieCircle
                    stroke={progressBgColor}
                    strokeWidth={strokeWidth}
                    strokeLinecap={strokeLinecap}
                    {...circleProps}
                    cx={origin}
                    cy={origin}
                    r={origin - strokeWidth}
                />
                <PieCircle
                    stroke={progressFgColor}
                    strokeWidth={strokeWidth}
                    strokeLinecap={strokeLinecap}
                    {...circleProps}
                    cx={origin}
                    cy={origin}
                    r={origin - strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                />
            </PieContainer>
            <PieText color={textColor} {...textProps}>
                {children}
            </PieText>
        </Box>
    );
};

Pie.propTypes = {
    ...Box.propTypes,
    size: PropTypes.number,
    children: PropTypes.any,
    progress: PropTypes.number,
    textColor: PropTypes.string,
    strokeWidth: PropTypes.number,
    progressText: PropTypes.string,
    progressFgColor: PropTypes.string,
    progressBgColor: PropTypes.string,
    textProps: PropTypes.shape(PieText.propTypes),
    circleProps: PropTypes.shape(PieCircle.propTypes)
};

export default Pie;
