import Flex from "components/Flex";
import React from "react";

import { Image, Text } from "rebass";
import styled from "styled-components";

// ----------------------------------------------------------------

import { SectionHeaders } from "views/HeartAge/commonElements";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import { MediaQuery } from "@sisuwellness/web-components";
import { useUserHealthCheckData } from "views/HeartAge";
import { calculateHeartAgeParams } from "views/HeartAge/utils";
import { fixedNumber } from "utilities/commonUtils";
import { PORTAL_IMAGE } from "constants/imagePath";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------

const StyledCard = styled(Card)`
    width: 100%;
    height: auto;
    padding: 0px;
    border-radius: 10px;
    box-shadow: 0 2px 4px 0 rgba(50, 50, 71, 0.06), 0 2px 2px 0 rgba(50, 50, 71, 0.06);
`;

const HeartAgeMore = props => {
    const { latestHealthCheck } = useUserHealthCheckData();

    const { t } = useTranslation();

    const { age, gender = "", tenYearCvdRisk, tenYearCvdRiskNormal, tenYearCvdRiskOptimal } = calculateHeartAgeParams(
        latestHealthCheck
    );

    const differenceInRisk = fixedNumber(tenYearCvdRiskNormal - tenYearCvdRisk);
    let differenceString = "";

    if (differenceInRisk < 0) differenceString = `${Math.abs(differenceInRisk)}% higher than`;
    else if (differenceInRisk > 0) differenceString = `${Math.abs(differenceInRisk)}% lower than`;
    else differenceString = "the same as";

    const isGenderMale = gender.toLowerCase() === "male";

    return (
        <Flex fd="column" gap="16px">
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    {t("members_portal_web.views.heart_age.page.more.header")}
                </Text>
            </SectionHeaders>

            <HeadingLabel fontSize={["20px", "", "22px"]}>
                {t("members_portal_web.views.heart_age.page.more.did_you_know")}
            </HeadingLabel>

            <Flex jc="space-between" gap={["8px", "16px"]}>
                <StyledCard>
                    <Flex width="100%" gap="8px" px="24px">
                        <HeadingLabel fontSize={["15px", "", "17px"]} py="24px" data-testid="label-2">
                            {t("members_portal_web.views.heart_age.page.more.headings.0", {
                                gender: gender.toLowerCase(),
                                age: age,
                                percent: tenYearCvdRiskNormal,
                                differenceString: differenceString
                            })}
                        </HeadingLabel>
                        <MediaQuery devices={["desktop"]}>
                            <Image
                                alt="more-2"
                                src={`${PORTAL_IMAGE}/premium/heartAgeMore${isGenderMale ? "Male" : "Female"}.svg`}
                            />
                        </MediaQuery>
                    </Flex>
                </StyledCard>
                <StyledCard>
                    <Flex width="100%" gap="8px" px="24px">
                        <HeadingLabel fontSize={["15px", "", "17px"]} py="24px" data-testid="label-1">
                            {t("members_portal_web.views.heart_age.page.more.headings.1", {
                                percent: tenYearCvdRiskOptimal
                            })}
                        </HeadingLabel>
                        <MediaQuery devices={["desktop"]}>
                            <Image src={`${PORTAL_IMAGE}/premium/heartAgeMore1.svg`} alt="more-1" />
                        </MediaQuery>
                    </Flex>
                </StyledCard>
            </Flex>
        </Flex>
    );
};

export default HeartAgeMore;
