import React from "react";

import { Box, Flex } from "rebass";

// ----------------------------------------------------------------------------------------------

import { Line } from "@sisuwellness/web-components/components/Chart";
import { createYGridValues as createEquallySpacedValues } from "components/Templates/TrendChart/Line/utils";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import {
    calculateMuscleMassForAgeRange,
    RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE,
    RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS,
    RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS
} from "@sisuwellness/utilities/Guidelines/MuscleMass";
import { theme } from "@sisuwellness/web-components/components/Chart/Line";
import { baseTheme } from "@sisuwellness/web-components/theme";

import CustomPoint from "./CustomPoint";
import CustomLine from "./Line";
import Legend from "./Legend";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------------------------

// eslint-disable-next-line
const Simulation = ({ muscleMass, age }) => {
    const { t } = useTranslation();

    const dataToPlot = [
        RATE_OF_INCREASE_PER_YEAR_WITH_NO_RESISTANCE,
        RATE_OF_INCREASE_PER_YEAR_WITH_ONE_TO_TWO_SESSIONS,
        RATE_OF_INCREASE_PER_YEAR_WITH_TWO_TO_FOUR_SESSIONS
    ].map(resistanceTraining => ({
        id: resistanceTraining.ID,
        data: calculateMuscleMassForAgeRange(age, muscleMass, resistanceTraining.VALUE, 15).map(
            ({ age, muscleMass }) => ({
                x: age,
                y: muscleMass
            })
        )
    }));

    const xAxisValues = createEquallySpacedValues(age, age + 15, {
        parts: 6,
        rounded: true
    });

    return (
        <Box>
            <Flex flexDirection="column" bg="hpCardBackground">
                <HeadingLabel mt="32px" px="32px" fontWeight="500" alignSelf="flex-start" fontSize={["12px", "16px"]}>
                    {t("c_skeletal_muscle")} (%)
                </HeadingLabel>
                <Box width="100%" height={["50vh"]} pr={["16px", "32px"]}>
                    <Line
                        isInteractive={false}
                        data={dataToPlot}
                        xScale={{ type: "point" }}
                        yScale={{ type: "linear", min: 10, max: 50 }}
                        gridYValues={[10, 20, 30, 40, 50]}
                        useMesh={true}
                        axisBottom={{
                            tickSize: 0,
                            legend: "Age",
                            tickPadding: 20,
                            tickRotation: 0,
                            legendOffset: 10,
                            legendPosition: "end",
                            tickValues: xAxisValues
                        }}
                        axisLeft={{
                            tickSize: 0,
                            tickPadding: 10,
                            tickRotation: 0,
                            tickValues: [10, 20, 30, 40, 50]
                        }}
                        pointSymbol={props => <CustomPoint {...props} currentAge={age} />}
                        pointBorderWidth={2}
                        pointLabelYOffset={-12}
                        enablePoints={true}
                        layers={[
                            "grid",
                            "markers",
                            "axes",
                            "areas",
                            "crosshair",
                            CustomLine,
                            "points",
                            "slices",
                            "mesh",
                            "legends"
                        ]}
                        theme={{
                            ...theme,
                            axis: {
                                ...theme.axis,
                                legend: {
                                    text: {
                                        fontSize: 13,
                                        fontWeight: "bold",
                                        fill: baseTheme.colours.hpPrimaryPurple.hex
                                    }
                                }
                            }
                        }}
                    />
                </Box>
                <Legend />
            </Flex>
        </Box>
    );
};

Simulation.propTypes = {};

export default Simulation;
