import React from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router";
import { Flex, Image, Text } from "rebass";

//------------------------------------------------------------------------------

import routes from "constants/routes";
import { PORTAL_IMAGE } from "constants/imagePath";
import SummaryCard from "components/Cards/SummaryCard";
import { HeadingLabel as Title } from "@sisuwellness/ui/src/components/Labels";
import { MetricCardBtnSecondary } from "components/CommonComponents/Button";
import chroma from "chroma-js";
import { useTranslation } from "react-i18next";

//------------------------------------------------------------------------------

const SisuStationCard = ({ detailed = true, ...rest }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <Flex flexDirection={["column", "row"]} flexGrow={1} {...rest}>
            <SummaryCard
                data-testid="find-health-station"
                borderRadius={detailed ? ["10px 10px 0px 0px", "10px 0px 0px 10px"] : "10px"}
                cardHeader={
                    <Title fontSize="15px" fontWeight="normal" mb="16px" textAlign={detailed ? "left" : "center"}>
                        {t("members_portal_web.components.templates.metric_cards.sisu.title")}
                    </Title>
                }
                cardFooter={
                    <MetricCardBtnSecondary
                        display="block"
                        width="244px"
                        mx={detailed ? "unset" : "auto"}
                        onClick={() => history.push(routes.findHealthStations)}
                    >
                        {detailed
                            ? t("members_portal_web.components.templates.metric_cards.sisu.find_station.detailed")
                            : t("members_portal_web.components.templates.metric_cards.sisu.find_station.main")}
                    </MetricCardBtnSecondary>
                }
                cardBody={
                    <Flex flexDirection="column" alignItems={detailed ? "flex-start" : "center"}>
                        {detailed ? (
                            <Image
                                mr="auto"
                                width="386px"
                                display="block"
                                src={`${PORTAL_IMAGE}/healthHub/dashed-lines.svg`}
                            />
                        ) : (
                            <Image width="46px" src={`${PORTAL_IMAGE}/healthHub/station.svg`} />
                        )}
                        <Text
                            maxWidth="386px"
                            fontSize="13px "
                            fontWeight="light"
                            color="hpGrayishBlueText"
                            textAlign={detailed ? "left" : "center"}
                        >
                            {detailed ? (
                                <>
                                    <Text my="1em">
                                        {t(
                                            "members_portal_web.components.templates.metric_cards.sisu.journey.detailed.info"
                                        )}
                                    </Text>
                                    <Text my="1em">
                                        {t(
                                            "members_portal_web.components.templates.metric_cards.sisu.journey.detailed.prompt"
                                        )}
                                    </Text>
                                </>
                            ) : (
                                <Text as="p" my="1em">
                                    {t("members_portal_web.components.templates.metric_cards.sisu.journey.alt.info")}
                                </Text>
                            )}
                        </Text>
                    </Flex>
                }
            />
            {detailed && (
                <SummaryCard
                    width={["100%", "297px"]}
                    flexGrow={["1", "0"]}
                    bg={chroma("#cfddff")
                        .alpha(0.3)
                        .hex("rgba")}
                    borderRadius={["0px 0px 10px 10px", "0px 10px 10px 0px"]}
                    cardBody={
                        <Flex size="100%" alignItems="center" justify="center">
                            <Image m="auto" maxHeight="100%" src={`${PORTAL_IMAGE}/healthHub/station.svg`} />
                        </Flex>
                    }
                />
            )}
        </Flex>
    );
};

SisuStationCard.propTypes = {
    ...Flex.propTypes,
    detailed: PropTypes.bool
};

export default SisuStationCard;
