import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import SiSUKeyboard from "../SisuKeyboard";
import { Style } from "../base/Keyboard";

const Container = styled.div`
    background: ${props => props.theme.colours.flat.lightGray.hex};
    position: absolute;
    left: 0;
    bottom: 0;

    height: 38%;
    width: 100%;

    button {
        opacity: 1;
    }

    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.9));
    display: flex;
`;

const AttachedKeyboard = ({
    inputNode,
    onNext,
    style = Style.sisu,
    disableAlphabets = false,
    disableSymbols = false,
    uppercaseOnly = false,
    enableDecimal = false,
    nextEnabled = true
}) => {
    const props = {
        inputNode,
        onNext,
        layoutStyle: style,
        disableAlphabets,
        disableSymbols,
        isFirstLetterUppercase: true,
        uppercaseOnly,
        enableDecimal,
        nextEnabled
    };

    return inputNode ? (
        <Container>
            <SiSUKeyboard {...props} />
        </Container>
    ) : null;
};

AttachedKeyboard.propTypes = {
    inputNode: PropTypes.any,
    onNext: PropTypes.func,
    style: PropTypes.string,
    disableAlphabets: PropTypes.bool,
    disableSymbols: PropTypes.bool,
    uppercaseOnly: PropTypes.bool,
    enableDecimal: PropTypes.bool,
    nextEnabled: PropTypes.bool
};

export default withTheme(AttachedKeyboard);
