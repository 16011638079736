import React from "react";
import { ResponsiveLine } from "@nivo/line";
import { baseTheme } from "../../../theme";

export const theme = {
    textColor: baseTheme.colours.hpPrimaryPurple.hex,
    fontSize: 12,
    axis: {
        domain: {
            line: {
                stroke: baseTheme.colours.hpPrimaryPurple.hex,
                strokeWidth: 1
            }
        }
    },
    grid: {
        line: {
            stroke: baseTheme.chart.line.stroke,
            strokeWidth: baseTheme.chart.line.strokeWidth
        }
    }
};

const Line = ({ data, ...rest }) => <ResponsiveLine data={data} {...rest} />;

Line.propTypes = {
    ...ResponsiveLine.propTypes
};

Line.defaultProps = {
    margin: { top: 30, right: 20, bottom: 60, left: 80 },
    data: [],
    useMesh: true,
    crosshairType: "x",
    curve: "monotoneX",
    enableGridX: false,
    colors: [baseTheme.portalColor.hpPrimaryPurple.hex],
    lineWidth: 1,
    pointSize: 18,
    useArea: true,
    theme
};

export default Line;
