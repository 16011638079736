import styled, { css } from "styled-components";
import { Box } from "rebass";

export const QuestionTitle = styled(Box)`
    font-family: "Proxima Nova Alt Bold", Helvetica, Arial, sans-serif;
    font-size: 45px;
    color: ${props => props.theme.colours.flat.darkGray.hex};
    text-align: center;
`;

export const QuestionHelpText = styled(Box)`
    font-family: "Proxima Nova Alt", Helvetica, Arial, sans-serif;
    font-size: 36px;
    color: ${props => props.theme.colours.flat.darkGray.hex};
    text-align: center;
`;

const normalChoiceStyle = css`
    color: ${props => props.theme.colours.flat.darkGray.hex};
    background-color: ${props => props.theme.colours.flat.white.hex};
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
`;

const selectedChoiceStyle = css`
    background: ${props => props.theme.colours.flat.darkBlue.hex};
    color: ${props => props.theme.colours.flat.white.hex};
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
`;

export const QuestionChoice = styled.button`
    border: 3px solid ${({ theme }) => theme.colours.flat.darkGray.hex};
    border-radius: ${({ theme }) => theme.defaults.borderRadius};
    font-family: "Proxima Nova Alt", Helvetica, Arial, sans-serif;
    font-size: 30px;
    padding: 20px 10px;
    margin: 10px 0;
    width: 100%;
    max-width: 880px;

    @media (hover: hover) {
        :hover {
            background: ${props => props.theme.colours.flat.blue.hex};
            color: #fff;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
            border: 3px solid ${props => props.theme.colours.flat.blue.hex};
            width: ${props => props.width};
        }
    }

    :active {
        background: ${props => props.theme.colours.flat.darkBlue.hex};
        border: 3px solid ${props => props.theme.colours.flat.darkBlue.hex};
        color: ${props => props.theme.colours.flat.white.hex};
        box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.25);
        width: ${props => props.width};
    }

    :focus {
        outline: none;
    }

    ${({ isSelected }) => (isSelected ? selectedChoiceStyle : normalChoiceStyle)}
`;
