import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButton = styled.button`
    font-weight: normal;
    font-size: ${props => props.theme.text.heading4.sizeRem};
    background-color: ${props => props.theme.colours.flat.blue.hex};
    color: ${props => props.theme.colours.flat.white.hex};
    border-radius: 0.2em;
    padding: 0.5em 1.2em;
    min-width: 6em;
    width: ${props => (props.fullWidth ? "100%" : "auto")};
    border: 0;

    :hover {
        background-color: ${props => props.theme.colours.flat.blue.hex};
        ${props => props.theme.shadowCss.elevated()};
    }

    :active {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        background-color: ${props => props.theme.colours.flat.blue.hex};
        ${props => props.theme.shadowCss.elevated()};
    }

    :focus {
        outline: none;
    }

    :disabled {
        background-color: ${props => props.theme.colours.flat.gray.hex};
    }
`;

class PrimaryAction extends Component {
    static displayName = "Primary Action Button";
    static propTypes = {
        children: PropTypes.string.isRequired,
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        fullWidth: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        fullWidth: false
    };

    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        return (
            <StyledButton onClick={this.handleOnClick} disabled={this.props.disabled} fullWidth={this.props.fullWidth}>
                {this.props.children}
            </StyledButton>
        );
    }
}

export default styled(PrimaryAction)``;
export { StyledButton };
