import BaseEndPoint from "../BaseEndPoint";

export default class Survey extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/surveys/" + path);
    }
}
