import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(height) {
        this._height = height;
        this._validateHeightValue(this._height);
    }

    /**
     * Returns the given Height value
     * @returns {Number}
     */
    get() {
        return this._height;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "HEIGHT";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_HEIGHT";
    }

    /**
     * Validates the given Height value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateHeightValue(height) {
        if (!(typeof height === "number") || Number.isNaN(height) || height < 0) {
            throw new InvalidArgumentError("Height must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns recommendation string
     *
     * @returns {string}
     *
     */
    static recommendations() {
        return "recommendations.height";
    }
}
