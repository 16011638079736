import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import FlexBase from "../Cards/FlexBase";
import { media } from "../../themes";

const ImageContainer = styled.img`
    border-radius: 5px;
`;

const StyledFlexBase = styled(FlexBase)`
    background: ${props => props.background};
    align-items: center;
    justify-content: center;

    ${media.tablet`
        display: none;
    `}

    ${media.mobile`
        display: none;
    `}
`;

const AdSlot = ({ schema }) => {
    const { src, alt } = schema;
    return (
        <StyledFlexBase>
            <ImageContainer src={src} alt={alt} data-testid="adslot-double-height" />
        </StyledFlexBase>
    );
};

AdSlot.propTypes = {
    schema: PropTypes.object.isRequired
};

export default withTheme(AdSlot);
