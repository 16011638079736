import React from "react";
import PropTypes from "prop-types";

import { Flex, Image } from "rebass";
import { useHistory } from "react-router";

// ---------------------------------------------------------------------------------------------------------

import { HealthCardRecommendation } from "components/HealthCard/atomicComponents";
import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { OutcomeCardBtnSecondary } from "components/CommonComponents/Button";
import { getCtaText } from "utilities/premium";
import SummaryCard from "components/Cards/SummaryCard";
import { PORTAL_IMAGE } from "constants/imagePath";
import routes from "constants/routes";

import { BlurContainer, StyledMetricCardContainer } from "../../styled";
import CompositionGraph from "./compositionGraph";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------------------------------------------------------

const BodyCompositionOutcomeCard = ({ isPremium, value = {}, ...rest }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const { muscleMass, bodyFatPercent } = value;

    let muscleMassValue = muscleMass;

    if (!isPremium) {
        // Dummy Value for locked premium card state
        muscleMassValue = 12;
    }

    const handleOnClick = () =>
        history.push(isPremium && !muscleMassValue ? routes.findHealthStations : routes.bodyComposition);

    const recommendationText =
        muscleMassValue && isPremium
            ? t("members_portal_web.components.templates.outcome_cards.body_composition.recommendation_text.main")
            : t("members_portal_web.components.templates.outcome_cards.body_composition.recommendation_text.alt");

    return (
        <SummaryCard
            py="24px"
            height="324px"
            cardHeader={
                <Flex mb="8px" justifyContent="space-between">
                    <Heading>{t("words.body_composition")}</Heading>
                    {isPremium && (
                        <Heading fontSize="13px" fontWeight="bold" opacity="0.7">
                            {t("words.premium")}
                        </Heading>
                    )}
                </Flex>
            }
            cardFooter={
                <OutcomeCardBtnSecondary
                    id="body-composition"
                    onClick={handleOnClick}
                    data-testid="body-composition-cta"
                >
                    {t(getCtaText(muscleMassValue, isPremium))}
                </OutcomeCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" isPremium={isPremium}>
                    <Flex width="100%" maxWidth="215px" justifyContent="space-between">
                        <CompositionGraph value={muscleMassValue} bodyFatPercent={bodyFatPercent} />
                    </Flex>
                    {!isPremium && (
                        <BlurContainer mt="8px" top="18%" height="183px" alignItems="center" justifyContent="center">
                            <Image size="130px" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} alt="premium-lock" />
                        </BlurContainer>
                    )}
                    <HealthCardRecommendation fontSize="13px" mt="16px" text={recommendationText} textAlign="left" />
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

BodyCompositionOutcomeCard.propTypes = {
    title: PropTypes.string,
    isPremium: PropTypes.bool,
    value: PropTypes.object,
    indicator: PropTypes.shape({ label: PropTypes.string })
};

export default BodyCompositionOutcomeCard;
