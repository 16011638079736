import { mediaQueryForCss } from "utilities/mediaQuery";
import styled from "styled-components";
import { Text } from "rebass";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PortalButton = styled.button`
    display: block;

    width: ${props => (props.width ? props.width : "296px")};
    height: 52px;

    background-color: ${props =>
        props.noBackground ? props.theme.colours.flat.white.hex : props.theme.portalColor.hpPrimaryPurple.hex};
    border-radius: 64px;
    border: ${props => (props.noBackground ? "2px solid " + props.theme.portalColor.hpPrimaryPurple.hex : "none")};

    font-size: ${props => props.theme.fontSize[16].px};
    font-weight: ${props => props.theme.fontWeight.intermediate};
    letter-spacing: -1px;
    outline: none;
    color: ${props => (props.noBackground ? props.theme.portalColor.hpPrimaryPurple.hex : "white")};

    :disabled {
        background-color: ${props => props.theme.portalColor.hpPurple200.hex};
        color: ${props => props.theme.portalColor.hpPurple400.hex};
    }

    ${props =>
        mediaQueryForCss({
            width: ["", "", props.width ? props.width : "296px"]
        })}

    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: ${props => (props.justify ? props.justify : "center")};
    margin-top: ${props => props.mTop};
`;

export const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SisuLogo = styled.img`
    width: 228px;
    height: 87px;
    margin-bottom: ${props => (props.signUp ? "16px" : "48px")};
    ${props =>
        mediaQueryForCss({
            width: ["", "", "168px"],
            height: ["", "", "64px"],
            "margin-bottom": ["", "", props.signUp ? "32px" : "40px"]
        })}
`;

export const CardHeader = styled.p`
    font-size: ${props => props.theme.fontSize[34].px};
    line-height: 1.15;
    text-align: left;
    color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    margin: 0px;
    margin-bottom: ${props => (props.signUp ? "16px" : "32px")};
    font-weight: ${props => props.theme.fontWeight.intermediate};
    ${props =>
        mediaQueryForCss({
            "font-size": ["", "", props.signUp ? props.theme.fontSize[22].px : props.theme.fontSize[28].px],
            "line-height": ["", "", "1.14"]
        })}
`;

export const CardSubHeader = styled.p`
    font-size: ${props => props.theme.fontSize[22].px};
    line-height: 1.23;
    letter-spacing: -0.22px;
    text-align: left;
    color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    margin: 0px;
    font-weight: normal;
    ${props =>
        mediaQueryForCss({
            "font-size": ["", "", props.signUp ? props.theme.fontSize[13].px : props.theme.fontSize[20].px],
            "line-height": ["", "", props.signUp ? "normal" : "1.25"],
            "letter-spacing": ["", "", props.signUp ? "normal" : "-0.22px"],
            "margin-bottom": ["", "", props.signUp ? "15px" : "0"]
        })}
`;

export const MessageBox = styled.div`
    width: 100%;
    background-color: ${props =>
        props.error ? props.theme.portalColor.hpDarkPink2.hex : props.theme.portalColor.hpGreen.hex};
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    border-radius: 0px 0px 10px 10px;
    ${mediaQueryForCss({
        "max-height": ["", "", "107px"]
    })}
`;

export const MessageText = styled.p`
    font-size: ${props => props.theme.fontSize[20].px};
    font-weight: ${props => props.theme.fontWeight.light};
    line-height: 1.4;
    text-align: center;
    color: #ffffff;
    ${mediaQueryForCss({
        padding: ["", "", "25px 24px 25px 24px"]
    })}
`;

export const LoginCard = styled.div`
    width: 50%;
    max-width: 720px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 60px 108px 60px 108px;
    margin: 7% 0 7% 0;
    background-color: white;
    ${mediaQueryForCss({
        width: ["", "90%"],
        padding: ["", "", "56px 24px"]
    })}
    div form {
        margin-top: 16px;
        button {
            margin-bottom: 60px;
        }
    }
`;

export const FooterText = styled(Text)`
    font-size: ${props => props.theme.fontSize[18].px};
    line-height: 1.23;
    letter-spacing: -0.22px;
    color: ${props => props.theme.portalColor.hpPurple700.hex};
    margin: 0px;
    font-weight: ${props => props.theme.fontWeight.intermediate};
    a {
        text-decoration: none;
        color: ${props => props.theme.portalColor.hpPurple700.hex};
    }
`;

export const CardContainer = styled.div`
    width: 100%;
    min-height: calc(100vh - ${props => props.theme.defaults.headerHeight});
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    margin: 0px;
    padding: 0px;
    position: relative;
    ${props =>
        mediaQueryForCss({
            height: ["", "", (props.height - parseInt(props.theme.membersPortal.mobileHeaderHeight)).toString() + "px"]
        })}
`;

export const ErrorWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: ${props => (props.width ? props.width : "100%")};
    max-width: ${props => (props.maxWidth ? props.maxWidth : "100%")};
    top: -32px;
    position: absolute;
    border-radius: 0px 0px 10px 10px;
    padding: ${props => (props.padding ? props.padding : "0")};
    ${props =>
        mediaQueryForCss({
            width: ["", props.mobileWidth ? props.mobileWidth : "100%"],
            "max-height": ["", "", "107px"],
            padding: ["", "", "0"]
        })}
    background-color: ${props => (props.error ? props.theme.portalColor.hpDarkPink2.hex : "")};
`;
export const LoginWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - ${props => props.theme.defaults.headerHeight});
    position: relative;
`;

export const SignupCard = styled.div`
    width: 75%;
    max-width: 1110px;
    margin: 2% 0 2% 0;
    border-radius: 10px;
    padding: 30px 64px;
    background-color: ${props => props.theme.colours.flat.white.hex};
    ${mediaQueryForCss({
        width: ["", "100%"],
        padding: ["", "", "32px 23px"]
    })}

    .signup-form {
        flex-direction: "column";
    }
`;

export const PageTitle = styled.div`
    font-weight: 500;
    color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    font-size: ${props => props.theme.fontSize[34].px};
    ${props =>
        mediaQueryForCss({
            "font-size": ["", props.theme.fontSize[28].px]
        })}
`;

export const PageDescription = styled.div`
    margin-top: 16px;
    font-size: ${props => props.theme.fontSize[15].px};
    color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
`;
