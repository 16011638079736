import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { useLocation } from "react-router-dom";

//------------------------------------------------------------------------

import LeftNavBar from "./LeftNavBar";
import ThinHeader from "./ThinHeader";
import PageContainer from "./PageContainer";
import AuthenticatedHeader from "./AuthenticatedHeader";

//------------------------------------------------------------------------

import { isRouteStatic } from "utilities/leftNav";
import { mediaQueryForCss } from "utilities/mediaQuery";
import { MediaQuery } from "@sisuwellness/web-components";

//------------------------------------------------------------------------

const Container = styled.div`
    display: flex;
    overflow: hidden;
    ${mediaQueryForCss({
        "justify-content": ["", "", "center"]
    })}
`;

const LeftNavContainer = styled.div`
    z-index: 2;
    margin-top: 30px;
    background-color: white;
    height: 100%;
    border-radius: 0px 20px 0px 0px;
`;

const Wrapper = styled.div`
    background-color: transparent;
    min-width: 260px;
    max-width: 260px;
    position: ${props => (props.float ? "absolute" : "")};
    overflow: hidden;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    align-items: stretch;
`;

const HeaderWrapper = styled.div`
    margin: 0px 40px;
    ${mediaQueryForCss({
        margin: ["", "", "0px"]
    })}
`;

const renderWithContainerPages = (children, visible, currentRoute) => {
    let marginLeft = !isRouteStatic(currentRoute) ? "auto" : "0px";
    return (
        <Container>
            <MediaQuery devices={["desktop", "tablet"]}>
                <Wrapper float={!isRouteStatic(currentRoute)}>
                    {visible && (
                        <LeftNavContainer>
                            <LeftNavBar />
                        </LeftNavContainer>
                    )}
                </Wrapper>
            </MediaQuery>

            <PageContainer id={"page-container"} mL={marginLeft}>
                {children}
            </PageContainer>
        </Container>
    );
};

const BaseMemberViewWithMenu = ({ children, menu, withContainer }) => {
    const currentRoute = useLocation().pathname.toLowerCase();
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        if (!isRouteStatic(currentRoute)) {
            setVisible(false);
        }
    }, []);
    return (
        <>
            <ThinHeader show={menu} data-printid="base-member-menu-header" data-testid="base-member-with-menu">
                <HeaderWrapper>
                    <AuthenticatedHeader show={menu} visible={visible} setVisible={setVisible} />
                </HeaderWrapper>
            </ThinHeader>
            {withContainer ? (
                renderWithContainerPages(children, visible, currentRoute)
            ) : (
                <>
                    <Wrapper float>
                        {visible && (
                            <MediaQuery devices={["desktop", "tablet"]}>
                                <LeftNavContainer>
                                    <LeftNavBar />
                                </LeftNavContainer>
                            </MediaQuery>
                        )}
                    </Wrapper>
                    {children}
                </>
            )}
        </>
    );
};

BaseMemberViewWithMenu.displayName = "Page Container With Member Menu";
BaseMemberViewWithMenu.propTypes = {
    children: PropTypes.any,
    menu: PropTypes.bool,
    withContainer: PropTypes.bool.isRequired
};

BaseMemberViewWithMenu.defaultProps = {
    menu: true,
    withContainer: true
};

export default BaseMemberViewWithMenu;
