import React, { useState } from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "./index.css";
import { useTranslation } from "react-i18next";

const DatePicker = ({ startDate, endDate, onChange, ...rest }) => {
    const { t } = useTranslation();
    const [focusedInput, setFocusedInput] = useState(null);

    const textSpecs = {
        startDatePlaceholderText: rest.startDatePlaceholderText
            ? rest.startDatePlaceholderText
            : t("web_components.forms.range_picker.start_date_placeholder"),
        endDatePlaceholderText: rest.endDatePlaceholderText
            ? rest.endDatePlaceholderText
            : t("web_components.forms.range_picker.end_date_placeholder")
    };

    function onFocusChange(focusedInput) {
        setFocusedInput(focusedInput);
    }

    return (
        <DateRangePicker
            {...rest}
            startDatePlaceholderText={textSpecs.startDatePlaceholderText}
            endDatePlaceholderText={textSpecs.endDatePlaceholderText}
            onDatesChange={onChange}
            onFocusChange={onFocusChange}
            startDate={startDate}
            endDate={endDate}
            focusedInput={focusedInput}
        />
    );
};

DatePicker.defaultProps = {
    // input related props
    startDateId: "startDate",
    endDateId: "endDate",
    disabled: false,
    required: false,
    screenReaderInputMessage: "",
    showClearDates: false,
    showDefaultInputIcon: true,
    customInputIcon: null,
    customArrowIcon: null,
    customCloseIcon: null,
    block: false,
    small: false,
    regular: false,

    // calendar presentation and interaction related props
    renderMonthText: null,
    orientation: "horizontal",
    anchorDirection: "left",
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,

    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {},
    onNextMonthClick() {},
    onClose() {},

    // day presentation and interaction related props
    renderCalendarDay: undefined,
    renderDayContents: null,
    minimumNights: 1,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isDayHighlighted: () => false,
    isOutsideRange: () => false,

    // internationalization
    monthFormat: "MMMM YYYY",
    displayFormat: "DD/MM/YYYY",

    daySize: 48
};

DatePicker.propTypes = {
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    onChange: PropTypes.func
};

export default DatePicker;
