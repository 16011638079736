import React, { useState } from "react";

import { Flex, Text } from "rebass";
import { TabNavigation } from "components/TabNavigation";
import { useTranslation } from "react-i18next";

const CompositionTabs = props => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState("MUSCLE");

    const NAVIGATION = {
        MUSCLE: "words.muscle",
        FAT: "words.fat",
        OTHER: "words.other"
    };

    return (
        <Flex
            minHeight="330px"
            flexDirection="column"
            bg="hpCardBackground"
            p={["16px", "32px"]}
            {...props}
            data-testid="overview-tabs-bc"
        >
            <TabNavigation currentKey={currentTab} setCurrentKey={setCurrentTab} navigationKeyValuePairs={NAVIGATION} />
            <Text color="hpGrayishBlueText" fontSize="13px" fontWeight="light" mt="16px">
                {currentTab === "MUSCLE" && (
                    <>
                        <Text fontWeight="500">
                            {t("members_portal_web.views.body_composition.unlocked.breakdown.tabs.muscle.title")}
                        </Text>
                        <Text py="1em">
                            {t("members_portal_web.views.body_composition.unlocked.breakdown.tabs.muscle.explanation")}
                        </Text>
                        <Text as="ul" px="20px">
                            <li>
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.muscle.benefits.0"
                                )}
                            </li>
                            <li>
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.muscle.benefits.1"
                                )}
                            </li>
                            <li>
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.muscle.benefits.2"
                                )}
                            </li>
                            <li>
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.muscle.benefits.3"
                                )}
                            </li>
                        </Text>
                    </>
                )}
                {currentTab === "FAT" && (
                    <>
                        <Text fontWeight="500">
                            {t("members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.title")}
                        </Text>
                        <Text py="1em">
                            {t("members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.explanation")}
                        </Text>
                        <Text as="ul" px="20px">
                            <li>
                                <Text as="span" fontWeight="normal">
                                    {t(
                                        "members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.types.0.title"
                                    )}
                                </Text>{" "}
                                –{" "}
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.types.0.information"
                                )}
                            </li>
                            <li>
                                <Text as="span" fontWeight="normal">
                                    {t(
                                        "members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.types.1.title"
                                    )}
                                </Text>{" "}
                                –{" "}
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.types.1.information"
                                )}
                            </li>
                            <li>
                                <Text as="span" fontWeight="normal">
                                    {t(
                                        "members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.types.2.title"
                                    )}
                                </Text>{" "}
                                –{" "}
                                {t(
                                    "members_portal_web.views.body_composition.unlocked.breakdown.tabs.fat.types.2.information"
                                )}
                            </li>
                        </Text>
                    </>
                )}
                {currentTab === "OTHER" && (
                    <>
                        <Text fontWeight="500">
                            {t("members_portal_web.views.body_composition.unlocked.breakdown.tabs.other.title")}
                        </Text>
                        <Text py="1em">
                            {t("members_portal_web.views.body_composition.unlocked.breakdown.tabs.other.explanation")}
                        </Text>
                    </>
                )}
            </Text>
        </Flex>
    );
};

CompositionTabs.propTypes = {};

export default CompositionTabs;
