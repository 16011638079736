/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import HealthMetrics from "utilities/metrics";
import { SquaredCard as Card, StatusCard } from "@sisuwellness/ui/src/components/Cards";
import { StatusLabel } from "@sisuwellness/ui/src/components/Labels";
import Pie from "@sisuwellness/ui/src/components/Pie";
import ProgressView from "components/ProgressView";
import { MediaQuery } from "@sisuwellness/web-components";
import { formatDate } from "utilities/commonUtils";
import { CustomBox } from "components/HealthCard/styled-components";
import { useTranslation } from "react-i18next";

const OutcomeOverview = ({ type, datum, lastMeasurementDate, theme, ...rest }) => {
    const { t } = useTranslation();

    const lastDate = formatDate(lastMeasurementDate, "DD MMMM YYYY");

    const OverviewMobile = ({
        unit = "",
        text = "",
        value = "",
        progress = 0,
        secUnit = "",
        secValue = "",
        showIcon = false,
        icon = undefined,
        indicator = { bg: "", label: "" }
    }) => (
        <Flex mb="16px" flexDirection="column">
            <Flex width="100%" pt="15px" justifyContent="space-between" alignItems="center" px="28px">
                {showIcon ? (
                    <Flex size="94px" minWidth="94px" alignItems="center" justifyContent="center">
                        <CustomBox
                            size="70px"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            backgroundSize="contain"
                            backgroundPosition="center"
                            backgroundRepeat="no-repeat"
                            backgroundImage={`url(${icon})`}
                        >
                            <Flex
                                flexWrap="nowrap"
                                justifyContent="center"
                                alignItems="baseline"
                                css={{ "& > * + *": { marginLeft: "4px !important" } }}
                                data-testid="overview-value"
                            >
                                {value && (
                                    <Text
                                        fontSize="20px"
                                        textAlign="center"
                                        lineHeight="1.25"
                                        fontWeight="500"
                                        color={theme.colours.blackTints700.hex}
                                    >
                                        {value}
                                    </Text>
                                )}
                                {unit && (
                                    <Text
                                        fontSize="15px"
                                        textAlign="center"
                                        lineHeight="1.33"
                                        color={theme.colours.blackTints700.hex}
                                    >
                                        {unit}
                                    </Text>
                                )}
                                {secValue && (
                                    <Text
                                        fontSize="20px"
                                        textAlign="center"
                                        lineHeight="1.25"
                                        fontWeight="500"
                                        color={theme.colours.blackTints700.hex}
                                    >
                                        {secValue}
                                    </Text>
                                )}
                                {secUnit && (
                                    <Text
                                        fontSize="15px"
                                        textAlign="center"
                                        lineHeight="1.33"
                                        color={theme.colours.blackTints700.hex}
                                    >
                                        {secUnit}
                                    </Text>
                                )}
                            </Flex>
                        </CustomBox>
                    </Flex>
                ) : (
                    <Pie progress={progress} progressFgColor={indicator.bg} size={94} strokeWidth={4}>
                        <Flex
                            flexWrap="nowrap"
                            justifyContent="center"
                            alignItems="baseline"
                            data-testid="overview-value"
                            css={{ "& > * + *": { marginLeft: "4px !important" } }}
                        >
                            {value && (
                                <Text
                                    fontSize="20px"
                                    textAlign="center"
                                    lineHeight="1.25"
                                    fontWeight="500"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {value}
                                </Text>
                            )}
                            {unit && (
                                <Text
                                    fontSize="15px"
                                    textAlign="center"
                                    lineHeight="1.33"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {unit}
                                </Text>
                            )}
                            {secValue && (
                                <Text
                                    fontSize="20px"
                                    textAlign="center"
                                    lineHeight="1.25"
                                    fontWeight="500"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {secValue}
                                </Text>
                            )}
                            {secUnit && (
                                <Text
                                    fontSize="15px"
                                    textAlign="center"
                                    lineHeight="1.33"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {secUnit}
                                </Text>
                            )}
                        </Flex>
                    </Pie>
                )}
                <StatusCard
                    bg={indicator.bg}
                    maxWidth="114px"
                    py="4px"
                    height="fit-content"
                    data-testid="overview-rating"
                >
                    <StatusLabel color="white" fs={15} fontWeight={500} textAlign="center" mt="3px">
                        {indicator.label}
                    </StatusLabel>
                </StatusCard>
            </Flex>
            <Text mt="24px" fontSize="11px" color={theme.colours.flat.lightBlack.hex} textAlign="center">
                {t("members_portal_web.components.outcome_overview.taken_on")}: {lastDate}
            </Text>
            <Text mt="24px" fontSize="13px" color={theme.colours.flat.lightBlack.hex} textAlign="center">
                {t(text)}
            </Text>
        </Flex>
    );

    const OverviewDesktop = ({
        unit = "",
        text = "",
        value = "",
        progress = 0,
        secUnit = "",
        secValue = "",
        showIcon = false,
        icon = undefined,
        indicator = { bg: "", label: "" }
    }) => (
        <Flex mb="64px">
            {showIcon ? (
                <Flex size="165px" minWidth="165px" alignItems="center" justifyContent="center">
                    <CustomBox
                        size="120px"
                        minWidth="120px"
                        alignItems="center"
                        flexDirection="column"
                        justifyContent="center"
                        backgroundSize="contain"
                        backgroundPosition="center"
                        backgroundRepeat="no-repeat"
                        backgroundImage={`url(${icon})`}
                    >
                        <Flex
                            flexWrap="nowrap"
                            justifyContent="center"
                            alignItems="baseline"
                            css={{ "& > * + *": { marginLeft: "4px !important" } }}
                            data-testid="overview-value"
                        >
                            {value && (
                                <Text
                                    fontSize="34px"
                                    textAlign="center"
                                    lineHeight="1.25"
                                    fontWeight="500"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {value}
                                </Text>
                            )}
                            {unit && (
                                <Text
                                    fontSize="20px"
                                    textAlign="center"
                                    lineHeight="1.33"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {unit}
                                </Text>
                            )}
                            {secValue && (
                                <Text
                                    fontSize="34px"
                                    textAlign="center"
                                    lineHeight="1.25"
                                    fontWeight="500"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {secValue}
                                </Text>
                            )}
                            {secUnit && (
                                <Text
                                    fontSize="20px"
                                    textAlign="center"
                                    lineHeight="1.33"
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {secUnit}
                                </Text>
                            )}
                        </Flex>
                    </CustomBox>
                </Flex>
            ) : (
                <Pie progress={progress} progressFgColor={indicator.bg} size={165} strokeWidth={8}>
                    <Flex
                        flexWrap="nowrap"
                        justifyContent="center"
                        alignItems="baseline"
                        css={{ "& > * + *": { marginLeft: "4px !important" } }}
                        data-testid="overview-value"
                    >
                        {value && (
                            <Text
                                fontSize="28px"
                                textAlign="center"
                                lineHeight="1.25"
                                fontWeight="500"
                                color={theme.colours.blackTints700.hex}
                            >
                                {value}
                            </Text>
                        )}
                        {unit && (
                            <Text
                                fontSize="20px"
                                textAlign="center"
                                lineHeight="1.33"
                                color={theme.colours.blackTints700.hex}
                            >
                                {unit}
                            </Text>
                        )}
                        {secValue && (
                            <Text
                                fontSize="28px"
                                textAlign="center"
                                lineHeight="1.25"
                                fontWeight="500"
                                color={theme.colours.blackTints700.hex}
                            >
                                {secValue}
                            </Text>
                        )}
                        {secUnit && (
                            <Text
                                fontSize="20px"
                                textAlign="center"
                                lineHeight="1.33"
                                color={theme.colours.blackTints700.hex}
                            >
                                {secUnit}
                            </Text>
                        )}
                    </Flex>
                </Pie>
            )}
            <Flex alignItems="space-between" flexDirection="column" ml="64px" height="100%">
                <StatusCard
                    bg={indicator.bg}
                    width="128px"
                    py="4px"
                    height="fit-content"
                    minHeight="48px"
                    data-testid="overview-rating"
                >
                    <StatusLabel color="white" fs={18} fontWeight={500} textAlign="center" mt="3px">
                        {indicator.label}
                    </StatusLabel>
                </StatusCard>
                <Text mt="24px" fontSize="15px" color={theme.colours.flat.lightBlack.hex}>
                    {t("members_portal_web.components.outcome_overview.taken_on")}: {lastDate}
                </Text>
                <Text mt="24px" fontSize="22px" color={theme.colours.flat.lightBlack.hex} fontWeight={300}>
                    {t(text)}
                </Text>
            </Flex>
        </Flex>
    );

    const hideProgressBarForTypes = ["SMOKING"];
    const hideProgressBar = hideProgressBarForTypes.includes(type);

    return (
        <Card width="100%" height="auto" py={["32px", "40px"]} px={["20px, 40px"]} {...rest}>
            <HealthMetrics type={type} healthCheck={datum}>
                {data => (
                    <>
                        <MediaQuery devices={["tablet", "desktop"]}>
                            <OverviewDesktop {...data} />
                        </MediaQuery>
                        <MediaQuery devices={["mobile"]}>
                            <OverviewMobile {...data} />
                        </MediaQuery>
                        {!hideProgressBar && <ProgressView type={type} datum={datum} />}
                    </>
                )}
            </HealthMetrics>
        </Card>
    );
};

OutcomeOverview.propTypes = {
    ...Card.propTypes,
    type: PropTypes.string,
    datum: PropTypes.object,
    lastMeasurementDate: PropTypes.string,
    meanData: PropTypes.any,
    unit: PropTypes.string,
    theme: PropTypes.object
};

export default OutcomeOverview;
