import React, { useContext } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// --------------------------------------------------------------------

import { NO_NETWORK_ERROR_MESSAGE, REQUEST_FAILED_ERROR_MESSAGE } from "@sisuwellness/utilities/constants/messages";
import { setCitizen } from "@sisuwellness/connected-components/actions/auth";
import routes from "constants/routes";
import { Margin } from "components/styled-components";
import APIClient from "utilities/APIClient";

import AddressCard, { EditField, OptionField } from "./addressCard";
import { MessageContext } from "components/Context";
import AddressForm from "./addressForm";
import { MESSAGE_ERROR, MESSAGE_SUCCESS } from "constants/messages";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------

const Address = ({ forms }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const history = useHistory();

    const { handleMessage } = useContext(MessageContext);

    const { citizen } = useSelector(state => state.auth);
    const { countryCode, postalCode, mobileNumber } = citizen.userProfile;

    const availableCountries = citizen.availableCountries || [];
    const availableCountriesOption = availableCountries.map(country => {
        return { value: country.alpha2, label: country.name };
    });

    const getCurrentCountry = Object.values(availableCountriesOption)
        .filter(country => country.value === countryCode)
        .map(country => country.label)[0];

    const optFields = [
        {
            main: t("words.country"),
            sub: getCurrentCountry
        },
        {
            main: t("words.post_code"),
            sub: postalCode || "NA"
        },
        {
            main: t("words.mobile"),
            sub: mobileNumber
        }
    ];

    const handleSubmit = async (values, { setSubmitting }) => {
        const updatedUserProfile = {
            ...citizen.userProfile,
            ...values
        };
        try {
            const response = await APIClient.auth.updateUserProfile(updatedUserProfile);
            if (response.succeeded()) {
                dispatch(setCitizen({ ...citizen, userProfile: { ...updatedUserProfile } }));
                history.replace(routes.myAccount.personalDetails);
                handleMessage(MESSAGE_SUCCESS, t("members.portal_web.components.address.message_success"));
            } else if (response.isNetworkFailure()) {
                handleMessage(MESSAGE_ERROR, t(NO_NETWORK_ERROR_MESSAGE));
            } else if (response.isBadRequest()) {
                const responseBody = await response.body();
                let errorMessage = t("members.portal_web.components.address.message_failure");
                if (responseBody.length && responseBody[0].value) {
                    errorMessage = responseBody[0].value;
                }
                handleMessage(MESSAGE_ERROR, errorMessage);
            } else {
                handleMessage(MESSAGE_ERROR, t(REQUEST_FAILED_ERROR_MESSAGE));
            }
        } catch (error) {
            handleMessage(MESSAGE_ERROR, error.message);
        } finally {
            setSubmitting(false);
            window.scrollTo(0, 0);
        }
    };

    return (
        <AddressCard heading={t("members_portal_web.components.address.heading")}>
            {!forms && <EditField to={routes.myAccount.address}>{t("words.edit")}</EditField>}
            {!forms ? (
                optFields.map(option => (
                    <OptionField key={option.main}>
                        <div className="field-main">{option.main}</div>
                        <div className="field-sub">{option.sub}</div>
                    </OptionField>
                ))
            ) : (
                <Margin mTop={["-8px"]} style={{ gridColumn: "span 2" }}>
                    <Margin mTop={["20px"]}>
                        <AddressForm onSubmit={handleSubmit} />
                    </Margin>
                </Margin>
            )}
        </AddressCard>
    );
};

Address.propTypes = {
    forms: PropTypes.bool.isRequired
};

Address.defaultProps = {
    forms: false
};

export default Address;
