import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";

// ------------------------------------------------------------------------

import Flex from "components/Flex";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { theme } from "@sisuwellness/web-components";
import { pathOr } from "ramda";
import { hexToRgba } from "utilities/commonUtils";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------

export const DifferenceLabel = ({ heartAge, actualAge, rating, ...rest }) => {
    const { t } = useTranslation();

    let difference = heartAge - actualAge;
    let signValue = difference < 0 ? "-" : difference === 0 ? "" : "+";

    difference = Math.abs(difference);

    const unit = difference === 1 ? "words.year" : "words.years";

    const color = theme.guidelines.HeartAge[rating] || {};

    return (
        <Flex fd="column" gap="4px" jc="space-between" {...rest}>
            <HeadingLabel fontSize={["11px", "", "15px"]}>{t("words.difference")}</HeadingLabel>
            <Flex ai="center" jc="center" width="80px" height="30px" borderRadius="2px" bg={hexToRgba("white", 0.4)}>
                <Text color={pathOr("hpPrimaryPurple", ["sisuPortal", "hex"], color)} fontSize="13px" fontWeight="bold">
                    {`${signValue}${difference}`} {t(unit)}
                </Text>
            </Flex>
        </Flex>
    );
};

DifferenceLabel.propTypes = {
    ...Flex.propTypes,
    heartAge: PropTypes.number,
    actualAge: PropTypes.number,
    rating: PropTypes.string
};
