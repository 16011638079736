import styled from "styled-components";
import { Link } from "react-router-dom";
import { mediaQueryForCss } from "utilities/mediaQuery";
import { Box } from "rebass";
import { grid } from "styled-system";

export const Card = styled.div`
    width: 100%;
    height: auto;
    padding: 20px;
    min-height: 90px;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: ${({ theme }) => theme.portalShadows.card1};
`;

export const Container = styled(Box)`
    width: 100%;
    display: grid;
    grid-gap: 20px 0px;
    grid-template-columns: auto auto;

    ${grid}
`;

export const HeaderField = styled.div`
    line-height: 1.14;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize[28].px};
    color: ${({ theme }) => theme.colours.hpPrimaryPurple.hex};

    ${props =>
        mediaQueryForCss({
            "font-size": ["", props.theme.fontSize[20].px]
        })}
`;

export const EditField = styled(Link)`
    cursor: pointer;
    line-height: 1.33;
    margin-left: auto;
    text-decoration: none;
    font-size: ${({ theme }) => theme.fontSize[15].px};
    font-weight: ${({ theme }) => theme.fontWeight.light};
    color: ${({ theme }) => theme.colours.hpPrimaryPurple.hex};

    ${props =>
        mediaQueryForCss({
            "font-size": ["", props.theme.fontSize[13].px]
        })}
`;

export const OptionField = styled.div`
    gap: 8px;
    display: flex;
    text-align: left;
    grid-column: span 2;
    flex-direction: column;
    color: ${({ theme }) => theme.colours.blackTints700.hex};

    .field-main {
        line-height: 1.29;
        font-size: ${({ theme }) => theme.fontSize[17].px};
        font-weight: ${({ theme }) => theme.fontWeight.semiBold}; /* TODO: change to normal when adding font family */

        ${props =>
            mediaQueryForCss({
                "font-size": ["", props.theme.fontSize[15].px]
            })}
    }

    .field-sub {
        font-size: ${({ theme }) => theme.fontSize[16].px};
        font-weight: ${({ theme }) => theme.fontWeight.light};

        ${props =>
            mediaQueryForCss({
                "font-size": ["", props.theme.fontSize[13].px]
            })}
    }

    .heading-checkbox {
        line-height: 1.33;
        font-size: ${({ theme }) => theme.fontSize[15].px};
        font-weight: ${({ theme }) => theme.fontWeight.semiBold}; /* TODO: change to normal when adding font family */
    }
`;

export const OptionHeadingField = styled(Box)`
    display: flex;
    text-align: left;
    grid-column: span 2;
    flex-direction: column;
    color: ${({ theme }) => theme.colours.blackTints700.hex};

    & > * + * {
        margin-top: 8px;
    }

    .heading-main {
        line-height: 1.29;
        font-size: ${({ theme }) => theme.fontSize[22].px};
        font-weight: ${({ theme }) => theme.fontWeight.regular};

        ${props =>
            mediaQueryForCss({
                "font-size": ["", props.theme.fontSize[17].px]
            })}
    }

    .heading-sub {
        font-size: ${({ theme }) => theme.fontSize[15].px};
        font-weight: ${({ theme }) => theme.fontWeight.light};

        ${props =>
            mediaQueryForCss({
                "font-size": ["", props.theme.fontSize[13].px]
            })}
    }
`;
