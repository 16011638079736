import React from "react";
import PropTypes from "prop-types";

// -----------------------------------------------------------------------------------

import InputField from "components/InputField";
import { useTranslation } from "react-i18next";

// -----------------------------------------------------------------------------------

export const NameField = ({ values, touched, errors, handleChange, handleBlur }) => {
    const { t } = useTranslation();

    return (
        <>
            <InputField
                label={t("words.first_name")}
                fontFamily="GT Walsheim Pro"
                inputProps={{
                    name: "firstName",
                    placeholder: t("words.first_name"),
                    "data-testid": "firstName",
                    onChange: handleChange,
                    value: values.firstName,
                    onBlur: handleBlur
                }}
                error={touched.firstName ? t(errors.firstName, { fieldName: t("words.first_name").toLowerCase() }) : ""}
            />
            <InputField
                label={t("words.surname")}
                fontFamily="GT Walsheim Pro"
                inputProps={{
                    name: "lastName",
                    placeholder: t("words.last_name"),
                    "data-testid": "lastName",
                    onChange: handleChange,
                    value: values.lastName,
                    onBlur: handleBlur
                }}
                error={touched.lastName ? t(errors.lastName, { fieldName: t("words.last_name").toLowerCase() }) : ""}
            />
        </>
    );
};

NameField.propTypes = {
    values: PropTypes.object,
    errors: PropTypes.object,
    touched: PropTypes.object,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func
};
