import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";
import { isSignUpFlow } from "./utils";
import { useTranslation } from "react-i18next";

export const Title = ({ state = {} }) => {
    const { t } = useTranslation();

    let title = "",
        subTitle = "",
        subText = "";

    switch (true) {
        case isSignUpFlow(state).setup:
            title = "members_portal_web.views.sign_up.flows.setup.title";
            subTitle = null;
            subText = "members_portal_web.views.sign_up.flows.setup.subtext";
            break;

        case isSignUpFlow(state).basic:
        case isSignUpFlow(state).registration:
            title = "members_portal_web.views.sign_up.flows.basic_reg.title";
            subTitle = "members_portal_web.views.sign_up.flows.basic_reg.subtitle";
            subText = "members_portal_web.views.sign_up.flows.basic_reg.subtext";
            break;
    }

    return (
        <>
            <Text fontSize={["22px", "34px"]} fontWeight={["500", "normal"]} color="hpPrimaryPurple">
                {t(title)}
            </Text>
            {subTitle && (
                <Text fontSize={["13px", "17px"]} color="blackTints700" mb={["10px", "0"]}>
                    {t(subTitle)}
                </Text>
            )}
            <Text fontSize={["13px", "17px"]} color="blackTints700">
                {t(subText)}
            </Text>
        </>
    );
};

Title.propTypes = {
    state: PropTypes.object
};
