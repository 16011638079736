import BaseEndPoint from "../BaseEndPoint";

export default class HealthCheck extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/health-checks/" + path);
    }

    /**
     * Get stats endpoint
     *
     * @param {object} params
     * @return {Promise<Response>}
     */
    getStats(params) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString(params);
        return this._send(this.getUrlPath("stats") + queryString, settings);
    }
}
