import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Errors, Info } from "../../Forms";
import { Flex } from "rebass";
import CardBase from "../../Cards";
import { media } from "../../../themes";
import { LoginHeader, AuthClientFooter } from "../index";

const StyledContainer = styled(Flex)`
    min-height: 100vh;
`;

const StyledCardBase = styled(CardBase)`
    width: 23em;
    margin: 0px;

    .content {
        display: block;
    }

    ${media.mobile`
        width: 100%;
    `}
`;

const AuthContainer = ({ errorMessage, infoMessage, clientCode, children }) => {
    return (
        <Flex flexDirection="column">
            <StyledContainer width={"100%"} flexDirection={"column"} justifyContent="center" alignItems="center">
                <StyledCardBase noPadding>
                    <LoginHeader clientCode={clientCode} />

                    {errorMessage && <Errors data-testid="error-message">{errorMessage}</Errors>}
                    {infoMessage && <Info data-testid="info-container">{infoMessage}</Info>}
                    {children}
                </StyledCardBase>

                <AuthClientFooter clientCode={clientCode} />
            </StyledContainer>
        </Flex>
    );
};

AuthContainer.propTypes = {
    children: PropTypes.node,
    errorMessage: PropTypes.string,
    infoMessage: PropTypes.string,
    clientCode: PropTypes.string
};

export default AuthContainer;
