/* eslint-disable camelcase */
export default class SleepReport {
    /**
     * @param {object} sleepReportResults
     * @param {number} sleepReportResults.ahiScore
     * @param {string} sleepReportResults.type
     * @param {number} sleepReportResults.number_of_recordings
     * @param {string} sleepReportResults.summary_report_file
     * @param {string} sleepReportResults.report_type
     * @param {{ ahi: number, report_type: string, start_time: Date, end_time: Date }[]} sleepReportResults.nightly_reports
     */
    constructor(sleepReportResults = {}) {
        this._report = sleepReportResults;
    }

    /**
     * Get latest Nightly Report
     *  @return {{ ahi: number, report_type: string, start_time: Date, end_time: Date }}
     */
    get latestNightlyReport() {
        return this._report.nightly_reports?.[0];
    }

    /**
     * Get latest nightly report start time
     * @return {Date}
     */
    get latestNightlyReportStartTime() {
        return this.latestNightlyReport?.start_time;
    }

    /**
     * @return {boolean}
     */
    get isProcessed() {
        return !!this._report.processed;
    }

    /**
     * @return {number}
     */
    get ahiScore() {
        return this._report.ahiScore;
    }

    /**
     * @return {string}
     */
    get summary() {
        return this._report.summary_report_file;
    }

    /**
     * @param {string} data.file_url
     */
    static downloadReport = ({ file_url: fileUrl }) => fileUrl && window.location.assign(fileUrl);
}
