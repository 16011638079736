import React, { useContext } from "react";
import PropTypes from "prop-types";

import { ThemeContext } from "styled-components";
import { useLocation } from "react-router";

// --------------------------------------------------------------------

import { Link } from "@sisuwellness/web-components";

// --------------------------------------------------------------------

import routes from "constants/routes";
import { BreadCrumb, BreadCrumbItem, BreadCrumbNav } from "./styled";
import Icons from "components/Icons";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------

const BreadCrumbMap = {
    "my-account": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.my_account_details",
        linkTo: routes.myAccount.home
    },
    preferences: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.preferences",
        linkTo: routes.myAccount.preferences
    },
    security: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.security",
        linkTo: routes.myAccount.security
    },
    payments: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.payments",
        linkTo: routes.myAccount.payments
    },
    general: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.general",
        linkTo: routes.myAccount.preferencesGeneral
    },
    "personal-details": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.personal_details",
        linkTo: routes.myAccount.personalDetails
    },
    profile: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.profile",
        linkTo: routes.myAccount.profile
    },
    address: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.address",
        linkTo: routes.myAccount.address
    },
    "account-settings": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.account_settings",
        linkTo: routes.myAccount.accountSettings
    },
    "health-checks": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.past_health_checks",
        linkTo: routes.healthChecks
    },
    trends: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.dashboard",
        linkTo: routes.healthHub
    },
    bmi: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.body_mass_index",
        linkTo: "#"
    },
    "blood-pressure": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.heart_rate",
        linkTo: "#"
    },
    "heart-rate": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.blood_pressure",
        linkTo: "#"
    },
    "body-fat": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.body_fat",
        linkTo: "#"
    },
    diabetes: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.diabetes",
        linkTo: "#"
    },
    weight: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.weight",
        linkTo: "#"
    },
    qrisk: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.qrisk",
        linkTo: "#"
    },
    height: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.height",
        linkTo: "#"
    },
    alcohol: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.alcohol",
        linkTo: "#"
    },
    smoking: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.smoking",
        linkTo: "#"
    },
    activity: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.activity",
        linkTo: "#"
    },
    "perceived-stress": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.stress",
        linkTo: "#"
    },
    improve: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.online_health",
        linkTo: "#"
    },
    "health-hub": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.health_hub",
        linkTo: routes.healthHub
    },
    "hra-result": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.hra_result",
        linkTo: "#"
    },
    "billing-and-subscription": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.billing",
        linkTo: "#"
    },
    "heart-age": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.heart_age",
        linkTo: "#"
    },
    "daily-energy": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.daily_energy",
        linkTo: "#"
    },
    rewards: {
        displayName: "members_portal_web.views.breadcrumbs.display_names.rewards",
        linkTo: "#"
    },
    "body-composition": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.body_composition",
        linkTo: "#"
    },
    "muscle-mass": {
        displayName: "members_portal_web.views.breadcrumbs.display_names.muscle_mass",
        linkTo: "#"
    }
};

const BreadCrumbs = ({ extra, ...rest }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    let paths = pathname.split("/");
    paths.shift(); // To skip the first empty item.
    const theme = useContext(ThemeContext);

    return (
        <BreadCrumb mb="16px" {...rest}>
            {paths.map((path, index) => {
                return (
                    <React.Fragment key={index}>
                        <Link to={BreadCrumbMap[path] ? BreadCrumbMap[path].linkTo : extra ? "#" : routes.root}>
                            <BreadCrumbItem key={index}>
                                {BreadCrumbMap[path]
                                    ? t(BreadCrumbMap[path].displayName)
                                    : extra
                                    ? extra
                                    : t("members_portal_web.views.breadcrumbs.display_names.unknown")}
                            </BreadCrumbItem>
                        </Link>
                        {index != paths.length - 1 && (
                            <BreadCrumbNav>
                                <Icons
                                    chevron
                                    width="14px"
                                    height="14px"
                                    strokeWidth="3px"
                                    transform="rotate(-90deg)"
                                    stroke={theme.colours.hpPrimaryPurple.hex}
                                />
                            </BreadCrumbNav>
                        )}
                    </React.Fragment>
                );
            })}
        </BreadCrumb>
    );
};

BreadCrumbs.propTypes = {
    extra: PropTypes.string
};

export default BreadCrumbs;
