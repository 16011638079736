import React, { useRef } from "react";
import PropTypes from "prop-types";

//------------------------------------------------------------------------------

import { HRAStateProvider as Provider } from "components/Context";
import { getTotalQuestions } from "utilities/hraUtils";

//------------------------------------------------------------------------------

const HRAStateProvider = ({ children }) => {
    const list = useRef(null);
    const visitedQuestions = useRef({});

    /**
     * Calculates required states for HRA
     * @param {object} userJourneyModel
     * @param {Number} currentQuestionId
     * @returns {{ currentIndex: Number, now: Number }}
     *
     */
    const getHraState = (userJourneyModel, currentQuestionId) => {
        if (!list.current) list.current = getTotalQuestions(userJourneyModel);
        const currentIndex = list.current.indexOf(currentQuestionId);
        const remainingQuestions = list.current.slice(currentIndex, Number.MAX_SAFE_INTEGER);
        if (!visitedQuestions.current[currentQuestionId]) visitedQuestions.current[currentQuestionId] = true;
        return {
            currentIndex: Object.values(visitedQuestions.current).filter(entry => entry).length,
            now: 100 * ((list.current.length - remainingQuestions.length) / list.current.length)
        };
    };

    /**
     * Reset hra states
     */
    const handleResetState = () => {
        list.current = null;
        visitedQuestions.current = {};
    };

    /**
     * Callback to calculate states if pervious question is selected
     * @param {Number} currentQuestionId
     */
    const setPreviousQuestion = currentQuestionId => (visitedQuestions.current[currentQuestionId] = false);

    return (
        <Provider
            value={{
                setPreviousQuestion,
                getHraState: getHraState,
                resetState: handleResetState
            }}
        >
            {children}
        </Provider>
    );
};

HRAStateProvider.propTypes = {
    children: PropTypes.any.isRequired
};

export default HRAStateProvider;
