import React from "react";
import PropTypes from "prop-types";
import { Text, Box, Flex } from "rebass";
import { FormForNumber } from "./form";
import { useTranslation } from "react-i18next";

const HRANumber = ({ model, setErrors, onSubmit, value }) => {
    const { t } = useTranslation();

    const handleSubmit = values => {
        let value = values.number;
        onSubmit(`${value}`);
    };

    return (
        <Flex justifyContent="center" flexDirection="column" alignItems="flex-start">
            <Text fontSize={["22px", "34px"]} textAlign="left" fontWeight="500">
                {t("web_components.survey.hra_restyle.number.what_is_your")} {t(model.getDisplayText()).toLowerCase()}
            </Text>
            <Box mt="32px" />
            <FormForNumber
                onSubmit={handleSubmit}
                {...{ setErrors, value }}
                placeholder={model.getHelpText()}
                isRequired={model.isRequired()}
            />
        </Flex>
    );
};

HRANumber.propTypes = {
    model: PropTypes.shape({
        getHelpText: PropTypes.func,
        getDisplayText: PropTypes.func,
        isRequired: PropTypes.func
    }),
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any
};

export default HRANumber;
