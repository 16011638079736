import {
    BLOOD_PRESSURE,
    BODY_MASS_INDEX,
    HEART_RATE,
    DIABETES,
    BODY_FAT,
    WEIGHT,
    QRISK3,
    HEIGHT,
    ALCOHOL_RISK,
    ACTIVITY,
    HEART_AGE,
    TEN_YEAR_CVD_RISK
} from "constants/trend-charts";
import { roundToDec } from "@sisuwellness/utilities/math";

export const TYPE_PROPS_MAP = {
    [BLOOD_PRESSURE]: {
        keys: ["sys", "dia"],
        meanKeys: ["sys", "dia"],
        meanDecimal: 0,
        metricUnit: "mmHg",
        healthyRanges: [
            {
                label: "Optimal systolic range (90-119)",
                ranges: {
                    upperBound: 119,
                    lowerBound: 90
                }
            },
            {
                label: "Optimal diastolic range (60-79)",
                ranges: {
                    upperBound: 79,
                    lowerBound: 60
                }
            }
        ],
        yAxis: {
            maxDefaultValue: 119,
            minDefaultValue: 50,
            format: roundToDec(0)
        },
        usePointColor: true
    },
    [BODY_MASS_INDEX]: {
        keys: ["bmi"],
        meanKeys: ["bmi"],
        meanDecimal: 1,
        metricUnit: "bmi",
        healthyRanges: [],
        yAxis: {
            roundValue: 1,
            format: roundToDec(1)
        },
        usePointColor: true
    },
    [HEART_RATE]: {
        keys: ["hr"],
        meanKeys: ["hr"],
        meanDecimal: 0,
        metricUnit: "bpm",
        healthyRanges: [
            {
                label: "Optimal Heart Rate",
                ranges: {
                    upperBound: 80,
                    lowerBound: 40
                }
            }
        ],
        yAxis: {
            minDefaultValue: 30,
            maxDefaultValue: 80,
            roundValue: 5,
            format: roundToDec(0)
        },
        usePointColor: true
    },
    [DIABETES]: {
        keys: ["ausdriskScoreValue"],
        meanKeys: ["ausdriskScoreValue"],
        meanDecimal: 0,
        metricUnit: "pts",
        healthyRanges: [],
        yAxis: {
            roundValue: 2
        },
        usePointColor: true
    },
    [BODY_FAT]: {
        keys: ["bodyFatPercent"],
        meanKeys: ["bodyFatPercent"],
        meanDecimal: 1,
        metricUnit: "%",
        healthyRanges: [],
        yAxis: {
            roundValue: 1,
            format: roundToDec(1)
        },
        usePointColor: true
    },
    [WEIGHT]: {
        keys: ["weight"],
        meanKeys: ["weight"],
        meanDecimal: 1,
        imperialUnit: "st",
        metricUnit: "kgs",
        healthyRanges: [],
        yAxis: {
            roundValue: 1,
            format: roundToDec(1)
        },
        usePointColor: true
    },
    [QRISK3]: {
        keys: ["qriskScore"],
        meanKeys: ["qriskScore"],
        meanDecimal: 1,
        metricUnit: "%",
        healthyRanges: [],
        yAxis: {
            roundValue: 1,
            format: roundToDec(1)
        },
        usePointColor: true
    },
    [HEIGHT]: {
        keys: ["height"],
        meanKeys: ["height"],
        meanDecimal: 1,
        metricUnit: "cm",
        imperialUnit: "cm",
        healthyRanges: [],
        yAxis: {
            roundValue: 1,
            format: roundToDec(1)
        },
        usePointColor: false
    },
    [ALCOHOL_RISK]: {
        keys: ["alcoholScore"],
        meanKeys: ["alcoholScore"],
        meanDecimal: 1,
        imperialUnit: "",
        metricUnit: "",
        healthyRanges: [],
        yAxis: {
            roundValue: 1,
            format: roundToDec(1)
        },
        usePointColor: true
    },
    [ACTIVITY]: {
        keys: ["activityRating"],
        meanKeys: ["activityRating"],
        healthyRanges: [],
        yAxis: {},
        usePointColor: true
    },
    [HEART_AGE]: {
        keys: ["heartAge"],
        meanKeys: ["heartAge"],
        healthyRanges: [],
        metricUnit: "Heart Age",
        imperialUnit: "Heart Age",
        usePointColor: true,
        yAxis: {
            roundValue: 5,
            minDefaultValue: 0
        }
    },
    [TEN_YEAR_CVD_RISK]: {
        metricUnit: "%",
        meanDecimal: 1,
        imperialUnit: "%",
        healthyRanges: [],
        keys: ["tenYearCvdRisk"],
        meanKeys: ["tenYearCvdRisk"],
        usePointColor: true,
        yAxis: { minDefaultValue: 0 }
    }
};
