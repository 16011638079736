/**
 * Sanitises an email address
 * - trims whitespace
 * - lowercase
 * - remove trailing period
 *
 * @param {string} emailAddress
 * @return {string}
 */
const sanitiseEmailAddress = function(emailAddress) {
    return emailAddress
        .trim()
        .toLowerCase()
        .replace(/\.+$/, "");
};

export { sanitiseEmailAddress };
