import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(isSmoker) {
        this._validateSmokingValue(isSmoker);
        this._isSmoker = isSmoker;
    }

    /**
     * Returns the given Smoking value
     * @returns {Boolean}
     */
    get() {
        return this._isSmoker;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "SMOKING";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_SMOKING";
    }

    /**
     * Validates the given Smoking value. It is intended to be a boolean value
     *
     * @returns {boolean}
     * @private
     */
    _validateSmokingValue(isSmoker) {
        if (!(typeof isSmoker === "boolean")) {
            throw new InvalidArgumentError("Smoking must be a valid boolean value");
        }

        return true;
    }

    /**
     * Returns an object representing various data for each Smoking range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            normal: {
                key: "normal",
                label: "ranges.normal"
            },
            high: {
                key: "high",
                label: "ranges.high"
            }
        };
    }

    /**
     * Returns if the given Smoking is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._isSmoker === false;
    }

    /**
     * Returns if the given Smoking is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._isSmoker === true;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isNormal():
                info = SiSU.ranges.normal.key;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.key;
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let info = "";
        switch (true) {
            case this.isNormal():
                info = SiSU.ranges.normal.label;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.label;
                break;
        }

        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 2,
            riskLevel = 0;
        switch (true) {
            case this.isNormal():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isHigh():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }

    get recommendation() {
        if (this.isNormal()) return "recommendations.smoking.normal";

        return "recommendations.smoking.high";
    }
}
