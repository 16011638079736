import React from "react";
import PropTypes from "prop-types";
import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";

// -----------------------------------------------------------------------------------

import InputField from "components/InputField";
import { checkIfEventValueIsNumber } from "../../utils";
import { useTranslation } from "react-i18next";

// -----------------------------------------------------------------------------------

export const NumericPostalCodesCountries = [COUNTRIES.AUSTRALIA, COUNTRIES.SOUTH_AFRICA];
export const PostalCodeField = ({ values, touched, errors, handleBlur, handleChange, ...rest }) => {
    const { t } = useTranslation();

    const handleOnChange = event => {
        if (NumericPostalCodesCountries.includes(values.countryCode) && !checkIfEventValueIsNumber(event)) return;
        handleChange(event);
    };

    const inputProps = {
        name: "postalCode",
        onBlur: handleBlur,
        placeholder: t("words.postcode"),
        onChange: handleOnChange,
        "data-testid": "postalCode",
        value: String(values["postalCode"])
    };

    const isError = touched["postalCode"] ? errors["postalCode"] : "";

    return (
        <InputField
            fontFamily="GT Walsheim Pro"
            label={t("words.postcode")}
            inputProps={inputProps}
            error={isError}
            {...rest}
        />
    );
};

PostalCodeField.propTypes = {
    touched: PropTypes.object,
    errors: PropTypes.object,
    values: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func,
    isPostalAddress: PropTypes.bool
};
