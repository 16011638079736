import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter, Redirect } from "react-router-dom";
import Auth from "utilities/auth";
import { FlowState } from "@sisuwellness/utilities/User";
import Password from "@sisuwellness/utilities/Auth/Password";
import routes from "constants/routes";

class RegisterView extends Component {
    static displayName = "registerView";

    static propTypes = {
        location: PropTypes.object,
        match: PropTypes.object
    };

    constructor(props) {
        super(props);

        this.state = {
            username: props.location.state.username,
            consents: props.location.state.consents,
            tokenLocale: props.location.state.tokenLocale,
            campaign: props.location.state.campaign,
            token: props.match.params.token,
            errorMessage: null,
            submitting: false
        };

        this.passwordUtility = new Password();
    }

    componentDidMount() {
        Auth.isAuthenticated().then(authenticated => {
            if (authenticated) {
                this.setState({
                    flowState: FlowState.FLOW_STATE_USER_AUTHENTICATED
                });
            }
        });
    }

    render() {
        if (FlowState.userIsAuthenticated(this.state.flowState)) {
            let from = { pathname: "/health-hub" };
            return <Redirect to={from} />;
        }

        const consents = this.state.consents;

        const countryCode = this.state.tokenLocale.languageTag.split("_")[1] || null;
        return (
            <Redirect
                to={{
                    pathname: routes.signup,
                    state: {
                        type: "register",
                        consents: consents,
                        token: this.state.token,
                        email: this.state.username,
                        locale: this.state.tokenLocale.languageTag,
                        countryCode
                    }
                }}
            />
        );
    }
}

export default withRouter(RegisterView);
