import React from "react";
import { Route, Switch } from "react-router";

// ----------------------------------------------------------------------------------------------

import { Margin } from "components/styled-components";
import routes from "constants/routes";

import SecurityDetail from "./securityDetail";

// ----------------------------------------------------------------------------------------------

const Security = () => {
    return (
        <Margin mTop={["32px"]} mBottom={["32px"]}>
            <Switch>
                <Route exact path={routes.myAccount.accountSettings} render={() => <SecurityDetail forms={true} />} />
                <Route
                    exact
                    path={routes.myAccount.security}
                    render={() => (
                        <div style={{ display: "grid", rowGap: "32px" }}>
                            <SecurityDetail />
                        </div>
                    )}
                />
            </Switch>
        </Margin>
    );
};

export default Security;
