import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, Flex } from "rebass";
import { debounce } from "lodash";

// ----------------------------------------------------------------------------

import { Line } from "@sisuwellness/web-components/components/Chart";
import { withDimensions } from "components/HOC";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import TrendFilter from "components/TrendFilter";
import { CHART_FILTERS, FILTER_ONE_MONTH } from "constants/trend-charts";

import { TrendLegend } from "./TrendLegend";
import TrendNavigation from "./TrendNavigation";
import CustomPointSymbol from "./TrendPointSymbol";
import TrendTooltip from "./TrendTooltip";
import { createFilteredDatapoints, mapFilterTypeToTimeScale } from "./utils";
import LineComponent from "./LineComponent";

// ----------------------------------------------------------------------------

const CHART_INTERACTION_DEBOUNCE_TIMEOUT = 20;

const LineChart = ({
    children,
    AreaLayer,
    settings = {},
    healthChecks = [],
    dimension,
    CustomLegendComponent,
    CustomLineComponent = LineComponent,
    plottingUtility = {},
    latestHealthCheckData,
    ...rest
}) => {
    const [width] = dimension;

    // States
    const [activeDate, setActiveDate] = useState();
    const [marginFromLeft, setMarginFromLeft] = useState("0px");
    const [currentFilter, setCurrentFilter] = useState(FILTER_ONE_MONTH);
    const [currentDataPoint, setCurrentDataPoint] = useState({});

    // Event handlers
    const onMouseMove = ({ data } = {}) =>
        data.analyticsHealthCheckId !== currentDataPoint.analyticsHealthCheckId && debouncedSetDataPoint(data);
    const debouncedSetDataPoint = debounce(setCurrentDataPoint, CHART_INTERACTION_DEBOUNCE_TIMEOUT);

    // Plotting Data
    const {
        unit,
        color,
        yRange,
        legends,
        markers,
        margins,
        dataPoints,
        historyProps,
        axisLeftProps,
        keyForValueString
    } = plottingUtility;
    const points = dataPoints(healthChecks, settings); // Create Data Points

    const filteredPoints = createFilteredDatapoints(points, currentFilter, activeDate); // Filter data points wrt to applied filter
    const { min, max, yScaleRanges } = yRange(filteredPoints, width); // Calculate Min Max ranges from the data points
    const { axisBottomProps, xScaleProps } = mapFilterTypeToTimeScale(currentFilter, width, filteredPoints); // axis props

    useEffect(() => {
        const gElement = document.querySelectorAll("g")[0];
        const trendContainer = document.getElementById("trend-container");
        setMarginFromLeft(`${gElement?.getBoundingClientRect()?.x - trendContainer?.getBoundingClientRect().x ?? 0}px`);
    }, [width]);

    const commonStyles = { px: ["16px", marginFromLeft], style: { transition: "margin .2s linear" } };

    // pass dimensions prop to Area layers
    const CustomAreaLayer = AreaLayer && (props => <AreaLayer dimension={dimension} {...props} />);

    return (
        <Box width="100%" {...rest}>
            {children &&
                children({
                    filter: currentFilter,
                    historyProps: historyProps(filteredPoints, latestHealthCheckData, settings)
                })}
            <TrendFilter mb="14px" filters={CHART_FILTERS} onChange={setCurrentFilter} activeFilter={currentFilter} />
            <Flex flexDirection="column" alignItems="center" bg="hpCardBackground" id="trend-container">
                <TrendNavigation
                    data={points}
                    currentFilter={currentFilter}
                    setActiveDate={setActiveDate}
                    {...commonStyles}
                />
                <HeadingLabel
                    mt="24px"
                    fontWeight="500"
                    alignSelf="flex-start"
                    fontSize={["12px", "16px"]}
                    {...commonStyles}
                >
                    {unit(settings)}
                </HeadingLabel>
                <Box width="100%" height={["50vh"]}>
                    <Line
                        curve="linear"
                        id="line-chart"
                        pointSize={10}
                        useMesh={true}
                        yFormat=" >-.2f"
                        axisRight={null}
                        pointBorderWidth={2}
                        data={filteredPoints}
                        xScale={{ type: "time", useUTC: false, ...xScaleProps }}
                        margin={{ top: 20, bottom: 72, ...margins(width) }}
                        axisLeft={{
                            tickSize: 0,
                            tickPadding: 5,
                            tickValues: yScaleRanges,
                            format: tick => tick?.toLocaleString?.() ?? tick,
                            ...axisLeftProps
                        }}
                        gridYValues={yScaleRanges}
                        axisBottom={{
                            tickSize: 0,
                            orient: "bottom",
                            tickPadding: 12,
                            ...axisBottomProps
                        }}
                        yScale={{ type: "linear", min, max }}
                        onMouseMove={onMouseMove}
                        onMouseLeave={() => debouncedSetDataPoint.cancel()}
                        tooltip={props => (
                            <TrendTooltip
                                {...props}
                                // eslint-disable-next-line
                                color={color(props.point?.data)}
                                tooltipKey={keyForValueString?.(settings)}
                            />
                        )}
                        pointSymbol={props => (
                            <CustomPointSymbol currentPoint={currentDataPoint} {...props} color={color(props?.datum)} />
                        )}
                        layers={[
                            "grid",
                            "markers",
                            "axes",
                            CustomAreaLayer, // add custom area layer
                            "crosshair",
                            CustomLineComponent, // add the custom Line layer
                            "slices",
                            "points",
                            "mesh",
                            "legends"
                        ]}
                        markers={markers?.(latestHealthCheckData, width)}
                    />
                </Box>
                {CustomLegendComponent ? (
                    <CustomLegendComponent legends={legends(points)} {...commonStyles} />
                ) : (
                    <TrendLegend mt="-16px" mb="24px" legends={legends(points)} {...commonStyles} />
                )}
            </Flex>
        </Box>
    );
};

LineChart.propTypes = {
    healthChecks: PropTypes.array,
    settings: PropTypes.object,
    dimension: PropTypes.array,
    children: PropTypes.func,
    AreaLayer: PropTypes.any,
    CustomLineComponent: PropTypes.any,
    CustomLegendComponent: PropTypes.any,
    plottingUtility: PropTypes.object,
    latestHealthCheckData: PropTypes.object
};

export default withDimensions(LineChart);
