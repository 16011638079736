import { Answers, QuestionCodes } from "../../UserJourney/UserJourneyMap";
import CoreUtils from "../../core/coreUtils";

// Reference for score calculations:
// http://ohnurses.org/wp-content/uploads/2015/05/Perceived-Stress-Scale-4.pdf
// The guideline is just a generic interpretation of the score that has been created by SiSU Health Group

const AnswerScoreMapRising = Object.values(Answers.StressTestPSS4).map((answer, index) => {
    return {
        answer: answer,
        score: index
    };
});

const AnswerScoreMapFalling = Object.values(Answers.StressTestPSS4).map((answer, index) => {
    return {
        answer: answer,
        score: 4 - index
    };
});

const ScoreMap = {
    [QuestionCodes.pss4UnableToControl]: AnswerScoreMapRising,
    [QuestionCodes.pss4CouldNotOverComeDifficulties]: AnswerScoreMapRising,
    [QuestionCodes.pss4ThingsGoingYourWay]: AnswerScoreMapFalling,
    [QuestionCodes.pss4CanHandleYourPersonalProblem]: AnswerScoreMapFalling
};

const totalRiskLevel = 3;

const ranges = {
    low: {
        key: "low",
        label: "ranges.low",
        range: "0-5"
    },
    intermediate: {
        key: "moderate",
        label: "ranges.moderate",
        range: "6-10"
    },
    high: {
        key: "high",
        label: "ranges.high",
        range: "11-16"
    }
};

const SisuStressPss4GuidelineDefinition = [
    {
        min: 11,
        max: 16,
        key: "high",
        label: "ranges.high",
        rangeInfo: "11-16",
        ranges,
        riskLevel: 3 / (totalRiskLevel + 1)
    },
    {
        min: 6,
        max: 10,
        key: "moderate",
        label: "ranges.moderate",
        rangeInfo: "6-10",
        ranges,
        riskLevel: 2 / (totalRiskLevel + 1)
    },
    { min: 0, max: 5, key: "low", label: "ranges.low", rangeInfo: "0-5", ranges, riskLevel: 1 / (totalRiskLevel + 1) }
];

function CalcScore(answers) {
    return Object.keys(answers).reduce((calculatedScore, question) => {
        let score = 0;
        const scoreMap = ScoreMap[question];
        if (scoreMap) {
            const match = scoreMap.find(obj => obj.answer === answers[question]);
            score = match ? match.score : 0;
        }

        return calculatedScore + score;
    }, 0);
}

/**
 * Finds the guideline for the given score
 * @param score
 * @returns {*}
 */
function SisuGuideline(score) {
    return SisuStressPss4GuidelineDefinition.find(gl => CoreUtils.isWithin(score, gl.min, gl.max));
}

function StressPss4(answers) {
    const score = CalcScore(answers);
    const guideline = SisuGuideline(score);
    const rangeInfo = guideline.rangeInfo;
    const key = guideline.key;
    const ranges = SisuStressPss4GuidelineDefinition;
    const label = guideline.label;
    return { score, rangeInfo, key, ranges, label };
}

const RiskName = "STRESS_PSS4";
const RiskCode = "STRESS_PSS4";
const GuidelineName = "SiSU STRESS_PSS4 Guideline";
const GuidelineCode = "SISU_STRESS_PSS4";

export {
    CalcScore,
    SisuGuideline,
    StressPss4,
    SisuStressPss4GuidelineDefinition,
    RiskName,
    RiskCode,
    GuidelineName,
    GuidelineCode
};
