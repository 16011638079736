import BaseEndPoint from "../BaseEndPoint";

export default class UserSubscriptions extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    get() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("/user/subscriptions"), settings);
    }

    manage(subscriptionConsents) {
        return super.post("/user/subscriptions", { subscriptionConsents });
    }

    subscriptionCheckout() {
        return super.post("/subscriptions/checkout");
    }

    subscriptionPortal() {
        return super.post("/subscriptions/portal");
    }
}
