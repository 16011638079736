import {
    isMobileView,
    isUnitImperial,
    calculateMinMax,
    createYGridValues,
    flattenPointValues,
    createPlottableDataPoints,
    calculateMeanValue,
    downBy
} from "components/Templates/TrendChart/Line/utils";
import { UnitConverter } from "@sisuwellness/utilities";
import { fixedNumber } from "utilities/commonUtils";
import { theme } from "@sisuwellness/web-components";
import { t } from "i18next";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

const METRIC_FIELDS = {
    rounded: false,
    key: ["height"],
    additionalKeys: ["heightInString"]
};

/**
 * Update data wrt to unit preference
 * @param {object} data
 * @param {object} settings
 * @return {{ rating: { key: string, label: string, ranges: object }, height: number, heightInString: string }}
 */
const heightWithUnitPreference = (data, settings) => {
    if (!data.height) return { height: null };
    const { feet, inches } = UnitConverter.cmToFtInch(data.height);
    const heightInFeetString = `${feet}' ${fixedNumber(inches)}"`;
    const heightInCm = `${fixedNumber(data.height)} cm`;

    const convertToOnlyFeet = feet + inches / 12;

    return {
        ...data,
        heightInString: isUnitImperial(settings) ? heightInFeetString : heightInCm,
        height: isUnitImperial(settings) ? fixedNumber(convertToOnlyFeet) : data.height
    };
};

const plot = {
    // Lookup key for weight value in string
    keyForValueString: () => "heightInString",

    unit: settings =>
        isUnitImperial(settings)
            ? `${t("words.height")} (${t("units.long_feet")})`
            : `${t("words.height")} (${t("units.centimetres")})`,

    color: () => defaultColor,

    dataPoints: (healthChecks = [], settings) => {
        const points = createPlottableDataPoints(data => heightWithUnitPreference(data, settings))(
            healthChecks,
            METRIC_FIELDS.key,
            METRIC_FIELDS.additionalKeys
        );
        return points;
    },

    yRange: (points = []) => {
        const { minimum, maximum } = calculateMinMax(flattenPointValues(points, "y"));
        const ranges = createYGridValues(downBy(minimum), maximum, { parts: 5, rounded: METRIC_FIELDS.rounded }).map(
            num => fixedNumber(num)
        );

        return {
            min: ranges[0],
            yScaleRanges: ranges,
            max: ranges[ranges.length - 1]
        };
    },

    legends: () => [],

    historyProps: (filteredPoints = [], latestHealthCheckData = {}, settings) => {
        const { height } = latestHealthCheckData;
        const datum = heightWithUnitPreference({ height }, settings);

        let meanHeight = calculateMeanValue("height", filteredPoints[0]);

        // filtered points have height according to unit preference
        if (isUnitImperial(settings)) meanHeight = UnitConverter.ftInchToCm(meanHeight, 0);

        const meanDatum = heightWithUnitPreference({ height: meanHeight }, settings);

        return {
            value: datum.heightInString,
            meanValue: meanDatum.heightInString ?? "-"
        };
    },

    margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
};

export default { plottingUtility: plot };
