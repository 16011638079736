import React from "react";
import PropTypes from "prop-types";

import { Flex, Box } from "rebass";
import { border } from "styled-system";
import styled from "styled-components";

// ------------------------------------------------------------------------

const Progress = styled(Box)(border);
export const ProgressBar = React.forwardRef(
    ({ bg = "black", height = "5px", borderRadius = "", children = "", ...rest }, forwardRef) => (
        <Box width="100%">
            <Flex width="100%" justifyContent="center" style={{ position: "relative" }}>
                <Progress
                    bg={bg}
                    height={height}
                    width="100%"
                    borderRadius={borderRadius}
                    {...rest}
                    as="hr"
                    ref={forwardRef}
                />
                {children}
            </Flex>
        </Box>
    )
);

// -----------------------------------------------------------------------

ProgressBar.propTypes = {
    bg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.string,
    borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
    children: PropTypes.any
};

export default ProgressBar;
