import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const TooltipWrapper = styled.div`
    > div {
        background-color: ${props => props.theme.colours.flat.black.hex} !important;
        ${props => props.theme.shadowCss.float()};
        &.place-top {
            &:after {
                border-top-color: ${props => props.theme.colours.flat.black.hex} !important;
                border-top-style: solid !important;
                border-top-width: 6px !important;
            }
        }
    }
`;

const Tooltip = ({ name, tooltip, tooltipProps, children, ...rest }) => {
    return (
        <>
            <a data-tip data-for={name} {...rest}>
                {children}
            </a>
            <TooltipWrapper>
                <ReactTooltip id={name} {...tooltipProps}>
                    {tooltip}
                </ReactTooltip>
            </TooltipWrapper>
        </>
    );
};

Tooltip.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.node.isRequired,
    tooltipProps: PropTypes.object
};

Tooltip.defaultProps = {
    tooltipProps: {
        effect: "solid"
    }
};

export default Tooltip;
