import React, { useEffect, useContext } from "react";

import { useSelector } from "react-redux";
import { Card, Flex } from "rebass";
import { ThemeContext } from "styled-components";

// --------------------------------------------------------------------

import AdSlotView from "components/AdSlotView";
import PageLayout from "components/PageLayout";
import RecommendationCard from "./recommendationCard";

import { AD_SLOT_CODES } from "@sisuwellness/utilities/CampaignAd";
import { HeadingLabel as Heading, NormalLabel as Label } from "@sisuwellness/ui/src/components/Labels";
import { SMOKING } from "constants/trend-charts";
import PropTypes from "prop-types";
import EmptyState from "./emptyState";
import { PremiumFeature } from "components/PremiumFeatures";
import InterpolatedRecommendation from "utilities/Recommendation";
import FlexWithGap from "components/Flex";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------

const RecommendationsView = ({ history }) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);
    const { recommendations, latestUserCampaignActivity, userProfile } = useSelector(state => state.auth.citizen);
    const campaignId = latestUserCampaignActivity ? latestUserCampaignActivity.campaignId : null;
    const gender = userProfile ? userProfile.gender : "male";
    const isRecommendationAvailable = recommendations.length > 0;
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    let filteredRecommendations = recommendations.map(recommendation => {
        // calulate smoking rating based on the priority
        // (smoking recommendation does not have a rating key)
        if (recommendation.risk === SMOKING) {
            recommendation.rating = recommendation.priority === 1 ? "normal" : "high";
        }
        return recommendation;
    });

    return (
        <PageLayout>
            <Heading fontSize={["28px", "34px"]} fontWeight={theme.fontWeight.intermediate}>
                {t("words.recommendations")}
            </Heading>
            {isRecommendationAvailable && (
                <>
                    <Label fs={15} mt="16px" fontWeight="normal">
                        {t("members_portal_web.views.recommendations.heading")}
                    </Label>
                    <Flex
                        data-testid="recommendations"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        flexDirection={["column-reverse", "row"]}
                        pb="140px"
                    >
                        <FlexWithGap mt="24px" flex="2 0 65%" flexWrap="wrap" jc="center" fd="column" gap="10px">
                            <PremiumFeature forceRender>
                                {filteredRecommendations.map((recommendation, index) => (
                                    <InterpolatedRecommendation
                                        key={index}
                                        index={index}
                                        recommendation={recommendation}
                                    >
                                        <RecommendationCard />
                                    </InterpolatedRecommendation>
                                ))}
                            </PremiumFeature>
                        </FlexWithGap>
                    </Flex>
                </>
            )}
            {!isRecommendationAvailable && <EmptyState gender={gender} history={history} theme={theme} />}
            <Card mb="10px">
                <AdSlotView adSlotCode={AD_SLOT_CODES.PORTAL_HEALTH_HUB_FOOTER} campaignId={campaignId} criteria={{}} />
            </Card>
        </PageLayout>
    );
};

RecommendationsView.propTypes = {
    history: PropTypes.object
};

export default RecommendationsView;
