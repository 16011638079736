import React from "react";

const HeartAgeIcon = () => (
    <svg width="96" height="85" viewBox="0 0 96 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.05878 9.19313C18.4705 -0.397709 33.7299 -0.397709 43.1416 9.19313L46.8671 12.9895C47.2591 13.3889 47.9026 13.3889 48.2946 12.9895L52.02 9.19313C61.4317 -0.397709 76.6911 -0.397709 86.1028 9.19313C95.5145 18.784 95.5145 34.3338 86.1028 43.9246L49.0083 81.7251C48.2243 82.524 46.9373 82.524 46.1533 81.7251L9.05878 43.9246C-0.352928 34.3338 -0.352928 18.784 9.05878 9.19313Z"
            stroke="#ff7d7d"
            strokeWidth="4"
        />
    </svg>
);

HeartAgeIcon.propTypes = {};

export default HeartAgeIcon;
