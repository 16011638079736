import React from "react";
import PropTypes from "prop-types";

import { withTheme } from "styled-components";
import { useMediaQuery } from "react-responsive";

/**
 * MediaQuery component to determine what to render based on view size
 *
 * @param {Object} props
 * @param {JSX.Element} props.children React Node to render
 * @param {('mobile'|'tablet'|'desktop')[]} props.devices Minimum device requirement
 * @param {Object<string, any>} props.theme Theme object
 */
const MediaQuery = ({ children, devices, theme: { mediaQuerySizes = {} } }) => {
    const mediaQueries = {
        desktop: useMediaQuery({
            minWidth: mediaQuerySizes.desktop
        }),
        tablet: useMediaQuery({
            minWidth: mediaQuerySizes.mobile + 1,
            maxWidth: mediaQuerySizes.desktop - 1
        }),
        mobile: useMediaQuery({
            maxWidth: mediaQuerySizes.mobile
        })
    };

    return <>{devices.some(device => mediaQueries[device]) && children}</>;
};

MediaQuery.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object.isRequired,
    devices: PropTypes.arrayOf(PropTypes.oneOf(["mobile", "tablet", "desktop"]))
};

MediaQuery.defaultProps = {
    devices: ["mobile", "tablet", "desktop"]
};

export default withTheme(MediaQuery);
