export default class Answer {
    constructor(answer) {
        this.answer = answer;
        this.piiScrubbed = false;
    }

    getRawAnswer() {
        return this.answer;
    }
}
