import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Keyboard, { Style } from "./base/Keyboard";
import SymbolsNumericReducedLayout from "./base/layouts/SymbolsNumericReducedLayout";
import "./base/Keyboard.css"; // TODO: use styled-component
import { withTranslation } from "react-i18next";
class SisuKeyboard extends PureComponent {
    static propTypes = {
        inputNode: PropTypes.any,
        layoutStyle: PropTypes.string,
        disableSymbols: PropTypes.bool,
        disableAlphabets: PropTypes.bool,
        onClick: PropTypes.func,
        onNext: PropTypes.func,
        nextEnabled: PropTypes.bool,
        isFirstLetterUppercase: PropTypes.bool,
        uppercaseOnly: PropTypes.bool,
        enableDecimal: PropTypes.bool,
        defaultEnterText: PropTypes.string,
        t: PropTypes.func
    };

    static defaultProps = {
        layoutStyle: Style.sisu,
        disableSymbols: false,
        disableAlphabets: false,
        nextEnabled: true,
        isFirstLetterUppercase: false,
        uppercaseOnly: false,
        enableDecimal: false,
        defaultEnterText: "buttons.uc_next"
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Keyboard
                inputNode={this.props.inputNode}
                onClick={this.props.onClick}
                onNext={value => this.props.onNext(value)}
                isNumeric={false}
                style={this.props.layoutStyle}
                disableSymbols={this.props.disableSymbols}
                disableAlphabets={this.props.disableAlphabets}
                symbolsLayout={SymbolsNumericReducedLayout}
                nextEnabled={this.props.nextEnabled}
                isFirstLetterUppercase={this.props.isFirstLetterUppercase}
                data-testid={"touch-keyboard"}
                uppercaseOnly={this.props.uppercaseOnly}
                enableDecimal={this.props.enableDecimal}
                defaultEnterText={this.props.t(this.props.defaultEnterText)}
            />
        );
    }
}

export default withTranslation()(SisuKeyboard);
