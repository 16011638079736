import React, { useEffect, useContext } from "react";

import { ThemeContext } from "styled-components";
import { pathOr } from "ramda";
import { useLocation } from "react-router";

// ----------------------------------------------------------------------------

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Link, Redirect } from "react-router-dom";

// ----------------------------------------------------------------------------

import withAuthentication from "components/withAuthentication";

import routes from "constants/routes";
import APIClient from "utilities/APIClient";
import InputField from "components/InputField";

// ----------------------------------------------------------------------------

import { useStateObject } from "@sisuwellness/utilities/hooks";
import { AuthClientFooter } from "@sisuwellness/web-components";
import { FlowState } from "@sisuwellness/utilities/User";
import { useTranslation } from "react-i18next";

import {
    ButtonContainer,
    CardHeader,
    CardSubHeader,
    Container,
    MessageBox,
    MessageText,
    FooterText,
    LoginCard,
    LogoContainer,
    PortalButton,
    SisuLogo,
    ErrorWrapper,
    LoginWrapper
} from "components/CommonComponents";
import {
    CHECK_EMAIL_ACTIVATION_LINK_MESSAGE,
    EXPIRED_SESSION_ERROR_MESSAGE,
    INVALID_ACCESS_ERROR_MESSAGE,
    INVALID_EMAIL_ADDRESS_ERROR_MESSAGE,
    INVALID_REG_LINK_ERROR_MESSAGE,
    NOT_REGISTERED_EMAIL_ERROR_MESSAGE,
    NO_NETWORK_ERROR_MESSAGE,
    REQUEST_FAILED_ERROR_MESSAGE
} from "constants/messages";
import PageLayout from "components/PageLayout";
import { PORTAL_IMAGE } from "constants/imagePath";
import { REGISTRATION_TOKEN } from "constants/";
import useRudderStack from "hooks/useRudderStack";
import FormLocalisationWrapper from "utilities/FormLocalisationWrapper";

// ----------------------------------------------------------------------------

const IdentifyView = () => {
    const theme = useContext(ThemeContext);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const setupTokenFromSearchParams = searchParams.get("st");
    const registrationTokenFromSearchParams = searchParams.get("rt");
    const rudderStack = useRudderStack();
    const { t } = useTranslation();

    const localisedRequestFailedErrorMessage = t(REQUEST_FAILED_ERROR_MESSAGE);
    const localisedInvalidEmailAddressErrorMessage = t(INVALID_EMAIL_ADDRESS_ERROR_MESSAGE);

    const [state, setState] = useStateObject({
        submitting: false,
        flowState: setupTokenFromSearchParams
            ? FlowState.FLOW_STATE_USER_REQUIRES_COMPLETION
            : FlowState.FLOW_STATE_USER_UNKNOWN,
        errorMessage: null,
        infoMessage: null,
        username: "",
        registrationToken: registrationTokenFromSearchParams,
        setupToken: setupTokenFromSearchParams,
        campaign: null,
        campaignCode: searchParams.get("cc")
    });

    if (registrationTokenFromSearchParams)
        window.localStorage.setItem(REGISTRATION_TOKEN, registrationTokenFromSearchParams);

    let isMounted = true;
    const handleSetState = state => isMounted && setState(state);

    useEffect(() => {
        const { flowState, campaignCode } = state;
        async function loadCampaignData() {
            const response = await APIClient.campaigns.search(state.campaignCode);
            if (response.succeeded()) {
                const campaign = await response.body();
                handleSetState({ campaign });
            }
        }
        if (FlowState.userIsUnknown(flowState) && campaignCode) {
            loadCampaignData();
        }

        return () => (isMounted = false);
    }, []);

    async function handleSubmit(event) {
        handleSetState({ username: event.username });

        handleSetState({
            errorMessage: null,
            infoMessage: null,
            submitting: true
        });

        // user submits email
        try {
            const response = await APIClient.auth.peep(event.username, state.registrationToken);
            if (response.succeeded()) {
                const responseBody = await response.body();

                if (responseBody.userState === FlowState.USER_STATE_COMPLETE) {
                    handleSetState({
                        flowState: FlowState.FLOW_STATE_USER_EXISTS
                    });
                } else if (responseBody.userState === FlowState.USER_STATE_INCOMPLETE) {
                    handleSetState({ infoMessage: t(CHECK_EMAIL_ACTIVATION_LINK_MESSAGE) });
                    rudderStack.trackEvent("Account Authentication Failure", { type: "noPassword" });
                }
            } else if (response.isNetworkFailure()) {
                handleSetState({
                    errorMessage: t(NO_NETWORK_ERROR_MESSAGE)
                });
            } else if (response.isBadRequest()) {
                handleSetState({
                    errorMessage: localisedInvalidEmailAddressErrorMessage
                });
            } else if (response.isNotFound()) {
                const responseBody = await response.body();
                if (state.registrationToken) {
                    // validate the token first
                    const tokenResponse = await APIClient.tokens.validateToken(state.registrationToken, "campaign");
                    const campaign = pathOr(null, ["rawPayload", "data", "campaign"], tokenResponse);

                    // token is valid, continue to registration page
                    if (tokenResponse.succeeded()) {
                        handleSetState({
                            registrationConsents: responseBody.registrationConsents,
                            tokenLocale: responseBody.tokenLocale,
                            flowState: FlowState.FLOW_STATE_USER_CAN_REGISTER,
                            campaign
                        });
                    } else {
                        handleSetState({
                            errorMessage: t(INVALID_REG_LINK_ERROR_MESSAGE)
                        });
                    }
                } else {
                    handleSetState({
                        errorMessage: t(NOT_REGISTERED_EMAIL_ERROR_MESSAGE)
                    });
                }
            } else {
                window.Rollbar.error("Peep returned status code [" + response.rawPayload.status + "]", response);
                handleSetState({
                    errorMessage: localisedRequestFailedErrorMessage
                });
            }
        } catch (error) {
            window.Rollbar.error("Failed to call peep", error);
            handleSetState({
                errorMessage: localisedRequestFailedErrorMessage
            });
        } finally {
            handleSetState({
                submitting: false
            });
            window.scrollTo(0, 0);
        }
    }

    const {
        flowState,
        username,
        campaign,
        registrationToken,
        registrationConsents,
        tokenLocale,
        setupToken,
        campaignCode,
        errorMessage,
        infoMessage,
        submitting
    } = state;

    if (FlowState.userExists(flowState)) {
        return (
            <Redirect
                to={{
                    pathname: routes.login,
                    state: {
                        pathname: routes.root,
                        ...location.state,
                        registrationToken,
                        username,
                        campaign
                    }
                }}
            />
        );
    }

    // user has a legitimate setup token, redirect to setup
    else if (FlowState.userRequiresCompletion(flowState)) {
        return (
            <Redirect
                to={{
                    pathname: `${routes.setup}/${setupToken}`,
                    state: { pathname: routes.root, ...location.state, campaignCode },
                    search: location.search
                }}
            />
        );
    }

    // user has a legitimate registration token, redirect to registration
    else if (FlowState.userCanRegister(flowState)) {
        return (
            <Redirect
                to={{
                    pathname: `${routes.register}/${registrationToken}`,
                    state: {
                        ...location.state,
                        username: username,
                        consents: registrationConsents,
                        tokenLocale,
                        campaign
                    }
                }}
            />
        );
    }

    const EmailSchema = Yup.object().shape({
        username: Yup.string()
            .email(localisedInvalidEmailAddressErrorMessage)
            .required(t("yup_messages.email.required_alt"))
    });

    const clientCode = pathOr(null, ["campaign", "client", "code"], state);
    const invalidAccess = searchParams.get("invalid_access") === "true" ? t(INVALID_ACCESS_ERROR_MESSAGE) : "";
    const expiredToken = searchParams.get("token_expired") === "true" ? t(EXPIRED_SESSION_ERROR_MESSAGE) : "";
    const error = errorMessage || invalidAccess || expiredToken;

    return (
        <PageLayout containerProps={{ bg: theme.colours.hpPrimaryPurple.hex }}>
            <LoginWrapper>
                <ErrorWrapper width={"50%"} maxWidth={"720px"} mobileWidth={"90%"}>
                    {error && (
                        <MessageBox error={true} data-testid="error-message">
                            <MessageText>{error}</MessageText>
                        </MessageBox>
                    )}
                    {infoMessage && (
                        <MessageBox data-testid="info-container">
                            <MessageText>{infoMessage}</MessageText>
                        </MessageBox>
                    )}
                </ErrorWrapper>
                <LoginCard>
                    <div style={{ width: "100%" }}>
                        <LogoContainer>
                            <SisuLogo src={`${PORTAL_IMAGE}/sisu-health-logo.png`} alt="SiSU Logo" />
                        </LogoContainer>
                        <CardHeader>
                            {!registrationToken
                                ? t("members_portal_web.views.identify.sign_in")
                                : t("members_portal_web.views.identify.sign_up_or_sign_in")}
                        </CardHeader>
                        <CardSubHeader>{t("members_portal_web.views.identify.enter_your_email")}</CardSubHeader>
                        <Formik initialValues={{ username }} validationSchema={EmailSchema} onSubmit={handleSubmit}>
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setTouched }) => {
                                return (
                                    <Form data-testid="identify-form">
                                        <FormLocalisationWrapper touched={touched} setTouched={setTouched}>
                                            <InputField
                                                fontFamily="GT Walsheim Pro"
                                                inputProps={{
                                                    name: "username",
                                                    placeholder: t("words.email"),
                                                    "data-testid": "username",
                                                    onChange: handleChange,
                                                    value: values.username,
                                                    onBlur: handleBlur,
                                                    id: "text-field-id-username"
                                                }}
                                                error={touched.username ? errors.username : ""}
                                            />
                                            <ButtonContainer mTop="40px">
                                                <PortalButton
                                                    disabled={submitting}
                                                    id={"identify-next-button"}
                                                    type={"submit"}
                                                    onClick={handleSubmit}
                                                    data-testid="next-button"
                                                >
                                                    {t("words.next")}
                                                </PortalButton>
                                            </ButtonContainer>
                                        </FormLocalisationWrapper>
                                    </Form>
                                );
                            }}
                        </Formik>
                        {!registrationToken && (
                            <Container data-testid="no-account-link">
                                <FooterText fontSize={["16px", "18px"]}>
                                    {t("members_portal_web.views.identify.no_account")}{" "}
                                    <Link to={routes.signup}>{t("members_portal_web.views.identify.sign_up_now")}</Link>
                                </FooterText>
                            </Container>
                        )}
                        <AuthClientFooter clientCode={clientCode} />
                    </div>
                </LoginCard>
            </LoginWrapper>
        </PageLayout>
    );
};

export default withAuthentication(IdentifyView);
