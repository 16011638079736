import React from "react";
import PropTypes from "prop-types";

import { Collapse } from "react-bootstrap";
import { Box, Text } from "rebass";

export const ErrorNotification = ({ boolExp, children, ...rest }) => (
    <Collapse in={!!boolExp} unmountOnExit>
        <Box width="100%" textAlign="left">
            <Text mt="20px" color="primaryRed" fontSize="11px" {...rest} data-testid="error-text">
                {children}
            </Text>
        </Box>
    </Collapse>
);

ErrorNotification.propTypes = {
    children: PropTypes.any,
    boolExp: PropTypes.any.isRequired
};
