/* eslint-disable no-undef */
import Client from "@sisuwellness/api-client";

export default new Client({
    domain: process.env.REACT_APP_API_URL,
    memberApiDomain: process.env.REACT_APP_MEMBER_API_URL,
    buildVersion: process.env.REACT_APP_BUILD_VERSION,
    releaseVersion: process.env.REACT_APP_RELEASE_VERSION,
    origin: Client.Origins.portal,
    clientType: Client.ClientTypes.USER
});
