import Flex from "components/Flex";
import React, { useState } from "react";

import { Text, Box, Card, Image } from "rebass";

// ----------------------------------------------------------------

import { InformationModal, SectionHeaders } from "views/HeartAge/commonElements";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { HEART_AGE } from "constants/messages";
import { PORTAL_IMAGE } from "constants/imagePath";
import { TabNavigation } from "components/TabNavigation";

import { SimulatedTab } from "./simulated";
import { CurrentTab } from "./current";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------

const NAVIGATION = {
    CURRENT: "words.current",
    SIMULATED: "words.simulated"
};

const HeartAgeOverview = props => {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = useState("CURRENT");

    const isTabSimulated = currentTab === "SIMULATED";

    return (
        <Box width="100%" textAlign="left" {...props} data-testid="overview-heart-age">
            <SectionHeaders height="43px" width="100%" px="16px">
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    {t("members_portal_web.views.heart_age.page.overview.title")}
                </Text>
            </SectionHeaders>
            <Card bg="hpCardBackground" py="24px">
                <Flex
                    gap="16px"
                    jc="space-between"
                    fd={["column", "row"]}
                    pr={["0px", "6px", "21px"]}
                    pl={["0px", "42px", "21px"]}
                >
                    <HeadingLabel fontSize={["20px", "22px"]}>
                        {t("members_portal_web.views.heart_age.page.overview.heading")}
                    </HeadingLabel>
                    <InformationModal heading={t(HEART_AGE.MODAL.CALCULATE.HEADING)}>
                        {HEART_AGE.MODAL.CALCULATE.CONTENT(t)}
                        <Flex width="100%" jc="center" ai="center" mt="32px">
                            <Image src={`${PORTAL_IMAGE}/premium/factory.svg`} alt="factory.svg" />
                        </Flex>
                    </InformationModal>
                </Flex>
                <Flex fd="column" p={["24px 0px 0px", "24px 24px 0px"]}>
                    <TabNavigation
                        currentKey={currentTab}
                        setCurrentKey={setCurrentTab}
                        navigationKeyValuePairs={NAVIGATION}
                    />
                    <CurrentTab displayTab={!isTabSimulated} />
                    <SimulatedTab displayTab={isTabSimulated} />
                </Flex>
            </Card>
        </Box>
    );
};

export default HeartAgeOverview;
