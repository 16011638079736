import styled from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";
import { Text } from "rebass";

export const FooterText = styled.p`
    font-size: ${props => props.theme.fontSize[16].px};
    line-height: 1.23;
    letter-spacing: -0.22px;
    color: ${props => props.theme.colours.blackTints400.hex};
    margin: 10px 0 0 0;
    text-align: center;

    a {
        text-decoration: none;
        color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    }
`;

export const FlagCode = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 40px
        height: 30px;
        object-fit: cover;   
    }
`;

export const responsiveSelect = `
.spinner-box .spinner-chevron {
    ${mediaQueryForCss({
        "margin-right": ["10px"]
    })}
}

.spinner-box .spinner-value {
    ${mediaQueryForCss({
        "margin-left": ["10px"]
    })}
}

&::before {
    ${mediaQueryForCss({
        "font-size": ["14px"]
    })}
}
`;

export const SignupFormContainer = styled.div`
    display: flex;
    justify-content: space-between;
    ${mediaQueryForCss({
        "flex-direction": ["", "", "column"]
    })}

    form {
        width: 50%;
        ${mediaQueryForCss({
            width: ["50%", "50%", "100%"]
        })};

        ${mediaQueryForCss({
            "margin-bottom": ["", "", "48px"]
        })}
    }
`;

export const SpanLink = styled.span`
    color: ${props => props.theme.colours.flat.blackTint.hex};
    margin-left: 3px;
    text-decoration: underline;
`;

export const CheckboxLabel = styled(Text)`
    font-weight: 500;
    color: ${props => props.theme.colours.flat.blackTint.hex};
`;
