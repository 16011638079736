import React, { Component } from "react";
import PropTypes from "prop-types";
import QuestionTitle from "./Title";
import Help from "./Help";
import styled from "styled-components";
import { Validation, DateValidator } from "@sisuwellness/utilities/Validation";
import { TypeConverter } from "@sisuwellness/utilities/Converters/index";
import moment from "moment";
import { Errors, SelectField } from "../../Forms";
import theme from "../../../themes";
import QuestionIcon from "./QuestionIcon";
import { withTranslation } from "react-i18next";

const StyledContainer = styled.div`
    display: block;
`;

const StyledDateContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const StyleDateInput = styled.div`
    flex: 1 1 auto;
    margin: 1em;
    min-width: 200px;
`;

class DateQuestion extends Component {
    constructor(props) {
        super(props);

        // generate the available months
        let monthSelectOptions = [];
        for (let i = 1; i <= 12; i++) {
            let value = i < 10 ? "0" + i : "" + i;
            let label = moment(i, "M").format("MMMM");
            monthSelectOptions.push({ value: value, label });
        }

        // generate the available years, using a chosen direction
        let yearSelectOptions = [];
        const yearDirection = this.props.yearDirection || "asc";
        if (yearDirection === "asc") {
            for (let i = this.props.minYear; i <= this.props.maxYear; i++) {
                yearSelectOptions.push({ value: "" + i, label: "" + i });
            }
        } else {
            for (let i = this.props.maxYear; i >= this.props.minYear; i--) {
                yearSelectOptions.push({ value: "" + i, label: "" + i });
            }
        }

        const validation = new Validation();
        validation.addValidator(new DateValidator({}));

        if (validation.validate(props.value, true).length === 0) {
            let date = TypeConverter.stringToDate(props.value, "YYYY-MM-DD");

            let dayValue = date.format("DD");
            let monthValue = date.format("MM");
            let yearValue = date.format("Y");

            let daySelectOptions = this.buildDaySelectOptions(monthValue, yearValue);

            let dayChoice = daySelectOptions.filter(option => option.value.toString() === dayValue);
            let monthChoice = monthSelectOptions.filter(option => option.value.toString() === monthValue);
            let yearChoice = yearSelectOptions.filter(option => option.value.toString() === yearValue);

            this.state = {
                dateValidator: validation,
                day: {
                    value: dayValue,
                    defaultValue: dayChoice,
                    options: daySelectOptions
                },
                month: {
                    value: monthValue,
                    defaultValue: monthChoice,
                    options: monthSelectOptions
                },
                year: {
                    value: yearValue,
                    defaultValue: yearChoice,
                    options: yearSelectOptions
                }
            };
        } else {
            this.state = {
                dateValidator: validation,
                day: {
                    value: "",
                    defaultValue: "",
                    options: this.buildDaySelectOptions()
                },
                month: {
                    value: "",
                    defaultValue: "",
                    options: monthSelectOptions
                },
                year: {
                    value: "",
                    defaultValue: "",
                    options: yearSelectOptions
                }
            };
        }

        this.handleDayInputChange = this.handleDayInputChange.bind(this);
        this.handleMonthInputChange = this.handleMonthInputChange.bind(this);
        this.handleYearInputChange = this.handleYearInputChange.bind(this);
    }

    /**
     * Builds an options list for days based on a given month/year
     *
     * @param month
     * @param year
     * @returns {Array}
     */
    buildDaySelectOptions(month, year) {
        let endDay =
            moment((year || "2000") + "-" + (month || "01") + "01", "YYYY-MM-DD")
                .endOf("month")
                .format("DD") || 31;

        let daySelectOptions = [];
        for (let i = 1; i <= endDay; i++) {
            let value = i < 10 ? "0" + i : "" + i;
            daySelectOptions.push({ value, label: value });
        }

        return daySelectOptions;
    }

    /**
     * Handles the day selection input change
     * @param dayValue
     */
    handleDayInputChange(dayValue) {
        let day = this.state.day;

        day.value = dayValue;
        this.setState({ day });

        this.onValueChange();
    }

    /**
     * Handles the month selection input change
     * @param monthValue
     */
    handleMonthInputChange(monthValue) {
        let day = this.state.day;
        let month = this.state.month;

        day.options = this.buildDaySelectOptions(monthValue, this.state.year.value);
        month.value = monthValue;
        this.setState({ day, month });

        this.onValueChange();
    }

    /**
     * Handles the year selection input change
     * @param yearValue
     */
    handleYearInputChange(yearValue) {
        let day = this.state.day;
        let year = this.state.year;

        day.options = this.buildDaySelectOptions(this.state.month.value, yearValue);
        year.value = yearValue;
        this.setState({ day, year });

        this.onValueChange();
    }

    /**
     * Called on each successful select value change
     * If all values exist, trigger the onValueChange callback
     */
    onValueChange() {
        const day = this.state.day.value;
        const month = this.state.month.value;
        const year = this.state.year.value;

        if (year && month && day) {
            const dateString = [year, month, day].join("-");
            this.props.onValueChange(dateString);
        }
    }

    render() {
        let getIcon = this.props.model.getQuestionIcon();
        let hasErrors = this.props.errors && this.props.errors.length;

        let helpText = null;
        if (this.props.model.getHelpText()) {
            helpText = <Help>{this.props.t(this.props.model.getHelpText())}</Help>;
        }

        let validationErrors = null;
        if (hasErrors) {
            validationErrors = <Errors rounded={true}>{this.props.t(this.props.errors)}</Errors>;
        }

        return (
            <StyledContainer data-testid={"survey-question-date"}>
                {validationErrors}
                <QuestionIcon type={theme.icons.types[getIcon]} />
                <QuestionTitle hasErrors={hasErrors} htmlFor={this.props.id}>
                    {this.props.t(this.props.model.getDisplayText())}
                </QuestionTitle>
                {helpText}
                <StyledDateContainer>
                    <StyleDateInput>
                        <SelectField
                            id={this.props.id + "-day"}
                            options={this.state.day.options}
                            defaultValue={this.state.day.defaultValue}
                            onValueChange={this.handleDayInputChange}
                            placeholder={"Day"}
                        />
                    </StyleDateInput>

                    <StyleDateInput>
                        <SelectField
                            id={this.props.id + "-month"}
                            options={this.state.month.options}
                            defaultValue={this.state.month.defaultValue}
                            onValueChange={this.handleMonthInputChange}
                            placeholder={"Month"}
                        />
                    </StyleDateInput>
                    <StyleDateInput>
                        <SelectField
                            id={this.props.id + "-year"}
                            options={this.state.year.options}
                            defaultValue={this.state.year.defaultValue}
                            onValueChange={this.handleYearInputChange}
                            placeholder={"Year"}
                        />
                    </StyleDateInput>
                </StyledDateContainer>
            </StyledContainer>
        );
    }
}

DateQuestion.displayName = "Date Question";
DateQuestion.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    onValueChange: PropTypes.func.isRequired,
    minYear: PropTypes.string.isRequired,
    maxYear: PropTypes.string.isRequired,

    value: PropTypes.string,
    yearDirection: PropTypes.string,
    errors: PropTypes.any,
    t: PropTypes.func
};

export default withTranslation(DateQuestion);
