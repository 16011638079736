import React from "react";
// import { path } from "ramda";
import { Cell, Header, LinkCell } from "./commonElements";
import routes from "constants/routes";
import { fixedNumber } from "utilities/commonUtils";
import { t } from "i18next";

const handleCellConstruct = (row, { isInterestedInPremium, isPremium }) => {
    return isPremium ? (
        <Cell data-testid="table-cell-muscleMass" row={row} value={fixedNumber(row.value)} unit="%" />
    ) : (
        isInterestedInPremium && (
            <LinkCell
                row={row}
                data-testid="table-cell-muscleMass"
                href={routes.bodyComposition}
                text={t("words.calculate")}
            />
        )
    );
};

export const muscleMassDef = (iconTypes, premiumProps) => ({
    minWidth: 92,
    accessor: "muscleMass",
    Cell: row => handleCellConstruct(row, premiumProps),
    Header: () => <Header label={t("words.skeletal_mass")} iconType={iconTypes.muscleMass} />
});
