import React, { Component } from "react";
import PropTypes from "prop-types";
import TemplateOne from "./Templates/TemplateOne";
import Survey from "../Survey/Survey";

export default class Consent extends Component {
    static displayName = "Consent Component";

    static propTypes = {
        model: PropTypes.object.isRequired,
        onComplete: PropTypes.func.isRequired,
        theme: PropTypes.object,
        touchKeyboard: PropTypes.bool,
        getComponentForQuestionType: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            userAccepts: null
        };

        this.handleAcceptConsent = this.handleAcceptConsent.bind(this);
        this.handleRejectConsent = this.handleRejectConsent.bind(this);
        this.handleSurveyComplete = this.handleSurveyComplete.bind(this);
    }

    handleAcceptConsent() {
        this.props.model.userAccepted = true;
        this.setState({ userAccepts: true });

        if (!this.props.model.getSurvey()) {
            this.props.onComplete();
        }
    }

    handleRejectConsent() {
        this.props.model.userAccepted = false;
        this.setState({ userAccepts: false });
        this.props.onComplete();
    }

    handleSurveyComplete() {
        this.props.onComplete();
    }

    compositeTemplateByConsent() {
        let output = null;
        let model = this.props.model;
        switch (true) {
            case model.usesTemplateOne():
                output = (
                    <TemplateOne
                        schema={model.configuration.templateOne}
                        onAccepted={this.handleAcceptConsent}
                        onRejected={this.handleRejectConsent}
                    />
                );
                break;

            default:
                throw new Error("Unsupported template [" + model.templateName + "]");
        }

        // capture the successful render of this consent
        model.shownToUser = true;

        return output;
    }

    render() {
        let output = null;
        let { model, touchKeyboard } = this.props;

        switch (this.state.userAccepts) {
            case true:
                output = (
                    <Survey
                        onComplete={this.handleSurveyComplete}
                        model={model.getSurvey()}
                        touchKeyboard={touchKeyboard}
                        getComponentForQuestionType={this.props.getComponentForQuestionType}
                    />
                );
                break;
            case false:
                break;
            default:
                output = this.compositeTemplateByConsent();
        }

        return output;
    }
}
