import React, { useContext } from "react";

import { Flex, Image, Text } from "rebass";
import { useHistory } from "react-router";
import { ThemeContext } from "styled-components";

//........................................................

import routes from "constants/routes";
import PageLayout from "components/PageLayout";
import withAuthentication from "components/withAuthentication";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { useTranslation } from "react-i18next";

//........................................................

const NotFoundView = () => {
    const history = useHistory();
    const theme = useContext(ThemeContext);
    const { t } = useTranslation();

    return (
        <PageLayout>
            <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                width="100%"
                height="calc(100vh - 200px)"
            >
                <Image
                    src={`${window.PUBLIC_URL}/images/globe.svg`}
                    width={["100px", "152px"]}
                    height={["120px", "184px"]}
                />
                <Text
                    fontSize={["34px", "50px"]}
                    color={theme.colours.hpPrimaryPurple.hex}
                    fontWeight={theme.fontWeight.intermediate}
                    mt="40px"
                >
                    {t("words.ooops")}
                </Text>
                <Text
                    fontSize={["20px", "28px"]}
                    color={theme.colours.hpPrimaryPurple.hex}
                    fontWeight={theme.fontWeight.intermediate}
                    mt="24px"
                    mb={["32px", "40px"]}
                    textAlign="center"
                >
                    {t("members_portal_web.views.not_found.text")}
                </Text>
                <PrimaryButton
                    width="200px"
                    onClick={() => {
                        history.push(routes.root);
                    }}
                >
                    {t("words.home")}
                </PrimaryButton>
            </Flex>
        </PageLayout>
    );
};

//........................................................

export default withAuthentication(NotFoundView);
