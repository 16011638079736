import React from "react";
import PropTypes from "prop-types";

import { Box, Text } from "rebass";

// --------------------------------------------------------------

import { CustomCardStack } from "./styled";

// --------------------------------------------------------------

const CategorisedWidget = ({ id, title, widgetList }) => (
    <Box mb="60px" data-testid={id}>
        <Text fontSize="34px" mb={["16px", "40px"]} fontWeight="normal" id="healthhub-title" color="hpPrimaryPurple">
            {title}
        </Text>
        <CustomCardStack itemsLength={widgetList.length} gap={["16px"]}>
            {widgetList.map((item, index) => item(index))}
        </CustomCardStack>
    </Box>
);

CategorisedWidget.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    widgetList: PropTypes.array
};

export default CategorisedWidget;
