import React from "react";

const LineComponent = ({ series, lineGenerator, xScale, yScale }) =>
    series.map(({ id, data, color }) => (
        <path
            key={id}
            fill="none"
            stroke={color}
            d={lineGenerator(data.map(d => ({ x: xScale(d.data.x), y: yScale(d.data.y) })))}
        />
    ));

export default LineComponent;
