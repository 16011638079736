import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";

// ------------------------------------------------------------------------

import InputField from "components/InputField";
import { LockIcon } from ".";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------

export const EmailField = ({ touched, errors, values, handleBlur, handleChange, isLocked, ...rest }) => {
    const { t } = useTranslation();
    const inputValue = values.email;

    const inputProps = {
        name: "email",
        onBlur: handleBlur,
        value: inputValue,
        disabled: !!isLocked,
        onChange: handleChange,
        "data-testid": "email"
    };

    const error = touched.email ? t(errors.email) : false;

    return (
        <InputField
            label={t("words.email")}
            error={error}
            isLocked={isLocked}
            inputProps={inputProps}
            rightChild={isLocked && <LockIcon />}
            {...rest}
        />
    );
};

EmailField.propTypes = {
    ...Text.propTypes,
    isLocked: PropTypes.bool,
    errors: PropTypes.object,
    values: PropTypes.object,
    touched: PropTypes.object,
    handleBlur: PropTypes.func,
    handleChange: PropTypes.func
};
