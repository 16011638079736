import React from "react";
import PropTypes from "prop-types";
import { baseTheme } from "../../../theme";

const AreaLayer = ({
    label,
    labelMarginBottom,
    fill,
    fillOpacity,
    yScale,
    innerWidth,
    lowerBound,
    upperBound,
    labelColor,
    width
}) => {
    const isMobileScreen = width < 600;

    const CHAR_WIDTH = isMobileScreen ? 6.5 : 8;
    const labelWidth = label.length * CHAR_WIDTH;
    const labelPos = innerWidth - labelWidth;
    const lowerBoundY = yScale(lowerBound);
    const upperBoundY = yScale(upperBound);

    return (
        <>
            <text
                x={labelPos}
                fill={labelColor}
                y={lowerBoundY - labelMarginBottom}
                fontSize={isMobileScreen ? "smaller" : "normal"}
            >
                {label}
            </text>
            <rect
                x="0"
                fill={fill}
                y={upperBoundY}
                width={innerWidth}
                fillOpacity={fillOpacity}
                height={Math.abs(upperBoundY - lowerBoundY)}
            />
        </>
    );
};

AreaLayer.propTypes = {
    label: PropTypes.string.isRequired,
    labelMarginBottom: PropTypes.number.isRequired,
    yScale: PropTypes.func.isRequired,
    innerWidth: PropTypes.number.isRequired,
    lowerBound: PropTypes.number.isRequired,
    upperBound: PropTypes.number.isRequired,
    fill: PropTypes.string.isRequired,
    fillOpacity: PropTypes.number.isRequired,
    labelColor: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired
};

AreaLayer.defaultProps = {
    label: "",
    fillOpacity: 0.8,
    labelMarginBottom: 5,
    fill: baseTheme.portalColor.hpPurple200.hex,
    labelColor: baseTheme.colours.flat.primaryBlack.hex
};

export default AreaLayer;
