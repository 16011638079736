export default class BaseValidator {
    /**
     *
     * @param options
     */
    constructor(options = {}) {
        this._options = Object.assign({}, this._getDefaultOptions(), options);
    }

    /**
     *
     * @return {{}}
     * @private
     */
    /* istanbul ignore next */
    _getDefaultOptions() {
        return {};
    }

    /**
     * public interface
     * A function that validates the given value based on the options passed at construction
     * Adds error messages to an array.
     *
     * @param value
     * @return {String[]}
     */
    /* istanbul ignore next */
    validate(value) {
        return ["You have not implemented this validator: [" + value + "]"];
    }
}
