import React from "react";
import PropTypes from "prop-types";

import { Box, Text } from "rebass";
import { default as ReactSelect, components } from "react-select";
import styled from "styled-components";
import { layout, compose, typography, space } from "styled-system";

import { theme } from "@sisuwellness/web-components";
import { hexToRgba } from "utilities/commonUtils";
import { ErrorNotification } from "components/Forms";

const StyledSelect = styled(ReactSelect)(
    compose(
        typography,
        layout,
        space
    )
);

const IndicatorSeparator = () => {
    return <></>;
};

//eslint-disable-next-line
const DropdownIndicator = ({ isDisabled, children, ...rest }) => {
    if (isDisabled) return <></>;
    return <components.DropdownIndicator {...rest}>{children}</components.DropdownIndicator>;
};

const Select = ({ styles, components, openMenuOnFocus = true, isError, showBoxShadow, label, ...rest }) => {
    const customStyles = {
        control: (styles, { isFocused }) => ({
            ...styles,
            cursor: "pointer",
            minHeight: "50px",
            backgroundColor: "white",
            borderRadius: "5px",
            paddingLeft: "10px",
            boxShadow: showBoxShadow && theme.portalShadows.card2,
            borderColor: isError ? theme.colours.flat.primaryRed.hex : hexToRgba(theme.colours.blackTints600.hex, 0.24),
            ":hover": {
                ...styles[":hover"],
                borderColor: isFocused && hexToRgba(theme.colours.blackTints600.hex, 0.24)
            }
        }),
        option: (styles, { isDisabled, isFocused }) => ({
            ...styles,
            height: "50px",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            borderBottom: `solid 1px ${hexToRgba(theme.colours.blackTints600.hex, 0.24)}`,
            backgroundColor: isFocused ? hexToRgba(theme.colours.blackTints600.hex, 0.04) : "transparent",
            color: theme.colours.blackTints700.hex,
            ":active": {
                ...styles[":active"],
                backgroundColor: !isDisabled && hexToRgba(theme.colours.blackTints600.hex, 0.24)
            }
        }),
        input: styles => ({
            ...styles,
            color: isError ? theme.colours.flat.primaryRed.hex : theme.colours.blackTints700.hex
        }),
        singleValue: styles => ({
            ...styles,
            color: isError ? theme.colours.flat.primaryRed.hex : theme.colours.blackTints700.hex
        }),
        placeholder: styles => ({ ...styles, color: theme.colours.blackTints300.hex }),
        clearIndicator: styles => ({ ...styles }),
        container: styles => ({ ...styles }),
        dropdownIndicator: styles => ({ ...styles }),
        group: styles => ({ ...styles }),
        groupHeading: styles => ({ ...styles }),
        indicatorsContainer: styles => ({ ...styles }),
        indicatorSeparator: styles => ({ ...styles }),
        loadingIndicator: styles => ({ ...styles }),
        loadingMessage: styles => ({ ...styles }),
        menu: styles => ({ ...styles, borderRadius: "5px" }),
        menuList: styles => ({ ...styles, padding: 0 }),
        menuPortal: styles => ({ ...styles }),
        multiValue: styles => ({ ...styles }),
        multiValueLabel: styles => ({ ...styles }),
        multiValueRemove: styles => ({ ...styles }),
        noOptionsMessage: styles => ({ ...styles }),
        valueContainer: styles => ({ ...styles }),
        ...styles
    };

    return (
        <Box width="100%" textAlign="left" data-testid={rest["data-testid"] || "select"}>
            {label && (
                <Text fontSize="15px" fontWeight="semiBold" textAlign="left" mb="8px">
                    {label}
                </Text>
            )}
            <StyledSelect
                maxMenuHeight={250}
                openMenuOnFocus={openMenuOnFocus}
                components={{ IndicatorSeparator, DropdownIndicator, ...components }}
                styles={customStyles}
                {...rest}
            />
            <ErrorNotification boolExp={!!isError}>{isError}</ErrorNotification>
        </Box>
    );
};

Select.propTypes = {
    ...StyledSelect.propTypes,
    isError: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export { components };
export default Select;
