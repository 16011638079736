import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import enGbFlag from "flag-icons/flags/1x1/gb.svg";
import skFlag from "flag-icons/flags/1x1/sk.svg";
import ukFlag from "flag-icons/flags/1x1/ua.svg";

const supportedLangs = {
    "en-GB": {
        flag: enGbFlag,
        localName: "English"
    },
    sk: {
        flag: skFlag,
        localName: "Slovenčina"
    },
    uk: {
        flag: ukFlag,
        localName: "Українська"
    }
};

const i18nInit = i18n
    .use(LanguageDetector)
    .use(HttpApi)
    .use(initReactI18next)
    .init({
        debug: process.env.REACT_APP_I18N_DEBUG,
        fallbackLng: "en-GB",
        load: "currentOnly",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: process.env.REACT_APP_I18N_CDN_URL
        },
        detection: {
            convertDetectedLanguage: lng => {
                // Default to English if detected language is not supported
                if (supportedLangs[lng] === undefined) {
                    return "en-GB";
                }
                return lng;
            }
        }
    });

export default i18nInit;
export { supportedLangs };
