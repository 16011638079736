import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class ReicheldBainCo {
    /**
     * Constructor
     */
    constructor(netPromoterScore) {
        this._validate(netPromoterScore);
        this._netPromoterScore = netPromoterScore;
    }

    /**
     * Returns the given Net Promoter Score value
     * @returns {Number}
     */
    get() {
        return this._netPromoterScore;
    }

    /**
     * Validates the given Net Promoter Score value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validate(netPromoterScore) {
        if (
            !(typeof netPromoterScore === "number") ||
            Number.isNaN(netPromoterScore) ||
            netPromoterScore < 0 ||
            netPromoterScore > 10
        ) {
            throw new InvalidArgumentError("Net Promoter Score must be a valid number between 0 and 10");
        }

        return true;
    }

    /**
     * Returns if the given Net Promoter Score is in the 'promoter' range
     *
     * @returns {boolean}
     */
    isPromoter() {
        return this._netPromoterScore >= 9;
    }

    /**
     * Returns if the given Net Promoter Score is in the 'passive' range
     *
     * @returns {boolean}
     */
    isPassive() {
        return this._netPromoterScore >= 7 && this._netPromoterScore <= 8;
    }

    /**
     * Returns if the given Net Promoter Score is in the 'elevated' range
     *
     * @returns {boolean}
     */
    isDetractor() {
        return this._netPromoterScore >= 0 && this._netPromoterScore <= 6;
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isPromoter():
                info = "Promoter";
                break;
            case this.isPassive():
                info = "Passive";
                break;
            case this.isDetractor():
                info = "Detractor";
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isPromoter():
                info = "promoter";
                break;
            case this.isPassive():
                info = "passive";
                break;
            case this.isDetractor():
                info = "detractor";
                break;
        }

        return info;
    }

    /**
     * Returns the numeric value for this NPS' rating
     * @return {Number}
     */
    get scoreContribution() {
        let value = null;
        switch (true) {
            case this.isPromoter():
                value = 1;
                break;
            case this.isPassive():
                value = 0;
                break;
            case this.isDetractor():
                value = -1;
                break;
        }

        return value;
    }
}
