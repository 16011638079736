import styled from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";

export const RulesHeader = styled.div`
    font-size: ${props => props.theme.fontSize[20].px};
    ${props => {
        return mediaQueryForCss({
            "font-size": [props.theme.fontSize[20].px, props.theme.fontSize[15].px],
            "font-weight": ["normal", props.theme.fontWeight.semiBold],
            margin: ["0 40.5px 4px 0", "0 0 16px 0"]
        });
    }}
`;

export const Rule = styled.div`
    flex: 0 50%;
    display: flex;
    align-items: center;
    ${mediaQueryForCss({
        margin: ["0 0 0 7px", "0"]
    })}
`;

export const RuleText = styled.div`
    margin-left: 10px;
    font-size: ${props => props.theme.fontSize[15].px};
    ${props => {
        return mediaQueryForCss({
            "font-size": [props.theme.fontSize[15].px, props.theme.fontSize[11].px],
            "margin-left": ["10px", "8px"]
        });
    }}
`;

export const RulesContainer = styled.div`
    flex-flow: wrap;
    ${mediaQueryForCss({
        display: ["block", "flex"]
    })}
`;

export const RuleImage = styled.img`
    ${mediaQueryForCss({
        width: ["24px", "20px"]
    })}
`;
