import React, { useEffect, useState } from "react";

const withDimensions = Component => {
    const NewComponent = props => {
        const [dimension, setDimensions] = useState([0, 0]);

        useEffect(() => {
            function setDim() {
                setDimensions([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener("resize", setDim);
            setDim();
            return () => window.removeEventListener("resize", setDim);
        }, []);

        return <Component dimension={dimension} {...props} />;
    };
    return NewComponent;
};

export default withDimensions;
