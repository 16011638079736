import { SET_HEALTH_CHECKS } from "constants/actions";

const initialState = {
    healthChecks: []
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_HEALTH_CHECKS: {
            return {
                ...state,
                healthChecks: payload
            };
        }
        default: {
            return state;
        }
    }
};
