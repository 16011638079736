import React, { Component } from "react";
import styled, { ThemeProvider } from "styled-components";
import { Icon, theme, Heading } from "@sisuwellness/web-components";
import { SecondaryButton } from "@sisuwellness/web-components/components/Buttons";
import BaseMemberViewWithoutMenu from "components/BaseMemberViewWithoutMenu";
import PropTypes from "prop-types";
import { Box } from "rebass";
import i18n from "../i18n";

const Rollbar = window.Rollbar;

const StyledContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    flex-direction: column;
    color: ${props => props.theme.colours.flat.darkGray.hex};
`;

// cannot use react-router-dom elements outside of router hence simply use anchor
const StyledLink = styled.a`
    font-size: ${props => props.theme.text.heading5.sizeRem};
    outline: none;
`;

/**
 * @see https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends Component {
    static propTypes = {
        children: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            t: (key, fallback) => fallback
        };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        Rollbar.error(error, info);
    }

    componentDidMount() {
        // Load translations if possible
        // Do nothing and use the fallback on error since we're already in the ErrorBoundary
        i18n.then(t => {
            this.setState({ t });
        }).catch(() => {});
    }

    render() {
        const { hasError, t } = this.state;
        const { children } = this.props;
        const { icons = {}, colours = {} } = theme;

        if (hasError) {
            return (
                <ThemeProvider theme={theme}>
                    <BaseMemberViewWithoutMenu>
                        <StyledContent>
                            <Box mb="1em">
                                <Icon
                                    size={icons.sizes.question}
                                    type={icons.types.unavailable}
                                    colour={colours.flat.darkGray.hex}
                                />
                            </Box>
                            <Heading renderAs={"h2"} mb="1em">
                                {t("test", "Sorry, something went wrong.")}
                            </Heading>
                            <StyledLink href={"/"}>
                                <SecondaryButton borderRadius="5px">
                                    <Heading renderAs="h6">{t("return_home", "RETURN HOME")}</Heading>
                                </SecondaryButton>
                            </StyledLink>
                        </StyledContent>
                    </BaseMemberViewWithoutMenu>
                </ThemeProvider>
            );
        } else {
            return children;
        }
    }
}

export default ErrorBoundary;
