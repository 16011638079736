import React from "react";

// ----------------------------------------------------------------

import FlexWithGap from "components/Flex";
import BCompBreakdown from "./BcompBreakdown";

// ----------------------------------------------------------------

const UnlockedBodyComposition = () => (
    <FlexWithGap fd="column" gap="32px">
        <BCompBreakdown />
    </FlexWithGap>
);

export default UnlockedBodyComposition;
