import { Guidelines } from "../index";

export default class CampaignAd {
    /**
     * @param {object} campaignAd
     */
    constructor(campaignAd = null) {
        if (campaignAd) {
            this.initialise(campaignAd);
        }
    }

    initialise(campaignAd) {
        // store the original POJO
        this._campaignAd = campaignAd;

        this._criteria = campaignAd.criteria;
        this._schema = campaignAd.schema;
        this._priority = campaignAd.priority;

        if (campaignAd.assets) {
            this._assets = campaignAd.assets;
        }
    }

    isTypeRecommendation() {
        return this._schema.type === "recommendation";
    }

    isTypeLogo() {
        return this._schema.type === "logo";
    }

    isTypeVideo() {
        return this._schema.type === "video";
    }

    isTypeImage() {
        return this._schema.type === "image";
    }

    isTypeHybrid() {
        return this._schema.type === "hybrid";
    }

    get priority() {
        return this._priority;
    }

    get recommendation() {
        if (!this.isTypeRecommendation()) {
            throw new Error("This CampaignAd is not a recommendation");
        }

        return {
            title: this.title,
            risk: this._criteria.risk,
            guideline: this._criteria.guideline,
            rating: this._criteria.rating,
            text: this.recommendationText,
            textPortal: this.recommendationTextPortal,
            url: this.recommendationUrl,
            priority: this.priority
        };
    }

    get title() {
        let title = "";
        switch (this._criteria.risk) {
            case "BODY_MASS_INDEX":
                title = "BMI recommendation";
                break;

            case "BLOOD_PRESSURE":
                title = "Blood pressure recommendation";
                break;

            case "HEART_RATE":
                title = "Heart rate recommendation";
                break;

            case "BODY_FAT":
                title = "Body fat recommendation";
                break;

            case "DIABETES":
                title = "Diabetes recommendation";
                break;

            case "SMOKING":
                title = "Smoking recommendation";
                break;

            case "WAIST_CIRCUMFERENCE":
                title = "Waist circumference recommendation";
                break;

            case "TOTAL_CHOLESTEROL":
                title = "Total Cholesterol recommendation";
                break;

            case "FASTING_GLUCOSE":
                title = "Fasting Glucose recommendation";
                break;

            case "NON_FASTING_GLUCOSE":
                title = "Non-Fasting Glucose recommendation";
                break;

            case "HDL_CHOLESTEROL":
                title = "HDL Cholesterol recommendation";
                break;

            case "QRISK3":
                title = "Heart & Stroke Risk recommendation";
                break;

            case "ALCOHOL_RISK":
                title = "Alcohol recommendation";
                break;

            case "ACTIVITY":
                title = "Activity recommendation";
                break;

            case Guidelines.PSS4.RiskCode:
                title = "Perceived Stress recommendation";
                break;
        }

        return title;
    }

    get recommendationText() {
        if (!this.isTypeRecommendation()) {
            throw new Error("This CampaignAd is not a recommendation");
        }

        return this._schema.components.recommendationText != null
            ? this._schema.components.recommendationText.value
            : "";
    }

    get recommendationTextPortal() {
        if (!this.isTypeRecommendation()) {
            throw new Error("This CampaignAd is not a recommendation");
        }

        return this._schema.components.recommendationTextPortal != null
            ? this._schema.components.recommendationTextPortal.value
            : "";
    }

    get recommendationUrl() {
        if (!this.isTypeRecommendation()) {
            throw new Error("This CampaignAd is not a recommendation");
        }

        return this._schema.components.url != null ? this._schema.components.url.value : "";
    }

    get logoUrl() {
        if (!this.isTypeLogo()) {
            throw new Error("This CampaignAd is not a logo");
        }

        const value = this.getValueForComponent("logo");

        let url = null;
        if (value) {
            url = this._assets[value].path;
        }

        return url;
    }

    get imageUrl() {
        if (!this.isTypeImage() && !this.isTypeHybrid()) {
            throw new Error("This CampaignAd is not an image");
        }

        const value = this.getValueForComponent("image");

        let url = null;
        if (value) {
            url = this._assets[value].path;
        }

        return url;
    }

    get videoUrl() {
        if (!this.isTypeVideo() && !this.isTypeHybrid()) {
            throw new Error("This CampaignAd is not a video");
        }

        const value = this.getValueForComponent("video");

        let url = null;
        if (value) {
            url = this._assets[value].path;
        }

        return url;
    }

    getValueForComponent(component) {
        return this._schema.components[component].value;
    }

    mapComponentsToAssetIds() {
        const map = [];

        for (let componentKey in this._schema.components) {
            const component = this._schema.components[componentKey];

            if (component.type === "asset" && component.value) {
                map.push({
                    component: componentKey,
                    assetId: component.value
                });
            }
        }

        return map;
    }

    get campaignAdPOJO() {
        return this._campaignAd;
    }
}
