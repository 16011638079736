import { Box } from "rebass";
import styled from "styled-components";
import { typography } from "styled-system";

export const InputFieldTheme = (theme, length) => {
    return {
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            padding: "0px 10px",
            background: "transparent"
        },
        containerOpen: {},
        input: {
            width: "100%",
            height: "100%",
            border: 0,
            outline: "none",
            background: "transparent"
        },
        inputOpen: {},
        inputFocused: {},
        suggestionsContainer: {
            width: "101%",
            height: `${50 * length}px`,
            maxHeight: "400px",
            background: "white",
            overflow: `hidden ${50 * length > 400 ? "auto" : "hidden"}`,
            position: "absolute",
            padding: "0",
            left: "-2px",
            top: "102%",
            zIndex: "1"
        },
        suggestionsContainerOpen: {},
        suggestionsList: {
            padding: 0,
            listStyleType: "none"
        },
        suggestion: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "49px",
            cursor: "pointer",
            userSelect: "none"
        },
        suggestionFirst: {},
        suggestionHighlighted: {},
        sectionContainer: {},
        sectionContainerFirst: {},
        sectionTitle: {}
    };
};

export const InputContainer = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    max-height: 50px;
    padding: 10px 10px;
    border-radius: 5px;
    position: relative;
    background-color: white;
    box-sizing: border-box;
    justify-content: flex-start;
    color: ${props => (props.error ? "#ff647c" : "")};
    box-shadow: ${props => props.error && "0 8px 8px 0 rgba(255, 100, 124, 0.08)"};
    border: 1px solid ${props => (props.error ? "#ff647c" : "rgba(102, 102, 102, 0.24)")};

    input {
        font-size: 13px;
        font-weight: 300;
        color: ${props => (props.error ? "#ff647c" : props.theme.colours.blackTints700.hex)};

        ::placeholder {
            color: #b3b3b3;
        }
    }

    &::before {
        left: 0;
        top: -26px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        position: absolute;
        content: attr(label);
        color: ${({ theme }) => theme.colours.blackTints700.hex};
    }

    ${props => props.customStyles}
`;

export const LeftChild = styled.div`
    width: fit-content;
    height: 30px;
`;

export const RightChild = styled.div`
    width: fit-content;
    height: 30px;
`;

export const ErrorText = styled.div`
    text-align: left;
    font-size: 11px;
    line-height: 1.18;
    color: #ff647c;
    margin-top: 19px;
`;

export const Container = styled(Box)`
    ${typography}
`;
