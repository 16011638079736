import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import APIClient from "utilities/APIClient";
import Select from "react-select";
import { Errors, Info, TextField, DateOfBirth, RadioButton } from "@sisuwellness/web-components/components/Forms";
import { Paragraph } from "@sisuwellness/web-components";
import PrimaryButton from "@sisuwellness/web-components/components/Buttons/primary";
import UserProfileUtility from "@sisuwellness/utilities/User/UserProfile";
import { compose } from "ramda";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { setCitizen } from "@sisuwellness/connected-components/actions/auth";
import { NO_NETWORK_ERROR_MESSAGE, REQUEST_FAILED_ERROR_MESSAGE } from "constants/messages";
import { OTHER_COUNTRY_CODE } from "@sisuwellness/utilities/constants";
import { Formik, Form } from "formik";
import { Box } from "rebass";
import SettingsLayout from "components/SettingsLayout";
import { useStateObject } from "@sisuwellness/utilities/hooks";

import { validateSchema } from "@sisuwellness/utilities/Validation/UserProfileValidation";
import { useTranslation } from "react-i18next";
import FormLocalisationWrapper from "utilities/FormLocalisationWrapper";

const StyledSelect = styled(Select)`
    margin-top: 0.5rem;
    margin-bottom: 20px;
`;

const RequiredStar = styled.span`
    color: red;
    &:before {
        content: "*";
    }
`;

const UserProfile = ({ citizen, setCitizen, onUserProfileCompleted, theme }) => {
    const { t } = useTranslation();

    const [state, setState] = useStateObject({
        errorMessage: null,
        infoMessage: null
    });

    const defaultCountryCode = citizen.userProfile?.countryCode;
    const [countryCode, setCountryCode] = useState(defaultCountryCode);
    const { errorMessage, infoMessage } = state;

    const availableCountries = citizen.availableCountries || [];
    const availableCountriesOption = availableCountries.map(country => {
        return { value: country.alpha2, label: country.name };
    });

    const isPostcodeOptional = () => countryCode === OTHER_COUNTRY_CODE;

    async function handleSubmit(formValues, { setSubmitting }) {
        const userProfileValues = {
            email: formValues.email,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            dateOfBirth: formValues.dateOfBirth,
            gender: formValues.gender,
            countryCode: formValues.countryCode,
            postalCode: formValues.postalCode,
            mobileNumber: formValues.mobileNumber
        };

        setSubmitting(true);

        setState({
            errorMessage: null,
            infoMessage: null
        });

        const availableCountries = citizen.availableCountries.map(country => country.alpha2);
        const userProfileUtil = new UserProfileUtility(userProfileValues, { availableCountries, isPostcodeOptional });
        const errors = userProfileUtil.validate(userProfileValues);

        if (errors.length) {
            window.scrollTo(0, 0);

            setState({
                errorMessage: errors
            });
            setSubmitting(false);
            return;
        }

        try {
            const response = await APIClient.auth.updateUserProfile(userProfileValues);

            if (response.succeeded()) {
                setState({
                    infoMessage: t("members_portal_web.components.notifications.update_success")
                });
                window.scrollTo(0, 0);

                if (typeof onUserProfileCompleted == "function") {
                    onUserProfileCompleted();
                }

                setCitizen({
                    ...citizen,
                    userProfile: {
                        ...userProfileValues
                    }
                });
            } else {
                window.scrollTo(0, 0);
                let errorMessage = null;

                if (response.isNetworkFailure()) {
                    errorMessage = t(NO_NETWORK_ERROR_MESSAGE);
                } else if (response.isBadRequest()) {
                    const responseBody = await response.body();
                    errorMessage = t("members_portal_web.components.notifications.invalid_fields");
                    if (responseBody.length && responseBody[0].value) {
                        errorMessage = responseBody[0].value;
                    }
                } else {
                    errorMessage = t(REQUEST_FAILED_ERROR_MESSAGE);
                }

                setState({ errorMessage });
            }
        } catch (error) {
            setState({
                errorMessage: error.message
            });
        }

        setSubmitting(false);
    }

    return (
        <Formik
            validationSchema={validateSchema({ isPostcodeOptional, countryCode })}
            initialValues={citizen.userProfile}
            onSubmit={handleSubmit}
        >
            {({ isSubmitting, values, handleChange, setFieldValue, errors, handleBlur, touched, setTouched }) => (
                <Form>
                    <FormLocalisationWrapper touched={touched} setTouched={setTouched}>
                        <SettingsLayout
                            topContent={
                                <>
                                    {errorMessage && <Errors>{errorMessage}</Errors>}
                                    {infoMessage && <Info>{infoMessage}</Info>}{" "}
                                </>
                            }
                            leftColumn={
                                <>
                                    <Paragraph>
                                        {t("members_portal_web.components.user_profile.fields.first_name")}{" "}
                                        <RequiredStar />{" "}
                                    </Paragraph>
                                    <TextField
                                        name="firstName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.firstName}
                                        errors={errors.firstName}
                                    />
                                    <Paragraph mt="0.5em">
                                        {t("members_portal_web.components.user_profile.fields.last_name")}{" "}
                                        <RequiredStar />
                                    </Paragraph>
                                    <TextField
                                        name="lastName"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.lastName}
                                        errors={errors.lastName}
                                    />
                                    <Paragraph mt="0.5em">
                                        {t("members_portal_web.components.user_profile.fields.email")} <RequiredStar />
                                    </Paragraph>
                                    <TextField
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        errors={errors.email}
                                    />
                                    <Paragraph mt="0.5em">
                                        {t("members_portal_web.components.user_profile.fields.date_of_birth")}{" "}
                                        <RequiredStar />
                                    </Paragraph>
                                    <DateOfBirth
                                        onChange={value => setFieldValue("dateOfBirth", value)}
                                        onBlur={handleBlur}
                                        errors={errors.dateOfBirth}
                                        value={values.dateOfBirth}
                                    />
                                    <Paragraph mt="0.5em">
                                        {t("members_portal_web.components.user_profile.fields.gender")} <RequiredStar />
                                    </Paragraph>
                                    <Box mt="0.5em" mb="1.5em">
                                        <RadioButton
                                            onChange={value => setFieldValue("gender", value)}
                                            options={[
                                                {
                                                    label: t("words.male"),
                                                    value: "Male"
                                                },
                                                {
                                                    label: t("words.female"),
                                                    value: "Female"
                                                }
                                            ]}
                                            errors={errors.gender}
                                            value={values.gender}
                                            defaultValue={values.gender}
                                        />
                                    </Box>
                                    <label htmlFor="countryCode">
                                        {t("members_portal_web.components.user_profile.fields.country")}{" "}
                                        <RequiredStar />
                                    </label>
                                    <StyledSelect
                                        aata-testi={"country-code"}
                                        name={"countryCode"}
                                        inputId={"countryCode"}
                                        isSearchable={true}
                                        options={availableCountriesOption}
                                        placeholder={t(
                                            "members_portal_web.components.user_profile.fields.country_placeholder"
                                        )}
                                        defaultValue={values.countryCode}
                                        value={availableCountriesOption.find(
                                            options => options.value === values.countryCode
                                        )}
                                        onBlur={handleBlur}
                                        onChange={option => {
                                            setFieldValue("countryCode", option.value);
                                            setCountryCode(option.value);
                                        }}
                                    />
                                    {!isPostcodeOptional() && (
                                        <>
                                            <Paragraph mt="0.5em">
                                                {t("members_portal_web.components.user_profile.fields.postal_code")}{" "}
                                                <RequiredStar />
                                            </Paragraph>
                                            <TextField
                                                name="postalCode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.postalCode}
                                                errors={errors.postalCode}
                                                disabled={false}
                                            />
                                        </>
                                    )}
                                    <Paragraph mt="0.5em">
                                        {t("members_portal_web.components.user_profile.fields.mobile_number")}
                                    </Paragraph>
                                    <TextField
                                        name="mobileNumber"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.mobileNumber}
                                        errors={errors.mobileNumber}
                                    />
                                    <Box>
                                        ( <RequiredStar />{" "}
                                        {t("members_portal_web.components.user_profile.fields.required_fields")})
                                    </Box>
                                </>
                            }
                            rightColumn={
                                <Box width={["80%", 1]} mx="auto">
                                    <PrimaryButton
                                        fullWidth
                                        label={t("words.save")}
                                        type={"submit"}
                                        disabled={isSubmitting}
                                        borderRadius={"5px"}
                                    />
                                </Box>
                            }
                            icon={theme.icons.types.profile}
                        />
                    </FormLocalisationWrapper>
                </Form>
            )}
        </Formik>
    );
};

UserProfile.propTypes = {
    theme: PropTypes.object.isRequired,
    onUserProfileCompleted: PropTypes.func,
    citizen: PropTypes.object.isRequired,
    setCitizen: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth: { citizen } = {} } = {}) => ({
    citizen
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setCitizen
        },
        dispatch
    );

export default compose(
    withTheme,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(UserProfile);
