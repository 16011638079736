export default class BaseRule {
    constructor(options = {}) {
        this._rules = [];
        this._options = Object.assign({ noRulesResult: false }, options);
    }

    /* istanbul ignore next */
    matches() {
        throw new Error("passes() must be implemented");
    }
}
