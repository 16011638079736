import React, { Component } from "react";

import DropDownMenu from "./AuthenticatedHeader/DropDownMenu";
import Hamburger from "./LeftNavBar/Hamburger";
import { MediaQuery } from "@sisuwellness/web-components";
import PageContainer from "./BaseMemberViewWithMenu";
import PropTypes from "prop-types";
import ThinHeader from "./ThinHeader";
import styled from "styled-components";
import { PORTAL_IMAGE } from "constants/imagePath";

const StyledPageContainerInner = styled.div`
    font-size: 0.7em;
    width: 90%;
    padding: 5%;
`;
export const HeaderContainer = styled.div`
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
`;
export const ItemsContainer = styled.div`
    margin-top: 23px;
    display: flex;
`;
export const LogoContainer = styled.div`
    margin-left: 40px;
`;
export const Card = styled.div`
    width: 80px;
    height: 34px;
    box-shadow: 0 4px 8px 0 rgba(50, 50, 71, 0.06), 0 4px 4px 0 rgba(50, 50, 71, 0.08);
    background-color: #eef3f6;
    border-radius: 50px 10px 10px 50px;
    padding: 4px;
    margin-left: auto;
`;

export const DropDownConatiner = styled.div`
    margin-right: 40px;
`;
export default class BaseMemberUserJourneyView extends Component {
    static displayName = "BaseMemberUserJourneyView";
    static propTypes = {
        children: PropTypes.any.isRequired,
        userJourney: PropTypes.object.isRequired,
        onSaveAndExit: PropTypes.func.isRequired
    };
    render() {
        return (
            <>
                <PageContainer id={"page-container"}>
                    <StyledPageContainerInner>{this.props.children}</StyledPageContainerInner>
                </PageContainer>
                <ThinHeader>
                    <MediaQuery devices={["desktop"]}>
                        <HeaderContainer>
                            <ItemsContainer>
                                <LogoContainer>
                                    <img
                                        data-testid="header-logo-without-menu"
                                        src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`}
                                    />
                                </LogoContainer>
                            </ItemsContainer>
                            <ItemsContainer>
                                <DropDownConatiner>
                                    <DropDownMenu />
                                </DropDownConatiner>
                            </ItemsContainer>
                        </HeaderContainer>
                    </MediaQuery>
                    <MediaQuery devices={["mobile", "tablet"]}>
                        <Hamburger pageWrapId={"page-container"} />
                    </MediaQuery>
                </ThinHeader>
            </>
        );
    }
}
