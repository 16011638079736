import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CoreUtils from "@sisuwellness/utilities/core/coreUtils";

const Root = styled.div`
    min-width: 120px;
    min-height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;

    border: 1px solid ${props => props.theme.colours.flat.blue.hex};
    border-radius: 7px;
    overflow: hidden;

    :disabled {
        background-color: ${props => props.theme.colours.flat.gray.hex};
    }

    user-select: none;
`;

const Button = styled.div`
    flex: 0 0 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colours.flat.blue.hex};
    color: ${props => props.theme.colours.flat.white.hex};
    font-size: 3em;

    :active {
        background: ${props => props.theme.colours.flat.darkBlue.hex};
    }
`;

const Value = styled.div`
    flex: 1 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${props => props.theme.colours.flat.white.hex};
    color: ${props => props.theme.colours.flat.darkGray.hex};
    font-size: 2.2em;
`;

export default class QuantityButton extends Component {
    static propTypes = {
        className: PropTypes.string,
        onValueChanged: PropTypes.func,
        maxValue: PropTypes.number,
        minValue: PropTypes.number,
        defaultValue: PropTypes.number
    };

    static defaultProps = {
        maxValue: 50,
        minValue: 0,
        defaultValue: 0
    };

    constructor(props) {
        super(props);

        this.state = {
            value: CoreUtils.limit(this.props.defaultValue, [this.props.minValue, this.props.maxValue])
        };
    }

    onValueChanged(type) {
        let { value } = this.state;
        type === "+" ? value++ : value--;
        value = CoreUtils.limit(value, [this.props.minValue, this.props.maxValue]);
        this.setState({ value });

        if (this.props.onValueChanged == null) {
            return;
        }

        this.props.onValueChanged(value, type);
    }

    render() {
        return (
            <Root className={`one-little-quantity-button-${this.props.className}`} data-testid={"quantity-button"}>
                <Button key={"me-minus"} onClick={() => this.onValueChanged("-")}>
                    -
                </Button>
                <Value>{this.state.value}</Value>
                <Button key={"me-plus"} onClick={() => this.onValueChanged("+")}>
                    +
                </Button>
            </Root>
        );
    }
}
