import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled.div`
    border-radius: ${props => props.theme.defaults.borderRadius};
    border: 1px solid ${props => props.theme.colours.flat.darkGray.hex};
`;

const StyledButton = styled.button`
    width: 50%;
    border: 0;
    outline: none;
    border-radius: ${props => {
        let radius;
        if (props.first) {
            radius = props.theme.defaults.borderRadius + " 0 0 " + props.theme.defaults.borderRadius;
        } else {
            radius = "0 " + props.theme.defaults.borderRadius + " " + props.theme.defaults.borderRadius + " 0";
        }

        return radius;
    }};
    background: ${props =>
        props.selected ? props.theme.colours.flat.darkGray.hex : props.theme.colours.flat.white.hex};
    color: ${props => (props.selected ? props.theme.colours.flat.white.hex : props.theme.colours.flat.darkGray.hex)};
    height: ${props => props.height || "3em"};
    font-size: ${props => props.theme.text.heading6.sizeRem};
    padding: 0 22px;
`;

const Switch = ({ first, second, onToggle, defaultValue, height }) => {
    const [selected, setSelected] = useState(defaultValue || first);

    const handleToggle = e => {
        e.preventDefault();
        setSelected(e.target.value);
        onToggle(e.target.value);
    };

    return (
        <StyledContainer>
            <StyledButton
                value={first}
                first={true}
                onClick={e => handleToggle(e)}
                selected={first.toLowerCase() === selected.toLowerCase()}
                height={height}
            >
                {first}
            </StyledButton>
            <StyledButton
                value={second}
                first={false}
                onClick={e => handleToggle(e)}
                selected={second.toLowerCase() === selected.toLowerCase()}
                height={height}
            >
                {second}
            </StyledButton>
        </StyledContainer>
    );
};

Switch.propTypes = {
    first: PropTypes.string.isRequired,
    second: PropTypes.string.isRequired,
    onToggle: PropTypes.func,
    defaultValue: PropTypes.string,
    height: PropTypes.string,
    fontSize: PropTypes.string
};

export default Switch;
