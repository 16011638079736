import React, { useContext } from "react";
import PropTypes from "prop-types";

// ------------------------------------------------------------

import { Flex, Box, Text } from "rebass";
import styled, { ThemeContext, createGlobalStyle } from "styled-components";

// ------------------------------------------------------------

import Popup from "components/PortalModal/popup";
import { PORTAL_IMAGE } from "constants/imagePath";
import { StyledButtonCross } from "components/PortalModal/styled";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------

const StyledText = styled(Text)`
    color: ${props => props.theme.colours.blackTints700.hex};
`;

const HideScrollbarElement = createGlobalStyle`
   ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    @media print{@page {size: landscape}}
`;

const CustomBox = styled(Box)`
    overflow-y: auto;
    & > * + * {
        margin-top: 16px;
    }
`;

// ------------------------------------------------------------

const HealthInfoPopup = ({ closeModal, modalIsOpen }) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);

    return (
        <>
            <HideScrollbarElement />
            <Popup closeModal={closeModal} modalIsOpen={modalIsOpen} hideHeader>
                <Box data-testid="range-popup">
                    <Flex width="100%" justifyContent="space-between" mb="20px">
                        <Text color={theme.colours.hpPrimaryPurple.hex} fontSize={["22px", "28px"]}>
                            {t("members_portal_web.views.health_risk_assessment.popup.specific_info")}
                        </Text>
                        <StyledButtonCross
                            onClick={closeModal}
                            src={`${PORTAL_IMAGE}/menu-close.svg`}
                            alt={"cross"}
                            data-testid="close-modal"
                        />
                    </Flex>
                    <CustomBox height={["100%", "150px"]}>
                        <StyledText
                            minWidth={["70px", "85px"]}
                            fontWeight={["normal", "300"]}
                            fontSize={["13px", "18px"]}
                        >
                            {t("members_portal_web.views.health_risk_assessment.popup.measurements")}
                        </StyledText>
                        <StyledText
                            minWidth={["70px", "85px"]}
                            fontWeight={["normal", "300"]}
                            fontSize={["13px", "18px"]}
                        >
                            {t("members_portal_web.views.health_risk_assessment.popup.no_access")}
                        </StyledText>
                    </CustomBox>
                </Box>
            </Popup>
        </>
    );
};

HealthInfoPopup.propTypes = {
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func
};

export default HealthInfoPopup;
