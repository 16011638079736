import BaseEndPoint from "../BaseEndPoint";

export default class Medication extends BaseEndPoint {
    constructor(client) {
        super(client);
    }

    get = (url, queryStringParameters = null) => {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString(queryStringParameters);
        return this._send(this.getUrlPath(url) + queryString, settings);
    };

    /**
     * @return {Promise} popular categories
     */
    category = () => {
        return this.get("/medication/category");
    };

    /**
     * @param {string} query search query
     * @return {Promise} suggestions for the searched key
     */
    searchQuery = query => {
        return this.get("/medication/search", { q: query });
    };

    /**
     * @param {string} value search key
     * @param {string} type type of med search (By category or brandName or drugName)
     * @return {Promise} search results for the searched key
     */
    searchResults = (value, type) => {
        return this.get("/medication/medications", { value, type });
    };

    /**
     * @param {string} drugName drug name of the searched product
     * @param {string} brandName brand name of the searched product
     * @param {string} brandType brand type of the search product ( generic or original )
     * @return {Promise} medication details for the searched product
     */
    medicationDetails = (drugName, brandName, brandType) => {
        return this.get("/medication/medication-details", { drugName, brandName, brandType });
    };
}
