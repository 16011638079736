import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";
import { withTranslation } from "react-i18next";

class LifestyleIndex extends Component {
    render() {
        const { t, theme, date, score: scoreProp, ...rest } = this.props;
        let guideline = null;
        let guidelineStyle = null;
        const score = scoreProp ? scoreProp.toFixed() : null;

        if (score) {
            guideline = new Guidelines.HealthRiskAssessment.Guideline(scoreProp);
            guidelineStyle = theme.guidelines.SiSUHealthRiskAssessment[guideline.key];
        }

        return (
            <Attribute
                {...rest}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={t("web_components.cards.attributes.lifestyle_index.title")}
                date={date}
                primaryStatValue={score}
                primaryStatUnit={"%"}
                icon={theme.icons.types.lifestyle}
                t={t}
            />
        );
    }
}

LifestyleIndex.displayName = "LifestyleIndex Attribute";
LifestyleIndex.propTypes = {
    t: PropTypes.func,
    theme: PropTypes.any.isRequired,
    score: PropTypes.number,
    date: PropTypes.string
};

export default withTranslation()(withTheme(LifestyleIndex));
