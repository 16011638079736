import React from "react";

// ----------------------------------------------------------------------------

import MyAccountDetails from "./myAccount";
import PageLayout from "components/PageLayout";

// ----------------------------------------------------------------------------

const AccountView = () => {
    return (
        <PageLayout>
            <MyAccountDetails />
        </PageLayout>
    );
};

export default AccountView;
