import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { theme } from "../../theme";

const StyledSVG = styled.svg`
    /* Use the current text color as the icon’s fill color. */
    fill: ${props => props.colour};
    stroke: transparent;
    stroke-width: 0;
    /* Inherit the text’s size too. Also allows sizing the icon by changing its font-size. */
    width: ${props => (props.width ? props.width : props.size)};
    height: ${props => (props.height ? props.height : props.width ? "auto" : props.size)};
    /* The default vertical-align is \`baseline\`, which leaves a few pixels of space below the icon. Using \`center\` prevents this. For icons shown alongside text, you may want to use a more precise value, e.g. \`vertical-align: -4px\` or \`vertical-align: -0.15em\`. */
    vertical-align: middle;
    /* Paths and strokes that overflow the viewBox can show in IE11. */
    overflow: hidden;
    max-width: ${props => props.maxSize};
    max-height: ${props => props.maxSize};
`;

/**
 * Uses sizes and colours defined in the theme.
 *
 * @see https://fvsch.com/svg-icons/
 */
const Icon = ({ className, colour, size, width, height, maxSize, cdn, buildVersion, type }) => {
    if (type == null) {
        size = "2em";
    }

    return (
        <StyledSVG
            className={className}
            data-testid={`svg-icon-${type}`}
            viewBox="0 0 16 16"
            aria-hidden={true}
            focusable={false}
            colour={colour}
            size={size}
            width={width}
            height={height}
            maxSize={maxSize}
        >
            <use xlinkHref={`${cdn}/icons/icon-map.svg?v=${buildVersion}#${type}`} />
        </StyledSVG>
    );
};

Icon.propTypes = {
    theme: PropTypes.any.isRequired,
    type: PropTypes.string.isRequired,
    cdn: PropTypes.string.isRequired,
    colour: PropTypes.string,
    size: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    maxSize: PropTypes.string,
    className: PropTypes.string,
    buildVersion: PropTypes.string
};

Icon.defaultProps = {
    cdn: window.PUBLIC_URL || "",
    buildVersion: window.REACT_APP_BUILD_VERSION || "",
    colour: theme.colours.flat.black.hex,
    size: theme.icons.sizes.normal
};

export default React.memo(withTheme(Icon));
