import Response from "./Response";
import zlib from "pako";
import axios from "axios";

const NO_COMPRESS_HTTP_METHODS = ["HEAD", "GET", "DELETE"];
export default class BaseEndPoint {
    static buildVersionTag = "X-SISU-BUILD-VERSION";
    static releaseVersionTag = "X-SISU-RELEASE-VERSION";
    static originHeader = "X-SISU-ORIGIN";
    static clientTypeHeader = "X-SISU-CLIENT-TYPE";

    constructor(client) {
        this.client = client;
    }

    /**
     * Returns an object containing fetch() settings
     * @returns {{modes: "cors", headers: {"Content-Type": string,  Accept: "application/json"}}} Default request settings object
     */
    _getDefaultFetchSettings() {
        let headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
        };

        const { buildVersion, releaseVersion, disablePragma, origin, clientType, ...rest } = this.client.config;

        if (buildVersion) {
            headers[BaseEndPoint.buildVersionTag] = buildVersion;
        }

        if (releaseVersion) {
            headers[BaseEndPoint.releaseVersionTag] = releaseVersion;
        }

        if (disablePragma) {
            delete headers["Pragma"];
        }

        if (origin) {
            headers[BaseEndPoint.originHeader] = origin;
        }

        if (clientType) {
            headers[BaseEndPoint.clientTypeHeader] = clientType;
        }

        return {
            mode: "cors",
            headers,
            ...rest
        };
    }

    /**
     * Given an object of keys/values, generate a query string
     *
     * @param {Object<string, any>} params
     * @returns {string}
     */
    generateQueryString(params) {
        if (params && Object.keys(params).length) {
            const query = new URLSearchParams();

            for (let key in params) {
                const value = params[key];
                query.append(key, value);
            }

            return "?" + query.toString();
        } else {
            return "";
        }
    }

    /**
     * Returns the domain and full path to the resource endpoint
     * @param {string} path - URL path of resource endpoint
     */
    getUrlPath(path) {
        return this.client.config.domain + path;
    }

    /**
     * Returns a list of resources
     * @param {boolean} detailed
     * @param {Object} queryStringParameters
     * @return {Promise<Response>}
     */
    get(detailed = false, queryStringParameters = null) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString(queryStringParameters);
        return this._send(this.getUrlPath(detailed ? "detailed" : "") + queryString, settings);
    }

    /**
     * Returns the requested resource
     *
     * @param resourceId
     * @param detailed
     * @return {Promise<Response>}
     */
    getResource(resourceId, detailed = false) {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath(resourceId + (detailed ? "/detailed" : "")), settings);
    }

    /**
     * Creates a new resource
     *
     * @param {string} url
     * @param {Object<string, any>} data
     * @param {boolean} publicRoute
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    post(url, data, publicRoute = false, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "POST",
            data: JSON.stringify(data)
        });

        return this._send(this.getUrlPath(url), settings, publicRoute, compress);
    }

    /**
     * Creates a new resource
     *
     * @param {string} url
     * @param {Object<string, any>} data
     * @param {boolean} publicRoute
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    patch(url, data, publicRoute = false, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "PATCH",
            data: JSON.stringify(data)
        });

        return this._send(this.getUrlPath(url), settings, publicRoute, compress);
    }

    /**
     * Update a resource
     *
     * @param {string} url
     * @param {Object<string, any>} data
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    put(url, data, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "PUT",
            data: JSON.stringify(data)
        });

        return this._send(this.getUrlPath(url), settings, false, compress);
    }

    /**
     * Delete a resource
     *
     * @param {string} url
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     */
    delete(url, compress = true) {
        let settings = this._getDefaultFetchSettings();

        settings = Object.assign(settings, {
            method: "DELETE"
        });

        return this._send(this.getUrlPath(url), settings, false, compress);
    }

    /**
     *
     * @param {any} data
     * @returns {Promise}
     * @private
     */
    _compressData(data) {
        return new Promise(function(resolve, reject) {
            try {
                if (data) {
                    const buffer = zlib.deflate(data);
                    resolve(buffer);
                } else {
                    resolve();
                }
            } catch (error) {
                console.error("Failed to deflate with zlib", error);
                reject(error);
            }
        });
    }

    /**
     * Sends the request and returns a Response object
     *
     * @param {string} route
     * @param {Object<string, any>} settings
     * @param {boolean} publicRoute
     * @param {boolean} compress - Whether the request body should be compressed
     * @return {Promise<Response>}
     * @protected
     */
    async _send(route, settings = {}, publicRoute = false, compress = true) {
        if (!publicRoute) {
            settings.headers["Authorization"] = "Bearer " + this.client.authToken;
        }

        let processSettings = Promise.resolve(settings);
        settings = await this.translateRequest(processSettings, "en-GB");
        if (compress && !NO_COMPRESS_HTTP_METHODS.includes(settings.method)) {
            processSettings = this._compressData(settings.data)
                .then(compressedBody => {
                    settings.headers["Content-Encoding"] = "deflate";
                    settings.data = compressedBody;
                    return settings;
                })
                .catch(error => {
                    throw error;
                });
        }

        return processSettings.then(processedSettings =>
            // eslint-disable-next-line no-undef
            axios({
                ...processedSettings,
                url: route,
                validateStatus: status => typeof status == "number"
            })
                .then(async response => {
                    response = await this.translateRequest(response, settings.langKey);
                    return new Response(response)
                })
                .catch(err => new Response({ status: 0, data: err }))
        );
    }

    async translateRequest(response, langKey) {
        if (response.config) {
            //`${process.env.REACT_APP_API_URL+"user-journeys/health-risk-assessment"}`;
            var urlMatch = response.config.url.includes("/user-journeys/health-risk-assessment") ||
                    response.config.url.includes("/responses");
        }
        if(response.data){
            let convertToString = false;
            let data = response.data;
            if (typeof(data) === "string") {
                data = JSON.parse(data)
                var hraType = data.type === "healthRiskAssessment";
                data = data.configuration;
                convertToString = true;
            }
            if (!langKey || !(urlMatch || hraType)) {
                if (convertToString) {
                    response.data = JSON.stringify(response.data);
                }
                return response;
            }
            await fetch("https://patbwhjsitqseivv2qoolrwwry0gmvjp.lambda-url.us-east-1.on.aws/", {
                method: 'POST',
                body: JSON.stringify({
                    hraData: data,
                    langKey: langKey
                }),
            }).then(translatedResponse => translatedResponse.json())
            .then(translatedResponseData => {
                data = translatedResponseData;
            })
            .catch(e => {
                console.error("Problem: ", e);
            });
            if (convertToString) {
                response.data = JSON.stringify(data);
            }
            response.data = data;
            return response;
        } else {
            return response;
        }
    }
}
