/**
 * Ref: https://github.com/WiaczeslawP/react-screen-keyboard
 */

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import KeyboardButton from "./KeyboardButton";

import LatinLayout from "./layouts/LatinLayout";
import CyrillicLayout from "./layouts/CyrillicLayout";
import SlovakLayout from "./layouts/SlovakLayout";
import UkrainianLayout from "./layouts/UkrainianLayout";
import SymbolsNumericReducedLayout from "./layouts/SymbolsNumericReducedLayout";
import NumericLayout from "./layouts/NumericLayout";
import SisuLatinLayout from "./layouts/SisuLatinLayout";

import BackspaceIcon from "./icons/BackspaceIcon";
import LanguageIcon from "./icons/LanguageIcon";
import ShiftIcon from "./icons/ShiftIcon";
import theme from "../../../themes";
import { KeyboardRoot, KeyboardNumericRoot, KeyboardRow } from "./KeyboardStyles";
import { withTranslation } from "react-i18next";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
const Style = {
    genericTouch: "genericTouch",
    sisu: "sisu",
    sisuEmail: "sisuEmail"
};

class Keyboard extends PureComponent {
    static propTypes = {
        leftButtons: PropTypes.arrayOf(PropTypes.node),
        rightButtons: PropTypes.arrayOf(PropTypes.node),
        inputNode: PropTypes.any,
        onClick: PropTypes.func,
        onNext: PropTypes.func,
        nextEnabled: PropTypes.bool,
        isFirstLetterUppercase: PropTypes.bool,
        isNumeric: PropTypes.bool,
        disableSymbols: PropTypes.bool,
        disableAlphabets: PropTypes.bool,
        style: PropTypes.string,
        maxWidth: PropTypes.string,
        margin: PropTypes.string,
        layouts: PropTypes.arrayOf(
            PropTypes.shape({
                symbolsKeyValue: PropTypes.string,
                layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
                specialKeysLayout: PropTypes.arrayOf(PropTypes.string)
            })
        ),
        symbolsLayout: PropTypes.shape({
            symbolsKeyValue: PropTypes.string,
            layout: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
        }),
        className: PropTypes.string,
        uppercaseOnly: PropTypes.bool,
        enableDecimal: PropTypes.bool,
        defaultEnterText: PropTypes.string,
        i18n: PropTypes.object,
        t: PropTypes.func
    };

    static defaultProps = {
        leftButtons: [],
        rightButtons: [],
        isFirstLetterUppercase: false,
        isNumeric: false,
        disableSymbols: false,
        disableAlphabets: false,
        maxWidth: "1020px",
        margin: "30px auto",
        layouts: [LatinLayout, SisuLatinLayout, SlovakLayout, UkrainianLayout, CyrillicLayout],
        symbolsLayout: SymbolsNumericReducedLayout,
        nextEnabled: true,
        uppercaseOnly: false,
        enableDecimal: false,
        defaultEnterText: "buttons.uc_next"
    };

    constructor(props) {
        super(props);

        this.isUppercase = this.isUppercase.bind(this);

        let currentLayout = {
            "en-GB": 0,
            sk: 2,
            uk: 3
        }[props.i18n.language];

        // Load email layout
        if (this.props.style === Style.sisuEmail) {
            currentLayout = 1;
        }

        this.state = {
            currentLayout: currentLayout ? currentLayout : 1,
            showSymbols: this.props.disableAlphabets,
            // evaluate this immediately so that a click of a key is not necessary
            uppercase: this.isUppercase()
        };

        // Enter key on the physical keyboard
        this.handlePhysicalKeyPressed = this.handlePhysicalKeyPressed.bind(this);
        const rootElement = document.getElementById("root");
        if (rootElement) {
            rootElement.addEventListener("keypress", this.handlePhysicalKeyPressed);
        }
    }

    componentWillUnmount() {
        const rootElement = document.getElementById("root");
        rootElement.removeEventListener("keypress", this.handlePhysicalKeyPressed);
    }

    handlePhysicalKeyPressed(e) {
        if (e.key === "Enter") {
            this.props.nextEnabled && this.props.onNext ? this.props.onNext(this.props.inputNode.value) : null;
        }
    }

    handleLanguageClick = () => {
        this.setState({
            currentLayout: (this.state.currentLayout + 1) % this.props.layouts.length,
            showSymbols: false
        });

        this.props.inputNode.focus();
    };

    handleShiftClick = () => {
        this.setState({ uppercase: !this.state.uppercase });

        this.props.inputNode.focus();
    };

    handleSymbolsClick = () => {
        this.setState({ showSymbols: !this.state.showSymbols });

        this.props.inputNode.focus();
    };

    handleLetterButtonClick = key => {
        key = key + ""; //stringfy or number keys (which send an int) break the selection point
        const { inputNode } = this.props;
        let { value, selectionStart, selectionEnd } = inputNode;
        const nextValue = value.substring(0, selectionStart) + key + value.substring(selectionEnd);

        inputNode.value = nextValue;
        if (this.props.onClick) {
            this.props.onClick(nextValue);
        }

        inputNode.focus();
        inputNode.setSelectionRange(selectionStart + key.length, selectionStart + key.length);

        this.setState({ uppercase: this.isUppercase() });
        inputNode.dispatchEvent(new Event("input", { bubbles: true }));
    };

    handleBackspaceClick = () => {
        const { inputNode } = this.props;
        const { value, selectionStart, selectionEnd } = inputNode;
        let nextValue;
        let nextSelectionPosition;
        if (selectionStart === selectionEnd) {
            nextValue = value.substring(0, selectionStart - 1) + value.substring(selectionEnd);
            nextSelectionPosition = selectionStart - 1;
        } else {
            nextValue = value.substring(0, selectionStart) + value.substring(selectionEnd);
            nextSelectionPosition = selectionStart;
        }
        nextSelectionPosition = nextSelectionPosition > 0 ? nextSelectionPosition : 0;

        inputNode.value = nextValue;
        if (this.props.onClick) {
            this.props.onClick(nextValue);
        }
        setTimeout(() => {
            inputNode.focus();
            inputNode.setSelectionRange(nextSelectionPosition, nextSelectionPosition);
        }, 0);
        this.setState({ uppercase: this.isUppercase() });
        inputNode.dispatchEvent(new Event("input", { bubbles: true }));
    };

    isUppercase() {
        const { inputNode, isFirstLetterUppercase, uppercaseOnly } = this.props;
        const upperCase = (inputNode && !inputNode.value.length && isFirstLetterUppercase) || uppercaseOnly;
        return inputNode && inputNode.type !== "password" && inputNode.dataset.type !== "email" && upperCase;
    }

    getKeys() {
        let keysSet;
        keysSet = this.state.showSymbols
            ? this.props.symbolsLayout.layout
            : this.props.layouts[this.state.currentLayout].layout;

        return this.state.uppercase ? keysSet.map(keyRow => keyRow.map(key => key.toUpperCase())) : keysSet;
    }

    getSpecialKeys() {
        return this.props.layouts[this.state.currentLayout].specialKeysLayout;
    }

    getSymbolsKeyValue() {
        if (this.state.showSymbols) {
            return this.props.layouts[this.state.currentLayout].symbolsKeyValue;
        }

        return this.props.symbolsLayout.symbolsKeyValue;
    }

    renderKeyRows() {
        const keys = this.getKeys();
        return keys.map((row, i) => (
            <KeyboardRow key={`row-${i}`}>
                {i === keys.length - 1 && (
                    <KeyboardButton
                        value={<ShiftIcon />}
                        classes="keyboard-shiftButton"
                        onClick={this.handleShiftClick}
                    />
                )}
                {row.map(button => (
                    <KeyboardButton value={button} onClick={this.handleLetterButtonClick} key={button} />
                ))}
                {i === keys.length - 1 && (
                    <KeyboardButton
                        value={this.getSymbolsKeyValue()}
                        classes="keyboard-symbolButton"
                        onClick={this.handleSymbolsClick}
                    />
                )}
            </KeyboardRow>
        ));
    }

    renderSisuKeyRows() {
        const keys = this.getKeys();

        return keys.map((row, i) => (
            <KeyboardRow key={`row-${i}`}>
                {i === keys.length - 1 && !this.state.showSymbols && (
                    <KeyboardButton value={<ShiftIcon />} onClick={this.handleShiftClick} minWidth={"100px"} />
                )}
                {row.map(button => {
                    const isNumber = !isNaN(button);
                    const isSymbol = this.state.showSymbols && !isNumber;
                    const showDot = this.state.showSymbols && button === "." && this.props.enableDecimal;
                    const disabled = !showDot && isSymbol && this.props.disableSymbols;

                    return (
                        <KeyboardButton
                            value={button}
                            onClick={this.handleLetterButtonClick}
                            key={button}
                            isDisabled={disabled}
                        />
                    );
                })}
                {i === 0 && <KeyboardButton value={<BackspaceIcon />} onPressed={this.handleBackspaceClick} />}
                {i === 1 && (
                    <KeyboardButton
                        value={this.props.t(this.props.defaultEnterText)}
                        onClick={() =>
                            this.props.nextEnabled && this.props.onNext
                                ? this.props.onNext(this.props.inputNode.value)
                                : null
                        }
                        backgroundColor={
                            this.props.nextEnabled ? theme.colours.flat.blue.hex : theme.colours.flat.gray.hex
                        }
                        color={theme.colours.flat.white.hex}
                        minWidth={"150px"}
                        isDisabled={!this.props.nextEnabled}
                    />
                )}
            </KeyboardRow>
        ));
    }

    renderSisuSpecialKeysRow() {
        let layout;
        if (this.state.showSymbols) {
            layout = (
                <KeyboardRow>
                    <KeyboardButton
                        value={this.getSymbolsKeyValue(true)}
                        onClick={this.handleSymbolsClick}
                        isDisabled={this.props.disableAlphabets}
                    />
                    <KeyboardButton
                        value={" "}
                        classes="keyboard-spaceButton"
                        onClick={this.handleLetterButtonClick}
                        isDisabled={this.props.style === Style.sisuEmail}
                    />
                    <KeyboardButton
                        value={this.getSymbolsKeyValue(true)}
                        onClick={this.handleSymbolsClick}
                        isDisabled={this.props.disableAlphabets}
                    />
                </KeyboardRow>
            );
            return layout;
        }

        const keys = this.getSpecialKeys();
        switch (this.props.style) {
            case Style.sisuEmail:
                layout = (
                    <KeyboardRow>
                        <KeyboardButton value={this.getSymbolsKeyValue(true)} onClick={this.handleSymbolsClick} />
                        {keys.map(button => {
                            let key;
                            switch (button) {
                                case "@":
                                    key = (
                                        <KeyboardButton
                                            value={button}
                                            onClick={this.handleLetterButtonClick}
                                            key={button}
                                        />
                                    );
                                    break;

                                case ".com":
                                    key = (
                                        <KeyboardButton
                                            value={button}
                                            onClick={this.handleLetterButtonClick}
                                            key={button}
                                        />
                                    );
                                    break;

                                default:
                                    key = (
                                        <KeyboardButton
                                            value={button}
                                            onClick={this.handleLetterButtonClick}
                                            classes="keyboard-emailKey"
                                            key={button}
                                        />
                                    );
                                    break;
                            }
                            return key;
                        })}
                    </KeyboardRow>
                );
                break;

            case Style.genericTouch:
            case Style.sisu:
            default:
                layout = (
                    <KeyboardRow>
                        <KeyboardButton value={this.getSymbolsKeyValue(true)} onClick={this.handleSymbolsClick} />
                        <KeyboardButton
                            value={" "}
                            classes="keyboard-spaceButton"
                            onClick={this.handleLetterButtonClick}
                        />
                        <KeyboardButton value={this.getSymbolsKeyValue(true)} onClick={this.handleSymbolsClick} />
                    </KeyboardRow>
                );
                break;
        }

        return layout;
    }

    renderNumeric() {
        const keys = NumericLayout.layout;
        const { leftButtons, rightButtons } = this.props;
        return (
            <KeyboardNumericRoot className={this.props.className}>
                {keys.map((row, i) => (
                    <KeyboardRow key={`row-${i}`}>
                        {row.map(button => (
                            <KeyboardButton value={button} onClick={this.handleLetterButtonClick} key={button} />
                        ))}
                        {i === keys.length - 1 && (
                            <KeyboardButton value={<BackspaceIcon />} onClick={this.handleBackspaceClick} />
                        )}
                    </KeyboardRow>
                ))}
                <KeyboardRow>
                    {leftButtons}
                    {rightButtons}
                </KeyboardRow>
            </KeyboardNumericRoot>
        );
    }

    renderSisuNumpad() {
        const keys = NumericLayout.layout;
        const { leftButtons, rightButtons } = this.props;
        return (
            <KeyboardNumericRoot className={this.props.className}>
                {keys.map((row, i) => (
                    <KeyboardRow key={`row-${i}`}>
                        {row.map(button => (
                            <KeyboardButton value={button} onClick={this.handleLetterButtonClick} key={button} />
                        ))}
                        {i === keys.length - 1 && (
                            <KeyboardButton value={<BackspaceIcon />} onClick={this.handleBackspaceClick} />
                        )}
                    </KeyboardRow>
                ))}
                <KeyboardRow>
                    {leftButtons}
                    {rightButtons}
                </KeyboardRow>
            </KeyboardNumericRoot>
        );
    }

    renderAlphanumeric() {
        const { leftButtons, rightButtons, inputNode } = this.props;
        return (
            <KeyboardRoot maxWidth={this.props.maxWidth} margin={this.props.margin} className={this.props.className}>
                <KeyboardRow>
                    {numbers.map(button => (
                        <KeyboardButton
                            value={button}
                            onClick={this.handleLetterButtonClick}
                            classes="keyboard-numberButton"
                            key={button}
                        />
                    ))}
                    <KeyboardButton
                        value={<BackspaceIcon />}
                        classes="keyboard-backspaceButton"
                        onClick={this.handleBackspaceClick}
                    />
                </KeyboardRow>
                {this.renderKeyRows()}
                <KeyboardRow>
                    {leftButtons}
                    {this.props.layouts.length > 1 ? (
                        <KeyboardButton
                            value={<LanguageIcon />}
                            classes="keyboard-languageButton"
                            onClick={this.handleLanguageClick}
                        />
                    ) : null}
                    {inputNode.dataset.type === "email" ? (
                        <KeyboardButton
                            value={"@"}
                            classes="keyboard-atButton"
                            onClick={this.handleLetterButtonClick}
                        />
                    ) : null}
                    <KeyboardButton value={" "} classes="keyboard-spaceButton" onClick={this.handleLetterButtonClick} />
                    {inputNode.dataset.type === "email" ? (
                        <KeyboardButton
                            value={"."}
                            classes="keyboard-fullstopButton"
                            onClick={this.handleLetterButtonClick}
                        />
                    ) : null}
                    {rightButtons}
                </KeyboardRow>
            </KeyboardRoot>
        );
    }

    renderSisuAlphanumeric() {
        return (
            <KeyboardRoot maxWidth={this.props.maxWidth} margin={this.props.margin} className={this.props.className}>
                {this.renderSisuKeyRows()}
                {this.renderSisuSpecialKeysRow()}
            </KeyboardRoot>
        );
    }

    render() {
        if (!this.props.inputNode) {
            return null;
        }

        switch (this.props.style) {
            case Style.genericTouch:
                return this.props.isNumeric ? this.renderNumeric() : this.renderAlphanumeric();

            case Style.sisu:
                return this.props.isNumeric ? this.renderSisuNumpad() : this.renderSisuAlphanumeric();

            case Style.sisuEmail:
                return this.props.isNumeric ? this.renderSisuNumpad() : this.renderSisuAlphanumeric();

            default:
                return this.props.isNumeric ? this.renderNumeric() : this.renderAlphanumeric();
        }
    }
}

export default withTranslation()(Keyboard);
export { Style };
