export const roundedCornerPlugin = {
    id: "custom-rounded-ends",
    afterDraw: chart => {
        const ctx = chart.canvas.getContext("2d");
        const arcs = chart.getDatasetMeta(0).data ?? [];
        arcs &&
            arcs.forEach(arc => {
                var radius = (arc.outerRadius + arc.innerRadius) / 2;
                var thickness = (arc.outerRadius - arc.innerRadius) / 2;
                var angle = Math.PI - arc.endAngle - Math.PI / 2;

                ctx.save();
                ctx.translate(arc.x, arc.y);

                ctx.fillStyle = arc.options.backgroundColor;
                ctx.beginPath();
                ctx.arc(radius * Math.sin(angle), radius * Math.cos(angle), thickness, 0, 2 * Math.PI);
                ctx.fill();

                ctx.restore();
            });
    }
};
