import { add, compose, multiply } from "ramda";
import { UnitConverter } from "../../../index";

export const calcLeanBodyMass = (weightKg, bodyFatPercentage) => (weightKg * (100 - bodyFatPercentage)) / 100;

// The Katch-McArdle Basal Metabolic Rate (BMR) [kJ/day]
export default (weightKg, bodyFatPercentage) =>
    compose(
        UnitConverter.kCalToKJ,
        add(370),
        multiply(21.6),
        calcLeanBodyMass
    )(weightKg, bodyFatPercentage);
