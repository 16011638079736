import styled from "styled-components";
import { Flex } from "rebass";

const TableContainer = styled(Flex)`
    height: 100%;

    // Normal version
    .ReactTable {
        width: 100%;
        height: 100%;

        .rt-tbody {
            .rt-tr:hover {
                background-color: ${props => props.theme.table.hoverColour};
            }
        }

        .rt-table {
            height: 100%;
        }
    }
`;

export default TableContainer;
