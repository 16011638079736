import Units from "../../core/unitConverter";
import InvalidArgumentError from "../../Errors/InvalidArgumentError";
import BodyMassIndex from "../../HealthCalculators/BodyMassIndex";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(weight, height) {
        this._weight = weight;
        this._height = height;
        this._isMetric = true;
        this._validateWeightValue(this._weight);
        this._validateHeightValue(this._height);
    }

    /**
     * Returns the given Weight value
     * @returns {Number}
     */
    get() {
        return this._weight;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "WEIGHT";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_WEIGHT";
    }

    /**
     * Validates the given Weight value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateWeightValue(weight) {
        if (!(typeof weight === "number") || Number.isNaN(weight) || weight < 0) {
            throw new InvalidArgumentError("Weight must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Validates the given Height value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateHeightValue(height) {
        if (!(typeof height === "number") || Number.isNaN(height) || height < 0) {
            throw new InvalidArgumentError("Height must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns weight converted to Imperial value (in lbs)
     *
     * @returns {number}
     */
    setUnitToImperial() {
        if (!this._isMetric) return this._weight;
        this._isMetric = false;
        this._weight = this._convertWeightWRTUnit(this._weight);
        return this._weight;
    }

    /**
     * Returns weight converted to metric value
     *
     * @returns {number}
     */
    setUnitToMetric() {
        if (this._isMetric) return this._weight;
        this._isMetric = true;
        this._weight = this._convertWeightWRTUnit(this._weight);
        return this._weight;
    }

    /**
     * Returns target weight for BMI of value 24 (ideal BMI)
     *
     * @returns {number}
     */
    get targetWeight() {
        const weight = BodyMassIndex.getWeightForHeightAndBMI(this._height, 24);
        return this._isMetric ? weight : this._convertWeightWRTUnit(weight);
    }

    /**
     * Returns weight converted to metric or imperial (In lbs)
     *
     * @param {number} wt weight to convert
     * @returns {number}
     * @private
     */
    _convertWeightWRTUnit(wt) {
        const weight = this._isMetric ? Units.poundsToKgs(wt) : Units.kgToPounds(wt);
        return weight;
    }

    /**
     * Returns weight converted to metric or imperial (In lbs)
     *
     * @param {number} bmi body mass index
     * @param {boolean} round a flag to get a rounded weight value for bmi
     * @returns {number}
     */
    _calculateWeightWRTBmi(bmi, round = false) {
        let weight = BodyMassIndex.getWeightForHeightAndBMI(this._height, bmi);
        weight = this._isMetric ? weight : Units.kgToPounds(weight);
        return round ? Math.round(weight) : weight;
    }

    /**
     * Returns an object representing various data for each Weight range
     *
     * @returns {object}
     */
    get ranges() {
        return {
            low: {
                key: "low",
                label: "ranges.low",
                range: `< ${this._calculateWeightWRTBmi(18.5, true)}`
            },

            normal: {
                key: "normal",
                label: "ranges.normal",
                range: `${this._calculateWeightWRTBmi(18.5, true)} - ${this._calculateWeightWRTBmi(24.9, true)}`
            },

            elevated: {
                key: "elevated",
                label: "ranges.elevated",
                range: `${this._calculateWeightWRTBmi(25, true)} - ${this._calculateWeightWRTBmi(29.9, true)}`
            },

            high: {
                key: "high",
                label: "ranges.high",
                range: `${this._calculateWeightWRTBmi(30, true)} - ${this._calculateWeightWRTBmi(34.9, true)}`
            },

            veryHigh: {
                key: "veryHigh",
                label: "ranges.very_high",
                range: `${this._calculateWeightWRTBmi(35, true)}+`
            }
        };
    }

    /**
     * Returns if the given Weight is in the 'low' range
     *
     * @returns {boolean}
     */
    isLow() {
        return this._weight < this._calculateWeightWRTBmi(18.5);
    }

    /**
     * Returns if the given Weight is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._weight >= this._calculateWeightWRTBmi(18.5) && this._weight < this._calculateWeightWRTBmi(25);
    }

    /**
     * Returns if the given Weight is in the 'elevated' range
     *
     * @returns {boolean}
     */
    isElevated() {
        return this._weight >= this._calculateWeightWRTBmi(25) && this._weight < this._calculateWeightWRTBmi(30);
    }

    /**
     * Returns if the given Weight is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._weight >= this._calculateWeightWRTBmi(30) && this._weight < this._calculateWeightWRTBmi(35);
    }

    /**
     * Returns if the given Weight is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        return this._weight >= this._calculateWeightWRTBmi(35);
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = this.ranges.low.range;
                break;
            case this.isNormal():
                info = this.ranges.normal.range;
                break;
            case this.isElevated():
                info = this.ranges.elevated.range;
                break;
            case this.isHigh():
                info = this.ranges.high.range;
                break;
            case this.isVeryHigh():
                info = this.ranges.veryHigh.range;
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = this.ranges.low.key;
                break;
            case this.isNormal():
                info = this.ranges.normal.key;
                break;
            case this.isElevated():
                info = this.ranges.elevated.key;
                break;
            case this.isHigh():
                info = this.ranges.high.key;
                break;
            case this.isVeryHigh():
                info = this.ranges.veryHigh.key;
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = this.ranges.low.label;
                break;
            case this.isNormal():
                info = this.ranges.normal.label;
                break;
            case this.isElevated():
                info = this.ranges.elevated.label;
                break;
            case this.isHigh():
                info = this.ranges.high.label;
                break;
            case this.isVeryHigh():
                info = this.ranges.veryHigh.label;
                break;
        }

        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 5,
            riskLevel = 0;
        switch (true) {
            case this.isLow():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
            case this.isElevated():
                riskLevel = 3 / (totalRiskLevel + 1);
                break;
            case this.isHigh():
                riskLevel = 4 / (totalRiskLevel + 1);
                break;
            case this.isVeryHigh():
                riskLevel = 5 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }
}
