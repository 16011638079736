import { Flex } from "rebass";
import styled from "styled-components";
import { typography } from "styled-system";

export const SpinnerContainer = styled.div`
    width: 100%;
    height: 50px;
    position: relative;

    ::before {
        content: attr(label);
        font-size: 15px;
        font-weight: ${props => props.theme.fontWeight.semiBold};
        line-height: 1.33;
        text-align: left;
        position: absolute;
        bottom: 100%;
        margin-bottom: 8px;
        left: 0;
    }

    .spinner-box {
        cursor: ${props => (props.showCursor ? "pointer" : "auto")};
        width: 100%;
        height: 50px;
        border-radius: 5px;
        background-color: white;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        color: ${props => (props.error && !props.open ? "#ff647c" : props.theme.colours.blackTints700.hex)};
        box-shadow: ${props => props.error && !props.open && "0 8px 8px 0 rgba(255, 100, 124, 0.08)"};
        border: solid 1px
            ${props =>
                props.open
                    ? props.theme.colours.horizonBlue.hex
                    : props.error
                    ? "#ff647c"
                    : "rgba(102, 102, 102, 0.24)"};

        .spinner-chevron {
            width: 29px;
            height: 28px;
            margin: 16px 20px 16px auto;
            transform: ${props => (props.open ? "scale(-1)" : "scale(1)")};
        }

        & > .spinner-value {
            margin: 16px 20px;
            font-size: 13px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
            letter-spacing: normal;
            text-align: left;
            min-width: 16px;
        }
    }
    ${props => (props.customStyle ? props.customStyle : "")}
`;

export const SpinnerOptions = styled.div`
    position: absolute;
    width: 100%;
    max-height: 250px;
    background: white;
    border-radius: 5px;
    top: 100%;
    z-index: 2;
    left: 0;
    overflow: ${props => (props.length > 5 ? "auto" : "hidden")};
    box-shadow: ${props => props.theme.portalShadows.card2};

    .spinner-item {
        padding: 16px 20px;
        font-size: 13px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.38;
        letter-spacing: normal;
        text-align: left;
        cursor: pointer;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: rgba(102, 102, 102, 0.08);
            left: 0;
            bottom: 0;
        }
    }

    .spinner-item.no-seperator::after {
        content: none;
    }
`;

export const Container = styled(Flex)`
    ${typography}
`;
