import styled from "styled-components";
import { Box, Flex, Image, Text, Card } from "rebass";

import Select from "react-select";
import baseTheme, { media } from "@sisuwellness/web-components/themes";
import React from "react";
import { PORTAL_IMAGE } from "constants/imagePath";

// ------------------------------------------------------------------------

export const ColumnWrapper = styled(Flex)`
    height: 494px;
    width: 100%;
    background: ${props => props.theme.colours.flat.white.hex};

    ${media.mobile`
        height: auto;
        border-radius: 10px;
        background: ${props => props.theme.colours.hpPurple100.hex}
    `}
`;

export const Warning = styled.div`
    color: ${props => props.theme.colours.flat.white.hex};
    background: ${props => props.theme.colours.flat.rose.hex};
    padding: 10px 20px;
    text-align: center;
    border-radius: ${props => props.theme.defaults.borderRadius};
    margin-bottom: 15px;

    p {
        display: block;
        margin: 5px;

        a {
            font-weight: bold;
            cursor: pointer;
        }
    }
`;

export const RightColumn = styled(Box)`
    flex: 2 0px;

    border-radius: 10px;
    overflow: hidden;

    ${media.mobile`
        height: 40vh;
        min-height: 40vh;
        overflow: visible;
    `}
`;

export const GEO_LOCATION_STATE = {
    DENIED: "DENIED",
    UNKNOWN: "UNKNOWN"
};

export const StyledGrid = styled(Flex)`
    display: grid;
    row-gap: 16px;
`;

export const StyledSelect = styled(Select)`
    & > div {
        border: none;
        background: ${props => props.theme.colours.flat.white.hex};
    }
`;

export const customStyles = {
    container: provided => ({
        ...provided,
        margin: "auto",
        width: "100%"
    }),
    control: (provided, state) => ({
        ...provided,
        height: "54px",
        borderRadius: "5px",
        boxShadow: state.isFocused ? "0" : "0"
    }),
    placeholder: () => ({
        fontSize: baseTheme.text.heading6.size,
        color: baseTheme.colours.flat.lightBlack.hex,
        padding: "2px"
    }),
    indicatorSeparator: () => ({
        display: "none"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        margin: "auto",
        padding: "8px",
        width: "50px",
        height: "100%",
        cursor: "pointer",
        borderRadius: "0 5px 5px 0"
    }),
    dropdownIndicator: base => ({
        ...base,
        color: "#000000",
        strokeWidth: "1px"
    })
};

export const aus = (
    <Flex alignItems="center">
        <Image src={`${PORTAL_IMAGE}/countryFlags/au.svg`} height="24px" width="24px" mr="24px" alt="Australia Flag" />
        <Text>Australia</Text>
    </Flex>
);
export const uk = (
    <Flex alignItems="center">
        <Image src={`${PORTAL_IMAGE}/countryFlags/gb.svg`} height="24px" width="24px" mr="24px" alt="UK Flag" />
        <Text>UK</Text>
    </Flex>
);
export const placeHolder = (
    <Flex alignItems="center">
        <Image src={`${PORTAL_IMAGE}/countryFlags/flag.svg`} height="24px" width="24px" mr="10px" />
        <Text fontSize="13px">Select your country</Text>
    </Flex>
);

//eslint-disable-next-line
export const MapIcon = ({ stroke = "#4D4D4D", height = "24", width = "24" }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 28 28">
            <path
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M23 12c0 7-9 13-9 13s-9-6-9-13c0-2.387.948-4.676 2.636-6.364C9.324 3.948 11.613 3 14 3s4.676.948 6.364 2.636C22.052 7.324 23 9.613 23 12z"
            />
            <path
                stroke={stroke}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14 15c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z"
            />
        </svg>
    );
};

export const StyledCard = styled(Card)`
    margin-bottom: 16px;
    padding: 16px 24px 24px 16px;
    margin-bottom: 16px;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid
        ${props => (props.active ? props.theme.colours.hpPrimaryPurple.hex : props.theme.colours.flat.white.hex)};
`;
