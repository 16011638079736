import { add, compose, curry, filter, keys, map, nth, when } from "ramda";
import { LactatingCorrectionKj } from "./TotalDailyEnergyExpenditure";
import { UnitConverter } from "../../core";

export const RiskName = "DAILY_ENERGY_USE";
export const RiskCode = "DAILY_ENERGY_USE";
export const GuidelineName = "SiSU Daily Energy Use Guideline";
export const GuidelineCode = "SISU_DAILY_ENERGY_USE";

// there is no actual risk here, just to be consistent
export const TotalRiskLevel = 3;
export const Keys = {
    low: "low",
    moderate: "moderate",
    high: "high"
};

export const getRanges = (bmrKj, isLactating) =>
    map(when(() => isLactating, map(add(LactatingCorrectionKj))), {
        [Keys.low]: [-Number.MAX_VALUE, bmrKj * 1.75],
        [Keys.moderate]: [bmrKj * 1.75, bmrKj * 2.0],
        [Keys.high]: [bmrKj * 2.0, Number.MAX_VALUE]
    });

export const getRangeKey = (dailyEnergyUseKj, deuRanges) =>
    compose(
        nth(0),
        keys,
        filter(([min, max]) => dailyEnergyUseKj >= min && dailyEnergyUseKj < max)
    )(deuRanges);

export const formatNumber = curry((locale, num) => UnitConverter.formatNumberToReadableFix(num, locale));

export const buildGuidelineDefinition = (deuRanges, inCalories, locale) => {
    const transformRange = compose(
        formatNumber(locale),
        when(() => inCalories, UnitConverter.kJToKcal)
    );

    const { low, moderate, high } = map(map(transformRange), deuRanges);

    return {
        low: {
            key: Keys.low,
            label: "ranges.low",
            riskLevel: 3 / (TotalRiskLevel + 1),
            rangeInfo: `< ${low[1]}`
        },
        moderate: {
            key: Keys.moderate,
            label: "ranges.moderate",
            riskLevel: 2 / (TotalRiskLevel + 1),
            rangeInfo: `${moderate[0]} - ${moderate[1]}`
        },
        high: {
            key: Keys.high,
            label: "ranges.high",
            riskLevel: 1 / (TotalRiskLevel + 1),
            rangeInfo: `${high[0]}+`
        }
    };
};

// TODO: Figure out why Jest coverage for branching is 66%, while other metrics are 100%
/* istanbul ignore next */
export default ({ dailyEnergyUseKj, bmrKj, isLactating, inCalories, locale } = {}) => {
    const deuRanges = getRanges(bmrKj, isLactating);

    const rangeKey = getRangeKey(dailyEnergyUseKj, deuRanges);

    const ranges = buildGuidelineDefinition(deuRanges, inCalories, locale);

    return { ranges, ...ranges[rangeKey] };
};
