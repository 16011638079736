/**
 * Returns list of all question ids for the specified user journey
 * @param {object} userJourneyModel
 */
export const getTotalQuestions = userJourneyModel => {
    const questionIdList = [];

    if (!userJourneyModel) return questionIdList;

    // traverse through all user journey pages
    userJourneyModel
        .getUserJourneyPages()
        .filter(userjourneyPage => userjourneyPage.getPageType().isTypeSurvey()) // filter only survey pages
        .forEach(userjourneyPage => {
            // get survey on each page
            const survey = userjourneyPage.getSurvey();

            // get questions groups for the survey
            const questionGroup = survey.getQuestionGroups();
            questionGroup.forEach(questionGroup => {
                // get each question in the question group
                questionGroup.getQuestions().forEach(question => {
                    questionIdList.push(question.getQuestionId());
                });
            });
        });

    // returns list of questions ids in order
    return questionIdList;
};
