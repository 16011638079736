import React from "react";
import { path } from "ramda";
import { Cell, Header, LinkCell } from "./commonElements";
import { calculateHeartAge } from "utilities/premium";
import routes from "constants/routes";
import { t } from "i18next";

const handleCellConstruct = (row, { isInterestedInPremium, isPremium }) => {
    const datum = path(["original"], row);
    const { heartAge = null } = calculateHeartAge(datum);

    return isPremium ? (
        <Cell data-testid="table-cell-heartAge" row={row} value={heartAge} unit="years" />
    ) : (
        isInterestedInPremium && (
            <LinkCell data-testid="table-cell-heartAge" row={row} href={routes.heartAge} text={t("words.calculate")} />
        )
    );
};

export const heartAgeDef = (iconTypes, premiumProps) => ({
    minWidth: 92,
    accessor: "heartAge",
    Cell: row => handleCellConstruct(row, premiumProps),
    Header: () => <Header label={t("words.heart_age")} iconType={iconTypes.heartAge} />
});
