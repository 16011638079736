import { range, reverse } from "ramda";
import { moment } from "utilities/commonUtils";

export const MONTHS = moment.monthsShort();

const toYear = moment().year();
const fromYear = moment()
    .subtract(100, "year")
    .year();

export const YEAR = reverse(range(fromYear, toYear));
export const DAY = range(1, 32).map(num =>
    num.toLocaleString("en-US", { minimumIntegerDigits: 2, useGrouping: false })
);
