import React, { useState } from "react";
import PropTypes from "prop-types";

import { Image, Text } from "rebass";

// ------------------------------------------------------------------------

import Flex from "components/Flex";
import { PORTAL_IMAGE } from "constants/imagePath";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import NotificationModal, { ModalBody } from "components/NotificationModal";
import styled from "styled-components";

// ------------------------------------------------------------------------

const StyledText = styled(Text)`
    p {
        font-size: inherit;
    }
`;

export const InformationModal = ({ heading, children: content, ...rest }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOnClick = () => setIsModalOpen(isOpen => !isOpen);
    const handleOnClose = () => setIsModalOpen(false);

    return (
        <>
            <Flex gap="4px" onClick={handleOnClick} ai="center" style={{ cursor: "pointer" }} {...rest}>
                <Image size="16px" src={`${PORTAL_IMAGE}/premium/info.svg`} alt="info-icon" mb="2px" />
                <HeadingLabel fontSize="14px" fontWeight="500">
                    {heading}
                </HeadingLabel>
            </Flex>
            <NotificationModal onClose={handleOnClose} open={isModalOpen} width={["343px", "716px", "794px"]}>
                {close => (
                    <ModalBody p={["16px", "48px"]}>
                        <Flex gap={["16px", "29px"]} fd="column">
                            <Flex mt={["0px", "6px"]} jc="space-between">
                                <HeadingLabel fontSize={["20px", "28px"]}>{heading}</HeadingLabel>
                                <Image
                                    onClick={close}
                                    alt="modal-close"
                                    style={{ cursor: "pointer" }}
                                    src={`${PORTAL_IMAGE}/cross.svg`}
                                />
                            </Flex>
                            <StyledText color="blackTints700" fontWeight="light" fontSize="18px">
                                {content}
                            </StyledText>
                        </Flex>
                    </ModalBody>
                )}
            </NotificationModal>
        </>
    );
};
InformationModal.propTypes = {
    heading: PropTypes.string,
    children: PropTypes.any
};
