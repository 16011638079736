import { Box } from "rebass";
import styled from "styled-components";

const Card = styled(Box)`
    border-radius: 5px;
    ${props => props.theme.shadowCss.rest()};
    ${props => props.clickable && `cursor: pointer`};
`;

Card.defaultProps = {
    p: "1.25em",
    backgroundColor: "#FFFFFF"
};

export const TitleCard = styled(Card)`
    border-radius: 5px 5px 0 0;
    border-bottom: 2px solid ${props => props.theme.colours.flat.teal.hex};
`;

export const FooterCard = styled(Card)`
    border-radius: 0 0 5px 5px;
`;

export default Card;
