import React, { Component } from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { StyledChoice, StyledChoiceSelected } from "../../Forms/Choice";
import { withTranslation } from "react-i18next";

const defaultMargin = "0.3em";
const increasedMargin = "1em";

const StyledLabel = styled.p`
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.text.heading4.sizeRem};
`;

// at the time of this styling change, the margin was set to 0 so that at least 3 large choices would display without being
// truncated by the bounding container
const StyledHelpText = styled.p`
    margin: 0;
    padding: 0;
    font-size: ${props => props.theme.text.subText.sizeRem};
`;

class Choice extends Component {
    static displayName = "Choice Button";

    static propTypes = {
        className: PropTypes.string,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
        schema: PropTypes.object.isRequired,
        selected: PropTypes.bool.isRequired,
        isFirst: PropTypes.bool.isRequired,
        isLast: PropTypes.bool.isRequired,
        size: PropTypes.string,
        theme: PropTypes.object.isRequired,
        isFullWidth: PropTypes.bool,
        t: PropTypes.func,
        i18n: PropTypes.object
    };

    static defaultProps = {
        size: "medium"
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    async handleClick(e) {
        e.preventDefault();

        let { selected, schema, onSelect, onDeselect } = this.props;

        const newI18n = this.props.i18n.cloneInstance({});
        await newI18n.changeLanguage("en-GB");

        const englishLabel = newI18n.t(schema.label);

        if (selected) {
            onDeselect(englishLabel);
        } else {
            onSelect(englishLabel);
        }
    }

    render() {
        const { size, isFirst, isLast, schema, theme, isFullWidth } = this.props;

        const width = theme.buttons[size].sizeRem;

        // change margin based on first and last choice so there is sufficient whitespace between question title (or help text) and the next button;
        let topMargin = isFirst ? increasedMargin : defaultMargin;
        let bottomMargin = isLast ? increasedMargin : defaultMargin;

        let text = <StyledLabel>{this.props.t(schema.label)}</StyledLabel>;
        let helpText = schema.helpText ? <StyledHelpText>{this.props.t(schema.helpText)}</StyledHelpText> : null;

        let choice = (
            <StyledChoice
                className={`choice-${this.props.className}`}
                width={isFullWidth ? "90%" : width}
                data-component-name={"choice"}
                onClick={this.handleClick}
                topMargin={topMargin}
                bottomMargin={bottomMargin}
            >
                {text}
                {helpText}
            </StyledChoice>
        );
        if (this.props.selected) {
            choice = (
                <StyledChoiceSelected
                    className={`choice-${this.props.className}`}
                    width={width}
                    data-component-name={"choice"}
                    onClick={this.handleClick}
                    topMargin={topMargin}
                    bottomMargin={bottomMargin}
                >
                    {text}
                    {helpText}
                </StyledChoiceSelected>
            );
        }
        return choice;
    }
}

export default withTranslation()(withTheme(Choice));
