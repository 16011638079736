import React, { Component } from "react";
import PropTypes from "prop-types";
import { Text, Choice, Number, Date, Height, Weight, DateOfBirth } from "../QuestionTypes";
import styled from "styled-components";
import SiSUKeyboard from "../../Keyboards/SisuKeyboard";
import { Style as KeyboardStyle } from "../../Keyboards/base/Keyboard";
import { userJourneyMap as Codes } from "@sisuwellness/utilities";
import { UserJourneyMap } from "@sisuwellness/utilities/UserJourney";
import OneQuestionAtATimeNavigation from "../Navigation/OneQuestionAtATimeNavigation";
import { NumericPostalCodesLanguageTags, LanguageTags } from "@sisuwellness/utilities/constants/locale";
import MultipleAnswer from "../QuestionTypes/MultipleAnswer";
import Address from "../QuestionTypes/Address";
import MedicareValidator from "@sisuwellness/utilities/Validation/MedicareValidator";
import { pathOr } from "ramda";
import MedicareNumber from "../../Medicare/MedicareNumber";
import MedicareExpiry from "../../Medicare/MedicareExpiry";
import MedicareAcknowledgment from "../../Medicare/MedicareAcknowledgment";
import { Navigation } from "../HRARestyle";
import { HRAHeight, HRANumber, HRAWeight } from "../HRARestyle/QuestionTypes";
import HRAChoice from "../HRARestyle/QuestionTypes/Choice";
import PhysicalActivityLevel from "../../PhysicalActivityLevel";
import { withTranslation } from "react-i18next";

const Root = styled.div`
    display: flex;
    flex-direction: column;
    height: ${props => (props.isKeyboardShown === true ? "64%" : "100%")};
    justify-content: ${props => (props.isKeyboardShown === true ? "space-between" : "center")};
    align-items: stretch;
`;

const KeyboardContainer = styled.div`
    background: ${props => props.theme.colours.flat.lightGray.hex};
    position: absolute;
    left: 0;
    bottom: 0;

    height: 38%;
    width: 100%;

    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.9));
    display: flex;
`;

const NavigationBarContainer = styled.div`
    margin: ${props => (props.isKeyboardShown === true ? "1.5em 0 3em 0" : "1.5em 0 0.5em 0")};
`;

class OneQuestionAtATime extends Component {
    static displayName = "Question Group that displays one question at a time";
    static propTypes = {
        model: PropTypes.object.isRequired,
        onComplete: PropTypes.func.isRequired,
        onChangeCurrentQuestion: PropTypes.func.isRequired,
        canAskNextQuestion: PropTypes.func,
        touchKeyboard: PropTypes.bool,
        getComponentForQuestionType: PropTypes.func,
        locale: PropTypes.object,
        t: PropTypes.func
    };

    static defaultProps = {
        touchKeyboard: false
    };

    constructor(props) {
        super(props);

        this.state = {
            value: "", // retains the state of the input field values
            currentQuestionId: null,
            inputNode: null,
            keyboardOwnerName: null,
            errors: [],
            canProceed: false,
            multipleAnswers: []
        };

        this.handleNextClick = this.handleNextClick.bind(this);
        this.handleBackClick = this.handleBackClick.bind(this);
        this.handleSingleChoiceSelect = this.handleSingleChoiceSelect.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleRemoveValue = this.handleRemoveValue.bind(this);
    }

    componentDidMount() {
        this.handleNextQuestion();
    }

    handleValueChange(value) {
        this.setState({ value });
    }

    handleSingleChoiceSelect(value) {
        // updating state.value results in a race condition where we can't answer the
        // question so we use the value in the callback immediately
        this.answerCurrentQuestion(value);
    }

    handleMultipleAnswerChange(answers) {
        this.setState({ multipleAnswers: answers, value: answers.length > 0 ? false : [] });
    }

    addressNextEnabled(questionModel) {
        if (!questionModel.isRequired()) {
            return true;
        }

        if (this.state.value == null || this.state.value.length === 0) {
            return false;
        }

        const value = JSON.parse(this.state.value);
        return value.inputValue && value.inputValue.length;
    }

    handleRemoveValue() {
        this.setState({ value: null });
    }

    handleNextClick(event = null) {
        let answer = null;
        if (event) answer = event.answer;
        if (this.currentQuestion.isQuestionTypeMultipleAnswer()) {
            const outcome = this.currentQuestion.addMultipleAnswers(this.state.multipleAnswers);
            if (outcome === true) {
                this.handleNextQuestion();
            }
        } else {
            this.answerCurrentQuestion(answer || this.state.value);
        }
    }

    handleBackClick() {
        let question = this.props.model.getQuestionByQuestionId(this.state.currentQuestionId);
        let previousQuestion = this.props.model.getSurvey().getPreviouslyAnsweredQuestion(question);
        if (previousQuestion) {
            let answers = previousQuestion.getAnswers();

            this.setState({
                errors: [],
                currentQuestionId: previousQuestion.getQuestionId(),
                value: answers.length ? answers[0].getRawAnswer() : null
            });
        }
    }

    answerCurrentQuestion(answer) {
        let question = this.currentQuestion;
        if (question.getAnswers().length > 0) {
            // clear answers so that 're-answers' from going back simply replace instead of append
            question.clearAnswers(); // FIXME: only supports single answer questions
        }

        if (question.isQuestionTypeNumber() && answer) {
            answer = parseFloat(answer);
        }

        let outcome, valid;
        const survey = this.props.model.getSurvey();
        const config = survey ? (survey.getResponse() ? survey.getResponse().configuration : null) : null;
        const timezone = pathOr("Australia/ACT", ["healthStationInstallation", "address", "timezone"], config);
        const vConfig = question.getValidationConfiguration();
        const addAnswer = (answer, valid) => (valid ? question.addAnswer(answer) : [vConfig.regexErrorMessage]);
        switch (question.getCode()) {
            case Codes.questionCodes.scriptsNowProfileMedicareNumber:
                valid =
                    vConfig.isRequired && answer
                        ? MedicareValidator.validateCardNumber(MedicareValidator.parseMedicareNumber(answer).cardNumber)
                        : true;

                break;

            case Codes.questionCodes.scriptsNowProfileMedicareExpiry:
                valid =
                    vConfig.isRequired && answer
                        ? MedicareValidator.validateExpiry(MedicareValidator.reformExpiry(answer), timezone)
                        : true;
                break;

            default:
                valid = true;
        }

        outcome = addAnswer(answer, valid);
        this.setState({
            inputNode: outcome === true ? null : this.state.inputNode,
            errors: outcome === true ? null : outcome
        });

        if (outcome === true) {
            this.handleNextQuestion();
        }
    }

    /**
     *
     * @returns {Question|null}
     */
    get currentQuestion() {
        return this.props.model.getQuestionByQuestionId(this.state.currentQuestionId);
    }

    handleNextQuestion() {
        // scroll to top of window so that next presented question is clearly shown in full
        window.scroll(0, 0);

        let currentQuestion = this.currentQuestion;
        let stateUpdate = {
            errors: [],
            currentQuestionId: null,
            value: ""
        };

        if (this.props.model.getSurvey().isStateComplete()) {
            // arises upon question exit conditions.
            this.props.onComplete(this.props.model);
        } else {
            let nextQuestion = this.props.model.getSurvey().getNextQuestionToShow(currentQuestion);
            if (!nextQuestion) {
                this.props.onComplete(this.props.model);
            } else {
                if (this.canAskNextQuestion(nextQuestion)) {
                    // we have another question
                    this.props.onChangeCurrentQuestion(nextQuestion);

                    // FIXME: assumes single answer to question
                    stateUpdate.value =
                        nextQuestion.getAnswers().length > 0 ? nextQuestion.getAnswers()[0].getRawAnswer() : "";
                    stateUpdate.currentQuestionId = nextQuestion.getQuestionId();
                } else {
                    nextQuestion.setStateDidNotQualify();
                    this.handleNextQuestion();
                }
            }
        }

        if (stateUpdate.currentQuestionId) {
            this.setState(stateUpdate);
        }
    }

    onInputFocus(inputRef) {
        if (this.state.inputNode === inputRef) {
            return;
        }

        this.setState({ inputNode: inputRef });
    }

    onScanCompleted(barcode) {
        this.answerCurrentQuestion(barcode);
    }

    onScanSkipped() {
        this.handleNextQuestion();
    }

    getKeyboard(overrideProps = {}) {
        let keyboard = null;
        if (this.props.touchKeyboard && this.state.inputNode !== null) {
            const keyboardStyle = KeyboardStyle.sisu;
            const questionModel = this.props.model.getQuestionByQuestionId(this.state.currentQuestionId);
            const survey = questionModel.getQuestionGroup().getSurvey();
            const code = questionModel.getCode();
            const languageTag = survey.locale ? survey.locale.languageTag : LanguageTags.enAU;

            let disableAlphabets = false;
            let disableSymbols = false;
            let uppercaseOnly = false;
            let enableDecimal = false;

            switch (code) {
                case Codes.questionCodes.dateOfBirth:
                case Codes.questionCodes.mobileNumber:
                case Codes.questionCodes.scriptsNowProfileMobileNumber:
                    disableAlphabets = true;
                    disableSymbols = true;
                    break;

                case Codes.questionCodes.postalCode:
                    disableAlphabets = NumericPostalCodesLanguageTags.some(lt => lt === languageTag);
                    disableSymbols = true;
                    uppercaseOnly = languageTag === LanguageTags.enGB;
                    break;

                case Codes.questionCodes.cholhdlRatio:
                    disableAlphabets = true;
                    disableSymbols = true;
                    enableDecimal = true;
                    break;

                default:
                    disableAlphabets = false;
                    disableSymbols = false;
                    enableDecimal = false;
                    uppercaseOnly = false;
            }

            const originalProps = {
                inputNode: this.state.inputNode,
                onNext: this.handleNextClick,
                layoutStyle: keyboardStyle,
                disableAlphabets,
                disableSymbols,
                isFirstLetterUppercase: true,
                uppercaseOnly,
                enableDecimal
            };

            const props = Object.assign(originalProps, overrideProps);
            keyboard = (
                <KeyboardContainer data-testid={"keyboard-container"}>
                    <SiSUKeyboard {...props} />
                </KeyboardContainer>
            );
        }

        return keyboard;
    }

    // TODO: disabled for now as it is not clear if these callbacks are necessary
    canAskNextQuestion(question) {
        return this.props.canAskNextQuestion ? this.props.canAskNextQuestion(question) : true;
    }

    render() {
        if (!this.state.currentQuestionId) {
            return null;
        }

        let questionModel = this.props.model.getQuestionByQuestionId(this.state.currentQuestionId);
        let survey = questionModel.getQuestionGroup().getSurvey();
        let question = null;
        let keyboard = null;
        let backEnabled = !survey.isFirstQuestionShown(questionModel);
        let nextEnabled =
            !questionModel.isRequired() ||
            (typeof this.state.value === "string" ? this.state.value.length > 0 : this.state.value != null);
        let healthStationMode = this.props.model.getSurvey().getHealthStationMode();
        let showNavigationUi = true;
        const { getComponentForQuestionType } = this.props;
        const medicareInfo = getComponentForQuestionType ? getComponentForQuestionType() : [];
        const defaultText = (
            <Text
                key={questionModel.getQuestionId()}
                centreText={true}
                model={questionModel}
                errors={this.state.errors}
                value={this.props.t(this.state.value)}
                id={"survey-question-" + questionModel.getQuestionId()}
                autoFocus={true}
                onValueChange={value => this.handleValueChange(value)}
                touchKeyboard={this.props.touchKeyboard}
                onInputFocus={inputNode => this.onInputFocus(inputNode)}
                inputWidth={"70%"}
            />
        );

        switch (true) {
            case questionModel.isQuestionTypeChoice():
                switch (questionModel.getCode()) {
                    case UserJourneyMap.questionCodes.scriptsNowProfileMedicareAcknowledgement:
                        question = (
                            <MedicareAcknowledgment
                                title={this.props.t(questionModel.getDisplayText())}
                                label={this.props.t(questionModel.getChoices()[0].label)}
                                info={medicareInfo}
                                onAck={answer => {
                                    questionModel._schema.displayText += medicareInfo.join();
                                    this.handleSingleChoiceSelect(answer);
                                }}
                                onBack={this.handleBackClick}
                            />
                        );
                        break;

                    case UserJourneyMap.questionCodes.physicalActivityLevel:
                        question = (
                            <PhysicalActivityLevel
                                title={this.props.t(questionModel.getDisplayText())}
                                helpText={this.props.t(questionModel.getHelpText())}
                                choices={questionModel.getChoices()}
                                onSelect={this.handleSingleChoiceSelect}
                                onDeselect={this.handleRemoveValue}
                                selectedChoiceText={this.state.value}
                            />
                        );
                        break;

                    default:
                        question = (
                            <Choice
                                key={questionModel.getQuestionId()}
                                model={questionModel}
                                errors={this.state.errors}
                                id={"survey-question-" + questionModel.getQuestionId()}
                                selected={[this.state.value]}
                                onSelect={this.handleSingleChoiceSelect}
                                onDeselect={this.handleRemoveValue}
                            />
                        );
                }

                break;

            case questionModel.isQuestionTypeNumber():
                if (healthStationMode) {
                    question = (
                        <Number
                            key={questionModel.getQuestionId()}
                            model={questionModel}
                            errors={this.state.errors}
                            id={"survey-question-" + questionModel.getQuestionId()}
                            value={this.state.value}
                            autoFocus={true}
                            touchKeyboard={this.props.touchKeyboard}
                            onValueChange={value => this.handleValueChange(value)}
                            onInputFocus={inputNode => this.onInputFocus(inputNode)}
                        />
                    );
                    keyboard = this.getKeyboard({
                        nextEnabled,
                        disableAlphabets: true,
                        disableSymbols: true
                    });
                } else {
                    switch (questionModel.getCode()) {
                        case UserJourneyMap.questionCodes.weight:
                            question = (
                                <Weight
                                    key={questionModel.getQuestionId()}
                                    model={questionModel}
                                    errors={this.state.errors}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                    value={this.state.value}
                                    onValueChange={value => this.handleValueChange(value)}
                                />
                            );
                            break;
                        case UserJourneyMap.questionCodes.height:
                            question = (
                                <Height
                                    key={questionModel.getQuestionId()}
                                    model={questionModel}
                                    errors={this.state.errors}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                    value={this.state.value}
                                    onValueChange={value => this.handleValueChange(value)}
                                />
                            );
                            break;
                        default:
                            question = (
                                <Number
                                    key={questionModel.getQuestionId()}
                                    model={questionModel}
                                    errors={this.state.errors}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                    value={this.state.value}
                                    onValueChange={value => this.handleValueChange(value)}
                                />
                            );
                    }
                }

                break;

            case questionModel.isQuestionTypeDate():
                switch (questionModel.getCode()) {
                    case UserJourneyMap.questionCodes.dateOfBirth:
                        question = (
                            <DateOfBirth
                                key={questionModel.getQuestionId()}
                                id={"survey-question-" + questionModel.getQuestionId()}
                                model={questionModel}
                                errors={this.state.errors}
                                value={this.state.value}
                                autoFocus={true}
                                onValueChange={value => this.handleValueChange(value)}
                                touchKeyboard={this.props.touchKeyboard}
                                onInputFocus={inputNode => this.onInputFocus(inputNode)}
                            />
                        );
                        keyboard = this.getKeyboard({ nextEnabled });
                        break;

                    default:
                        question = (
                            <Date
                                minYear={1985}
                                maxYear={2018}
                                id={"survey-question-" + questionModel.getQuestionId()}
                                model={questionModel}
                                errors={this.state.errors}
                                value={this.state.value}
                                onValueChange={value => this.handleValueChange(value)}
                            />
                        );
                }

                break;

            case questionModel.isQuestionTypeBarcode() &&
                healthStationMode &&
                this.props.getComponentForQuestionType != null:
                question = this.props.getComponentForQuestionType(
                    {
                        survey: survey,
                        onScanCompleted: barcode => this.onScanCompleted(barcode),
                        onScanSkipped: () => this.onScanSkipped()
                    },
                    "BARCODE"
                );

                showNavigationUi = false;
                break;

            case questionModel.isQuestionTypeMultipleAnswer():
                question = (
                    <MultipleAnswer
                        key={questionModel.getQuestionId()}
                        model={questionModel}
                        id={"survey-question-" + questionModel.getQuestionId()}
                        onAnswersChange={a => this.handleMultipleAnswerChange(a)}
                    />
                );
                break;

            case questionModel.isQuestionTypeText():
            default:
                switch (questionModel.getCode()) {
                    case UserJourneyMap.questionCodes.scriptsNowProfileMedicareNumber:
                        question = (
                            <MedicareNumber
                                key={"medicare-number"}
                                title={questionModel.getDisplayText()}
                                onFocusChanged={inputNode => this.onInputFocus(inputNode)}
                                onValuesChanged={data => this.handleValueChange(data.combined)}
                                onNoMedicare={() => this.handleNextClick()}
                                errors={this.state.errors}
                            />
                        );
                        keyboard = this.getKeyboard({
                            nextEnabled,
                            disableAlphabets: true,
                            disableSymbols: true
                        });
                        break;

                    case UserJourneyMap.questionCodes.scriptsNowProfileMedicareExpiry:
                        question = (
                            <MedicareExpiry
                                key={"medicare-expiry"}
                                title={questionModel.getDisplayText()}
                                helpText={questionModel.getHelpText()}
                                onFocusChanged={inputNode => this.onInputFocus(inputNode)}
                                onValuesChanged={data => this.handleValueChange(data.combined)}
                                errors={this.state.errors}
                            />
                        );

                        keyboard = this.getKeyboard({
                            nextEnabled,
                            disableAlphabets: true,
                            disableSymbols: true
                        });
                        break;

                    case UserJourneyMap.questionCodes.scriptsNowProfileAddress:
                        question = (
                            <Address
                                key={"scripts-now-address"}
                                title={questionModel.getDisplayText()}
                                helpText={questionModel.getHelpText()}
                                iconType={questionModel.getQuestionIcon()}
                                onFocusChange={inputNode => this.onInputFocus(inputNode)}
                                onValueChange={data => this.handleValueChange(JSON.stringify(data, null, 2))}
                                errors={this.state.errors}
                            />
                        );

                        nextEnabled = this.addressNextEnabled(questionModel);
                        keyboard = this.getKeyboard({
                            nextEnabled,
                            disableAlphabets: false,
                            disableSymbols: false
                        });
                        break;

                    case UserJourneyMap.questionCodes.scriptsNowExitFeedback:
                        question = defaultText;
                        showNavigationUi = false;
                        keyboard = this.getKeyboard({
                            nextEnabled,
                            defaultEnterText: this.props.t("buttons.uc_submit")
                        });
                        break;

                    default:
                        question = defaultText;
                        keyboard = this.getKeyboard({ nextEnabled });
                }

                break;
        }

        const isHra = (
            this.props.model.getSurvey().getUserJourneyPage() || { getUserJourney: () => ({}) }
        ).getUserJourney().isHRA;
        if (isHra) {
            let HRAQuestion;
            switch (true) {
                case questionModel.isQuestionTypeChoice():
                    switch (questionModel.getCode()) {
                        default:
                            HRAQuestion = props => (
                                <HRAChoice
                                    {...props}
                                    model={questionModel}
                                    value={[this.state.value]}
                                    key={questionModel.getQuestionId()}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                />
                            );
                    }

                    break;
                case questionModel.isQuestionTypeNumber():
                    switch (questionModel.getCode()) {
                        case UserJourneyMap.questionCodes.weight:
                            HRAQuestion = props => (
                                <HRAWeight
                                    {...props}
                                    model={questionModel}
                                    value={this.state.value}
                                    key={questionModel.getQuestionId()}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                />
                            );
                            break;
                        case UserJourneyMap.questionCodes.height:
                            HRAQuestion = props => (
                                <HRAHeight
                                    {...props}
                                    model={questionModel}
                                    value={this.state.value}
                                    key={questionModel.getQuestionId()}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                />
                            );
                            break;
                        default:
                            HRAQuestion = props => (
                                <HRANumber
                                    {...props}
                                    model={questionModel}
                                    value={this.state.value}
                                    key={questionModel.getQuestionId()}
                                    id={"survey-question-" + questionModel.getQuestionId()}
                                />
                            );
                            break;
                    }
            }

            return (
                <Navigation
                    model={questionModel}
                    nextButtonDisabled={!nextEnabled}
                    backButtonDisabled={!backEnabled}
                    onBackClick={this.handleBackClick}
                    onNextClick={this.handleNextClick}
                    survey={this.props.model.getSurvey()}
                    onValueChange={this.handleValueChange}
                >
                    {props => (HRAQuestion ? <HRAQuestion {...props} /> : question)}
                </Navigation>
            );
        }

        return (
            <Root
                id={"surveyQuestionGroup" + this.props.model.getQuestionGroupId()}
                data-testid={"survey-question-group-container"}
                isKeyboardShown={!(keyboard == null)}
            >
                {question}
                {showNavigationUi && (
                    <NavigationBarContainer isKeyboardShown={!(keyboard == null)}>
                        <OneQuestionAtATimeNavigation
                            data-testid={"survey-question-group"}
                            survey={this.props.model.getSurvey()}
                            onBackClick={this.handleBackClick}
                            onNextClick={this.handleNextClick}
                            showNextButton={true}
                            showBackButton={true}
                            nextButtonDisabled={!nextEnabled}
                            backButtonDisabled={!backEnabled}
                            healthStationMode={healthStationMode}
                            className={"navigation-bar"}
                        />
                    </NavigationBarContainer>
                )}
                {keyboard}
            </Root>
        );
    }
}

export default withTranslation()(OneQuestionAtATime);
