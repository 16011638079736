import styled from "styled-components";

const KeyboardRoot = styled.div`
    margin: ${props => (props.margin ? props.margin : "0 auto")};
    width: 100%;
    max-width: ${props => (props.maxWidth ? props.maxWidth : "57em")};

    background: #f2f2f2;
    //box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .5);
    font-size: 22px;
    font-weight: 800;

    display: flex;
    align-items: stretch;
    flex-direction: column;
    flex: 1 1 auto;
`;

const KeyboardNumericRoot = styled(KeyboardRoot)`
    width: auto;
    min-width: 10em;
`;

const KeyboardRow = styled.div`
    display: flex;
    align-items: center;
    min-height: 20px;
    margin: 5px 0;
    height: 100%;
`;

// TODO: To have text overflow handled, I need to add a span with proper overflow attributes for button text
const KeyboardStyledButton = styled.button`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-basis: 5.5em;
    height: 100%;
    margin: 0 5px;
    border-radius: 5px;
    background-color: ${props => (props.backgroundColor ? props.backgroundColor : "#FFF")};
    color: ${props => (props.color ? props.color : props.theme.colours.flat.darkGray.hex)};
    border: 1px solid ${props => props.theme.colours.flat.gray.hex};
    font-size: 1.2em;

    filter: ${props => props.theme.shadows.rest};
    min-width: ${props => (props.minWidth ? props.minWidth : "auto")};
    min-height: 20px;
    user-select: none;

    :focus {
        outline: none;
    }

    :disabled {
        opacity: 0.4;
        cursor: default;
    }

    :active {
        background-color: #ccc;
    }

    overflow: hidden;
`;

export { KeyboardRoot, KeyboardNumericRoot, KeyboardRow, KeyboardStyledButton };
