import { Validation, DateValidator, NumberValidator } from "../Validation";
import InvalidArgumentError from "../Errors/InvalidArgumentError";
import moment from "moment";

export default class TypeConverter {
    /**
     * @param {string} dateString
     * @param {string} format moment supported format
     * @return {*}
     */
    static stringToDate(dateString, format) {
        const validation = new Validation();
        validation.addValidator(new DateValidator({ format }));

        const errors = validation.validate(dateString, true);
        if (errors.length) {
            throw new InvalidArgumentError("value [" + JSON.stringify(dateString) + "] can not be converted to a date");
        }

        return moment(dateString, format);
    }

    /**
     *
     * @param numberString
     * @return {number}
     */
    static stringToNumber(numberString) {
        const validation = new Validation();
        validation.addValidator(new NumberValidator());

        const errors = validation.validate(numberString, true);
        if (errors.length) {
            throw new InvalidArgumentError("value [" + numberString + "] can not be converted to a number");
        }

        return Number(numberString);
    }
}
