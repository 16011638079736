import React from "react";
import PropTypes from "prop-types";

const DottedBottomAxisTick = ({ format, value, x, y, marginTop, opacity }) => {
    const gStyle = { opacity };
    return (
        <g transform={`translate(${x},${y})`} style={gStyle}>
            <circle r={5} fill="lightgray" />
            <text
                textAnchor="middle"
                dominantBaseline="middle"
                transform={`translate(0,${y + marginTop})`}
                style={{
                    fontSize: 11
                }}
            >
                {format ? format(value) : value}
            </text>
        </g>
    );
};

DottedBottomAxisTick.propTypes = {
    format: PropTypes.func,
    value: PropTypes.any,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    marginTop: PropTypes.number,
    opacity: PropTypes.number
};

DottedBottomAxisTick.defaultProps = {
    marginTop: 22
};

export default DottedBottomAxisTick;
