import moment from "moment-timezone";

/**
 * Validates a given Medicare expiry date
 * Expected expiry date format: YYYY-MM
 *
 * @param {String} expiry
 * @param timezone
 * @return {boolean}
 * @private
 */
const validateExpiry = (expiry, timezone = "Australia/ACT") => {
    const expiryDate = moment.tz(expiry, timezone).format("YYYY-MM");
    const now = moment.tz(timezone).format("YYYY-MM");
    return /^\d{4}-\d{2}$/.test(expiry) && expiryDate != "Invalid Date" && expiryDate >= now;
};

/**
 * Validates a given Medicare card number
 * Expected card number format: 1234567890
 *
 * @param {String} cardNumber
 * @return {boolean}
 * @private
 */
const validateCardNumber = cardNumber => {
    if (!cardNumber) return false;

    const candidate = cardNumber.replace(/\s/g, ""); // remove any spaces in card number string

    if (candidate.length !== 10) return false;

    const matches = /^(?<identifier>[2-6]\d{7})(?<checksumDigit>\d)(?<issueNumber>\d)$/.exec(candidate);

    if (!matches) return false;

    const identifier = matches.groups.identifier;
    const checksumDigit = matches.groups.checksumDigit;
    const weights = [1, 3, 7, 9, 1, 3, 7, 9];

    let sum = 0;
    weights.forEach((weight, index) => (sum += parseInt(identifier[index], 10) * weight));

    return sum % 10 === parseInt(checksumDigit, 10);
};

/**
 * Validates a given Medicare card number and expiry date
 * Expected card number format: 1234567890
 * Expected expiry date format: YYYY-MM
 *
 * @param {String} cardNumber
 * @param {String} expiry
 * @param timezone
 * @return {boolean}
 * @private
 */
const validate = (cardNumber, expiry, timezone = "Australia/ACT") =>
    validateCardNumber(cardNumber) && validateExpiry(expiry, timezone);

const parseMedicareNumber = input => {
    const parts = /^(?<cardNumber>\d{10})-?(?<reference>\d)$/.exec(input);
    return parts
        ? { cardNumber: parts.groups.cardNumber, reference: parts.groups.reference }
        : { cardNumber: null, reference: null };
};

const reformExpiry = input => {
    const parts = /^(?<month>\d{2})[/-](?<year>\d{4})$/.exec(input);
    return parts ? `${parts.groups.year}-${parts.groups.month}` : input;
};

export default { validateExpiry, validateCardNumber, validate, parseMedicareNumber, reformExpiry };
