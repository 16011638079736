import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";
import { SET_DEVICE_LOCATION } from "constants/actions";

const initialState = {
    countryCode: "AU",
    isCountryUK: false
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_DEVICE_LOCATION: {
            return {
                ...state,
                countryCode: payload,
                isCountryUK: payload === COUNTRIES.UNITED_KINGDOM
            };
        }

        default: {
            return state;
        }
    }
};
