import React, { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Grommet, RadioButton as GrommetRadioButton } from "grommet";
import { Flex, Box } from "rebass";
const RadioButton = ({ name, options, defaultValue, onChange: onChangeProp, theme }) => {
    const [value, setValue] = useState(defaultValue);

    function onChange(value) {
        setValue(value);

        if (typeof onChangeProp == "function") {
            onChangeProp(value);
        }
    }

    useEffect(() => {
        onChange(value);
    }, []);

    const primaryColor = theme.colours.flat.blue.hex;
    const hoverColor = theme.colours.flat.lightBlue.hex;

    // eslint-disable-next-line react/prop-types
    function renderRadioButton({ label, value: optionValue }, index) {
        const key = `radio-${label}-input-${index}`.toLowerCase().replace(" ", "");

        return (
            <Box mr="1em" key={key} data-testid={key} onClick={() => onChange(optionValue)}>
                <GrommetRadioButton
                    key={`radio-button-${index}`}
                    checked={value === optionValue}
                    label={label}
                    name={name}
                />
            </Box>
        );
    }

    return (
        <Grommet
            plain={true}
            theme={{
                ...theme,
                global: {
                    colors: {
                        brand: primaryColor,
                        focus: primaryColor
                    }
                },
                radioButton: {
                    border: {
                        color: primaryColor
                    },
                    checked: {
                        color: primaryColor
                    },
                    hover: {
                        border: {
                            color: hoverColor
                        }
                    },
                    size: "30px"
                }
            }}
        >
            <Flex>{options.map(renderRadioButton)}</Flex>
        </Grommet>
    );
};

RadioButton.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired
        })
    ),
    defaultValue: PropTypes.any,
    onChange: PropTypes.func,
    theme: PropTypes.object.isRequired
};

export default withTheme(RadioButton);
