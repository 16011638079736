import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledPlainButton = styled.button`
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    font: inherit;
    -webkit-text-decoration: none;
    text-decoration: none;
    margin: 0;
    background: transparent;
    overflow: visible;
    text-transform: none;
    color: inherit;
    border: none;
    padding: 0;
    text-align: inherit;
    color: ${props => props.theme.colours.flat.blue.hex};

    span {
        vertical-align: middle;
    }
`;

const PlainButton = ({ children, ...props }) => (
    <StyledPlainButton {...props} type="button">
        {children}
    </StyledPlainButton>
);

PlainButton.propTypes = {
    children: PropTypes.node.isRequired
};

export default PlainButton;
