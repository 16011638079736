import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";

import { ConversionModalContext, ConversionModalProvider as Provider } from "components/Context";
import NotificationModal from "components/NotificationModal";
import { ExtraBreakpointWrapper } from "components/ExtraBreakpointWrapper";

import { useSelector } from "react-redux";
import { ModalContent } from "./modalContent";
import { PollingPage } from "./pollingPage";
import { CongratulationsModal } from "./congratulationsModal";

export const ConversionModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCongratsModalOpen, setIsCongratsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { citizen } = useSelector(state => state.auth);
    const { search, pathname } = useLocation();
    const history = useHistory();

    const onModalClose = () => {
        const isUpgradeViaQueryVisible = search && new URLSearchParams(search).get("modal") === "premium-upgrade";
        if (isUpgradeViaQueryVisible) {
            history.replace(pathname);
        }
        setIsOpen(false);
    };

    return (
        <>
            <Provider value={{ triggerModal: setIsOpen }}>{children}</Provider>

            <ExtraBreakpointWrapper screen="phablet" width="382px">
                <NotificationModal onClose={onModalClose} open={isOpen} width={["343px", "716px"]}>
                    {close => <ModalContent close={close} citizen={citizen} openModal={() => setIsLoading(true)} />}
                </NotificationModal>
            </ExtraBreakpointWrapper>

            <ExtraBreakpointWrapper screen="phablet" width="382px">
                <NotificationModal
                    onClose={() => setIsCongratsModalOpen(false)}
                    open={isCongratsModalOpen}
                    width={["343px", "716px"]}
                >
                    {close => <CongratulationsModal close={close} />}
                </NotificationModal>
            </ExtraBreakpointWrapper>

            {isLoading && (
                <PollingPage
                    citizen={citizen}
                    setIsLoading={setIsLoading}
                    setIsCongratsModalOpen={setIsCongratsModalOpen}
                />
            )}
        </>
    );
};

ConversionModalProvider.propTypes = {
    children: PropTypes.any
};

/**
 * @return {{ triggerModal: Function }}
 */
export const useConversionModal = () => useContext(ConversionModalContext);
