import i18next from "i18next";
import { useEffect, useMemo } from "react";

const FormLocalisationWrapper = ({ children, touched, setTouched }) => {
    const selectedLanguage = useMemo(() => {
        if (i18next.isInitialized) {
            return i18next.language;
        }
    }, [i18next.language]);

    /**
     * Touch the fields which have been previously used in the form to
     * re-render error messages in the selected language
     */
    useEffect(() => {
        if (touched && setTouched) {
            setTouched({ ...touched });
        }
    }, [selectedLanguage]);

    return children;
};

export default FormLocalisationWrapper;
