import codes from "../UserJourney/UserJourneyMap";

const medicalCategoryNames = {
    acne: "Acne",
    asthmaAndEmphysema: "Asthma & Emphysema",
    bladder: "Bladder",
    bloodPressure: "Blood Pressure",
    cholesterol: "Cholesterol",
    contraception: "Contraception",
    diabetes: "Diabetes",
    digestion: "Digestion",
    erectileDysfunction: "Erectile Dysfunction",
    gout: "Gout",
    hayfeverAndAllergy: "Hayfever & Allergy",
    inflammationAndPain: "Inflammation & Pain",
    malePatternBaldness: "Male Pattern Baldness",
    menopause: "Menopause",
    migraine: "Migraine",
    osteoporosis: "Osteoporosis",
    prostate: "Prostate",
    anticoagulant: "Anticoagulant",
    anticonvulsants: "Anticonvulsants"
};

const specifications = {
    clientCode: codes.clients.telstraHealthScriptsNow,
    surveyCodes: [
        {
            code: codes.surveyCodes.scriptsNowProfile,
            medicalCategoryName: null,
            medicalCategoryId: null,
            brandNameRegex: null,
            alwaysAsk: false
        },
        {
            code: codes.surveyCodes.scriptsNowGeneral,
            medicalCategoryName: "General Medical Questions",
            medicalCategoryId: "general",
            brandNameRegex: null,
            alwaysAsk: true,
            conditional: false
        },

        {
            code: codes.surveyCodes.scriptsNowAcne,
            medicalCategoryName: medicalCategoryNames.acne,
            medicalCategoryId: "dc5ab080-3b94-41c8-8feb-8b4c639673c4",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowAsthmaEmphysema,
            medicalCategoryName: medicalCategoryNames.asthmaAndEmphysema,
            medicalCategoryId: "a5a6c8e3-f826-47f2-be1d-ed33e2f64a16",
            brandNameRegex: /^((?!symbicort|seretide).)*$/i,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowSymbicortSeretide,
            medicalCategoryName: medicalCategoryNames.asthmaAndEmphysema,
            medicalCategoryId: "a5a6c8e3-f826-47f2-be1d-ed33e2f64a16",
            brandNameRegex: /(symbicort|seretide)/i,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowBaldness,
            medicalCategoryName: medicalCategoryNames.malePatternBaldness,
            medicalCategoryId: "05265760-f15a-48b1-bd41-6fbac08329f2",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowBladder,
            medicalCategoryName: medicalCategoryNames.bladder,
            medicalCategoryId: "2a67fd45-f285-4d1a-aa8e-60de1797f1af",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowBp,
            medicalCategoryName: medicalCategoryNames.bloodPressure,
            medicalCategoryId: "4f20cf03-7556-491d-98a0-3fa6f2808950",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowCholesterol,
            medicalCategoryName: medicalCategoryNames.cholesterol,
            medicalCategoryId: "c091c7c2-373b-48d3-b4f4-8c728cb413d0",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowContraception,
            medicalCategoryName: medicalCategoryNames.contraception,
            medicalCategoryId: "c05cfdc1-a975-4d1b-b007-0b10d1d2ac04",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowDiabetes,
            medicalCategoryName: medicalCategoryNames.diabetes,
            medicalCategoryId: "1b80c437-d8d1-4ea5-ba59-45b49f47418b",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowDigestion,
            medicalCategoryName: medicalCategoryNames.digestion,
            medicalCategoryId: "ba1973af-e4a7-4576-be8f-ac1585f71f63",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowEd,
            medicalCategoryName: medicalCategoryNames.erectileDysfunction,
            medicalCategoryId: "a4e701e7-3d01-4c93-ac89-c801b91a20e2",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowGout,
            medicalCategoryName: medicalCategoryNames.gout,
            medicalCategoryId: "9f0a7aff-6385-4f67-9134-20ca67568f1b",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowHayfever,
            medicalCategoryName: medicalCategoryNames.hayfeverAndAllergy,
            medicalCategoryId: "8f0eb7a3-5a70-4034-b178-c296188746b8",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowMenopause,
            medicalCategoryName: medicalCategoryNames.menopause,
            medicalCategoryId: "fb60a619-46ab-4997-92e5-4d3bd85bfe0d",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowMigraine,
            medicalCategoryName: medicalCategoryNames.migraine,
            medicalCategoryId: "d2e8b7bb-8881-4099-8d25-1591f868bbdb",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowOsteoporosis,
            medicalCategoryName: medicalCategoryNames.osteoporosis,
            medicalCategoryId: "6b808552-23e1-4983-89be-dcb9300e8b78",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowPain,
            medicalCategoryName: medicalCategoryNames.inflammationAndPain,
            medicalCategoryId: "fabdf6cb-8498-4313-87ac-51905a6af895",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowProstate,
            medicalCategoryName: medicalCategoryNames.prostate,
            medicalCategoryId: "fb127b94-895c-4848-80cc-8432d86457d5",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowAnticoagulant,
            medicalCategoryName: medicalCategoryNames.anticoagulant,
            medicalCategoryId: "8f1898ba-1d48-43e6-84f2-bb8dc4b55dc1",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        },
        {
            code: codes.surveyCodes.scriptsNowAnticonvulsants,
            medicalCategoryName: medicalCategoryNames.anticonvulsants,
            medicalCategoryId: "b2bd9865-7f6d-4991-b1b5-cfc8603ab4e8",
            brandNameRegex: null,
            alwaysAsk: false,
            conditional: true
        }
    ]
};

const pbsCategories = {
    pbs: {
        name: "Unrestricted Benefit",
        message:
            "This medication is listed under the government Pharmaceutical Benefits Scheme (PBS). To access this subsidy using Scripts Now you should provide your Medicare details in this digital consultation otherwise, your medication may not be subsidised."
    },
    nonPbs: {
        name: "Not Eligible",
        message: "This medication is not eligible under the Pharmaceutical Benefits Scheme"
    },
    pbsRestriction: {
        name: "Restricted Benefit",
        message:
            "This medication is listed under the government Pharmaceutical Benefits Scheme (PBS). To access this subsidy using Scripts Now you will require a short phone consultation after completing this digital consultation (free for Medicare cardholders) otherwise, your medication will not be subsidised."
    },
    authStreamlined: {
        name: "Authority Required- Streamlined",
        message:
            "This medication is listed under the government Pharmaceutical Benefits Scheme (PBS). To access this subsidy using Scripts Now you will require a short phone consultation after completing this digital consultation (free for Medicare cardholders) otherwise, your medication will not be subsidised."
    },
    pbsAuth: {
        name: "Authority Required",
        message:
            "This medication is listed under the government Pharmaceutical Benefits Scheme (PBS). To access this subsidy using Scripts Now you will require a short phone consultation after completing this digital consultation (free for Medicare cardholders) otherwise, your medication will not be subsidised."
    }
};

const scriptsNowChargesDetails = {
    //TODO: refactor to use below consultation charges
    doctorConsultationChargesWithoutTHC: 19.99,
    doctorConsultationChargesWithTHC: 40,

    regularConsultationCharges: 19.99,
    telehealthConsultationChargesWithoutER: 20,
    teleHealthConsultationCharges: 40,
    expressDeliveryCharges: 11.95,
    standardDeliveryCharges: 8.95,
    transactionCharges: 2.9
};

export const scriptsNowPharmacyAddress = {
    name: "Priceline Pharmacy Bourke Street Mall",
    email: "PPBSM.dispensary@p4h.net.au",
    faxNumber: "",
    address: "Shop 108 - 112 & 125 Centrepoint Building, Bourke St Mall, Melbourne, VIC, 3000, Australia",
    phoneNumber: "(03) 9654 2034"
};

export const klaviyoEventChannel = {
    portal: "portal",
    station: "station"
};

export default {
    specifications,
    medicalCategoryNames,
    pbsCategories,
    scriptsNowChargesDetails,
    scriptsNowPharmacyAddress
};
