import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import styled, { ThemeContext } from "styled-components";

// ----------------------------------------------------------------------------------------------

import { SquaredCard as Card, StatusCard } from "@sisuwellness/ui/src/components/Cards";
import { HeadingLabel, NormalLabel, StatusLabel } from "@sisuwellness/ui/src/components/Labels";
import Pie from "@sisuwellness/ui/src/components/Pie";
import { hexToRgba } from "utilities/commonUtils";

// ----------------------------------------------------------------------------------------------

const CustomLabel = styled(NormalLabel)`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const IndexCard = ({ title, progress, value, unit, text, indicator: { label, bg } }) => {
    const theme = useContext(ThemeContext);

    return (
        <Card bg="white" width="299px" height="233px" p="24px">
            <HeadingLabel fontSize="17px" mb="16px">
                {title}
            </HeadingLabel>
            <Flex justifyContent="space-between" alignItems="center" mb="16px">
                <Pie progress={progress} progressFgColor={bg} progressBgColor={hexToRgba(bg, 0.1)} size={77}>
                    <Flex flexWrap="nowrap" justifyContent="center" alignItems="baseline">
                        {value && (
                            <Text
                                mt="3px"
                                fontSize="22px"
                                textAlign="center"
                                lineHeight="1.25"
                                fontWeight="500"
                                color={theme.colours.flat.lightBlack.hex}
                            >
                                {value}
                            </Text>
                        )}
                        {unit && (
                            <Text
                                mt="3px"
                                fontSize="15px"
                                textAlign="center"
                                lineHeight="1.33"
                                color={theme.colours.blackTints400.hex}
                            >
                                {unit}
                            </Text>
                        )}
                    </Flex>
                </Pie>
                <StatusCard bg={bg}>
                    <StatusLabel color="white" fs={[14, 16]} mt="3px">
                        {label}
                    </StatusLabel>
                </StatusCard>
            </Flex>
            <CustomLabel fs={13} color={theme.colours.hpGrayishBlueText.hex}>
                {text}
            </CustomLabel>
        </Card>
    );
};

IndexCard.propTypes = {
    title: PropTypes.string,
    progress: PropTypes.number,
    unit: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    url: PropTypes.string,
    icon: PropTypes.string,
    showIcon: PropTypes.bool,
    priority: PropTypes.number,
    indicator: PropTypes.shape({
        fg: PropTypes.string,
        bg: PropTypes.string,
        label: PropTypes.string
    }),
    isRiskHigh: PropTypes.bool,
    value: PropTypes.any
};

export default IndexCard;
