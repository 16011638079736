import { UserProfile } from "../User";
import { pathOr } from "ramda";

export default class Auth {
    constructor(APIClient) {
        this.APIClient = APIClient;
    }

    authenticate(authToken) {
        this.APIClient.setAuthToken(authToken);
        return this._setValue("auth_token", authToken);
    }

    /**
     * Removes the stored auth token, effectively unauthenticating the client
     * @returns {Promise<boolean>}
     */
    unauthenticate() {
        return this._removeValue("auth_token");
    }

    /**
     * @returns {Promise<boolean>}
     */
    isAuthenticated() {
        let self = this;

        return this._getValue("auth_token").then(authToken => {
            if (authToken) {
                this.APIClient.setAuthToken(authToken);

                return this.APIClient.auth.status().then(response => {
                    if (!response.succeeded()) {
                        return self.unauthenticate().then(() => {
                            return false;
                        });
                    } else {
                        return true;
                    }
                });
            }

            return false;
        });
    }

    /**
     * @param {Object<string, any>} userProfile
     * @returns {Promise<boolean>}
     */
    isUserProfileCompleted(userProfile) {
        if (userProfile) {
            const userProfileUtility = new UserProfile(userProfile);
            const errors = userProfileUtility.validate(userProfile);
            return errors.length === 0;
        }
        return false;
    }

    /**
     * @param key
     * @protected
     * @returns {Promise<string>}
     */
    // eslint-disable-next-line
    _getValue(key) {
        throw new Error("Must implement in extending classes");
    }

    /**
     * @param key
     * @param value
     * @protected
     * @returns {Promise<boolean>}
     */
    // eslint-disable-next-line
    _setValue(key, value) {
        throw new Error("Must implement in extending classes");
    }

    /**
     * @param key
     * @protected
     * @returns {Promise<boolean>}
     */
    // eslint-disable-next-line
    _removeValue(key) {
        throw new Error("Must implement in extending classes");
    }

    /**
     *
     * @return {Promise<string|null>}
     */
    getUserUUID() {
        return this._getValue("auth_token").then(token => {
            let base64Url = token.split(".")[1];
            let base64 = base64Url.replace("-", "+").replace("_", "/");
            let payload = JSON.parse(window.atob(base64));
            return pathOr(pathOr(null, ["custom:uuid"], payload), ["data", "uuid"], payload);
        });
    }

    getAuthToken() {
        return this._getValue("auth_token");
    }
}
