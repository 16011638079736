import { Box, Flex, Text, Card } from "rebass";
import { mediaQueryForCss } from "utilities/mediaQuery";
import styled from "styled-components";
import { SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";

//........................................................

export const GridLayout = styled(Flex)`
    width: 100%;
    display: grid;
    row-gap: 40px;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 346px);
    ${mediaQueryForCss({
        "row-gap": ["", "", "45px"]
    })}
`;

export const BtnContainer = styled(Box)`
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const Heading = styled(Text)`
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
`;

export const StationCard = styled(Card)`
    background-color: ${props => props.theme.portalColor.hpCardBackground.hex}
    position: relative;
    min-width:346px
    height: 388px !important
`;

export const StyledButton = styled(SecondaryButton)`
    font-weight: ${props => props.theme.fontWeight.intermediate};
`;

export const BroughtToByCard = styled(Flex)`
    height: 72px;
    width: 346px;
    border-radius: 8px;
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.theme.portalColor.hpCardBackground.hex};
`;
