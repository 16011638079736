import React, { useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";

import AutoSuggest from "react-autosuggest";
import { Collapse } from "react-bootstrap";

// ----------------------------------------------------------------------------

import { ErrorText, InputContainer, InputFieldTheme, LeftChild, RightChild, Container } from "./styled-components";
import { isEmpty } from "ramda";

// ----------------------------------------------------------------------------

const InputField = ({
    id,
    inputProps,
    leftChild,
    rightChild,
    label,
    className,
    error,
    forwardRef,
    autoSuggest,
    fontFamily,
    handleOnSuggestionsFetch,
    handleGetSuggestionValue,
    handleRenderSuggestion,
    children,
    ...rest
}) => {
    const [suggestions, setSuggestions] = useState([]);

    useImperativeHandle(forwardRef, () => {
        return forwardRef.current ? forwardRef.current.input : forwardRef.current;
    });

    const handleSuggestionsClear = () => setSuggestions([]);

    const onSuggestionsFetch = ({ value }) => handleOnSuggestionsFetch(value, setSuggestions);

    const isError = Array.isArray(error) ? !isEmpty(error) : Boolean(error);

    return (
        <Container fontFamily={fontFamily ? fontFamily : "Open Sans"}>
            <InputContainer
                p="10px"
                mb="16px"
                bg="white"
                width="100%"
                label={label}
                height="50px"
                className={className}
                error={isError}
                {...rest}
            >
                {leftChild && <LeftChild>{leftChild}</LeftChild>}
                {children}
                <AutoSuggest
                    ref={forwardRef}
                    id={id ? id : "input-field"}
                    theme={InputFieldTheme(Boolean(children), suggestions ? suggestions.length : [])}
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={autoSuggest ? onSuggestionsFetch : () => {}}
                    onSuggestionsClearRequested={autoSuggest ? handleSuggestionsClear : () => {}}
                    alwaysRenderSuggestions
                    getSuggestionValue={autoSuggest ? handleGetSuggestionValue : () => {}}
                    renderSuggestion={autoSuggest ? handleRenderSuggestion : () => {}}
                    inputProps={{ ...inputProps, autoComplete: "off", autoFocus: true }}
                />
                {rightChild && <RightChild>{rightChild}</RightChild>}
            </InputContainer>
            <Collapse in={isError} unmountOnExit>
                <ErrorText>{error}</ErrorText>
            </Collapse>
        </Container>
    );
};

InputField.propTypes = {
    inputProps: PropTypes.shape({
        value: PropTypes.string.isRequired
    }),
    id: PropTypes.any,
    label: PropTypes.string,
    leftChild: PropTypes.any,
    rightChild: PropTypes.any,
    customStyles: PropTypes.any,
    className: PropTypes.string,
    fontFamily: PropTypes.string,
    theme: PropTypes.object,
    forwardRef: PropTypes.object,
    autoSuggest: PropTypes.bool.isRequired,
    handleOnSuggestionsFetch: PropTypes.func,
    handleGetSuggestionValue: PropTypes.func,
    handleRenderSuggestion: PropTypes.func,
    error: PropTypes.any,
    children: PropTypes.any
};

InputField.defaultProps = {
    autoSuggest: false
};

export default React.forwardRef((props, ref) => <InputField {...props} forwardRef={ref} />);
