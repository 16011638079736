import BaseRule from "./BaseRule";
import AnswerRegexRule from "./Types/AnswerRegexRule";
import AnswerNumberRule from "./Types/AnswerNumberRule";

export default class RuleSet extends BaseRule {
    /**
     * Adds a rule to the rule set
     *
     * @param rule
     * @return {RuleSet}
     */
    addRule(rule) {
        this._rules.push(rule);
        return this;
    }

    /**
     * if any rule passes, then the set passes.
     */
    matches() {
        let matches = this._rules.length > 0 ? false : this._options.noRulesResult;

        let totalRuleCount = this._rules.length;
        if (totalRuleCount > 0) {
            let successRuleCount = 0;
            for (let rule of this._rules) {
                if (rule.matches()) {
                    successRuleCount++;
                }
            }

            // all rules where found to match
            matches = totalRuleCount === successRuleCount;
        }

        return matches;
    }

    /**
     *
     * @param {{questionCode: string, minimum: Number, maximum: Number, matches: string[]}[]} rulesSchema
     * @param response
     * @param options
     * @returns {RuleSet}
     */
    static createFromAnswerRuleSchema(rulesSchema, response, options = {}) {
        const ruleSet = new RuleSet(Object.assign(options, { response }));

        if (rulesSchema) {
            for (let schema of rulesSchema) {
                if (
                    (schema.hasOwnProperty("minimum") && schema.minimum) ||
                    (schema.hasOwnProperty("maximum") && schema.maximum)
                ) {
                    ruleSet.addRule(
                        new AnswerNumberRule({
                            response,
                            questionCode: schema.questionCode,
                            minimum: schema.hasOwnProperty("minimum") ? schema.minimum : null,
                            maximum: schema.hasOwnProperty("maximum") ? schema.maximum : null
                        })
                    );
                }

                if (schema.hasOwnProperty("matches") && schema.matches) {
                    ruleSet.addRule(
                        new AnswerRegexRule({
                            response,
                            questionCode: schema.questionCode,
                            regularExpressions: schema.matches
                        })
                    );
                }
            }
        }

        return ruleSet;
    }
}
