import * as yup from "yup";

/**
 * Returns a yup-created validation schema for the questions array
 * @return {*}
 * @constructor
 */
const QUESTIONS_VALIDATION_SCHEMA = yup
    .array()
    .of(
        yup
            .object()
            .shape({
                questionCode: yup.string(),
                answers: yup.array().of(
                    yup.object().shape({
                        answer: yup.string().required()
                    })
                )
            })
            .required()
    )
    .required();

export { QUESTIONS_VALIDATION_SCHEMA };
