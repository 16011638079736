export default class BaseConfiguration {
    constructor(config) {
        this._configuration = config;
    }

    /* istanbul ignore next */
    get campaignId() {
        throw new Error("getCampaignId() must be implemented");
    }

    get raw() {
        return this._configuration;
    }
}
