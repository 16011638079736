import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import styled from "styled-components";
import { compose, curry, uniqBy, __ } from "ramda";

// --------------------------------------------------------------------

import Icons from "components/Icons";
import { FILTER_ALL_TIME, FILTER_ONE_MONTH, FILTER_ONE_YEAR } from "constants/trend-charts";
import { formatDate } from "utilities/commonUtils";
import { flattenPointValues } from "../utils";
import { withDimensions } from "components/HOC";
import { isMobileView } from "../metricSpecsheet";

// --------------------------------------------------------------------

const StyledIcons = styled(Icons)`
    cursor: pointer;
    visibility: ${({ visible }) => (visible ? "unset" : "hidden")};

    transition: visibility 0.2s linear;
`;

/**
 * String format function
 * @param {string} format
 * @param {Array} data
 * @return {string}
 */
export const uniqeFunction = (format, date) => formatDate(date, format);

/**
 *
 * @param {Array<object>} data
 * @param {string} filterType
 * @return {Array<object>}
 */
export const filterByUniqueDates = (data, filterType) => {
    switch (filterType) {
        case FILTER_ONE_MONTH:
            return compose(
                uniqBy(curry(uniqeFunction)("MM/YYYY")),
                curry(flattenPointValues)(__, "dateCreated")
            )(data);

        case FILTER_ALL_TIME:
        case FILTER_ONE_YEAR:
            return compose(
                uniqBy(curry(uniqeFunction)("YYYY")),
                curry(flattenPointValues)(__, "dateCreated")
            )(data);
    }
};

/**
 * Construct Navigation name based on date
 * @param {Array} list
 * @param {number} activeIndex
 * @param {string} filterType
 * @return {string}
 */
const getNavigationText = (list, activeIndex, filterType) => {
    const date = list[activeIndex];
    switch (filterType) {
        case FILTER_ONE_MONTH:
            return formatDate(date, "MMMM YYYY");

        case FILTER_ONE_YEAR:
            return formatDate(date, "YYYY");

        case FILTER_ALL_TIME: {
            return "";
        }
    }
};

const TrendNavigation = ({ setActiveDate, currentFilter, data, dimension: [width], ...rest }) => {
    const [navigationList, setNavigationList] = useState([]);
    const [activeNavigationIndex, setActiveNavigationIndex] = useState(0);

    const hasNext = activeNavigationIndex - 1 >= 0;
    const hasPrev = activeNavigationIndex + 1 < navigationList.length;

    const navigationText = getNavigationText(navigationList, activeNavigationIndex, currentFilter);

    useEffect(() => {
        setActiveNavigationIndex(0);
    }, [currentFilter]);

    useEffect(() => {
        setNavigationList(filterByUniqueDates(data, currentFilter).sort((a, b) => b.localeCompare(a)));
    }, [currentFilter, data]);

    useEffect(() => {
        const date = navigationList[activeNavigationIndex];
        setActiveDate(date);
    }, [activeNavigationIndex, navigationList]);

    const next = () => setActiveNavigationIndex(index => (index - 1 >= 0 ? index - 1 : index));
    const prev = () => setActiveNavigationIndex(index => (index + 1 < navigationList.length ? index + 1 : index));

    const isNotAllTimeFilter = currentFilter !== FILTER_ALL_TIME;

    const iconSize = isMobileView(width) ? "20" : "40";

    return (
        <Flex width="100%" justifyContent="space-between" alignItems="center" mt="24px" {...rest}>
            <StyledIcons
                left
                chevron
                onClick={prev}
                width={iconSize}
                height={iconSize}
                data-testid="left-nav"
                visible={isNotAllTimeFilter && hasPrev}
            />

            <Text fontSize={["12px", "16px"]} color="hpPrimaryPurple" fontWeight="intermediate">
                {navigationText}
            </Text>

            <StyledIcons
                right
                chevron
                onClick={next}
                width={iconSize}
                height={iconSize}
                data-testid="right-nav"
                visible={isNotAllTimeFilter && hasNext}
            />
        </Flex>
    );
};

TrendNavigation.propTypes = {
    data: PropTypes.array,
    dimension: PropTypes.array,
    currentFilter: PropTypes.string,
    setActiveDate: PropTypes.func
};

export default withDimensions(TrendNavigation);
