import styled, { withTheme } from "styled-components";
import Icon from "../Icons/Icon";
import React from "react";
import PropTypes from "prop-types";
import FlexBase from "./FlexBase";
import withPrintable from "../withPrintable";
import { compose } from "ramda";

const Extremity = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: auto;
    width: 75px;
    min-width: 75px;
    max-width: 75px;
`;

const StyledIconContainer = styled(Extremity)`
    align-items: ${props => (props.tall ? "flex-start" : "center")};
    padding-top: ${props => (props.tall ? "1.25em" : 0)};
    background: ${props => props.iconBackground};
    border-radius: ${props => {
        let borderRadius = props.theme.defaults.borderRadius + " 0 0  " + props.theme.defaults.borderRadius;
        if (props.reverse) {
            borderRadius = "0 " + props.theme.defaults.borderRadius + " " + props.theme.defaults.borderRadius + " 0";
        }
        return borderRadius;
    }};
    ${props =>
        props.printable &&
        `${props.reverse ? "border-left" : "border-right"}: 1px ${props.theme.colours.flat.gray.hex} solid`};
`;

const StyledStatsContainer = styled.div`
    display: flex;
    align-items: ${props =>
        props.containerFlexAlign ? props.containerFlexAlign : props.tall ? "flex-start" : "center"};
    justify-content: flex-start;
    flex-grow: 6;
    flex-basis: auto;
    * {
        ${props => (props.printable ? `color: ${props.theme.colours.flat.black.hex} !important` : "")}
    }
`;

const InnerContainer = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    padding: 1.25em;
    display: flex;
    align-items: center;
`;

const FlexCardWithIcon = ({
    iconColour,
    theme,
    reverse,
    tall,
    iconComponent,
    iconBackground,
    iconType,
    children,
    containerFlexAlign,
    printable,
    ...rest
}) => {
    return (
        <FlexBase reverse={reverse} tall={tall} printable={printable} {...rest}>
            <StyledIconContainer
                iconBackground={printable ? theme.colours.flat.white.hex : iconBackground}
                reverse={reverse}
                tall={tall}
                printable={printable}
            >
                {iconComponent || (
                    <Icon
                        type={iconType}
                        colour={printable ? theme.colours.flat.black.hex : iconColour || theme.colours.flat.white.hex}
                    />
                )}
            </StyledIconContainer>
            <StyledStatsContainer tall={tall} containerFlexAlign={containerFlexAlign} printable={printable}>
                {children}
            </StyledStatsContainer>
        </FlexBase>
    );
};

FlexCardWithIcon.displayName = "Flex Base with Icon";

FlexCardWithIcon.defaultProps = {
    reverse: false,
    tall: false
};

FlexCardWithIcon.propTypes = {
    children: PropTypes.any,
    theme: PropTypes.object.isRequired,
    iconComponent: PropTypes.node,
    iconType: PropTypes.string.isRequired,
    iconColour: PropTypes.string,
    iconBackground: PropTypes.string.isRequired,
    reverse: PropTypes.bool,
    tall: PropTypes.bool,
    containerFlexAlign: PropTypes.string,
    printable: PropTypes.bool
};

const themedFlexBase = compose(
    withTheme,
    withPrintable
)(FlexCardWithIcon);

export { themedFlexBase as default, InnerContainer };
