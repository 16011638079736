import { BLOOD_PRESSURE, BODY_MASS_INDEX, SMOKING } from "constants/trend-charts";
import { theme } from "@sisuwellness/web-components";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import { PORTAL_IMAGE } from "constants/imagePath";
import { fixedNumber } from "utilities/commonUtils";
import { t } from "i18next";

/**
 * Get metric details specifically for heart age page
 * @param {string} type
 * @param {object} datum
 * @return {{} | { icon: string, title: string, value: string | number, unit: string, label: string, color: string, recommendation: string}}
 */
export const getMetricDetails = (type, datum) => {
    const { themeKey } = METRIC_INDICATOR[type] || {};

    // CONTINUE HERE

    const themeColor = theme.guidelines[themeKey];

    const { key = "" } = calculatePointColor(type, datum) || {};

    switch (type) {
        case BODY_MASS_INDEX: {
            const title = t("words.bmi");
            let metric = {};

            const { bmi } = datum;

            switch (key) {
                case "low":
                    metric = {
                        label: t("ranges.low"),
                        color: themeColor.low.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.bmi.low"
                        )
                    };
                    break;

                case "normal":
                    metric = {
                        label: t("ranges.normal"),
                        color: themeColor.normal.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.bmi.normal"
                        )
                    };
                    break;

                case "high":
                case "elevated":
                    metric = {
                        label: t("ranges.intermediate"),
                        color: themeColor.elevated.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.bmi.intermediate"
                        )
                    };
                    break;

                case "veryHigh":
                    metric = {
                        label: t("ranges.very_high"),
                        color: themeColor.high.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.bmi.very_high"
                        )
                    };
                    break;
            }

            return {
                ...metric,
                title,
                unit: t("units.uc_bmi"),
                value: fixedNumber(bmi),
                icon: `${PORTAL_IMAGE}/healthHub/bmi.svg`
            };
        }

        case BLOOD_PRESSURE: {
            const title = t("words.blood_pressure");
            let metric = {};

            const { sys, dia } = datum;

            switch (key) {
                case "optimal":
                    metric = {
                        label: t("ranges.optimal"),
                        color: themeColor.optimal.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.blood_pressure.optimal"
                        )
                    };
                    break;

                case "normal":
                    metric = {
                        label: t("ranges.normal"),
                        color: themeColor.normal.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.blood_pressure.normal"
                        )
                    };
                    break;

                case "mildHypertension":
                case "highNormal":
                    metric = {
                        label: t("ranges.high_normal"),
                        color: themeColor.highNormal.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.blood_pressure.high_normal"
                        )
                    };
                    break;

                case "severeHypertension":
                case "moderateHypertension":
                    metric = {
                        label: t("ranges.high"),
                        color: themeColor.moderateHypertension.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.blood_pressure.high"
                        )
                    };
                    break;
            }
            return {
                ...metric,
                title,
                unit: t("units.mmhg"),
                value: `${sys}/${dia}`,
                icon: `${PORTAL_IMAGE}/healthHub/bloodpressure.svg`
            };
        }

        case SMOKING: {
            const title = "Smoking";
            let metric = {};

            const { isSmoker } = datum;

            switch (key) {
                case "normal":
                    metric = {
                        label: t("words.great"),
                        color: themeColor.normal.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.smoking.great"
                        )
                    };
                    break;

                case "high":
                    metric = {
                        label: t("words.risk"),
                        color: themeColor.high.sisuPortal.hex,
                        recommendation: t(
                            "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.smoking.risk"
                        )
                    };
                    break;
            }
            return {
                ...metric,
                title,
                value: isSmoker ? t("words.yes") : t("words.not_smoking"),
                icon: `${PORTAL_IMAGE}/healthHub/smoking.svg`
            };
        }

        case "DIABETIC": {
            const title = t("words.diabetes");
            let metric = {};

            const { hasDiabetes } = datum;

            const isDiabetic = hasDiabetes === "diabetic";

            if (isDiabetic)
                metric = {
                    label: t("ranges.high_risk"),
                    color: theme.colours.hpPrimaryRed.hex,
                    recommendation: t(
                        "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.diabetic.high_risk"
                    )
                };
            else
                metric = {
                    label: t("ranges.normal"),
                    color: theme.colours.hpGreen.hex,
                    recommendation: t(
                        "members_portal_web.views.heart_age.page.affects.utilities.metrics.recommendations.diabetic.normal"
                    )
                };

            return {
                ...metric,
                title,
                value: isDiabetic ? t("words.diagnosed") : t("words.no"),
                icon: `${PORTAL_IMAGE}/premium/diabetic.svg`
            };
        }

        default:
            return {};
    }
};
