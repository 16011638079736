import { averageJoeBmiLog, averageJoeHasDiabetes, averageJoeSmoker, averageJoeSystolicLog } from "./constants";

export default ({
    coeffHaSys,
    coeffHaSmoke,
    coeffHaBmi,
    coeffHaDiab,
    haConst,
    haConstiDenom,
    haExpo,
    haConstiNumDiv
}) => tenYearCvdRisk => {
    const haConstiNum = Math.exp(
        -(
            averageJoeSystolicLog * coeffHaSys +
            averageJoeSmoker * coeffHaSmoke +
            averageJoeBmiLog * coeffHaBmi +
            averageJoeHasDiabetes * coeffHaDiab -
            haConst
        ) / haConstiNumDiv
    );

    const heartAgeConsti = haConstiNum * (1 / haConstiDenom);

    const haTerm = Math.pow(-Math.log(1 - tenYearCvdRisk), haExpo);

    const heartAge = heartAgeConsti * haTerm;

    return heartAge >= 85 ? 85 : Math.round(heartAge);
};
