import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Keyboard from "./base/Keyboard";
import LatinLayout from "./base/layouts/LatinLayout";
import KeyboardButton from "./base/KeyboardButton";
import { Style as KeyboardStyle } from "./base/Keyboard";
import "./base/Keyboard.css"; // TODO: use styled-component
import { withTranslation } from "react-i18next";

class GeneralKeyboard extends PureComponent {
    static propTypes = {
        inputNode: PropTypes.any,
        onClick: PropTypes.func,
        onSubmit: PropTypes.func,
        onBack: PropTypes.func,
        t: PropTypes.func
    };

    constructor(props) {
        super(props);
    }

    onSubmit(event) {
        if (this.props.onSubmit) {
            this.props.onSubmit(event, this.props.inputNode);
        }
    }

    onBack(event) {
        if (this.props.onBack) {
            this.props.onBack(event, this.props.inputNode);
        }
    }

    render() {
        return (
            <Keyboard
                style={KeyboardStyle.genericTouch}
                isNumeric={false}
                inputNode={this.props.inputNode}
                leftButtons={[
                    <KeyboardButton onClick={e => this.onBack(e)} value={this.props.t("words.back")} key="BackButton" />
                ]}
                rightButtons={[
                    <KeyboardButton
                        onClick={e => this.onSubmit(e)}
                        value={this.props.t("words.submit")}
                        classes="keyboard-submit-button"
                        key="SubmitButton"
                    />
                ]}
                onClick={this.props.onClick}
                layouts={[LatinLayout]}
            />
        );
    }
}

export default withTranslation()(GeneralKeyboard);
