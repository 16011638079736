import React from "react";
import { Route } from "react-router-dom";
import Auth from "utilities/auth";
import PropTypes from "prop-types";
import FlowState from "@sisuwellness/utilities/User/FlowState";
import CompleteUserProfileView from "views/CompleteUserProfile";
import { useTranslation } from "react-i18next";
import withAuthentication from "components/withAuthentication";
import { useSelector } from "react-redux";

const PrivateRoute = ({ viewComponent: ViewComponent, location, ...rest }) => {
    const { citizen: { userProfile } = {} } = useSelector(state => state.auth);
    const { t: Translator } = useTranslation();
    const flowState = Auth.isUserProfileCompleted(userProfile)
        ? FlowState.FLOW_STATE_USER_AUTHENTICATED
        : FlowState.FLOW_STATE_USER_PROFILE_INCOMPLETE;

    if (FlowState.userProfileIncomplete(flowState)) {
        // force the user to complete their user profile
        return <CompleteUserProfileView manage={false} t={Translator} />;
    } else if (FlowState.userIsAuthenticated(flowState)) {
        // display the authenticated route
        return <Route location={location} {...rest} component={ViewComponent} />;
    } else {
        return null;
    }
};

PrivateRoute.propTypes = {
    location: PropTypes.object,
    viewComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired
};

export default withAuthentication(PrivateRoute);
