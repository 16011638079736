import React from "react";
import { theme } from "../../theme";

import { SecondaryButton as SecondaryBtn } from "./styled";

const SecondaryButton = ({
    bg,
    color,
    disabled,
    children,
    disabledBg,
    backgroundColor,
    borderColor,
    disabledBorderColor,
    disabledColor = theme.primaryBtn.disabledColor,
    ...rest
}) => (
    <SecondaryBtn
        disabled={disabled}
        color={disabled ? disabledColor : color}
        bg={disabled ? disabledBg : bg || backgroundColor}
        borderColor={disabled ? disabledBorderColor || disabledColor : borderColor}
        {...rest}
    >
        {children}
    </SecondaryBtn>
);

SecondaryButton.propTypes = {
    ...SecondaryBtn.propTypes
};

SecondaryButton.defaultProps = {
    borderWidth: "1px",
    borderStyle: "solid",
    width: theme.secondaryBtn.width,
    height: theme.secondaryBtn.height,
    borderColor: theme.secondaryBtn.borderColor,
    borderRadius: theme.secondaryBtn.borderRadius,
    backgroundColor: theme.secondaryBtn.backgroundColor
};

export default SecondaryButton;
