import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledButton = styled.button`
    font-size: ${props => props.theme.text.heading4.size};
    background-color: ${props => props.theme.colours.flat.white.hex};
    color: ${props => props.theme.colours.flat.blue.hex};
    border-radius: 0.2em;
    ${props => props.theme.shadowCss.buttonRest()};
    padding: 0.5em 1.2em;
    min-width: 6em;
    border: 3px solid ${props => props.theme.colours.flat.blue.hex};

    :hover {
        color: ${props => props.theme.colours.flat.darkBlue.hex};
        border-color: ${props => props.theme.colours.flat.darkBlue.hex};
        ${props => props.theme.shadowCss.buttonElevated()};
    }

    :active {
        color: ${props => props.theme.colours.flat.darkBlue.hex};
        border-color: ${props => props.theme.colours.flat.darkBlue.hex};
        ${props => props.theme.shadowCss.buttonElevated()};
    }

    :focus {
        outline: none;
    }
`;

class SecondaryAction extends Component {
    constructor(props) {
        super(props);

        this.handleOnClick = this.handleOnClick.bind(this);
    }

    handleOnClick() {
        this.props.onClick();
    }

    render() {
        return <StyledButton onClick={this.handleOnClick}>{this.props.children}</StyledButton>;
    }
}

SecondaryAction.displayName = "Primary Button";
SecondaryAction.propTypes = {
    children: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default styled(SecondaryAction)``;
