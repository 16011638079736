import { Calendar, Check, Home, List, Map, TextBook } from "./Icon";
import { Field, FieldTitle, LeftChild, LeftNavContainer, StyledLink, Title } from "./styled-components";
import React from "react";
import routes from "constants/routes";
import { useSelector } from "react-redux";
import { pickAttribute } from "utilities/commonUtils";
import ExtraBreakpointWrapper from "components/ExtraBreakpointWrapper";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line react/prop-types
const ResponsiveStyledLink = ({ children, ...rest }) => (
    <ExtraBreakpointWrapper screen="macAndTab" pl="19px">
        <StyledLink {...rest}>{children}</StyledLink>
    </ExtraBreakpointWrapper>
);

// eslint-disable-next-line react/prop-types
const ResponsiveFieldTitle = ({ text }) => (
    <ExtraBreakpointWrapper screen="macAndTab" fontSize="12px">
        <FieldTitle>{text}</FieldTitle>
    </ExtraBreakpointWrapper>
);

const LeftNavBar = ({ position = "fixed", ...rest }) => {
    const { t } = useTranslation();
    const { citizen } = useSelector(state => state.auth);
    const isHraTaken = Number.isFinite(pickAttribute(["analytics", "healthRiskScore"], citizen));

    return (
        <ExtraBreakpointWrapper screen="macAndTab" width="200px">
            <LeftNavContainer
                height="100%"
                zIndex="2"
                overflow="hidden"
                width={["100%", "230px"]}
                position={position}
                {...rest}
                data-testid="left-nav"
            >
                <ExtraBreakpointWrapper screen="macAndTab" margin="29px 0px 12px 19px" fontSize="14px">
                    <Title margin="29px 0px 14px 24px" data-testid="title" fontSize="15px">
                        {t("members_portal_web.components.left_nav_bar.title")}
                    </Title>
                </ExtraBreakpointWrapper>
                <ResponsiveStyledLink exact to={routes.healthHub} data-testid="dashboard">
                    <Field>
                        <LeftChild>
                            <Home />
                        </LeftChild>
                        <ResponsiveFieldTitle text={t("members_portal_web.components.left_nav_bar.my_dashboard")} />
                    </Field>
                </ResponsiveStyledLink>

                <ResponsiveStyledLink to={routes.recommendations} data-testid="recommendation">
                    <Field>
                        <LeftChild>
                            <List />
                        </LeftChild>
                        <ResponsiveFieldTitle text={t("members_portal_web.components.left_nav_bar.recommendations")} />
                    </Field>
                </ResponsiveStyledLink>

                {/* Temporarily disable the HRA until it can be translated */}
                {/* <ResponsiveStyledLink
                    isActive={(_, location) =>
                        [routes.hraResult, routes.healthRiskOnboarding, routes.healthRiskAssessment].includes(
                            location.pathname
                        )
                    }
                    to={isHraTaken ? routes.hraResult : routes.healthRiskOnboarding}
                    data-testid="health-risk"
                >
                    <Field>
                        <LeftChild>
                            <Check />
                        </LeftChild>
                        <ResponsiveFieldTitle text={t("members_portal_web.components.left_nav_bar.health_score")} />
                    </Field>
                </ResponsiveStyledLink> */}

                <ResponsiveStyledLink to={routes.improve} data-testid="plans">
                    <Field>
                        <LeftChild>
                            <TextBook />
                        </LeftChild>
                        <ResponsiveFieldTitle text={t("members_portal_web.components.left_nav_bar.online_plans")} />
                    </Field>
                </ResponsiveStyledLink>
                <ResponsiveStyledLink to={routes.healthChecks} data-testid="history">
                    <Field>
                        <LeftChild>
                            <Calendar />
                        </LeftChild>
                        <ResponsiveFieldTitle text={t("members_portal_web.components.left_nav_bar.past_checks")} />
                    </Field>
                </ResponsiveStyledLink>
                <ResponsiveStyledLink to={routes.findHealthStations} data-testid="find-station">
                    <Field>
                        <LeftChild>
                            <Map />
                        </LeftChild>
                        <ResponsiveFieldTitle text={t("members_portal_web.components.left_nav_bar.find_sisu")} />
                    </Field>
                </ResponsiveStyledLink>
            </LeftNavContainer>
        </ExtraBreakpointWrapper>
    );
};

LeftNavBar.propTypes = {
    ...LeftNavContainer.propTypes
};

LeftNavBar.defaultProps = {
    minHeight: "760px"
};

export default LeftNavBar;
