import BaseEndPoint from "../BaseEndPoint";

export default class DoctorsOnDemand extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/doctors-on-demand/" + path);
    }

    getMedications() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("medications"), settings);
    }

    getPharmacies() {
        let settings = this._getDefaultFetchSettings();
        settings = Object.assign(settings, { method: "GET" });

        return this._send(this.getUrlPath("pharmacies"), settings);
    }

    createQuickScript(responseId) {
        return this.post("create-quick-script", { responseId });
    }
}
