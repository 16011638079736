import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

// ----------------------------------------------------------------------------

import { ModalBody, customStyles } from "./styled";
import withDimensions from "components/HOC/withDimensions";

// ----------------------------------------------------------------------------

const MOBILE_VIEWPORT_BREAKPOINT = 376;
const SMALLER_TABLET_VIEWPORT_BREAKPOINT = 601;
const BIGGER_TABLET_VIEWPORT_BREAKPOINT = 769;
const DESKTOP_VIEWPORT_BREAKPOINT = 992;

export const Popup = withDimensions(({ style, children, modalIsOpen, closeModal, dimension, ...rest }) => {
    const [custom, setCustom] = useState({});

    const [width] = dimension;

    useEffect(() => {
        if (style) {
            const temp = {};
            const index =
                width < MOBILE_VIEWPORT_BREAKPOINT
                    ? 3
                    : width < SMALLER_TABLET_VIEWPORT_BREAKPOINT
                    ? 2
                    : width < BIGGER_TABLET_VIEWPORT_BREAKPOINT
                    ? 1
                    : 0;
            Object.keys(style).forEach(key => (temp[key] = style[key][index]));
            setCustom(temp);
        } else {
            setCustom({ width: width < 600 ? "80%" : "50%" });
        }
    }, [width]);

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={
                style
                    ? customStyles(custom)
                    : customStyles({ width: width > DESKTOP_VIEWPORT_BREAKPOINT ? "50%" : "95%" })
            }
            ariaHideApp={false}
            {...rest}
            data-testid="portal-modal"
        >
            <ModalBody>{children}</ModalBody>
        </Modal>
    );
});

Popup.propTypes = {
    style: PropTypes.object,
    children: PropTypes.any,
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    dimension: PropTypes.array
};

export default Popup;
