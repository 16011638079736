import { useCallback, useEffect, useRef } from "react";
import throttle from "lodash/throttle";
import { COUNTRIES } from "@sisuwellness/utilities/User/UserProfile";
import { GooglePlaces } from "@sisuwellness/utilities/User";

export default () => {
    const placesService = useRef(null);
    const autoCompleteService = useRef(null);
    const autoCompleteSessionToken = useRef(null);

    const google = window.google;

    useEffect(() => {
        if (google && google.maps.places && autoCompleteService.current == null) {
            autoCompleteService.current = new google.maps.places.AutocompleteService();
            placesService.current = new google.maps.places.PlacesService(document.createElement("div"));
            refreshSessionToken();
        }
    }, []);

    const refreshSessionToken = () => {
        if (google) autoCompleteSessionToken.current = new google.maps.places.AutocompleteSessionToken();
    };

    const fetchPredictions = useCallback(
        throttle((request, callback) => {
            autoCompleteService.current.getPlacePredictions(request, callback);
        }, 200),
        []
    );

    const fetchDetails = useCallback(
        throttle((request, callback) => {
            placesService.current.getDetails(request, callback);
        }, 200),
        []
    );

    const getAddressComponent = useCallback(
        /**
         * @param {string} placeId
         * @return {Promise}
         */
        async placeId => {
            if (!placeId) return;

            const requestPayload = {
                placeId: placeId,
                fields: ["address_components"],
                sessionToken: autoCompleteSessionToken.current
            };

            return new Promise(resolve => fetchDetails(requestPayload, resolve));
        },
        [fetchDetails]
    );

    const getPlacePredictions = useCallback(
        /**
         *
         * @param {string} searchValue
         * @param {Array<string>} country array of country codes to restrict search
         * @return {Promise<Array>}
         */
        async (searchValue, country = [COUNTRIES.AUSTRALIA]) => {
            if (!searchValue) return [];

            const requestPayload = {
                input: searchValue,
                componentRestrictions: { country },
                sessionToken: autoCompleteSessionToken.current
            };

            return new Promise(resolve => fetchPredictions(requestPayload, resolve)).then(results => results || []);
        },
        [fetchPredictions]
    );

    const getAllAddressFields = useCallback(
        /**
         * @param {string} placeId
         * @return {Promise<{ state: string, suburb: string, country: string, subpremise: string, postalCode: string, streetAddress: string }>}
         */
        async placeId => getAddressComponent(placeId).then(result => GooglePlaces.getAddressComponents(result)),
        [getAddressComponent]
    );

    return {
        fetchDetails,
        placesService,
        fetchPredictions,
        refreshSessionToken,
        getPlacePredictions,
        getAddressComponent,
        autoCompleteService,
        getAllAddressFields,
        autoCompleteSessionToken
    };
};
