import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TimelineGlobalStyle from "./GlobalStyle";
import { Box } from "rebass";

const TIMELINE_LAYOUTS = {
    ONE_COLUMN: "ONE_COLUMN",
    TWO_COLUMNS: "TWO_COLUMNS"
};

const Timeline = ({ animate, children, className, layout, iconSize, ...rest }) => (
    <>
        <TimelineGlobalStyle iconSize={iconSize} />
        <Box
            {...rest}
            className={classNames(className, "vertical-timeline", {
                "vertical-timeline--animate": animate,
                "vertical-timeline--two-columns": layout === TIMELINE_LAYOUTS.TWO_COLUMNS,
                "vertical-timeline--one-column": layout === TIMELINE_LAYOUTS.ONE_COLUMN
            })}
        >
            {children}
        </Box>
    </>
);

Timeline.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    className: PropTypes.string,
    animate: PropTypes.bool,
    layout: PropTypes.oneOf(Object.values(TIMELINE_LAYOUTS)),
    iconSize: PropTypes.number
};

Timeline.defaultProps = {
    animate: true,
    layout: TIMELINE_LAYOUTS.ONE_COLUMN,
    iconSize: 70
};

export default Timeline;
