import React from "react";
import PropTypes from "prop-types";

import { Flex } from "rebass";
import { useHistory } from "react-router";

// ----------------------------------------------------------------------------

import Popup from "./popup";
import { Box, StyledButtonCross } from "./styled";
import { PORTAL_IMAGE } from "constants/imagePath";
import { Margin } from "components/styled-components";
import { PrimaryButton, SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------

const PortalModal = ({ closeModal, modalIsOpen }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <Popup closeModal={closeModal} modalIsOpen={modalIsOpen} hideHeader>
            <Box>
                <Flex width="100%" justifyContent="space-between">
                    <div className="header">{t("members_portal_web.components.portal_modal.unsaved_changes")}</div>
                    <StyledButtonCross onClick={closeModal} src={`${PORTAL_IMAGE}/menu-close.svg`} alt={"cross"} />
                </Flex>
                <Margin mTop={["20px"]}>
                    <div className="content">{t("members_portal_web.components.portal_modal.confirmation_text")}</div>
                </Margin>
                <Margin mTop={["40px"]}>
                    <Flex justifyContent="space-between">
                        <SecondaryButton
                            width={window.innerWidth < 600 ? "140px" : "200px"}
                            type="button"
                            style={{ color: "#372168" }}
                            onClick={closeModal}
                        >
                            {t("members_portal_web.components.portal_modal.buttons.stay")}
                        </SecondaryButton>
                        <PrimaryButton
                            width={window.innerWidth < 600 ? "140px" : "200px"}
                            type="button"
                            onClick={() => {
                                history.goBack();
                            }}
                        >
                            {t("members_portal_web.components.portal_modal.buttons.cancel")}
                        </PrimaryButton>
                    </Flex>
                </Margin>
            </Box>
        </Popup>
    );
};

PortalModal.propTypes = {
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func
};

export default PortalModal;
