import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(heartRate) {
        this._validateHeartRate(heartRate);
        this._heartRate = heartRate;
    }

    /**
     * Returns the given Heart Rate value
     * @returns {Number}
     */
    get() {
        return this._heartRate;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "HEART_RATE";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_HR";
    }

    /**
     * Validates the given Heart Rate value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateHeartRate(heartRate) {
        if (!(typeof heartRate === "number") || Number.isNaN(heartRate) || heartRate < 0) {
            throw new InvalidArgumentError("Heart Rate must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the given Heart Rate is in the 'low' range
     *
     * @returns {boolean}
     */
    isLow() {
        return this._heartRate < 40;
    }

    /**
     * Returns if the given Heart Rate is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._heartRate >= 40 && this._heartRate < 80;
    }

    /**
     * Returns if the given Heart Rate is in the 'elevated' range
     *
     * @returns {boolean}
     */
    isElevated() {
        return this._heartRate >= 80 && this._heartRate < 100;
    }

    /**
     * Returns if the given Heart Rate is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._heartRate >= 100 && this._heartRate < 120;
    }

    /**
     * Returns if the given Heart Rate is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        return this._heartRate >= 120;
    }

    /**
     * Returns an object representing various data for each Heart Rate range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            low: {
                key: "low",
                label: "ranges.low",
                range: "< 40"
            },

            normal: {
                key: "normal",
                label: "ranges.normal",
                range: "40 - 80"
            },

            elevated: {
                key: "elevated",
                label: "ranges.elevated",
                range: "80 - 100"
            },

            high: {
                key: "high",
                label: "ranges.high",
                range: "100 - 120"
            },

            veryHigh: {
                key: "veryHigh",
                label: "ranges.very_high",
                range: "120+"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.range;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.range;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.range;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.range;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.range;
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.key;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.key;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.key;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.key;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.key;
                break;
        }

        return info;
    }

    /**
     * returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.label;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.label;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.label;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.label;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.label;
                break;
        }

        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 5,
            riskLevel = 0;
        switch (true) {
            case this.isLow():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
            case this.isElevated():
                riskLevel = 3 / (totalRiskLevel + 1);
                break;
            case this.isHigh():
                riskLevel = 4 / (totalRiskLevel + 1);
                break;
            case this.isVeryHigh():
                riskLevel = 5 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }
}
