import React from "react";

import { Image, Text, Flex } from "rebass";
import { useHistory } from "react-router";

// --------------------------------------------------------------------------------

import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { PORTAL_IMAGE } from "constants/imagePath";
import PageLayout from "components/PageLayout";
import routes from "constants/routes";

import { EmptyHealthCheckContainer } from "./styled";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------------------

const NoHealthCheckView = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <PageLayout>
            <Heading mb={["0px", "160px"]} fontWeight="intermediate" fontSize={["28px", "34px"]}>
                {t("members_portal_web.views.health_checks.no_checks.title")}
            </Heading>
            <EmptyHealthCheckContainer>
                <Image
                    width="163px"
                    mb={["40px", "24px"]}
                    src={`${PORTAL_IMAGE}/healthChecks/emptyHealthCheck.svg`}
                    alt="Meal Planner"
                />
                <Flex>
                    <Text
                        mb={["32px", "40px"]}
                        mx="10px"
                        fontSize={["20px", "28px"]}
                        color="hpPrimaryPurple"
                        textAlign="center"
                    >
                        {t("members_portal_web.views.health_checks.no_checks.text.0")}
                        <br />
                        {t("members_portal_web.views.health_checks.no_checks.text.1")}
                    </Text>
                </Flex>
                <PrimaryButton data-testid="find-a-station" onClick={() => history.push(routes.findHealthStations)}>
                    {t("words.find_a_health_station")}
                </PrimaryButton>
            </EmptyHealthCheckContainer>
        </PageLayout>
    );
};

NoHealthCheckView.propTypes = {};

export default NoHealthCheckView;
