const MeasurementSystems = {
    metric: "METRIC",
    imperial: "IMPERIAL"
};

const LanguageTags = {
    enAU: "en_AU",
    enGB: "en_GB",
    enUS: "en_US",
    enIE: "en_IE",
    enCH: "en_CH",
    skSK: "sk_SK"
};

const ImperialLanguageTags = ["en_GB", "en_US", "en_IE"];
const NumericPostalCodesLanguageTags = ["en_AU"];

const DEFAULT_LOCALE = "en";

export { MeasurementSystems, LanguageTags, ImperialLanguageTags, NumericPostalCodesLanguageTags, DEFAULT_LOCALE };
