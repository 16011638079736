import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex } from "rebass";
import { ResponsiveImage } from "@sisuwellness/web-components";
import { useTranslation } from "react-i18next";

const StyledFlex = styled(Flex)`
    margin-top: 1em;
`;

const StyledFooterText = styled.span`
    font-size: ${props => props.theme.text.heading6.sizeRem};
    margin-right: 0.75em;
`;

const CLIENT_CODES = {
    PRICELINE: "PRICELINE"
};

const AuthClientFooter = ({ clientCode }) => {
    const { t } = useTranslation();

    return clientCode != CLIENT_CODES.PRICELINE ? null : (
        <StyledFlex justifyContent="center" alignItems="center" data-testid="login-footer">
            <StyledFooterText>{t("web_components.auth.client_footer.text")}</StyledFooterText>
            <ResponsiveImage
                data-testid="login-footer-logo"
                src={"/images/clients/priceline/logo.png"}
                alt="Priceline Logo"
                height="30px"
            />
        </StyledFlex>
    );
};

AuthClientFooter.propTypes = {
    clientCode: PropTypes.string
};

export default AuthClientFooter;
