import { useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import Auth from "utilities/auth";

const AuthPassthrough = () => {
    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("auth-passthrough");

    useEffect(() => {
        if (!token) return;

        searchParams.delete("auth-passthrough");
        location.search = searchParams.toString();

        Auth.authenticate(token).then(() => history.replace(location));
    }, [token]);

    return null;
};

export default AuthPassthrough;
