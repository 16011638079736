import React from "react";
import PropTypes from "prop-types";

import { Text, Box } from "rebass";
import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { createLegends, downBy, isMobileView } from "components/Templates/TrendChart/Line/utils";

import {
    calculateMinMax,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { MUSCLE_MASS } from "constants/trend-charts";
import { fixedNumber } from "utilities/commonUtils";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";

import Guideline from "@sisuwellness/utilities/Guidelines";
import { all, complement, isNil } from "ramda";

// ------------------------------------------------------------------------------------------------

// import { useUserHealthCheckData } from "views/BodyComposition";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import { SectionHeaders } from "views/HeartAge/commonElements";
import { pickAttribute } from "utilities/commonUtils";
import OutcomeHistory from "components/Templates/OutcomeHistory";
import { LineChart } from "components/Templates/TrendChart";
import { useUserHealthCheckData } from "views/MuscleMass";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------------------------------

const MuscleMassHistory = props => {
    const { t } = useTranslation();
    const { latestHealthCheck, healthChecks } = useUserHealthCheckData();
    const lastMeasurementDate = pickAttribute(["updatedAt"], latestHealthCheck);

    const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

    const METRIC_FIELDS = {
        rounded: true,
        key: ["muscleMass"],
        additionalKeys: ["age", "gender"]
    };

    const filterEmptyPoints = data => {
        const { age, gender, muscleMass } = data;
        if (all(complement(isNil))([age, gender, muscleMass])) return { ...data, unit: "%" };
        return null;
    };

    const plot = {
        unit: () => t("words.skeletal_muscle") + " (%)",

        color: ({ age, gender, muscleMass } = {}) => {
            const { guideline, pointColor } = calculatePointColor(MUSCLE_MASS, { age, gender, muscleMass });
            return guideline ? pointColor : defaultColor;
        },

        dataPoints: (healthChecks = []) =>
            createPlottableDataPoints(filterEmptyPoints)(healthChecks, METRIC_FIELDS.key, METRIC_FIELDS.additionalKeys),

        yRange: (points = [], width) => {
            const { maximum, minimum } = calculateMinMax(flattenPointValues(points, "y"));
            const ranges = createYGridValues(downBy(minimum, 5), maximum, {
                parts: isMobileView(width) ? 5 : 7,
                rounded: METRIC_FIELDS.rounded
            });

            return {
                min: ranges[0],
                yScaleRanges: ranges,
                max: ranges[ranges.length - 1]
            };
        },

        legends: () =>
            createLegends(
                Guideline.MuscleMass.buildGuidelineDefinition(),
                METRIC_INDICATOR.MUSCLE_MASS.themeKey,
                "words.skeletal_muscle"
            ),

        historyProps: (filteredPoints = [], latestHealthCheck = {}) => {
            const { muscleMass, age, gender } = latestHealthCheck;

            const meanMuscleMass = calculateMeanValue("muscleMass", filteredPoints[0]);
            const meanAge = calculateMeanValue("age", filteredPoints[0]);

            const ratingKey = calculatePointColor(MUSCLE_MASS, { age, gender, muscleMass })?.guideline?.key;
            const meanRatingKey = calculatePointColor(MUSCLE_MASS, { age: meanAge, gender, muscleMass: meanMuscleMass })
                ?.guideline?.key;

            return {
                unit: "%",
                value: fixedNumber(muscleMass),
                meanValue: fixedNumber(meanMuscleMass),
                valueColor: theme.guidelines.muscleMass[ratingKey]?.sisuPortal?.hex,
                meanColor: theme.guidelines.muscleMass[meanRatingKey]?.sisuPortal?.hex
            };
        },

        margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
    };

    const mmProps = { plottingUtility: plot };

    return (
        <Box>
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    {t("words.your_history")}
                </Text>
            </SectionHeaders>

            <HeadingLabel fontSize="22px" mt="24px">
                {t("members_portal_web.views.muscle_mass.history.heading")}
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px" mb="16px">
                {t("members_portal_web.views.muscle_mass.history.label")}
            </NormalLabel>

            <LineChart
                healthChecks={healthChecks}
                latestHealthCheckData={latestHealthCheck}
                {...mmProps}
                data-testid="history-muscle-mass"
            >
                {({ filter, historyProps }) => (
                    <OutcomeHistory
                        pb="24px"
                        filter={filter}
                        {...historyProps}
                        lastMeasurementDate={lastMeasurementDate}
                    />
                )}
            </LineChart>
        </Box>
    );
};

MuscleMassHistory.propTypes = { setWeightGoal: PropTypes.func };

export default MuscleMassHistory;
