import BaseValidator from "./BaseValidator";

export default class RegexValidator extends BaseValidator {
    _getDefaultOptions() {
        return {
            regexPattern: null,
            regexErrorMessage: null
        };
    }

    /**
     *
     * @param value
     * @return {Array}
     */
    validate(value) {
        const errors = [];
        if (this._options.regexPattern) {
            const regExp = new RegExp(this._options.regexPattern, "i");

            if (!regExp.test(String(value))) {
                let errorMessage = "Please enter a valid value";
                if (this._options.regexErrorMessage) {
                    errorMessage = this._options.regexErrorMessage;
                }

                errors.push(errorMessage);
            }
        }

        return errors;
    }
}
