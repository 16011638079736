import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";
import { theme } from "../../theme";

// ------------------------------------------------------------------------

const NormalLabel = ({ children, fs = 16, ...rest }) => (
    <Text
        fontSize={theme.fontSize[fs].px}
        fontWeight={theme.fontWeight.light}
        color={theme.colours.hpPrimaryPurple}
        {...rest}
    >
        {children}
    </Text>
);

NormalLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    fs: PropTypes.number
};

export default NormalLabel;
