import { Flex, Box } from "rebass";
import { default as styled } from "styled-components";

export const StyledCard = styled(Flex)`
    border-radius: 5px;
    background: #f9fbff;
    align-items: center;
`;

export const StyledBox = styled(Box)`
    border: 0px;
`;

export const StyledInfoBox = styled(Flex)`
    width: 100%;
    border-radius: 10px;
    border: solid 1px #b3b3b3;
`;
