import { Flex, Text } from "rebass";
import { default as styled } from "styled-components";

import { position, layout } from "styled-system";

export const PositionedContainer = styled(Flex)`
    position: relative;
    svg path {
        stroke: ${({ stroke }) => stroke};
    }
`;

export const StyledText = styled(Text)`
    top: 43%;
    left: 50%;
    height: auto;
    height: 100%;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: center;
    transform: translate(-50%, -50%);
`;

export const BlurContainer = styled(Flex)`
    position: absolute;
    left: 0;
    width: 100%;
    min-height: 165px;
    border-radius: 5px;
    background-color: rgba(234, 225, 255, 0.5);
    transform: translateY(-4px);
    z-index: 2;

    &&& {
        filter: none;
    }

    ${position};
    ${layout};
`;

export const StyledMetricCardContainer = styled(Flex)`
    > * {
        filter: ${({ isPremium }) => (isPremium ? "none" : "blur(8px)")};
    }
`;
