import * as yup from "yup";

export const FIELD_VALIDATION = {
    METRIC: {
        HEIGHT: "HEIGHT_METRIC",
        WEIGHT: "WEIGHT_METRIC",
        NUMBER: "NUMBER_METRIC"
    },
    IMPERIAL: {
        HEIGHT: "HEIGHT_IMPERIAL",
        WEIGHT: "WEIGHT_IMPERIAL"
    }
};

export const validationSchema = type => {
    switch (type) {
        case FIELD_VALIDATION.METRIC.HEIGHT:
            return yup.object().shape({
                heightInCm: yup
                    .number()
                    .typeError("Please enter a number")
                    .positive("Negative numbers are not allowed")
                    .min(90, "Number should be greater than or equal to 90 cm")
                    .max(240, "Number should be less than or equal to 240 cm")
                    .when("isRequired", {
                        is: true,
                        then: yup.number().required("This is a required field")
                    })
            });

        case FIELD_VALIDATION.IMPERIAL.HEIGHT:
            return yup.object().shape({
                heightInft: yup
                    .number()
                    .integer("Decimals are not allowed")
                    .positive("Negative numbers are not allowed")
                    .typeError("Please enter a valid number")
                    .min(2, "Number should be greater than or equal to 2 ft")
                    .max(7, "Number should be less than or equal to 7 ft")
                    .when("isRequired", {
                        is: true,
                        then: yup.number().required("This is a required field")
                    }),
                heightInInch: yup
                    .number()
                    .integer("Decimals are not allowed")
                    .positive("Negative numbers are not allowed")
                    .typeError("Please enter a number")
                    .max(12, "Number must be less than or equal to 12 in")
                    .when("heightInft", {
                        is: undefined,
                        then: yup
                            .number()
                            .positive("Negative numbers are not allowed")
                            .typeError("Feet field cannot be empty")
                    })
                    .when("heightInft", {
                        is: 2,
                        then: yup
                            .number()
                            .positive("Negative numbers are not allowed")
                            .min(11, "Height must be greater than 2'11'' ")
                            .typeError("Please enter a valid number")
                    })
                    .when("heightInft", {
                        is: 7,
                        then: yup
                            .number()
                            .positive("Negative numbers are not allowed")
                            .max(10, "Height must be less than 7'10'' ")
                            .typeError("Please enter a valid number")
                    })
            });

        case FIELD_VALIDATION.METRIC.WEIGHT:
            return yup.object().shape({
                weightInKg: yup
                    .number()
                    .typeError("Please enter a number")
                    .positive("Negative numbers are not allowed")
                    .min(30, "Number should be greater than or equal to 30 kg")
                    .max(250, "Number should be less than or equal to 250 kg")
                    .when("isRequired", {
                        is: true,
                        then: yup.number().required("This is a required field")
                    })
            });

        case FIELD_VALIDATION.IMPERIAL.WEIGHT:
            return yup.object().shape({
                weightInSt: yup
                    .number()
                    .integer("Decimals are not allowed")
                    .positive("Negative numbers are not allowed")
                    .typeError("Please enter a valid number")
                    .min(4, "Number should be greater than or equal to 4 st")
                    .max(39, "Number should be less than or equal to 39 st")
                    .when("isRequired", {
                        is: true,
                        then: yup.number().required("This is a required field")
                    }),
                weightInLbs: yup
                    .number()
                    .positive("Negative numbers are not allowed")
                    .typeError("Please enter a number")
                    .max(14, "Number must be less than or equal to 14 lbs")
                    .when("weightInSt", {
                        is: undefined,
                        then: yup
                            .number()
                            .positive("Negative numbers are not allowed")
                            .typeError("Feet field cannot be empty")
                    })
                    .when("weightInSt", {
                        is: 4,
                        then: yup
                            .number()
                            .positive("Negative numbers are not allowed")
                            .min(10.2, "Weight is below the specified range")
                            .typeError("Please enter a valid number")
                    })
                    .when("weightInSt", {
                        is: 39,
                        then: yup
                            .number()
                            .positive("Negative numbers are not allowed")
                            .max(5.1, "Weight is more than the specified range")
                            .typeError("Please enter a valid number")
                    })
            });

        case FIELD_VALIDATION.METRIC.NUMBER:
            return yup.object().shape({
                number: yup
                    .number()
                    .typeError("Please enter a number")
                    .positive("Negative numbers are not allowed")
                    .when("isRequired", {
                        is: true,
                        then: yup.number().required("This is a required field")
                    })
            });
    }
};
