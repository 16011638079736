import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled.div`
    color: ${props => props.theme.colours.flat.white.hex};
    background: ${props => props.theme.colours.flat.lightBlue.hex};
    visibility: ${props => (props.hasText ? "visible" : "hidden")};
    padding: 10px 20px;
    text-align: center;
`;

const Info = ({ children, ...rest }) => (
    <StyledContainer hasText={children} {...rest}>
        {children}&nbsp;
    </StyledContainer>
);

Info.propTypes = {
    children: PropTypes.node.isRequired
};

export default Info;
