import { SET_HEALTH_STATIONS } from "constants/actions";
import { createAction } from "redux-actions";
import APIClient from "utilities/APIClient";
import { handleResponse } from "@sisuwellness/utilities/front-end-utils/errors";

// Redux actions
export const setHealthStations = createAction(SET_HEALTH_STATIONS);

// Redux thunk actions
export function getHealthStationsAPI() {
    return async dispatch => {
        const response = await APIClient.healthStation.public();
        const result = await handleResponse(response, []);

        dispatch(setHealthStations(result));

        return result;
    };
}
