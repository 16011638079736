import styled from "styled-components";
import { Box } from "rebass";
import { Heading } from "@sisuwellness/web-components";

export const StyledBackground = styled(Box)`
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    height: 100%;
    display: flex;
    z-index: 5;
`;

export const OverlapImage = styled.img`
    position: absolute;
    left: 35%;
    height: 80%;
    z-index: 6;
    bottom: 0;
`;

export const MobileImage = styled.img`
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
`;

export const StyledFooter = styled(Box)`
    width: 100%;
    z-index: 1;
    position: relative;
    border-top: 1px solid ${props => props.theme.colours.hpPurple100.hex};
    background-color: ${props => props.theme.colours.hpNavBackground.hex};
`;

export const StyledHeading = styled(Heading)`
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    font-size: ${props => props.theme.fontSize[20].px};
`;

export const FooterWrapper = styled(Box)`
    position: relative;
    z-index: 99;
    width: calc(100vw - (100vw - 100%));
`;

export const PageConstraint = styled(Box)`
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    position: relative;
    z-index: 99;
    border-bottom: 4px solid ${props => props.theme.colours.hpPrimaryPurple.hex};
`;
