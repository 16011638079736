export default {
    symbolsKeyValue: "ABC",
    layout: [
        ["ľ", "š", "č", "ť", "ž", "ý", "á", "í", "é"],
        ["q", "w", "e", "r", "t", "z", "u", "i", "o", "p", "ú", "ä", "ň"],
        ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ô", "§"],
        ["y", "x", "c", "v", "b", "n", "m"]
    ],
    specialKeysLayout: []
};
