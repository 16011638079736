import calcCvd from "./Framingham/";
import SisuHeartAgeGuideline, { RiskCode, RiskName, GuidelineCode, GuidelineName } from "./SiSU";

const Calculate = ({ gender, age, systolic, bmi, isSmoker, hasDiabetes, isTakingBpMeds } = {}) => {
    const cvd = calcCvd({
        gender,
        age,
        systolic,
        bmi,
        isSmoker,
        hasDiabetes,
        isTakingBpMeds
    });

    const rating = SisuHeartAgeGuideline(cvd.heartAge, cvd.heartAgeOptimal, age);

    return { rating, heartAgeRating: rating.key, ...cvd };
};

export { Calculate, SisuHeartAgeGuideline, RiskCode, RiskName, GuidelineCode, GuidelineName };
