/**
 * A class that acts as a container of a range of validator classes that
 * returns a list of errors if not valid
 *
 */
export default class Validation {
    constructor() {
        /**
         *
         * @type {BaseValidator[]}
         * @private
         */
        this._validators = [];
    }

    /**
     *
     * @param {BaseValidator} validator
     * @return {Validation}
     */
    addValidator(validator) {
        this._validators.push(validator);
        return this;
    }

    /**
     * Returns array of error messages. If there are no messages, then it's valid
     *
     * @param value
     * @param isRequired set to true if the value must be defined and not falsy
     * @return {String[]}
     */
    validate(value, isRequired = false) {
        let errors = [];
        let hasValue = !(value == null || value === "");

        if (isRequired && !hasValue) {
            errors.push("A value must be defined");
        } else if (hasValue) {
            for (let v of this._validators) {
                Object.assign(errors, v.validate(value));
            }
        }

        return errors;
    }

    /**
     * Returns a boolean equivilent of validate()
     *
     * @param value
     * @param isRequired
     * @returns {boolean}
     */
    isValid(value, isRequired = false) {
        let errors = this.validate(value, isRequired);
        return errors.length === 0;
    }
}
