import styled from "styled-components";
import { Box, Flex } from "rebass";
import { grid, shadow } from "styled-system";

export const CheckboxContainer = styled(Box)`
    cursor: pointer;
    position: relative;
    border-radius: 5px;
    box-sizing: border-box;
    border: 1px solid rgba(102, 102, 102, 0.24);
    box-shadow: ${props => props.theme.portalShadows.card1};

    ${shadow}

    input {
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
    }

    .check-back {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        border-radius: 5px;
        background: transparent;
    }

    input[type="checkbox"]:checked + .check-back {
        background: ${props => props.color} !important;
    }

    .check-tick {
        top: 0;
        left: 50%;
        width: 24px;
        height: 24px;
        display: block;
        position: absolute;
        transform: translateX(-50%);
    }
`;

export const Label = styled.label`
    margin: 0;
    cursor: pointer;
    width: fit-content;
`;

export const FlexLayout = styled(Flex)(grid);
