import React from "react";
import PropTypes from "prop-types";
import { OverlayArea } from "@sisuwellness/web-components/components/Chart";
import { useTranslation } from "react-i18next";

const AreaLayer = ({ dimension: [width], ...rest }) => {
    const { t } = useTranslation();

    return (
        <OverlayArea
            label={t("members_portal_web.views.trend.hr_props.optimal")}
            upperBound={80}
            lowerBound={40}
            width={width}
            {...rest}
        />
    );
};

AreaLayer.propTypes = {
    dimension: PropTypes.array
};

export default AreaLayer;
