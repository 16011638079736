import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Flex } from "rebass";
import { media } from "@sisuwellness/web-components/themes";
import { isArray } from "lodash";

const styledGap = gap => css`
    margin: -${gap} 0px 0px -${gap};

    & > * {
        margin: ${gap} 0px 0px ${gap} !important;
    }
`;

const EmulatedFlexGap = styled(Flex)`
    flex-wrap: wrap;

    ${({ gap }) => (!isArray(gap) ? styledGap(gap) : styledGap(gap[2] || gap[1] || gap[0]))};

    ${media.tablet`
        ${({ gap }) => isArray(gap) && styledGap(gap[1] || gap[0])};
    `}

    ${media.mobile`
        ${({ gap }) => isArray(gap) && styledGap(gap[0])};
    `}
`;

const CardStack = ({ children, gap = "24px", ...rest }) => (
    <EmulatedFlexGap flexWrap="wrap" gap={gap} {...rest}>
        {children}
    </EmulatedFlexGap>
);

CardStack.propTypes = {
    ...Flex.propTypes,
    children: PropTypes.any,
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
};

export default CardStack;
