import BaseEndPoint from "../BaseEndPoint";

export default class ScriptsNow extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path = "") {
        if (path) {
            path = "/" + path;
        }
        return super.getUrlPath("/scripts-now" + path);
    }

    createScriptsNowOrderForHealthStation(responseId, source = "station") {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "POST",
            data: JSON.stringify({ responseId, source })
        };

        return this._send(this.getUrlPath("create-order-for-health-station"), settings);
    }

    fetchAll(queryParamObj) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(this.getUrlPath("all" + this.generateQueryString(queryParamObj)), settings);
    }

    getById(id) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };
        return this._send(this.getUrlPath(id), settings);
    }

    getByKeywords(queryParamObj) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };
        return this._send(this.getUrlPath("search" + this.generateQueryString(queryParamObj)), settings);
    }

    getConsents() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };
        return this._send(super.getUrlPath("/consents/scripts-now"), settings);
    }

    getServiceStatus() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };
        return this._send(this.getUrlPath("service-status"), settings);
    }
}
