import styled from "styled-components";
import { Flex } from "rebass";

const TableContainer = styled(Flex)`
    // Normal version
    .ReactTable {
        width: 100%;
        height: ${props => props.height};
        color: ${props => props.theme.colours.flat.blackTint.hex};

        .rt-tbody {
            .health-check-cell,
            .health-check-side-cell {
                background-color: ${props => props.theme.portalColor.hpCardBackground.hex};
                text-align: center;
                cursor: pointer;
            }

            .rt-tr-group {
                .rt-td:not(:nth-child(-n + 1)) {
                    padding-left: ${props => props.theme.table.spacing.column};
                    padding-right: ${props => props.theme.table.spacing.column};
                }

                .rt-td:nth-child(1) {
                    margin-right: 32px;
                    .health-check-side-cell {
                        justify-content: center;
                    }
                }

                :last-child {
                    .rt-td {
                        overflow: visible;
                        padding-bottom: 5px;

                        .health-check-cell {
                            border-radius: ${props => props.theme.table.borderRadius.tail};
                            border-bottom: none;
                        }
                    }
                }
            }

            .rt-tr-group:last-child {
                .rt-td:nth-child(1) .health-check-side-cell {
                    border-radius: 0 0 10px 10px;
                }
            }
        }

        .rt-thead {
            .rt-th {
                padding-top: 5px;
                .health-check-header {
                    background-color: ${props => props.theme.portalColor.hpCardBackground.hex};
                    border-radius: ${props => props.theme.table.borderRadius.head};
                    font-size: 15px;
                    font-weight: 600;
                }
                .custom-header {
                    background-color: ${props => props.theme.portalColor.hpCardBackground.hex};
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            .rt-th:nth-child(1) {
                margin-right: 32px;

                .custom-header {
                    border-radius: 10px 10px 0 0;
                }
            }

            .rt-th:not(:nth-child(-n + 1)) {
                padding-left: ${props => props.theme.table.spacing.column};
                padding-right: ${props => props.theme.table.spacing.column};
            }
        }
    }

    // Fixed columns version
    .ReactTable.rthfc {
        height: calc(100vh - 225px);

        .rthfc-th-fixed-left,
        .rthfc-td-fixed-left {
            background: ${props => props.theme.portalColor.hpCardBackground.hex};
        }

        .rthfc-th-fixed-left-last,
        .rthfc-td-fixed-left-last {
            margin-right: 32px;
        }

        .rthfc-th-fixed-left:nth-child(1) {
            border-radius: 10px 10px 0 0;
        }

        .rt-thead {
            .rt-th {
                padding-top: 0px;
            }

            .rt-th:not(:nth-child(-n + 1)) {
                padding-left: ${props => props.theme.table.spacing.column};
                padding-right: ${props => props.theme.table.spacing.column};
            }
        }

        .rt-tbody {
            .rt-tr-group {
                .rt-td:not(:nth-child(-n + 1)) {
                    padding-left: ${props => props.theme.table.spacing.column};
                    padding-right: ${props => props.theme.table.spacing.column};
                }

                :last-child .rthfc-td-fixed-left-last {
                    border-radius: 0 0 10px 10px;
                }
            }
        }
    }
`;

export default TableContainer;
