import { Flex, Text } from "rebass";
import styled from "styled-components";
import React, { useState } from "react";
import PropTypes from "prop-types";

const CustomBox = styled(Flex)`
    cursor: pointer;
    user-select: none;
    border: solid 1px;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    border-color: ${({ theme: { colours }, isSelected }) =>
        isSelected ? colours.hpPrimaryPurple.hex : colours.blackTints400.hex};
    border-top-left-radius: ${({ rLeft }) => (rLeft ? "10px" : "0px")};
    border-bottom-left-radius: ${({ rLeft }) => (rLeft ? "10px" : "0px")};
    border-top-right-radius: ${({ rRight }) => (rRight ? "10px" : "0px")};
    border-bottom-right-radius: ${({ rRight }) => (rRight ? "10px" : "0px")};
    border-left: ${({ isSelected, rRight }) => !isSelected && rRight && "0px"};
    border-right: ${({ isSelected, rLeft }) => !isSelected && rLeft && "0px"};
    color: ${({ theme: { colours }, disabled }) => disabled && colours.blackTints400.hex};
    background-color: ${({ theme: { colours }, isSelected }) => isSelected && colours.hpGrayishBlue.hex};
`;

const Switch = ({ first, second, defaultValue = "", disableFirst = false, disableSecond = false, onToggle }) => {
    const [selected, setSelected] = useState(defaultValue || disableFirst ? second : first);

    const isFirstSelected = first === selected;
    const isSecondSelected = second === selected;

    const handleOnToggle = selectedItem => {
        onToggle(selectedItem);
        setSelected(selectedItem);
    };

    return (
        <Flex width={["157px", "300px"]} height={["32px", "55px"]} justifyContent="center" alignItems="center">
            <CustomBox
                rLeft
                height="100%"
                disabled={disableFirst}
                width={["78.5px", "150px"]}
                isSelected={isFirstSelected}
                onClick={() => !disableFirst && handleOnToggle(first)}
            >
                <Text fontSize={["11px", "15px"]} fontWeight={["400", "500"]}>
                    {first}
                </Text>
            </CustomBox>
            <CustomBox
                rRight
                height="100%"
                disabled={disableSecond}
                width={["78.5px", "150px"]}
                isSelected={isSecondSelected}
                onClick={() => !disableSecond && handleOnToggle(second)}
            >
                <Text fontSize={["11px", "15px"]} fontWeight={["400", "500"]}>
                    {second}
                </Text>
            </CustomBox>
        </Flex>
    );
};

Switch.propTypes = {
    first: PropTypes.string,
    second: PropTypes.string,
    defaultValue: PropTypes.string,
    disableFirst: PropTypes.bool,
    disableSecond: PropTypes.bool,
    onToggle: PropTypes.func
};

export default Switch;
