import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Response } from "@sisuwellness/utilities";
import { UserJourney as UserJourneyModel } from "@sisuwellness/utilities/UserJourney";
import UserJourney from "@sisuwellness/web-components/components/UserJourneys/UserJourney";
import APIClient from "../utilities/APIClient";
import BaseMemberUserJourneyView from "../components/BaseMemberUserJourneyView";
import Auth from "../utilities/auth";
import { EventCodes } from "@sisuwellness/utilities/Response";

class Pulse extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userJourneyModel: null,
            response: null,
            // citizen: null,
            userJourneyPageId: null
        };

        this.handleComplete = this.handleComplete.bind(this);
        this.handleJourneyPageComplete = this.handleJourneyPageComplete.bind(this);
        this.handleShowPage = this.handleShowPage.bind(this);
        this.handleSaveAndExit = this.handleSaveAndExit.bind(this);
    }

    componentDidMount() {
        APIClient.userJourneys
            .getResource(this.props.match.params.userJourneyId, true)
            .then(response => {
                if (response.succeeded()) {
                    return response.body();
                } else {
                    this.setState({ userJourneyModel: null });
                }

                return false;
            })
            .then(body => {
                let next = null;

                if (body) {
                    next = Auth.getUserUUID();
                }

                return Promise.all([Promise.resolve(body), Promise.resolve(next)]);
            })
            .then(([body, uuid]) => {
                if (uuid) {
                    let response = new Response();
                    let userJourneyModel = new UserJourneyModel(body);

                    response.userUUID = uuid;
                    response.setUserJourney(userJourneyModel);

                    this.setState({ userJourneyModel, response });
                }
            });
    }

    handleJourneyPageComplete() {
        // we do nothing with success and fail conditions... just be hopeful
        // APIClient.response.update(this.state.response);
    }

    handleComplete() {
        let responseUtility = this.state.response;
        APIClient.response.post("", this.state.response).then(response => {
            if (response.succeeded()) {
                response.body().then(body => {
                    responseUtility.responseId = body.responseId;
                    APIClient.response.finish(this.state.response).then(response => {
                        if (response.succeeded()) {
                            this.setState({ complete: true });
                        }
                    });
                });
            }
        });
    }

    // handleShowPage(userJourneyPageId) {
    //
    //     let page = this.state.userJourneyModel.getPageByUserJourneyPageId(userJourneyPageId);
    //     this.setState({currentPage: page});
    // }

    handleShowPage(userJourneyPageId) {
        this.setState({ userJourneyPageId });
    }

    handleSaveAndExit() {
        this.state.response.addEvent(EventCodes.healthRiskAssessment.saved);
    }

    onPageResolved() {}

    render() {
        let component = null;
        if (this.state.complete) {
            component = <Redirect to={"/health-hub"} />;
        } else if (this.state.userJourneyModel) {
            component = (
                <BaseMemberUserJourneyView
                    userJourney={this.state.userJourneyModel}
                    onSaveAndExit={this.handleSaveAndExit}
                >
                    <UserJourney
                        model={this.state.userJourneyModel}
                        onComplete={this.handleComplete}
                        onPageResolved={this.handleJourneyPageComplete}
                        onShowPage={this.handleShowPage}
                    />
                </BaseMemberUserJourneyView>
            );
        }

        return component;
    }
}

Pulse.displayName = "Pulse View";
Pulse.propTypes = {
    match: PropTypes.object.isRequired
};

export default Pulse;
