import styled from "styled-components";

const Container = styled.div`
    padding: 1.5em;

    h2 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading2.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    h3 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading3.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    h4 {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        font-size: ${props => props.theme.text.heading4.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
    }

    p {
        font-size: ${props => props.theme.text.paragraph.sizeRem};
        margin: 0 0 0.8em;
        padding: 0;
        line-height: 1.3em;
        max-width: 100%;
    }

    ul {
        font-size: ${props => props.theme.text.heading5.sizeRem};
        line-height: 1.3em;
    }

    strong {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
    }
`;

export default Container;
