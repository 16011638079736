import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import styled, { ThemeContext } from "styled-components";
import { Flex, Text, Box } from "rebass";
import { isEmpty } from "ramda";
import { Icon } from "@sisuwellness/web-components";

const Banner = styled(Flex)`
    color: white;
    border-radius: 0px 0px 10px 10px;
`;

const StyledText = styled(Text)`
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.25;
    text-align: center;
`;

const ImageIcon = styled(Box)`
    cursor: pointer;
`;

const MessageBanner = ({ isDismissible, message, handleMessage }) => {
    const theme = useContext(ThemeContext);

    useEffect(() => {
        if (!isDismissible && !isEmpty(message)) setTimeout(() => handleMessage("none"), 5000);
    }, [message, isDismissible]);

    const handleCloseAction = () => handleMessage("none");

    const typeColor = (() => {
        if (message.error) return theme.colours.hpDarkPink.hex;
        else if (message.success) return theme.colours.hpDarkGreen.hex;
        else if (message.general) return theme.colours.hpPrimaryPurple.hex;
        else return "tranparent";
    })();

    return (
        <Banner
            px="20px"
            py={["20px", "40px"]}
            alignItems="center"
            minHeight={["90px", "105px"]}
            justifyContent="center"
            bg={typeColor}
        >
            {message.error && <StyledText>{message.error}</StyledText>}
            {message.success && <StyledText>{message.success}</StyledText>}
            {message.general && <StyledText>{message.general}</StyledText>}
            {!isEmpty(message) && isDismissible && (
                <ImageIcon mr={["5px", "20px"]} ml="20px" onClick={handleCloseAction} data-testid="banner-close">
                    <Icon width="24px" height="24px" colour="white" type={theme.icons.types.simpleClose} />
                </ImageIcon>
            )}
        </Banner>
    );
};

MessageBanner.propTypes = {
    isDismissible: PropTypes.bool.isRequired,
    message: PropTypes.objectOf(PropTypes.string).isRequired,
    handleMessage: PropTypes.func.isRequired
};

export default MessageBanner;
