import React from "react";

import { Text } from "rebass";
import { useUserHealthCheckData } from "views/HeartAge";

// ----------------------------------------------------------------

import { SectionHeaders } from "views/HeartAge/commonElements";
import { calculateHeartAgeParams } from "views/HeartAge/utils";
import Flex from "components/Flex";

import HeartAgeTrend from "./heartAgeTrend";
import TenYearCvdRiskTrend from "./tenYearCvdRiskTrend";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------

const HeartAgeHistory = props => {
    const { t } = useTranslation();
    const { isCountryUK } = useSelector(state => state.deviceLocation);
    const { healthChecks, latestHealthCheck } = useUserHealthCheckData();

    const latestHealthCheckData = calculateHeartAgeParams(latestHealthCheck);
    const { lastMeasurementDate } = latestHealthCheckData;

    return (
        <Flex fd="column" gap="24px">
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    {t("words.your_history")}
                </Text>
            </SectionHeaders>

            <HeartAgeTrend
                data-testid="history-heart-age"
                healthChecks={healthChecks}
                lastMeasurementDate={lastMeasurementDate}
                latestHealthCheckData={latestHealthCheckData}
            />

            {!isCountryUK && (
                <TenYearCvdRiskTrend
                    data-testid="history-cvd"
                    healthChecks={healthChecks}
                    lastMeasurementDate={lastMeasurementDate}
                    latestHealthCheckData={latestHealthCheckData}
                />
            )}
        </Flex>
    );
};

export default HeartAgeHistory;
