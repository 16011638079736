import React from "react";
import PropTypes from "prop-types";

// --------------------------------------------------------------------

import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import Flex from "components/Flex";

import {
    HealthCardIcon,
    HealthCardRecommendation,
    HealthCardStatus,
    HealthCardTextValue
} from "components/HealthCard/atomicComponents";
import { MediaQuery } from "@sisuwellness/web-components";
import { ExtraBreakpointWrapper } from "components/ExtraBreakpointWrapper";
import { getMetricDetails } from "./utils";

// --------------------------------------------------------------------

export const HealthCard = ({ type, datum, ...rest }) => {
    const { icon, title, value, unit, color, label, recommendation } = getMetricDetails(type, datum);

    return (
        <Card p="24px" width="100%" height="auto" {...rest}>
            <Flex jc="space-between" ai="center">
                <Heading>{title}</Heading>
                <MediaQuery devices={["desktop"]}>
                    <HealthCardStatus indicator={{ bg: color, label }} width="114px" height="40px" />
                </MediaQuery>
            </Flex>
            <ExtraBreakpointWrapper screen="smallDesktop" fd="column">
                <Flex jc="space-between" ai={["", "", "center"]} fd={["column", "column", "row"]} gap="16px">
                    <ExtraBreakpointWrapper screen="smallDesktop" width="100%">
                        <Flex width={["100%", "", "auto"]} jc="space-between" ai="center" color="blackTints700">
                            <HealthCardIcon icon={icon}>
                                <HealthCardTextValue
                                    value={value}
                                    unit={unit}
                                    valueProps={{ fontSize: "20px", textAlign: "center" }}
                                    unitProps={{ fontSize: "15px", fontWeight: "light" }}
                                />
                            </HealthCardIcon>
                            <MediaQuery devices={["tablet", "mobile"]}>
                                <HealthCardStatus
                                    indicator={{ bg: color, label }}
                                    width="114px"
                                    height="40px"
                                    ml="12px"
                                />
                            </MediaQuery>
                        </Flex>
                    </ExtraBreakpointWrapper>
                    <HealthCardRecommendation text={recommendation} textOverflow={false} />
                </Flex>
            </ExtraBreakpointWrapper>
        </Card>
    );
};

HealthCard.propTypes = {
    type: PropTypes.string,
    datum: PropTypes.object
};
