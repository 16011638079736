import React from "react";
import PropTypes from "prop-types";

import { SecondaryButton, PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";

export const ButtonVariant = ({ variant = "primary", ...rest }) =>
    variant === "primary" ? <PrimaryButton {...rest} /> : <SecondaryButton {...rest} />;
ButtonVariant.propTypes = { variant: PropTypes.oneOf(["primary", "secondary"]) };

export const MetricCardBtnPrimary = props => <ButtonVariant width="172px" height="32px" fontSize="14px" {...props} />;

export const MetricCardBtnSecondary = props => (
    <MetricCardBtnPrimary bg="transparent" variant="secondary" color="hpPrimaryPurple" {...props} />
);

export const OutcomeCardBtnSecondary = props => (
    <MetricCardBtnSecondary width="100%" height="40px" fontSize="15px" {...props} />
);

export const ModalPrimaryButton = props => (
    <MetricCardBtnPrimary width="100%" maxWidth="250px" height="50px" fontSize="16px" {...props} />
);

export const ModalSecondaryButton = props => (
    <MetricCardBtnSecondary width="100%" maxWidth="250px" height="50px" fontSize="16px" {...props} />
);
