import styled from "styled-components";

import { Text, Box } from "rebass";

export const PieContainer = styled.svg`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

export const PieBackground = styled(Box)`
    top: 50%;
    left: 50%;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
`;

export const PieCircle = styled.circle`
    fill: none;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    transform-origin: center;
    transition: stroke-dashoffset 1000ms cubic-bezier(0.5, 0.3, 0.77, 1);
`;

export const PieText = styled(Text)`
    top: 50%;
    left: 50%;
    line-height: 0.94;
    text-align: center;
    position: absolute;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
`;
