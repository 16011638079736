import React from "react";
import PropTypes from "prop-types";

import CardStack from "components/Cards/CardStack";
import HRACard from "./hraCard";
import HeartAgeCard from "./heartAgeCard";
import SleepCard from "./sleepCard";
import SisuStationCard from "./sisuCard";
import BodyCompositionCard from "./bodyComposition";
import { isNil } from "ramda";

const MetricCardArrangement = ({
    canBePremium,
    sleepReportResults,
    lifestyle,
    heartAge,
    isPremium,
    tdee: metricData,
    ...rest
}) => {
    const isSleepReportAvailable = !!sleepReportResults;

    const { muscleMass } = metricData;

    return (
        <CardStack pb="60px" {...rest}>
            {/* Temporarily disable the HRA until it can be translated */}
            <HRACard lifestyle={lifestyle} />

            {canBePremium && <HeartAgeCard {...heartAge} isPremium={isPremium} data-testid="summary-heart-age" />}
            {canBePremium && !isNil(muscleMass) && (
                <BodyCompositionCard value={metricData} isPremium={isPremium} data-testid="summary-body-composition" />
            )}
            {!canBePremium && <SisuStationCard detailed={!isSleepReportAvailable} data-testid="summary-station-card" />}
            {isSleepReportAvailable && <SleepCard citizen={{ sleepReportResults }} data-testid="summary-sleep-card" />}
        </CardStack>
    );
};

MetricCardArrangement.propTypes = {
    canBePremium: PropTypes.bool,
    sleepReportResults: PropTypes.object,
    lifestyle: PropTypes.object,
    heartAge: PropTypes.object,
    isPremium: PropTypes.bool,
    tdee: PropTypes.object
};

export default MetricCardArrangement;
