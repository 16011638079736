import { matchSize, rgba, generateGlobalTypographyStyle } from "./utils";
import { css } from "styled-components";
import { baseTheme, stationTheme } from "./theme";

// Iterate through the sizes and create a media template
const media = Object.keys(baseTheme.mediaQuerySizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
        @media (max-width: ${baseTheme.mediaQuerySizes[label] / 16}em) {
            ${css(...args)}
        }
    `;

    return acc;
}, {});

export { baseTheme as default, stationTheme, media, matchSize, rgba, generateGlobalTypographyStyle };
