import { isNaN } from "lodash";
import { all } from "ramda";

/**
 * @param {InputEvent | KeyboardEvent} event
 * @return {string}
 */
export const getValueFromEvent = event => event.target.value || "";

/**
 * @param {string} value
 * @return {boolean}
 */
export const isNumber = (value = "") => {
    const stringValue = String(value)
        .replace(/\s+/g, "")
        .trim();
    return !isNaN(Number(stringValue));
};

/**
 * @param {InputEvent | KeyboardEvent} event
 * @return {boolean}
 */
export const checkIfEventValueIsNumber = event => {
    const value = getValueFromEvent(event);
    return isNumber(value);
};

/**
 *
 * @param {InputEvent | KeyboardEvent} event
 * @param {string} delimeter
 * @return {boolean}
 */
export const checkIfEventValueIsNumberWithDelimeter = (event, delimeter = "/") => {
    const value = getValueFromEvent(event);
    return all(isNumber)(value.split(delimeter));
};
