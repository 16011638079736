import { Box, Flex } from "rebass";
import { height } from "styled-system";
import styled from "styled-components";

const ResponsiveBox = styled(Box)(height);

ResponsiveBox.propTypes = {
    ...Box.propTypes,
    ...height.propTypes
};

const ResponsiveFlex = styled(Flex)(height);

ResponsiveFlex.propTypes = {
    ...Box.propTypes,
    ...height.propTypes
};

export { ResponsiveBox, ResponsiveFlex };
