import BloodPressure from "./BloodPressure";
import BodyMassIndex from "./BodyMassIndex";
import HealthRiskAssessment from "./HealthRiskAssessment";
import BodyFat from "./BodyFat";
import HeartRate from "./HeartRate";
import AustralianDiabetesRisk, { AusDiabetesGuidelineDefinition } from "./AusDiabetesRisk/AusDiabetesRisk";
import NetPromoterScore from "./NetPromoterScore";
import WaistCircumference from "./WaistCircumference";
import TotalCholesterol from "./TotalCholesterol";
import FastingGlucose from "./FastingGlucose";
import NonFastingGlucose from "./NonFastingGlucose";
import HDLCholesterol from "./HDLCholesterol";
import { StressPss4 as StressTest } from "./Stress/SiSU";
import * as PSS4 from "./Stress/SiSU";
import * as Qrisk from "./Qrisk/SiSU";
import Alcohol from "./Alcohol/SiSU";
import Activity from "./Activity/SiSU";
import Weight from "./Weight";
import HRAIndex from "./HRAIndex";
import Sleep from "./Sleep";
import Height from "./Height";
import Smoking from "./Smoking";
import * as HeartAge from "./HeartAge";
import * as DailyEnergyUse from "./DailyEnergyUse";
import * as MuscleMass from "./MuscleMass";

export default {
    AusDiabetesGuidelineDefinition,
    AustralianDiabetesRisk,
    BloodPressure,
    BodyFat,
    BodyMassIndex,
    FastingGlucose,
    HDLCholesterol,
    HealthRiskAssessment,
    HeartRate,
    NetPromoterScore,
    NonFastingGlucose,
    TotalCholesterol,
    WaistCircumference,
    StressTest,
    PSS4,
    Qrisk,
    Alcohol,
    Activity,
    Weight,
    HRAIndex,
    Sleep,
    Height,
    Smoking,
    HeartAge,
    DailyEnergyUse,
    MuscleMass
};
