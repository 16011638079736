import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.svg`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    fill: ${props => props.fill};
    -webkit-transform: ${props => (props.webKitTransform ? props.webKitTransform : "")};
`;

const Chevron = ({
    top = false,
    left = false,
    right = false,
    width,
    height,
    fill,
    stroke,
    viewBox,
    strokeWidth,
    strokeLinecap,
    strokeLinejoin,
    transform,
    ...rest
}) => {
    let contolledTransform = transform;
    if (top) contolledTransform = "rotate(180deg)";
    if (left) contolledTransform = "rotate(90deg)";
    if (right) contolledTransform = "rotate(-90deg)";

    return (
        <Container
            width={width}
            height={height}
            fill={fill}
            viewBox={viewBox}
            {...rest}
            webKitTransform={contolledTransform}
            transform={contolledTransform && contolledTransform.replace("deg", "")}
        >
            <path
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeLinecap={strokeLinecap}
                strokeLinejoin={strokeLinejoin}
                d="M8.824 11l6 6 6-6"
            />
        </Container>
    );
};

Chevron.propTypes = {
    top: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    viewBox: PropTypes.string,
    strokeWidth: PropTypes.string,
    strokeLinecap: PropTypes.string,
    strokeLinejoin: PropTypes.string,
    transform: PropTypes.string,
    rest: PropTypes.shape(Container.propTypes)
};

Chevron.defaultProps = {
    width: "28",
    height: "28",
    fill: "none",
    stroke: "#000",
    viewBox: "0 0 28 28",
    strokeWidth: "1px",
    strokeLinecap: "round",
    strokeLinejoin: "round"
};

export default Chevron;
