import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";

import styled, { ThemeContext } from "styled-components";
import { Flex, Box } from "rebass";
import { useLocation, useHistory } from "react-router";
import { useSelector } from "react-redux";
import { Fade } from "react-bootstrap";

// --------------------------------------------------------------------
import ExtraBreakpointWrapper from "components/ExtraBreakpointWrapper";
import { MediaQuery } from "@sisuwellness/web-components";
import AuthenticatedHeader from "components/AuthenticatedHeader";
import LeftNavBar from "components/LeftNavBar";
import { isRouteStatic } from "utilities/leftNav";
import BaseMemberViewWithoutMenu from "components/BaseMemberViewWithoutMenu";
import { media } from "@sisuwellness/web-components/themes";
import { MessageContext } from "components/Context";
import { isEmpty } from "ramda";
import MessageBanner from "components/MessageBanner";
import MenuOverlay from "./menu.js";
import Footer from "components/Footer";
import { useConversionModal } from "components/ContextProviders";
import useGtm from "hooks/use-gtm";
// --------------------------------------------------------------------

const PAGE_SCROLL_OFFSET = 470;

// --------------------------------------------------------------------

// Wrapper for the page layout
const Container = styled(Flex)`
    width: calc(100vw - (100vw - 100%));
`;

// Wrapper for header
const HeaderWrapper = styled(Box)`
    top: 0;
    z-index: 999;
    position: fixed;
    background-color: ${props => props.theme.colours.hpNavBackground.hex};
    width: calc(100vw - (100vw - 100%));
    box-shadow: ${props => (props.boxShadow ? props.theme.portalShadows.card3 : "none")};
`;

// Wrapper for page content with max-width constraints
const PageConstraint = styled(Box)`
    width: 100%;
    max-width: 1440px;
    box-sizing: border-box;
    position: relative;
`;

// Wrapper for sidebar content (if exists)
const Sidebar = styled(Box)`
    z-index: 2;
    min-height: 100vh;
    position: ${({ float }) => (float ? "absolute" : "")};
`;

// Wrapper for message banners
const MessageContainer = styled(Box)`
    z-index: 99;
    position: fixed;
    top: ${({ top }) => top}px;
    left: ${({ left }) => left[2]};
    transform: ${({ isSidebarVisible }) => (isSidebarVisible ? "" : "translateX(-50%)")};

    ${media.tablet`
        left: ${({ left }) => left[1]}
        transform: translateX(0);
    `}

    ${media.mobile`
        left: ${({ left }) => left[0]}
        transform: translateX(0);
    `}
`;

// eslint-disable-next-line react/prop-types
const ResponsiveSidebar = ({ float = false, height, sidebarRef, children }) => (
    <ExtraBreakpointWrapper screen="macAndTab" minWidth="200px">
        <Sidebar minWidth="230px" pt={height - 24} float={float} ref={sidebarRef}>
            {children}
        </Sidebar>
    </ExtraBreakpointWrapper>
);

// --------------------------------------------------------------------

const PageLayout = ({ children, containerProps, headerProps, ...rest }) => {
    const theme = useContext(ThemeContext);
    const { premium: { isPremium = false } = {} } = useSelector(state => state.auth.citizen);

    const { state, pathname, search } = useLocation();
    const history = useHistory();
    const citizen = useSelector(state => state.auth.citizen);
    const { trackSignUpSucessful, trackLoginSucessful } = useGtm();

    useEffect(() => {
        if (authenticated && state && (state.viaSignUp || state.viaLogin)) {
            const {
                premium: { isInterestedInPremium = false, isPremium = false }
            } = citizen;
            const visitorType =
                isPremium || isInterestedInPremium ? (isPremium ? "premium" : "interested in premium") : "core";
            if (state.viaSignUp) {
                trackSignUpSucessful(visitorType);
            }
            if (state.viaLogin) {
                trackLoginSucessful(visitorType);
            }
            history.replace({ pathname, state: { ...state, viaSignUp: false, viaLogin: false }, search });
        }
    }, []);

    // calculate dynamic height for header
    const [height, setHeight] = useState(0);

    // calculate dynamic width for sidebar
    const [width, setWidth] = useState(0);

    // toggle menu button on authenticated header for non-static routes (Eg: /trends/:metric)
    const [visible, setVisible] = useState(false);

    // message object { error, success, general } from message context
    const { message, isDismissible, handleMessage } = useContext(MessageContext);

    // boolean flag to check if user authenticated
    const { authenticated } = useSelector(state => state.auth);

    const currentRoute = useLocation().pathname.toLowerCase();
    // check if current route is state or non-static
    const isFloat = !isRouteStatic(currentRoute);

    // dynamically calculate width || height for Html Elements
    const calculateWidthForRef = element => element && setWidth(element.getBoundingClientRect().width);
    const calculateHeightForRef = element => element && setHeight(element.getBoundingClientRect().height + 24);

    // Handle LHS scroll
    useEffect(() => {
        document.addEventListener("scroll", handleOffsetForScroll);
        return () => document.removeEventListener("scroll", handleOffsetForScroll);
    }, []);

    const { triggerModal } = useConversionModal();
    useEffect(() => {
        if (authenticated && search && (new URLSearchParams(search).get("modal") === "premium-upgrade") & !isPremium) {
            triggerModal(true);
        }
    }, [search]);

    // Scroll LHS navigation if Page height is less than
    // PAGE_SCROLL_OFFSET value else the position of LHS nav is fixed
    const handleOffsetForScroll = () => {
        const footer = document.getElementById("member-portal-footer");
        const leftNav = document.getElementById("left-nav");
        if (footer && leftNav) {
            const footerOffsetFromTop = footer.offsetTop - window.pageYOffset;
            leftNav.style.transform =
                footerOffsetFromTop <= PAGE_SCROLL_OFFSET
                    ? `translateY(${-PAGE_SCROLL_OFFSET + footerOffsetFromTop}px)`
                    : (leftNav.style.transform = `translateY(0px)`);
        }
    };
    return (
        <MenuOverlay {...{ authenticated }}>
            {(handleTranslate = () => {}) => (
                <Container
                    height="max-content"
                    id="outer-container"
                    flexDirection="column"
                    bg={theme.colours.hpWhite.hex}
                    {...containerProps}
                >
                    <HeaderWrapper
                        boxShadow={authenticated}
                        minHeight={["50px", "70px"]}
                        {...headerProps}
                        ref={calculateHeightForRef}
                    >
                        <PageConstraint bg="transparent" px={["", "16px", "40px"]} mx="auto">
                            {authenticated ? (
                                <AuthenticatedHeader {...{ visible, setVisible, handleTranslate }} />
                            ) : (
                                <BaseMemberViewWithoutMenu />
                            )}
                        </PageConstraint>
                    </HeaderWrapper>

                    <Fade in={!isEmpty(message)} unmountOnExit>
                        <MessageContainer
                            top={height - 32} // calculate distance from top wrt header height
                            isSidebarVisible={authenticated || !isFloat} // adjust properties if LHS is not visible (unauthenticated user)
                            width={["100%", `calc(100% - ${width}px)`, "720px"]} // calculate width wrt to LHS navigaiton bar
                            left={["0px", `${width}px`, `calc(50% - ${width}px)`]} // calculate left margin wrt to LHS navigation bar
                            data-testid="message-banner"
                        >
                            <MessageBanner {...{ message, isDismissible, handleMessage }} />
                        </MessageContainer>
                    </Fade>

                    <Container id="page-content" mx="auto" maxWidth="1440px" {...rest}>
                        <MediaQuery devices={["desktop"]}>
                            {authenticated &&
                                (isFloat ? (
                                    <Fade in={visible} unmountOnExit>
                                        <ResponsiveSidebar
                                            float={isFloat}
                                            sidebarRef={calculateWidthForRef}
                                            height={height}
                                        >
                                            <LeftNavBar height="100vh" id="left-nav" />
                                        </ResponsiveSidebar>
                                    </Fade>
                                ) : (
                                    <ResponsiveSidebar sidebarRef={calculateWidthForRef} height={height}>
                                        <LeftNavBar id="left-nav" />
                                    </ResponsiveSidebar>
                                ))}
                            <PageConstraint
                                pt={height}
                                px={isFloat ? ["16px", "40px", "40px"] : ["16px", "24px", "24px"]}
                                mx="auto"
                                minHeight="100vh"
                            >
                                {children}
                            </PageConstraint>
                        </MediaQuery>
                        <MediaQuery devices={["mobile", "tablet"]}>
                            <PageConstraint pt={height} minHeight="100vh" px={["13px", "24px"]}>
                                {children}
                            </PageConstraint>
                        </MediaQuery>
                    </Container>
                    <Footer />
                </Container>
            )}
        </MenuOverlay>
    );
};

PageLayout.propTypes = {
    children: PropTypes.any,
    headerProps: PropTypes.shape(Box.propTypes),
    containerProps: PropTypes.shape(Flex.propTypes)
};

export { PageConstraint, HeaderWrapper };
export default PageLayout;
