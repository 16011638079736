import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CampaignAd, AD_SLOT_CODES } from "@sisuwellness/utilities/CampaignAd";
import CardBase from "@sisuwellness/web-components/components/Cards/base";
import APIClient from "../utilities/APIClient";
import { AdSlots } from "@sisuwellness/web-components";
import { useAsyncData } from "@sisuwellness/utilities/hooks";

const StyledCard = styled(CardBase)``;

const AdSlotView = ({ adSlotCode, campaignId, criteria, replacements, static: isStatic, children }) => {
    const AsyncAction = isStatic
        ? () => {}
        : () => APIClient.ads.getAdContent(campaignId, adSlotCode, criteria, replacements);
    const [{ isLoading, isError, data }] = useAsyncData(AsyncAction, null);

    const campaignAdUtility = new CampaignAd(data);

    function getLayout() {
        switch (adSlotCode) {
            case AD_SLOT_CODES.RECOMMENDATIONS_LIST_SLOT1:
            case AD_SLOT_CODES.RECOMMENDATIONS_LIST_SLOT2:
            case AD_SLOT_CODES.RECOMMENDATIONS_LIST_SLOT3:
                return getDefaultLayout();

            case AD_SLOT_CODES.PORTAL_HEALTH_CHECK_LIST_SLOT_ONE:
            case AD_SLOT_CODES.PORTAL_HEALTH_CHECK_VIEW_SLOT_ONE:
                return (
                    <AdSlots.ThinImageBanner
                        schema={{
                            src: campaignAdUtility.imageUrl,
                            background: "transparent"
                        }}
                    ></AdSlots.ThinImageBanner>
                );
            case AD_SLOT_CODES.PORTAL_RECOMMENDATIONS_SLOT_ONE:
                return (
                    <AdSlots.TallImageBanner
                        schema={{
                            src: campaignAdUtility.imageUrl,
                            background: "transparent"
                        }}
                    ></AdSlots.TallImageBanner>
                );
            case AD_SLOT_CODES.PORTAL_HEALTH_HUB_FOOTER:
                return (
                    <AdSlots.PortalFooterBanner
                        schema={{
                            src: campaignAdUtility.imageUrl,
                            background: "transparent"
                        }}
                    ></AdSlots.PortalFooterBanner>
                );
            case AD_SLOT_CODES.PORTAL_HEALTH_HUB_TOP_ROW: {
                return (
                    <AdSlots.DoubleHeightAdSlot
                        schema={{
                            src: campaignAdUtility.imageUrl,
                            background: "transparent"
                        }}
                    ></AdSlots.DoubleHeightAdSlot>
                );
            }
            // static set of codes
            case "thinClientAd1":
            case "wideClientAd1":
                return (
                    <AdSlots.ThinImageBanner
                        schema={{
                            src:
                                "https://s3-us-west-2.amazonaws.com/sisu-universe-staging/temporary/priceline-thin-banner.png",
                            background: "#ef53a3"
                        }}
                    ></AdSlots.ThinImageBanner>
                );
            case "tallClientAd1":
                return (
                    <AdSlots.TallImageBanner
                        schema={{
                            src:
                                "https://s3-us-west-2.amazonaws.com/sisu-universe-staging/temporary/priceline-tall-banner.png",
                            background: "#ef53a3"
                        }}
                    ></AdSlots.TallImageBanner>
                );
            default:
                return null;
        }
    }

    function getDefaultLayout() {
        return <StyledCard>{children}</StyledCard>;
    }

    if (!isStatic && (isLoading || isError || !data)) {
        return null;
    }

    return getLayout();
};

AdSlotView.propTypes = {
    adSlotCode: PropTypes.string.isRequired,
    campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    criteria: PropTypes.object,
    replacements: PropTypes.array,
    static: PropTypes.bool,
    children: PropTypes.node
};

export default AdSlotView;
