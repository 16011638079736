import React, { useContext } from "react";
import PropTypes from "prop-types";

import { compose, isNil } from "ramda";
import { ThemeContext } from "styled-components";
import { withRouter } from "react-router-dom";

// -------------------------------------------------------------------------------------------

import { withPrintable } from "@sisuwellness/web-components";
import { usePremiumFeature } from "components/PremiumFeatures";

import {
    bloodPressureDef,
    bmiDef,
    bodyFatPercentDef,
    diabetesRiskDef,
    heartAgeDef,
    heartRateDef,
    placeAndDateDef,
    qriskDef,
    weightDef,
    muscleMassDef
} from "./columns";
import Table from "./table";

// -------------------------------------------------------------------------------------------

const HealthCheckDataTable = ({ healthChecks, settings }) => {
    const { isInterestedInPremium, isPremium, canBePremium } = usePremiumFeature();

    const {
        icons: { types: iconTypes = {} }
    } = useContext(ThemeContext);

    const hasDiabetes = healthChecks.filter(h => h.ausdriskScoreValue).length > 0;
    const hasQrisk = healthChecks.filter(h => h.qriskScore).length > 0;

    const hasMuscleMass = healthChecks.some(h => !isNil(h.muscleMass));

    const columnDefs = [
        placeAndDateDef(),
        weightDef(iconTypes, settings),
        bmiDef(iconTypes),
        bodyFatPercentDef(iconTypes),
        bloodPressureDef(iconTypes),
        heartRateDef(iconTypes),
        canBePremium ? heartAgeDef(iconTypes, { isInterestedInPremium, isPremium }) : null,
        canBePremium && hasMuscleMass ? muscleMassDef(iconTypes, { isInterestedInPremium, isPremium, settings }) : null,
        hasDiabetes ? diabetesRiskDef(iconTypes) : null,
        hasQrisk ? qriskDef(iconTypes) : null
    ].filter(x => x);

    return (
        <Table
            data={healthChecks}
            defaultPageSize={healthChecks.length}
            pageSize={healthChecks.length}
            columns={columnDefs}
        />
    );
};

HealthCheckDataTable.propTypes = {
    printable: PropTypes.bool,
    history: PropTypes.object.isRequired,
    settings: PropTypes.object.isRequired,
    healthChecks: PropTypes.arrayOf(PropTypes.object)
};

HealthCheckDataTable.defaultProps = {
    healthChecks: []
};

export default compose(
    withRouter,
    withPrintable
)(HealthCheckDataTable);
