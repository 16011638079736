import React from "react";
import MultipleTexts from "../../Survey/QuestionTypes/MultipleTexts";
import PropTypes from "prop-types";

const Fields = [
    {
        id: "medicare-number-1",
        label: "10-digit Medicare number",
        value: "",
        placeholder: "1234567890",
        regex: /^[0-9]{10}$/g,
        proportion: 4
    },
    {
        id: "medicare-number-2",
        label: "Ref",
        value: "",
        placeholder: "1",
        regex: /^[0-9]$/g,
        proportion: 1
    }
];

const MedicareNumberInput = ({ helpText = "", onFocusChanged = () => {}, onValuesChanged = () => {}, errors = [] }) => {
    return (
        <MultipleTexts
            key={"medicare-number"}
            helpText={helpText}
            fields={Fields}
            onFocusChanged={e => onFocusChanged(e)}
            onValuesChanged={e => onValuesChanged(e)}
            errors={errors}
            jointCharacter={"-"}
        />
    );
};

MedicareNumberInput.propTypes = {
    helpText: PropTypes.string,
    onFocusChanged: PropTypes.func,
    onValuesChanged: PropTypes.func,
    errors: PropTypes.array
};

export default MedicareNumberInput;
