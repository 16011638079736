/*
 https://en.wikipedia.org/wiki/Cole%E2%80%93Cole_equation
 The Cole-Cole model in frequency domain:

                      R0 - Rinf
    Z(w) = Rinf + -------------------
				  1 + (j.w.tau)^alpha

 The following is the magnitude of the model, |Z(w)|,
 for a given frequency and a set of parameters.
 */
export const coleModelMagnitude = ([R0, Rinf, alpha, tau]) => w => {
    // derived parameters
    const k1 = w * w * tau * tau;
    const k2 = Math.cos(Math.PI * alpha * 0.5);
    const p = Math.pow(k1, alpha * 0.5);

    // numerator
    const numA = p * p * Rinf * Rinf;
    const numbB = 2 * Rinf * p * k2 * R0;
    const numC = R0 * R0;
    const num = numA + numbB + numC;

    // denominator
    const den = 1 + 2 * p * k2 + p * p;

    // |Z|
    return Math.sqrt(num / den);
};
