import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import APIClient from "../../utilities/APIClient";
import UserJourney from "@sisuwellness/web-components/components/UserJourneys/UserJourney";
import { UserJourney as UserJourneyModel } from "@sisuwellness/utilities/UserJourney";
import BaseMemberViewWithMenu from "../../components/BaseMemberViewWithMenu";
import LoadingView from "components/LoadingView";

class UserJourneyView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userJourneyModel: null,
            complete: false
        };

        this.handleComplete = this.handleComplete.bind(this);
    }

    componentDidMount() {
        APIClient.userJourneys
            .getResource(this.props.match.params.userJourneyId, true)
            .then(response => {
                if (response.succeeded()) {
                    return response.body();
                } else {
                    this.setState({ userJourneyModel: null });
                }

                return false;
            })
            .then(body => {
                if (body) {
                    let userJourneyModel = new UserJourneyModel(body);
                    this.setState({ userJourneyModel });
                }
            });
    }

    handleComplete() {
        this.setState({ complete: true });
    }

    render() {
        let component = null;
        if (this.state.complete) {
            component = <Redirect to={"/health-hub"} />;
        } else if (this.state.userJourneyModel) {
            component = <UserJourney model={this.state.userJourneyModel} onComplete={this.handleComplete} />;
        } else {
            component = <LoadingView />;
        }

        return <BaseMemberViewWithMenu>{component}</BaseMemberViewWithMenu>;
    }
}

UserJourneyView.displayName = "User Journey View";
UserJourneyView.propTypes = {
    match: PropTypes.object.isRequired
};

export default UserJourneyView;
