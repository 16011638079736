import CampaignAd from "./CampaignAd";

const AD_SLOT_CODES = {
    RECOMMENDATIONS_LIST_SLOT1: "RECOMMENDATIONS_LIST_SLOT1",
    RECOMMENDATIONS_LIST_SLOT2: "RECOMMENDATIONS_LIST_SLOT2",
    RECOMMENDATIONS_LIST_SLOT3: "RECOMMENDATIONS_LIST_SLOT3",
    PORTAL_HEALTH_CHECK_LIST_SLOT_ONE: "PORTAL_HEALTH_CHECK_LIST_SLOT_ONE",
    PORTAL_HEALTH_CHECK_VIEW_SLOT_ONE: "PORTAL_HEALTH_CHECK_VIEW_SLOT_ONE",
    PORTAL_RECOMMENDATIONS_SLOT_ONE: "PORTAL_RECOMMENDATIONS_SLOT_ONE",
    PORTAL_HEALTH_HUB_FOOTER: "PORTAL_HEALTH_HUB_FOOTER",
    PORTAL_HEALTH_HUB_TOP_ROW: "PORTAL_HEALTH_HUB_TOP_ROW"
};

export { CampaignAd, AD_SLOT_CODES };
