import CardStack from "components/Cards/CardStack";
import styled, { css } from "styled-components";
import { media } from "@sisuwellness/web-components/themes";

const maxWidth = width => css`
    & > * {
        max-width: ${width};
    }
`;

export const CustomCardStack = styled(CardStack)`
    ${maxWidth("100%")};

    ${media.tablet`
        ${maxWidth("unset")};
    `};

    ${media.mobile`
        ${maxWidth("unset")};
    `};
`;
