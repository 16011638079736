import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { matchSize } from "../../themes";
import { ErrorText } from "./";
import hideEye from "./assets/HideEye.svg";
import showEye from "./assets/ShowEye.svg";
import { t } from "i18next";

const TextFieldContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

const TogglePassword = styled.span`
    position: absolute;
    right: 0.5rem;
    bottom: 2rem;

    &:hover {
        cursor: pointer;

        & > div > div {
            opacity: 1;
        }
    }
`;

const ToggleContainer = styled.div`
    position: relative;
    width: 100%;
`;

const ToggleIcon = styled.img`
    width: 1.5rem;
    height: 1.5rem;
`;

const ToggleTooltip = styled.div`
    position: absolute;
    text-align: center;
    font-size: 0.7rem;
    width: 80px;
    right: -100%;
    bottom: 1.5rem;
    color: #eee;
    padding: 0.25rem 0.5rem;
    opacity: 0;
    border-radius: 3px;
    background-color: #666;
    transition: opacity 0.8s;
`;

const InputContainer = styled.div`
    position: relative;
`;

const StyledLabel = styled.label`
    display: block;
    font-size: ${props =>
        props.labelSize ||
        matchSize({
            medium: props.theme.text.heading5.sizeRem,
            large: props.theme.text.heading3.sizeRem
        })(props.theme.textField.size)};
    font-weight: ${props => props.theme.text.paragraph.weight};
`;

const StyledInput = styled.input.attrs(props => ({
    autoFocus: props.autoFocus,
    required: props.required,
    onChange: props.onChange,
    onFocus: props.onFocus,
    onInput: props.onInput,
    onBlur: props.onBlur
}))`
    border: 0;
    box-sizing: border-box;
    color: ${props => props.theme.colours.flat.gray.hex};
    font-family: ${props => (props.type === "password" ? "Verdana, sans-serif" : "inherit")};
    font-size: ${props =>
        props.textSize ||
        matchSize({
            medium: props.theme.text.heading4.sizeRem,
            large: props.theme.text.heading4.sizeRem
        })(props.theme.textField.size)};
    letter-spacing: ${props => (props.type === "password" ? "2px" : "inherit")};
    font-weight: normal;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 2px;
    padding-right: ${props => (props.isPassword ? "2.5rem" : "2px")};
    border-bottom: 2px solid
        ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.gray.hex)};
    transition: 0.5s border-bottom;

    :focus {
        border-bottom: 2px solid
            ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.blue.hex)};
        font-weight: ${props => (props.type === "password" ? "normal" : "bolder")};
        outline: none;
        color: ${props => props.theme.colours.flat.darkGray.hex};
    }

    ::placeholder {
        color: ${props => props.theme.colours.flat.gray.hex};
    }
`;

class TextField extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();

        this.textSpecs = {
            showPassword: t("web_components.forms.text_field.show_password"),
            hidePassword: t("web_components.forms.text_field.hide_password")
        };

        this.state = {
            isShowPasswordEnabled: false
        };
    }

    componentDidMount() {
        const { autoFocus, onFocus } = this.props;
        if (autoFocus && typeof onFocus == "function") {
            onFocus(this.inputRef.current);
        }
    }

    onFocus() {
        const { onFocus } = this.props;
        if (typeof onFocus == "function") {
            onFocus(this.inputRef.current);
        }
    }

    onBlur(e) {
        const { onBlur } = this.props;

        if (typeof onBlur == "function") {
            onBlur(e);
        }
    }

    onChange(e) {
        const { onChange } = this.props;

        if (typeof onChange == "function") {
            onChange(e);
        }
    }

    onInput(e) {
        const { onInput } = this.props;

        if (typeof onInput == "function") {
            onInput(e);
        }
    }

    render() {
        const {
            className,
            fieldId,
            labelSize,
            label,
            type,
            placeholder,
            name,
            autoFocus,
            required,
            textSize,
            disabled,
            autoComplete,
            forwardedRef,
            value,
            errors
        } = this.props;
        return (
            <TextFieldContainer className={`text-field-sd-${className}`}>
                <StyledLabel htmlFor={`text-field-id-${fieldId}`} labelSize={labelSize}>
                    {label}
                </StyledLabel>
                <InputContainer>
                    <StyledInput
                        id={`text-field-id-${fieldId}`}
                        className={`text-field-sd-input-${className}`}
                        type={this.state.isShowPasswordEnabled ? "text" : type}
                        isPassword={type === "password"}
                        placeholder={placeholder || value}
                        name={name}
                        autoFocus={autoFocus}
                        required={required}
                        onChange={e => this.onChange(e)}
                        onInput={e => this.onInput(e)}
                        onFocus={() => this.onFocus()}
                        onBlur={e => this.onBlur(e)}
                        ref={forwardedRef || this.inputRef}
                        textSize={textSize}
                        disabled={disabled}
                        autoComplete={autoComplete}
                        defaultValue={value}
                        aria-label={!label && name}
                        errors={errors}
                    />
                    {type === "password" && (
                        <TogglePassword>
                            <ToggleContainer>
                                <ToggleIcon
                                    src={!this.state.isShowPasswordEnabled ? showEye : hideEye}
                                    alt="toggle-password-visibility"
                                    data-toggle="tooltip"
                                    onClick={() => {
                                        this.setState({ isShowPasswordEnabled: !this.state.isShowPasswordEnabled });
                                    }}
                                />
                                <ToggleTooltip>
                                    {!this.state.isShowPasswordEnabled
                                        ? this.textSpecs.showPassword
                                        : this.textSpecs.hidePassword}
                                </ToggleTooltip>
                            </ToggleContainer>
                        </TogglePassword>
                    )}
                </InputContainer>
                {errors && <ErrorText>{t(errors)}</ErrorText>}
            </TextFieldContainer>
        );
    }
}

TextField.displayName = "TextField";
TextField.propTypes = {
    autoFocus: PropTypes.any,
    name: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    fieldId: PropTypes.string.isRequired,
    className: PropTypes.string,
    required: PropTypes.bool,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onInput: PropTypes.func,
    onBlur: PropTypes.func,
    labelSize: PropTypes.string,
    textSize: PropTypes.string,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.string,
    forwardedRef: PropTypes.object,
    value: PropTypes.string,
    errors: PropTypes.string
};

TextField.defaultProps = {
    type: "text"
};

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => <TextField {...props} forwardedRef={ref} />);
