import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Flex, Box, Heading } from "rebass";
import { Next } from "../Buttons";
import MediaQuery from "../../MediaQuery/index";
import CardBase from "../../Card/index";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const StyledImageContainer = styled(CardBase)`
    padding: 2rem 2rem;
    text-align: left;
`;

const StyledIntroWrapper = styled.div`
    margin: auto;
    max-width: 900px;
`;

const Intro = ({ theme, title = "", text = "", imageUrl, onNext }) => {
    const { t } = useTranslation();
    const parsedText = parse(text);
    const textAreaWidth = imageUrl ? 3 / 5 : 1;
    const handleOnNext = e => {
        if (onNext) {
            onNext(e);
        }
    };

    return (
        <StyledIntroWrapper data-testid="survey-intro">
            <Flex justifyContent="center" alignItems="top">
                {imageUrl && (
                    <MediaQuery devices={["desktop", "tablet"]}>
                        <Box p={3} width={2 / 5} color="white" textAlign="center">
                            <StyledImageContainer>
                                <img src={imageUrl} alt="" width="100%" />
                            </StyledImageContainer>
                        </Box>
                    </MediaQuery>
                )}
                <Box p={3} width={[1, textAreaWidth, textAreaWidth]} color="black">
                    <CardBase>
                        <Box p={[2, 3, 3]}>
                            <Heading fontSize={7} color="primary" data-testid="survey-intro-title">
                                {title}
                            </Heading>
                            <p data-testid="survey-intro-para">{parsedText}</p>
                        </Box>
                    </CardBase>
                </Box>
            </Flex>
            <Flex justifyContent="center" flexWrap="wrap-reverse">
                <Box p={[0, 0, 3]} width={[1, 2 / 5, 2 / 5]} color="black">
                    <Flex flexWrap="wrap" justifyContent="space-between" listStyle="none" textAlign="center" p={3}>
                        <Box
                            p={[2, 0, 0]}
                            width={[1, 1 / 2, 1 / 2]}
                            fontSize={theme.text.paragraph.size}
                            textAlign="left"
                        >
                            <a
                                href="https://www.sisuhealthgroup.com/privacy-policy-au/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("web_components.survey.intro.privacy_policy_au")}
                            </a>
                        </Box>
                        <Box
                            p={[2, 0, 0]}
                            width={[1, 1 / 2, 1 / 2]}
                            fontSize={theme.text.paragraph.size}
                            textAlign="left"
                        >
                            <a
                                href="https://www.sisuhealthgroup.com/privacy-policy-eu/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t("web_components.survey.intro.privacy_policy_uk")}
                            </a>
                        </Box>
                    </Flex>
                </Box>
                <Box p={3} width={[1, 3 / 5, 3 / 5]} color="black" textAlign="right">
                    <MediaQuery devices={["desktop", "tablet"]}>
                        <Next variant="primary" mr={2} onClick={e => handleOnNext(e)}>
                            {t("words.continue")}
                        </Next>
                    </MediaQuery>
                    <MediaQuery devices={["mobile"]}>
                        <Next variant="primary" p={3} mr={3} fullWidth={true} onClick={e => handleOnNext(e)}>
                            {t("words.continue")}
                        </Next>
                    </MediaQuery>
                </Box>
            </Flex>
        </StyledIntroWrapper>
    );
};

Intro.propTypes = {
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    theme: PropTypes.object.isRequired,
    onNext: PropTypes.func
};

export default withTheme(Intro);
