import React from "react";

// ----------------------------------------------------------------

import FlexWithGap from "components/Flex";

import HeartAgeAffects from "./HeartAgeAffects";
import HeartAgeHistory from "./HeartAgeHistory";
import HeartAgeMore from "./HeartAgeMore";
import HeartAgeOverview from "./HeartAgeOverview";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------

const UnlockedHeartAge = () => {
    const { isCountryUK } = useSelector(state => state.deviceLocation);

    return (
        <FlexWithGap fd="column" gap="32px">
            <HeartAgeOverview />
            {!isCountryUK && <HeartAgeMore />}
            <HeartAgeAffects />
            <HeartAgeHistory />
        </FlexWithGap>
    );
};

export default UnlockedHeartAge;
