import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import moment from "moment";
import FlexCardWithIcon, { InnerContainer } from "./FlexCardWithIcon";
import { useTranslation } from "react-i18next";

const StyledInnerContainer = styled(InnerContainer)`
    padding: 0;
    align-items: stretch;
`;

const StyledStatsContainer = styled.div`
    flex: 1 0;
    padding-left: 1em;
`;

const StyledGuidelineContainer = styled.div`
    flex: 1 auto;
    width: 30%;
    //min-width: 100px;
    max-width: 30%;
    padding-right: 1em;
`;

const StyledTitle = styled.h6`
    font-size: ${props => props.theme.text.heading6.sizeRem};
    font-weight: normal;
    margin: 0.5em 0;
    padding: 0;
`;

const StyledStats = styled.p`
    margin: 0;
`;

const StyledPrimaryStatValue = styled.span`
    font-size: 1.7rem;
    font-family: "Proxima Nova Semibold", Arial, sans-serif;
    padding-right: ${props => (props.value === "-" ? "6px" : 0)};
`;

const StyledPrimaryStatUnit = styled.span`
    font-size: ${props => props.theme.text.paragraph.sizeRem};
    font-weight: normal;
`;

const StyledSecondaryStatValue = styled.span`
    font-size: ${props => props.theme.text.paragraph.sizeRem};
    font-weight: ${props => (props.value === "-" ? "normal" : "bold")};
    padding-left: ${props => (props.value === "-" ? "20px" : 0)};
    margin-left: 0.5em;
`;

const StyledSecondaryStatUnit = styled.span`
    font-size: ${props => props.theme.text.paragraph.sizeRem};
    font-weight: normal;
`;

const StyledDate = styled.p`
    font-size: ${props => props.theme.text.subText.sizeRem};
    font-weight: normal;
    margin: 0 0 0.4em;
`;

const StyledGuidelineRating = styled.p`
    color: ${props => props.colour};
    text-align: right;
    font-size: ${props => props.theme.text.feedback.sizeRem};
    font-weight: bold;
    margin-top: 0.75em;
`;

const StyledGuidelineRatingDetail = styled.p`
    display: none;

    color: ${props => props.colour};
    text-align: right;
    font-size: ${props => props.theme.text.paragraph.sizeRem};
    font-weight: normal;
    position: absolute;
    bottom: 0;
    right: 15px;
`;

const Attribute = ({
    gradientCSS: colourGradient,
    colour,
    guidelineRating,
    primaryStatValue,
    secondaryStatValue,
    date,
    guidelineRatingName,
    guidelineRatingInfo,
    title,
    icon,
    primaryStatUnit,
    secondaryStatUnit,
    theme,
    printable,
    noDataMessage
}) => {
    const { t } = useTranslation();
    let guidelineText = null;
    let guidelineDetail = null;

    if (primaryStatValue === null) {
        // force the zero-ing out of these props
        colourGradient = theme.colours.flat.gray.hex;
        guidelineRating = null;
        primaryStatValue = "-";
        secondaryStatValue = secondaryStatUnit ? primaryStatValue : null;
        date = null;
    } else {
        // FIXME: Date is a non ISO compliant string, which will yields unreliable results across browsers
        if (date) {
            date = moment(date)
                .locale("en")
                .format("D MMM YYYY");
        }

        if (guidelineRatingName || guidelineRatingInfo) {
            guidelineText = t("words.guideline");
            guidelineDetail = (
                <StyledGuidelineRatingDetail colour={colour}>
                    {guidelineRatingName}
                    <br />
                    {guidelineText}
                    <br />
                    {guidelineRatingInfo}
                </StyledGuidelineRatingDetail>
            );
        }
    }

    const isRightColToBeHidden = () => {
        if (guidelineRating == null || primaryStatValue == null) return true;

        return (
            typeof guidelineRating === "string" &&
            typeof primaryStatValue === "string" &&
            guidelineRating.trim().toLowerCase() === primaryStatValue.trim().toLowerCase()
        );
    };

    return (
        <FlexCardWithIcon
            iconType={icon}
            iconBackground={colourGradient}
            reverse={false}
            containerFlexAlign={"stretch"}
            printable={printable}
        >
            <StyledInnerContainer>
                <StyledStatsContainer>
                    <StyledTitle>{title}</StyledTitle>
                    <StyledStats>
                        {noDataMessage || (
                            <>
                                <StyledPrimaryStatValue value={primaryStatValue}>
                                    {primaryStatValue}
                                </StyledPrimaryStatValue>
                                <StyledPrimaryStatUnit>{primaryStatUnit}</StyledPrimaryStatUnit>
                                <StyledSecondaryStatValue value={secondaryStatValue}>
                                    {secondaryStatValue}
                                </StyledSecondaryStatValue>
                                <StyledSecondaryStatUnit>{secondaryStatUnit}</StyledSecondaryStatUnit>{" "}
                            </>
                        )}
                    </StyledStats>
                    {/* &nbsp; required to maintain card design when date does not have a value */}
                    <StyledDate>{date}&nbsp;</StyledDate>
                </StyledStatsContainer>
                {!isRightColToBeHidden() && (
                    <StyledGuidelineContainer>
                        <StyledGuidelineRating colour={colour} data-testid="guideline-rating">
                            {guidelineRating ? guidelineRating.toUpperCase() : ""}
                        </StyledGuidelineRating>
                        {guidelineDetail}
                    </StyledGuidelineContainer>
                )}
            </StyledInnerContainer>
        </FlexCardWithIcon>
    );
};

Attribute.displayName = "BaseAttribute";
Attribute.propTypes = {
    theme: PropTypes.any.isRequired,
    icon: PropTypes.string.isRequired,
    colour: PropTypes.string.isRequired,
    gradientCSS: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    primaryStatValue: PropTypes.any,
    primaryStatUnit: PropTypes.string,
    secondaryStatValue: PropTypes.string,
    secondaryStatUnit: PropTypes.string,
    date: PropTypes.string,
    guidelineRating: PropTypes.string,
    guidelineRatingName: PropTypes.string,
    guidelineRatingInfo: PropTypes.string,
    printable: PropTypes.bool,
    noDataMessage: PropTypes.string
};
Attribute.defaultProps = {
    colour: "darkGray"
};

export default withTheme(Attribute);
