import styled from "styled-components";

/**
 * Binding hasErrors for adding margin disgusts me but for some reason the Errors component would not accept any additional styles... path of least effort exerted
 * "Cake or Death"
 */
const StyledDisplayText = styled.label`
    font-family: "Proxima Nova Semibold", Arial, sans-serif;
    font-size: ${props => props.theme.text.heading3.sizeRem};
    margin-top: 0;
    color: ${props => props.theme.colours.flat.darkGray.hex};
    display: block;
`;

export default StyledDisplayText;
