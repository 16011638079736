import {
    ACTIVITY,
    ALCOHOL_RISK,
    BLOOD_PRESSURE,
    BODY_FAT,
    BODY_MASS_INDEX,
    DIABETES,
    HEART_RATE,
    QRISK3,
    TOTAL_CHOLESTEROL,
    HDL_CHOLESTEROL,
    NON_FASTING_GLUCOSE,
    STRESS_PSS4,
    WEIGHT,
    HRA_SOCIAL_SCORE,
    HRA_EMOTIONAL_SCORE,
    HRA_LIFESTYLE_SCORE,
    SLEEP,
    SMOKING,
    FASTING_GLUCOSE,
    WAIST_CIRCUMFERENCE,
    HRA_HEALTH_SCORE,
    HEART_AGE,
    TEN_YEAR_CVD_RISK,
    TDEE,
    MUSCLE_MASS
} from "constants/trend-charts";

export const METRIC_INDICATOR = {
    [BLOOD_PRESSURE]: {
        title: "words.blood_pressure",
        themeKey: "NationalHeartFoundationAustralia"
    },
    [BODY_MASS_INDEX]: {
        title: "words.body_mass_index",
        themeKey: "bodyMassIndex"
    },
    [BODY_FAT]: {
        title: "words.body_fat",
        themeKey: "bodyFat"
    },
    [HEART_RATE]: {
        title: "words.heart_rate",
        themeKey: "SiSUHeartRate"
    },
    [DIABETES]: {
        title: "words.diabetes",
        themeKey: "AustralianDiabetesRisk"
    },
    [STRESS_PSS4]: {
        title: "words.stress",
        themeKey: "PSS4"
    },
    [ALCOHOL_RISK]: {
        title: "words.alcohol",
        themeKey: "Alcohol"
    },
    [ACTIVITY]: {
        title: "words.activity",
        themeKey: "Activity"
    },
    [QRISK3]: {
        title: "words.qrisk",
        themeKey: "Qrisk"
    },
    [TOTAL_CHOLESTEROL]: {
        title: "words.total_cholesterol",
        themeKey: "SiSUTotalCholesterol"
    },
    [HDL_CHOLESTEROL]: {
        title: "words.hdl_cholesterol",
        themeKey: "SiSUHDLCholesterol"
    },
    [NON_FASTING_GLUCOSE]: {
        title: "words.non_fasting_glucose",
        themeKey: "SiSUNonFastingGlucose"
    },
    [WEIGHT]: {
        title: "words.weight",
        themeKey: "weight"
    },
    [HRA_SOCIAL_SCORE]: {
        title: "words.hra_social_score",
        themeKey: "SiSUHRAIndex"
    },
    [HRA_EMOTIONAL_SCORE]: {
        title: "words.hra_emotional_score",
        themeKey: "SiSUHRAIndex"
    },
    [HRA_LIFESTYLE_SCORE]: {
        title: "words.hra_lifestyle_score",
        themeKey: "SiSUHRAIndex"
    },
    [SLEEP]: {
        title: "words.sleep",
        themeKey: "Sleep"
    },
    [SMOKING]: {
        title: "words.smoking_status",
        themeKey: "Smoking"
    },
    [FASTING_GLUCOSE]: {
        title: "words.fasting_glucose",
        themeKey: "FastingGlucose"
    },
    [WAIST_CIRCUMFERENCE]: {
        title: "words.waist_circumference",
        themeKey: "WaistCircumference"
    },
    [HRA_HEALTH_SCORE]: {
        title: "words.hra_health_score",
        themeKey: "SiSUHealthRiskAssessment"
    },
    [HEART_AGE]: {
        title: "words.heart_age",
        themeKey: "HeartAge"
    },
    [TEN_YEAR_CVD_RISK]: {
        title: "words.ten_year_cvd_risk",
        themeKey: "HeartAge"
    },
    [TDEE]: {
        title: "words.tdee",
        themeKey: "DailyEnergyUse"
    },
    [MUSCLE_MASS]: {
        title: "words.skeletal_mass",
        themeKey: "muscleMass"
    }
};
