import React from "react";

import { Flex as Flexbox } from "rebass";
import styled from "styled-components";
import { border } from "styled-system";

// ------------------------------------------------------------------------

const Flex = styled(Flexbox)(border);

// ------------------------------------------------------------------------

const StatusCard = ({ children, ...rest }) => (
    <Flex
        minWidth="75px"
        px="10px"
        height="30px"
        alignItems="center"
        justifyContent="center"
        bg="#ff647c"
        borderRadius="2px"
        {...rest}
    >
        {children}
    </Flex>
);

StatusCard.propTypes = {
    ...Flex.propTypes
};

export default StatusCard;
