export function upBy(num, val) {
    return num + val;
}

export function downBy(num, val) {
    const downValue = num - val;
    return downValue > 0 ? downValue : 0;
}

export function splitIntoParts(min = 0, max = 0, noParts = 1, shouldRound = false) {
    let result = [],
        delta = (max - min) / (noParts - 1);
    while (min < max) {
        result.push(shouldRound ? Math.ceil(min) : min);
        min += delta;
    }
    if (result.length < noParts) {
        result.push(shouldRound ? Math.ceil(max) : max);
    } else {
        result[result.length - 1] = shouldRound ? Math.ceil(max) : max;
    }
    return result;
}

export function transformNumToDecimal(num, decimal) {
    return Number(num) ? Number(num).toFixed(decimal) : num;
}

export function roundToDec(decimal = 1) {
    if (Number.isInteger(decimal) && decimal >= 0) {
        return val => `${Number(val).toFixed(decimal)}`;
    }
    return val => val;
}
