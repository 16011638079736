import styled, { css } from "styled-components";

import { Box } from "rebass";
import { compose, position, background, typography } from "styled-system";

import { NormalLabel as Label } from "@sisuwellness/ui/src/components/Labels";
import FlexWithGap from "components/Flex";

export const BtnBox = styled(Box)`
    left: 0;
    bottom: 0;
    position: absolute;
`;

const clip = css`
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
export const CustomLabel = styled(Label)`
    ${({ textOverflow = true }) => textOverflow && clip}
`;

export const CustomBox = styled(FlexWithGap)(
    compose(
        position,
        background,
        typography
    )
);
