import { Calculate } from "@sisuwellness/utilities/Guidelines/HeartAge";
import { calculate as CalculateTDEE } from "@sisuwellness/utilities/Guidelines/DailyEnergyUse";
import { isNaN } from "lodash-es";
import * as R from "ramda";
import { SETTINGS } from "constants/";
import { UnitConverter } from "@sisuwellness/utilities";

/**
 * @param {number} value
 * @param {boolean} isPremium
 * @return {string}
 *
 */
export const getCtaText = (value, isPremium) =>
    isPremium
        ? Number.isFinite(value)
            ? "members_portal_web.utilities.premium.cta_text.learn_more"
            : "members_portal_web.utilities.premium.cta_text.find_station"
        : "members_portal_web.utilities.premium.cta_text.other";

/**
 * Get Heart Age Recommendation
 * @param {string} rating
 * @return {string}
 */
export const getHeartAgeRecommendation = rating => {
    switch (rating) {
        case "optimal":
            return "members_portal_web.utilities.premium.heart_age_recommendation.optimal";
        case "normal":
            return "members_portal_web.utilities.premium.heart_age_recommendation.normal";
        case "elevated":
            return "members_portal_web.utilities.premium.heart_age_recommendation.elevated";
        default:
            return "members_portal_web.utilities.premium.heart_age_recommendation.default";
    }
};

/**
 * Calculate Heart Age related parameters
 * @param {object} healthCheckData
 * @return {{ tenYearCvdRisk: number, tenYearCvdRiskNormal: number, tenYearCvdRiskOptimal: number, heartAge: number, heartAgeOptimal: number, rating: any, heartAgeRating: any }}
 */
export const calculateHeartAge = ({
    age,
    sys,
    bmi,
    gender,
    isSmoker = false,
    hasDiabetes = "",
    isOnBpMedication = ""
}) => {
    const mappedCvdParams = {
        age: Number(age),
        bmi: Number(bmi),
        isSmoker: !!isSmoker,
        systolic: Number(sys),
        gender: gender || null,
        hasDiabetes: hasDiabetes === "diabetic",
        isTakingBpMeds: isOnBpMedication === "yes-bp-meds"
    };

    // If any CVD param is nill or NaN
    if (
        R.values(mappedCvdParams)
            .map(params => R.isNil(params) || isNaN(params))
            .some(param => param)
    )
        return {};

    return Calculate(mappedCvdParams);
};

/**
 * Get Daily Energy Recommendation based on rating
 *
 * @param {"low" | "moderate" | "high" | string}  rating
 * @return {string}
 */
export const getTDEERecommendation = rating => {
    switch (rating) {
        case "low":
            return "members_portal_web.utilities.premium.tdee_recommendation.low";
        case "moderate":
            return "members_portal_web.utilities.premium.tdee_recommendation.moderate";
        case "high":
            return "members_portal_web.utilities.premium.tdee_recommendation.high";
        default:
            return "members_portal_web.utilities.premium.tdee_recommendation.default";
    }
};

/**
 *`Get Tdee metrics from citizen data or try to calculate if null
 * @param {object} data citizen data
 * @param {object} settings
 * @return {object}
 */
export const getTdeeMetricsFromCitizenData = (data, settings) => {
    const { dailyEnergyUse, basalMetabolicRate, dailyEnergyUseRating } = data;
    const payload = R.isNil(dailyEnergyUse)
        ? getTDEEMetricsWithUnitPreference(calculateTotalDailyEnergyExpenditure(data), settings)
        : {
              ...getTDEEMetricsWithUnitPreference(
                  {
                      bmrKj: basalMetabolicRate,
                      dailyEnergyUseKj: dailyEnergyUse,
                      bmrCals: UnitConverter.kJToKcal(basalMetabolicRate),
                      dailyEnergyUseCals: UnitConverter.kJToKcal(dailyEnergyUse)
                  },
                  settings
              ),
              dailyEnergyUseRating
          };

    return { ...data, ...payload };
};

/**
 * Get Daily Energy Metrics with Unit Preference
 *
 * @param {object} tdeeMetrics
 * @param {number} tdeeMetrics.bmrKj
 * @param {number} tdeeMetrics.bmrCals
 * @param {number} tdeeMetrics.dailyEnergyUseKj
 * @param {number} tdeeMetrics.dailyEnergyUseCals
 * @param {{ key: string, label: string, ranges: object }} tdeeMetrics.ratingKj
 * @param {{ key: string, label: string, ranges: object }} tdeeMetrics.ratingCals
 *
 * @param {object} settings
 * @param {string} settings.energyUnit
 *
 * @return {{ bmr: number, dailyEnergyUse: number, dailyEnergyUseRating: string, rating: { key: string, label: string, ranges: object }, unitInString: string }}
 */
export const getTDEEMetricsWithUnitPreference = (
    { bmrKj, dailyEnergyUseKj, ratingKj, bmrCals, dailyEnergyUseCals, ratingCals },
    settings
) => ({
    bmr: Math.round(isUnitInCalories(settings) ? bmrCals : bmrKj),
    rating: isUnitInCalories(settings) ? ratingCals : ratingKj,
    dailyEnergyUse: Math.round(isUnitInCalories(settings) ? dailyEnergyUseCals : dailyEnergyUseKj),
    dailyEnergyUseRating: isUnitInCalories(settings) ? ratingCals?.key : ratingKj?.key,
    unitInString: isUnitInCalories(settings) ? "cal/day" : "kJ/day"
});

/**
 * Map health check properties and calculate tdee metrics
 *
 * @param {object} healthCheck
 * @return {{ratingKj: (*|(*&{ranges: {high: {riskLevel, rangeInfo: string, label: string, key: string}, low: {riskLevel, rangeInfo: string, label: string, key: string}, moderate: {riskLevel, rangeInfo: string, label: string, key: string}}})), bmrCals: *, dailyEnergyUseCals: *, ratingCals: (*|(*&{ranges: {high: {riskLevel, rangeInfo: string, label: string, key: string}, low: {riskLevel, rangeInfo: string, label: string, key: string}, moderate: {riskLevel, rangeInfo: string, label: string, key: string}}})), bmrKj: *, dailyEnergyUseKj: *}}
 */
export const calculateTotalDailyEnergyExpenditure = ({
    weight,
    locale,
    isLactating,
    bodyFatPercent,
    physicalActivityLevel
} = {}) =>
    CalculateTDEE({
        locale,
        isLactating,
        weightKg: weight,
        bodyFatPercentage: bodyFatPercent,
        physicalActivityLevel: physicalActivityLevel
    });

/**
 *  Check if settings have energy unit in calories
 *
 * @param {{ energyUnit: string }} settings
 * @return {boolean}
 */
export const isUnitInCalories = ({ energyUnit } = {}) =>
    SETTINGS.ENERGY_UNIT[energyUnit] === SETTINGS.ENERGY_UNIT.kilocalories;
