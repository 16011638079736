import React from "react";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { Text, Image, Box } from "rebass";
import styled from "styled-components";

// -----------------------------------------------------------------------

import { media } from "@sisuwellness/web-components/themes";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { ModalBody } from "components/NotificationModal";
import { ExtraBreakpointWrapper } from "components/ExtraBreakpointWrapper";
import FlexWithGap from "components/Flex";
import { PORTAL_IMAGE } from "constants/imagePath";
import { useChargebee } from "utilities/chargebee";

import featureList from "./featureList";
import { useTranslation } from "react-i18next";

// -----------------------------------------------------------------------

const StyledImage = styled(Image)`
    left: 0;
    z-index: 1;
    bottom: 0;
    position: absolute;

    ${media.mobile`
        display: none;
    `}
`;

// -----------------------------------------------------------------------

const columnWidth = ["74px", "84px"];

export const ModalContent = ({ close, openModal: openPollingScreen }) => {
    const { t } = useTranslation();
    const Chargebee = useChargebee();
    const { isCountryUK } = useSelector(state => state.deviceLocation);

    const handleOnUpgrade = () => {
        close();
        Chargebee.openChargebeeCheckout({ callbacks: { openPollingScreen } });
    };

    return (
        <ExtraBreakpointWrapper screen="phablet" p="32px">
            <ModalBody p={["32px 24px", "40px"]} position="relative" data-testid="conversion-modal">
                <FlexWithGap fd="column" jc="center" gap={["4px", "8px"]} mb={["16px", "8px"]}>
                    <FlexWithGap jc="space-between" ai="flex-start">
                        <Text fontSize={["20px", "28px"]} color="hpPrimaryPurple" maxWidth="574px">
                            {t("members_portal_web.components.context_providers.conversion_modals.content.sisu_plus")}
                        </Text>
                        <Image
                            src={`${PORTAL_IMAGE}/cross.svg`}
                            pt={["4px", "8px"]}
                            height="24px"
                            width="24px"
                            onClick={close}
                            css={{ cursor: "pointer" }}
                        />
                    </FlexWithGap>
                    <Text fontSize={["15px", "17px"]} fontWeight="light" color="hpPrimaryPurple">
                        {t("members_portal_web.components.context_providers.conversion_modals.content.contracts")}
                    </Text>
                </FlexWithGap>

                <FlexWithGap ai="center" jc="flex-end" textAlign="center" mb="8px">
                    <FlexWithGap fd="column" width={columnWidth}>
                        <Text fontSize={["16px", "18px"]} color="blackTints900" fontWeight="intermediate">
                            {t("words.core")}
                        </Text>
                        <Text color="hpPurple700" fontSize={["11px", "15px"]}>
                            {t("words.free")}
                        </Text>
                    </FlexWithGap>

                    <FlexWithGap fd="column" width={columnWidth}>
                        <Text fontSize={["16px", "18px"]} color="blackTints900" fontWeight="intermediate">
                            {t("words.plus")}
                        </Text>
                        <Text color="hpPurple700" fontSize={["11px", "15px"]}>
                            {t("members_portal_web.components.context_providers.conversion_modals.content.price")}
                        </Text>
                    </FlexWithGap>
                </FlexWithGap>

                {featureList(isCountryUK).map(({ featureName, core, premium }, ix) => {
                    return (
                        <React.Fragment key={ix}>
                            <Box as="hr" height="2px" width="100%" bg="greyTints" />
                            <FlexWithGap
                                key={ix}
                                ai="center"
                                jc="space-between"
                                my={["5px", "10px"]}
                                color="blackTints900"
                                data-testid="feature-offering"
                            >
                                <ExtraBreakpointWrapper screen="phablet" width="150px">
                                    <Text width={["135px", "444px"]} fontSize={["13px", "15px"]}>
                                        {t(featureName)}
                                    </Text>
                                </ExtraBreakpointWrapper>
                                <FlexWithGap jc="center" ai="center" width={columnWidth}>
                                    {core && (
                                        <Image
                                            size="24px"
                                            src={`${PORTAL_IMAGE}/premium/green_tick.svg`}
                                            alt="feature-check"
                                        />
                                    )}
                                </FlexWithGap>
                                <FlexWithGap jc="center" ai="center" width={columnWidth}>
                                    {premium && (
                                        <Image
                                            size="24px"
                                            src={`${PORTAL_IMAGE}/premium/green_tick.svg`}
                                            alt="feature-check"
                                        />
                                    )}
                                </FlexWithGap>
                            </FlexWithGap>
                        </React.Fragment>
                    );
                })}
                <Box as="hr" height="2px" width="100%" bg="greyTints" />

                <Text mt="48px" width={["135px", "444px"]} fontSize={["13px", "15px"]}>
                    {t("members_portal_web.components.context_providers.conversion_modals.content.bmi_bp_hr_bf_dr")}
                </Text>

                <FlexWithGap jc="center" ai="center" mt="40px" position="relative" zIndex="2">
                    <PrimaryButton onClick={handleOnUpgrade}>
                        {t("members_portal_web.components.context_providers.conversion_modals.content.upgrade")}™
                    </PrimaryButton>
                </FlexWithGap>

                <StyledImage src={`${PORTAL_IMAGE}/premium/modal_footer.svg`} alt="feature-footer" />
            </ModalBody>
        </ExtraBreakpointWrapper>
    );
};

ModalContent.propTypes = {
    close: PropTypes.func,
    openModal: PropTypes.func
};
