import React from "react";
import { Route, Switch } from "react-router";

// ----------------------------------------------------------------------------------------------

import { Margin } from "components/styled-components";
import routes from "constants/routes";

import GeneralCard from "./generalCard";

// ----------------------------------------------------------------------------------------------

const Preferences = () => {
    return (
        <Margin mBottom={["32px"]}>
            <Switch>
                <Route exact path={routes.myAccount.preferencesGeneral} render={() => <GeneralCard forms />} />
                <Route
                    exact
                    path={routes.myAccount.preferences}
                    render={() => (
                        <div style={{ display: "grid", rowGap: "32px" }}>
                            <GeneralCard />
                        </div>
                    )}
                />
            </Switch>
        </Margin>
    );
};

export default Preferences;
