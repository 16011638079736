import BaseEndPoint from "../BaseEndPoint";

export default class Consents extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    termsOfUse(languageTag) {
        return super.post("/consents/terms-of-use", { languageTag }, true);
    }

    privacyPolicy(languageTag) {
        return super.post("/consents/privacy-policy", { languageTag }, true);
    }

    getConsents() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };
        return super._send(this.getUrlPath("/consents/global"), settings);
    }
}
