export const averageJoeSystolicLog = Math.log(125);
export const averageJoeSmoker = 0;
export const averageJoeBmiLog = Math.log(22.5);
export const averageJoeHasDiabetes = 0;
export const normalBmi = 22.5;
export const normalSys = 125;
export const optimalBmi = 22;
export const optimalSys = 110;

export const genderConstants = {
    MALE: {
        coeff10yrAge: 3.11296,
        getCoeff10yrSys: takingBpMeds => (takingBpMeds ? 1.92672 : 1.85508),
        coeff10yrSysNoMed: 1.85508, //need to save this value for 10yr risk cal just in case user is on meds
        coeff10yrSmoke: 0.70953,
        coeff10yrBmi: 0.79277,
        coeff10yrDiab: 0.5316,

        coeff10yrSumProdConst: 0.88431,

        coeffHaSys: 1.85508,
        coeffHaSmoke: 0.70953,
        coeffHaBmi: 0.79277,
        coeffHaDiab: 0.5316,

        haConst: 23.9388,
        haConstiDenom: 0.510015994, // =POWER((-LN(0.88431)),(1/3.11296))
        haExpo: 0.321237664, // =PRODUCT(1,1/3.11296)
        haConstiNumDiv: 3.11296
    },

    FEMALE: {
        coeff10yrAge: 2.72107,
        getCoeff10yrSys: takingBpMeds => (takingBpMeds ? 2.88267 : 2.81291),
        coeff10yrSysNoMed: 2.81291, //need to save this value for 10yr risk cal just in case user is on meds
        coeff10yrSmoke: 0.61868,
        coeff10yrBmi: 0.51125,
        coeff10yrDiab: 0.77763,

        coeff10yrSumProdConst: 0.94833,

        coeffHaSys: 2.81291,
        coeffHaSmoke: 0.61868,
        coeffHaBmi: 0.51125,
        coeffHaDiab: 0.77763,

        haConst: 26.0145,
        haConstiDenom: 0.339881038, // =POWER((-LN(0.94833)),(1/2.72107))
        haExpo: 0.36750249, // =PRODUCT(1,1/2.72107)
        haConstiNumDiv: 2.72107
    }
};

export const getGenderConstants = gender => genderConstants[gender.toUpperCase()];
