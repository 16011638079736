/* eslint-disable camelcase */
import { useMessageBanner } from "components/ContextProviders";
import { MESSAGE_ERROR } from "constants/messages";
import { useTranslation } from "react-i18next";
import APIClient from "utilities/APIClient";
import { getErrorMessage, modifyResposePromise } from "utilities/commonUtils";

/**
 * Chargebee hook for checkout and manage subscription flows
 * @return {{ openChargebeeCheckout: Function, openChargebeePortal: Function  }}
 */
export const useChargebee = () => {
    const chargebee = window.Chargebee.getInstance();
    const { t } = useTranslation();

    const { handleMessage = () => {} } = useMessageBanner();

    /**
     * Open Chargebee checkout
     * @param {object} param
     * @param {object} param.callbacks
     * @param {Function} param.callbacks.openPollingScreen
     */
    const openChargebeeCheckout = ({ callbacks: { openPollingScreen } }) => {
        if (!chargebee) return;

        chargebee.openCheckout({
            hostedPage: () =>
                modifyResposePromise(APIClient.userSubscriptions.subscriptionCheckout())
                    .then(({ hosted_page }) => hosted_page)
                    .catch(err => handleMessage(MESSAGE_ERROR, t(getErrorMessage(err)))),

            success: () => {
                openPollingScreen(true);
                chargebee.closeAll();
            }
        });
    };

    /**
     * Open Chargebee Portal
     */
    const openChargebeePortal = () => {
        if (!chargebee) return;

        chargebee.setPortalSession(() =>
            modifyResposePromise(APIClient.userSubscriptions.subscriptionPortal())
                .then(({ portal_session }) => portal_session)
                .catch(err => handleMessage(MESSAGE_ERROR, t(getErrorMessage(err))))
        );

        const chargebeePortalInstance = chargebee.createChargebeePortal();

        chargebeePortalInstance.open();
    };

    return { openChargebeeCheckout, openChargebeePortal };
};
