import styled from "styled-components";
import { border, layout, space, color } from "styled-system";

export const PrimaryButton = styled.button`
    outline: none;
    cursor: pointer;
    height: ${props => (props.height ? props.height : props.theme.primaryBtn.height)};
    width: ${props => (props.width ? props.width : props.theme.primaryBtn.width)};
    color: ${props => (props.color ? props.color : props.theme.primaryBtn.color)};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : props.theme.primaryBtn.borderRadius)};
    border: solid 1px ${props => (props.borderColor ? props.borderColor : props.theme.primaryBtn.borderColor)};
    background-color: ${props =>
        props.backgroundColor ? props.backgroundColor : props.theme.primaryBtn.backgroundColor};

    &[disabled] {
        cursor: not-allowed;
        color: ${props => (props.disabledColor ? props.disabledColor : props.theme.primaryBtn.disabledColor)};
        border-color: ${props =>
            props.disabledBorderColor ? props.disabledBorderColor : props.theme.primaryBtn.disabledBorderColor};
        background-color: ${props =>
            props.disabledBackgroundColor
                ? props.disabledBackgroundColor
                : props.theme.primaryBtn.disabledBackgroundColor};
    }
`;

export const SecondaryButton = styled.button`
    outline: none;
    cursor: pointer;

    ${layout}
    ${border}
    ${color}
    ${space}
`;
