import React, { Component } from "react";
import PropTypes from "prop-types";
import DisabledButton from "./DisabledAction";
import PrimaryAction from "./PrimaryAction";

export default class Next extends Component {
    static displayName = "Next Button";
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        children: PropTypes.string.isRequired,
        fullWidth: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        fullWidth: false
    };

    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.onClick) {
            this.props.onClick();
        }
    }

    render() {
        let button = null;
        if (this.props.disabled) {
            button = (
                <div data-testid={"button-next"}>
                    <DisabledButton>{this.props.children}</DisabledButton>
                </div>
            );
        } else {
            button = (
                <div data-testid={"button-next"}>
                    <PrimaryAction onClick={this.handleClick} fullWidth={this.props.fullWidth}>
                        {this.props.children}
                    </PrimaryAction>
                </div>
            );
        }

        return button;
    }
}
