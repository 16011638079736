import BaseEndPoint from "../BaseEndPoint";

export default class Response extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/responses/" + path);
    }

    /**
     *
     * @param response
     * @return {*}
     */
    update(response) {
        response.incrementSequence();
        return this.put(response.responseId, response.toJSON());
    }

    /**
     *
     * @param response
     * @return {*}
     */
    finish(response) {
        response.incrementSequence();
        response.setIsFinished();
        return this.put(response.responseId, response.toJSON());
    }
}
