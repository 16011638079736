import BaseConfiguration from "./BaseConfiguration";

export default class HealthRiskAssessmentConfiguration extends BaseConfiguration {
    get campaignId() {
        let campaignId = null;

        if (
            this._configuration.hasOwnProperty("campaign") &&
            this._configuration.campaign &&
            this._configuration.campaign.hasOwnProperty("campaignId") &&
            this._configuration.campaign.campaignId
        ) {
            campaignId = this._configuration.campaign.campaignId;
        }

        return campaignId;
    }

    /**
     * There is no country id for a health risk assessment
     * @returns {null}
     */
    get countryId() {
        return null;
    }
}
