import BaseRule from "../BaseRule";

export default class AnswerRegexRule extends BaseRule {
    /**
     * Checks to see if at least one answer matches the rule.
     */
    matches() {
        let matches = false;

        /** @type {Answer[]} */
        const answers = this._options.response.getAnswersForQuestionByCode(this._options.questionCode);

        if (answers !== null) {
            for (let answer of answers) {
                for (let regularExpression of this._options.regularExpressions) {
                    if (regularExpression === "NULL") continue;
                    // currently hard coding case insensitive flag
                    let regExp = new RegExp(regularExpression, "i");
                    if (regExp.test(answer.getRawAnswer())) {
                        matches = true;
                        break; // nothing further to evaluate
                    }
                }
            }
        } else {
            for (let regularExpression of this._options.regularExpressions) {
                // "NULL" matches null answers, not a regexp
                if (regularExpression === "NULL") {
                    matches = true;
                }
            }
        }

        return matches;
    }
}
