import validator from "validate.js";

export default class WeightCalibrationData {
    constructor(schema = null) {
        this._beforeCalibration = {
            weight: 0,
            totalWeight: 0,
            tareWeight: 0
        };

        this._afterCalibration = {
            weight: 0,
            totalWeight: 0,
            tareWeight: 0
        };

        this._isPowerCycled = false;
        this._isTared = false;
        this._calibrationError = null;

        if (schema) {
            this.fromJson(schema);
        }
    }

    setBeforeCalibration(weight, totalWeight, tareWeight) {
        this._beforeCalibration = { weight, totalWeight, tareWeight };
    }

    setAfterCalibration(weight, totalWeight, tareWeight) {
        this._afterCalibration = { weight, totalWeight, tareWeight };
    }

    set isPowerCycled(value) {
        this._isPowerCycled = value;
    }

    get isPowerCycled() {
        return this._isPowerCycled;
    }

    set isTared(value) {
        this._isTared = value;
    }

    get isTared() {
        return this._isTared;
    }

    set calibrationError(value) {
        this._calibrationError = value;
    }

    get calibrationError() {
        return this._calibrationError;
    }

    get beforeCalibration() {
        return this._beforeCalibration;
    }

    get afterCalibration() {
        return this._afterCalibration;
    }

    toJson() {
        return {
            beforeCalibration: {
                weight: this._beforeCalibration.weight,
                totalWeight: this._beforeCalibration.totalWeight,
                tareWeight: this._beforeCalibration.tareWeight
            },
            afterCalibration: {
                weight: this._afterCalibration.weight,
                totalWeight: this._afterCalibration.totalWeight,
                tareWeight: this._afterCalibration.tareWeight
            },
            isPowerCycled: this._isPowerCycled,
            isTared: this._isTared,
            calibrationError: this._calibrationError
        };
    }

    fromJson(data) {
        const result = WeightCalibrationData.validate(data);
        if (!result.isValid) {
            console.log("WeightCalibrationData validate() failed: ", result);
            return;
        }

        this._beforeCalibration.weight = data.beforeCalibration.weight;
        this._beforeCalibration.totalWeight = data.beforeCalibration.totalWeight;
        this._beforeCalibration.tareWeight = data.beforeCalibration.tareWeight;

        this._afterCalibration.weight = data.afterCalibration.weight;
        this._afterCalibration.totalWeight = data.afterCalibration.totalWeight;
        this._afterCalibration.tareWeight = data.afterCalibration.tareWeight;

        this._isPowerCycled = data.isPowerCycled;
        this._isTared = data.isTared;
        this._calibrationError = data.calibrationError || null;
    }

    static validate(schema) {
        let constraints = {
            beforeCalibration: {
                presence: true
            },
            afterCalibration: {
                presence: true
            },
            isPowerCycled: {
                presence: true,
                type: "boolean"
            },
            isTared: {
                presence: true,
                type: "boolean"
            },
            calibrationError: {
                presence: false,
                type: "string"
            }
        };

        let result = validator(schema, constraints);
        if (result) {
            return { result, isValid: false };
        }

        constraints = {
            weight: {
                presence: true,
                type: "number"
            },
            totalWeight: {
                presence: true,
                type: "number"
            },
            tareWeight: {
                presence: true,
                type: "number"
            }
        };

        result = validator(schema.beforeCalibration, constraints);
        if (result) {
            return { result, isValid: false };
        }

        result = validator(schema.afterCalibration, constraints);
        if (result) {
            return { result, isValid: false };
        }

        result = null;
        return { result, isValid: true };
    }
}
