import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const TooltipV2 = ({ name, tooltip, tooltipProps, children, ...rest }) => {
    return (
        <>
            <a data-tip data-for={name} {...rest}>
                {children}
            </a>
            <ReactTooltip id={name} {...tooltipProps}>
                {tooltip}
            </ReactTooltip>
        </>
    );
};

TooltipV2.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.node.isRequired,
    tooltipProps: PropTypes.object
};

TooltipV2.defaultProps = {
    tooltipProps: {
        effect: "solid"
    }
};

export default TooltipV2;
