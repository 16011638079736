import React, { useEffect } from "react";

import FindAStation from "../FindAStation";
import LoadingView from "components/LoadingView";
import LoadingViewWithoutMenu from "components/LoadingViewWithoutMenu";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { getHealthStationsAPI } from "actions/health-station";
import { useAsyncData } from "@sisuwellness/utilities/hooks";
import PageLayout from "components/PageLayout";
import withAuthentication from "components/withAuthentication";

export const HealthStationsView = ({ authenticated, stations, getHealthStationsAPI, citizen }) => {
    const [{ isLoading }] = useAsyncData(() => getHealthStationsAPI(), []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const supportedRegions = ["AU", "GB"];
    const region = (citizen.availableCountries || [])
        .map(country => country.alpha2)
        .find(country => supportedRegions.includes(country));

    if (stations.length === 0 && !isLoading) return null;

    const renderWithCitizen = () => {
        if (isLoading) {
            return <LoadingView />;
        }
        return (
            <PageLayout>
                <FindAStation installations={stations} region={region} />
            </PageLayout>
        );
    };

    const renderWithoutCitizen = () => {
        if (isLoading) {
            return <LoadingViewWithoutMenu />;
        }

        return (
            <PageLayout>
                <FindAStation installations={stations} region={region} />
            </PageLayout>
        );
    };

    return authenticated ? renderWithCitizen() : renderWithoutCitizen();
};

HealthStationsView.propTypes = {
    stations: PropTypes.array.isRequired,
    getHealthStationsAPI: PropTypes.func.isRequired,
    citizen: PropTypes.object.isRequired
};

const mapStateToProps = ({
    healthStation: { stations = [] } = {},
    auth: { citizen = {}, authenticated = false } = {}
}) => ({
    authenticated,
    stations,
    citizen
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getHealthStationsAPI
        },
        dispatch
    );

export default compose(
    withAuthentication,
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(HealthStationsView);
