import React from "react";
import PropTypes from "prop-types";

import { media } from "@sisuwellness/web-components/themes";
import { isArray } from "lodash";
import { Flex as Flexbox } from "rebass";
import styled from "styled-components";
import { position, border, flexbox } from "styled-system";
import { isEmpty } from "lodash-es";

/**
 * Checks if browser supports flex-gap property
 * @return {Boolean}
 */
const checkFlexGapIsSupported = () => {
    // create flex container with row-gap set
    const flex = document.createElement("div");
    flex.style.display = "flex";
    flex.style.flexDirection = "column";
    flex.style.rowGap = "1px";

    // create two, elements inside it
    flex.appendChild(document.createElement("div"));
    flex.appendChild(document.createElement("div"));

    // append to the DOM (needed to obtain scrollHeight)
    document.body.appendChild(flex);
    const isSupported = flex.scrollHeight === 1; // flex container should be 1px high from the row-gap
    flex.parentNode.removeChild(flex);

    return isSupported;
};

/**
 * Parses responsive values for css properties
 * @param {string | Array<string>} property css properties
 * @param {*} deviceIndex responsive breakpoints index
 * @return {string}
 */
const reponsiveValue = (property, deviceIndex) => (isArray(property) ? property[deviceIndex] : property);

/**
 * Creates css template to fallback if browser does not support flex-gap
 *
 * Note: fallback is not a replacement for flex-gap. It is just a
 * manipulation of margins with "lobotomized owl selector (* + *)"
 * to imitate flex-gap that achieves basic gap gutters.
 *
 * @param {object} props
 * @param {string | Array} props.gap flex-gap property
 * @param {boolean} props.isGapSupported flag if browser supports flex-gap
 * @param {string | Array} props.flexDirection flex-direction property
 * @param {number} deviceIndex corresponding to responsive breakpoints
 * @return {string} css string
 */
const getFlexGapPropertyTemplate = ({ flexDirection, isGapSupported, gap = "" }, deviceIndex) => {
    return isGapSupported
        ? `gap: ${reponsiveValue(gap, deviceIndex)};`
        : reponsiveValue(flexDirection, deviceIndex) === "column"
        ? !isEmpty(gap) &&
          `& > * + * {
                margin: ${reponsiveValue(
                    gap,
                    deviceIndex
                )} 0px 0px 0px !important;             // Margin top for adjacent siblings of direct children
            }`
        : !isEmpty(gap) &&
          `& > * + * {
                margin: 0px 0px 0px ${reponsiveValue(
                    gap,
                    deviceIndex
                )} !important;                         // Margin left for adjacent siblings of direct children
            }`;
};

/**
 * Make array of gap to have atleast 3 items.
 *
 * @param {Array<string>} gapProp
 * @return {Array<string>}
 *
 * @example
 * processGapArray(["10px"]) // ["10px", "10px", "10px"]
 * processGapArray(["10px", "20px"]) // ["10px", "20px", "20px"]
 */
const processGapArray = gapProp => {
    if (!isArray(gapProp)) return gapProp;
    return gapProp.length < 3
        ? Array(3)
              .fill(gapProp[0])
              .map((_, ix) => gapProp[ix] || gapProp[gapProp.length - 1])
        : gapProp;
};

const Flex = styled(Flexbox)`
    ${props => getFlexGapPropertyTemplate(props, 2)}

    ${media.tablet`
        ${props => getFlexGapPropertyTemplate(props, 1)}
    `}

    ${media.mobile`
        ${props => getFlexGapPropertyTemplate(props, 0)}
    `}

    ${position}
    ${border}
    ${flexbox}
`;

/**
 * @component
 * Flex component supporting gap property
 */
const FlexWithGap = React.forwardRef(
    ({ children, gap, ai, jc, fd, alignItems, justifyContent, flexDirection, ...rest }, ref) => {
        const isGapSupported = checkFlexGapIsSupported();

        return (
            <Flex
                ref={ref}
                gap={processGapArray(gap)}
                alignItems={ai || alignItems}
                isGapSupported={isGapSupported}
                justifyContent={jc || justifyContent}
                flexDirection={fd || flexDirection}
                {...rest}
            >
                {children}
            </Flex>
        );
    }
);

FlexWithGap.propTypes = {
    ...Flex.propTypes,
    jc: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    ai: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    fd: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    gap: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
};

export default FlexWithGap;
