import React from "react";

import { useConversionModal } from "components/ContextProviders";
import { ButtonVariant } from "..";

const UpgradeSiSUBtn = props => {
    const { triggerModal } = useConversionModal();

    const handleOnClick = () => triggerModal(true);

    return <ButtonVariant width="203px" height="49px" fontSize="15px" {...props} onClick={handleOnClick} />;
};

UpgradeSiSUBtn.defaultProps = { children: "Upgrade Now" };

export default UpgradeSiSUBtn;
