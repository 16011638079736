/**It returns the Radian value of a degree
 * @param {Number} degree - Degree between Lat and Lng
 * @return {Float} Radian value
 */
export function degreeToRadian(degree) {
    return (degree * Math.PI) / 180;
}

/**Using Haversine formula to find the closest marker.
 *
 * @param {Object} installations It has array of LatLng value of installed station.
 * @param {Float} searchedLat Latitude value of searched location.
 * @param {Float} searchedLng Longitude value of searched location.
 * @return {Object} containing LatLng value of searched loction and nearest station.
 */
export function findClosestMarker(installations, searchedLat, searchedLng) {
    const lat = searchedLat;
    const lng = searchedLng;
    const R = 6371; // radius of earth in km
    let distances = [];
    let closest = -1;
    for (let i = 0; i < installations.length; i++) {
        const mlat = installations[i].address.latitude;
        const mlng = installations[i].address.longitude;
        const dLat = degreeToRadian(mlat - lat);
        const dLong = degreeToRadian(mlng - lng);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(degreeToRadian(lat)) * Math.cos(degreeToRadian(lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        distances[i] = d;
        if (closest == -1 || d < distances[closest]) {
            closest = i;
        }
    }

    const data = {
        start: {
            lat: lat,
            lng: lng
        },
        dest: {
            lat: installations[closest].address.latitude,
            lng: installations[closest].address.longitude
        }
    };

    return data;
}
