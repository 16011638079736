import { Guidelines } from "@sisuwellness/utilities";
import { theme } from "@sisuwellness/ui/src/theme";

/**
 * @param {number} score Health Risk Score
 * @returns {String} Recommendation for specified score
 */
export const getHRARecommendation = score => {
    if (score === null || score === undefined)
        return "members_portal_web.utilities.health_risk_score.recommendations.default";
    else {
        const guideline = new Guidelines.HealthRiskAssessment.Guideline(score).key;
        switch (guideline) {
            case "excellent":
                return "members_portal_web.utilities.health_risk_score.recommendations.excellent";
            case "good":
                return "members_portal_web.utilities.health_risk_score.recommendations.good";
            case "moderate":
                return "members_portal_web.utilities.health_risk_score.recommendations.moderate";
            case "atRisk":
                return "members_portal_web.utilities.health_risk_score.recommendations.at_risk";
            case "increasedRisk":
                return "members_portal_web.utilities.health_risk_score.recommendations.increased_risk";
            default:
                return "members_portal_web.utilities.health_risk_score.recommendations.default";
        }
    }
};

/**
 * @param {number} score Health Risk Score
 * @returns {String} colour code with respect to guideline
 */
export const getHRARiskColor = score => {
    if (Number.isFinite(score)) {
        const guideline = new Guidelines.HealthRiskAssessment.Guideline(score);
        return theme.guidelines["SiSUHealthRiskAssessment"][guideline.key].sisuPortal.hex;
    }
    return theme.colours.hpPrimaryPurple.hex;
};
