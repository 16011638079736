import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// ----------------------------------------------------------------

import HealthHubMetrics from "./Metrics";
import PageLayout from "components/PageLayout";

import { useAsyncData } from "@sisuwellness/utilities/hooks";
import { getHealthChecksAPI } from "actions/health-checks";
import LoadingView from "components/LoadingView";
import { isEmpty } from "ramda";
import { DashboardBanner } from "./dashboardBanner";
import { PremiumFeature } from "components/PremiumFeatures";

// ----------------------------------------------------------------

const HealthHubView = () => {
    const citizen = useSelector(state => state.auth.citizen);
    const [isCardPresent, setisCardPresent] = useState(false);
    const { healthChecks } = useSelector(state => state.healthCheck);

    const dispatch = useDispatch();

    const [{ isStarted, isLoading }] = useAsyncData(() => isEmpty(healthChecks) && dispatch(getHealthChecksAPI()), []);

    if (!isStarted || isLoading) {
        return <LoadingView />;
    }

    const campaignId = citizen.latestUserCampaignActivity ? citizen.latestUserCampaignActivity.campaignId : null;

    if (!citizen || Object.keys(citizen) === 0) {
        return null;
    }

    return (
        <PageLayout>
            <PremiumFeature forceRender>
                <DashboardBanner userProfile={citizen.userProfile} isCardPresent={isCardPresent} />
                <HealthHubMetrics
                    citizen={citizen}
                    campaignId={campaignId}
                    healthChecks={healthChecks}
                    isCardPresent={isCardPresent}
                    setisCardPresent={setisCardPresent}
                />
            </PremiumFeature>
        </PageLayout>
    );
};

export default HealthHubView;
