import { SET_USER_NAME, SET_AUTHENTICATION_STATUS, SET_CITIZEN } from "../../constants/actions";

export const initialState = {
    username: "",
    authenticated: false,
    citizen: {}
};

export default (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case SET_USER_NAME: {
            return {
                ...state,
                username: payload
            };
        }

        case SET_AUTHENTICATION_STATUS: {
            return {
                ...state,
                authenticated: payload
            };
        }

        case SET_CITIZEN: {
            return {
                ...state,
                citizen: payload
            };
        }

        default: {
            return state;
        }
    }
};
