import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledSecondaryButton = styled.button`
    ${props => props.fullWidth && `width: 100%;`};
    font-size: ${props => props.theme.text.heading6.sizeRem};
    background-color: ${props => props.theme.colours.flat.white.hex};
    color: ${props => props.theme.colours.flat.blue.hex};
    border-radius: ${props => props.borderRadius};
    ${props => props.theme.shadowCss.rest()};
    padding: ${props => props.padding};
    border: 2px solid ${props => props.theme.colours.flat.blue.hex};
    user-select: none;
    text-transform: uppercase;

    :hover {
        background-color: ${props => props.theme.colours.flat.lightBlue.hex};
        color: ${props => props.theme.colours.flat.white.hex};
        ${props => props.theme.shadowCss.elevated()};
        border: 2px solid ${props => props.theme.colours.flat.lightBlue.hex};
    }

    :active {
        background-color: ${props => props.theme.colours.flat.blue.hex};
        ${props => props.theme.shadowCss.elevated()};
        color: ${props => props.theme.colours.flat.white.hex};
        border: 2px solid ${props => props.theme.colours.flat.blue.hex};
    }

    :focus {
        outline: none;
    }

    :disabled {
        background-color: ${props => props.theme.colours.flat.gray.hex};
    }
`;

const SecondaryButton = ({ label, children, onClick, disabled, borderRadius, ...rest }) => (
    <StyledSecondaryButton {...rest} onClick={onClick} disabled={disabled} borderRadius={borderRadius}>
        {label}
        {children}
    </StyledSecondaryButton>
);

SecondaryButton.propTypes = {
    label: PropTypes.string,
    children: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    borderRadius: PropTypes.string,
    fullWidth: PropTypes.bool,
    padding: PropTypes.string
};

SecondaryButton.defaultProps = {
    borderRadius: "2em",
    fullWidth: false,
    padding: "0.8em 1em"
};

export default SecondaryButton;
