import React from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------

import Icon from "../../Icons";

import { CheckboxContainer, FlexLayout, Label } from "./styled";
import { theme } from "../../../theme";

// ----------------------------------------------------------------------------

const Checkbox = ({
    id = "",
    children,
    inputProps,
    gap = "10px",
    color = theme.portalColor.hpPrimaryPurple.hex,
    ...rest
}) => {
    return (
        <FlexLayout alignItems="center" gridColumnGap={gap} {...rest}>
            <CheckboxContainer size="24px" bg="white" color={color}>
                <input {...inputProps} id={`checkbox-${id}`} type="checkbox" data-testid="checkbox" />
                <div className="check-back" />
                <Icon
                    type={theme.icons.types.checkMark}
                    colour={theme.colours.flat.white.hex}
                    size={theme.icons.sizes.normal}
                    maxSize={"100%"}
                    className="check-tick"
                />
            </CheckboxContainer>
            {children && (
                <Label className="check-label" htmlFor={`checkbox-${id}`} data-testid="checkbox-label">
                    {children}
                </Label>
            )}
        </FlexLayout>
    );
};

Checkbox.propTypes = {
    ...CheckboxContainer.defaultProps,
    id: PropTypes.any
};

export default Checkbox;
