import React from "react";
import PropTypes from "prop-types";

//------------------------------------------------------------------------------

import { DimensionProvider as Provider } from "components/Context";
import { withDimensions } from "components/HOC";

//------------------------------------------------------------------------------

const DimensionProvider = withDimensions(({ children, dimension }) => {
    const [width, height] = dimension;
    return <Provider value={{ width, height }}>{children}</Provider>;
});

DimensionProvider.propTypes = {
    children: PropTypes.any.isRequired
};

export default DimensionProvider;
