import BaseEndPoint from "../BaseEndPoint";

export default class CustomerRequests extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath(`/customer-requests/${path}`);
    }

    /**
     * Return Customer Requests with optional parameters and detail level
     *
     * @param {int} clientAddressId
     * @param {Object} searchParams
     * @param {boolean} detailed
     * @return {[Object]}
     */
    search(clientAddressId, searchParams = {}, detailed = true) {
        searchParams.clientAddressId = clientAddressId;
        return this.get(detailed, searchParams);
    }

    /**
     * Return Customer Requests with optional parameters and detail level
     *
     * @param {int} clientAddressId
     * @param {Object} searchParams
     * @return {Object}
     */
    searchStats(clientAddressId, searchParams = {}) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        searchParams.clientAddressId = clientAddressId;
        const queryString = this.generateQueryString(searchParams);
        return this._send(this.getUrlPath("stats") + queryString, settings);
    }

    /**
     * Updates a single Customer Request with a given state
     *
     * @param {int} customerRequestId
     * @param {int} customerRequestStateId
     * @return {Promise<Response>}
     */
    update(customerRequestId, customerRequestStateId) {
        return this.put(customerRequestId, { customerRequestStateId });
    }

    /**
     * Creates a Customer Request Call event
     *
     * @param {int} customerRequestId
     * @param {int} clientAddressStaffId
     * @param {Date} happenedAt
     * @param {string|null} clinicalNotes (optional)
     * @return {Promise<Response>}
     */
    createCall(customerRequestId, clientAddressStaffId, happenedAt, clinicalNotes = null) {
        return this.post(`${customerRequestId}/call`, {
            clientAddressStaffId,
            happenedAt,
            clinicalNotes
        });
    }

    /**
     * Creates a Clinical Intervention
     *
     * @param {int} customerRequestId
     * @param {int} clientAddressStaffId
     * @param {int} pharmacyClinicalInterventionClassificationId
     * @param {int[]} pharmacyRecommendationIds
     * @param {Date} happenedAt
     * @param {string|null} medicinesInvolved (optional)
     * @param {string|null} clinicalNotes (optional)
     * @return {Promise<Response>}
     */
    createClinicalIntervention(
        customerRequestId,
        clientAddressStaffId,
        pharmacyClinicalInterventionClassificationId,
        pharmacyRecommendationIds,
        happenedAt,
        medicinesInvolved = null,
        clinicalNotes = null
    ) {
        return this.post(`${customerRequestId}/clinical-interventions`, {
            clientAddressStaffId,
            pharmacyClinicalInterventionClassificationId,
            pharmacyRecommendationIds,
            happenedAt,
            medicinesInvolved,
            clinicalNotes
        });
    }

    /**
     * Creates a Print event
     *
     * @param {int} customerRequestId
     * @param {int|null} clientAddressStaffId
     * @return {Promise<Response>}
     */
    createPrint(customerRequestId, clientAddressStaffId = null) {
        const payload = clientAddressStaffId != null ? { clientAddressStaffId } : {};
        return this.post(`${customerRequestId}/print`, payload);
    }

    /**
     * Returns all known Clinical Intervention Classifications
     * @return {Promise<Response>}
     */
    getClinicalInterventionClassifications() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(super.getUrlPath("/clinical-intervention-classifications"), settings);
    }

    /**
     * Returns all known Clinical Intervention Recommendations
     * @return {Promise<Response>}
     */
    getClinicalInterventionRecommendations() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(super.getUrlPath("/clinical-intervention-recommendations"), settings);
    }

    /**
     * Returns all known Customer Requests States
     * @return {Promise<Response>}
     */
    getCustomerRequestStates() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(this.getUrlPath("states"), settings);
    }

    /**
     * Returns all known Customer Requests Types
     * @return {Promise<Response>}
     */
    getCustomerRequestTypes() {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(this.getUrlPath("types"), settings);
    }

    /**
     *
     * @param {int} clientAddressId
     * @param {String} startDate
     * @param {String} endDate
     * @return {Promise<Response>}
     */
    generateReport(clientAddressId, startDate, endDate) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString({
            clientAddressId,
            startDate: startDate,
            endDate: endDate
        });

        return this._send(this.getUrlPath("report") + queryString, settings);
    }
}
