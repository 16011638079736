/* eslint-disable no-unused-vars */
import BaseEndPoint from "../BaseEndPoint";
import { customerDetails } from "./mock";

export default class Payments extends BaseEndPoint {
    constructor(client) {
        super(client);
    }

    initializeTransaction = () => {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "POST"
        };

        return super._send(this.getUrlPath("/scripts-now/initialize-transaction"), settings);
    };

    startTransaction = (requestBody, scriptsNowSessionUuid) => {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "POST",
            data: JSON.stringify({ ...requestBody, source: "scriptsNowPortal" })
        };

        return this._send(this.getUrlPath(`/scripts-now/start-transaction/${scriptsNowSessionUuid}`), settings);
    };

    /**
     * @param {string} value couponCode
     * @return {Promise} url to validate coupon
     */
    validateCoupon = value => {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return super._send(
            this.getUrlPath("/scripts-now/order/coupon" + this.generateQueryString({ coupon: value })),
            settings
        );
    };
}
