import React from "react";

import { useSelector } from "react-redux";
import { Box, Flex, Image } from "rebass";

//------------------------------------------------------------------------------

import {
    StyledBackground,
    OverlapImage,
    MobileImage,
    StyledFooter,
    StyledHeading,
    FooterWrapper,
    PageConstraint
} from "./styled";
import { PORTAL_IMAGE } from "constants/imagePath";
import { AnchorLink, MediaQuery, Paragraph } from "@sisuwellness/web-components";
import ExtraBreakpointWrapper from "components/ExtraBreakpointWrapper";
import { useTranslation } from "react-i18next";

//------------------------------------------------------------------------------

const ICON_MARGIN_RIGHT = ["30px"];
const Footer = () => {
    const { t } = useTranslation();

    function renderSocialIcon() {
        return (
            <Flex justifyContent="flex-end" pt={["60px", "50px", "0px"]}>
                <Box mr={ICON_MARGIN_RIGHT}>
                    <AnchorLink
                        aria-label="Facebook page"
                        href="https://www.facebook.com/Sisuhealthgroup/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Image width="20px" src={`${PORTAL_IMAGE}/fb.svg`} alt="icon-facebook" />
                    </AnchorLink>
                </Box>
                <Box mr={ICON_MARGIN_RIGHT}>
                    <AnchorLink
                        aria-label="LinkedIn page"
                        href="https://www.linkedin.com/company/sisuhealthgroup/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Image width="20px" src={`${PORTAL_IMAGE}/linkedin.svg`} alt="icon-linkedin" />
                    </AnchorLink>
                </Box>
                <Box mr={ICON_MARGIN_RIGHT}>
                    <AnchorLink
                        aria-label="Instagram page"
                        href="http://instagram.com/sisuhealthgroup"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Image width="20px" src={`${PORTAL_IMAGE}/instagram.svg`} alt="icon-instagram" />
                    </AnchorLink>
                </Box>
            </Flex>
        );
    }
    function renderNonMobileVersion(imgName) {
        return (
            <Flex flexDirection="row" width="100%">
                <StyledBackground width="100%">
                    <MediaQuery devices={["desktop"]}>
                        <Box pt="90px" width="65%" mx="auto">
                            <img width="100%" src={`${PORTAL_IMAGE}/footer/${imgName}.svg`} alt={"mobile"} />
                        </Box>
                    </MediaQuery>
                    <Flex
                        height="100%"
                        justifyContent="space-between"
                        pt="50px"
                        pl="20px"
                        width={["100%", "100%", "35%"]}
                        flexDirection="column"
                    >
                        <Flex width="100%" flexDirection="column">
                            <Paragraph size={["36px", "36px", "28px"]} fontWeight="300" pb="32px">
                                {t("members_portal_web.components.footer.still_looking")}?
                            </Paragraph>
                            <Flex
                                flexWrap="wrap"
                                flexDirection={["column", "row", "row"]}
                                justifyContent="space-between"
                            >
                                <Box width={["100%", "50%", "50%"]}>
                                    <StyledHeading pb="24px" renderAs="h6">
                                        {t("words.about")}
                                    </StyledHeading>
                                    <AnchorLink
                                        href="https://www.sisuhealthgroup.com/about-us/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paragraph pb="24px" size="20px" fontWeight="300">
                                            {t("words.about_sisu_health")}
                                        </Paragraph>
                                    </AnchorLink>
                                    <AnchorLink
                                        href="http://www.sisuhealthgroup.com/privacy/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paragraph size="20px" pb="24px" fontWeight="300">
                                            {t("members_portal_web.components.footer.privacy_terms_of_use")}
                                        </Paragraph>
                                    </AnchorLink>
                                    <AnchorLink
                                        href="https://www.sisuhealthgroup.com/in-the-press/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paragraph size="20px" pb="24px" fontWeight="300">
                                            {t("words.press")}
                                        </Paragraph>
                                    </AnchorLink>
                                </Box>
                                <Box width={["100%", "50%", "45%"]}>
                                    <StyledHeading pb="24px" renderAs="h6">
                                        {t("members_portal_web.components.footer.quality_assurance")}
                                    </StyledHeading>
                                    <AnchorLink
                                        href="https://sisuhealthgroup.com/quality-compliance/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paragraph pb="24px" size="20px" fontWeight="300">
                                            {t("members_portal_web.components.footer.quality_compliance")}
                                        </Paragraph>
                                    </AnchorLink>
                                    <AnchorLink
                                        href="https://www.sisuhealthgroup.com/frequently-asked-questions/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paragraph pb="24px" size="20px" fontWeight="300">
                                            {t("words.faq")}
                                        </Paragraph>
                                    </AnchorLink>
                                </Box>
                                <Box width={["100%", "50%", "45%"]}>
                                    <StyledHeading pb="24px" renderAs="h6">
                                        {t("words.contact")}
                                    </StyledHeading>
                                    <AnchorLink
                                        href="https://www.sisuhealthgroup.com/contact-us/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <Paragraph pb={["60px", "40px", "24px"]} size="20px" fontWeight="300">
                                            {t("members_portal_web.components.footer.contact_sisu")}
                                        </Paragraph>
                                    </AnchorLink>
                                </Box>
                            </Flex>
                        </Flex>
                        <MediaQuery>
                            <Image
                                width="130px"
                                src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`}
                                alt="logo"
                                mb={["60px", "0"]}
                            />
                            <Flex>{renderSocialIcon()}</Flex>
                            <Paragraph
                                size="20px"
                                fontWeight="300"
                                pt={["24px", "24px", "0px"]}
                                pb={["60px", "50px", "24px"]}
                            >
                                © {new Date().getFullYear()} —{" "}
                                {t("members_portal_web.components.footer.sisu_health_group")}
                            </Paragraph>
                        </MediaQuery>
                    </Flex>
                </StyledBackground>
            </Flex>
        );
    }

    const initialValue = useSelector(state => state.auth.citizen);

    const defaultCountryCode = initialValue.userProfile && initialValue.userProfile.countryCode;

    const imgName = defaultCountryCode === "AU" || defaultCountryCode === "GB" ? defaultCountryCode : "other";

    return (
        <ExtraBreakpointWrapper screen="macbook" mt="130px">
            <FooterWrapper bg="hpNavBackground" id="member-portal-footer" mt={["160px", "200px"]}>
                <PageConstraint mx="auto">
                    <StyledFooter>
                        <MediaQuery devices={["desktop"]}>
                            <OverlapImage src={`${PORTAL_IMAGE}/footer/girlAndDog.svg`} alt={"mobile"} />
                        </MediaQuery>
                        <MediaQuery devices={["mobile", "tablet"]}>
                            <MobileImage src={`${PORTAL_IMAGE}/footer/${imgName}-m.svg`} alt={"mobile"} />
                        </MediaQuery>
                        <StyledBackground data-testid="footer" data-printid="footer">
                            {renderNonMobileVersion(imgName)}
                        </StyledBackground>
                    </StyledFooter>
                </PageConstraint>
            </FooterWrapper>
        </ExtraBreakpointWrapper>
    );
};

export default Footer;
