import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { supportedLangs } from "i18n";
import PropTypes from "prop-types";
import { moment } from "../../utilities/commonUtils";

const supportedLangsArray = Object.keys(supportedLangs);

const registerOpenDropdownHandlers = ({ optionsLength, activeIndex, setActiveIndex, select }) => {
    const keyDownCallback = e => {
        e.preventDefault();
        switch (e.key) {
            case "Up":
            case "ArrowUp":
                e.preventDefault();
                setActiveIndex(activeIndex <= 0 ? optionsLength - 1 : activeIndex - 1);
                return;
            case "Down":
            case "ArrowDown":
                e.preventDefault();
                setActiveIndex(activeIndex + 1 === optionsLength ? 0 : activeIndex + 1);
                return;
            case "Enter":
            case " ": // Space
                e.preventDefault();
                select(supportedLangs[activeIndex]);
                return;
            case "Esc":
            case "Escape":
                e.preventDefault();
                select(false);
                return;
            case "PageUp":
            case "Home":
                e.preventDefault();
                setActiveIndex(0);
                return;
            case "PageDown":
            case "End":
                e.preventDefault();
                setActiveIndex(supportedLangs.length - 1);
                return;
        }
    };
    document.addEventListener("keydown", keyDownCallback);
    return () => {
        document.removeEventListener("keydown", keyDownCallback);
    };
};

const registerClosedDropdownHandlers = ({ setIsDropdownOpen }) => {
    const keyDownCallback = e => {
        switch (e.key) {
            case "Up":
            case "ArrowUp":
            case "Down":
            case "ArrowDown":
            case " ": // Space
            case "Enter":
                e.preventDefault();
                setIsDropdownOpen(true);
        }
    };
    document.addEventListener("keydown", keyDownCallback);
    return () => {
        document.removeEventListener("keydown", keyDownCallback);
    };
};

const isSafari = () => {
    const chromeInAgent = navigator.userAgent.indexOf("Chrome") > -1;
    const safariInAgent = navigator.userAgent.indexOf("Safari") > -1;
    return safariInAgent && !chromeInAgent;
};

const SelectContainer = styled.div`
    position: relative;
    padding-right: ${props => props.paddingRight || "0"};
`;

const SelectButton = styled.button`
    display: flex;
    background: transparent;
    border: none;
    align-items: center;
    gap: 0.5rem;
    color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};

    :focus-visible {
        outline-color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    }
`;

const SelectDropdown = styled.ul`
    position: absolute;
    display: ${props => (props.isOpen ? "block" : "none")};
    list-style: none;
    margin-top: 0.5rem;
    padding: 0.25rem;
    background: white;
    width: 100%;
    min-width: 200px;
    right: ${props => (props.openLeft ? "0" : "initial")};
    border-radius: 8px;
    box-shadow: rgba(50, 50, 71, 0.06) 0px 4px 8px 0px, rgba(50, 50, 71, 0.08) 0px 4px 4px 0px;

    :focus-visible {
        outline-color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    }
`;

const SelectElement = styled.li`
    background: ${props => (props.isActive ? props.theme.portalColor.hpPurple200.hex : "transparent")};
    font-weight: ${props => (props.isChosen ? "bold" : "normal")};
    color: ${props => props.theme.portalColor.hpPrimaryPurple.hex};
    padding: 0.45rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
`;

const Flag = styled.img`
    border-radius: 9999px;
    width: 1.5rem;
    height: 1.5rem;
`;

const SmallFlag = styled.img`
    border-radius: 9999px;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
`;

const SelectRadio = styled.input`
    display: none;
`;

const SelectLabel = styled.label`
    margin: 0;
`;

const LanguageDropdown = ({ paddingRight, iconOnly = false, openLeft = true }) => {
    const { t, i18n } = useTranslation();

    const currentLang = i18n.language;
    const [isDropdownOpen, setIsDropdownOpenInternal] = useState(false);
    const listRef = useRef();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isFocus, setIsFocus] = useState(false);

    const select = () => {
        setIsDropdownOpen(false);
        i18n.changeLanguage(supportedLangsArray[activeIndex]);
        moment.locale(supportedLangsArray[activeIndex].toLowerCase());
    };

    const setIsDropdownOpen = v => {
        if (v) {
            const selected = supportedLangsArray.findIndex(o => o === currentLang);
            setActiveIndex(selected < 0 ? 0 : selected);
            if (listRef.current && isSafari()) {
                requestAnimationFrame(() => {
                    listRef.current.focus();
                });
            }
        } else {
            if (listRef.current && isSafari()) {
                requestAnimationFrame(() => {
                    listRef.current.previousSibling.focus();
                });
            }
        }
        setIsDropdownOpenInternal(v);
    };

    useEffect(() => {
        if (isDropdownOpen) {
            return registerOpenDropdownHandlers({
                activeIndex,
                setActiveIndex,
                optionsLength: supportedLangs.length,
                select
            });
        } else if (isFocus) {
            return registerClosedDropdownHandlers({
                setIsDropdownOpen
            });
        }
    }, [isDropdownOpen, activeIndex, isFocus]);

    return (
        <SelectContainer paddingRight={paddingRight}>
            <SelectButton
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                role="combobox"
                aria-haspopup="listbox"
                aria-controls="language_dropdown"
                aria-expanded={isDropdownOpen}
                aria-activedescendant={`element_${currentLang}`}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                aria-label={t("buttons.change_language")}
            >
                <Flag src={supportedLangs[currentLang].flag} />
                {!iconOnly && t("buttons.change_language")}
            </SelectButton>
            <SelectDropdown
                role="listbox"
                ref={listRef}
                id="language_dropdown"
                tabIndex={-1}
                isOpen={isDropdownOpen}
                openLeft={openLeft}
            >
                {Object.entries(supportedLangs).map(([langCode, langDetails], index) => (
                    <SelectElement
                        key={langCode}
                        id={`element_${langCode}`}
                        aria-selected={index === activeIndex}
                        role="option"
                        onMouseOver={() => setActiveIndex(index)}
                        isActive={index === activeIndex}
                        isChosen={currentLang === langCode}
                    >
                        <SelectLabel>
                            <SelectRadio
                                type="radio"
                                name="language_radio"
                                value={langCode}
                                checked={currentLang === langCode}
                                onChange={() => select(langCode)}
                            />
                            <SmallFlag src={langDetails.flag} />
                            {langDetails.localName}
                        </SelectLabel>
                    </SelectElement>
                ))}
            </SelectDropdown>
        </SelectContainer>
    );
};

LanguageDropdown.propTypes = {
    paddingRight: PropTypes.string,
    iconOnly: PropTypes.bool,
    openLeft: PropTypes.bool
};

export default LanguageDropdown;
