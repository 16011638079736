import React from "react";
import PropTypes from "prop-types";
import { OverlayArea } from "@sisuwellness/web-components/components/Chart";
import { useTranslation } from "react-i18next";

const AreaLayer = ({ dimension: [width], ...rest }) => {
    const { t } = useTranslation();

    return (
        <>
            <OverlayArea
                label={t("members_portal_web.views.trend.bp_props.optimal_sys")}
                upperBound={119}
                lowerBound={90}
                width={width}
                {...rest}
            />
            <OverlayArea
                label={t("members_portal_web.views.trend.bp_props.optimal_dia")}
                upperBound={79}
                lowerBound={60}
                width={width}
                {...rest}
            />
        </>
    );
};

AreaLayer.propTypes = {
    dimension: PropTypes.array
};

export default AreaLayer;
