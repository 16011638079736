import { theme } from "@sisuwellness/web-components";

/**
 * Create a media query for mobile devices
 * @return {{ query: string }}
 */
export const createQueryForMobile = () => ({ query: `(max-width: ${theme.mediaQuerySizes.mobile}px)` });

/**
 * Calculate total number of records that have been viewed
 *
 * ASSUMPTION: Number of visible records will be less than or equal to the Limit provided.
 *
 * @param {number} limit Max limit of records allowed to render on each page
 * @param {number} currentPageIndex Current Page Index
 * @param {number} visibleRecordsLength Number of records that are rendered on current page
 * @return {number}
 */
export const calculateViewedRecords = (limit, currentPageIndex, visibleRecordsLength) => {
    if (visibleRecordsLength < limit) {
        if (currentPageIndex === 1) return visibleRecordsLength;
        else return (currentPageIndex - 1) * limit + visibleRecordsLength;
    }
    return currentPageIndex * visibleRecordsLength;
};
