import React, { useCallback, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import { Box } from "rebass";

import { MediaQuery, theme } from "@sisuwellness/web-components";
import Hamburger from "components/LeftNavBar/Hamburger";
import styled from "styled-components";
import Swipe from "./swiper";
import { useDimensions } from "components/ContextProviders";

const OuterContainer = styled(Box)`
    position: relative;
    min-height: 100vh;
    height: 100vh;
`;

const MenuOverlay = ({ children, authenticated }) => {
    const isMenuOpen = useRef(false);

    const { width } = useDimensions();

    // Translate page to the right to show menu
    const handleTranslate = useCallback(() => {
        // Container containing main page components
        const mainContainer = document.getElementById("outer-container");

        // Container underneath the outer-container
        const bgContainer = document.getElementById("bg-container");
        const hamburger = document.getElementById("hamburger");
        // define transition/animations to css properties
        mainContainer.style.transitionProperty = "transform, height, margin, border-radius";
        mainContainer.style.transitionDuration = "300ms";
        mainContainer.style.transitionTimingFunction = "ease-in";

        const isOpen = isMenuOpen.current;

        // css properties to change when state of menu is changed
        mainContainer.style.transform = isOpen ? "none" : "translateX(285px)";
        mainContainer.style.minHeight = isOpen ? "100%" : "94vh";
        mainContainer.style.marginTop = isOpen ? "0px" : "16px";
        mainContainer.style.borderRadius = isOpen ? "0px" : "20px";
        mainContainer.style.overflow = isOpen ? "scroll" : "hidden";
        mainContainer.style.height = isOpen ? "max-content" : "94vh";
        mainContainer.style.border = isOpen ? "none" : "1px solid #ebe9f0";
        mainContainer.scroll = isOpen ? "no" : "yes";
        bgContainer.style.overflow = isOpen ? "scroll" : "hidden";
        bgContainer.style.backgroundColor = isOpen ? "transparent" : "#fdfcff";
        hamburger.style.visibility = isOpen ? "hidden" : "visible";
        hamburger.style.opacity = isOpen ? 0 : 1;
        isMenuOpen.current = !isMenuOpen.current;
    }, [width]);

    const handleOnDocumentClose = useCallback(() => isMenuOpen.current && handleTranslate(), [handleTranslate]);

    // handle swipe left action
    const handleSwipeLeft = useCallback(() => isMenuOpen.current && handleTranslate(), [handleTranslate]);

    useEffect(() => {
        if (window.innerWidth < theme.mediaQuerySizes.desktop) {
            // add touch listeners for swipe only for tablets and mobiles
            const swipe = new Swipe({ onLeft: handleSwipeLeft });
            return () => swipe.remove();
        }
    }, [handleSwipeLeft]);

    useEffect(() => {
        const container = document.getElementById("page-content");
        if (container) {
            container.addEventListener("click", handleOnDocumentClose);
            return () => container.removeEventListener("click", handleOnDocumentClose);
        }
    }, [handleOnDocumentClose]);

    return (
        <>
            <MediaQuery devices={["mobile", "tablet"]}>
                <OuterContainer backgroundColor="transparent" id="bg-container">
                    {authenticated && <Hamburger />}
                    {children(handleTranslate)}
                </OuterContainer>
            </MediaQuery>
            <MediaQuery devices={["desktop"]}>{children()}</MediaQuery>
        </>
    );
};

MenuOverlay.propTypes = {
    children: PropTypes.func,
    authenticated: PropTypes.bool
};

export default MenuOverlay;
