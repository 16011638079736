import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { TDEE } from "constants/trend-charts";
import { append, curry, pathOr, __ } from "ramda";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import {
    calculateTotalDailyEnergyExpenditure,
    getTDEEMetricsWithUnitPreference,
    isUnitInCalories
} from "utilities/premium";
import {
    calculateMinMax,
    createLegends,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues
} from "./utils";
import { t } from "i18next";

const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

export const METRIC_FIELDS = {
    [TDEE]: {
        rounded: true,
        key: ["bmr", "dailyEnergyUse"],
        additionalKeys: ["rating", "unitInString"]
    }
};

export const isMobileView = width => width < theme.mediaQuerySizes.mobile;

/**
 * Helper Funtions for each metric
 * @param {string} type Metric type
 * @return {{ color: (...args) => string, dataPoints: (healthChecks: [], settings: object) => { id: string, data: { x: Date, y: *, id: string }[] }[], yRange: (points: []) => { min: number, max: number, yScaleRanges: number[] }, legends: (points: []) => { id: string, color: string, label: string }[], unit: (settings: {}) => string }  } }}
 */
export const plotMetric = type => {
    switch (type) {
        case TDEE:
            return {
                unit: settings =>
                    isUnitInCalories(settings)
                        ? `${t("units.c_calories")} (${t("units.cals")})`
                        : `${t("units.c_kilojoules")} (${t("units.kilojoule")})`,

                color: ({ id, rating } = {}) =>
                    id === "bmr"
                        ? theme.colours.hpAmethystSmoke.hex
                        : theme.guidelines.DailyEnergyUse[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

                dataPoints: (healthChecks = [], settings) =>
                    createPlottableDataPoints(
                        data => ({ ...data, unit: data.unitInString }),
                        curry(getTDEEMetricsWithUnitPreference)(__, settings),
                        calculateTotalDailyEnergyExpenditure
                    )(healthChecks, METRIC_FIELDS.TDEE.key, METRIC_FIELDS.TDEE.additionalKeys),

                yRange: (points = [], width) => {
                    const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
                    const ranges = createYGridValues(0, maximum, {
                        parts: isMobileView(width) ? 5 : 7,
                        rounded: METRIC_FIELDS.TDEE.rounded
                    });

                    return {
                        min: 0,
                        max: maximum,
                        yScaleRanges: ranges.slice(1, ranges.length)
                    };
                },

                legends: (points = []) =>
                    append(
                        {
                            id: "bmr",
                            label: t("members_portal_web.components.templates.trend_chart.basal_metabolic"),
                            color: theme.colours.hpAmethystSmoke.hex
                        },
                        createLegends(
                            pathOr({}, [0, "data", 0, "rating", "ranges"], points),
                            METRIC_INDICATOR.TDEE.themeKey
                        ).reverse()
                    ),

                margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
            };

        default:
            return {};
    }
};
