import BaseEndPoint from "../BaseEndPoint";

export default class AppVersion extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        // TODO: replace the base with a proper url resolve
        return super.getUrlPath("/app-versions" + path);
    }

    settingsForGet() {
        return { ...this._getDefaultFetchSettings(), method: "GET" };
    }

    getAppVersion(appName) {
        return this._send(this.getUrlPath(`/${appName}`), this.settingsForGet(), true);
    }

    getAppBuildSpecs(appName) {
        return this._send(this.getUrlPath(`/${appName}/build-specs`), this.settingsForGet(), false);
    }

    getStationApiAppVersion() {
        return this.getAppVersion("station-api");
    }

    getStationApiBuildSpecs() {
        return this.getAppBuildSpecs("station-api");
    }

    getStationMainScreenAppVersion() {
        return this.getAppVersion("station-main-screen");
    }

    getStationAdScreenAppVersion() {
        return this.getAppVersion("station-ad-screen");
    }

    getMembersPortalAppVersion() {
        return this.getAppVersion("members-portal");
    }

    getPharmacyPortalAppVersion() {
        return this.getAppVersion("pharmacy-portal");
    }
}
