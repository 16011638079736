import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isFunction } from "lodash-es";
import { Redirect } from "react-router";

const PremiumFeatureContext = React.createContext({});
const PremiumFeatureProvider = PremiumFeatureContext.Provider;

export const PremiumFeature = ({ children, forceRender = false }) => {
    const { premium: { isInterestedInPremium = false, isPremium = false } = {} } = useSelector(
        state => state.auth.citizen
    );

    const isOnlyInterested = !isPremium && isInterestedInPremium;
    const canBePremium = isPremium || isInterestedInPremium;
    const props = { isPremium, isInterestedInPremium, canBePremium, isOnlyInterested };

    // Check if user is neither a premium member nor interested in premium, or is a forced render
    const shouldRenderContent = canBePremium || forceRender;
    if (!shouldRenderContent) return <></>;

    return (
        <PremiumFeatureProvider value={props}>
            {isFunction(children) ? (
                <PremiumFeatureContext.Consumer>{value => children(value)}</PremiumFeatureContext.Consumer>
            ) : (
                children
            )}
        </PremiumFeatureProvider>
    );
};

PremiumFeature.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    forceRender: PropTypes.bool
};

export const PremiumExclusive = ({ redirectRoute, ...rest } = {}) => {
    const { premium: { isPremium = false } = {} } = useSelector(state => state.auth.citizen);

    if (!isPremium) return redirectRoute ? <Redirect to={redirectRoute} /> : <></>;

    return <PremiumFeature {...rest} />;
};

PremiumExclusive.propTypes = {
    redirectRoute: PropTypes.string
};

/**
 * Premium HoC
 * @param {React.Component} Component
 * @return {React.Component}
 */
export const withPremium = Component => props => (
    <PremiumFeature forceRender={true}>
        <Component {...props} />
    </PremiumFeature>
);

/**
 * Hook to get premium feature related flags
 * @return {{ isPremium: boolean, isInterestedInPremium: boolean, canBePremium: boolean, isOnlyInterested: boolean }}
 */
export const usePremiumFeature = () => useContext(PremiumFeatureContext);
