import React, { useState, useEffect } from "react";

export default function withPrintable(WrappedComponent) {
    return props => {
        const [printable, setPrintable] = useState(false);

        function handleBeforePrint() {
            setPrintable(true);
        }

        function handleAfterPrint() {
            setPrintable(false);
        }

        useEffect(() => {
            window.addEventListener("beforeprint", handleBeforePrint);
            window.addEventListener("afterprint", handleAfterPrint);

            return () => {
                window.removeEventListener("beforeprint", handleBeforePrint);
                window.removeEventListener("afterprint", handleAfterPrint);
            };
        }, []);

        return (
            <>
                <WrappedComponent
                    {...props}
                    className={printable ? "page-break" : undefined}
                    // eslint-disable-next-line react/prop-types
                    printable={printable || props.printable || undefined}
                />
            </>
        );
    };
}
