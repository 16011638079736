import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(cholesterol) {
        this._validateValue(cholesterol);
        this._cholesterol = cholesterol;
    }

    /**
     * Returns the given Cholesterol value
     * @returns {Number}
     */
    get() {
        return this._cholesterol;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "TOTAL_CHOLESTEROL";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_TOTAL_CHOLESTEROL";
    }

    /**
     * Validates the given Cholesterol value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateValue(cholesterol) {
        if (!(typeof cholesterol === "number") || Number.isNaN(cholesterol) || cholesterol < 0) {
            throw new InvalidArgumentError("Cholesterol must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns if the given Cholesterol is in the 'optimal' range
     *
     * @returns {boolean}
     */
    isOptimal() {
        return this._cholesterol < 4;
    }

    /**
     * Returns if the given Cholesterol is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._cholesterol >= 4.0 && this._cholesterol < 5.5;
    }

    /**
     * Returns if the given Cholesterol is in the 'elevated' range
     *
     * @returns {boolean}
     */
    isElevated() {
        return this._cholesterol >= 5.5 && this._cholesterol < 6.5;
    }

    /**
     * Returns if the given Cholesterol is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._cholesterol >= 6.5;
    }

    /**
     * Returns an object representing various data for each Cholesterol range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            optimal: {
                key: "optimal",
                label: "ranges.optimal",
                range: "< 4.00 mmol/L"
            },

            normal: {
                key: "normal",
                label: "ranges.normal",
                range: "4.00 - 5.49 mmol/L"
            },

            elevated: {
                key: "elevated",
                label: "ranges.elevated",
                range: "5.50 - 6.49 mmol/L"
            },

            high: {
                key: "high",
                label: "ranges.high",
                range: "6.50+ mmol/L"
            }
        };
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isOptimal():
                info = SiSU.ranges.optimal.range;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.range;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.range;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.range;
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isOptimal():
                info = SiSU.ranges.optimal.key;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.key;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.key;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.key;
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let info = "";
        switch (true) {
            case this.isOptimal():
                info = SiSU.ranges.optimal.label;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.label;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.label;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.label;
                break;
        }
        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 4,
            riskLevel = 0;
        switch (true) {
            case this.isOptimal():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
            case this.isElevated():
                riskLevel = 3 / (totalRiskLevel + 1);
                break;
            case this.isHigh():
                riskLevel = 4 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }
}
