import baseTheme from "./baseTheme";

const stationTextSize = {
    heading1: { size: "90px", weight: "bold", sizeRem: "5.625rem" },
    heading2: { size: "60px", weight: "normal", sizeRem: "3.75rem" },
    heading3: { size: "45px", weight: "normal", sizeRem: "2.813rem" },
    heading4: { size: "36px", weight: "bold", sizeRem: "2.25rem" },
    heading5: { size: "30px", weight: "normal", sizeRem: "1.875rem" },
    heading6: { size: "28px", weight: "normal", sizeRem: "1.75rem" },
    largeText: { size: "34px", weight: "normal", sizeRem: "2.125rem" },
    paragraph: { size: "24px", weight: "normal", sizeRem: "1.5rem" },
    paragraph2: { size: "22px", weight: "normal", sizeRem: "1.375rem" },
    feedback: { size: "20px", weight: "bold", sizeRem: "1.25rem" },
    subText: { size: "20px", weight: "normal", sizeRem: "1.25rem" },
    smallText: { size: "16px", weight: "normal", sizeRem: "1rem" }
};

const colours = baseTheme.colours;

const stationDefault = {
    shadows: {
        rest: "drop-shadow(2px 2px 4px rgba(0,0,0,0.15))",
        elevated: "drop-shadow(4px 6px 6px rgba(0,0,0,0.25))"
    },
    colours: colours,
    text: {
        ...stationTextSize,
        title: {
            size: stationTextSize.heading2.sizeRem,
            color: colours.flat.darkGray.hex,
            family: '"Proxima Nova Semibold", Arial, sans-serif'
        },
        subTitle: {
            size: stationTextSize.heading5.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        instruction: {
            size: stationTextSize.heading4.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        info: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        footer: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        progress: {
            size: stationTextSize.heading4.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        progressUnit: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.darkGray.hex,
            family: '"Proxima Nova Semibold", Arial, sans-serif'
        },
        recommendation: {
            size: stationTextSize.heading3.sizeRem,
            color: colours.flat.darkGray.hex,
            family: '"Proxima Nova Semibold", Arial, sans-serif'
        },
        scoreName: {
            size: stationTextSize.heading5.sizeRem,
            color: colours.flat.darkGray.hex,
            family: '"Proxima Nova Semibold", Arial, sans-serif',
            notSelected: {
                size: stationTextSize.paragraph.sizeRem,
                color: colours.flat.darkGray.hex,
                family: '"Proxima Nova Semibold", Arial, sans-serif'
            }
        },
        scoreValue: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal",
            family: '"Proxima Nova Regular", Arial, sans-serif'
        },
        scoreInfoName: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        scoreInfoValue: {
            size: stationTextSize.heading5.sizeRem,
            color: colours.flat.darkGray.hex,
            family: '"Proxima Nova Semibold", Arial, sans-serif'
        },
        scoreInfoUnit: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.darkGray.hex,
            weight: "normal"
        },
        progressLabel: {
            size: stationTextSize.paragraph.sizeRem,
            color: colours.flat.gray.hex,
            weight: "normal"
        }
    },
    borderRadius: {
        veryBig: "35px",
        big: "10px",
        small: "5px"
    },
    buttonsSpacing: {
        vertical: "10px",
        horizontal: "20px"
    },
    IconSize: {
        bigger: "60px",
        big: "48px",
        medium: "32px"
    },
    scoresColors: {
        level6: colours.gradientCSS.darkRose,
        level5: colours.gradientCSS.rose,
        level4: colours.gradientCSS.orange,
        level3: colours.gradientCSS.lightGreen,
        level2: colours.gradientCSS.green,
        level1: colours.gradientCSS.darkGreen
    },
    scoresColorsSolid: {
        level6: colours.flat.darkRose.hex,
        level5: colours.flat.rose.hex,
        level4: colours.flat.orange.hex,
        level3: colours.flat.lightGreen.hex,
        level2: colours.flat.green.hex,
        level1: colours.flat.darkGreen.hex
    },
    opacity: {
        foreground: "1.00",
        background: "0.45"
    }
};

const stationTheme = {
    // Extending baseTheme
    ...baseTheme,

    // Common components
    textField: {
        size: "large"
    },
    searchBar: {
        size: "large"
    },

    // Unique components
    colours: stationDefault.colours,
    scoresColors: stationDefault.scoresColors,
    scoresColorsSolid: stationDefault.scoresColorsSolid,
    shadows: stationDefault.shadows,
    text: stationDefault.text,
    borderRadius: stationDefault.borderRadius,
    buttonsSpacing: stationDefault.buttonsSpacing,
    screens: {
        main: {
            width: "1280px",
            height: "1024px"
        },
        ad: {
            height: "1080px",
            width: "1920px"
        }
    },
    actionButtons: {
        width: "255px",
        height: "70px",
        borderRadius: stationDefault.borderRadius.small,
        text: {
            size: stationDefault.text.heading4.sizeRem,
            weight: "normal"
        },
        shadow: {
            normal: stationDefault.shadows.rest,
            active: stationDefault.shadows.elevated
        },
        primary: {
            backgroundColor: {
                normal: stationDefault.colours.flat.blue.hex,
                active: stationDefault.colours.flat.darkBlue.hex
            },
            color: {
                normal: stationDefault.colours.flat.white.hex,
                active: stationDefault.colours.flat.white.hex
            },
            border: {
                normal: "0",
                active: "0"
            }
        },
        secondary: {
            backgroundColor: {
                normal: stationDefault.colours.flat.white.hex,
                active: stationDefault.colours.flat.white.hex
            },
            color: {
                normal: stationDefault.colours.flat.blue.hex,
                active: stationDefault.colours.flat.darkBlue.hex
            },
            border: {
                normal: `3px solid ${stationDefault.colours.flat.blue.hex}`,
                active: `3px solid ${stationDefault.colours.flat.darkBlue.hex}`
            }
        }
    },
    baseContainer: {
        width: "1020px",
        height: "825px",
        marginTop: "100px"
    },
    appBar: {
        cols: ["150px", "1fr", "65px"],
        rows: ["1fr"],
        height: "80px",
        backgroundColor: stationDefault.colours.flat.darkBlue.hex,
        logoIconSize: "65%",
        checksIconSize: stationDefault.IconSize.medium,
        closeIconSize: stationDefault.IconSize.big
    },
    cardWelcome: {
        cols: ["70px", "1fr", "70px"],
        rows: ["70px", "auto", "auto", "1fr", "35px", "70px", "35px"]
    },
    cardThankYou: {
        cols: ["1fr"],
        rows: ["70px", "1fr", "88px", "35px", "70px", "35px"]
    },
    cardInstruction: {
        cols: ["68fr", "16px", "100fr"],
        rows: ["1fr"],
        textual: {
            width: "597px",
            padding: "50px 40px 30px 40px",
            rows: ["auto 1fr 70px"]
        }
    },
    cardMeasurement: {
        cols: ["100fr", "1px", "70fr"],
        rows: ["1fr"],
        textual: {
            width: "600px",
            padding: "50px 40px 30px 40px",
            rows: ["auto", "auto", "1fr", "1px", "auto"]
        },
        visual: {
            cols: ["1fr"],
            rows: ["auto", "1fr", "auto"]
        }
    },
    cardResults: {
        cols: ["100fr", "16px", "70fr"],
        rows: ["auto", "16px", "2fr"],
        cardResult: {
            rows: ["auto", "1fr", "auto", "auto"],
            padding: "50px 30px 30px 30px"
        },
        cardAction: {
            rows: ["1fr", "auto"],
            recommendation: {
                backgroundColor: stationDefault.colours.flat.white.hex,
                borderColor: stationDefault.colours.flat.darkTeal.hex,
                borderRightColor: stationDefault.colours.gradientCSS.teal,
                color: stationDefault.colours.flat.darkGray.hex,
                padding: "20px 20px 0 40px",
                iconSize: stationDefault.IconSize.bigger
            },
            buttonPadding: "30px 40px"
        }
    },
    cardScore: {
        normal: {
            cols: ["auto", "minmax(180px, auto)", "1px", "1fr"],
            maxWidth: "260px"
        },
        dense: {
            cols: ["auto", "minmax(160px, auto)", "1px", "1fr"],
            maxWidth: "260px"
        },
        thick: {
            maxHeight: {
                selected: "120px",
                normal: "70px"
            }
        },
        narrow: {
            maxHeight: {
                selected: "120px",
                normal: "67px"
            }
        },
        minHeight: "60px",
        padding: "0 10px 0 22px",
        opacity: {
            selected: stationDefault.opacity.foreground,
            normal: stationDefault.opacity.background
        },
        borderRadius: stationDefault.borderRadius.small
    },
    progress: {
        maxHeight: "230px",
        minHeight: "150px",
        percentages: {
            valueBaseBackground: stationDefault.colours.flat.gray.hex,
            valueBackground: stationDefault.colours.flat.darkTeal.hex
        }
    }
};

export default stationTheme;
