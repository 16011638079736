import styled from "styled-components";

const SecondaryLink = styled.a`
    font-size: ${props => props.theme.text.heading6.size};
    background-color: ${props => props.theme.colours.flat.white.hex};
    color: ${props => props.theme.colours.flat.blue.hex};
    border-radius: 2em;
    ${props => props.theme.shadowCss.buttonRest()};
    padding: 0.8em 3em;
    border: 2px solid ${props => props.theme.colours.flat.blue.hex};
    text-decoration: none;

    :hover {
        background-color: ${props => props.theme.colours.flat.lightBlue.hex};
        color: ${props => props.theme.colours.flat.white.hex};
        ${props => props.theme.shadowCss.buttonElevated()};
        border: 0;
    }

    :active {
        background-color: ${props => props.theme.colours.flat.blue.hex};
        ${props => props.theme.shadowCss.buttonElevated()};
        color: ${props => props.theme.colours.flat.white.hex};
        border: 0;
    }

    :focus {
        outline: none;
    }
`;

SecondaryLink.displayName = "Secondary Link";

export default SecondaryLink;
