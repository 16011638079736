import CoreUtils from "../../core/coreUtils";

const totalRiskLevel = 2;

const ranges = {
    low: {
        key: "low",
        label: "ranges.low_risk",
        range: "< 10"
    },
    elevated: {
        key: "elevated",
        label: "ranges.elevated_risk",
        range: "10 +"
    }
};

const SisuQriskGuidelineDefinition = [
    {
        min: 10,
        max: 100,
        key: "elevated",
        label: "ranges.elevated_risk",
        rangeInfo: "10+",
        riskLevel: 2 / (totalRiskLevel + 1),
        ranges: ranges
    },
    {
        min: 0,
        max: 9,
        key: "low",
        label: "ranges.low_risk",
        rangeInfo: "<10",
        riskLevel: 1 / (totalRiskLevel + 1),
        ranges: ranges
    }
];

/**
 * Finds the guideline for the given score
 * @param score
 * @returns {*}
 */
function GetGuideline(score) {
    score = Math.floor(score);
    return SisuQriskGuidelineDefinition.find(gl => CoreUtils.isWithin(score, gl.min, gl.max));
}

const RiskName = "QRISK3";
const RiskCode = "QRISK3";
const GuidelineName = "SiSU QRISK3 Guideline";
const GuidelineCode = "SISU_QRISK3";

export { GetGuideline, RiskName, RiskCode, GuidelineName, GuidelineCode, SisuQriskGuidelineDefinition };
