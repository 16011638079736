import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { Flex, Box } from "rebass";
import { Icon, MediaQuery, Card } from "@sisuwellness/web-components";

const StyledContainer = styled(Flex)`
    min-height: ${props => props.minHeight};
`;

const SettingsLayout = ({ topContent, rightColumn, leftColumn, icon, theme, minHeight, ...rest }) => (
    <Card {...rest} width={[1, 1, 3 / 4]} p={0} mx="auto">
        {topContent}

        <StyledContainer flexWrap="wrap" minHeight={minHeight}>
            <Box width={[1, 3 / 4]} p="1.25em">
                {leftColumn}
            </Box>
            <Box
                width={[1, 1 / 4]}
                backgroundColor={["white", theme.colours.flat.lightGray.hex]}
                mb={["1em", 0]}
                p="1.25em"
                textAlign="center"
            >
                <MediaQuery devices={["tablet", "desktop"]}>
                    {icon && (
                        <Icon type={icon} colour={theme.colours.flat.gray.hex} size={theme.icons.sizes.question} />
                    )}
                </MediaQuery>
                {rightColumn}
            </Box>
        </StyledContainer>
    </Card>
);

SettingsLayout.propTypes = {
    theme: PropTypes.object.isRequired,
    topContent: PropTypes.node,
    rightColumn: PropTypes.node,
    leftColumn: PropTypes.node,
    icon: PropTypes.string,
    minHeight: PropTypes.string
};

SettingsLayout.defaultProps = {
    minHeight: "70vh"
};

export default withTheme(SettingsLayout);
