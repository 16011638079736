import React from "react";
import PropTypes from "prop-types";

import { theme } from "@sisuwellness/web-components";
import { useMediaQuery } from "react-responsive";
import { keys } from "ramda";

const mobileSizeInPx = theme.mediaQuerySizes.mobile400;
const tabletSizeInPx = theme.mediaQuerySizes.tablet;
const macbookSizeInPx = theme.mediaQuerySizes.macbook;
const phabletSizeInPx = theme.mediaQuerySizes.phablet;
const mobileMaxSizeInPx = theme.mediaQuerySizes.mobile;
const smallDesktopInPx = theme.mediaQuerySizes.desktop;

const createQuery = ({ minWidth, maxWidth }) => ({
    query: `(min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`
});

const SCREENS = {
    phablet: { minWidth: mobileSizeInPx + 1, maxWidth: phabletSizeInPx },
    tablet: { minWidth: mobileMaxSizeInPx + 1, maxWidth: tabletSizeInPx },
    macbook: { minWidth: tabletSizeInPx + 1, maxWidth: macbookSizeInPx },
    macAndTab: { minWidth: mobileMaxSizeInPx + 1, maxWidth: macbookSizeInPx },
    smallDesktop: { minWidth: mobileSizeInPx + 1, maxWidth: smallDesktopInPx + 1 }
};

/**
 * @component
 * A wrapper to add extra break points if required with rebass/styled-components
 */
export const ExtraBreakpointWrapper = ({ children, screen = "macbook", ...rest }) => {
    const query = createQuery(SCREENS[screen] || SCREENS["macbook"]);
    const isMediaQueryTriggered = useMediaQuery(query);

    return isMediaQueryTriggered ? React.cloneElement(children, rest) : children;
};

ExtraBreakpointWrapper.propTypes = {
    screen: PropTypes.oneOf(keys(SCREENS))
};

export default ExtraBreakpointWrapper;
