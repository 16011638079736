import React, { Component } from "react";
import PropTypes from "prop-types";
import { Errors, StyledInput } from "../../Forms";
import QuestionTitle from "./Title";
import Help from "./Help";
import styled from "styled-components";
import theme from "../../../themes";
import QuestionIcon from "./QuestionIcon";
import { withTranslation } from "react-i18next";

const StyledContainer = styled.div``;

class Text extends Component {
    static defaultProps = {
        touchKeyboard: false,
        inputWidth: "100%"
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        if (this.props.autoFocus && this.props.onInputFocus) {
            this.props.onInputFocus(this.inputRef.current);
        }
    }

    onInputFocus() {
        if (this.props.onInputFocus) {
            this.props.onInputFocus(this.inputRef.current);
        }
    }

    handleInputChange(e) {
        this.props.onValueChange(e.target.value);
    }

    render() {
        const { model, errors, id, placeholder, autoFocus, centreText, value, inputWidth, t } = this.props;
        const hasErrors = errors && errors.length > 0;
        let getIcon = model.getQuestionIcon();
        let helpText = null;
        if (model.getHelpText()) {
            helpText = <Help>{t(model.getHelpText())}</Help>;
        }

        return (
            <StyledContainer data-testid={"survey-question-text"}>
                {hasErrors && <Errors rounded={true}>{t(errors)}</Errors>}
                {getIcon && <QuestionIcon type={theme.icons.types[getIcon]} />}
                <QuestionTitle hasErrors={hasErrors} htmlFor={id}>
                    {t(model.getDisplayText())}
                </QuestionTitle>
                {helpText}
                <StyledInput
                    hasErrors={hasErrors}
                    placeholder={placeholder}
                    id={id}
                    type={"text"}
                    autoFocus={autoFocus}
                    autoComplete={"off"}
                    ref={this.inputRef}
                    onFocus={e => this.onInputFocus(e)}
                    onInput={this.handleInputChange}
                    onChange={() => {}}
                    centreText={centreText}
                    value={value}
                    data-testid={`input-${id}`}
                    width={inputWidth}
                />
            </StyledContainer>
        );
    }
}

Text.displayName = "Text Question";
Text.propTypes = {
    id: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    onValueChange: PropTypes.func.isRequired,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
    touchKeyboard: PropTypes.bool,
    onInputFocus: PropTypes.func,
    errors: PropTypes.any,
    centreText: PropTypes.bool,
    inputWidth: PropTypes.string,
    t: PropTypes.func
};

export default withTranslation()(Text);
