import routes from "constants/routes";

/**
 *
 * @param {String} currentRoute
 * @return {Boolean} whether current rotute is static left-nav route or not
 */
export const isRouteStatic = currentRoute => {
    return (
        (currentRoute.includes(routes.healthHub) &&
            !currentRoute.includes(routes.hraResult) &&
            !currentRoute.includes(routes.heartAge) &&
            !currentRoute.includes(routes.dailyEnergy) &&
            !currentRoute.includes(routes.bodyComposition) &&
            !currentRoute.includes(routes.muscleMass)) ||
        currentRoute.includes(routes.recommendations) ||
        currentRoute.includes(routes.healthRiskOnboarding) ||
        currentRoute.includes(routes.improve) ||
        currentRoute.includes(routes.healthChecks) ||
        currentRoute.includes(routes.findHealthStations) ||
        currentRoute.includes(routes.myAccount.home) ||
        currentRoute.includes(routes.rewards)
    );
};

/**
 *
 * @param {String, String}
 * @return {String} initials of both parameter
 */
export const nameInitials = (firstName, lastName) =>
    firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : "";
