import validator from "validate.js";
import WeightCalibrationData from "../weightCalibration/weightCalibrationData";

export default class DiagnosticsData {
    constructor(schema = null) {
        this._weightCalibration = new WeightCalibrationData();

        if (schema) {
            this.fromJson(schema);
        }
    }

    set weightCalibration(value) {
        let result = WeightCalibrationData.validate(value);
        if (!result.isValid) {
            console.log("DiagnosticsData weightCalibration setter failed: ", result);
            return;
        }

        this._weightCalibration = value;
    }

    get weightCalibration() {
        return this._weightCalibration;
    }

    toJson() {
        return {
            weightCalibration: this.weightCalibration.toJson()
        };
    }

    fromJson(data) {
        let result = DiagnosticsData.validate(data);
        if (!result.isValid) {
            console.log("DiagnosticsData validate() failed: ", result);
            return;
        }

        this._weightCalibration = new WeightCalibrationData(data.weightCalibration);
    }

    static validate(schema) {
        let constraints = {
            weightCalibration: {
                presence: true
            }
        };

        let result = validator(schema, constraints);
        if (result) {
            return { result, isValid: false };
        }

        result = null;
        return { result, isValid: true };
    }
}
