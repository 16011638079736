import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Image } from "rebass";
import Slider from "@material-ui/core/Slider";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

// --------------------------------------------------------------------------------------

import Flex from "components/Flex";
import { PORTAL_IMAGE } from "constants/imagePath";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { CircularCard, LabelBox, StyledSwtich, SwitchTabs } from "./styled-components";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------------------------

const theme = createMuiTheme({
    overrides: {
        MuiSlider: {
            thumb: { color: "#372168" },
            track: { color: "#372168" },
            rail: { color: "#d7d3e1" }
        }
    }
});

// --------------------------------------------------------------------------------------

export const MetricSliders = ({
    heading,
    icon,
    units,
    actualReading,
    setReadingValue,
    minValue,
    maxValue,
    defaultValue,
    ...rest
}) => {
    const { t } = useTranslation();
    const [instantaneousReading, setInstantaneousReading] = useState(actualReading);
    useEffect(() => setInstantaneousReading(actualReading), [actualReading]);

    const handleSliderChange = (_, newValue) => {
        setInstantaneousReading(newValue);
        setReadingValue(newValue);
    };

    return (
        <Flex width="314px" gap="16px" {...rest}>
            <CircularCard size="65px">
                <Image size="45px" src={`${PORTAL_IMAGE}/premium/${icon}`} alt={icon} />
            </CircularCard>
            <Flex width="100%" fd="column">
                <Flex jc="space-between">
                    <HeadingLabel fontSize={["17px", "", "20px"]}>{t(heading)}</HeadingLabel>
                    <LabelBox color="hpDarkPurple" data-testid="slider-value">
                        {Math.round(instantaneousReading)} {t(units)}
                    </LabelBox>
                </Flex>
                <HeadingLabel fontSize={["11px", "", "13px"]} mb="16px">
                    {t("members_portal_web.views.heart_age.page.overview.simulated.sliders.heading", {
                        value: defaultValue,
                        units: t(units)
                    })}
                </HeadingLabel>
                <ThemeProvider theme={theme}>
                    <Slider
                        min={minValue}
                        max={maxValue}
                        data-testid="slider"
                        value={instantaneousReading}
                        onChange={handleSliderChange}
                    />
                </ThemeProvider>
            </Flex>
        </Flex>
    );
};

MetricSliders.propTypes = {
    icon: PropTypes.string,
    units: PropTypes.string,
    heading: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    actualReading: PropTypes.number,
    setReadingValue: PropTypes.func,
    defaultValue: PropTypes.number
};

// --------------------------------------------------------------------------------------

export const WeightSlider = ({ weight, onChange, defaultValue }) => (
    <MetricSliders
        units="units.kilograms"
        minValue={30}
        maxValue={250}
        heading="words.weight"
        icon="weight.svg"
        actualReading={weight}
        setReadingValue={onChange}
        defaultValue={defaultValue}
        data-testid="slider-weight"
    />
);

WeightSlider.propTypes = {
    weight: PropTypes.number,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number
};

// --------------------------------------------------------------------------------------

export const BloodPressureSlider = ({ systolic, onChange, defaultValue }) => (
    <MetricSliders
        units="units.mmhg"
        minValue={70}
        maxValue={260}
        heading="words.systolic_bp"
        icon="bloodPressure.svg"
        actualReading={systolic}
        setReadingValue={onChange}
        defaultValue={defaultValue}
        data-testid="slider-bp"
    />
);

BloodPressureSlider.propTypes = {
    systolic: PropTypes.number,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number
};

// --------------------------------------------------------------------------------------

export const SmokingSlider = ({ isSmoker, onChange, defaultValue }) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(isSmoker ? "YES" : "NO");
    useEffect(() => setActiveTab(isSmoker ? "YES" : "NO"), [isSmoker]);

    const isActive = key => activeTab === key;
    const handleOnClick = key => {
        setActiveTab(key);
        onChange(key === "YES");
    };

    return (
        <Flex width="314px" gap="16px" data-testid="slider-smoke">
            <CircularCard size="65px">
                <Image size="45px" src={`${PORTAL_IMAGE}/premium/smoking.svg`} alt="smoking.svg" />
            </CircularCard>
            <Flex width="100%" fd="column">
                <HeadingLabel fontSize={["17px", "", "20px"]}>{t("words.smoking")}</HeadingLabel>
                <Flex jc="space-between">
                    <HeadingLabel fontSize={["11px", "", "13px"]} mb="16px">
                        {t("members_portal_web.views.heart_age.page.overview.simulated.sliders.smoking.heading", {
                            status: defaultValue ? t("words.yes") : t("words.no")
                        })}
                    </HeadingLabel>
                    <StyledSwtich data-testid="switch-tabs">
                        <SwitchTabs isActive={isActive("YES")} onClick={() => handleOnClick("YES")}>
                            {t("words.yes")}
                        </SwitchTabs>
                        <SwitchTabs isActive={isActive("NO")} onClick={() => handleOnClick("NO")}>
                            {t("words.no")}
                        </SwitchTabs>
                    </StyledSwtich>
                </Flex>
            </Flex>
        </Flex>
    );
};

SmokingSlider.propTypes = {
    isSmoker: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.bool
};
