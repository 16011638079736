import { HealthChecksFetchStatus } from "constants/";
import {
    SET_PAGINATION_STATS,
    SET_PAGINATION_HEALTH_CHECKS,
    SET_PAGINATION_HEALTH_CHECKS_FETCH_STATUS
} from "constants/actions";

const initialState = {
    stats: {},
    healthChecks: {},
    healthChecksFetchStatus: HealthChecksFetchStatus.notStarted
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_PAGINATION_STATS: {
            return {
                ...state,
                stats: payload
            };
        }

        case SET_PAGINATION_HEALTH_CHECKS_FETCH_STATUS: {
            return {
                ...state,
                healthChecksFetchStatus: payload
            };
        }

        case SET_PAGINATION_HEALTH_CHECKS: {
            const { healthChecks } = state;
            healthChecks[payload.page] = payload.healthChecks;
            return { ...state, healthChecks };
        }

        default: {
            return state;
        }
    }
};
