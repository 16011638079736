import React from "react";
import { useHistory, Switch, Route } from "react-router";

// ----------------------------------------------------------------------------

import routes from "constants/routes";
import Preferences from "views/Preferences";
import BreadCrumbs from "views/BreadCrumbs";

import { Header, NavHeading, NavImage, NavItem, DefaultStyle } from "./styled";
import { Margin } from "components/styled-components";
import PersonalDetails from "views/PersonalDetails";
import Security from "components/SecurityDetail";
import { PORTAL_IMAGE } from "constants/imagePath";
import { PremiumFeature } from "components/PremiumFeatures";
import { LinkCard } from "./linkCard";
import BillingAndSubscription from "components/BillingAndSubscriptions";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------

const NavItemList = [
    {
        heading: "members_portal_web.views.account.nav_items.personal_details.heading",
        text: "members_portal_web.views.account.nav_items.personal_details.text",
        image: `${PORTAL_IMAGE}/myAccount/text_window_elements.svg`,
        backgroundImage: `url(${PORTAL_IMAGE}/myAccount/Illustration.svg)`,
        backgroundImageMobile: `url(${PORTAL_IMAGE}/myAccount/mobile/Illustration.svg)`,
        linkTo: routes.myAccount.personalDetails
    },
    {
        heading: "members_portal_web.views.account.nav_items.security.heading",
        text: "members_portal_web.views.account.nav_items.security.text",
        image: `${PORTAL_IMAGE}/myAccount/lock.svg`,
        backgroundImage: `url(${PORTAL_IMAGE}/myAccount/Illustration_2.svg)`,
        backgroundImageMobile: `url(${PORTAL_IMAGE}/myAccount/mobile/Illustration_2.svg)`,
        linkTo: routes.myAccount.security
    },
    {
        heading: "members_portal_web.views.account.nav_items.preference.heading",
        text: "members_portal_web.views.account.nav_items.preference.text",
        image: `${PORTAL_IMAGE}/myAccount/preferences.svg`,
        backgroundImage: `url(${PORTAL_IMAGE}/myAccount/Illustration_3.svg)`,
        backgroundImageMobile: `url(${PORTAL_IMAGE}/myAccount/mobile/Illustration_3.svg)`,
        linkTo: routes.myAccount.preferences
    },
    {
        checkPremium: true,
        heading: "members_portal_web.views.account.nav_items.billing.heading",
        text: "members_portal_web.views.account.nav_items.billing.text",
        image: `${PORTAL_IMAGE}/myAccount/money.svg`,
        backgroundImage: `url(${PORTAL_IMAGE}/myAccount/Illustration_billing.svg)`,
        backgroundImageMobile: `url(${PORTAL_IMAGE}/myAccount/mobile/Illustration_billing.svg)`,
        linkTo: routes.myAccount.billingAndSubscription
    }
];

const MyAccount = () => {
    const { t } = useTranslation();
    const history = useHistory();

    function renderSection(pathname) {
        switch (pathname) {
            case routes.myAccount.personalDetails:
            case routes.myAccount.profile:
            case routes.myAccount.address:
                return <PersonalDetails />;

            case routes.myAccount.security:
            case routes.myAccount.accountSettings:
                return <Security />;
            case routes.myAccount.preferences:
            case routes.myAccount.preferencesGeneral:
                return <Preferences />;

            case routes.myAccount.billingAndSubscription:
                return (
                    <PremiumFeature>
                        <BillingAndSubscription />
                    </PremiumFeature>
                );

            default:
                return history.push(routes.myAccount.home);
        }
    }

    function renderThinHeader(pathname) {
        const navItem = NavItemList.filter(item => pathname.includes(item.linkTo));
        return navItem.length ? (
            <NavItem item={navItem[0]} thin={true}>
                <NavHeading thin={true}>{t(navItem[0].heading)}</NavHeading>
                <NavImage src={navItem[0].image} thin={true}></NavImage>
            </NavItem>
        ) : (
            history.push(routes.myAccount.home)
        );
    }

    function renderMyAccountHome() {
        const sectionHeader = t("members_portal_web.views.account.account_home.heading");
        return (
            <>
                <Header>{sectionHeader}</Header>
                {NavItemList.map((item, index) => {
                    return item.checkPremium ? (
                        <PremiumFeature key={index}>
                            <LinkCard item={item} />
                        </PremiumFeature>
                    ) : (
                        <LinkCard item={item} key={index} />
                    );
                })}
            </>
        );
    }

    return (
        <Switch>
            <Route exact path={routes.myAccount.home} render={() => renderMyAccountHome()} />
            <Route
                render={({ location }) => (
                    <DefaultStyle>
                        <BreadCrumbs />
                        <Margin mTop={["", "", "", "48px"]}>{renderThinHeader(location.pathname)}</Margin>
                        {renderSection(location.pathname)}
                    </DefaultStyle>
                )}
            />
        </Switch>
    );
};

export default MyAccount;
