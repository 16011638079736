import React from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------

import Chevron from "./chevron";
import Tick from "./tick";

// ----------------------------------------------------------------------------

const Icons = ({ chevron, tick, ...rest }) => {
    return (
        <>
            {chevron && <Chevron {...rest} />}
            {tick && <Tick {...rest} />}
        </>
    );
};

Icons.propTypes = {
    chevron: PropTypes.bool,
    tick: PropTypes.bool,
    rest: PropTypes.shape(Chevron.propTypes)
};

export default Icons;
