import * as Yup from "yup";

import Password from "@sisuwellness/utilities/Auth/Password";
import { t } from "i18next";

// --------------------------------------------------------------------------

const PasswordUtil = new Password();
export const validationSchema = Yup.object().shape({
    email: Yup.string()
        .email(t("yup_messages.email.valid"))
        .required(t("yup_messages.email.required")),
    existingPassword: Yup.string(),
    newPassword: Yup.string()
        .test("compare-old", t("yup_messages.password.same"), function(val) {
            if (!val) {
                return true;
            }
            return this.parent.existingPassword !== val ? true : false;
        })
        .test("password-valid", Password.errors.requirement, function(val) {
            if (!val) {
                return true;
            }
            return val ? Promise.resolve(PasswordUtil.isPasswordValid(val)) : false;
        }),
    newPasswordConfirm: Yup.string().test("compare-old", t("yup_messages.password.no_match"), function(val) {
        if (!val) {
            return true;
        }
        return this.parent.newPassword == val ? true : false;
    })
});
