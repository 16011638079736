import React, { useState } from "react";

import { useLocation } from "react-router-dom";
import { isEmpty } from "lodash-es";

// ------------------------------------------------------------

import { useAsyncData } from "@sisuwellness/utilities/hooks";
import PageLayout from "components/PageLayout";
import LoadingView from "components/LoadingView";
import APIClient from "utilities/APIClient";

import Signup from "./signup";
import { isSignUpFlow } from "./utils";

// ------------------------------------------------------------

const SignupView = () => {
    const [consents, setConsents] = useState();
    const [locale, setLocale] = useState();
    const { state = {} } = useLocation();

    const [{ isLoading, data = {} }] = useAsyncData(
        () => isSignUpFlow(state).basic && APIClient.consents.getConsents()
    );

    if (isLoading) return <LoadingView />;

    if (!consents && !locale && !isEmpty(data)) {
        setConsents(data.registrationConsents);
        setLocale(data.locale.languageTag);
    }

    return (
        <PageLayout containerProps={{ bg: "hpPrimaryPurple" }}>
            <Signup consents={consents || state.consents} locale={locale || state.locale} state={state} />
        </PageLayout>
    );
};

export default SignupView;
