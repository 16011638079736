import Rule from "./Rule";
import { QUESTIONS_VALIDATION_SCHEMA } from "../Schemas/Questions";
import * as yup from "yup";

export default class RuleGroup {
    /**
     * @return {*}
     * @constructor
     */
    static get SCHEMA_VALIDATION() {
        return yup
            .object()
            .shape({
                ruleGroupId: yup.number().required(),
                name: yup.string().required(),
                icon: yup.string().required(),
                rulesSatisfier: yup
                    .string()
                    .oneOf(this.SUPPORTED_SATISFIERS)
                    .required(),
                rules: yup
                    .array()
                    .of(Rule.SCHEMA_VALIDATION)
                    .required()
            })
            .required();
    }

    /**
     * Returns an array of supported satisfiers
     *
     * @return {[String]}
     * @constructor
     */
    static get SUPPORTED_SATISFIERS() {
        return [RuleGroup.SATISFIER_ALL, RuleGroup.SATISFIER_ANY, RuleGroup.SATISFIER_NONE];
    }
    /**
     * The "All" satisfier - all rules within this group must be valid for this rule group to be valid
     *
     * @return {String}
     * @constructor
     */
    static get SATISFIER_ALL() {
        return "All";
    }

    /**
     * The "Any" satisfier - at least one rule within this group must be valid for this rule group to be valid
     *
     * @return {String}
     * @constructor
     */
    static get SATISFIER_ANY() {
        return "Any";
    }

    /**
     * The "None" satisfier - no rules within this group must be valid for this rule group to be valid
     *
     * @return {String}
     * @constructor
     */
    static get SATISFIER_NONE() {
        return "None";
    }

    /**
     * Initialises a Rule Group with a specific schema
     * @param {{ruleGroupId: Number, name:String, icon:String, rulesSatisfier:String, rules:[Object]}} schema
     */
    constructor(schema) {
        RuleGroup.SCHEMA_VALIDATION.validateSync(schema);

        this._ruleGroupId = schema.ruleGroupId;
        this._name = schema.name;
        this._icon = schema.icon;
        this._rulesSatisfier = schema.rulesSatisfier;

        /**
         * @type {[Rule]}
         * @private
         */
        this._rules = [];

        for (let ruleSchema of schema.rules) {
            this._rules.push(new Rule(ruleSchema));
        }
    }

    /**
     * @return {{name: String, rules: {comparison: String, comparisonValue: String, questionCode: String}[], ruleGroupId: Number, rulesSatisfier: String}}
     */
    get schema() {
        return {
            ruleGroupId: this._ruleGroupId,
            name: this._name,
            icon: this._icon,
            rulesSatisfier: this._rulesSatisfier,
            rules: this._rules.map(rule => rule.schema)
        };
    }

    /**
     * Validates the current rule against an array of answers
     *
     * @param {[{questionCode:String, answers:[{answer:String}]}]} questions
     * @return {{valid:Boolean, schema:Object, validRules:[{valid:Boolean, match:String, schema:{Object}}]}}
     * @throws Error
     */
    validate(questions) {
        QUESTIONS_VALIDATION_SCHEMA.validateSync(questions);

        const validRules = [];

        for (let rule of this._rules) {
            const result = rule.validate(questions);

            if (result.valid) {
                validRules.push(result);
            }
        }

        let valid = false;
        switch (this._rulesSatisfier) {
            case RuleGroup.SATISFIER_ALL:
                valid = validRules.length === this._rules.length;
                break;
            case RuleGroup.SATISFIER_ANY:
                valid = validRules.length > 0;
                break;
            case RuleGroup.SATISFIER_NONE:
                valid = validRules.length === 0;
                break;
        }

        return {
            valid,
            schema: this.schema,
            validRules
        };
    }
}
