import React from "react";
import PropTypes from "prop-types";

import { Box, Image } from "rebass";
import styled, { css } from "styled-components";
import chroma from "chroma-js";
import { position } from "styled-system";

// -------------------------------------------------------------------------

import { UserProfile } from "@sisuwellness/utilities/User";
import { SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { media } from "@sisuwellness/web-components/themes";
import FlexWithGap from "components/Flex";
import { scrollTo } from "utilities/commonUtils";
import { PORTAL_IMAGE } from "constants/imagePath";
import { usePremiumFeature } from "components/PremiumFeatures";
import { useConversionModal } from "components/ContextProviders";

import { Heading } from "./styled";
import { useTranslation } from "react-i18next";

// -------------------------------------------------------------------------

const background = css`
    border-radius: 10px;
    background-size: cover;
    background-image: url("${PORTAL_IMAGE}/premium/billing-status.svg");

    ${media.mobile`
        background-image: url("${PORTAL_IMAGE}/premium/billing-status-m.svg")
    `}  
`;

const StyledContainer = styled(FlexWithGap)`
    position: relative;
    ${({ isPremium }) => isPremium && background}
`;

const PremiumTag = styled(Heading)`
    position: absolute;

    ${position};
`;

export const DashboardBanner = ({ userProfile, isCardPresent, ...rest }) => {
    const { t } = useTranslation();
    const profile = new UserProfile(userProfile);
    const { gender, firstName } = profile;

    const { isPremium, isInterestedInPremium } = usePremiumFeature();
    const { triggerModal } = useConversionModal();

    const handleScrollToTitle = () => {
        const title = document.getElementById("healthhub-title");
        const hraCard = document.getElementById("healthhub-hra-card");
        const accountInfo = document.getElementById("healthhub-accountInfo");
        if (title && accountInfo) {
            const offset = title.offsetTop - accountInfo.offsetTop;
            scrollTo(0, offset);
        } else if (accountInfo && hraCard) {
            const offset = hraCard.offsetTop - accountInfo.offsetTop;
            scrollTo(0, offset);
        }
    };

    const checkIfIsInterestedButNotPremium = isInterestedInPremium && !isPremium;

    return (
        <Box id="healthhub-accountInfo" {...rest}>
            <StyledContainer
                ai="center"
                isPremium={isPremium}
                fd={["column", "", "row"]}
                gap={["24px", "24px", "32px"]}
                p={["24px 16px 18px", "32px"]}
                bg={isPremium ? "hpLightBlue" : "hpCardBackground"}
            >
                <Image
                    alt="avatar-logo"
                    size={["75px", "120px"]}
                    src={`${PORTAL_IMAGE}/healthHub/${gender.toLowerCase()}.svg`}
                />
                <FlexWithGap fd="column" gap="24px">
                    <Heading fontSize={["17px", "22px"]}>
                        {t("words.hello")} {firstName},
                        <br />
                        {t("members_portal_web.views.health_hub.dashboard.welcome")}
                    </Heading>
                    <FlexWithGap fd={["column", "", "row"]} gap="16px">
                        <SecondaryButton
                            minHeight="40px"
                            color="hpPrimaryPurple"
                            width={["100%", "", "250px"]}
                            onClick={handleScrollToTitle}
                            borderColor="hpPrimaryPurple"
                            backgroundColor={
                                isPremium
                                    ? chroma("white")
                                          .alpha(0.5)
                                          .hex()
                                    : "transparent"
                            }
                        >
                            {isCardPresent
                                ? t("members_portal_web.views.health_hub.dashboard.view_latest")
                                : t("members_portal_web.views.health_hub.dashboard.take_hra")}
                        </SecondaryButton>
                        {checkIfIsInterestedButNotPremium && (
                            <SecondaryButton
                                minHeight="40px"
                                color="hpPrimaryPurple"
                                width={["100%", "", "250px"]}
                                backgroundColor="transparent"
                                borderColor="hpPrimaryPurple"
                                onClick={() => triggerModal(true)}
                            >
                                {t("members_portal_web.views.health_hub.dashboard.unlock_plus")}
                            </SecondaryButton>
                        )}
                    </FlexWithGap>
                </FlexWithGap>
                {isPremium && (
                    <PremiumTag
                        right={["16px", "32px"]}
                        top={["24px", "32px"]}
                        fontSize="13px"
                        fontWeight="bold"
                        opacity="0.7"
                        width={["75px", "122px"]}
                    >
                        {t("members_portal_web.views.health_hub.dashboard.plus_member")}
                    </PremiumTag>
                )}
            </StyledContainer>
        </Box>
    );
};

DashboardBanner.propTypes = {
    ...Box.propTypes,
    userProfile: PropTypes.object,
    isCardPresent: PropTypes.bool
};
