export default class Response {
    constructor(payload) {
        this.rawPayload = payload;
    }

    succeeded() {
        return this.rawPayload.status >= 200 && this.rawPayload.status < 300;
    }

    failed() {
        return this.rawPayload.status >= 400;
    }

    isNetworkFailure() {
        return this.rawPayload.status === 0;
    }

    isNotAuthorised() {
        return this.rawPayload.status === 401 || this.rawPayload.status === 403;
    }

    isConflict() {
        return this.rawPayload.status === 409;
    }

    isNotFound() {
        return this.rawPayload.status === 404;
    }

    isBadRequest() {
        return this.rawPayload.status === 400;
    }

    getRawResponse() {
        return this.rawPayload;
    }

    getRawStatusCode() {
        return this.rawPayload.status;
    }

    async body() {
        return await this.getRawResponse().data;
    }
}
