import React, { useContext } from "react";
import PropTypes from "prop-types";
import MessageProvider from "./messageProvider";
import HRAStateProvider from "./hraStateProvider";
import DimensionProvider from "./dimensions";
import { DimensionContext, HRAStateContext, MessageContext } from "components/Context";
import { ConversionModalProvider, useConversionModal } from "./ConversionModal";

const ContextProviders = ({ children }) => {
    return (
        <MessageProvider>
            <ConversionModalProvider>
                <DimensionProvider>
                    <HRAStateProvider>{children}</HRAStateProvider>
                </DimensionProvider>
            </ConversionModalProvider>
        </MessageProvider>
    );
};

ContextProviders.propTypes = {
    children: PropTypes.any.isRequired
};

export { useConversionModal };
export default ContextProviders;

/**
 * Hook for window dimensions
 * @return {{ width: number, height: number}}
 */
export const useDimensions = () => useContext(DimensionContext);

/**
 * Hook for message banner
 * @return {{ message: string, handleMessage: Function, isDismissible: boolean }}
 */
export const useMessageBanner = () => useContext(MessageContext);

/**
 * Hook for hra state provider
 * @return {{ setPreviousQuestion: Function, getHraState: Function, resetState: Function }}
 */
export const useHraStateProvider = () => useContext(HRAStateContext);
