import React, { useCallback } from "react";

import { Box, Image, Text } from "rebass";
import styled from "styled-components";

// ------------------------------------------------------------------------

import { media } from "@sisuwellness/web-components/themes";
import { default as Flex } from "components/Flex";
import { PrimaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { usePremiumFeature } from "components/PremiumFeatures";
import { useConversionModal } from "components/ContextProviders";
import { PORTAL_IMAGE } from "constants/imagePath";
import { useChargebee } from "utilities/chargebee";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------

const StyledContainer = styled(Box)`
    grid-column: span 2;
    border-radius: 5px;
    background-image: url("${PORTAL_IMAGE}/premium/billing-status.svg");

    ${media.mobile`
        background-image: url("${PORTAL_IMAGE}/premium/billing-status-m.svg")
    `}
`;

const StyledFlex = styled(Flex)`
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.mobile`
        display: none;
    `}
`;

export const CONTENT = {
    CORE: {
        cta: "members_portal_web.components.billing_subscriptions.status_card.core.cta",
        name: "members_portal_web.components.billing_subscriptions.status_card.core.name",
        description: "members_portal_web.components.billing_subscriptions.status_card.core.description"
    },
    PREMIUM: {
        cta: "members_portal_web.components.billing_subscriptions.status_card.premium.cta",
        name: "members_portal_web.components.billing_subscriptions.status_card.premium.name",
        description: "members_portal_web.components.billing_subscriptions.status_card.premium.description"
    }
};

export const StatusCard = () => {
    const { t } = useTranslation();
    const { isPremium } = usePremiumFeature();
    const { triggerModal } = useConversionModal();

    const Chargebee = useChargebee();

    const { cta, description } = isPremium ? CONTENT.PREMIUM : CONTENT.CORE;

    const handleOnClick = useCallback(() => (isPremium ? Chargebee.openChargebeePortal() : triggerModal(true)), [
        isPremium
    ]);

    const image = {
        alt: "sisu-plus",
        src: `${PORTAL_IMAGE}/premium/status_plus_logo.svg`
    };

    return (
        <StyledContainer width={["100%", "", "714px"]} bg="hpLightBlue">
            <Flex p="20px" gap={["24px", "40px"]} fd={["column", "row"]}>
                <StyledFlex bg="white" minWidth="130px" height="110px">
                    <Image {...image} />
                </StyledFlex>
                <Flex fd="column" jc="center" gap="8px">
                    <Text fontSize="12px" color="blackTints600">
                        {t(description)}
                    </Text>
                </Flex>
                <Flex alignSelf="center" mb="4px">
                    <PrimaryButton width="135px" onClick={handleOnClick}>
                        {t(cta)}
                    </PrimaryButton>
                </Flex>
            </Flex>
        </StyledContainer>
    );
};
