import React from "react";
import PropTypes from "prop-types";
import { Box } from "rebass";
import { LineChart } from "components/Templates/TrendChart";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import OutcomeHistory from "components/Templates/OutcomeHistory";
import { baseTheme as theme } from "@sisuwellness/web-components/theme";
import { isMobileView } from "components/Templates/TrendChart/Line/utils";
import { find, mergeRight, pathOr, propEq } from "ramda";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";
import {
    calculateMinMax,
    createLegends,
    createPlottableDataPoints,
    createYGridValues,
    flattenPointValues,
    calculateMeanValue
} from "components/Templates/TrendChart/Line/utils";
import { SisuHeartAgeGuideline } from "@sisuwellness/utilities/Guidelines/HeartAge";
import { calculateHeartAgeParams } from "views/HeartAge/utils";
import { fixedNumber } from "utilities/commonUtils";
import { useTranslation } from "react-i18next";

const TenYearCvdRiskTrend = ({ healthChecks, lastMeasurementDate, latestHealthCheckData, ...rest }) => {
    const { t } = useTranslation();

    const defaultColor = theme.portalColor.hpPrimaryPurple.hex;

    const METRIC_FIELDS = {
        rounded: false,
        key: ["tenYearCvdRisk"],
        additionalKeys: ["age", "heartAge", "heartAgeOptimal", "rating", "unit"]
    };

    const plot = {
        unit: () => t("words.heart_stroke_risk") + " (%)",

        color: ({ rating } = {}) => theme.guidelines.HeartAge[(rating?.key)]?.sisuPortal?.hex ?? defaultColor,

        dataPoints: (healthChecks = []) =>
            createPlottableDataPoints(
                h => (h.heartAge > 0 && h.tenYearCvdRisk > 0 ? mergeRight(h, { unit: "%" }) : {}),
                calculateHeartAgeParams
            )(healthChecks, METRIC_FIELDS.key, METRIC_FIELDS.additionalKeys),

        yRange: (points = []) => {
            const { maximum } = calculateMinMax(flattenPointValues(points, "y"));
            const ranges = createYGridValues(0, maximum, { parts: 5, rounded: METRIC_FIELDS.rounded }).map(num =>
                fixedNumber(num)
            );

            return {
                min: 0,
                yScaleRanges: ranges,
                max: ranges[ranges.length - 1]
            };
        },

        legends: (points = []) =>
            createLegends(
                pathOr({}, [0, "data", 0, "rating", "ranges"], points),
                METRIC_INDICATOR.TEN_YEAR_CVD_RISK.themeKey,
                "words.risk"
            ),

        historyProps: (filteredPoints = [], latestHealthCheck = {}) => {
            const { age, heartAge, heartAgeOptimal, tenYearCvdRisk } = latestHealthCheck;

            const rating = SisuHeartAgeGuideline(heartAge, heartAgeOptimal, age);

            const meanAge = calculateMeanValue("age", find(propEq("id", "tenYearCvdRisk"))(filteredPoints));
            const meanHeartAge = calculateMeanValue("heartAge", find(propEq("id", "tenYearCvdRisk"))(filteredPoints));
            const meanTenYearCvdRisk = calculateMeanValue(
                "tenYearCvdRisk",
                find(propEq("id", "tenYearCvdRisk"))(filteredPoints)
            );

            const { key: meanRatingKey } = SisuHeartAgeGuideline(meanHeartAge, heartAgeOptimal, meanAge);

            return {
                unit: "%",
                value: tenYearCvdRisk,
                meanValue: fixedNumber(meanTenYearCvdRisk),
                valueColor: theme.guidelines.HeartAge[(rating?.key)]?.sisuPortal?.hex,
                meanColor: theme.guidelines.HeartAge[meanRatingKey]?.sisuPortal?.hex
            };
        },

        markers: (data, width) => [
            {
                axis: "y",
                textStyle: {
                    fill: "#736495",
                    fontSize: "11px",
                    transform: isMobileView(width) && "translate(250px, -14px) rotate(0deg)"
                },
                value: data.tenYearCvdRiskNormal,
                legendOrientation: "horizontal",
                lineStyle: { stroke: "#736495", strokeWidth: 1, strokeDasharray: 8 },
                legend: t("members_portal_web.views.heart_age.page.history.covid_risk_trend.age_gender", {
                    gender: data.gender.toLowerCase()
                })
            }
        ],

        margins: width => ({ right: isMobileView(width) ? 16 : 80, left: isMobileView(width) ? 50 : 80 })
    };

    const cvdRiskProps = { plottingUtility: plot };

    return (
        <Box width="100%" textAlign="left" mb={["30px", "60px"]} {...rest}>
            <HeadingLabel fontSize="22px" fontWeight="500">
                {t("members_portal_web.views.heart_age.page.history.covid_risk_trend.heading")}
            </HeadingLabel>
            <NormalLabel fontSize="15px" fontWeight="300" mt="8px">
                {t("members_portal_web.views.heart_age.page.history.covid_risk_trend.label")}
            </NormalLabel>
            <LineChart healthChecks={healthChecks} latestHealthCheckData={latestHealthCheckData} {...cvdRiskProps}>
                {({ filter, historyProps }) => (
                    <OutcomeHistory
                        py="24px"
                        filter={filter}
                        {...historyProps}
                        lastMeasurementDate={lastMeasurementDate}
                    />
                )}
            </LineChart>
        </Box>
    );
};

TenYearCvdRiskTrend.propTypes = {
    ...Box.propTypes,
    healthChecks: PropTypes.array,
    lastMeasurementDate: PropTypes.string,
    latestHealthCheckData: PropTypes.object
};

export default TenYearCvdRiskTrend;
