import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex } from "rebass";

const StyledHeader = styled(Flex)`
    border-bottom: ${props => props.showBorder && props.theme.table.header.border};
    font-weight: bold;
    font-family: "Proxima Nova Semibold", Arial, sans-serif;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    height: 100%;
    line-height: ${props => props.theme.table.header.lineHeight};
    font-size: 1rem;
`;

const Header = ({ children, ...rest }) => {
    return <StyledHeader {...rest}>{children}</StyledHeader>;
};

Header.propTypes = {
    showBorder: PropTypes.bool,
    children: PropTypes.node,
    ...Flex.propTypes
};

Header.defaultProps = {
    justifyContent: "center",
    alignItems: "center",
    p: "10px",
    showBorder: true,
    width: "100%",
    flexWrap: "wrap"
};

export default React.memo(Header);
