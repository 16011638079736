import React from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";

const Height = ({ t, theme, date, height, ...rest }) => {
    return (
        <Attribute
            {...rest}
            t={t}
            title={t("web_components.cards.attributes.height.title")}
            date={date}
            primaryStatUnit={"cm"}
            primaryStatValue={typeof height == "number" ? height.toFixed(1) : ""}
            icon={theme.icons.types.height}
        />
    );
};

Height.displayName = "Body Mass Index Attribute";
Height.propTypes = {
    t: PropTypes.func,
    theme: PropTypes.any.isRequired,
    height: PropTypes.number,
    date: PropTypes.string
};

export default withTranslation()(withTheme(Height));
