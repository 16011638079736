import { PORTAL_IMAGE } from "constants/imagePath";

export const getFlagPath = countryCode => {
    switch (countryCode) {
        case "GB":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/gb.svg`,
                alt: "gb-flag"
            };
        case "AU":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/au.svg`,
                alt: "au-flag"
            };
        case "DE":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/de.svg`,
                alt: "de-flag"
            };
        case "IE":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/ie.svg`,
                alt: "ie-flag"
            };
        case "ZA":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/za.svg`,
                alt: "za-flag"
            };
        case "NZ":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/nz.svg`,
                alt: "nz-flag"
            };
        case "ZZ":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/other.svg`,
                alt: "other-flag"
            };
        case "CH":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/ch.svg`,
                alt: "ch-flag"
            };
        case "SK":
            return {
                src: `${PORTAL_IMAGE}/countryFlags/sk.svg`,
                alt: "sk-flag"
            };
        default:
            return {
                src: `${PORTAL_IMAGE}/countryFlags/au.svg`,
                alt: "au-flag"
            };
    }
};
