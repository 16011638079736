import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import { Flex as Flexbox, Box, Text, Image } from "rebass";
import styled, { ThemeContext } from "styled-components";
import { useSelector } from "react-redux";
import { position, typography, compose } from "styled-system";

//------------------------------------------------------------------------------

import ProgressBar from "@sisuwellness/ui/src/components/ProgressBar";
import calculatePointColor from "utilities/trend-chart/calculateGuldeline";
import { progress, parseRangeForWeightInStLbs } from "utilities/progressBar";
import { WEIGHT } from "constants/trend-charts";
import { useContext } from "react";
import { media } from "@sisuwellness/web-components/themes";
import RecommendationPopup from "./RecommendationPopup";
import { PORTAL_IMAGE } from "constants/imagePath";
import { pluck, compose as comp, filter, length } from "ramda";
import getTooltipValues from "./targetValue";
import ProgressTooltips from "./ProgressTooltips";
import { useTranslation } from "react-i18next";
//------------------------------------------------------------------------------
const ProgressInfo = styled(Box)(
    compose(
        position,
        typography
    )
);
const Flex = styled(Flexbox)`
    & > * + * {
        margin-left: 10px;

        ${media.mobile`
            margin-left: 4px;
        `}
    }

    ${position}
`;

//------------------------------------------------------------------------------

const DEFAULT_UNIT = "metric";

const ProgressView = ({ type, datum, showPopup = true, modalHeading = "" }) => {
    const { t } = useTranslation();

    const {
        settings: { unitMeasurement = DEFAULT_UNIT }
    } = useSelector(state => state);
    const theme = useContext(ThemeContext);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const isUnitMetric = unitMeasurement === DEFAULT_UNIT;

    const isTypeWeight = type === WEIGHT;

    const getRangeForImperialMetric = range => {
        if (!isTypeWeight || isUnitMetric) return range;
        return parseRangeForWeightInStLbs(range);
    };

    const getRange = useCallback(getRangeForImperialMetric, [unitMeasurement, type]);

    let { currentValue, pointColor, rating, guideline } = calculatePointColor(type, datum);

    if (!guideline) return null;

    const { value, unit, target } = getTooltipValues(type, { guideline, currentValue, isUnitMetric });
    const ranges = guideline.ranges || guideline.constructor.ranges;

    if (!ranges) return null;

    const metricProgressData = progress(type, ranges, isUnitMetric);

    const isPopupRecommendationsAvailable = comp(length, filter(x => x), pluck("recommendation"))(metricProgressData);

    return (
        <>
            <Flex minHeight={["90px", "150px"]} alignItems="center">
                <Flex width="100%" justifyContent="center" position="relative">
                    {metricProgressData.map(({ color, label, range }, index) => (
                        <React.Fragment key={index}>
                            <ProgressTooltips
                                rating={rating}
                                label={label}
                                unit={unit}
                                type={type}
                                range={range}
                                pointColor={pointColor}
                                value={value}
                                target={target}
                                index={index}
                                isUnitMetric={isUnitMetric}
                            />
                            <ProgressBar bg={color} key={index} borderRadius="0px" id="mp-outcome-bar">
                                <ProgressInfo
                                    top="105%"
                                    mt="16px"
                                    textAlign="center"
                                    position="absolute"
                                    fontSize={["9px", "15px"]}
                                    color={theme.colours.blackTints700.hex}
                                >
                                    {getRange(range)} <br /> {t(label)}
                                </ProgressInfo>
                            </ProgressBar>
                        </React.Fragment>
                    ))}
                </Flex>
            </Flex>
            {showPopup && Boolean(isPopupRecommendationsAvailable) && (
                <>
                    <Flexbox mt="25px" alignItems="center" justifyContent="center">
                        <Image
                            mr="5px"
                            src={`${PORTAL_IMAGE}/information.svg`}
                            height={["12.7px", "16px"]}
                            width={["12.7px", "16px"]}
                            alt="information"
                        />
                        <Text
                            textAlign="center"
                            onClick={() => setIsModalOpen(true)}
                            color={theme.colours.hpPrimaryPurple.hex}
                            css={{ cursor: "pointer" }}
                            fontSize={["12px", "14px"]}
                            fontWeight={["400", "500"]}
                        >
                            {modalHeading || t("members_portal_web.components.progress_view.modal_heading_default")}
                        </Text>
                    </Flexbox>
                    <RecommendationPopup
                        heading={modalHeading}
                        data={metricProgressData}
                        type={type}
                        modalIsOpen={isModalOpen}
                        closeModal={() => setIsModalOpen(false)}
                    />
                </>
            )}
        </>
    );
};

ProgressView.propTypes = {
    type: PropTypes.string,
    datum: PropTypes.object,
    showPopup: PropTypes.bool,
    modalHeading: PropTypes.string
};

export default ProgressView;
