import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import styled, { ThemeContext } from "styled-components";
import { position, compose, border, typography } from "styled-system";

import Tooltip from "@sisuwellness/ui/src/components/Tooltip";
import withDimensions from "components/HOC/withDimensions";
import { BLOOD_PRESSURE, WEIGHT } from "constants/trend-charts";
import { fixedNumber } from "utilities/commonUtils";
import { LEFT_TOOLTIP, RIGHT_TOOLTIP } from "constants/index";
import { tooltipPosition } from "utilities/progressBar";
import { UnitConverter } from "@sisuwellness/utilities";
import { max } from "ramda";

const StyledTooltip = styled(Tooltip)(
    compose(
        position,
        border
    )
);

const CustomFlex = styled(Flex)(
    compose(
        position,
        typography
    )
);

const CustomText = styled(Text)(position);

const RatingTooltip = ({
    unit,
    type,
    title,
    range,
    bgColor,
    index = 1,
    value: currentValue,
    dimension,
    gapBwRatings,
    isUnitMetric = false,
    isTargetVisible = false
}) => {
    const [positionFromLeft, setPositionFromLeft] = useState(0);
    const [tooltipWidth, setTooltipWidth] = useState(0);
    const [polygonType, setPolygonType] = useState("");
    const [textWidth, setTextWidth] = useState(0);
    const [titleWidth, setTitleWidth] = useState(0);

    const [windowWidth] = dimension;

    const theme = useContext(ThemeContext);

    useEffect(() => {
        handleTranslation();
    }, [index, currentValue, range, type, dimension, tooltipWidth, gapBwRatings]);

    let value = fixedNumber(currentValue);

    if (type === BLOOD_PRESSURE) value = currentValue;

    const marginWidth = windowWidth < 600 ? 4 : 10;
    const tooltipMargin = 4;

    const handleTooltipWidth = el => setTooltipWidth(el ? el.getBoundingClientRect().width / 2 : 0);
    const handleTextWidth = el => setTextWidth(el ? el.getBoundingClientRect().width : 0);
    const handleTitleWidth = el => setTitleWidth(el ? el.getBoundingClientRect().width : 0);

    // Calulates translations for tooltip for progress bar
    const handleTranslation = () => {
        let posOverlapDiff = 0;
        let marginDiff = index * marginWidth - tooltipWidth;
        setPolygonType("");
        const el = document.getElementById("mp-outcome-bar");
        const width = el.getBoundingClientRect().width;
        if (isTargetVisible) {
            if (Math.abs(gapBwRatings) <= tooltipWidth * 2) {
                const tooltipLeftDiff = tooltipWidth / 2;
                const tooltipRightDiff = (-tooltipWidth * 3) / 4;
                const isTarget = title === "Target";
                if (gapBwRatings < 0) {
                    posOverlapDiff = isTarget ? tooltipLeftDiff : tooltipRightDiff;
                    setPolygonType(isTarget ? LEFT_TOOLTIP : RIGHT_TOOLTIP);
                    marginDiff += isTarget ? 0 : -(marginWidth + tooltipMargin);
                } else {
                    posOverlapDiff = isTarget ? tooltipRightDiff : tooltipLeftDiff;
                    setPolygonType(isTarget ? RIGHT_TOOLTIP : LEFT_TOOLTIP);
                    marginDiff += isTarget ? -(marginWidth + tooltipMargin) : 0;
                }
            }
        }
        const pos = tooltipPosition(index, value, range, type, width) + marginDiff;
        setPositionFromLeft(pos + posOverlapDiff);
    };

    const { colours } = theme;

    const textColor =
        bgColor === colours.hpDarkGreen.hex || bgColor === colours.hpDarkPink.hex
            ? colours.hpWhite.hex
            : colours.flat.lightBlack.hex;

    const updatedValue = (() => {
        switch (type) {
            case WEIGHT:
                if (isUnitMetric) return `${value} ${unit}`;
                var { stones, pounds } = UnitConverter.poundsToStLbs(value);
                return `${stones} st ${pounds.toFixed(1)} lbs`;

            default:
                return `${value} ${unit}`;
        }
    })();

    return (
        <StyledTooltip
            bg={bgColor}
            bottom="100%"
            borderRadius="2px"
            position="absolute"
            mb={["4px", "10px"]}
            width={max(textWidth, titleWidth) + 10}
            ref={handleTooltipWidth}
            minHeight={["38px", "63px"]}
            left={positionFromLeft}
            polygonType={polygonType}
        >
            <CustomFlex
                fontSize={["11px", "15px"]}
                textAlign="center"
                color={textColor}
                alignItems="center"
                flexDirection="column"
                justifyContent="flex-start"
                height={["30px", "50px"]}
            >
                <CustomText ref={handleTitleWidth}>
                    <Text mt={["0px", "3px"]} style={{ whiteSpace: "nowrap" }}>
                        {title}
                    </Text>
                </CustomText>
                <CustomText ref={handleTextWidth} fontSize={["10px", "15px"]}>
                    <Text mt={["-3px", "0px"]} style={{ whiteSpace: "nowrap" }}>
                        {updatedValue}
                    </Text>
                </CustomText>
            </CustomFlex>
        </StyledTooltip>
    );
};

RatingTooltip.propTypes = {
    unit: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
    range: PropTypes.string,
    bgColor: PropTypes.string,
    index: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dimension: PropTypes.arrayOf(PropTypes.number),
    gapBwRatings: PropTypes.number,
    isTargetVisible: PropTypes.bool,
    isUnitMetric: PropTypes.bool
};

export default withDimensions(RatingTooltip);
