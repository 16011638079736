import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";
import { theme } from "../../theme";

// ------------------------------------------------------------------------

const HeadingLabel = ({ children, fs = 20, ...rest }) => (
    <Text fontSize={fs} fontWeight="400" color={theme.colours.hpPrimaryPurple.hex} {...rest}>
        {children}
    </Text>
);

HeadingLabel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    fs: PropTypes.any
};

export default HeadingLabel;
