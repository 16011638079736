import React from "react";
import PropTypes from "prop-types";

const Tick = ({ width, height, fill, stroke, viewBox, strokeWidth, strokeLinecap, strokeLinejoin, ...rest }) => {
    return (
        <svg width={width} height={height} fill={fill} viewBox={viewBox} {...rest}>
            <path
                stroke={stroke}
                strokeWidth={strokeWidth}
                strokeLinecap={strokeLinecap}
                strokeLinejoin={strokeLinejoin}
                d="M22 8.2l-11 11-5-5"
            />
        </svg>
    );
};

Tick.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    viewBox: PropTypes.string,
    strokeWidth: PropTypes.string,
    strokeLinecap: PropTypes.string,
    strokeLinejoin: PropTypes.string
};

Tick.defaultProps = {
    width: "28",
    height: "28",
    fill: "none",
    stroke: "#000",
    viewBox: "0 0 28 28",
    strokeWidth: "1px",
    strokeLinecap: "round",
    strokeLinejoin: "round"
};

export default Tick;
