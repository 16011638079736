import React from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { useTranslation } from "react-i18next";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";

const BodyFat = ({ age, gender, bodyFatPercent, theme, date, ...rest }) => {
    const { t } = useTranslation();
    let guideline = null;
    let guidelineStyle = null;
    let isDisabled = true;

    if (age && gender && bodyFatPercent) {
        try {
            guideline = new Guidelines.BodyFat.Guideline(age, gender, bodyFatPercent);
            guidelineStyle = theme.guidelines.bodyFat[guideline.key];
            isDisabled = false;
        } catch (err) {
            // enforce disabled card view
        }
    }

    return (
        <Attribute
            {...rest}
            colour={guidelineStyle ? guidelineStyle.flat.hex : null}
            gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
            guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
            guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
            title={t("web_components.cards.attributes.body_fat.title")}
            date={date}
            primaryStatUnit={"%"}
            primaryStatValue={isDisabled ? null : bodyFatPercent}
            icon={theme.icons.types.bodyFat}
        />
    );
};

BodyFat.displayName = "Body Fat Attribute";
BodyFat.propTypes = {
    t: PropTypes.func.isRequired,
    theme: PropTypes.any.isRequired,
    age: PropTypes.number,
    bodyFatPercent: PropTypes.number,
    gender: PropTypes.string,
    date: PropTypes.string
};

export default withTheme(BodyFat);
