import React from "react";
import { withTheme } from "styled-components";
import { Text } from "rebass";
import PropTypes from "prop-types";

const Paragraph = ({ children, renderAs: as, size, theme: { text = {} }, ...rest }) => (
    <Text fontSize={size || text.paragraph.sizeRem} {...rest} as={as}>
        {children}
    </Text>
);

Paragraph.propTypes = {
    renderAs: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    theme: PropTypes.object,
    children: PropTypes.node
};

Paragraph.defaultProps = {
    renderAs: "p"
};

export default withTheme(Paragraph);
