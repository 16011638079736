// eslint-disable-next-line node/no-extraneous-require
const owasp = require("owasp-password-strength-test");

export default class Password {
    constructor() {
        this.owasp = owasp.config({
            allowPassphrases: true,
            maxLength: 128,
            minLength: 8,
            minPhraseLength: 20,
            minOptionalTestsToPass: 3
        });
    }

    /**
     * Returns an object of error messages
     *
     * @returns {object}
     */
    static get errors() {
        return {
            requirement: "yup_messages.password.requirement",
            oldAndNewAreSame: "yup_messages.password.new",
            newAndConfirmDontMatch: "yup_messages.password.confirmation"
        };
    }

    /**
     * Is the specified password valid?
     *
     * @param password
     * @returns {boolean}
     */
    isPasswordValid(password) {
        const owaspResponse = owasp.test(password);
        const noSpecialCharacters = /[^a-z0-9\s_.#$%&+\-=<>@!{}",:()/~]/i.test(password) === false;

        return owaspResponse.strong && noSpecialCharacters;
    }

    /**
     * Get owasp test response
     *
     * @param password
     * @returns {object}
     */
    testPassword(password) {
        return owasp.test(password);
    }
}
