import React, { useEffect } from "react";

import animationData from "../../assets/lottie/hra_loader.json";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import Lottie from "react-lottie";
import { Heading, Paragraph } from "@sisuwellness/web-components";
import { Flex } from "rebass";
import { LOADING } from "constants/messages";
import { useTranslation } from "react-i18next";

const HraLoadingView = ({ minimumDisplayTime, onMinimumDisplayTimeReached, title, message, height = "100%" }) => {
    const { t } = useTranslation();

    var isMounted = true;
    useEffect(() => {
        if (minimumDisplayTime) {
            setTimeout(() => isMounted && onMinimumDisplayTimeReached(), minimumDisplayTime);
        }

        return () => (isMounted = false);
    });

    const spinnerOptions = {
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const loadingMessage = message || t(LOADING.WAITING);
    const loadingTitle = title || t(LOADING.MESSAGE);

    return (
        <Flex width="100%" height={height} alignItems="center" justifyContent="center" flexDirection="column">
            <Lottie options={spinnerOptions} height={250} width={250} isStopped={false} isPaused={false} />
            <Heading renderAs="h2" textAlign="center">
                {loadingTitle}
            </Heading>
            <Paragraph textAlign="center">{loadingMessage}</Paragraph>
        </Flex>
    );
};

HraLoadingView.displayName = "HraLoadingView";
HraLoadingView.propTypes = {
    theme: PropTypes.any.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
    height: PropTypes.string,
    minimumDisplayTime: PropTypes.number,
    onMinimumDisplayTimeReached: PropTypes.func
};

export default withTheme(HraLoadingView);
