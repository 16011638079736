import { add, compose, multiply, prop, when, __ } from "ramda";
import { Answers } from "../../../UserJourney/UserJourneyMap";

const PAL = Answers.PhysicalActivityLevel;

export const PhysicalActivityLevelFactorTable = {
    [PAL.sedentary]: 1.4,
    [PAL.mostlySedentary]: 1.6,
    [PAL.moderatelyActive]: 1.8,
    [PAL.heavilyActive]: 2.0,
    [PAL.significantlyActive]: 2.2
};

export const LactatingCorrectionKj = 2000;

// TDEE [kJ/day]
export default (bmrKj, palAnswer, isLactating) =>
    compose(
        when(() => isLactating, add(LactatingCorrectionKj)),
        multiply(bmrKj),
        prop(__, PhysicalActivityLevelFactorTable)
    )(palAnswer);
