import React from "react";
import PropTypes from "prop-types";

// ------------------------------------------------------------------------------------------------

import { PointSymbol } from "@sisuwellness/web-components/components/Chart";
import { theme } from "@sisuwellness/web-components";
import { withDimensions } from "components/HOC";

import { isMobileView } from "../metricSpecsheet";

// ------------------------------------------------------------------------------------------------

const CustomPointSymbol = ({ color, currentPoint, datum, dimension: [width], ...rest }) => {
    const highlight = datum?.analyticsHealthCheckId === currentPoint?.analyticsHealthCheckId;

    return (
        <PointSymbol
            {...rest}
            color={color}
            highlight={highlight}
            borderColor={highlight ? theme.colours.flat.lightBlue.hex : color}
            borderWidth={highlight ? (isMobileView(width) ? 1 : 2) : 0}
        />
    );
};

CustomPointSymbol.propTypes = {
    color: PropTypes.string,
    datum: PropTypes.object,
    currentPoint: PropTypes.object,
    dimension: PropTypes.array
};

export default withDimensions(CustomPointSymbol);
