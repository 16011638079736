import React, { useState } from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import { useHistory } from "react-router";
import { Form, Formik } from "formik";

// --------------------------------------------------------------------------

import routes from "constants/routes";
import PortalModal from "components/PortalModal";
import { PrimaryButton, SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import { Margin } from "components/styled-components";
import withDimensions from "components/HOC/withDimensions";

import { OptionHeadingField } from "./styled";
import Select from "components/Select";
import { getOptObject } from "utilities/commonUtils";
import { toPairs } from "ramda";
import { SETTINGS } from "constants/";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------------

const GeneralCardForm = ({ onSubmit, unitMeasurement, energyUnit, dimension }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [width] = dimension;
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <Formik initialValues={{ unitMeasurement, energyUnit }} validateOnChange={true} onSubmit={onSubmit}>
            {({ values, handleBlur, handleSubmit, setFieldValue, isSubmitting }) => {
                const isFormValuesChanged =
                    values.unitMeasurement !== unitMeasurement || values.energyUnit !== energyUnit;

                const onBackClick = () =>
                    isFormValuesChanged ? setIsModalOpen(true) : history.replace(routes.myAccount.preferences);

                return (
                    <>
                        <Form id="general-form" onSubmit={handleSubmit} style={{ gridColumn: "span 2" }}>
                            <OptionHeadingField mt="8px" mb="24px">
                                <div className="heading-main">{t("words.measurement")}</div>
                                <div className="heading-sub">
                                    {t("members_portal_web.views.preferences.general_card.form.preferred_unit_system")}
                                </div>
                            </OptionHeadingField>
                            <Text fontSize="13px" width={["100%", "160px"]} color="blackTints700">
                                <Text fontSize={["", "15px"]} fontWeight="intermediate" mb="8px">
                                    {t("members_portal_web.views.preferences.general_card.form.unit_of_measurement")}
                                </Text>
                                <Select
                                    name="unitMeasurement"
                                    data-testid="select-unitMeasurement"
                                    options={toPairs(SETTINGS.UNIT_MEASUREMENT).map(([key, val]) =>
                                        getOptObject(key, t(val))
                                    )}
                                    value={getOptObject(
                                        values.unitMeasurement,
                                        t(SETTINGS.UNIT_MEASUREMENT[values.unitMeasurement])
                                    )}
                                    onBlur={() => handleBlur({ target: { name: "unitMeasurement" } })}
                                    onChange={({ value }) => setFieldValue("unitMeasurement", value)}
                                />
                            </Text>

                            <OptionHeadingField mt="40px" mb="24px">
                                <div className="heading-main">{t("words.energy_system")}</div>
                                <div className="heading-sub">
                                    {t(
                                        "members_portal_web.views.preferences.general_card.form.preferred_energy_system"
                                    )}
                                </div>
                            </OptionHeadingField>
                            <Text fontSize="13px" width={["100%", "160px"]} color="blackTints700">
                                <Text fontSize={["", "15px"]} fontWeight="intermediate" mb="8px">
                                    {t("members_portal_web.views.preferences.general_card.form.unit_of_measurement")}
                                </Text>
                                <Select
                                    name="energyUnit"
                                    data-testid="select-energyUnit"
                                    options={toPairs(SETTINGS.ENERGY_UNIT).map(([key, val]) =>
                                        getOptObject(key, t(val))
                                    )}
                                    value={getOptObject(values.energyUnit, t(SETTINGS.ENERGY_UNIT[values.energyUnit]))}
                                    onBlur={() => handleBlur({ target: { name: "energyUnit" } })}
                                    onChange={({ value }) => setFieldValue("energyUnit", value)}
                                />
                            </Text>

                            <Margin mTop={["60px"]}>
                                <Flex justifyContent="space-between" pr={["0px", "20px"]} pl={["0px", "20px"]}>
                                    <SecondaryButton
                                        width={width < 800 ? "138px" : undefined}
                                        type="button"
                                        onClick={onBackClick}
                                    >
                                        {t("words.back")}
                                    </SecondaryButton>
                                    <PrimaryButton
                                        width={width < 800 ? "138px" : ""}
                                        type="submit"
                                        disabled={!isFormValuesChanged || isSubmitting}
                                    >
                                        {t("words.save")}
                                    </PrimaryButton>
                                </Flex>
                            </Margin>
                        </Form>
                        <PortalModal closeModal={() => setIsModalOpen(false)} modalIsOpen={isModalOpen} />
                    </>
                );
            }}
        </Formik>
    );
};

GeneralCardForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    energyUnit: PropTypes.string.isRequired,
    unitMeasurement: PropTypes.string.isRequired,
    dimension: PropTypes.arrayOf(PropTypes.number).isRequired
};

export default withDimensions(GeneralCardForm);
