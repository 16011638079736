export default {
    root: "/",
    login: "/login",
    register: "/register",
    registerToken: "/register/:token",
    setup: "/setup",
    setupToken: "/setup/:token",
    improve: "/improve",
    improveLaunch: "/improve/launch",

    // Temporarily disable the HRA until it can be translated
    healthRiskAssessment: "/health-risk-assessment",
    healthRiskOnboarding: "/health-risk-onboarding",

    healthHub: "/health-hub",
    healthChecks: "/health-checks",
    healthChecksDetail: "/health-checks/:healthCheckId",
    userJourneys: "/journeys",
    userJourneyDetail: "/journeys/:userJourneyId",
    pulse: "/pulse/:userJourneyId",
    findHealthStations: "/find-health-stations",
    recommendations: "/recommendations",
    hraResult: "/health-hub/hra-result",

    // -------- Premium routes ----------

    heartAge: "/health-hub/heart-age",
    dailyEnergy: "/health-hub/daily-energy",
    bodyComposition: "/health-hub/body-composition",
    muscleMass: "/health-hub/muscle-mass",

    // -----------------------------------

    rewards: "/rewards",
    myAccount: {
        home: "/my-account",
        address: "/my-account/personal-details/address",
        profile: "/my-account/personal-details/profile",
        preferences: "/my-account/preferences",
        personalDetails: "/my-account/personal-details",
        security: "/my-account/security",
        payments: "/my-account/payments",
        preferencesGeneral: "/my-account/preferences/general",
        accountSettings: "/my-account/security/account-settings",
        billingAndSubscription: "/my-account/billing-and-subscription"
    },
    trend: {
        bloodPressure: "/trends/blood-pressure",
        bmi: "/trends/bmi",
        bodyFat: "/trends/body-fat",
        heartRate: "/trends/heart-rate",
        diabetes: "/trends/diabetes",
        qrisk: "/trends/qrisk",
        activity: "/trends/activity",
        weight: "/trends/weight",
        height: "/trends/height",
        alcohol: "/trends/alcohol",
        smoking: "/trends/smoking",
        perceivedStress: "/trends/perceived-stress"
    },
    myAccountDetail: "/my-account/:section",
    trendDetail: "/trends/:attribute",
    termOfUse: "/terms-of-use/:locale?",
    privacyPolicy: "/privacy-policy/:locale?",
    surveyNeeds: "/surveys/needs",
    surveyNeedsResult: "/surveys/needs/result",
    surveyNeedsResultDetail: "/surveys/needs/result/:uuid",
    notFound: "/:notFound",
    signup: "/sign-up"
};
