const LOGIN_SUCCESSFUL = "login_successful";
const SIGNUP_SUCCESSFUL = "signup_successful";

// ------------------------------------------------------------------------

/**
 * Generate a GTM/GA Login Success Event
 *
 * @param {String} visitorType
 */
export const trackLoginSucessful = visitorType => {
    window.dataLayer.push({
        event: LOGIN_SUCCESSFUL,
        visitorType
    });
};

/**
 * Generate a GTM/GA Login Success Event
 *
 * @param {String} visitorType
 */
export const trackSignUpSucessful = visitorType => {
    window.dataLayer.push({
        event: SIGNUP_SUCCESSFUL,
        visitorType
    });
};

// ------------------------------------------------------------------------

export default () => ({
    trackLoginSucessful,
    trackSignUpSucessful
});
