import React from "react";
import { Box, Image, Text } from "rebass";

import { PORTAL_IMAGE } from "constants/imagePath";
import styled from "styled-components";
import FlexWithGap from "components/Flex";
import { Link } from "react-router-dom";
import { space, layout, typography } from "styled-system";

const StyledHeader = styled(FlexWithGap)`
    width: 100%;
    flex-grow: 1;
    white-space: pre-wrap;
    box-sizing: content-box !important;
    border-bottom: 1px solid ${({ theme }) => theme.colours.hpPurple100.hex};
`;

const StyledLink = styled(Link)`
    flex-grow: 1;
    display: flex;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    align-items: center;
    white-space: pre-wrap;
    justify-content: center;
    flex-direction: column;
    text-decoration: underline;
    box-sizing: content-box !important;
    color: ${({ theme }) => theme.colours.hpBlue.hex};

    border-top: ${({ theme, index }) => (index === 0 ? "0px" : `1px solid ${theme.colours.hpPurple100.hex}`)};

    ${layout};
    ${space};
    ${typography};
`;

const StyledCell = styled(StyledLink)`
    cursor: auto;
    text-decoration: none;
`;

export const routeToSpecificPHC = ({ original: { analyticsHealthCheckId: id } }) => `/health-checks/${id}`;

// eslint-disable-next-line react/prop-types
export const Cell = ({ value, unit, id = "", row, ...rest }) => (
    <StyledCell id={id} as="div" height="40px" p="10px" to="#" index={row?.index} {...rest}>
        <Text fontSize="15px" color="hpEbonyBlack">
            {value || "—"}
        </Text>
        {!!(value && unit) && (
            <Text fontSize="12px" color="hpAmethystSmoke">
                {unit}
            </Text>
        )}
    </StyledCell>
);

// eslint-disable-next-line react/prop-types
export const LinkCell = ({ text, href, id = "", row, ...rest }) => (
    <StyledLink height="40px" p="10px 16px" id={id} to={href} index={row?.index} {...rest}>
        {text}
    </StyledLink>
);

// eslint-disable-next-line react/prop-types
export const Header = ({ label, iconType }) => {
    return (
        <StyledHeader
            p="10px"
            fd="column"
            ai="center"
            bg="hpCardBackground"
            data-testid={`health-check-header-${iconType}`}
        >
            <Box width="30px" height="36px" mb="8px">
                {iconType && <Image bg="white" alt={iconType} src={`${PORTAL_IMAGE}/healthChecks/${iconType}.svg`} />}
            </Box>

            <Text width="72px" fontSize="12px" fontWeight="intermediate" color="hpPurpleTints600">
                {label?.toUpperCase?.()}
            </Text>
        </StyledHeader>
    );
};
