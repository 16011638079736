import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import Guidelines from "@sisuwellness/utilities/Guidelines";
import { withTranslation } from "react-i18next";

class PSS4 extends Component {
    static displayName = "Activity";
    static propTypes = {
        t: PropTypes.func,
        theme: PropTypes.any.isRequired,
        score: PropTypes.string,
        date: PropTypes.string
    };

    render() {
        const { t, score, theme, date, ...rest } = this.props;
        let guideline = null;
        let guidelineStyle = null;
        let isDisabled = true;
        if (score !== null) {
            try {
                guideline = Guidelines.PSS4.SisuGuideline(score);
                guidelineStyle = theme.guidelines.PSS4[guideline.key];
                isDisabled = false;
            } catch (err) {
                // do nothing
            }
        }

        return (
            <Attribute
                {...rest}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guideline ? t(guideline.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={t("web_components.cards.attributes.perceived_stress.title")}
                date={date}
                primaryStatValue={isDisabled ? null : score}
                icon={theme.icons.types.mentalHealth}
                t={t}
            />
        );
    }
}

export default withTranslation()(withTheme(PSS4));
