const colourSet = {
    black: { hex: "#37474f", rgb: [55, 71, 79] },
    primaryBlack: { hex: "#000000" },
    blue: { hex: "#0081D1", rgb: [0, 129, 209] },
    darkBlue: { hex: "#084C7C", rgb: [8, 76, 124] },
    blackTint: { hex: "#4d4d4d", rgb: "rgb(77, 77, 77)" },
    darkGray: { hex: "#445967", rgb: [68, 89, 103] },
    darkGreen: { hex: "#35B34A", rgb: [62, 189, 178] },
    darkRose: { hex: "#EC2A6A", rgb: [236, 42, 106] },
    darkTeal: { hex: "#009C97", rgb: [0, 156, 151] },
    gray: { hex: "#CCCCCC", rgb: [204, 204, 204] },
    green: { hex: "#00BF01", rgb: [0, 191, 1] },
    lightBlue: { hex: "#49AEE2", rgb: [73, 174, 226] },
    lightGray: { hex: "#F2F2F2", rgb: [242, 242, 242] },
    dustyBlue: { hex: "#5789a3" },
    lightGreen: { hex: "#A6DE25", rgb: [166, 222, 37] },
    lightTeal: { hex: "#9ddfd9", rgb: [157, 223, 217] },
    lightRose: { hex: "#ffefef", rgb: [255, 239, 239] },
    orange: { hex: "#F7931E", rgb: [247, 147, 30] },
    rose: { hex: "#FF6768", rgb: [255, 103, 104] },
    teal: { hex: "#3EBDB2", rgb: [88, 205, 181] },
    white: { hex: "#FFFFFF", rgb: [255, 255, 255] },
    disabledLight: { hex: "#e8e8e8", rgb: [232, 232, 232] },
    disabledDark: { hex: "#888888", rgb: [136, 136, 136] },
    placeholder: { hex: "#a9a9a9", rgb: [169, 169, 169] },
    primaryRed: { hex: "#ff647c" },
    primaryOrange: { hex: "#ffa26b" },
    lightBlack: { hex: "#1a1a1a" },
    mediumGreen: { hex: "#00c48c" }
};

const colourSetGradient = {
    darkGreen: { from: "#009245", to: colourSet.darkGreen.hex },
    green: { from: "#00BF01", to: "#68D500" },
    lightGreen: { from: "#A6DE25", to: "#ABED48" },
    orange: { from: "#FCB449", to: colourSet.orange.hex },
    rose: { from: colourSet.rose.hex, to: "#ED4663" },
    darkRose: { from: "#EC2A6A", to: "#D4144A" },
    teal: {
        from: "#58CDB5",
        fromRgba: [88, 205, 181, 0.12],
        to: "#FFFFFF",
        toRgba: [255, 255, 255, 1.0]
    }
};

const portalColors = {
    hpWhite: { hex: "#fff" },
    hpLightBlue: { hex: "#cfddff" },
    hpPrimaryRed: colourSet.primaryRed,
    hpRed: { hex: "#e32617", rgb: "rgb(227,38,23)" },
    hpGreen: { hex: "#1abc9b", rgb: "rgb(26,188,155)" },
    hpBlue: { hex: "#2469ff", rgb: "rgb(36, 105, 255)" },
    hpRed100: { hex: "#fce9e8", rgb: "rgb(252,233,232)" },
    hpYellow: { hex: "#f6ac3d", rgb: "rgb(246, 172, 61)" },
    hpFireRed: { hex: "#d2222d", rgb: "rgb(210, 34, 45)" },
    hpOrange: { hex: "#ffa26b", rgb: "rgb(255, 162, 107)" },
    hpOrangeBackground: { hex: "#fef5ed", rgb: "rgb(254, 245, 237)" },
    hpDarkGreen: { hex: "#0e6352", rgb: "rgb(14, 99, 82)" },
    hpDarkGreenBackground: { hex: "#d0d9d7", rgb: "rgb(208, 217, 215)" },
    hpGreen100: { hex: "#f4fefc", rgb: "rgb(244,254,252)" },
    hpDarkPink: { hex: "#ce214a", rgb: "rgb(206, 33, 74)" },
    hpPinkBackground: { hex: "#fae9ed", rgb: "rgb(98,91,93)" },
    hpLightGray: { hex: "#e5e5e5", rgb: "rgb(229,229,229)" },
    hpContessa: { hex: "#d46f6a", rgb: "rgb(212, 111, 106)" },
    hpGrey200: { hex: "#f7f9f9", rgb: "rgb(247, 249, 249)" },
    hpPurple100: { hex: "#ebe9f0", rgb: "rgb(235, 233, 240)" },
    hpPurple700: { hex: "#736495", rgb: "rgb(115, 100, 149)" },
    hpPurple400: { hex: "#afa6c3", rgb: "rgb(175, 166, 195)" },
    hpPurple200: { hex: "#d7d3e1", rgb: "rgb(215, 211, 225)" },
    hpGrayishBlue: { hex: "#cedbe5", rgb: "rgb(206,219,229)" },
    hpGrayishBlueText: { hex: "#3e4345", rgb: "rgb(62,67,69)" },
    hpDesaturatedBlue: { hex: "#374a59", rgb: "rgb(55,74,89)" },
    hpPrimaryPurple: { hex: "#372168", rgb: "rgb(55, 33, 104)" },
    primaryBlueTints100: { hex: "#eef3f6", rgb: "rgb(238, 243, 246)" },
    primaryBlueTints300: { hex: "#cddce3", rgb: "rgb(205, 220, 227)" }
};

const tints = {
    blackTints700: { hex: "#4d4d4d", rgb: "rgb(77, 77, 77)" },
    blackTintsLight: { hex: "#5d6970", rgb: "rgb(93, 105, 112)" },
    blueTints500: { hex: "#abc4d1", rgb: "rgb(171, 196, 209)" },
    blueTints600: { hex: "#9ab8c8", rgb: "rgb(154, 184, 200)" },
    blueTints700: { hex: "#89acbf", rgb: "rgb(137, 172, 191)" },
    blueTints800: { hex: "#6895ac", rgb: "rgb(104, 149, 172)" },
    blueTintsDusty: { hex: "#5789a3", rgb: "rgb(87, 137, 163)" },
    islandSpice: { hex: "#FFFAEF", rgb: "rgb(250, 250, 239)" },
    horizonBlue: { hex: "#5789A3", rgb: "rgb(87, 137, 163)" },
    solitudeBlue: { hex: "#e4f3ff", rgb: "rgb(228, 243, 255)" },
    topazPurple: { hex: "#7d7987", rgb: "rgb(125, 121, 135)" },
    whisperWhite: { hex: "#f8f7fb", rgb: "rgb(248, 247, 251)" },
    blackTints600: { hex: "#666666", rgb: "rgb(102, 102, 102)" },
    blackTints400: { hex: "#999999", rgb: "rgb(153, 153, 153)" },
    blackTints800: { hex: "#333333", rgb: "rgb(51, 51, 51)" },
    blueTints900: { hex: "#1091ff", rgb: "rgb(16, 145, 255)" },
    blackTints300: { hex: "#b3b3b3", rgb: "rgb(179, 179, 179)" }
};

const gradientCSS = {
    carouselBlue: `linear-gradient(to top, ${tints.blueTints500.hex}, ${tints.blueTints600.hex}, ${tints.blueTints700.hex}, ${tints.blueTints800.hex}, ${tints.blueTintsDusty.hex})`,
    darkGreen: `linear-gradient(${colourSetGradient.darkGreen.from}, ${colourSetGradient.darkGreen.to}) no-repeat`,
    green: `linear-gradient(${colourSetGradient.green.from}, ${colourSetGradient.green.to}) no-repeat`,
    lightGreen: `linear-gradient(${colourSetGradient.lightGreen.from}, ${colourSetGradient.lightGreen.to}) no-repeat`,
    orange: `linear-gradient(${colourSetGradient.orange.from}, ${colourSetGradient.orange.to}) no-repeat`,
    rose: `linear-gradient(${colourSetGradient.rose.from}, ${colourSetGradient.rose.to}) no-repeat`,
    darkRose: `linear-gradient(${colourSetGradient.darkRose.from}, ${colourSetGradient.darkRose.to}) no-repeat`,
    teal: `linear-gradient(rgba(${colourSetGradient.teal.fromRgba.join()}), rgba(${colourSetGradient.teal.toRgba.join()})) no-repeat`
};

const guidelines = {
    bodyMassIndex: {
        low: {
            label: "Guidelines.BodyMassIndex.low",
            flat: colourSet.darkGreen,
            sisuPortal: portalColors.hpBlue,
            gradientCSS: gradientCSS.darkGreen,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        normal: {
            label: "Guidelines.BodyMassIndex.normal",
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        elevated: {
            label: "Guidelines.BodyMassIndex.elevated",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        high: {
            label: "Guidelines.BodyMassIndex.high",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        },
        veryHigh: {
            label: "Guidelines.BodyMassIndex.veryHigh",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    bodyFat: {
        low: {
            label: "Guidelines.BodyFat.low",
            flat: colourSet.green,
            sisuPortal: portalColors.hpBlue,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        normal: {
            label: "Guidelines.BodyFat.normal",
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.lightGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        high: {
            label: "Guidelines.BodyFat.high",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        veryHigh: {
            label: "Guidelines.BodyFat.veryHigh",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        }
    },
    NationalHeartFoundationAustralia: {
        optimal: {
            label: "Guidelines.NationalHeartFoundationAustralia.optimal",
            flat: colourSet.darkGreen,
            sisuPortal: portalColors.hpDarkGreen,
            gradientCSS: gradientCSS.darkGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        normal: {
            label: "Guidelines.NationalHeartFoundationAustralia.normal",
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        highNormal: {
            label: "Guidelines.NationalHeartFoundationAustralia.highNormal",
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpYellow,
            gradientCSS: gradientCSS.lightGreen,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        mildHypertension: {
            label: "Guidelines.NationalHeartFoundationAustralia.high",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        },
        moderateHypertension: {
            label: "Guidelines.NationalHeartFoundationAustralia.veryHigh",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        },
        severeHypertension: {
            label: "Guidelines.NationalHeartFoundationAustralia.severe",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    SiSUHealthRiskAssessment: {
        excellent: {
            label: "Excellent",
            flat: colourSet.green,
            sisuPortal: portalColors.hpDarkGreen,
            gradientCSS: gradientCSS.green
        },
        good: {
            label: "Good",
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.lightGreen
        },
        moderate: {
            label: "Moderate",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange
        },
        atRisk: {
            label: "At Risk",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose
        },
        increasedRisk: {
            label: "Increased Risk",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose
        }
    },
    SiSUHRAIndex: {
        excellent: {
            label: "Excellent",
            flat: colourSet.green,
            sisuPortal: portalColors.hpDarkGreen,
            gradientCSS: gradientCSS.green
        },
        good: {
            label: "Good",
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.lightGreen
        },
        moderate: {
            label: "Moderate",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange
        },
        atRisk: {
            label: "At Risk",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose
        },
        increasedRisk: {
            label: "Increased Risk",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose
        }
    },
    SiSUHeartRate: {
        low: {
            label: "Low",
            flat: colourSet.green,
            sisuPortal: portalColors.hpBlue,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        normal: {
            label: "Normal",
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.lightGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        elevated: {
            label: "Elevated",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        high: {
            label: "High",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        },
        veryHigh: {
            label: "Very High",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    AustralianDiabetesRisk: {
        low: {
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.lightGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        intermediate: {
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        high: {
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        }
    },
    Qrisk: {
        low: {
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.darkGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        elevated: {
            flat: colourSet.orange,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        }
    },
    SiSUNonFastingGlucose: {
        low: {
            label: "Low",
            flat: colourSet.green,
            sisuPortal: portalColors.hpBlue,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        normal: {
            label: "Normal",
            flat: colourSet.lightGreen,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.lightGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        elevated: {
            label: "Elevated",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        high: {
            label: "High",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    SiSUTotalCholesterol: {
        optimal: {
            label: "Optimal",
            flat: colourSet.darkGreen,
            sisuPortal: portalColors.hpDarkGreen,
            gradientCSS: gradientCSS.darkGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        normal: {
            label: "Normal",
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        elevated: {
            label: "Elevated",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        },
        high: {
            label: "High",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    SiSUHDLCholesterol: {
        low: {
            label: "Low",
            flat: colourSet.lightGray,
            sisuPortal: portalColors.hpBlue,
            gradientCSS: gradientCSS.lightGreen,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        },
        normal: {
            label: "Normal",
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        }
    },
    Alcohol: {
        noRisk: {
            flat: colourSet.darkGreen,
            sisuPortal: portalColors.hpDarkGreen,
            gradientCSS: gradientCSS.darkGreen,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        low: {
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        elevated: {
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        high: {
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpPrimaryRed,
            bgColor: portalColors.hpRed100
        },
        veryHigh: {
            flat: colourSet.darkRose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    Activity: {
        active: {
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        moderatelyActive: {
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        moderatelyInactive: {
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        inactive: {
            flat: colourSet.darkRose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    PSS4: {
        low: {
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        moderate: {
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange,
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        high: {
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    weight: {
        low: {
            label: "Guidelines.Weight.low",
            flat: colourSet.darkGreen,
            sisuPortal: portalColors.hpBlue,
            gradientCSS: gradientCSS.darkGreen
        },
        normal: {
            label: "Guidelines.Weight.normal",
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green
        },
        elevated: {
            label: "Guidelines.Weight.elevated",
            flat: colourSet.orange,
            sisuPortal: portalColors.hpOrange,
            gradientCSS: gradientCSS.orange
        },
        high: {
            label: "Guidelines.Weight.high",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose
        },
        veryHigh: {
            label: "Guidelines.Weight.veryHigh",
            flat: colourSet.rose,
            sisuPortal: portalColors.hpDarkPink,
            gradientCSS: gradientCSS.darkRose
        }
    },
    Sleep: {
        severe: {
            sisuPortal: portalColors.hpFireRed
        },
        moderate: {
            sisuPortal: portalColors.hpContessa
        },
        mild: {
            sisuPortal: portalColors.hpOrange
        },
        healthy: {
            sisuPortal: portalColors.hpGreen
        },
        pending: {
            sisuPortal: portalColors.hpPurple100
        }
    },
    Smoking: {
        normal: {
            flat: colourSet.green,
            sisuPortal: portalColors.hpGreen,
            gradientCSS: gradientCSS.green,
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        high: {
            flat: colourSet.rose,
            sisuPortal: portalColors.hpPrimaryRed,
            gradientCSS: gradientCSS.rose,
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    FastingGlucose: {
        low: {
            recommendationColor: portalColors.hpGreen,
            bgColor: portalColors.hpGreen100
        },
        normal: {
            recommendationColor: portalColors.hpDarkGreen,
            bgColor: portalColors.hpDarkGreenBackground
        },
        elevated: {
            recommendationColor: portalColors.hpOrange,
            bgColor: portalColors.hpOrangeBackground
        },
        high: {
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    },
    WaistCircumference: {
        high: {
            recommendationColor: portalColors.hpDarkPink,
            bgColor: portalColors.hpPinkBackground
        }
    }
};

const portalButton = {
    height: "50px",
    width: "255px",
    borderRadius: "64px"
};

const baseTheme = {
    colours: {
        flat: colourSet,
        gradient: colourSetGradient,
        gradientCSS,
        ...tints,
        ...portalColors
    },
    primaryBtn: {
        ...portalButton,
        color: colourSet.white.hex,
        borderColor: "transparent",
        backgroundColor: portalColors.hpPrimaryPurple.hex,
        disabledColor: portalColors.hpPurple400.hex,
        disabledBorderColor: portalColors.hpPurple400.hex,
        disabledBackgroundColor: portalColors.hpPurple200.hex
    },
    secondaryBtn: {
        ...portalButton,
        color: tints.blackTints700.hex,
        borderColor: portalColors.hpPrimaryPurple.hex,
        backgroundColor: colourSet.white.hex
    },
    text: {
        heading1: { size: "60px", weight: "bold", sizeRem: "3.75rem" },
        heading2: { size: "40px", weight: "normal", sizeRem: "2.5rem", weightSemiBold: "600" },
        heading3: { size: "30px", weight: "normal", sizeRem: "1.875rem", weightSemiBold: "600" },
        heading4: { size: "24px", weight: "bold", sizeRem: "1.5rem", weightSemiBold: "600" },
        heading5: {
            size: "20px",
            weight: "normal",
            sizeRem: "1.25rem",
            weightLight: 300,
            weightBold: 600
        },
        heading6: { size: "18px", weight: "normal", sizeRem: "1.2rem", weightLight: 300 },
        paragraph: { size: "16px", weight: "normal", sizeRem: "1rem" },
        feedback: { size: "14px", weight: "bold", sizeRem: "0.9rem" },
        subText: { size: "14px", weight: "normal", sizeRem: "0.9rem" },
        vsmallText: { size: "11px", weight: "normal", sizeRem: "0.7rem" }
    },
    fontFamily: {
        openSans: "Open Sans",
        montserrat: "Montserrat"
    },
    shadows: {
        rest: { x: 0, y: 3, blur: 6, opacity: 0.15, androidElevation: 5 },
        elevated: { x: 0, y: 6, blur: 6, opacity: 0.25, androidElevation: 25 },
        buttonRest: { x: 2, y: 2, blur: 2, opacity: 0.15, androidElevation: 5 },
        float: { x: 0, y: 9, blur: 18, opacity: 0.25, androidElevation: 25 },
        scriptCard: { x: 10, y: 40, blur: 50, spread: 0, rgba: "rgba(229, 233, 246, 0.4)" },
        buttonElevated: {
            x: 2,
            y: 2,
            blur: 2,
            opacity: 0.25,
            androidElevation: 25
        }
    },
    shadowCss: {
        rest: function() {
            const config = baseTheme.shadows.rest;
            return (
                "box-shadow: " +
                config.x +
                "px " +
                config.y +
                "px " +
                config.blur +
                "px rgba(0,0,0, " +
                config.opacity +
                ")"
            );
        },
        float: function() {
            const config = baseTheme.shadows.float;
            return (
                "box-shadow: " +
                config.x +
                "px " +
                config.y +
                "px " +
                config.blur +
                "px rgba(0,0,0, " +
                config.opacity +
                ")"
            );
        },
        elevated: function() {
            const config = baseTheme.shadows.elevated;
            return (
                "box-shadow: " +
                config.x +
                "px " +
                config.y +
                "px " +
                config.blur +
                "px rgba(0,0,0, " +
                config.opacity +
                ")"
            );
        },
        buttonRest: function() {
            const config = baseTheme.shadows.buttonRest;
            return (
                "box-shadow: " +
                config.x +
                "px " +
                config.y +
                "px " +
                config.blur +
                "px rgba(0,0,0, " +
                config.opacity +
                ")"
            );
        },
        buttonElevated: function() {
            const config = baseTheme.shadows.buttonElevated;
            return (
                "box-shadow: " +
                config.x +
                "px " +
                config.y +
                "px " +
                config.blur +
                "px rgba(0,0,0, " +
                config.opacity +
                ")"
            );
        },
        scriptCard: function() {
            const config = baseTheme.shadows.scriptCard;
            return `${config.x}px ${config.y}px ${config.blur}px ${config.spread}px ${config.rgba}`;
        }
    },
    portalShadows: {
        card1: "0 4px 8px 0 rgba(50, 50, 71, 0.06), 0 4px 4px 0 rgba(50, 50, 71, 0.08)",
        card2: "0 8px 16px 0 rgba(50, 50, 71, 0.06), 0 8px 8px 0 rgba(50, 50, 71, 0.08)"
    },
    defaults: {
        borderRadius: "5px",
        headerHeight: "80px"
    },
    membersPortal: {
        backgroundColor: "#e5f8f7",
        mobileHeaderHeight: "50px",
        mobileBackgroundColor: "#ffffff"
    },
    portalColor: {
        hpGreen: portalColors.hpGreen,
        hpDarkPink2: portalColors.hpDarkPink,
        hpPurple400: portalColors.hpPurple400,
        hpPurple200: portalColors.hpPurple200,
        hpPurple700: portalColors.hpPurple700,
        hpAliceBlue: portalColors.primaryBlueTints100,
        hpPrimaryPurple: portalColors.hpPrimaryPurple,
        hpCardBackground: { hex: "#f9fbff", rgb: "rgb(249, 251, 255)" }
    },
    cards: {
        padding: "0",
        thin: { height: "100px" },
        large: { height: "207px" },
        width: 350,
        distanceBetweenCards: "10px"
    },
    carouselCard: {
        width: [1140, 921, 727, 540, 345],
        height: [450, 425, 400, 375, 350],
        margin: [10, 9, 8, 7, 8],
        borderRadius: [24]
    },
    mediaQuerySizes: {
        desktop: 992,
        desktop1024: 1024,
        tablet: 769,
        tablet600: 600,
        mobile: 577,
        mobile400: 375
    },
    // Rebass breakpoints
    breakpoints: ["577px", "769px", "992px"],
    icons: {
        sizes: {
            micro: "1em",
            tiny: "1.5em",
            small: "2em",
            normal: "3em",
            large: "3.75em",
            question: "7em"
        },
        types: {
            // Note! Because the API needs to serve this too, any modification to the following list should be made in api/src/Surveys/Controllers/SurveyQuestionController.js
            activity: "activity",
            arrow: "arrow",
            arrowWithTail: "arrowWithTail",
            birthday: "birthday",
            bloodPressure: "bloodPressure",
            bmi: "bmi",
            bodyFat: "bodyFat",
            calibrating: "calibrating",
            checkMarkFull: "checkMarkFull",
            checkMark: "checkMark",
            cholesterol: "cholesterol",
            close: "close",
            comparison: "comparison",
            connect: "connect",
            diabetesRisk: "diabetesRisk",
            dizziness: "dizziness",
            emotional: "emotional",
            error: "error",
            findStation: "findStation",
            gender: "gender",
            glucose: "glucose",
            handStop: "handStop",
            healthCheck: "healthCheck",
            healthHub: "healthHub",
            heartAge: "heartAge",
            height: "height",
            heartRate: "heartRate",
            library: "library",
            lifestyle: "lifestyle",
            lifestyleScore: "lifestyleScore",
            logout: "logout",
            lostConnection: "lostConnection",
            meals: "meals",
            mentalHealth: "mentalHealth",
            name: "name",
            notFound: "notFound",
            offers: "offers",
            postcode: "postcode",
            pregnancy: "pregnancy",
            programs: "programs",
            recommendation: "recommendation",
            settings: "settings",
            smoking: "smoking",
            social: "social",
            stats: "stats",
            subscription: "subscription",
            todo: "todo",
            unavailable: "unavailable",
            updating: "updating",
            waist: "waist",
            weight: "weight",
            magnifier: "magnifier",
            cart: "cart",
            hdlCholesterol: "hdlCholesterol",
            changePassword: "changePassword",
            viewItem: "viewItem",
            sortUp: "sortUp",
            sortDown: "sortDown",
            printThick: "printThick",
            downArrow: "downArrow",
            upArrow: "upArrow",
            information: "information",
            healthChecks: "healthChecks",
            lifeProgram: "lifeProgram",
            plus: "plus",
            question: "question",
            facebook: "facebook",
            instagram: "instagram",
            linkedin: "linkedin",
            profile: "profile",
            alcohol: "alcohol",
            qrisk: "qrisk"
        }
    },
    buttons: {
        small: { sizeRem: "4em" },
        medium: { sizeRem: "8em" },
        large: { sizeRem: "15em" }
    },
    guidelines,
    textField: {
        size: "medium"
    },
    searchBar: {
        size: "medium"
    },
    table: {
        header: {
            lineHeight: "20px",
            border: `rgba(102, 102, 102, 0.16) 1px solid`
        },
        shadows: {
            body: "0px 0px 3px rgba(0, 0, 0, 0.15)",
            tail: "0px 0px 4px rgba(0, 0, 0, 0.15)",
            side: "1px -2px 2px rgba(0, 0, 0, 0.15)"
        },
        borderRadius: {
            head: "10px 10px 0px 0px",
            tail: "0px 0px 10px 10px"
        },
        cell: {
            border: `rgba(102, 102, 102, 0.16) 1px solid`
        },
        hoverColour: "#EFFAF8",
        viewItemColour: colourSet.blue.hex,
        spacing: {
            column: "12px"
        }
    },
    spaces: [0, 4, 6, 8, 10, 12, 16, 20, 24, 28, 32, 40, 48, 56, 64, 72, 80, 96, 110, 130, 150],
    fontWeight: {
        bold: 800,
        semiBold: 600,
        intermediate: 500,
        regular: 400,
        light: 300
    },
    fontSize: {
        11: { px: "11px", em: `${(1 / 16) * 11}em`, rem: `${(1 / 16) * 11}rem` },
        12: { px: "12px", em: `${(1 / 16) * 12}em`, rem: `${(1 / 16) * 12}rem` },
        13: { px: "13px", em: `${(1 / 16) * 13}em`, rem: `${(1 / 16) * 13}rem` },
        14: { px: "14px", em: `${(1 / 14) * 14}em`, rem: `${(1 / 14) * 14}rem` },
        15: { px: "15px", em: `${(1 / 16) * 15}em`, rem: `${(1 / 16) * 15}rem` },
        16: { px: "16px", em: `${(1 / 16) * 16}em`, rem: `${(1 / 16) * 16}rem` },
        17: { px: "17px", em: `${(1 / 16) * 17}em`, rem: `${(1 / 16) * 17}rem` },
        18: { px: "18px", em: `${(1 / 16) * 18}em`, rem: `${(1 / 16) * 18}rem` },
        20: { px: "20px", em: `${(1 / 16) * 20}em`, rem: `${(1 / 16) * 20}rem` },
        22: { px: "22px", em: `${(1 / 16) * 22}em`, rem: `${(1 / 16) * 22}rem` },
        28: { px: "28px", em: `${(1 / 16) * 28}em`, rem: `${(1 / 16) * 28}rem` },
        34: { px: "34px", em: `${(1 / 16) * 34}em`, rem: `${(1 / 16) * 34}rem` },
        40: { px: "40px", em: `${(1 / 16) * 40}em`, rem: `${(1 / 16) * 40}rem` },
        50: { px: "50px", em: `${(1 / 16) * 50}em`, rem: `${(1 / 16) * 50}rem` }
    },
    elipsis: {
        width: "10px",
        height: "10px",
        activeColor: tints.blueTintsDusty.hex,
        color: tints.blueTints500.hex
    },

    chart: {
        line: {
            grid: {
                stroke: colourSet.lightGray.hex,
                strokeWidth: 2
            },
            area: {
                fill: `#E6F5EC`
            }
        }
    }
};

export default baseTheme;
