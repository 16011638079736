import React, { Component } from "react";
import PropTypes from "prop-types";
import QuestionTitle from "./Title";
import Help from "./Help";
import styled from "styled-components";
import { Errors, SelectField } from "../../Forms";
import { Switch } from "../../Buttons";
import unitConverter from "@sisuwellness/utilities/core/unitConverter";
import theme, { media } from "../../../themes";
import QuestionIcon from "./QuestionIcon";
import { withTranslation } from "react-i18next";

const StyledContainer = styled.div``;

const StyledSwitchContainer = styled.div`
    text-align: right;
    margin-top: ${props => (props.hasErrors ? "1em" : 0)};

    ${media.mobile`
        text-align: center;
    `}
`;

const StyledSwitchPositionContainer = styled.div`
    display: inline-block;
    width: 19em;

    ${media.mobile`
        width: 100%;
    `}
`;

const StyledQuestionTitle = styled(QuestionTitle)`
    margin-top: 20px;
`;

class HeightQuestion extends Component {
    static displayName = "Height Question";
    static propTypes = {
        id: PropTypes.string.isRequired,
        model: PropTypes.object.isRequired,
        onValueChange: PropTypes.func.isRequired,
        value: PropTypes.any,
        autoFocus: PropTypes.bool,
        placeholder: PropTypes.string,
        onInputFocus: PropTypes.func,
        errors: PropTypes.any,
        t: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            units: {
                metric: "Metric",
                imperial: "Imperial"
            },
            isUnitMetric: true
        };

        this.selectRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleUnitToggle = this.handleUnitToggle.bind(this);
    }

    handleInputChange(selectedValue) {
        if (selectedValue !== null) {
            this.props.onValueChange(selectedValue);
        }
    }

    handleUnitToggle(selectedUnit) {
        this.setState({
            isUnitMetric: selectedUnit === this.state.units.metric
        });

        this.selectRef.current.select.clearValue();
    }

    render() {
        let getIcon = this.props.model.getQuestionIcon();
        let helpText = null;
        if (this.props.model.getHelpText()) {
            helpText = <Help>{this.props.t(this.props.model.getHelpText())}</Help>;
        }

        let options = [];
        if (this.state.isUnitMetric) {
            for (let i = 90; i <= 240; i++) {
                options.push({ value: i, label: i + " " + this.props.t("units.cm") });
            }
        } else {
            for (let i = 36; i <= 95; i++) {
                // 91.4 - 241.3cm
                // value needs to be metric
                options.push({
                    value: unitConverter.inchToCm(i).toFixed(1),
                    label: unitConverter.inchesToFtInchString(i)
                });
            }
        }

        const hasErrors = this.props.errors && this.props.errors.length;
        let validationErrors = null;
        if (hasErrors) {
            validationErrors = <Errors rounded={true}>{this.props.t(this.props.errors)}</Errors>;
        }

        return (
            <StyledContainer data-testid={"survey-question-height"}>
                {validationErrors}
                <StyledSwitchContainer hasErrors={hasErrors}>
                    <StyledSwitchPositionContainer>
                        <Switch
                            first={this.state.units.metric}
                            second={this.state.units.imperial}
                            onToggle={this.handleUnitToggle}
                        />
                    </StyledSwitchPositionContainer>
                </StyledSwitchContainer>
                <QuestionIcon type={theme.icons.types[getIcon]} />
                <StyledQuestionTitle htmlFor={this.props.id}>
                    {this.props.t(this.props.model.getDisplayText())}
                </StyledQuestionTitle>
                {helpText}
                <SelectField ref={this.selectRef} options={options} onValueChange={this.handleInputChange} />
            </StyledContainer>
        );
    }
}

export default withTranslation()(HeightQuestion);
