import React from "react";
import { Route, Switch } from "react-router";
import { Flex } from "rebass";

// -------------------------------------------------------------------------

import Address from "components/Address";
import Profile from "components/Profile";
import routes from "constants/routes";
import { Margin } from "components/styled-components";

// -------------------------------------------------------------------------

const PersonalDetails = () => {
    const renderPersonalDetailsHome = () => {
        return (
            <Flex flexDirection="column">
                <Margin mBottom={["32px"]}>
                    <Profile />
                </Margin>
                <Address />
            </Flex>
        );
    };

    return (
        <Margin mBottom={["32px"]}>
            <Switch>
                <Route exact path={routes.myAccount.address} render={() => <Address forms />} />
                <Route exact path={routes.myAccount.profile} render={() => <Profile forms />} />
                <Route exact path={routes.myAccount.personalDetails} render={() => renderPersonalDetailsHome()} />
            </Switch>
        </Margin>
    );
};

export default PersonalDetails;
