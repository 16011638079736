import React, { useCallback } from "react";

import styled from "styled-components";
import { grid } from "styled-system";
import { Text } from "rebass";

// --------------------------------------------------------------------

import BillingAndSubscriptionCard from "./billingAndSubscriptionCard";
import { useConversionModal } from "components/ContextProviders";
import { default as Flex } from "components/Flex";
import { usePremiumFeature } from "components/PremiumFeatures";
import { useChargebee } from "utilities/chargebee";

import { StatusCard, CONTENT } from "./statusCard";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------

const StyledText = styled(Text)`
    cursor: ${({ showCursor }) => (showCursor ? "pointer" : "auto")};
    ${grid};
`;

const BillingAndSubscription = () => {
    const { t } = useTranslation();
    const { isPremium } = usePremiumFeature();
    const { triggerModal } = useConversionModal();

    const Chargebee = useChargebee();

    const handleOnClick = useCallback(() => (isPremium ? Chargebee.openChargebeePortal() : triggerModal(true)), [
        isPremium
    ]);

    const { name, cta } = isPremium ? CONTENT.PREMIUM : CONTENT.CORE;

    return (
        <BillingAndSubscriptionCard heading={t("words.subscriptions")} gap={["16px 0px", "24px 0px"]}>
            <StyledText showCursor fontSize="13px" textAlign="right" color="hpPrimaryPurple" onClick={handleOnClick}>
                {t(cta)}
            </StyledText>
            <Flex fd="column" gap="10px">
                <Flex fd="row" ai="center" gap="8px">
                    <StyledText fontSize="20px" color="primaryBlack">
                        {t("members_portal_web.components.billing_subscriptions.member_status")}:
                    </StyledText>
                    <StyledText fontSize="20px" color="blackTint700">
                        {t(name)}
                    </StyledText>
                </Flex>
                <StyledText fontSize="17px" color="blackTint700">
                    {!isPremium && t("members_portal_web.components.billing_subscriptions.want_more")}
                </StyledText>
            </Flex>
            <StatusCard />
        </BillingAndSubscriptionCard>
    );
};

export default BillingAndSubscription;
