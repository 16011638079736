class FlowState {
    static userCanRegister(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_CAN_REGISTER;
    }

    static userIsUnknown(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_UNKNOWN;
    }

    static userExists(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_EXISTS;
    }

    static userIsNotAuthenticated(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_NOT_AUTHENTICATED;
    }

    static userIsAuthenticated(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_AUTHENTICATED;
    }

    static userRequiresCompletion(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_REQUIRES_COMPLETION;
    }

    static userProfileIncomplete(flowState) {
        return flowState === FlowState.FLOW_STATE_USER_PROFILE_INCOMPLETE;
    }

    static get FLOW_STATE_USER_UNKNOWN() {
        return "FLOW_STATE_USER_UNKNOWN";
    }

    static get FLOW_STATE_USER_EXISTS() {
        return "FLOW_STATE_USER_EXISTS";
    }

    static get FLOW_STATE_USER_CAN_REGISTER() {
        return "FLOW_STATE_USER_CAN_REGISTER";
    }

    static get FLOW_STATE_USER_NOT_AUTHENTICATED() {
        return "FLOW_STATE_USER_NOT_AUTHENTICATED";
    }

    static get FLOW_STATE_USER_AUTHENTICATED() {
        return "FLOW_STATE_USER_AUTHENTICATED";
    }

    static get FLOW_STATE_USER_REQUIRES_COMPLETION() {
        return "FLOW_STATE_USER_REQUIRES_COMPLETION";
    }

    static get FLOW_STATE_USER_PROFILE_INCOMPLETE() {
        return "FLOW_STATE_USER_PROFILE_INCOMPLETE";
    }

    static get USER_STATE_COMPLETE() {
        return "COMPLETE";
    }

    static get USER_STATE_INCOMPLETE() {
        return "INCOMPLETE";
    }
}

export default FlowState;
