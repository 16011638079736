// app-configuration reducer
export const SET_APP_READY = "SET_APP_READY";

// auth reducer
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_AUTHENTICATION_STATUS = "SET_AUTHENTICATION_STATUS";
export const SET_CITIZEN = "SET_CITIZEN";

// setting reducer
export const UPDATE_USER_SETTING = "UPDATE_USER_SETTING";
export const SET_USER_SETTING = "SET_USER_SETTING";
