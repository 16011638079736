import React, { useContext } from "react";

import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import { Text, Image, Box, Flex } from "rebass";
import { SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import FlexWithGap from "components/Flex";
import { useConversionModal } from "components/ContextProviders";
import { usePremiumFeature, withPremium } from "components/PremiumFeatures";
import { USES_LEARN_WORLD } from "./ImproveCardData";
import { PORTAL_IMAGE } from "constants/imagePath";
import { NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import { replace, toLower } from "ramda";
import useRudderStack from "hooks/useRudderStack";
import { useTranslation } from "react-i18next";

const StyledButton = styled(SecondaryButton)`
    width: 282px;
    height: 40px;
    display: block;
    margin-top: auto;
    font-weight: 500;
    background-color: transparent;
    color: ${props => props.theme.colours.hpPrimaryPurple.hex};
`;

const StyledCard = styled(Card)`
    padding: 0;
    overflow: hidden;
    border-radius: 10px 10px 0px 0px;
    position: relative;
`;

const BlurredCardImage = styled(Image)`
    filter: blur(6px);
    -webkit-filter: blur(6px);
    background-color: ${props => props.theme.colours.hpPurplishPink.hex};
    opacity: 0.7;
`;

const CardImageContainer = styled(Box)`
    position: relative;
`;

const LockOverlayBox = styled(Flex)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

const PremTag = styled(NormalLabel)`
    position: absolute;
    right: 16px;
    top: 16px;
    opacity: 0.7;
`;

const ImproveCard = ({ title, text, image, link, handleClick, isPremOnlyCard, isCoreOnlyCard, type }) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);
    const { isPremium, isInterestedInPremium } = usePremiumFeature();
    const { triggerModal } = useConversionModal();
    const rudderStack = useRudderStack();

    const getBackgroundColor = type => {
        switch (type) {
            case "ACtIVITY_PLANNER": {
                return theme.colours.hpYellow100.hex;
            }
            case "MEAL_PLANNER": {
                return theme.colours.hpLightGreen.hex;
            }
            case "PLAN_HEALTHY_HEART": {
                return theme.colours.hpDarkBlue.hex;
            }
            case "PLAN_BETTER_SLEEP": {
                return theme.colours.solitudeBlue.hex;
            }
            case "PLAN_BUILDING_STRESS_RESILIENCE": {
                return theme.colours.hpRed100.hex;
            }
            case "PLAN_SUSTAINABLE_WEIGHT_LOSS": {
                return theme.colours.hpOrangeBackground.hex;
            }
            case "PLAN_SEVEN_MINUTE_WORKOUT": {
                return "#EEF7E9";
            }
            default: {
                return "transparent";
            }
        }
    };

    const backgroundProps = {
        width: "100%",
        alt: title,
        src: image,
        maxHeight: "112px",
        "data-testid": "image",
        backgroundColor: getBackgroundColor(type)
    };

    const showLockedFeature = isInterestedInPremium && !isPremium && !!USES_LEARN_WORLD[link];
    const isPremiumUserFeature = isPremium && !!USES_LEARN_WORLD[link];

    if (!(isPremium || isInterestedInPremium) && isPremOnlyCard) return null;
    if ((isPremium || isInterestedInPremium) && isCoreOnlyCard) return null;

    const onCardButtonClick = () => {
        if (!showLockedFeature) {
            rudderStack.trackEvent("Program Interest", { programId: title });
            handleClick(link);
        } else {
            triggerModal(true);
        }
    };

    return (
        <StyledCard width="349px" height="326px" data-testid={`id-${replace(/ /g, "-", toLower(title ?? ""))}`}>
            {isPremiumUserFeature && (
                <PremTag fontSize="13px" fontWeight="bold">
                    {t("words.premium")}
                </PremTag>
            )}

            {showLockedFeature ? (
                <CardImageContainer>
                    <BlurredCardImage {...backgroundProps} />
                    <LockOverlayBox justifyContent="center" alignItems="center">
                        <Image src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} maxHeight="80px" />
                    </LockOverlayBox>
                </CardImageContainer>
            ) : (
                <Image {...backgroundProps} />
            )}
            <FlexWithGap fd="column" gap="8px" p="24px 32px 32px 32px" height="214px">
                <Heading fontSize="22px">{t(title)}</Heading>
                <Text fontSize="16px" color="hpPrimaryPurple" fontWeight="light">
                    {t(text)}
                </Text>
                <StyledButton onClick={onCardButtonClick}>
                    {t(showLockedFeature ? "words.unlock_now" : "words.access_now")}
                </StyledButton>
            </FlexWithGap>
        </StyledCard>
    );
};

ImproveCard.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    handleClick: PropTypes.func,
    image: PropTypes.string,
    isPremOnlyCard: PropTypes.bool,
    isCoreOnlyCard: PropTypes.bool
};

export default withPremium(ImproveCard);
