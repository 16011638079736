import React, { Component } from "react";
import styled, { withTheme } from "styled-components";

import { Icon } from "../Icons";
import PropTypes from "prop-types";
import TextField from "./TextField";
import { matchSize } from "../../themes";
import { withTranslation } from "react-i18next";

const Root = styled.div`
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    height: ${props => matchSize({ medium: "3.125em", large: "4.688em" })(props.theme.searchBar.size)};

    border-radius: 5px;
    background-color: ${props => props.theme.colours.flat.white.hex};
    ${props => props.theme.shadowCss.rest()};

    :focus {
        background-color: ${props => props.theme.colours.flat.lightGray.hex};
        ${props => props.theme.shadowCss.elevated()};
    }
`;

const SearchTextBox = styled(TextField)`
    margin: 0 3em 0 0.5em;
    width: 100%;
    border-bottom: none;
    :focus {
        border-bottom: none;
    }
`;

const SearchButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${props => props.theme.colours.flat.blue.hex};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;

    :active {
        background-color: ${props => props.theme.colours.flat.darkBlue.hex};
    }
    width: ${props => matchSize({ medium: "3.5em", large: "5em" })(props.theme.searchBar.size)};
`;

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.searchTerm = null;
    }

    static propTypes = {
        onSearchClicked: PropTypes.func,
        onInputFocus: PropTypes.func,
        onChange: PropTypes.func,
        onInput: PropTypes.func,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        size: PropTypes.oneOf(["medium", "large"]),
        theme: PropTypes.object.isRequired,
        t: PropTypes.func
    };

    static defaultProps = {
        placeholder: "web_components.forms.search_bar.placeholder",
        size: "medium"
    };

    onInputFocus(inputRef) {
        const { onInputFocus } = this.props;
        if (typeof onInputFocus == "function") {
            onInputFocus(inputRef);
        }
    }

    onChange(e) {
        const { onChange } = this.props;
        if (typeof onChange == "function") {
            onChange(e);
        }
    }

    onInput(e) {
        this.searchTerm = e.target.value;
        const { onInput } = this.props;
        if (typeof onInput == "function") {
            onInput(e);
        }
    }

    onClick() {
        const { onSearchClicked } = this.props;
        if (typeof onSearchClicked == "function") {
            onSearchClicked(this.searchTerm);
        }
    }

    render() {
        const { placeholder, className, size, theme } = this.props;

        return (
            <Root className={`search-bar-ashke-shogh-${className}`} size={size}>
                <SearchTextBox
                    name={"search-text-box"}
                    placeholder={this.props.t(placeholder, placeholder)}
                    label={""}
                    fieldId={"search-text-box"}
                    className={`search-text-box-in-the-search-bar`}
                    autoFocus={true}
                    onFocus={e => this.onInputFocus(e)}
                    onChange={e => this.onChange(e)}
                    onInput={e => this.onInput(e)}
                    type={"text"}
                    autoComplete={"off"}
                    size={size}
                />
                <SearchButton onClick={e => this.onClick(e)} size={size}>
                    <Icon
                        className={`some-icon-in-the-search-bar`}
                        type={theme.icons.types.magnifier}
                        colour={theme.colours.flat.white.hex}
                        size={matchSize({
                            medium: theme.icons.sizes.small,
                            large: theme.icons.sizes.large
                        })(theme.searchBar.size)}
                    />
                </SearchButton>
            </Root>
        );
    }
}

export default withTranslation()(withTheme(SearchBar));
