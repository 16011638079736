import ThinImageBanner from "./ThinImageBanner";
import TallImageBanner from "./TallImageBanner";
import ThankYouBanner from "./ThankYouBanner";
import PortalFooterBanner from "./PortalFooterBanner";
import DoubleHeightAdSlot from "./DoubleHeightAdSlot";

export default {
    ThinImageBanner,
    TallImageBanner,
    ThankYouBanner,
    PortalFooterBanner,
    DoubleHeightAdSlot
};
