import React, { useState } from "react";
import PropTypes from "prop-types";
import { Text, Box, Flex } from "rebass";
import { ImperialFormForHeight, MetricFormForHeight } from "./form";
import Switch from "../../Components/Switch";
import { UnitConverter } from "@sisuwellness/utilities";
import { useTranslation } from "react-i18next";

const UNITS = {
    imperial: "Imperial",
    metric: "Metric"
};

const Height = ({ model, setErrors, onSubmit, value }) => {
    const { t } = useTranslation();
    const [isUnitMetric, setIsUnitMetric] = useState(true);

    const handleToggle = selectedMetric => setIsUnitMetric(selectedMetric === UNITS.metric);

    const handleSubmit = values => {
        let value;
        if (isUnitMetric) value = values.heightInCm;
        else value = convertImperialToMetric(values.heightInft, values.heightInInch);
        onSubmit(`${value}`);
    };

    const convertImperialToMetric = (feet, inches) => {
        return UnitConverter.ftInchToCm(Number(feet), inches ? Number(inches) : 0).toFixed(1);
    };

    const convertMetricToImperial = value => {
        if (!value) return ["", ""];
        const { feet, inches } = UnitConverter.cmToFtInch(value);
        return [feet, inches.toFixed(1)];
    };

    return (
        <Flex justifyContent="center" flexDirection="column" alignItems="flex-start">
            <Text fontSize={["22px", "34px"]} textAlign="left" fontWeight="500">
                {t("web_components.survey.hra_restyle.height.what_is_your")} {t(model.getDisplayText()).toLowerCase()}{" "}
                {t(model.getHelpText())}
            </Text>
            <Flex justifyContent="space-between" alignItems="center" mt={["17px", "0px"]} width="100%">
                <Text textAlign="left" fontSize={["13px", "15px"]}>
                    {t("web_components.survey.hra_restyle.height.choose_measurement")}:
                </Text>
                <Switch first={UNITS.metric} second={UNITS.imperial} onToggle={handleToggle} />
            </Flex>
            <Box mt="32px" />
            {isUnitMetric ? (
                <MetricFormForHeight
                    onSubmit={handleSubmit}
                    {...{ setErrors, value }}
                    isRequired={model.isRequired()}
                />
            ) : (
                <ImperialFormForHeight
                    onSubmit={handleSubmit}
                    {...{ setErrors }}
                    isRequired={model.isRequired()}
                    value={convertMetricToImperial(value)}
                />
            )}
        </Flex>
    );
};

Height.propTypes = {
    model: PropTypes.shape({
        getHelpText: PropTypes.func,
        getDisplayText: PropTypes.func,
        isRequired: PropTypes.func
    }),
    setErrors: PropTypes.func,
    onSubmit: PropTypes.func,
    value: PropTypes.any
};

export default Height;
