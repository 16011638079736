import React from "react";
import { path } from "ramda";
import { fixedNumber } from "utilities/commonUtils";
import { Cell, Header } from "./commonElements";
import { t } from "i18next";

const handleCellConstruct = row => {
    const bodyFatPercent = path(["value"], row);
    const value = bodyFatPercent && fixedNumber(bodyFatPercent);
    return <Cell row={row} value={value} unit="%" />;
};

export const bodyFatPercentDef = iconTypes => ({
    minWidth: 92,
    accessor: "bodyFatPercent",
    Cell: handleCellConstruct,
    Header: () => <Header label={t("words.body_fat")} iconType={iconTypes.bodyFat} />
});
