import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { Text, Image } from "rebass";
import { useHistory } from "react-router";

// ------------------------------------------------------------------------------

import { HeadingLabel as Title } from "@sisuwellness/ui/src/components/Labels";
import routes from "constants/routes";
import SummaryCard from "components/Cards/SummaryCard";
import { PORTAL_IMAGE } from "constants/imagePath";
import { MetricCardBtnSecondary } from "components/CommonComponents/Button";
import { getCtaText } from "utilities/premium";

import { PositionedContainer, StyledText, BlurContainer, StyledMetricCardContainer } from "../../styled";
import HeartAgeIcon from "../../icon";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------------

const HeartAgeCard = ({ heartAge, isPremium, text: recommendationText, rating, ...rest }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const ratingKey = isPremium && rating?.key;
    const isHeartAgeFinite = isPremium && Number.isFinite(heartAge);

    const handleOnClick = useCallback(() => {
        history.push(isPremium && !isHeartAgeFinite ? routes.findHealthStations : routes.heartAge);
    }, [isPremium, heartAge]);

    const TEXT_SPECS = {
        optimal: {
            stroke: "#0e6352",
            text: t("members_portal_web.components.templates.metric_cards.heart_age.text_specs.optimal")
        },
        normal: {
            stroke: "#1abc9b",
            text: t("members_portal_web.components.templates.metric_cards.heart_age.text_specs.normal")
        },
        elevated: {
            stroke: "#F6AC3D",
            text: t("members_portal_web.components.templates.metric_cards.heart_age.text_specs.elevated")
        }
    };

    return (
        <SummaryCard
            cardHeader={
                <Title fontSize="15px" fontWeight="normal" mb="12px">
                    {t("members_portal_web.components.templates.metric_cards.heart_age.title")}
                </Title>
            }
            cardFooter={
                <MetricCardBtnSecondary onClick={handleOnClick} data-testid="heartage-cta" id="heart-age">
                    {t(getCtaText(heartAge, isPremium))}
                </MetricCardBtnSecondary>
            }
            cardBody={
                <StyledMetricCardContainer flexDirection="column" alignItems="center" isPremium={isPremium}>
                    <PositionedContainer size="90px" color="hpPrimaryPurple" stroke={TEXT_SPECS[ratingKey]?.stroke}>
                        <HeartAgeIcon />
                        <StyledText fontSize="20px" fontWeight="intermediate" textAlign="center">
                            <Text as="span">{isHeartAgeFinite ? heartAge : "?"}</Text>
                            <Text as="span" fontFamily="openSans" fontSize="12px" mt="-4px">
                                {t("words.years")}
                            </Text>
                        </StyledText>
                    </PositionedContainer>
                    {!isPremium && (
                        <BlurContainer height="168px" alignItems="center" justifyContent="center">
                            <Image size="130px" alt="premium-lock" src={`${PORTAL_IMAGE}/premium/premium-lock.svg`} />
                        </BlurContainer>
                    )}
                    <Text
                        mt="12px"
                        maxWidth="360px"
                        fontSize="13px"
                        textAlign="center"
                        fontWeight="light"
                        color="hpGrayishBlueText"
                    >
                        {isHeartAgeFinite
                            ? TEXT_SPECS[ratingKey]?.text || recommendationText
                            : t("members_portal_web.components.templates.metric_cards.heart_age.alt_heart_age_text")}
                    </Text>
                </StyledMetricCardContainer>
            }
            {...rest}
        />
    );
};

HeartAgeCard.propTypes = {
    text: PropTypes.string,
    isPremium: PropTypes.bool,
    heartAge: PropTypes.number,
    isInterestedInPremium: PropTypes.bool,
    rating: PropTypes.shape({
        key: PropTypes.oneOf(["elevated", "normal", "optimal"])
    })
};

export default HeartAgeCard;
