import Flex from "components/Flex";
import React from "react";

import { Text } from "rebass";
import { useHistory } from "react-router";

// ----------------------------------------------------------------

import { SectionHeaders } from "views/HeartAge/commonElements";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";
import { HealthCard } from "./healthCard";
import { BLOOD_PRESSURE, BODY_MASS_INDEX, SMOKING } from "constants/trend-charts";
import { useUserHealthCheckData } from "views/HeartAge";
import PlansCard from "components/Templates/Premium/PlansCard";
import routes from "constants/routes";
import { PLAN_HEALTHY_HEART } from "views/Improve/ImproveCardData";
import CardStack from "components/Cards/CardStack";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------

const HeartAgeAffects = props => {
    const { t } = useTranslation();
    const { latestHealthCheck } = useUserHealthCheckData();

    const history = useHistory();

    const handleOnClick = link => history.push({ pathname: routes.improve, search: `?wants=${link}` });

    return (
        <Flex fd="column" gap="16px">
            <SectionHeaders height="43px" width="100%" px="16px" {...props}>
                <Text color="white" fontSize={["22px", "", "28px"]}>
                    {t("members_portal_web.views.heart_age.page.affects.header")}
                </Text>
            </SectionHeaders>
            <Flex fd="column" gap="24px">
                <HeadingLabel fontSize={["13px", "", "15px"]}>
                    {t("members_portal_web.views.heart_age.page.affects.heading")}
                </HeadingLabel>
                <Flex fd={["column", "row"]} gap={["24px", "32px"]}>
                    <HealthCard type={BODY_MASS_INDEX} datum={latestHealthCheck} />
                    <HealthCard type={BLOOD_PRESSURE} datum={latestHealthCheck} />
                </Flex>
                <Flex fd={["column", "row"]} gap={["24px", "32px"]}>
                    <HealthCard type={SMOKING} datum={latestHealthCheck} />
                    <HealthCard type="DIABETIC" datum={latestHealthCheck} />
                </Flex>
            </Flex>

            <NormalLabel mt="16px" fontSize="22px">
                {t("members_portal_web.views.heart_age.page.affects.more_info")}
            </NormalLabel>
            <NormalLabel fontSize="15px" mt="8px" mb="16px">
                {t("members_portal_web.views.heart_age.page.affects.explore")}
            </NormalLabel>
            <CardStack>
                <PlansCard {...PLAN_HEALTHY_HEART} onClick={handleOnClick} />
            </CardStack>
        </Flex>
    );
};

export default HeartAgeAffects;
