import { Guidelines } from "@sisuwellness/utilities";
import { PORTAL_IMAGE } from "constants/imagePath";
import { METRIC_INDICATOR } from "utilities/metrics/mapIndicators";

/**
 *
 * @param {String} riskType This represent the type of risk
 * @returns {String} Returns the path of the icon
 */
export const iconPath = riskType => {
    switch (riskType) {
        case "HEART_AGE":
            return `${PORTAL_IMAGE}/recommendation/heart-age.svg`;
        case "BODY_MASS_INDEX":
            return `${PORTAL_IMAGE}/recommendation/bmi.svg`;
        case "DIABETES":
            return `${window.PUBLIC_URL}/images/recommendation/diabetesIcon.svg`;
        case "HDL_CHOLESTEROL":
        case "TOTAL_CHOLESTEROL":
            return `${window.PUBLIC_URL}/images/recommendation/cholesterol.svg`;
        case "FASTING_GLUCOSE":
        case "GLUCOSE":
        case "NON_FASTING_GLUCOSE":
            return `${window.PUBLIC_URL}/images/recommendation/fastingGlucose.svg`;
        case "SMOKING":
            return `${PORTAL_IMAGE}/recommendation/smoking.svg`;
        case "BLOOD_PRESSURE":
            return `${PORTAL_IMAGE}/recommendation/blood-pressure.svg`;
        case "HEART_RATE":
            return `${PORTAL_IMAGE}/recommendation/heart.svg`;
        case "BODY_FAT":
            return `${PORTAL_IMAGE}/recommendation/body-fat.svg`;
        case "SLEEP":
            return `${PORTAL_IMAGE}/recommendation/sleep.svg`;
        case "WEIGHT":
            return `${PORTAL_IMAGE}/recommendation/weight.svg`;
        case Guidelines.PSS4.RiskCode:
            return `${PORTAL_IMAGE}/recommendation/stress.svg`;
        case Guidelines.Qrisk.RiskCode:
        case Guidelines.Activity.RiskCode:
        case Guidelines.Alcohol.RiskCode:
        default:
            return `${PORTAL_IMAGE}/recommendation/recommendation.svg`;
    }
};

/**
 *
 * @param {Object} theme baseTheme
 * @param {Number} risk Type of risk
 * @param {Number} rating string value to display different colors based on rating
 * @return {Object} Object of border color, text color and background color
 */
export const baseColor = (theme, risk, rating) => {
    let colorCode = { color: "", bgColor: "" };
    const { themeKey } = METRIC_INDICATOR[risk] || {};

    if (!themeKey) return colorCode;

    const recommendationGuideline = theme.guidelines[themeKey][rating];
    colorCode.color = recommendationGuideline ? recommendationGuideline.recommendationColor.hex : null;
    colorCode.bgColor = recommendationGuideline ? recommendationGuideline.bgColor.hex : null;
    return colorCode;
};
