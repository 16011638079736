import React from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import { useHistory } from "react-router";

// ------------------------------------------------------------------------------

import Pie from "@sisuwellness/ui/src/components/Pie";
import { HeadingLabel as Title } from "@sisuwellness/ui/src/components/Labels";
import { getHRARecommendation, getHRARiskColor } from "utilities/healthRiskScore";
import routes from "constants/routes";

import SummaryCard from "components/Cards/SummaryCard";
import { MetricCardBtnPrimary, MetricCardBtnSecondary } from "components/CommonComponents/Button";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------------

const HRACard = ({ lifestyle: { healthRiskScore } = {}, ...rest }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const isHealthRiskScoreFinite = Number.isFinite(healthRiskScore);

    const handleOnClick = () => {
        history.push(isHealthRiskScoreFinite ? routes.hraResult : routes.healthRiskOnboarding);
    };

    return (
        <SummaryCard
            data-testid="health-risk-assessment-metric"
            cardHeader={
                <Title fontSize="15px" fontWeight="normal" mb="12px">
                    {t("members_portal_web.components.templates.metric_cards.hra.sisu_health_score")}
                </Title>
            }
            cardFooter={
                isHealthRiskScoreFinite ? (
                    <MetricCardBtnSecondary onClick={handleOnClick}>
                        {t("members_portal_web.components.templates.metric_cards.hra.learn_more")}
                    </MetricCardBtnSecondary>
                ) : (
                    <MetricCardBtnPrimary onClick={handleOnClick}>
                        {t("members_portal_web.components.templates.metric_cards.hra.take_health_assessment")}
                    </MetricCardBtnPrimary>
                )
            }
            cardBody={
                <Flex flexDirection="column" justifyContent="space-between" alignItems="center">
                    <Pie
                        size={85}
                        bg={isHealthRiskScoreFinite || "hpLightBlue"}
                        progress={isHealthRiskScoreFinite ? healthRiskScore : 100}
                        progressFgColor={getHRARiskColor(healthRiskScore)}
                    >
                        <Text fontSize="20px" textAlign="center" color="hpPrimaryPurple">
                            {isHealthRiskScoreFinite ? healthRiskScore : "?"}
                        </Text>
                    </Pie>
                    <Text
                        mt="16px"
                        maxWidth="360px"
                        fontSize="13px"
                        textAlign="center"
                        fontWeight="light"
                        color="hpGrayishBlueText"
                    >
                        {t(getHRARecommendation(healthRiskScore))}
                    </Text>
                </Flex>
            }
            {...rest}
        />
    );
};

HRACard.propTypes = {
    lifestyle: PropTypes.object
};

export default HRACard;
