import React, { useContext } from "react";
import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

// ----------------------------------------------------------------------------

import routes from "constants/routes";
import SecurityCardForm from "./securityCardForm";
import SecurityCard, { OptionField, EditField } from "./securityCard";

import {
    NO_NETWORK_ERROR_MESSAGE,
    REQUEST_FAILED_ERROR_MESSAGE,
    INCORRECT_PASSWORD_ERROR_MESSAGE,
    MESSAGE_SUCCESS,
    MESSAGE_ERROR
} from "constants/messages";
import APIClient from "utilities/APIClient";
import { MessageContext } from "components/Context";
import { setCitizen } from "@sisuwellness/connected-components/actions/auth";
import useRudderStack from "hooks/useRudderStack";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------------

const SecurityDetail = ({ forms, onUserProfileCompleted }) => {
    const { t } = useTranslation();

    const rudderStack = useRudderStack();
    const dispatch = useDispatch();
    const initialValue = useSelector(state => state.auth.citizen);
    const email = initialValue.userProfile.email;
    const { handleMessage } = useContext(MessageContext);
    const history = useHistory();
    const optFields = [
        {
            main: t("words.email_address"),
            sub: email,
            testId: "email"
        },
        {
            main: t("words.password"),
            sub: "********",
            testId: "password"
        }
    ];

    const localisedRequestFailedErrorMessage = t(REQUEST_FAILED_ERROR_MESSAGE);
    const localisedNoNetworkErrorMessage = t(NO_NETWORK_ERROR_MESSAGE);

    async function handleSubmit({ email, existingPassword, newPassword, newPasswordConfirm }, { setSubmitting }) {
        setSubmitting(true);
        if (existingPassword !== "" || newPassword !== "" || newPasswordConfirm !== "") {
            try {
                const response = await APIClient.auth.changePassword(existingPassword, newPassword, newPasswordConfirm);
                if (response.succeeded()) {
                    history.replace(routes.myAccount.security);
                    handleMessage(MESSAGE_SUCCESS, "Your password was successfully changed.");
                    rudderStack.trackEvent("Account Password Update", { type: "userEntered" });
                } else if (response.isNetworkFailure()) {
                    handleMessage(MESSAGE_ERROR, localisedNoNetworkErrorMessage);
                } else if (response.isNotAuthorised()) {
                    handleMessage(MESSAGE_ERROR, t(INCORRECT_PASSWORD_ERROR_MESSAGE));
                } else {
                    handleMessage(MESSAGE_ERROR, localisedRequestFailedErrorMessage);
                }
            } catch (error) {
                handleMessage(MESSAGE_ERROR, localisedRequestFailedErrorMessage);
            } finally {
                window.scrollTo(0, 0);
            }
        }
        const updatedUser = {
            ...initialValue.userProfile,
            email: email
        };

        if (initialValue.userProfile.email !== email) {
            try {
                const response = await APIClient.auth.updateUserProfile(updatedUser);
                if (response.succeeded()) {
                    dispatch(setCitizen({ ...initialValue.citizen, userProfile: { ...updatedUser } }));
                    history.replace(routes.myAccount.security);
                    handleMessage(MESSAGE_SUCCESS, t("members_portal_web.components.notifications.update_success"));
                    window.scrollTo(0, 0);

                    if (typeof onUserProfileCompleted == "function") {
                        onUserProfileCompleted();
                    }

                    setCitizen({
                        ...initialValue,
                        userProfile: {
                            ...email
                        }
                    });
                } else {
                    let errorMessage = null;

                    if (response.isNetworkFailure()) {
                        handleMessage(MESSAGE_ERROR, NO_NETWORK_ERROR_MESSAGE);
                    } else if (response.isBadRequest()) {
                        const responseBody = await response.body();
                        errorMessage = t("members_portal_web.components.notifications.invalid_fields");
                        if (responseBody.length && responseBody[0].value) {
                            errorMessage = responseBody[0].value;
                        }
                    } else {
                        errorMessage = localisedRequestFailedErrorMessage;
                    }
                    handleMessage(MESSAGE_ERROR, errorMessage);
                }
            } catch (error) {
                handleMessage(MESSAGE_ERROR, error.message);
            } finally {
                window.scrollTo(0, 0);
            }
        }
        setSubmitting(false);
    }

    return (
        <SecurityCard heading={t("words.account_settings")}>
            {!forms && (
                <EditField to={routes.myAccount.accountSettings} data-testid="security-form">
                    {t("words.edit")}
                </EditField>
            )}
            {!forms ? (
                optFields.map(option => (
                    <OptionField key={option.main}>
                        <div className="field-main">{option.main}</div>
                        <div className="field-sub" data-testid={option.testId}>
                            {option.sub}
                        </div>
                    </OptionField>
                ))
            ) : (
                <SecurityCardForm onSubmit={handleSubmit} />
            )}
        </SecurityCard>
    );
};

SecurityDetail.propTypes = {
    forms: PropTypes.bool,
    onUserProfileCompleted: PropTypes.func
};

SecurityDetail.defaultProps = {
    forms: false
};

export default SecurityDetail;
