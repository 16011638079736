import React from "react";
import PropTypes from "prop-types";

// ----------------------------------------------------------------------------

import { Card, Container, HeaderField } from "./styled";

// ----------------------------------------------------------------------------

const PreferenceCard = ({ heading, children, ...rest }) => {
    return (
        <Card>
            <Container {...rest}>
                <HeaderField>{heading}</HeaderField>
                {children}
            </Container>
        </Card>
    );
};

PreferenceCard.propTypes = {
    heading: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired
};

export default PreferenceCard;
