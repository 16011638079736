import React from "react";
import PropTypes from "prop-types";

import { compose } from "ramda";
import { Box, Flex, Text } from "rebass";

// ---------------------------------------------------------------------------------------------

import { pickAttribute } from "utilities/commonUtils";
import { SquaredCard as Card } from "@sisuwellness/ui/src/components/Cards";
import HealthMetrics from "utilities/metrics";
import { HRA_EMOTIONAL_SCORE, HRA_LIFESTYLE_SCORE, HRA_SOCIAL_SCORE } from "constants/trend-charts";
import { HeadingLabel, NormalLabel } from "@sisuwellness/ui/src/components/Labels";

import IndexCard from "./indexCard";
import { useTranslation } from "react-i18next";

// ---------------------------------------------------------------------------------------------

const ScoreBreakdown = ({ citizenData, ...rest }) => {
    const { t } = useTranslation();
    const toNumber = val => (val || val === 0 ? Number(val) : null);

    const HRASocialScore = compose(
        toNumber,
        parseFloat,
        pickAttribute(["socialScore", "value"])
    )(citizenData);

    const HRAEmotionalScore = compose(
        toNumber,
        parseFloat,
        pickAttribute(["emotionalScore", "value"])
    )(citizenData);

    const HRALifestyleScore = compose(
        toNumber,
        parseFloat,
        pickAttribute(["lifestyleIndex", "value"])
    )(citizenData);

    const datum = {
        HRASocialScore,
        HRAEmotionalScore,
        HRALifestyleScore
    };

    return (
        <Box {...rest}>
            <HeadingLabel fs={["22px", "34px"]}>
                {t("members_portal_web.views.hra_result.breakdown.heading")}
            </HeadingLabel>
            <NormalLabel fontSize={["13px", "15px"]} mt="16px" mb="24px" fontWeight="400">
                {t("members_portal_web.views.hra_result.breakdown.description")}
            </NormalLabel>
            <Card width="100%" height="fit-content" p={["24px", "40px"]}>
                <Flex
                    justifyContent={["stretch", "space-between"]}
                    alignItems="center"
                    flexWrap="wrap"
                    flexDirection={["column", "", "row"]}
                >
                    <HealthMetrics type={HRA_LIFESTYLE_SCORE} healthCheck={datum}>
                        {data => <IndexCard {...data} />}
                    </HealthMetrics>
                    <Text fontSize="20px" my="16px">
                        +
                    </Text>
                    <HealthMetrics type={HRA_SOCIAL_SCORE} healthCheck={datum}>
                        {data => <IndexCard {...data} />}
                    </HealthMetrics>
                    <Text fontSize="20px" my="16px">
                        +
                    </Text>
                    <HealthMetrics type={HRA_EMOTIONAL_SCORE} healthCheck={datum}>
                        {data => <IndexCard {...data} />}
                    </HealthMetrics>
                </Flex>
            </Card>
        </Box>
    );
};

ScoreBreakdown.propTypes = {
    citizenData: PropTypes.object.isRequired
};

export default ScoreBreakdown;
