import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import { withTranslation } from "react-i18next";

class HeartAge extends Component {
    render() {
        const { t, theme, date, heartAge, ...rest } = this.props;

        return (
            <Attribute
                {...rest}
                t={t}
                colour={theme.colours.flat.orange.hex}
                gradientCSS={theme.colours.gradientCSS.orange}
                title={this.props.t("web_components.cards.attributes.heart_age.title")}
                date={date}
                guidelineRating={this.props.t("web_components.cards.attributes.heart_age.guideline")}
                primaryStatValue={heartAge}
                icon={theme.icons.types.heartAge}
            />
        );
    }
}

HeartAge.displayName = "Heart Age Attribute";
HeartAge.propTypes = {
    t: PropTypes.func,
    theme: PropTypes.any.isRequired,
    age: PropTypes.number,
    heartAge: PropTypes.number,
    date: PropTypes.string
};

export default withTranslation()(withTheme(HeartAge));
