import React from "react";
import PropTypes from "prop-types";

import { Flex } from "rebass";

// ----------------------------------------------------------------

import { HeadingLabel as Heading } from "@sisuwellness/ui/src/components/Labels";
import FlexWithGap from "components/Flex";

import {
    HealthCardCallToAction,
    HealthCardIcon,
    HealthCardProgress,
    HealthCardStatus,
    HealthCardTextValue,
    HealthCardRecommendation
} from "./atomicComponents";
import SummaryCard from "components/Cards/SummaryCard";

// ----------------------------------------------------------------

export const HealthCardForDashboard = ({ metric, ...rest }) => {
    const { value, unit, secValue, secUnit, title, showIcon } = metric;

    const TextValue = (
        <FlexWithGap flexWrap="nowrap" jc="center" ai="baseline" gap="4px" color="blackTints700">
            <HealthCardTextValue value={value} unit={unit} valueProps={{ fontSize: 20, textAlign: "center" }} />
            <HealthCardTextValue value={secValue} unit={secUnit} unitProps={{ fontSize: 15 }} />
        </FlexWithGap>
    );

    return (
        <SummaryCard
            height="286px"
            scale="small"
            flexBasis={["162px", "772px", "265px"]}
            cardHeader={
                <Flex width="100%" justifyContent="center">
                    <Heading mb="16px" fontSize="15px">
                        {title}
                    </Heading>
                </Flex>
            }
            cardFooter={
                <HealthCardCallToAction width="100px" height="40px" fontSize="14px" fontWeight="regular" {...metric} />
            }
            cardBody={
                <Flex flexDirection="column" textAlign="left" alignItems="center">
                    <HealthCardStatus
                        width="114px"
                        height="26px"
                        fontProps={{ fontSize: 12, fontWeight: "400", mt: 0 }}
                        {...metric}
                    />
                    <Flex justifyContent="space-between" alignItems="center" my="16px">
                        {showIcon ? (
                            <HealthCardIcon {...metric}>{TextValue}</HealthCardIcon>
                        ) : (
                            <HealthCardProgress {...metric}>{TextValue}</HealthCardProgress>
                        )}
                    </Flex>
                </Flex>
            }
            {...rest}
        />
    );
};

const HealthCard = ({ metric, showRecommendations = false, ...rest }) => {
    const { value, unit, secValue, secUnit, title, showIcon } = metric;

    const TextValue = (
        <FlexWithGap flexWrap="nowrap" jc="center" ai="baseline" gap="4px" color="blackTints700">
            <HealthCardTextValue value={value} unit={unit} />
            <HealthCardTextValue value={secValue} unit={secUnit} />
        </FlexWithGap>
    );

    return (
        <SummaryCard
            height={showRecommendations ? "324px" : "260px"}
            scale="small"
            cardHeader={
                <Flex width="100%">
                    <Heading mb="16px">{title}</Heading>
                </Flex>
            }
            cardFooter={<HealthCardCallToAction {...metric} />}
            cardBody={
                <Flex flexDirection="column" textAlign="left">
                    <Flex justifyContent="space-between" alignItems="center" mb="12px">
                        {showIcon ? (
                            <HealthCardIcon {...metric}>{TextValue}</HealthCardIcon>
                        ) : (
                            <HealthCardProgress {...metric}>{TextValue}</HealthCardProgress>
                        )}
                        <HealthCardStatus {...metric} />
                    </Flex>
                    {showRecommendations && <HealthCardRecommendation fontSize="13px" {...metric} />}
                </Flex>
            }
            {...rest}
        />
    );
};

// ----------------------------------------------------------------

const commonProps = {
    ...SummaryCard.propTypes,
    metric: PropTypes.shape({
        title: PropTypes.string,
        progress: PropTypes.number,
        unit: PropTypes.string,
        text: PropTypes.string,
        link: PropTypes.string,
        url: PropTypes.string,
        icon: PropTypes.string,
        showIcon: PropTypes.bool,
        priority: PropTypes.number,
        indicator: PropTypes.shape({
            fg: PropTypes.string,
            bg: PropTypes.string,
            label: PropTypes.string
        }),
        secUnit: PropTypes.string,
        secValue: PropTypes.any,
        isRiskHigh: PropTypes.bool,
        value: PropTypes.any
    }).isRequired
};

HealthCardForDashboard.propTypes = { ...commonProps };
HealthCard.propTypes = { ...commonProps, showRecommendations: PropTypes.bool };

export default HealthCard;
