import { UPDATE_USER_SETTING, SET_CITIZEN } from "../../constants/actions";

export const initialState = {};

export default (state = initialState, { type, payload } = {}) => {
    switch (type) {
        case SET_CITIZEN: {
            return {
                ...state,
                ...payload.settings
            };
        }

        case UPDATE_USER_SETTING: {
            return {
                ...state,
                ...payload
            };
        }

        default: {
            return state;
        }
    }
};
