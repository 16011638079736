import styled from "styled-components";
import { Box, Flex as RebassFlex } from "rebass";

const FlexBox = styled(Box)`
    display: flex;
    flex-direction: ${props => props.fd};
    align-items: ${props => props.ai};
    justify-content: ${props => props.jc};
    flex: ${props => props.f};
`;

const Flex = styled(RebassFlex)`
    flex-direction: ${props => props.fd};
    align-items: ${props => props.ai};
    justify-content: ${props => props.jc};
    flex: ${props => props.f};
    gap: ${props => props.gap};
`;

export { Box, Flex, FlexBox };
