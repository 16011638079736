import styled from "styled-components";
import Select from "react-select";
import React from "react";
import PropTypes from "prop-types";
import { ErrorText } from "./";

const StyledSelect = styled(Select)`
    display: inline-block;
    min-width: 50%;

    > .styled-select__control {
        border: 0;
        color: ${props => props.theme.colours.flat.gray.hex};
        font-size: ${props => props.fontSize || props.theme.text.heading4.sizeRem};
        font-weight: normal;
        width: 100%;
        //margin-bottom: 20px;
        padding: 10px 2px;
        border-bottom: 2px solid
            ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.gray.hex)};
        box-shadow: none;
    }

    .styled-select__value-container {
        padding: 0;

        .styled-select__placeholder {
            color: ${props => props.theme.colours.flat.gray.hex};
        }
    }

    > .styled-select__control:hover {
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        border: 0;
        border-bottom: 2px solid
            ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.blue.hex)};
        font-size: ${props => props.fontSize || props.theme.text.heading4.sizeRem};
        outline: none;
        color: ${props => props.theme.colours.flat.gray.hex};
        box-shadow: none;
    }

    .styled-select__indicator-separator {
        visibility: hidden;
    }

    > .styled-select__menu {
        text-align: left;
        font-size: ${props => props.fontSize || props.theme.text.heading4.sizeRem};
    }
`;

const SelectField = ({
    onValueChange,
    options,
    placeholder,
    value,
    defaultValue,
    className,
    fontSize,
    errors,
    disabled,
    forwardedRef,
    ...rest
}) => {
    function handleInputChange(selectedValue) {
        if (selectedValue !== null) {
            onValueChange(selectedValue.value);
        }
    }

    return (
        <>
            <StyledSelect
                ref={forwardedRef}
                className={className}
                options={options}
                onChange={handleInputChange}
                classNamePrefix={"styled-select"}
                placeholder={placeholder}
                value={value}
                defaultValue={defaultValue}
                fontSize={fontSize}
                errors={errors}
                isDisabled={disabled}
                {...rest}
            />
            {errors && <ErrorText>{errors}</ErrorText>}
        </>
    );
};

SelectField.displayName = "Select";

SelectField.propTypes = {
    options: PropTypes.array.isRequired,
    onValueChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    value: PropTypes.any,
    defaultValue: PropTypes.any,
    fontSize: PropTypes.string,
    errors: PropTypes.string,
    disabled: PropTypes.bool,
    forwardedRef: PropTypes.object
};

// eslint-disable-next-line react/display-name
export default React.forwardRef((props, ref) => <SelectField {...props} forwardedRef={ref} />);
