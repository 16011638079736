import { mediaQueryForCss } from "utilities/mediaQuery";
import styled from "styled-components";

export const customStyles = style => ({
    overlay: {
        backgroundColor: "rgba(55, 33, 104, 0.8)",
        zIndex: "1000"
    },
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        maxHeight: "80vh",
        transform: "translate(-50%, -50%)",
        padding: "0px",
        margin: "0px",
        boxSizing: "border-box",
        borderRadius: "10px",
        ...style
    }
});

export const ModalBody = styled.div`
    padding: 40px 32px;
    ${mediaQueryForCss({
        padding: ["", "", "36px 24px"]
    })}
`;

export const StyledButtonCross = styled.img`
    width: 28px;
    height: 28px;
    cursor: pointer;
    align-self: center;
`;

export const Box = styled.div`
    .header {
        font-size: ${props => props.theme.fontSize[22].px};
        color: ${props => props.theme.colours.hpPrimaryPurple.hex};
    }

    .content {
        font-size: 17px;
        color: ${props => props.theme.colours.hpGrayishBlueText.hex};
    }
`;
