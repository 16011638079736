import React from "react";
import PropTypes from "prop-types";

import { Flex, Text } from "rebass";
import ReactPagination from "react-paginate";

// -----------------------------------------------------

import Chevron from "components/Icons/chevron";

import { calculateViewedRecords } from "./utilities";
import { StyledPagination } from "./styled";
import { useTranslation } from "react-i18next";

// -----------------------------------------------------

const Pagination = ({ pageProps: { stats, visibleRecords, currentPageIndex }, setCurrentPage, limit }) => {
    const { t } = useTranslation();
    const hasPrev = currentPageIndex > 1;
    const hasNext = currentPageIndex < stats.pages;

    const jumpToIndex = index => index >= 1 && index <= stats.pages && setCurrentPage(index);

    const next = () => hasNext && setCurrentPage(prevState => prevState + 1);

    const prev = () => hasPrev && setCurrentPage(prevState => prevState - 1);

    const OnPaginationItemsClick = ({ nextSelectedPage, isPrevious, isNext }) => {
        if (isNext) next();
        else if (isPrevious) prev();
        else jumpToIndex(nextSelectedPage + 1);
    };

    let viewedRecords = calculateViewedRecords(limit, currentPageIndex, visibleRecords);

    const showPaginationPanelForMultiplePages = stats.pages > 1;

    return (
        <Flex mt="28px" flexDirection="column" justifyContent="center" alignItems="center" data-testid="phc-pagination">
            {showPaginationPanelForMultiplePages && (
                <StyledPagination data-testid="phc-pagination-panel">
                    <ReactPagination
                        breakLabel="..."
                        pageRangeDisplayed={3}
                        pageCount={stats.pages}
                        marginPagesDisplayed={1}
                        renderOnZeroPageCount={null}
                        onClick={OnPaginationItemsClick}
                        initialPage={currentPageIndex - 1}
                        containerClassName="react-pagination"
                        nextLabel={<Chevron strokeWidth="2" width="22" height="22" right />}
                        previousLabel={<Chevron strokeWidth="2" width="22" height="22" left />}
                    />
                </StyledPagination>
            )}
            <Text fontSize="13px" color="hpDesaturatedBlue">
                {viewedRecords - visibleRecords + 1} - {viewedRecords} of {stats.total}{" "}
                {t("members_portal_web.views.health_checks.results_shown")}
            </Text>
        </Flex>
    );
};

Pagination.propTypes = {
    limit: PropTypes.number,
    setCurrentPage: PropTypes.func,
    pageProps: PropTypes.shape({
        visibleRecords: PropTypes.number,
        currentPageIndex: PropTypes.number,
        stats: PropTypes.shape({ total: PropTypes.number, pages: PropTypes.number })
    })
};

export default Pagination;
