import { PORTAL_IMAGE } from "constants/imagePath";

export const IMPROVE_DESTINATIONS = {
    MEALS: "meals",
    ACTIVITY: "activity_planner",
    PROGRAMS: {
        HEALTHY_HEART: "healthy-heart",
        SLEEP: "better-sleep",
        STRESS: "building-stress-resilience",
        WEIGHT_LOSS: "sustainable-weight-loss",
        PERSONAL_PRODUCTIVITY: "personal-productivity",
        SEVEN_MIN_WORKOUT: "the-7-minute-workout"
    }
};

export const USES_LEARN_WORLD = {
    meals: false,
    // eslint-disable-next-line camelcase
    activity_planner: false,
    "healthy-heart": "heartHealth",
    "better-sleep": "sleep",
    "building-stress-resilience": "buildingStressResilience",
    "sustainable-weight-loss": "sustainableWeightLoss",
    "the-7-minute-workout": "sevenMinuteWorkout"
};

export const PLAN_SEVEN_MIN_WORKOUT = {
    type: "PLAN_SEVEN_MIN_WORKOUT",
    title: "members_portal_web.views.improve.plans.titles.seven_min_workout",
    text: "members_portal_web.views.improve.plans.texts.seven_min_workout",
    image: `${PORTAL_IMAGE}/improve/seven-minute-workout.svg`,
    usesLearnWorld: true,
    link: IMPROVE_DESTINATIONS.PROGRAMS.SEVEN_MIN_WORKOUT,
    isPremOnlyCard: true
};

export const PLAN_HEALTHY_HEART = {
    type: "PLAN_HEALTHY_HEART",
    title: "members_portal_web.views.improve.plans.titles.healthy_heart",
    text: "members_portal_web.views.improve.plans.texts.healthy_heart",
    image: `${PORTAL_IMAGE}/improve/heart_age.svg`,
    usesLearnWorld: true,
    link: IMPROVE_DESTINATIONS.PROGRAMS.HEALTHY_HEART,
    isPremOnlyCard: true
};

export const PLAN_SUSTAINABLE_WEIGHT_LOSS = {
    type: "PLAN_SUSTAINABLE_WEIGHT_LOSS",
    title: "members_portal_web.views.improve.plans.titles.weight_loss",
    text: "members_portal_web.views.improve.plans.texts.weight_loss",
    image: `${PORTAL_IMAGE}/improve/sustainable-weight.svg`,
    usesLearnWorld: true,
    link: IMPROVE_DESTINATIONS.PROGRAMS.WEIGHT_LOSS,
    isPremOnlyCard: true
};

export const PLAN_BETTER_SLEEP = {
    type: "PLAN_BETTER_SLEEP",
    title: "members_portal_web.views.improve.plans.titles.better_sleep",
    text: "members_portal_web.views.improve.plans.texts.better_sleep",
    image: `${PORTAL_IMAGE}/improve/sleep.svg`,
    usesLearnWorld: true,
    link: IMPROVE_DESTINATIONS.PROGRAMS.SLEEP,
    isPremOnlyCard: true
};

export const PLAN_BUILDING_STRESS_RESILIENCE = {
    type: "PLAN_BUILDING_STRESS_RESILIENCE",
    title: "members_portal_web.views.improve.plans.titles.stress_resilience",
    text: "members_portal_web.views.improve.plans.texts.stress_resilience",
    image: `${PORTAL_IMAGE}/improve/stress.svg`,
    link: IMPROVE_DESTINATIONS.PROGRAMS.STRESS,
    isPremOnlyCard: true
};

export const IMPROVE_DATA = [
    {
        type: "ACTIVITY_PLANNER",
        title: "members_portal_web.views.improve.plans.titles.activity_planner",
        text: "members_portal_web.views.improve.plans.texts.activity_planner",
        image: `${PORTAL_IMAGE}/improve/activity.svg`,
        link: IMPROVE_DESTINATIONS.ACTIVITY
    },
    {
        type: "MEAL_PLANNER",
        title: "members_portal_web.views.improve.plans.titles.meal_planner",
        text: "members_portal_web.views.improve.plans.texts.meal_planner",
        image: `${PORTAL_IMAGE}/improve/meal.svg`,
        link: IMPROVE_DESTINATIONS.MEALS
    },
    PLAN_SEVEN_MIN_WORKOUT,
    PLAN_HEALTHY_HEART,
    PLAN_BETTER_SLEEP,
    PLAN_SUSTAINABLE_WEIGHT_LOSS,
    PLAN_BUILDING_STRESS_RESILIENCE
];
