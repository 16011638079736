import React, { Component } from "react";
import styled from "styled-components";
import QuestionGroup from "./QuestionGroups/QuestionGroup";
import PropTypes from "prop-types";
import { media } from "@sisuwellness/web-components/themes";
import { withTranslation } from "react-i18next";

const StyledSurvey = styled.div`
    text-align: center;
    width: 100%;
    height: 100%;

    ${media.mobile`
        font-size: 0.7em;
    `};
`;

class Survey extends Component {
    static displayName = "Survey";

    static defaultProps = {
        showHeader: false,
        touchKeyboard: false
    };

    static propTypes = {
        model: PropTypes.object.isRequired,
        onComplete: PropTypes.func.isRequired,
        canAskNextQuestion: PropTypes.func,
        touchKeyboard: PropTypes.bool,
        showHeader: PropTypes.bool,
        getComponentForQuestionType: PropTypes.func,
        t: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            currentQuestion: this.props.model.getNextQuestionToShow()
        };

        this.handleQuestionGroupComplete = this.handleQuestionGroupComplete.bind(this);
        this.updateCurrentQuestion = this.updateCurrentQuestion.bind(this);
    }

    componentDidMount() {
        // did the construction determine there was something to do or not
        if (!this.state.currentQuestion) {
            this.props.onComplete();
        }
    }

    updateCurrentQuestion(question) {
        this.setState({ currentQuestion: question });
    }

    handleQuestionGroupComplete() {
        let nextQuestion = this.props.model.getNextQuestionToShow(this.state.currentQuestion);
        this.setState({
            // currentQuestionGroupId
            currentQuestion: nextQuestion
        });

        if (this.props.model.isStateComplete() || nextQuestion == null) {
            // in preview use cases there is no response object
            let responseJSON = {};
            if (this.props.model.getResponse()) {
                responseJSON = this.props.model.getResponse().toJSON();
            }
            this.props.onComplete(responseJSON);
        }
    }

    canAskNextQuestion(question) {
        return this.props.canAskNextQuestion ? this.props.canAskNextQuestion(question) : true;
    }

    render() {
        if (!this.state.currentQuestion) {
            return null;
        }

        let questionGroupModel = this.state.currentQuestion.getQuestionGroup();

        let questionGroup = (
            <QuestionGroup
                key={questionGroupModel.getQuestionGroupId()}
                model={questionGroupModel}
                onComplete={this.handleQuestionGroupComplete}
                canAskNextQuestion={question => this.canAskNextQuestion(question)}
                touchKeyboard={this.props.touchKeyboard}
                onChangeCurrentQuestion={this.updateCurrentQuestion}
                getComponentForQuestionType={this.props.getComponentForQuestionType}
            />
        );

        let header = null;
        if (this.props.showHeader) {
            header = <h1>{this.props.t(this.props.model.getDisplayName())}</h1>;
        }

        return (
            <StyledSurvey
                data-testid={`survey-${this.props.model.getCode()}`}
                healthStationMode={questionGroupModel.getSurvey().getHealthStationMode() ? "true" : "false"}
            >
                {header}
                {questionGroup}
            </StyledSurvey>
        );
    }
}

export default withTranslation()(Survey);
