import React from "react";
import styled from "styled-components";

import { DataCell, DifferenceLabel, InformationModal } from "views/HeartAge/commonElements";
import { calculateHeartAgeParams, isHeartAgeIndicative } from "views/HeartAge/utils";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";
import { MediaQuery } from "@sisuwellness/web-components";
import { useUserHealthCheckData } from "views/HeartAge";
import { formatDate } from "utilities/commonUtils";
import { HEART_AGE } from "constants/messages";
import Flex from "components/Flex";
import OverviewCardUI from "../overviewCardUI";
import CardStack from "components/Cards/CardStack";
import { useSelector } from "react-redux";
import { Image } from "rebass";
import { PORTAL_IMAGE } from "constants/imagePath";
import { useTranslation } from "react-i18next";

const ItalicizedText = styled.span`
    font-weight: 300;
    font-style: oblique;
`;

export const CurrentTab = ({ displayTab }) => {
    const { t } = useTranslation();
    const { isCountryUK } = useSelector(state => state.deviceLocation);
    const { latestHealthCheck } = useUserHealthCheckData();

    const { age, heartAge, heartAgeRating, lastMeasurementDate, tenYearCvdRisk } = calculateHeartAgeParams(
        latestHealthCheck
    );

    const METRIC_TEXT_SPECS = {
        HEART_AGE: {
            heading: t("words.heart_age"),
            icon: "heart-age.svg",
            bg: "hpLightBlue"
        },
        TEN_YEAR_CVD_RISK: {
            heading: t("words.heart_stroke_risk"),
            icon: "heart-stroke.svg",
            bg: "hpPurplishPink",
            text: t("members_portal_web.views.heart_age.page.overview.cvd_risk_text")
        }
    };

    return (
        displayTab && (
            <Flex fd="column" gap="24px" mt="16px" width="100%">
                <Flex fd="column">
                    <HeadingLabel fontSize={["13px", "", "15px"]}>
                        {t("members_portal_web.views.heart_age.page.overview.current.headings.0", {
                            date: formatDate(lastMeasurementDate, "DD/MM/YYYY")
                        })}
                    </HeadingLabel>
                    {isHeartAgeIndicative(age) && (
                        <HeadingLabel fontSize={["13px", "", "15px"]}>
                            {t("members_portal_web.views.heart_age.page.overview.current.headings.1")}{" "}
                            <ItalicizedText>
                                {t("members_portal_web.views.heart_age.page.overview.current.headings.2")}
                            </ItalicizedText>
                            .
                        </HeadingLabel>
                    )}
                </Flex>
                <CardStack gap={["8px", "16px", "32px"]} py="24px">
                    <OverviewCardUI {...METRIC_TEXT_SPECS.HEART_AGE}>
                        <Flex width="100%" gap="16px" jc="space-between">
                            <DataCell
                                age={heartAge}
                                title={t("words.heart_age")}
                                gap={["16px", "8px", "4px"]}
                                data-testid="cell-heart-age"
                                units={t("words.years")}
                            />
                            <DataCell
                                age={age}
                                title={t("words.actual_age")}
                                gap={["16px", "8px", "4px"]}
                                data-testid="cell-actual-age"
                                units={t("words.years")}
                            />
                            <MediaQuery devices={["desktop", "tablet"]}>
                                <DifferenceLabel
                                    actualAge={age}
                                    heartAge={heartAge}
                                    rating={heartAgeRating}
                                    gap={["16px", "8px", "4px"]}
                                />
                            </MediaQuery>
                        </Flex>
                    </OverviewCardUI>
                    {isCountryUK ? (
                        <>
                            <MediaQuery devices={["desktop"]}>
                                <Flex bg="white" p="16px" justifyContent="center" flex={1}>
                                    <Image
                                        alt="uk-heart-age-overview.svg"
                                        src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                    />
                                </Flex>
                            </MediaQuery>
                            <MediaQuery devices={["tablet"]}>
                                <Flex bg="white" p="16px" justifyContent="center" flexGrow={1}>
                                    <Image
                                        alt="uk-heart-age-overview.svg"
                                        src={`${PORTAL_IMAGE}/premium/heartAge/uk-heart-age-overview.svg`}
                                    />
                                </Flex>
                            </MediaQuery>
                        </>
                    ) : (
                        <OverviewCardUI {...METRIC_TEXT_SPECS.TEN_YEAR_CVD_RISK}>
                            <DataCell
                                units="%"
                                age={tenYearCvdRisk}
                                gap={["4px", "20px", "0px"]}
                                data-testid="cell-heart-stroke"
                            />
                        </OverviewCardUI>
                    )}
                </CardStack>

                <CardStack gap={["8px", "16px", "32px"]}>
                    <Flex flexGrow={1} flexBasis="210px">
                        <InformationModal heading={t(HEART_AGE.MODAL.HEART_AGE_INFO.HEADING)}>
                            {HEART_AGE.MODAL.HEART_AGE_INFO.CONTENT(t)}
                        </InformationModal>
                    </Flex>
                    {!isCountryUK && (
                        <Flex flexGrow={1} flexBasis="210px">
                            <InformationModal heading={t(HEART_AGE.MODAL.HEART_STROKE.HEADING)}>
                                {HEART_AGE.MODAL.HEART_STROKE.CONTENT(t)}
                            </InformationModal>
                        </Flex>
                    )}
                </CardStack>
            </Flex>
        )
    );
};
