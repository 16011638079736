import React from "react";
import styled, { withTheme } from "styled-components";
import { FlexBase } from "../Cards";
import PropTypes from "prop-types";
import ResponsiveImage from "../ResponsiveImage";

const StyledImage = styled(ResponsiveImage)`
    border-radius: ${props => props.theme.defaults.borderRadius};
`;

const PortalFooterBanner = ({ schema: { background, src, alt }, theme: { colours = {} } }) => {
    const backgroundColour = background ? background : colours.flat.white.hex;
    return (
        <FlexBase background={backgroundColour} minHeight="auto">
            <StyledImage src={src} alt={alt} />
        </FlexBase>
    );
};

PortalFooterBanner.propTypes = {
    theme: PropTypes.object.isRequired,
    schema: PropTypes.object.isRequired
};

export default withTheme(PortalFooterBanner);
