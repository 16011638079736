import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField } from "../";
import { Flex, Box } from "rebass";
import { ErrorText } from "../";

const DateOfBirth = ({ onChange, value, errors }) => {
    const [defaultYear, defaultMonth, defaultDay] =
        typeof value == "string" && value.includes("-") ? value.split("-") : [];

    const [day, setDay] = useState(defaultDay);
    const [month, setMonth] = useState(defaultMonth);
    const [year, setYear] = useState(defaultYear);

    const monthRef = React.createRef();
    const yearRef = React.createRef();

    const boxWidth = [1, 1 / 3];
    const boxPaddingR = [0, "1em"];

    function onDateChange() {
        if (typeof onChange != "function") {
            return;
        }

        if (day && month && year) {
            onChange(`${year}-${month}-${day}`);
        } else {
            onChange(null);
        }
    }

    function onDayChange(event) {
        const value = event.target.value;
        setDay(value);

        if (value.length == 2) {
            monthRef.current.focus();
        }
    }

    function onMonthChange(event) {
        const value = event.target.value;
        setMonth(value);

        if (value.length == 2) {
            yearRef.current.focus();
        }
    }

    function onYearChange(event) {
        const value = event.target.value;
        setYear(value);
    }

    useEffect(() => {
        onDateChange();
    }, [day, month, year]);

    return (
        <Flex flexWrap="wrap">
            <Box width={boxWidth} pr={boxPaddingR}>
                <TextField fieldId="day" name="day" placeholder="DD" value={day} onChange={onDayChange} />
            </Box>
            <Box width={boxWidth} pr={boxPaddingR}>
                <TextField
                    fieldId="month"
                    name="month"
                    placeholder="MM"
                    value={month}
                    onChange={onMonthChange}
                    ref={monthRef}
                />
            </Box>
            <Box width={boxWidth}>
                <TextField
                    fieldId="year"
                    name="year"
                    placeholder="YYYY"
                    value={year}
                    onChange={onYearChange}
                    ref={yearRef}
                />
            </Box>
            {errors && <ErrorText>{errors}</ErrorText>}
        </Flex>
    );
};

DateOfBirth.propTypes = {
    value: PropTypes.string,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onChange: PropTypes.func
};

export default DateOfBirth;
