import BaseEndPoint from "../BaseEndPoint";

export default class Auth extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/user" + path);
    }

    /**
     * Updates the user's profile with provided information
     *
     * @param {("metric" | "imperial" | undefined)} unitMeasurement
     * @param {("kilojoules" | "kilocalories" | undefined)} energyUnit
     * @return {Promise<Response>}
     */
    updateSettings({ unitMeasurement, energyUnit }) {
        return this.patch("/settings", { unitMeasurement, energyUnit });
    }

    /**
     * Retrieve All user accepted consents
     *
     * @param {string} token Auth Token
     * @return {Promise<Response>}
     */
    getUserAcceptedConsents(token = "") {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        if (token) settings.headers["Authorization"] = "Bearer " + token;

        return this._send(this.getUrlPath("/consents"), settings, !!token);
    }

    /**
     * update user consents
     *
     * @param {{ consents: Array, source: string }} data Auth Token
     * @param {string} token Auth Token
     * @return {Promise<Response>}
     */
    updateUserConsents(data, token = "") {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "POST",
            data: JSON.stringify(data)
        };

        if (token) settings.headers["Authorization"] = "Bearer " + token;

        return this._send(this.getUrlPath("/consents"), settings, !!token);
    }
}
