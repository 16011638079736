import styled from "styled-components";

const StyledInput = styled.input.attrs(props => ({
    autoFocus: props.autoFocus
}))`
    background-color: transparent;
    border: 0;
    color: ${props => props.theme.colours.flat.darkGray.hex};
    font-size: ${props => props.theme.text.heading4.sizeRem};
    font-weight: normal;
    text-align: ${props => (props.centreText ? "center" : "left")};
    width: ${props => (props.width ? props.width : "100%")};
    margin: 1em 0;
    padding: 5px 2px;
    border-bottom: 2px solid
        ${props => (props.hasErrors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.gray.hex)};

    :focus {
        border-bottom: 2px solid
            ${props => (props.hasErrors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.blue.hex)};
        outline: none;
        color: ${props => props.theme.colours.flat.darkGray.hex};
    }

    ::placeholder {
        color: ${props => props.theme.colours.flat.gray.hex};
    }
`;

export default StyledInput;
