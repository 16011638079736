import React, { useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { QuestionChoice } from "./styled";
import { useTranslation } from "react-i18next";

const Label = styled.span`
    font-size: 30px;
`;

const HelpText = styled.span`
    font-size: 20px;
`;

const Spacer = () => <span> </span>;

const Choice = ({ choice: { label, helpText }, isSelected = false, onSelect = () => {}, onDeselect = () => {} }) => {
    const { t, i18n } = useTranslation();

    const onClick = useCallback(
        async e => {
            e.preventDefault();

            const newI18n = i18n.cloneInstance({});
            await newI18n.changeLanguage("en-GB");

            const englishLabel = newI18n.t(label);

            if (isSelected) {
                onDeselect(englishLabel);
            } else {
                onSelect(englishLabel);
            }
        },
        [label, onSelect, onDeselect]
    );

    return (
        <QuestionChoice isSelected={isSelected} onClick={onClick}>
            <Label>{t(label)}</Label>
            <Spacer />
            <HelpText>{t(helpText)}</HelpText>
        </QuestionChoice>
    );
};

Choice.propTypes = {
    choice: PropTypes.object,
    isSelected: PropTypes.bool,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func
};

export default Choice;
