import moment from "moment";
import BaseValidator from "./BaseValidator";
import DateConverter from "../Converters/DateConverter";

/**
 * The Date Validator parses according to ISO 8601
 * @see https://en.wikipedia.org/wiki/ISO_8601
 */
export default class DateValidator extends BaseValidator {
    /**
     *
     * @return {{format: string}}
     * @private
     */
    _getDefaultOptions() {
        return {
            format: "YYYY-MM-DD",
            minDate: null,
            maxDate: null,
            minYearsAgo: null,
            minYearsAgoErrorMessage: null,
            maxYearsAgo: null,
            maxYearsAgoErrorMessage: null
        };
    }

    /**
     * Is the provided string a valid date?
     * Expecting a date in the format YYYY-MM-DD
     *
     * @param {string} value
     * @returns {string[]}
     */
    validate(value) {
        let errors = [];

        if (!moment(value, this._options.format, true).isValid()) {
            errors.push(`${value} is not a valid date (expecting ${this._options.format} )`);
        }

        if (
            this._options.minDate &&
            moment(this._options.minDate, this._options.format, true).diff(
                moment(value, this._options.format, true),
                "days",
                false
            ) > 0
        ) {
            errors.push(`${value} is not a valid date (must be greater than ${this._options.minDate})`);
        }

        if (
            this._options.maxDate &&
            moment(value, this._options.format, true).diff(
                moment(this._options.maxDate, this._options.format, true),
                "days",
                false
            ) > 0
        ) {
            errors.push(`${value} is not a valid date (must be less than ${this._options.maxDate})`);
        }

        // min and max years ago validation
        if (this._options.minYearsAgo || this._options.maxYearsAgo) {
            const dateToYears = DateConverter.calculateYearsDifference(
                moment(value, this._options.format, true),
                moment()
            );

            // min years ago
            if (this._options.minYearsAgo && dateToYears < this._options.minYearsAgo) {
                if (this._options.minYearsAgoErrorMessage) {
                    errors.push(this._options.minYearsAgoErrorMessage);
                } else {
                    errors.push(
                        `${value} is not a valid date (must be at least ${this._options.maxYearsAgo} years ago)`
                    );
                }
            }

            // max years ago
            if (this._options.maxYearsAgo && dateToYears > this._options.maxYearsAgo) {
                if (this._options.maxYearsAgoErrorMessage) {
                    errors.push(this._options.maxYearsAgoErrorMessage);
                } else {
                    errors.push(
                        `${value} is not a valid date (must be less than ${this._options.maxYearsAgo} years ago)`
                    );
                }
            }
        }

        return [...new Set(errors)];
    }
}
