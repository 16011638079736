import { mediaQueryForCss, mqCssForIntermediateScreens } from "utilities/mediaQuery";

import styled from "styled-components";

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 1140px;
    box-sizing: border-box;
    ${mediaQueryForCss({
        "box-sizing": ["border-box"],
        width: ["100%", "100%", "100%"],
        "margin-top": ["120px", "120px", "120px", "90px"]
    })}
`;

export const MenuHeading = styled.p`
    margin: 0;
    cursor: pointer;
    font-family: ${props => props.theme.fontFamily.openSans};
    font-size: 14px;
    color: ${props => props.color};
    font-weight: ${props => (props.active ? props.theme.fontWeight.semiBold : 400)};
`;

export const HeaderText = styled.p`
    font-size: ${props => props.theme.fontSize[40].px};
    font-family: ${props => props.theme.fontFamily.montserrat};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colours.flat.primaryBlack.hex};
    line-height: 1.5;
    ${mediaQueryForCss({
        width: ["100%"],
        "font-size": ["40px", "36px", "34px"],
        "font-weight": ["bold", "600"],
        "line-height": ["", "", "1.15"],
        "word-spacing": ["", "", "-0.8px"]
    })}
`;

export const PageHeaderText = styled.p`
    font-size: ${props => props.theme.fontSize[50].px};
    font-family: ${props => props.theme.fontFamily.montserrat};
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: black;
    ${props =>
        mediaQueryForCss({
            "font-size": ["40px", "35px", "28px", "28px"],
            ...props.mediaQuery
        })}
`;

export const GreyText = styled.p`
    font-family: ${props => props.theme.fontFamily.openSans};
    color: ${props => props.theme.colours.blackTints700.hex};
    font-size: 20px;
    font-weight: 300;
    line-height: 1.4;
    margin-bottom: 60px;
    ${mediaQueryForCss({
        "font-size": ["19px", "18px", "17px", "17px"],
        "text-align": ["left"],
        "margin-bottom": ["", "", "40px"]
    })}
`;

export const ErrorText = styled.p`
    font-family: ${props => props.theme.fontFamily.montserrat};
    font-size: 22px;
    font-weight: ${props => props.theme.fontWeight.semiBold};
    color: ${props => props.theme.colours.flat.primaryRed.hex};
    ${mediaQueryForCss({
        "font-size": ["22px", "20px", "15px", "15px"],
        "margin-top": [0, 0, 0, "30px"]
    })}
`;

export const SmallText = styled.p`
    color: ${props => props.theme.colours.flat.blackTint.hex};
    margin: ${props => props.theme.spaces[10]}px 0;
    font-family: ${props => props.theme.fontFamily.openSans};
    font-size: 11px;
    line-height: 1.18;
    margin-top: 21px;
    ${mediaQueryForCss({
        "text-align": ["left"],
        width: ["", "", "345px"],
        "margin-top": [`${16 - 55}px`, "", "16px"]
    })}
`;

export const BaseContainer = styled.div`
    height: 100%;
    width: 100%;
    margin-bottom: 400px;
    margin-top: 260px;
    ${mediaQueryForCss({
        "margin-top": ["120px", "120px", "120px", "90px"]
    })}
    ${mqCssForIntermediateScreens({
        width: ["100%"]
    })}
`;

export const SpaceDiv = styled.div`
    width: 100%;
    height: 250px;
    ${props =>
        mediaQueryForCss({
            height: ["", "", "130px !important"],
            ...props.mediaQuery
        })}
`;

export const styleSearchbar = props => `
    width: 535px;
    height: 45px;
    border-radius: 5px;
    background-color: white;
    margin-top: 60px;
    input {
        font-size: 15px;
        &::placeholder {
            color: ${props.theme.colours.flat.dustyBlue.hex}
        }    
    }
    ${mediaQueryForCss({
        width: ["60%", "80%", "90%", "345px"],
        margin: ["55px 0px", "50px 0px", "20px 15px 0px -2px"]
    })}          
`;

export const Margin = styled.div`
    width: ${props => (props.width ? props.width[0] : "100%")};
    margin-left: ${props => (props.mLeft ? props.mLeft[0] : "0px")};
    margin-right: ${props => (props.mRight ? props.mRight[0] : "0px")};
    margin-top: ${props => (props.mTop ? props.mTop[0] : "0px")};
    margin-bottom: ${props => (props.mBottom ? props.mBottom[0] : "0px")};
    text-align: inherit;
    box-sizing: border-box;

    ${props =>
        mediaQueryForCss({
            width: props.width ? props.width.slice(1) : [""],
            "margin-left": props.mLeft ? props.mLeft.slice(1) : [""],
            "margin-right": props.mRight ? props.mRight.slice(1) : [""],
            "margin-top": props.mTop ? props.mTop.slice(1) : [""],
            "margin-bottom": props.mBottom ? props.mBottom.slice(1) : [""]
        })}
`;

export const ButtonMedium = styled.button`
    border: 0;
    height: 50px;
    color: white;
    padding: 0 40px;
    font-weight: 600;
    line-height: 50px;
    border-radius: 5px;
    box-shadow: ${props => props.theme.portalShadows.card1};
    width: ${props => (props.width ? props.width : "null")};
    font-family: ${props => props.theme.fontFamily.openSans};
    font-size: ${props => (props.size ? props.size : "16px")};
    background-color: ${({ theme, disabled }) =>
        disabled ? theme.colours.greyTints.hex : theme.colours.blueTintsDusty.hex};

    ${props =>
        mediaQueryForCss({
            padding: ["0 15px"],
            width: [props.mWidth]
        })}
`;

export const TopMobileSpaceDiv = styled.div`
    width: 100%;
    height: 239px;
    ${mediaQueryForCss({ height: ["10px", "10px"] })}
`;
