import React, { Component } from "react";
import { Link } from "react-router-dom";
import APIClient from "../../utilities/APIClient";
import { Cards } from "@sisuwellness/web-components";
import BaseMemberViewWithMenu from "../../components/BaseMemberViewWithMenu";
import LoadingView from "components/LoadingView";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

class UserJourneysList extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            items: []
        };

        this.handleComplete = this.handleComplete.bind(this);
    }

    componentDidMount() {
        APIClient.userJourneys
            .get()
            .then(response => {
                if (response.succeeded()) {
                    return response.body();
                } else {
                    this.setState({ items: [] });
                }

                return false;
            })
            .then(body => {
                if (body) {
                    this.setState({ items: body });
                }
            });
    }

    handleComplete() {
        this.setState({ complete: true });
    }

    render() {
        const t = this.props.t;

        let component = null;
        if (this.state.items.length > 0) {
            component = this.state.items.map(userJourney => (
                <Link key={userJourney.userJourneyId} to={"/journeys/" + userJourney.userJourneyId}>
                    <Cards thin={true}>{userJourney.name}</Cards>
                </Link>
            ));
        } else {
            component = <LoadingView />;
        }

        return (
            <BaseMemberViewWithMenu>
                <h3>{t("words.user_journeys")}</h3>
                {component}
            </BaseMemberViewWithMenu>
        );
    }
}

export default withTranslation()(UserJourneysList);
