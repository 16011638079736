import { keyframes } from "styled-components";

const RotateIndefinite = keyframes`
    from {
      transform: rotate(0deg);
    }
    
    to {
      transform: rotate(360deg);
    }
`;

const HeartBeat = keyframes`
    0% {
        transform: scale(1.0);
    }
    12% {
        transform: scale(0.87);
    }
    20% {
        transform: scale(1.0);
    }
    30% {
        transform: scale(0.93);
    }
    50% {
        transform: scale(1.0);
    }    
`;

export { RotateIndefinite, HeartBeat };
