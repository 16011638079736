import {
    BODY_FAT,
    BODY_MASS_INDEX,
    WEIGHT,
    HEIGHT,
    BLOOD_PRESSURE,
    HEART_RATE,
    DIABETES,
    STRESS_PSS4,
    ACTIVITY,
    ALCOHOL_RISK,
    SMOKING,
    HEART_AGE,
    TDEE,
    BODY_COMPOSITION,
    MUSCLE_MASS
} from "./trend-charts";

// Note: New Metric support should follow same object structure
const FACTS = {
    [BODY_MASS_INDEX]: {
        title: "members_portal_web.constants.facts.bmi.title",
        data: [
            {
                heading: "members_portal_web.constants.facts.bmi.explained.heading",
                content: "members_portal_web.constants.facts.bmi.explained.content"
            },
            {
                heading: "members_portal_web.constants.facts.bmi.calculated.heading",
                content: "members_portal_web.constants.facts.bmi.calculated.content"
            },
            {
                heading: "members_portal_web.constants.facts.bmi.relativity.heading",
                content: "members_portal_web.constants.facts.bmi.relativity.content"
            }
        ]
    },
    [BODY_FAT]: {
        title: "members_portal_web.constants.facts.body_fat.title",
        data: [
            {
                heading: "members_portal_web.constants.facts.body_fat.measurements.heading",
                content: "members_portal_web.constants.facts.body_fat.measurements.content"
            },
            {
                heading: "members_portal_web.constants.facts.body_fat.limitations.heading",
                content: "members_portal_web.constants.facts.body_fat.limitations.content"
            },
            {
                heading: "members_portal_web.constants.facts.body_fat.explained.heading",
                content: "members_portal_web.constants.facts.body_fat.explained.content"
            }
        ]
    },
    [WEIGHT]: {
        title: "members_portal_web.constants.facts.weight.title",
        data: [
            {
                heading: "members_portal_web.constants.facts.weight.cause.heading",
                content: "members_portal_web.constants.facts.weight.cause.content"
            }
        ]
    },
    [HEIGHT]: {
        title: "members_portal_web.constants.facts.height.title",
        data: [
            {
                heading: "members_portal_web.constants.facts.height.loss_normal.heading",
                content: "members_portal_web.constants.facts.height.loss_normal.content"
            },
            {
                heading: "members_portal_web.constants.facts.height.loss_problem.heading",
                content: "members_portal_web.constants.facts.height.loss_problem.content"
            }
        ]
    },
    [BLOOD_PRESSURE]: {
        title: "Blood Pressure",
        data: [
            {
                heading: "members_portal_web.constants.facts.blood_pressure.explained.heading",
                content: "members_portal_web.constants.facts.blood_pressure.explained.content"
            },
            {
                heading: "members_portal_web.constants.facts.blood_pressure.causes.heading",
                content: "members_portal_web.constants.facts.blood_pressure.causes.content"
            },
            {
                heading: "members_portal_web.constants.facts.blood_pressure.age_related.heading",
                content: "members_portal_web.constants.facts.blood_pressure.age_related.content"
            },
            {
                heading: "members_portal_web.constants.facts.blood_pressure.check_ups.heading",
                content: "members_portal_web.constants.facts.blood_pressure.check_ups.content"
            }
        ]
    },
    [HEART_RATE]: {
        title: "Heart Rate",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.explained.heading",
                content: "members_portal_web.constants.facts.heart_rate.explained.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.influences.heading",
                content: "members_portal_web.constants.facts.heart_rate.influences.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.heart_attack.heading",
                content: "members_portal_web.constants.facts.heart_rate.heart_attack.content"
            }
        ]
    },
    [DIABETES]: {
        title: "Diabetes",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.diabetes.explained.heading",
                content: "members_portal_web.constants.facts.heart_rate.diabetes.explained.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.diabetes.score_key.heading",
                content: "members_portal_web.constants.facts.heart_rate.diabetes.explained.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.diabetes.risk_factors.heading",
                content: "members_portal_web.constants.facts.heart_rate.diabetes.risk_factors.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.diabetes.calculation.heading",
                content: "members_portal_web.constants.facts.heart_rate.diabetes.calculation.content"
            }
        ]
    },
    [STRESS_PSS4]: {
        title: "Perceived Stress",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.perceived_stress.calculation.heading",
                content: "members_portal_web.constants.facts.heart_rate.perceived_stress.calculation.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.perceived_stress.influences.heading",
                content: "members_portal_web.constants.facts.heart_rate.perceived_stress.influences.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.perceived_stress.impact.heading",
                content: "members_portal_web.constants.facts.heart_rate.perceived_stress.impact.content"
            }
        ]
    },
    [ACTIVITY]: {
        title: "Physical Activity",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.activity.recommendation.heading",
                content: "members_portal_web.constants.facts.heart_rate.activity.recommendation.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.activity.calculation.heading",
                content: "members_portal_web.constants.facts.heart_rate.activity.calculation.content"
            }
        ]
    },
    [ALCOHOL_RISK]: {
        title: "Alcohol Intake",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.alcohol_risk.risk_factors.heading",
                content: "members_portal_web.constants.facts.heart_rate.alcohol_risk.risk_factors.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.alcohol_risk.calculation.heading",
                content: "members_portal_web.constants.facts.heart_rate.alcohol_risk.calculation.content"
            }
        ]
    },
    [SMOKING]: {
        title: "Smoking",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.smoking.risk_factors.heading",
                content: "members_portal_web.constants.facts.heart_rate.smoking.risk_factors.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.smoking.mortality.heading",
                content: "members_portal_web.constants.facts.heart_rate.smoking.mortality.content"
            }
        ]
    },
    [HEART_AGE]: {
        title: "Heart Age",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.heart_age.expectations.heading",
                content: "members_portal_web.constants.facts.heart_rate.heart_age.expectations.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.heart_age.calculation.heading",
                content: "members_portal_web.constants.facts.heart_rate.heart_age.calculation.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.heart_age.improvement.heading",
                content: "members_portal_web.constants.facts.heart_rate.heart_age.improvement.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.heart_age.medication_awareness.heading",
                content: "members_portal_web.constants.facts.heart_rate.heart_age.medication_awareness.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.heart_age.indicative_meaning.heading",
                content: "members_portal_web.constants.facts.heart_rate.heart_age.indicative_meaning.content"
            }
        ]
    },
    [TDEE]: {
        title: "Daily Energy Use",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.tdee.calculation.heading",
                content: "members_portal_web.constants.facts.heart_rate.tdee.calculation.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.tdee.expectations.heading",
                content: "members_portal_web.constants.facts.heart_rate.tdee.expectations.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.tdee.optimise.heading",
                content: "members_portal_web.constants.facts.heart_rate.tdee.optimise.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.tdee.accuracy.heading",
                content: "members_portal_web.constants.facts.heart_rate.tdee.accuracy.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.tdee.unit_difference.heading",
                content: "members_portal_web.constants.facts.heart_rate.tdee.unit_difference.content"
            }
        ]
    },
    [BODY_COMPOSITION]: {
        title: "What is body composition?",
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.body_composition.explained.heading",
                content: "members_portal_web.constants.facts.heart_rate.body_composition.explained.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.body_composition.factors.heading",
                content: "members_portal_web.constants.facts.heart_rate.body_composition.factors.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.body_composition.measurement.heading",
                content: "members_portal_web.constants.facts.heart_rate.body_composition.measurement.content"
            }
        ]
    },
    [MUSCLE_MASS]: {
        data: [
            {
                heading: "members_portal_web.constants.facts.heart_rate.muscle_mass.resistance_training.heading",
                content: "members_portal_web.constants.facts.heart_rate.muscle_mass.resistance_training.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.muscle_mass.resistance_recommendation.heading",
                content: "members_portal_web.constants.facts.heart_rate.muscle_mass.resistance_recommendation.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.muscle_mass.low_mass.heading",
                content: "members_portal_web.constants.facts.heart_rate.muscle_mass.low_mass.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.muscle_mass.importance_for_women.heading",
                content: "members_portal_web.constants.facts.heart_rate.muscle_mass.importance_for_women.content"
            },
            {
                heading: "members_portal_web.constants.facts.heart_rate.muscle_mass.diet_affect.heading",
                content: "members_portal_web.constants.facts.heart_rate.muscle_mass.diet_affect.content"
            }
        ]
    }
};

export default FACTS;
