import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { isEmpty } from "lodash-es";

// ------------------------------------------------------------------------

import Icons from "components/Icons";

import { ErrorText } from "../InputField/styled-components";
import { SpinnerContainer, SpinnerOptions, Container } from "./styled-components";

// ------------------------------------------------------------------------

const Spinner = ({
    children,
    data,
    tag,
    defaultValue,
    selectCallback,
    placeholder,
    error,
    fontFamily,
    addComponent,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState("");

    useEffect(() => {
        if (defaultValue[tag]) {
            setValue(defaultValue[tag]);
        } else {
            placeholder && setValue(placeholder);
        }
    }, [defaultValue]);

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => document.removeEventListener("click", handleDocumentClick);
    });

    const handleDocumentClick = () => {
        if (isOpen) setIsOpen(false);
    };

    const handleOnClickItem = (event, item, index) => {
        setValue(item);
        setIsOpen(false);
        selectCallback && selectCallback(tag, item, index);
    };

    const isGreaterThanOne = !isEmpty(data) && data.length > 0;

    return (
        <Container width={["100%"]} fontFamily={fontFamily ? fontFamily : "Open Sans"}>
            <SpinnerContainer
                open={isOpen}
                showCursor={isGreaterThanOne}
                data-testid="spinner-container"
                error={error}
                label={children ? children : ""}
                {...rest}
            >
                {addComponent ? addComponent : <></>}
                <div
                    className="spinner-box"
                    onClick={() => isGreaterThanOne && setIsOpen(!isOpen)}
                    data-testid="spinner-box"
                >
                    <div className="spinner-value" data-testid="spinner-value">
                        {value}
                    </div>
                    {isGreaterThanOne && (
                        <Icons className="spinner-chevron" stroke="#B3B3B3" data-testid="chevron-down" chevron />
                    )}
                    {isOpen && isGreaterThanOne && (
                        <SpinnerOptions className="spinner-opt-container" length={data.length}>
                            {data.map((item, index) => (
                                <div
                                    className={`spinner-item ${data.length - 1 === index ? "no-seperator" : ""}`}
                                    onClick={event => handleOnClickItem(event, item, index)}
                                    key={index}
                                    data-testid="spinner-item"
                                >
                                    {item}
                                </div>
                            ))}
                        </SpinnerOptions>
                    )}
                </div>
            </SpinnerContainer>
            {typeof error === "string" && <ErrorText>{error}</ErrorText>}
        </Container>
    );
};

Spinner.propTypes = {
    label: PropTypes.string,
    data: PropTypes.array,
    tag: PropTypes.string,
    defaultValue: PropTypes.object,
    selectCallback: PropTypes.func,
    children: PropTypes.any,
    placeholder: PropTypes.string,
    fontFamily: PropTypes.string,
    addComponent: PropTypes.any,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

export default Spinner;
