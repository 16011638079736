import InvalidArgumentError from "../../Errors/InvalidArgumentError";

export default class SiSU {
    /**
     * Constructor
     */
    constructor(bmi) {
        this._validateBMIValue(bmi);
        this._bmi = bmi;
    }

    /**
     * Returns the given BMI value
     * @returns {Number}
     */
    get() {
        return this._bmi;
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get RiskName() {
        return "BODY_MASS_INDEX";
    }

    // FIXME: Should this be the concern of the Guideline? Might be better suited to the Risk model
    static get GuidelineName() {
        return "SISU_BMI";
    }

    /**
     * Validates the given BMI value. It is intended to be a positive number
     *
     * @returns {boolean}
     * @private
     */
    _validateBMIValue(bmi) {
        if (!(typeof bmi === "number") || Number.isNaN(bmi) || bmi < 0) {
            throw new InvalidArgumentError("BMI must be a valid number greater than or equal to 0");
        }

        return true;
    }

    /**
     * Returns an object representing various data for each BMI range
     *
     * @returns {object}
     */
    static get ranges() {
        return {
            low: {
                key: "low",
                label: "ranges.low",
                range: "< 18.5"
            },

            normal: {
                key: "normal",
                label: "ranges.normal",
                range: "18.5 - 24.9"
            },

            elevated: {
                key: "elevated",
                label: "ranges.elevated",
                range: "25 - 29.9"
            },

            high: {
                key: "high",
                label: "ranges.high",
                range: "30 - 34.9"
            },

            veryHigh: {
                key: "veryHigh",
                label: "ranges.very_high",
                range: "35+"
            }
        };
    }

    /**
     * Returns if the given BMI is in the 'low' range
     *
     * @returns {boolean}
     */
    isLow() {
        return this._bmi < 18.5;
    }

    /**
     * Returns if the given BMI is in the 'normal' range
     *
     * @returns {boolean}
     */
    isNormal() {
        return this._bmi >= 18.5 && this._bmi < 25;
    }

    /**
     * Returns if the given BMI is in the 'elevated' range
     *
     * @returns {boolean}
     */
    isElevated() {
        return this._bmi >= 25 && this._bmi < 30;
    }

    /**
     * Returns if the given BMI is in the 'high' range
     *
     * @returns {boolean}
     */
    isHigh() {
        return this._bmi >= 30 && this._bmi < 35;
    }

    /**
     * Returns if the given BMI is in the 'very high' range
     *
     * @returns {boolean}
     */
    isVeryHigh() {
        return this._bmi >= 35;
    }

    /**
     * returns help information about the calculated range
     *
     * @returns {string}
     */
    get rangeInfo() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.range;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.range;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.range;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.range;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.range;
                break;
        }

        return info;
    }

    /**
     * returns key about the calculated range
     *
     * @returns {string}
     */
    get key() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.key;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.key;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.key;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.key;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.key;
                break;
        }

        return info;
    }

    /**
     * Returns label for the calculated range
     *
     * @returns {string}
     */
    get label() {
        let info = "";
        switch (true) {
            case this.isLow():
                info = SiSU.ranges.low.label;
                break;
            case this.isNormal():
                info = SiSU.ranges.normal.label;
                break;
            case this.isElevated():
                info = SiSU.ranges.elevated.label;
                break;
            case this.isHigh():
                info = SiSU.ranges.high.label;
                break;
            case this.isVeryHigh():
                info = SiSU.ranges.veryHigh.label;
                break;
        }

        return info;
    }

    /**
     * Calculates a risk level number between 0 - 1 for each rating
     *
     * @return {number}
     */
    get riskLevel() {
        let totalRiskLevel = 5,
            riskLevel = 0;
        switch (true) {
            case this.isLow():
                riskLevel = 1 / (totalRiskLevel + 1);
                break;
            case this.isNormal():
                riskLevel = 2 / (totalRiskLevel + 1);
                break;
            case this.isElevated():
                riskLevel = 3 / (totalRiskLevel + 1);
                break;
            case this.isHigh():
                riskLevel = 4 / (totalRiskLevel + 1);
                break;
            case this.isVeryHigh():
                riskLevel = 5 / (totalRiskLevel + 1);
                break;
        }

        return riskLevel;
    }
}
