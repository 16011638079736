import React, { useState } from "react";
import PropTypes from "prop-types";

import { useContext } from "react";
import { Box, Flex, Text } from "rebass";
import { Collapse } from "react-bootstrap";
import styled, { ThemeContext } from "styled-components";

//------------------------------------------------------------------------------

import { Icon } from "@sisuwellness/web-components";
import { mediaQueryForCss } from "utilities/mediaQuery";
import Badge from "@sisuwellness/ui/src/components/Badge";
import { BannerCard } from "@sisuwellness/ui/src/components/Cards";
import { useTranslation } from "react-i18next";

//------------------------------------------------------------------------------

const StyledText = styled(Text)`
    p {
        font-size: 18px;
        ${mediaQueryForCss({
            "font-size": ["", "", "13px"]
        })}
        a {
            font-size: 18px;
            color: ${props => props.theme.colours.flat.blackTint.hex};
            ${mediaQueryForCss({
                "font-size": ["", "", "13px"]
            })}
        }
    }

    strong {
        font-size: 18px;
        ${mediaQueryForCss({
            "font-size": ["", "", "13px"]
        })}
    }
`;
const CollapsableCard = ({ heading, content, ...rest }) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);

    const [open, isOpen] = useState(false);

    return (
        <Box {...rest}>
            <BannerCard
                height="90px"
                width="100%"
                p="unset"
                pl={["8px", "30px"]}
                pr={["8px", "48px"]}
                bg={theme.portalColor.hpCardBackground.hex}
            >
                <Flex
                    alignItems="center"
                    height="100%"
                    css={{ position: "relative", cursor: "pointer" }}
                    onClick={() => isOpen(!open)}
                >
                    <Text fontSize={["15px", "20px"]} color={theme.colours.blackTints700.hex} width={["283px", "100%"]}>
                        {t(heading)}
                    </Text>
                    <Badge
                        ml="auto"
                        justifyContent="center"
                        alignItems="center"
                        pt={!open && "3px"}
                        pb={open && "3px"}
                        data-testid="btn-dropdown"
                    >
                        <Icon size="24px" colour="white" type={theme.icons.types[open ? "upArrow" : "downArrow"]} />
                    </Badge>
                </Flex>
            </BannerCard>
            {content && (
                <Collapse in={open} unmountOnExit>
                    {/* NOTE: To avoid choppy animations, contents needs to be inside a node with no margin or padding */}
                    <div>
                        <StyledText
                            bg="white"
                            p="24px"
                            pr="48px"
                            fontSize={["13px", "18px"]}
                            fontWeight="300"
                            color={theme.colours.blackTints600.hex}
                        >
                            <div dangerouslySetInnerHTML={{ __html: t(content) }} />
                        </StyledText>
                    </div>
                </Collapse>
            )}
        </Box>
    );
};

CollapsableCard.propTypes = {
    ...Box.propTypes,
    heading: PropTypes.string,
    content: PropTypes.object
};

export default CollapsableCard;
