import { mediaQueryForCss } from "utilities/mediaQuery";

// --------------------------------------------------------------------

export const responsiveSelect = `
.spinner-box .spinner-chevron {
    ${mediaQueryForCss({
        "margin-right": ["10px"]
    })}
}

.spinner-box .spinner-value {
    ${mediaQueryForCss({
        "margin-left": ["10px"]
    })}
}

&::before {
    ${mediaQueryForCss({
        "font-size": ["14px"]
    })}
}
`;
