import { compose, pathOr, find, propSatisfies, includes } from "ramda";

export default class GooglePlaces {
    /**
     *
     * @param {{ description: string, matched_substrings: Array<{ length: number, offset: number }>, place_id: string, reference: string, structured_formatting: object, terms: Array<{ offset: number, value: string }>, types: Array<string> }} placesObject
     */
    constructor(placesObject) {
        this._places = placesObject;
    }

    /**
     * Get place id
     * @return {string}
     */
    getPlaceId() {
        return this._places.place_id;
    }

    /**
     * @private
     * @param {Array} addressComponent
     * @param {string} type component type
     * @param {string} name long_name | short_name
     * @return {string}
     */
    static getTypeOfAddress(addressComponent, type, name = "long_name") {
        return compose(
            pathOr("", [name]),
            find(propSatisfies(includes(type), "types"))
        )(addressComponent);
    }

    /**
     * Get all address fields from address component object
     * @param {object} addressComponent
     * @return {{ state: string, suburb: string, country: string, subpremise: string, postalCode: string, streetAddress: string  }}
     */
    static getAddressComponents(addressComponent) {
        const address = pathOr([], ["address_components"], addressComponent);

        return {
            suburb: GooglePlaces.getTypeOfAddress(address, "locality"),
            country: GooglePlaces.getTypeOfAddress(address, "country"),
            subpremise: GooglePlaces.getTypeOfAddress(address, "subpremise"),
            postalCode: GooglePlaces.getTypeOfAddress(address, "postal_code"),
            state: GooglePlaces.getTypeOfAddress(address, "administrative_area_level_1", "short_name"),
            streetAddress: `${GooglePlaces.getTypeOfAddress(address, "street_number")} ${GooglePlaces.getTypeOfAddress(
                address,
                "route"
            )}`
        };
    }
}
