import { Consent } from "@sisuwellness/utilities";

export const isSignUpFlow = ({ type = "" } = {}) => ({
    basic: !type,
    setup: type === "setup",
    registration: type === "register"
});

export const accpetConsents = user => {
    const tempConsent = [];
    for (let consent of user.consents) {
        const consentUtility = new Consent(consent);
        consentUtility.shownToUser = true;
        consentUtility.userAccepted = user.termsAndConditions;
        tempConsent.push(consentUtility.toJSON());
    }
    return tempConsent;
};
