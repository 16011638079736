import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { uniqBy, path } from "ramda";
import { Form, Formik } from "formik";
import { withTheme } from "styled-components";
import { Box, Card, Flex, Text } from "rebass";

// ------------------------------------------------------------------------

import {
    aus,
    uk,
    placeHolder,
    ColumnWrapper,
    Warning,
    RightColumn,
    GEO_LOCATION_STATE,
    StyledGrid,
    StyledSelect,
    customStyles,
    MapIcon,
    StyledCard
} from "./styled";
import { GMAPS_URL } from "constants/";
import MarkerClusterer from "@google/markerclusterer";
import { MediaQuery } from "@sisuwellness/web-components";
import { findClosestMarker } from "../../utilities/mapFunction/index";
import InputField from "components/InputField";
import getGoogleSuggestionsSetup from "utilities/googlePlacesSuggestions";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------------------

const FindAStation = ({ installations, region, theme }) => {
    const { t, i18n } = useTranslation();
    const [visibleInstallations, setVisibleInstallations] = useState([]);
    const [selectedInstallation, setSelectedInstallation] = useState(null);
    const [locationError, setLocationError] = useState(null);
    const [selectedCountry, setSelectedCountry] = useState(region);
    const [markers, setMarkers] = useState({});
    const [startNode, setStartNode] = useState();
    const [google, setGoogle] = useState();
    const [selectedCard, setSelectedCard] = useState(null);

    const map = useRef();
    const markerClusterer = useRef();
    const initialLocation = useRef();
    const mapRef = useRef();
    const inputRef = useRef();
    const directionsService = useRef();
    const directionsRenderer = useRef();
    const searchedLocation = useRef();
    const autoCompleteService = useRef(null);
    const placesService = useRef(null);
    const autoCompleteSessionToken = useRef(null);

    const suggestionsSetup = useRef(
        getGoogleSuggestionsSetup(autoCompleteService, placesService, autoCompleteSessionToken)
    );
    useEffect(() => {
        if (window.google) handleGoogleScript(window.google);
    }, [window.google]);

    const handleGoogleScript = suggestionsSetup.current.handleGoogleScript;

    const getPostalCode = suggestionsSetup.current.getPostalCode;

    const handleOnSuggestionsFetch = (searchValue, setResultsCallback) =>
        suggestionsSetup.current.handleOnSuggestionsFetch(searchValue, setResultsCallback, selectedCountry);

    const handleGetSuggestionValue = suggestionsSetup.current.handleGetSuggestionValue;

    const handleRenderSuggestion = suggestionsSetup.current.handleRenderSuggestion;

    /**
     * Attempts to determine and store the user's current location
     * On failure, store the failure reason to communicate back to the user
     */
    const getCurrentPosition = () => {
        /**
         * Sets the initial position with given lat/long, and triggers a map center change event
         * - falls back to a region-specific default (SiSU AU or UK HQ)
         *
         * @param {Number|null} latitude
         * @param {Number|null} longitude
         */
        const setInitialLocation = (latitude = null, longitude = null) => {
            if (latitude == null || longitude == null) {
                const region = selectedCountry || "AU";

                switch (region) {
                    case "GB":
                        latitude = 51.5291596;
                        longitude = -0.080698;
                        break;

                    case "AU":
                    default:
                        latitude = -37.9712371;
                        longitude = 144.4927027;
                        break;
                }
            }

            initialLocation.current = new window.google.maps.LatLng(latitude, longitude);
            onMapCenterChanged();
        };

        // Determine initial location
        if ("geolocation" in navigator) {
            const success = position => {
                setInitialLocation(position.coords.latitude, position.coords.longitude);
                setLocationError(null);
            };

            const error = error => {
                let locationError = null;
                switch (error.code) {
                    case error.PERMISSION_DENIED:
                        locationError = GEO_LOCATION_STATE.DENIED;
                        break;

                    default:
                        locationError = GEO_LOCATION_STATE.UNKNOWN;
                        break;
                }

                setInitialLocation();
                setLocationError(locationError);
            };

            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            // no geolocation available, use default position
            setInitialLocation();
        }
    };

    useEffect(() => {
        setGoogle(window.google);
        window.initialiseMapsCallback = initialiseMapsCallback;
        const googleMapsScript = document.createElement("script");
        googleMapsScript.id = "google-geometry";
        googleMapsScript.src = `${GMAPS_URL}&language=${i18n.language}`;
        document.head.appendChild(googleMapsScript);
    }, [window.google, i18n.language]);

    useEffect(() => {
        setSelectedCountry(region);
    }, [region]);

    /**
     * Handler for location search submission
     * @param event
     */
    const handleSubmit = event => {
        const searchTerm = event.postCode || "";
        const region = selectedCountry;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ address: searchTerm, region }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                searchedLocation.current = results[0];
                const data = findClosestMarker(
                    installations,
                    searchedLocation.current.geometry.location.lat(),
                    searchedLocation.current.geometry.location.lng()
                );
                fitBound(data);
                pathBetween(data);
            }
        });
    };

    const getUniqueAddresses = () => {
        return installations.every(installation => path(["address", "formattedAddress"], installation))
            ? uniqBy(installation => installation.address.formattedAddress, installations)
            : installations;
    };

    const fitBound = data => {
        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(new window.google.maps.LatLng(parseFloat(data.start.lat), parseFloat(data.start.lng)));
        bounds.extend(new window.google.maps.LatLng(parseFloat(data.dest.lat), parseFloat(data.dest.lng)));
        map.current.fitBounds(bounds);
        if (startNode && startNode.setMap) {
            startNode.setMap(null);
        }
        setStartNode(
            new window.google.maps.Marker({
                position: new window.google.maps.LatLng(parseFloat(data.start.lat), parseFloat(data.start.lng)),
                label: "A",
                map: map.current
            })
        );
    };

    const pathBetween = data => {
        const origin = new window.google.maps.LatLng(parseFloat(data.start.lat), parseFloat(data.start.lng));
        const destination = new window.google.maps.LatLng(parseFloat(data.dest.lat), parseFloat(data.dest.lng));
        directionsRenderer.current.setMap(map.current);
        directionsRenderer.current.setOptions({ suppressMarkers: true });
        directionsService.current = new window.google.maps.DirectionsService();
        directionsService.current.route(
            {
                origin: origin,
                destination: destination,
                travelMode: window.google.maps.TravelMode.DRIVING
            },
            (response, status) => {
                if (status === "OK") {
                    directionsRenderer.current.setDirections(response);
                }
            }
        );
    };

    const initialiseMapsCallback = () => {
        if (!window.google || !mapRef.current) {
            return;
        }
        map.current = new window.google.maps.Map(mapRef.current, { zoom: 16 });
        directionsRenderer.current = new window.google.maps.DirectionsRenderer();
        if (region != null) {
            locateToCountry(region);
        }
        initialiseMap(map.current);
    };
    const locateToCountry = code => {
        const placeId = getPlaceId(code);
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ placeId }, (results, status) => {
            if (status === window.google.maps.GeocoderStatus.OK) {
                map.current.setCenter(results[0].geometry.location);
                map.current.fitBounds(results[0].geometry.viewport);
            }
        });
    };

    /**
     * Callback function for window.google.Maps API
     * Called upon map initialisation
     */
    const initialiseMap = maps => {
        const bounds = new window.google.maps.LatLngBounds();
        const infoWindow = new window.google.maps.InfoWindow({
            maxWidth: 400
        });
        const clusterOptions = {
            styles: [
                {
                    height: 60,
                    url: window.PUBLIC_URL + "/images/google-maps-cluster.png",
                    width: 60,
                    textSize: 20
                }
            ]
        };
        markerClusterer.current = new MarkerClusterer(maps, [], clusterOptions);
        let tempObj = {};
        for (let installation of getUniqueAddresses()) {
            const position = {
                lat: installation.address.latitude,
                lng: installation.address.longitude
            };
            const directionsUrl =
                "https://www.google.com/maps/dir/?api=1&destination=" +
                encodeURI(installation.address.formattedAddress);

            let content = '<div style="padding: 10px;">';
            content =
                '<h2 style="font-family: Proxima Nova Regular, Arial, sans-serif; margin-top: 0;">' +
                installation.name +
                "</h2>";
            content +=
                '<h4 style="font-family: Proxima Nova Regular, Arial, sans-serif;">' +
                installation.address.formattedAddress +
                "</h4>";
            content +=
                '<div style="font-family: Proxima Nova Regular, Arial, sans-serif;"><a href="' +
                directionsUrl +
                '" target="_blank">Click here for Directions</a></div>';
            content += "</div>";

            const marker = new window.google.maps.Marker({
                position: position,
                icon: {
                    url: "/images/google-maps-pin-sh.svg",
                    scaledSize: new window.google.maps.Size(60, 60)
                },
                map: map.current
            });

            bounds.extend(marker.position);
            marker.addListener("click", () => {
                setSelectedInstallation(installation.healthStationInstallationId);
                infoWindow.setContent(content);
                maps.setZoom(14);
                maps.panTo(marker.position);
                infoWindow.open(maps, marker);
            });
            markerClusterer.current.addMarker(marker);
            tempObj[installation.healthStationInstallationId] = marker;
        }
        setMarkers(tempObj);
        maps.fitBounds(bounds);
        maps.addListener("bounds_changed", onMapCenterChanged);
        maps.addListener("center_changed", onMapCenterChanged);
        getCurrentPosition();
    };
    /**
     * Handler for clicking an installation marker
     * @param stationInstallationId
     */
    const onClickInstallationMarker = stationInstallationId => {
        const marker = markers[stationInstallationId];
        setSelectedCard(stationInstallationId);
        window.google.maps.event.trigger(marker, "click");
    };

    const onMapCenterChanged = () => {
        if (!map.current || !map.current.getBounds()) {
            return;
        }
        const bounds = map.current.getBounds();
        const visibleInstallations = [];
        for (let installation of getUniqueAddresses()) {
            const position = new window.google.maps.LatLng(
                installation.address.latitude,
                installation.address.longitude
            );
            if (bounds.contains(position)) {
                const visibleInstallation = {
                    id: installation.healthStationInstallationId,
                    name: installation.name,
                    address: installation.address.formattedAddress,
                    distance: 0
                };
                if (initialLocation.current && !searchedLocation.current) {
                    const latLong = new window.google.maps.LatLng(
                        installation.address.latitude,
                        installation.address.longitude
                    );
                    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                        latLong,
                        initialLocation.current
                    );
                    visibleInstallation.distance = distance;
                    visibleInstallation.distanceString = Number(distance / 1000).toFixed(2) + "km";
                } else if (searchedLocation.current) {
                    const latLongDest = new window.google.maps.LatLng(
                        installation.address.latitude,
                        installation.address.longitude
                    );
                    const latLongStart = new window.google.maps.LatLng(
                        searchedLocation.current.geometry.location.lat(),
                        searchedLocation.current.geometry.location.lng()
                    );
                    const distance = window.google.maps.geometry.spherical.computeDistanceBetween(
                        latLongStart,
                        latLongDest
                    );
                    visibleInstallation.distance = distance;
                    visibleInstallation.distanceString = Number(distance / 1000).toFixed(2) + "km";
                }
                visibleInstallations.push(visibleInstallation);
            }
        }

        // insertion sort based on distance
        for (let i = 0, j = 0; i < visibleInstallations.length; i++) {
            let installation = visibleInstallations[i];

            for (j = i - 1; j > -1 && visibleInstallations[j].distance > installation.distance; j--) {
                visibleInstallations[j + 1] = visibleInstallations[j];
            }

            visibleInstallations[j + 1] = installation;
        }

        setVisibleInstallations(visibleInstallations);
    };

    /**
     * Generates (and pads up to) the closest 3 results from all visible installations
     * @returns {Array}
     */
    const renderResults = () => {
        const results = [];
        for (let installation of visibleInstallations) {
            const classes = selectedInstallation === installation.id ? "selected" : "";
            const result = (
                <StyledCard
                    className={classes}
                    key={`installation-${installation.id}`}
                    bg={["#ebe9f0", "white", "white"]}
                    active={installation.id == selectedCard ? true : false}
                    onClick={() => onClickInstallationMarker(installation.id)}
                >
                    <Text
                        data-testid={`result-header-${installation.id}`}
                        fontSize="15px"
                        fontWeight="500"
                        color={theme.colours.blackTints700.hex}
                    >
                        {installation.name}
                    </Text>
                    <Text
                        data-testid={`result-address-${installation.id}`}
                        fontSize="11px"
                        fontWeight="normal"
                        color={theme.colours.blackTints700.hex}
                        opacity="0.5"
                        my="10px"
                    >
                        {installation.address}
                    </Text>
                    <Text
                        data-testid={`result-distance-${installation.id}`}
                        fontSize="11px"
                        fontWeight="normal"
                        color={theme.colours.blackTints700.hex}
                        opacity="0.5"
                    >
                        {installation.distanceString}
                    </Text>
                </StyledCard>
            );
            results.push(result);
            if (results.length === 2) {
                break;
            }
        }

        // pad the results list to ensure 2 results
        while (results.length < 2) {
            results.push(<Card key={"dummy-installation-" + (results.length - 1)} />);
        }

        return <Card mt={["24px", "0px"]}>{results}</Card>;
    };

    const renderWarning = () => {
        let warning = [];
        if (locationError) {
            warning.push(
                <p key={"unable-to-determine-location"}>
                    {t("members_portal_web.views.find_a_station.location_error.not_found")}
                </p>
            );

            // unknown geolocation state, offer retry
            if (locationError === GEO_LOCATION_STATE.UNKNOWN) {
                warning.push(
                    <p key={"try-enable-location"}>
                        <a onClick={getCurrentPosition}>
                            {t("members_portal_web.views.find_a_station.location_error.retry.button_label")}
                        </a>{" "}
                        {t("members_portal_web.views.find_a_station.location_error.retry.text")}
                    </p>
                );
                // denied geolocation state, suggest re-enable
            } else if (locationError === GEO_LOCATION_STATE.DENIED) {
                warning.push(
                    <p key={"please-check-location-service"}>
                        {t("members_portal_web.views.find_a_station.location_error.browser_text")}
                    </p>
                );
            }
        }

        return warning.length > 0 ? <Warning>{warning}</Warning> : null;
    };

    const getPlaceId = alpha2 => {
        switch (alpha2) {
            case "GB":
                return "ChIJqZHHQhE7WgIReiWIMkOg-MQ";
            case "AU":
            default:
                return "ChIJ38WHZwf9KysRUhNblaFnglM";
        }
    };

    useEffect(() => {
        map.current && selectedCountry && locateToCountry(selectedCountry);
    }, [selectedCountry]);

    const handleOnChange = e => {
        setSelectedCountry(e.value);
    };

    const availableStationCountries = [{ label: aus, value: "AU" }, { label: uk, value: "GB" }];
    return (
        <Card py={["0", "24px"]} bg="white" width="100%">
            <Text
                fontSize={["28px", "34px"]}
                color={theme.colours.hpPrimaryPurple.hex}
                fontWeight={theme.fontWeight.intermediate}
            >
                {t("members_portal_web.views.find_a_station.find")}
            </Text>
            <Text mt="16px" mb="32px" color={theme.colours.hpPrimaryPurple.hex} fontSize="15px" fontWeight="normal">
                {t("members_portal_web.views.find_a_station.tool_text")}
            </Text>
            {renderWarning()}
            <ColumnWrapper flexDirection={["column", "row"]}>
                <Flex
                    flexDirection="column"
                    mr={["0px", "40px"]}
                    width={["100%", "auto"]}
                    bg={theme.colours.hpPurple100.hex}
                    flex={["normal", "2 0", "1 0"]}
                    style={{ borderRadius: "10px" }}
                >
                    <MediaQuery devices={["tablet", "desktop"]}>
                        <Text
                            fontSize="17px"
                            fontWeight="500"
                            color={theme.colours.blackTints700.hex}
                            my="16px"
                            px={["13px", "24px"]}
                        >
                            {t("members_portal_web.views.find_a_station.find")}
                        </Text>
                        <Box
                            as="hr"
                            bg="#4d4d4d"
                            mb="16px"
                            css={{
                                border: 0,
                                height: "1px",
                                opacity: "0.1"
                            }}
                        />
                    </MediaQuery>
                    <Box width={"100%"} px={["13px", "24px"]} mt={["16px", "0"]}>
                        <StyledGrid flexDirection="column">
                            {!region && (
                                <StyledSelect
                                    name={"countryCode"}
                                    inputId={"countryCode"}
                                    isSearchable={true}
                                    options={availableStationCountries}
                                    placeholder={placeHolder}
                                    onChange={e => handleOnChange(e)}
                                    defaultValue={"AU"}
                                    styles={customStyles}
                                />
                            )}
                            <Formik initialValues={{ postCode: "" }} onSubmit={handleSubmit}>
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleBlur,
                                    handleSubmit,
                                    handleChange,
                                    setFieldValue
                                }) => {
                                    const Map = <MapIcon height="100%" stroke="grey" />;
                                    const handleOnInputChange = ({ newValue, method }) => {
                                        if (["click", "up", "down", "enter"].includes(method))
                                            handleValuesOnChangeMethod(newValue, method);
                                    };
                                    const handleValuesOnChangeMethod = (newValue, method) => {
                                        if (typeof newValue === "string") {
                                            return;
                                        }
                                        const arr = newValue.terms;
                                        let suburb = "",
                                            street = "",
                                            state = "";
                                        if (arr.length === 3) {
                                            street = arr[0].value;
                                            suburb = arr[0].value;
                                            state = arr[1].value;
                                        } else if (arr.length === 4) {
                                            street = arr[0].value;
                                            suburb = arr[1].value;
                                            state = arr[2].value;
                                        } else if (arr.length > 4) {
                                            state = arr[arr.length - 2].value;
                                            suburb = arr[arr.length - 3].value;
                                            street = arr
                                                .slice(0, arr.length - 3)
                                                .map(item => item.value)
                                                .join(", ");
                                        }

                                        getPostalCode(newValue.place_id, postalCode => {
                                            populateAddressFields(state, suburb, street, postalCode, method);
                                        });
                                    };

                                    const populateAddressFields = (state, suburb, street, postCode, method) => {
                                        const value = street + " " + suburb + " " + state + ", " + postCode;
                                        setFieldValue("postCode", value);
                                        if (method === "click") handleSubmit();
                                    };

                                    return (
                                        <Form onSubmit={handleSubmit} data-testid="search-form">
                                            <Box mb="16px">
                                                <InputField
                                                    className={"postcode"}
                                                    leftChild={Map}
                                                    customStyles={`
                                                        background: ${
                                                            selectedCountry ? "#ffffff" : theme.colours.hpGrey200.hex
                                                        };
                                                        border: none;
                                                    `}
                                                    inputProps={{
                                                        name: "postCode",
                                                        placeholder: t(
                                                            "members_portal_web.views.find_a_station.placeholder"
                                                        ),
                                                        "data-testid": "postCode",
                                                        onChange: (event, options) => {
                                                            handleChange(event);
                                                            handleOnInputChange(options);
                                                        },
                                                        value: values.postCode,
                                                        onBlur: handleBlur,
                                                        ref: inputRef,
                                                        id: "text-field-id-postcode",
                                                        disabled: selectedCountry == null
                                                    }}
                                                    fontFamily="GT Walsheim Pro"
                                                    autoSuggest={true}
                                                    handleOnSuggestionsFetch={handleOnSuggestionsFetch}
                                                    handleGetSuggestionValue={handleGetSuggestionValue}
                                                    handleRenderSuggestion={handleRenderSuggestion}
                                                    error={touched.postCode ? errors.postCode : ""}
                                                />
                                            </Box>
                                            <MediaQuery devices={["tablet", "desktop"]}>{renderResults()}</MediaQuery>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </StyledGrid>
                    </Box>
                </Flex>
                <RightColumn p="16px" bg={theme.colours.hpPurple100.hex}>
                    <Box
                        data-testid="google-map-container"
                        style={{ width: "100%", height: "100%", borderRadius: "10px" }}
                        ref={mapRef}
                    />
                </RightColumn>
            </ColumnWrapper>
            <MediaQuery devices={["mobile"]}>
                <Box mb="30px">{renderResults()}</Box>
            </MediaQuery>
        </Card>
    );
};

FindAStation.propTypes = {
    installations: PropTypes.arrayOf(PropTypes.object),
    theme: PropTypes.object,
    region: PropTypes.string
};

export default withTheme(FindAStation);
