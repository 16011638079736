import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";

// ------------------------------------------------------------------------

import Flex from "components/Flex";
import { HeadingLabel } from "@sisuwellness/ui/src/components/Labels";

// ------------------------------------------------------------------------

export const DataCell = ({ title, age, units = "Years", ...rest }) => (
    <Flex fd="column" gap="4px" jc="space-between" {...rest}>
        {title && (
            <HeadingLabel fontSize={["11px", "", "15px"]} lineHeight="1.33">
                {title}
            </HeadingLabel>
        )}
        <Flex fd={["column", "row"]} ai={["", "flex-end"]}>
            <HeadingLabel fontSize={["34px", "", "50px"]} fontWeight="intermediate" height={["32px", "", "45px"]}>
                {age}
            </HeadingLabel>
            <Text color="hpPurpleTints600" fontWeight="bold" fontSize="13px">
                {units}
            </Text>
        </Flex>
    </Flex>
);

DataCell.propTypes = {
    ...Flex.propTypes,
    title: PropTypes.string,
    age: PropTypes.number,
    units: PropTypes.string
};
