import React from "react";

//---------------------------------------------------------------------------------------------

import UnderstandingImpact from "components/Templates/Premium/UnderstandingImpact";
import { LOCKED_BODY_COMPOSITION } from "constants/constants.scripts";

//---------------------------------------------------------------------------------------------

const LockedMucleMass = () => <UnderstandingImpact metricType={LOCKED_BODY_COMPOSITION.UNDERSTANDING_IMPACT} />;

LockedMucleMass.propTypes = {};

export default LockedMucleMass;
