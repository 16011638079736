import codes from "../../UserJourney/UserJourneyMap";
import { pick } from "ramda";
const questionCodes = codes.surveys.covid19.questions;
const answers = pick(Object.keys(codes.answers).filter(key => key.startsWith("Covid19")), codes.answers);
const countries = answers.Covid19Country;
const AnswersMap = {
    [questionCodes.covid19Situation]: {
        [answers.Covid19Situation.socialDistancingWork]: {
            [countries.australia]: {
                title: "Your current situation",
                text: `<div>If you are still attending work, chances are you are one of the many essential services workers in our
        community. Thank you for your dedication, and please speak to your employer if you have any concerns about your
        health and safety during this time.</div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Your current situation",
                text: `<div>If you are still attending work, chances are you are one of the many essential services workers in our
        community. Thank you for your dedication, and please speak to your employer if you have any concerns about your
        health and safety during this time.</div>`,
                urls: []
            },
            [countries.other]: {
                title: "Your current situation",
                text: `<div>If you are still attending work, chances are you are one of the many essential services workers in our
        community. Thank you for your dedication, and please speak to your employer if you have any concerns about your
        health and safety during this time.</div>`,
                urls: []
            }
        },
        [answers.Covid19Situation.socialDistancingHome]: {
            [countries.australia]: {
                title: "Your current situation",
                text: `<div>Thank you for playing your part in helping minimise the spread of COVID-19 by practising social distancing.</div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Your current situation",
                text: `<div>Thank you for playing your part in helping minimise the spread of COVID-19 by practising social distancing.</div>`,
                urls: []
            },
            [countries.other]: {
                title: "Your current situation",
                text: `<div>Thank you for playing your part in helping minimise the spread of COVID-19 by practising social distancing.</div>`,
                urls: []
            }
        },
        [answers.Covid19Situation.governmentMandated]: {
            [countries.australia]: {
                title: "Your current situation",
                text: `<div>Some countries are enforcing isolation and some cases quarantine for people with no symptoms, such those
        returning from overseas. We understand this can be difficult, and may seem unfair, but these are necessary
        measures to slow the spread of COVID-19.</div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Your current situation",
                text: `<div>Some countries are enforcing isolation and in some cases quarantine for people with no symptoms, such those
        returning from overseas. We understand this can be difficult, and may seem unfair, but these are necessary
        measures to slow the spread of COVID-19.</div>`,
                urls: []
            },
            [countries.other]: {
                title: "Your current situation",
                text: `<div>Some countries are enforcing isolation and some cases quarantine for people with no symptoms, such those
        returning from overseas. We understand this can be difficult, and may seem unfair, but these are necessary
        measures to slow the spread of COVID-19.</div>`,
                urls: []
            }
        },
        [answers.Covid19Situation.selfIsolation]: {
            [countries.australia]: {
                title: "Your current situation",
                text: `<div class="tbc">We are sorry to hear that you or your family members are unwell. Even if you do not have 
        COVID-19, you are doing the right thing by staying home and not going out at all. We hope you feel better soon.</div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Your current situation",
                text: `<div class="tbc">We are sorry to hear that you or your family members are unwell. Even if you do not have 
        COVID-19, you are doing the right thing by staying home and not going out at all. We hope you feel better soon.</div>`,
                urls: []
            },
            [countries.other]: {
                title: "Your current situation",
                text: `<div>We are sorry to hear that you or your family members are unwell. Even if you do not have
        COVID-19, you are doing the right thing by staying home and not going out at all. We hope you all feel better soon.</div>`,
                urls: []
            }
        },
        [answers.Covid19Situation.quarantinedDueToDiagnoses]: {
            [countries.australia]: {
                title: "Your current situation",
                text: `<div class="tbc">We are sorry to hear that you are unwell with COVID-19, we hope you feel better soon.</div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Your current situation",
                text: `<div class="tbc">We are sorry to hear that you are unwell with COVID-19, we hope you feel better soon.</div>`,
                urls: []
            },
            [countries.other]: {
                title: "Your current situation",
                text: `<div>We are sorry to hear that you are unwell with COVID-19. You are doing the right thing by yourself, your
        family and your community. We hope you feel better soon.</div>`,
                urls: []
            }
        },
        [answers.Covid19Situation.usualRoutine]: {
            [countries.australia]: {
                title: "Your current situation",
                text: `<div>
        You indicated that you are currently following your usual routine.<br><br>

        It is imperative that everyone in Australia practises social distancing.<br><br>

        Stay at home
        <ul>
          <li>Only go outside for food, health reasons or work (but only if you cannot work from home)</li>
          <li>If you go out, stay 1.5 metres away from other people at all times</li>
          <li>Wash your hands as soon as you get home</li>
          <li>Do not meet others, even friends or family.</li>
          <li>if you are experiencing flu-like symptoms (fever, or a new continuous cough) you should not leave your
            home
            at all.</li>
        </ul>

        Visit <a
          href="https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19">health.gov.au</a>
        to slow the spread of COVID-19.
      </div>`,
                urls: [
                    "https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Your current situation",
                text: ` <div>
        You indicated that you are currently following your usual routine. <br><br>

        It is imperative that everyone follows the UK government guidance in order to slow the spread of
        COVID-19.<br><br>

        Stay at home
        <ul>

          <li>Only go outside for food, health reasons or work (but only if you cannot work from home)</li>
          <li>If you go out, stay 2 metres (6ft) away from other people at all times</li>
          <li>Wash your hands as soon as you get home</li>
          <li>Do not meet others, even friends or family.</li>
          <li>if you are experiencing flu-like symptoms (fever, or a new continuous cough) you should not leave your
            home
            at all.</li>
        </ul>

        For more information on what you can and cannot do visit <a
          href="https://www.gov.uk/government/publications/coronavirus-outbreak-faqs-what-you-can-and-cant-do/coronavirus-outbreak-faqs-what-you-can-and-cant-do">gov.uk</a>.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/coronavirus-outbreak-faqs-what-you-can-and-cant-do/coronavirus-outbreak-faqs-what-you-can-and-cant-do"
                ]
            },
            [countries.other]: {
                title: "Your current situation",
                text: `<div>
        You indicated that you are currently following your usual routine. <br><br>

        It is imperative that everyone follows these steps in order to slow the spread of COVID-19.<br><br>

        Stay at home
        <ul>
          <li>Only go outside for food, health reasons or work (but only if you cannot work from home)</li>
          <li>If you go out, stay 2 metres (6ft) away from other people at all times</li>
          <li>Wash your hands as soon as you get home</li>
          <li>Do not meet others, even friends or family.</li>
          <li>if you are experiencing flu-like symptoms (fever, or a new continuous cough) you should not leave your
            home
            at all.</li>
        </ul>
        Refer to your country's government health website for more further details.
      </div>`,
                urls: []
            }
        }
    },
    [questionCodes.covid19MainConcerns]: {
        [answers.Covid19MainConcerns.managingWorkFromHome]: {
            [countries.australia]: {
                title: "Concerns about working from home",
                text: `<div>
        Working from home presents a whole range of unique challenges for employees and employers.
        Visit <a href="https://www.worksafe.vic.gov.au/minimising-spread-coronavirus-covid-19-working-home">Worksafe
          Victoria</a> for more information on how to adjust to remote working.
      </div>`,
                urls: ["https://www.worksafe.vic.gov.au/minimising-spread-coronavirus-covid-19-working-home"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about working from home",
                text: `<div>
        Working from home presents a whole range of unique challenges for employees and employers.
        Visit <a href="https://www.acas.org.uk/working-from-home">Acas</a> for more information on how to adjust to
        remote working.
      </div>`,
                urls: ["https://www.acas.org.uk/working-from-home"]
            },
            [countries.other]: {
                title: "Concerns about working from home",
                text: `<div>
        Working from home presents a whole range of unique challenges for employees and employers.
        For employees, it is important to establish boundaries between work life and home life in order to preserve
        physical and mental health.
        Employers will also need to be understanding that their staff are simultaneously attempting to manage caring for
        children (as well as their educational needs) and their work tasks.
        Ensure you maintain regular communication with your line manager and be open about the challenges you are
        experiencing.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.worriedChildrenEducation]: {
            [countries.australia]: {
                title: "Concerns about children's education",
                text: `<div>
        At the time of writing (27 March 2020), most Australian state and territories have brought forward school
        holidays.
        As yet, no decisions have been made as to whether schools will be open or closed for Term 2. Many schools are
        making preparations for remote learning, in the meantime here is some helpful information for you to support
        your children.
        The following advice pertains to Victoria but contains helpful information for those in other states.
        <a href="https://www.education.vic.gov.au/parents/learning/Pages/home-learning.aspx">education.vic.gov.au</a>
      </div>`,
                urls: ["https://www.education.vic.gov.au/parents/learning/Pages/home-learning.aspx"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about children's education",
                text: `<div>
        At the time of writing (27 March 2020), schools, colleges and childcare providers will be closed to the majority
        of pupils until further notice, however some are encouraged to remain open for critial workers and vulnerable
        children.
        Please visit the <a
          href="https://www.gov.uk/government/publications/closure-of-educational-settings-information-for-parents-and-carers/closure-of-educational-settings-information-for-parents-and-carers">UK
          government page for continued updates</a>.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/closure-of-educational-settings-information-for-parents-and-carers/closure-of-educational-settings-information-for-parents-and-carers"
                ]
            },
            [countries.other]: {
                title: "Concerns about children's education",
                text: `<div>
        In many countries, schools have been closed to reduce the spread of COVID-19.
        If you have concerns about your child's education, please contact your school to learn more about preparations
        for remote learning.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.lostMyJob]: {
            [countries.australia]: {
                title: "Concerns about loss of job",
                text: `<div>
        We're sorry to hear you have lost your job. We understand this is an extremely stressful time.
        That said, the Federal Government, in close co-ordination with the states has announced a number of significant
        financial support programs - including augmentation of the JobSeeker allowance and the introduction of Jobkeeper
        Program. For more information about financial support from the government please visit
        <a href="https://www.servicesaustralia.gov.au/individuals/news/more-financial-support-people-affected-coronavirus">servicesaustralia.gov.au</a>.
      </div>`,
                urls: [
                    "https://www.servicesaustralia.gov.au/individuals/news/more-financial-support-people-affected-coronavirus"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about loss of job",
                text: `<div>
        We're sorry to hear you have lost your job. We understand this is an extremely stressful time.
        The UK Government has announced some significant financial support initiatives for both employees and employers
        and they can be viewed on <a
          href="https://www.gov.uk/government/publications/guidance-to-employers-and-businesses-about-covid-19/covid-19-guidance-for-employees">gov.uk</a>.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/guidance-to-employers-and-businesses-about-covid-19/covid-19-guidance-for-employees"
                ]
            },
            [countries.other]: {
                title: "Concerns about loss of job",
                text: `<div>
        We're sorry to hear you have lost your job.
        We understand this is an extremely stressful time and so many people are in the same situation. Many State and Federal Governments across the globe have introduced special and additional financial support for those who are now unemployed. Please refer to government information in your country to learn more about any assistance you may be entitled to.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.worriedLosingMyJob]: {
            [countries.australia]: {
                title: "Concerns about job security",
                text: `<div>
        We completely understand this is a really stressful time, and you are not alone in this.
        If the worst comes to the worst and you become unemployed, the following information might be useful to help
        understand what assistance is available:
        <a
          href="https://www.servicesaustralia.gov.au/individuals/subjects/affected-coronavirus-covid-19">servicesaustralia.gov.au</a>.
      </div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Concerns about job security",
                text: `<div>
        Being worried about your job is completely understandable during this stressful time, and you are not alone.
        The UK Government website provides useful and accurate advice on your rights and options as an employee: <a
          href="https://www.gov.uk/government/publications/guidance-to-employers-and-businesses-about-covid-19/covid-19-guidance-for-employees">gov.uk</a>.
        We hope this is helpful.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/guidance-to-employers-and-businesses-about-covid-19/covid-19-guidance-for-employees"
                ]
            },
            [countries.other]: {
                title: "Concerns about job security",
                text: `<div>
        Being worried about your job is completely understandable during this stressful time, and you are not alone.
        Please refer to government information in your country to learn more about any assistance you may be entitled
        to.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.enoughFood]: {
            [countries.australia]: {
                title: "Concerns about accessing food",
                text: `<div>At the time of writing, supermarkets and grocery stores are still open as an essential service. In addition,
        many restaurants are offering a home delivery service which is accessible online or over the telephone.
        If you have any symptoms or are feeling unwell, please stay home to protect your health, and the health of
        others.<br><br>

        If you cannot leave your home, ask others who are not in isolation to get food and necessities for you.<br><br>

        If you have no one to assist with bringing you groceries, emergency relief packages are currently being
        distributed in states and territories. Contact your <a
          href="https://www.health.gov.au/about-us/contact-us/local-state-and-territory-health-departments">state or
          territory health department for more information</a>.
      </div>`,
                urls: ["https://www.health.gov.au/about-us/contact-us/local-state-and-territory-health-departments"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about accessing food",
                text: `<div>
        At the time of writing, supermarkets and grocery stores are still open as an essential service. If you have any
        symptoms or are feeling unwell, please stay home to protect your health, and the health of others.<br><br>

        If you are classified as a clinically extremely vulnerable person you can register for support at <a
          href="https://www.gov.uk/coronavirus-extremely-vulnerable">www.gov.uk</a>.
        It may take time for any support offered through this service to arrive. Wherever possible you should continue
        to rely on friends, family and wider support to meet your needs.
      </div>`,
                urls: ["https://www.gov.uk/coronavirus-extremely-vulnerable"]
            },
            [countries.other]: {
                title: "Concerns about accessing food",
                text: `<div>
        At the time of writing, supermarkets and grocery stores are still open as an essential service.
        If you have any symptoms or are feeling unwell, please stay home to protect your health, and the health of
        others.<br><br>

        If you cannot leave your home, ask others who are not in isolation to get food and necessities for you.
      </div>
`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.enoughMedication]: {
            [countries.australia]: {
                title: "Concerns about accessing medication",
                text: `<div>
        At the time of writing, pharmacies are still open as an essential service.<br><br>

        If you need prescription medication but cannot leave your home, you can have it delivered from your regular
        pharmacy through the Home Medicines Service. <a
          href="https://www.health.gov.au/sites/default/files/documents/2020/03/covid-19-national-health-plan-home-medicines-services-information-for-consumers.pdf">Click
          here for the Home Medicines Service factsheet</a>.
      </div>`,
                urls: [
                    "https://www.health.gov.au/sites/default/files/documents/2020/03/covid-19-national-health-plan-home-medicines-services-information-for-consumers.pdf"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about accessing medication",
                text: `<div>
        At the time of writing, pharmacies are considered essential services, and are still open.<br><br>

        If you need prescription medication but cannot leave your home, contact your pharmacy over the telephone or
        online to see if they can arrange home delivery of your medication.
      </div>`,
                urls: []
            },
            [countries.other]: {
                title: "Concerns about accessing medication",
                text: `<div>
        At the time of writing, pharmacies are considered essential services, and are still open.<br><br>

        If you need prescription medication but cannot leave your home, contact your pharmacy over the telephone or
        online to see if they can arrange home delivery of your medication.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.feelingLonely]: {
            [countries.australia]: {
                title: "Concerns about mental health",
                text: `<div>
        The COVID-19 outbreak is affecting the way many of us live our lives and it's normal that this will affect
        people's mental health.
        The <a
          href="https://www.beyondblue.org.au/the-facts/looking-after-your-mental-health-during-the-coronavirus-outbreak">Beyond
          Blue website</a> has compiled some useful, authoritative resources which might be helpful.
      </div>`,
                urls: [
                    "https://www.beyondblue.org.au/the-facts/looking-after-your-mental-health-during-the-coronavirus-outbreak"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about mental health",
                text: `<div>
        The COVID-19 outbreak is affecting the way many of us live our lives and it's normal that this will affect
        people's mental health.
        The <a
          href="https://www.samaritans.org/how-we-can-help/support-and-information/if-youre-having-difficult-time/if-youre-worried-about-your-mental-health-during-coronavirus-outbreak/">samaritans.org
          website</a> has gathered some useful, authoritative resources which might be helpful.
      </div>`,
                urls: [
                    "https://www.samaritans.org/how-we-can-help/support-and-information/if-youre-having-difficult-time/if-youre-worried-about-your-mental-health-during-coronavirus-outbreak/"
                ]
            },
            [countries.other]: {
                title: "Concerns about mental health",
                text: `<div>The COVID-19 outbreak is affecting the way many of us live our lives and it's normal that this will affect
        people's mental health. Whilst Federal and State Health Departments often partner with specific mental health
        organisations and programs, there are also a number of resources which can be found online. If these aren't
        helpful, then search online for specific mental health support services available in the country you're
        currently located.</div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.worriedRelationship]: {
            [countries.australia]: {
                title: "Concerns about relationship",
                text: `<div>
        You are not alone if you are worried how your relationship will be affected by the COVID-19 pandemic.
        Even the most rock solid of marriages will be tested by spending the majority of time together for the
        foreseeable future.
        <a href="https://time.com/5811146/coronavirus-married-relationship/">This article from TIME</a> provides 11
        useful tips from couples therapists to help partners navigate this stressful time.
      </div>`,
                urls: ["https://time.com/5811146/coronavirus-married-relationship/"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about relationship",
                text: `<div>
        You are not alone if you are worried how your relationship will be affected by the COVID-19 pandemic.
        Even the most rock solid of marriages will be tested by spending the majority of time together for the
        foreseeable future.
        <a href="https://time.com/5811146/coronavirus-married-relationship/">This article from TIME</a> provides 11
        useful tips from couples therapists to help partners navigate this stressful time.
      </div>`,
                urls: ["https://time.com/5811146/coronavirus-married-relationship/"]
            },
            [countries.other]: {
                title: "Concerns about relationship",
                text: `<div>
        You are not alone if you are worried how your relationship will be affected by the COVID-19 pandemic.
        Even the most rock solid of marriages will be tested by spending the majority of time together for the
        foreseeable future.
        <a href="https://time.com/5811146/coronavirus-married-relationship/">This article from TIME</a> provides 11
        useful tips from couples therapists to help partners navigate this stressful time.
      </div>`,
                urls: ["https://time.com/5811146/coronavirus-married-relationship/"]
            }
        },
        [answers.Covid19MainConcerns.worriedGettingSick]: {
            [countries.australia]: {
                title: "Concerns about getting sick",
                text: `<div>
        People with COVID-19 may experience:
        <ul>
          <li>fever</li>
          <li>flu-like symptoms such as coughing, sore throat and fatigue</li>
          <li>shortness of breath</li>
        </ul>
        If you are concerned you may have COVID-19, it is important that you do not leave your home.
        You can use the <a href="https://www.healthdirect.gov.au/symptom-checker/tool/basic-details">Health Direct
          Symptom Checker</a> to find out what to do.
      </div>`,
                urls: ["https://www.healthdirect.gov.au/symptom-checker/tool/basic-details"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about getting sick",
                text: `<div>
        People with COVID-19 may experience:
        <ul>
          <li>fever</li>
          <li>flu-like symptoms such as coughing, sore throat and fatigue</li>
          <li>shortness of breath</li>
        </ul>
        If you are concerned you may have COVID-19, it is important that you do not leave your home. You can use the <a
          href="https://111.nhs.uk/covid-19">NHS Symptom Checker</a> to find out what to do.
      </div>`,
                urls: ["https://111.nhs.uk/covid-19"]
            },
            [countries.other]: {
                title: "Concerns about getting sick",
                text: `<div>
        People with COVID-19 may experience:
        <ul>
          <li>fever</li>
          <li>flu-like symptoms such as coughing, sore throat and fatigue</li>
          <li>shortness of breath</li>
        </ul>

        If you are concerned you may have COVID-19, it is important that you do not leave your home. Refer to your
        country's government health department to find out what you should do.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.worriedViolence]: {
            [countries.australia]: {
                title: "Concerns about partner or family violence",
                text: `<div>
        This is a difficult time for everyone, but there is no excuse for domestic violence of any type. If you or someone you
        know is experiencing family violence, and is in need of help or support please visit White
        Ribbon Australia for a comprehensive list of support hotline numbers.
        <a href="https://www.whiteribbon.org.au/find-help/domestic-violence-hotlines/">whiteribbon.org.au</a><br><br>
        If you are in more immediate danger, call police.
      </div>`,
                urls: ["https://www.whiteribbon.org.au/find-help/domestic-violence-hotlines/"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about partner or family violence",
                text: `<div>
        This is a difficult time for everyone, but there is no excuse for domestic violence of any type. If you or someone you
        know is experiencing family violence, and is in need of help or support please refer to <a
          href="https://www.gov.uk/government/publications/coronavirus-covid-19-and-domestic-abuse/coronavirus-covid-19-support-for-victims-of-domestic-abuse">this
          information on gov.uk</a> to find out where to get help.<br><br>
        If you are in more immediate danger, call police.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/coronavirus-covid-19-and-domestic-abuse/coronavirus-covid-19-support-for-victims-of-domestic-abuse"
                ]
            },
            [countries.other]: {
                title: "Concerns about partner or family violence",
                text: `<div>This is a difficult time for everyone, but there is no excuse for domestic violence of any type. If you or someone you
        know is experiencing family violence, and is in need of help or support please contact or refer to the domestic
        abuse and violence support services in your country or local area.<br><br>
        If you are in more immediate danger, call police.</div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.worriedFinance]: {
            [countries.australia]: {
                title: "Concerns about my finances",
                text: `<div>
        You're not alone, many, many people are worried about their financial circumstances during this pandemic.
        The National Debt Helpline provides a list of steps you can take to look after yourself and your family
        financially during the COVID-19 pandemic.
        <a href="https://ndh.org.au/debt-problems/covid19/">Click here to access this information</a>.
      </div>`,
                urls: ["https://ndh.org.au/debt-problems/covid19/"]
            },
            [countries.unitedKingdom]: {
                title: "Concerns about my finances",
                text: `<div>
        You're not alone, many, many people are worried about their financial circumstances during this pandemic.
        Step Change has compiled a list of common questions and answers if you are worried about the impact of COVID-19
        on your family finances.
        <a href="https://www.stepchange.org/debt-info/debt-and-coronavirus.aspx">stepchange.org</a>
      </div>`,
                urls: []
            },
            [countries.other]: {
                title: "Concerns about my finances",
                text: `<div>You're not alone, many, many people are worried about their financial circumstances during this pandemic.
        State and Federal Government websites in most countries provide detailed advice about the most common questions
        and answers for people experiencing financial strees as well as information about financial assistance,
        including postponement of repayments on certain types of loans.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19MainConcerns.noneOfTheAbove]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        }
    },
    [questionCodes.covid19GeneralHealth]: {
        [answers.Covid19GeneralHealth.excellent]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        },
        [answers.Covid19GeneralHealth.veryGood]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        },
        [answers.Covid19GeneralHealth.average]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        },
        [answers.Covid19GeneralHealth.notGood]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        },
        [answers.Covid19GeneralHealth.poor]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        }
    },
    [questionCodes.covid19Conditions]: {
        [answers.Covid19Conditions.smoke]: {
            [countries.australia]: {
                title: "Smoking and COVID-19",
                text: `<div>
        There currently isn't enough evidence to be certain that people who smoke are more likely to contract COVID-19.
        However there is growing evidence to suggest that people who smoke are more likely to be severely impacted by
        COVID-19 in the event that they do contract it due to the negative impact on their lung and cardiovascular
        health.<br><br>

        The best thing you can do for your health is to stop smoking.
        For more information to support your decision to quit, please visit <a
          href="https://quit.org.au">quit.org.au</a>.
      </div>`,
                urls: ["https://quit.org.au"]
            },
            [countries.unitedKingdom]: {
                title: "Smoking and COVID-19",
                text: `<div>
        There currently isn't enough evidence to be certain that people who smoke are more likely to get COVID-19.
        However there is growing evidence to suggest that people who smoke are more likely to be severely impacted by
        COVID-19 in the event that they do contract it due to the negative impact on their lung and cardiovascular
        health.<br><br>

        Quitting smoking is one of the best things you will ever do for your health.
        If you would like help to stop smoking please visit the following website: <a
          href="https://www.nhs.uk/oneyou/for-your-body/quit-smoking/?gclid=CNH486zCuOgCFYV0Gwody4UG2w">nhs.uk</a>.
      </div>`,
                urls: ["https://www.nhs.uk/oneyou/for-your-body/quit-smoking/?gclid=CNH486zCuOgCFYV0Gwody4UG2w"]
            },
            [countries.other]: {
                title: "Smoking and COVID-19",
                text: `<div>
        There currently isn't enough evidence to be certain that people who smoke are more likely to contract COVID-19.
        However there is growing evidence to suggest that people who smoke are more likely to be severely impacted by
        COVID-19 in the event that they do contract it due to the negative impact on their lung and cardiovascular
        health.<br><br>

        The best thing you can do for your health is to stop smoking.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Conditions.highBloodPressure]: {
            [countries.australia]: {
                title: "High blood pressure and COVID-19",
                text: `<div>
        You might have read reports that certain blood pressure medications could make it easier to become infected with
        the coronavirus, and are wondering whether you should continue to take your medication.<br><br>

        Heart Foundation Chief Medical Adviser, Professor Garry Jennings advises that <strong>Australians should
          continue to
          take their blood pressure medications as prescribed</strong>. “The dangers of suddenly stopping your blood
        pressure
        medications are clear and well known whereas the interaction of these types of medicines with COVID-19 is
        hypothetical and unproven,” he said.<br><br>

        Source: <a
          href="https://strokefoundation.org.au/News/2020/03/24/00/24/Coronavirus-Three-million-Australians-advised-not-to-stop-high-blood-pressure-medications">The
          Stroke Foundation, 24 March 2020</a>
      </div>
`,
                urls: [
                    "https://strokefoundation.org.au/News/2020/03/24/00/24/Coronavirus-Three-million-Australians-advised-not-to-stop-high-blood-pressure-medications"
                ]
            },
            [countries.unitedKingdom]: {
                title: "High blood pressure and COVID-19",
                text: `<div>
        You might have read reports that certain blood pressure medications could make it easier to become infected with
        the coronavirus, and are wondering whether you should continue to take your medication.<br><br>

        The British Heart Foundation strongly advises people to <strong>continue taking all their medications unless
          advised
          differently by their doctor</strong>.
        The <a
          href="https://www.bhf.org.uk/informationsupport/heart-matters-magazine/news/coronavirus-and-your-health">British
          Heart Foundation's website</a> provides a wealth of information for people with a heart or circulatory
        condition.
      </div>`,
                urls: [
                    "https://www.bhf.org.uk/informationsupport/heart-matters-magazine/news/coronavirus-and-your-health"
                ]
            },
            [countries.other]: {
                title: "High blood pressure and COVID-19",
                text: `<div >
        You might have read reports that certain blood pressure medications could make it easier to become infected with
        the coronavirus, and are wondering whether you should continue to take your medication.<br><br>

        It is strongly advised that people continue to take all their medications unless advised differently by their
        doctor or health care professional.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Conditions.pregnant]: {
            [countries.australia]: {
                title: "Pregnancy and COVID-19",
                text: `<div>
        So far, not enough is known about whether there is a risk of infection to unborn babies.
        Pregnant women should do what they can to avoid any infection, such washing hands with soap and water before and
        after eating, and after going to the toilet. Pregnant women should speak with their doctor or midwife about how
        to stay healthy during this time.<br><br>

        Source: <a
          href="https://www.healthdirect.gov.au/coronavirus-covid-19-groups-at-higher-risk-faqs#pregnant">healthdirect.gov.au</a>
      </div>`,
                urls: ["https://www.healthdirect.gov.au/coronavirus-covid-19-groups-at-higher-risk-faqs#pregnant"]
            },
            [countries.unitedKingdom]: {
                title: "Pregnancy and COVID-19",
                text: `<div>
        So far, not enough is known about whether there is a risk of infection to unborn babies. Pregnant women should
        do what they can to avoid any infection, such washing hands with soap and water before and after eating, and
        after going to the toilet. Pregnant women should speak with their doctor or midwife about how to stay healthy
        during this time.<br><br>

        The Royal College of Obstetricians and Gynaecologists also <a
          href="https://www.rcog.org.uk/en/guidelines-research-services/guidelines/coronavirus-pregnancy/covid-19-virus-infection-and-pregnancy/">has
          compiled answers to frequently asked questions about COVID-19 and pregnancy</a>.
      </div>`,
                urls: [
                    "https://www.rcog.org.uk/en/guidelines-research-services/guidelines/coronavirus-pregnancy/covid-19-virus-infection-and-pregnancy/"
                ]
            },
            [countries.other]: {
                title: "Pregnancy and COVID-19",
                text: `<div class="tbc">
        So far, not enough is known about whether there is a risk of infection to unborn babies. Pregnant women should
        do what they can to avoid any infection, such washing hands with soap and water before and after eating, and
        after going to the toilet. Pregnant women should speak with their doctor or midwife about how to stay healthy
        during this time.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Conditions.asthma]: {
            [countries.australia]: {
                title: "Asthma and COVID-19",
                text: `<div>
        There is not enough empirical data to say with certainty how COVID-19 affects people with asthma, but it has
        been deduced from previous experience that people with asthma are likely to be at more risk if they contract the
        disease.<br><br>
        Asthma Australia provides <a
          href="https://asthma.org.au/about-asthma/triggers/coronavirus-and-covid19/">detailed information on its
          website about COVID-19 and asthma</a>.
      </div>`,
                urls: ["https://asthma.org.au/about-asthma/triggers/coronavirus-and-covid19/"]
            },
            [countries.unitedKingdom]: {
                title: "Asthma and COVID-19",
                text: `<div>
        There is not enough empirical data to say with certainty how COVID-19 affects people with asthma, but it has
        been deduced from previous experience that people with asthma are likely to be at more risk if they contract the
        disease.<br><br>
        Asthma UK provides <a href="https://www.asthma.org.uk/advice/triggers/coronavirus-covid-19/">detailed
          information on its website about COVID-19 and asthma</a>.
      </div>`,
                urls: ["https://www.asthma.org.uk/advice/triggers/coronavirus-covid-19/"]
            },
            [countries.other]: {
                title: "Asthma and COVID-19",
                text: `<div>
        There is not enough empirical data to say with certainty how COVID-19 affects people with asthma,
        but it has been deduced from previous experience that people with asthma are likely to be at more
        risk if they contract the disease. Please refer to the national asthma organisation in your country
        for more information about what you need to know about asthma and COVID-19.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Conditions.diabetes]: {
            [countries.australia]: {
                title: "Diabetes and COVID-19",
                text: `<div>
        People with diabetes should be aware that they are at a higher risk of severe symptoms and complications from
        COVID-19.
        Please visit this <a href="https://www.diabetesaustralia.com.au/coronavirus">Diabetes Australia page</a> for
        more information about what you need to know about diabetes and COVID-19.
      </div>`,
                urls: ["https://www.diabetesaustralia.com.au/coronavirus"]
            },
            [countries.unitedKingdom]: {
                title: "Diabetes and COVID-19",
                text: `<div>
        People with diabetes should be aware that they are at a higher risk of severe symptoms and complications from
        COVID-19.
        Diabetes UK is continually updating its COVID-19 information for people living with diabetes and their families,
        <a href="https://www.diabetes.org.uk/about_us/news/coronavirus">please visit their page for the latest
          information</a>.
      </div>`,
                urls: ["https://www.diabetes.org.uk/about_us/news/coronavirus"]
            },
            [countries.other]: {
                title: "Diabetes and COVID-19",
                text: `<div>
        People with diabetes should be aware that they are at a higher risk of severe symptoms and complications from
        COVID-19.<br><br>
        Please refer to the national diabetes organisation in your country for more information about what you need to
        know about diabetes and COVID-19.
      </div>
`,
                urls: []
            }
        },
        [answers.Covid19Conditions.noFluVaccine]: {
            [countries.australia]: {
                title: "Flu vaccinations and COVID-19",
                text: `<div>
        <strong>Currently there is no vaccine for COVID-19.</strong><br><br>

        However, the Australian government recommends everyone aged six months and over should get vaccinated for
        seasonal flu.
        While this vaccine won't prevent you from contracting COVID-19, it will help reduce the spread and severity of
        seasonal flu, reducing burden on the health system. <a
          href="https://www.abc.net.au/news/health/2020-03-05/when-to-get-flu-vaccine-coronavirus-covid-19/12024498">Click
          here for more information</a> on when you should get vaccinated, and who is eligible for free vaccinations.
      </div>`,
                urls: [
                    "https://www.abc.net.au/news/health/2020-03-05/when-to-get-flu-vaccine-coronavirus-covid-19/12024498"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Flu vaccinations and COVID-19",
                text: `<div>
        <strong>Currently there is no vaccine for COVID-19.</strong><br><br>

        For more information on flu vaccinations in general, please visit the <a
          href="https://www.nhs.uk/conditions/vaccinations/flu-vaccine-questions-answers/">NHS flu vaccine FAQ page</a>.
      </div>`,
                urls: ["https://www.nhs.uk/conditions/vaccinations/flu-vaccine-questions-answers/"]
            },
            [countries.other]: {
                title: "Flu vaccinations and COVID-19",
                text: `<div>
        <strong>Currently there is no vaccine for COVID-19.</strong><br><br>

        For southern-hemisphere countries currently heading into winter, please check your government health department
        website for more information about seasonal flu vaccination.<br><br>

        While seasonal flu vaccines won't prevent you from contracting COVID-19, it will help reduce the spread and
        severity of seasonal flu, reducing burden on the health system.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Conditions.noneOfTheAbove]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        }
    },
    [questionCodes.covid19Sleep]: {
        [answers.Covid19Sleep.muchWorse]: {
            [countries.australia]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            },
            [countries.other]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            }
        },
        [answers.Covid19Sleep.slightlyWorse]: {
            [countries.australia]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            },
            [countries.other]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            }
        },
        [answers.Covid19Sleep.aboutTheSame]: {
            [countries.australia]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: []
            },
            [countries.other]: {
                title: "Getting enough sleep",
                text: `<div>
        Stressful situations and worrying that arises from this pandemic can impair healthy sleep patterns.
        In turn, a lack of good sleep can negatively affect mood and daytime functioning.
        Further, good sleep quality and quantity is important in helping to maintain a strong immune system.
        For more information on how to maintain good sleep habits during these times, please refer to <a
          href="https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html">sleephealthfoundation.org.au</a>.
      </div>`,
                urls: ["https://www.sleephealthfoundation.org.au/getting-good-sleep-during-the-covid-19-pandemic.html"]
            }
        },
        [answers.Covid19Sleep.slightlyBetter]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        },
        [answers.Covid19Sleep.muchBetter]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        }
    },
    [questionCodes.covid19Exercise]: {
        [answers.Covid19Exercise.muchLess]: {
            [countries.australia]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.other]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            }
        },
        [answers.Covid19Exercise.slightlyLess]: {
            [countries.australia]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.other]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            }
        },
        [answers.Covid19Exercise.aboutTheSame]: {
            [countries.australia]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.other]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            }
        },
        [answers.Covid19Exercise.slightlyMore]: {
            [countries.australia]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.other]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            }
        },
        [answers.Covid19Exercise.muchMore]: {
            [countries.australia]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.unitedKingdom]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            },
            [countries.other]: {
                title: "Getting enough exercise",
                text: `<div>
        During the COVID-19 pandemic, when so many of us are very restricted in our movements, it is even more important
        for people of all ages and abilities to be as active as possible.
        The World Health Organistion website provides more information about how much physical activity is required, and
        how to exercise safely during the COVID-19 pandemic.
        <a href="https://www.who.int/news-room/q-a-detail/be-active-during-covid-19">who.int</a>
      </div>`,
                urls: ["https://www.who.int/news-room/q-a-detail/be-active-during-covid-19"]
            }
        }
    },
    [questionCodes.covid19Meals]: {
        [answers.Covid19Meals.muchWorse]: {
            [countries.australia]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panic stockpiling, and is more considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>

        For more detailed tips and information, visit <a
          href="https://daa.asn.au/smart-eating-for-you/planning-your-pantry-during-the-covid-19-pandemic/">daa.asn.au</a>

      </div>`,
                urls: ["https://daa.asn.au/smart-eating-for-you/planning-your-pantry-during-the-covid-19-pandemic/"]
            },
            [countries.unitedKingdom]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panicky stockpiling, and is considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>
        For more detailed tips and information, visit <a
          href="https://www.bda.uk.com/resource/covid-19-corona-virus-advice-for-the-general-public.html">bda.uk.com</a>
      </div>`,
                urls: ["https://www.bda.uk.com/resource/covid-19-corona-virus-advice-for-the-general-public.html"]
            },
            [countries.other]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panicky stockpiling, and is more considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Meals.slightlyWorse]: {
            [countries.australia]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panic stockpiling, and is more considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>

        For more detailed tips and information, visit <a
          href="https://daa.asn.au/smart-eating-for-you/planning-your-pantry-during-the-covid-19-pandemic/">daa.asn.au</a>

      </div>`,
                urls: ["https://daa.asn.au/smart-eating-for-you/planning-your-pantry-during-the-covid-19-pandemic/"]
            },
            [countries.unitedKingdom]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panicky stockpiling, and is considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>
        For more detailed tips and information, visit <a
          href="https://www.bda.uk.com/resource/covid-19-corona-virus-advice-for-the-general-public.html">bda.uk.com</a>
      </div>`,
                urls: ["https://www.bda.uk.com/resource/covid-19-corona-virus-advice-for-the-general-public.html"]
            },
            [countries.other]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panicky stockpiling, and is more considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Meals.aboutTheSame]: {
            [countries.australia]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panic stockpiling, and is more considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>

        For more detailed tips and information, visit <a
          href="https://daa.asn.au/smart-eating-for-you/planning-your-pantry-during-the-covid-19-pandemic/">daa.asn.au</a>

      </div>`,
                urls: ["https://daa.asn.au/smart-eating-for-you/planning-your-pantry-during-the-covid-19-pandemic/"]
            },
            [countries.unitedKingdom]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panicky stockpiling, and is considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>
        For more detailed tips and information, visit <a
          href="https://www.bda.uk.com/resource/covid-19-corona-virus-advice-for-the-general-public.html">bda.uk.com</a>
      </div>`,
                urls: ["https://www.bda.uk.com/resource/covid-19-corona-virus-advice-for-the-general-public.html"]
            },
            [countries.other]: {
                title: "Nutritious meals",
                text: `<div>
        It can be challenging to maintain a healthy diet while minimising visits to the shops during the COVID-19
        pandemic. Here are some steps you can take to help you feel prepared regarding your food supply:<br>

        <ul>
          <li>Take stock of what you already have in your pantry</li>
          <li>Make a list. It's important to plan your meals before making the trip to the shops to ensure you have
            everything you need for meal preparation, and to avoid food wastage</li>
          <li>Having a plan also minimises panicky stockpiling, and is more considerate of others</li>
          <li>Good foods to consider are those with long shelf-lives, such as frozen fruit and vegetables, tinned soups
            and tuna, grains like pasta and rice, and long-life milk.</li>
        </ul>
      </div>`,
                urls: []
            }
        },
        [answers.Covid19Meals.slightlyBetter]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        },
        [answers.Covid19Meals.muchBetter]: {
            [countries.australia]: {
                text: ``,
                urls: []
            },
            [countries.unitedKingdom]: {
                text: ``,
                urls: []
            },
            [countries.other]: {
                text: ``,
                urls: []
            }
        }
    },
    [questionCodes.covid19Hygiene]: {
        [answers.Covid19Hygiene.notAtAll]: {
            [countries.australia]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.unitedKingdom]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.other]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            }
        },
        [answers.Covid19Hygiene.someDays]: {
            [countries.australia]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.unitedKingdom]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.other]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            }
        },
        [answers.Covid19Hygiene.mostDays]: {
            [countries.australia]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.unitedKingdom]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.other]: {
                title: "Hygiene",
                text: `<div>
        Regular thorough handwashing is one of the most important defences we have against the spread of
        COVID-19.<br><br>
        Proper handwashing technique is <a href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here
          on the World Health Organisation website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            }
        },
        [answers.Covid19Hygiene.everyDay]: {
            [countries.australia]: {
                title: "Hygiene",
                text: `<div>
        Great job on your dedication to regular hand hygiene!<br><br>
        If you need a reminder on the proper handwashing technique, it is <a
          href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here on the World Health Organisation
          website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.unitedKingdom]: {
                title: "Hygiene",
                text: `<div>
        Great job on your dedication to regular hand hygiene!<br><br>
        If you need a reminder on the proper handwashing technique, it is <a
          href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here on the World Health Organisation
          website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            },
            [countries.other]: {
                title: "Hygiene",
                text: `<div>
        Great job on your dedication to regular hand hygiene!<br><br>
        If you need a reminder on the proper handwashing technique, it is <a
          href="https://www.who.int/gpsc/clean_hands_protection/en/">illustrated here on the World Health Organisation
          website</a>.
      </div>`,
                urls: ["https://www.who.int/gpsc/clean_hands_protection/en/"]
            }
        }
    },
    [questionCodes.covid19SocialDistance]: {
        [answers.Covid19SocialDistance.notAtAll]: {
            [countries.australia]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance. This means no shaking hands, exchange of physical greetings,
        and wherever possible, staying at least 1.5 metres apart from others.<br><br>

        Source: <a
          href="https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19">Australian
          Government, Canberra</a>
      </div>`,
                urls: [
                    "https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance. This means no shaking hands, exchange of physical greetings,
        and wherever possible, staying at least 2 metres (6ft) apart from others.<br><br>

        Please <a
          href="https://www.gov.uk/government/publications/full-guidance-on-staying-at-home-and-away-from-others">visit
          the gov.uk website for advice and guidance on social distancing</a>.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/full-guidance-on-staying-at-home-and-away-from-others"
                ]
            },
            [countries.other]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance.
        This means no shaking hands, exchange of physical greetings, and wherever possible, staying at least 2 metres
        (6ft) apart from others.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19SocialDistance.someDays]: {
            [countries.australia]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance. This means no shaking hands, exchange of physical greetings,
        and wherever possible, staying at least 1.5 metres apart from others.<br><br>

        Source: <a
          href="https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19">Australian
          Government, Canberra</a>
      </div>`,
                urls: [
                    "https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance. This means no shaking hands, exchange of physical greetings,
        and wherever possible, staying at least 2 metres (6ft) apart from others.<br><br>

        Please <a
          href="https://www.gov.uk/government/publications/full-guidance-on-staying-at-home-and-away-from-others">visit
          the gov.uk website for advice and guidance on social distancing</a>.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/full-guidance-on-staying-at-home-and-away-from-others"
                ]
            },
            [countries.other]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance.
        This means no shaking hands, exchange of physical greetings, and wherever possible, staying at least 2 metres
        (6ft) apart from others.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19SocialDistance.mostDays]: {
            [countries.australia]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance. This means no shaking hands, exchange of physical greetings,
        and wherever possible, staying at least 1.5 metres apart from others.<br><br>

        Source: <a
          href="https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19">Australian
          Government, Canberra</a>
      </div>`,
                urls: [
                    "https://www.health.gov.au/news/health-alerts/novel-coronavirus-2019-ncov-health-alert/how-to-protect-yourself-and-others-from-coronavirus-covid-19/social-distancing-for-coronavirus-covid-19"
                ]
            },
            [countries.unitedKingdom]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance. This means no shaking hands, exchange of physical greetings,
        and wherever possible, staying at least 2 metres (6ft) apart from others.<br><br>

        Please <a
          href="https://www.gov.uk/government/publications/full-guidance-on-staying-at-home-and-away-from-others">visit
          the gov.uk website for advice and guidance on social distancing</a>.
      </div>`,
                urls: [
                    "https://www.gov.uk/government/publications/full-guidance-on-staying-at-home-and-away-from-others"
                ]
            },
            [countries.other]: {
                title: "Social distancing",
                text: `<div>
        Coronavirus is most likely transmitted communally when people come into close contact with one another. We can
        all help stop the spread by keeping our distance.
        This means no shaking hands, exchange of physical greetings, and wherever possible, staying at least 2 metres
        (6ft) apart from others.
      </div>`,
                urls: []
            }
        },
        [answers.Covid19SocialDistance.everyDay]: {
            [countries.australia]: {
                title: "Social distancing",
                text: `<div>Social distancing is one of the most important ways we can stop the spread of COVID-19. Well done on keeping your distance!</div>`,
                urls: []
            },
            [countries.unitedKingdom]: {
                title: "Social distancing",
                text: `<div>Social distancing is one of the most important ways we can stop the spread of COVID-19. Well done on keeping your distance!</div>`,
                urls: []
            },
            [countries.other]: {
                title: "Social distancing",
                text: `<div>Social distancing is one of the most important ways we can stop the spread of COVID-19. Well done on keeping your distance!</div>`,
                urls: []
            },
            [countries.notSpecified]: {
                title: "Social distancing",
                text: `<div>Social distancing is one of the most important ways we can stop the spread of COVID-19. Well done on keeping your distance!</div>`,
                urls: []
            }
        }
    }
};

const get = (questionCode, answer, country = countries.other, answersMap = AnswersMap) => {
    const none = {
        title: "",
        text: "",
        urls: []
    };

    // Invalid country
    if (!Object.values(countries).some(c => c === country)) {
        return none;
    }

    const answers = answersMap[questionCode];
    if (answers == null) {
        return none;
    }

    const feedback = answers[answer];
    if (feedback == null) {
        return none;
    }

    return feedback[country];
};

export { AnswersMap, questionCodes, answers, countries, get };
