import React, { Component } from "react";
import PropTypes from "prop-types";

import { Col, Grid, Row } from "react-bootstrap";
import styled from "styled-components";

// ----------------------------------------------------------------------------

import { media } from "@sisuwellness/web-components/themes";

// ----------------------------------------------------------------------------

import { mediaQueryForCss } from "utilities/mediaQuery";

// ----------------------------------------------------------------------------

const StyledPageContainer = styled(Col)`
    color: ${props => (props.isscriptspage ? "black" : props.theme.colours.flat.black.hex)};
    padding: 0;
`;
const ResponsiveInner = styled.div`
    padding: 0;

    ${props =>
        !props.isScriptsPage && !props.padding
            ? media.tablet`
        padding: 0 1em;
    `
            : ""};
`;

const Container = styled.div`
    width: 100%;
    ${mediaQueryForCss({
        width: ["", "", "100% !important"]
    })}
`;

const StyledGrid = styled(Grid)`
    margin: ${props => (props.margin ? props.margin : "")};
    margin-left: ${props => (props.marginleft ? props.marginleft : "")};
    width: ${props => (props.width ? props.width : "")};
    padding: ${props => (props.padding ? props.padding : "")};
`;

// ----------------------------------------------------------------------------
class PageContainer extends Component {
    render() {
        const { scriptsPageClass, id, children, margin, mL, width, padding } = this.props;

        return (
            <Container>
                <section id={id}>
                    <StyledGrid
                        className={scriptsPageClass}
                        margin={margin}
                        marginleft={mL}
                        width={width}
                        padding={padding}
                    >
                        <Row style={{ margin: margin ? margin : "" }}>
                            <StyledPageContainer
                                xs={12}
                                className="content-container"
                                isscriptspage={scriptsPageClass}
                                style={{ width: "100%" }}
                            >
                                <ResponsiveInner isScriptsPage={scriptsPageClass} padding={padding}>
                                    {children}
                                </ResponsiveInner>
                            </StyledPageContainer>
                        </Row>
                    </StyledGrid>
                </section>
            </Container>
        );
    }
}

// ----------------------------------------------------------------------------

PageContainer.displayName = "Page Container";

PageContainer.propTypes = {
    children: PropTypes.any,
    id: PropTypes.string,
    scriptsPageClass: PropTypes.string,
    mL: PropTypes.string,
    margin: PropTypes.string,
    width: PropTypes.string,
    padding: PropTypes.string
};

export default PageContainer;
