import React, { Component } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import APIClient from "utilities/APIClient";
import Auth from "utilities/auth";
import { FlowState } from "@sisuwellness/utilities/User";
import Password from "@sisuwellness/utilities/Auth/Password";
import routes from "../constants/routes";

// TODO: should be provided by the localization service
/*const SetupViewStrings = {
    errors: {
        invalidSetupLink: "The account setup link is invalid or has expired.",
        serverUnreachable: "Sorry the server could not be reached",
        unexpectedError: "An unexpected error occurred",
        invalidPayload: t("members_portal_web.components.notifications.invalid_fields")
    },

    labels: {
        completeYourAccount: "",
        submit: "Submit",
        login: "Login"
    },

    text: {
        checkEmailForActivationLink:
            "Please check your email for an activation link in order to complete your SiSU account/profile."
    }
};*/

export default class SetupView extends Component {
    static displayName = "SetupView";
    static propTypes = {
        location: PropTypes.object,
        match: PropTypes.object
    };

    constructor(props) {
        super(props);

        const previousState = props.location.state || null;

        this.passwordUtility = new Password();

        this.state = {
            loading: true,
            submitting: false,
            validToken: null,
            errorMessage: null,
            token: props.match.params.token,
            flowState: null,
            password: "",
            confirmPassword: "",
            campaign: null,
            campaignCode: previousState ? previousState.campaignCode : null
        };
    }

    componentDidMount() {
        Auth.isAuthenticated().then(authenticated => {
            if (authenticated) {
                this.setState({
                    flowState: FlowState.FLOW_STATE_USER_AUTHENTICATED,
                    loading: false
                });
            } else {
                this._onComponentMounted();
            }
        });
    }

    _onComponentMounted() {
        if (this.state.campaignCode) {
            this._loadCampaignData();
        } else {
            this._validateToken();
        }
    }

    _loadCampaignData() {
        APIClient.campaigns.search(this.state.campaignCode).then(response => {
            if (response.succeeded()) {
                response.body().then(campaign => {
                    this.setState({ campaign });
                    this._validateToken();
                });
            } else {
                this._validateToken();
            }
        });
    }

    _validateToken() {
        APIClient.tokens
            .validateUserToken(this.state.token, "setup")
            .then(response => {
                return response.succeeded() ? response.body() : null;
            })
            .then(body => {
                if (body) {
                    this.setState({
                        validToken: true,
                        loading: false,
                        userDetails: body.userProfile,
                        regionCode: body.regionCode
                    });
                } else {
                    this.setState({ validToken: false, loading: false });
                }
            });
    }

    _getClientCode() {
        let clientCode = null;
        if (this.state.campaign && this.state.campaign.client) {
            clientCode = this.state.campaign.client.code;
        }

        return clientCode;
    }

    render() {
        const { loading, flowState, validToken, campaignCode } = this.state;
        const { location } = this.props;
        if (loading) {
            return null;
        }

        // user is already authenticated, redirect to health hub
        if (FlowState.userIsAuthenticated(flowState)) {
            let redirect = { pathname: "/health-hub" };

            // set in PrivateRoute. redirects the user to this previously intended route
            if (location.state) {
                redirect = location.state;
            }
            return <Redirect to={redirect} />;
        }
        // invalid token provided, redirect to login
        else if (!validToken) {
            let redirect = {
                pathname: "/",
                state: location.state ? location.state.from : { pathname: "/" }
            };

            if (campaignCode) {
                redirect.search = "?cc=" + campaignCode;
            }

            return <Redirect to={redirect} />;
        }
        return (
            <Redirect
                to={{
                    pathname: routes.signup,
                    state: {
                        ...this.state.userDetails,
                        regionCode: this.state.regionCode,
                        token: this.state.token,
                        type: "setup"
                    },
                    search: location.search
                }}
            />
        );
    }
}
