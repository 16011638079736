import React from "react";
import { Box } from "rebass";

const getPolygonByType = type => {
    const CENTER_NOTCH_TOOLTIP_POLYGON = "polygon(0% 0%, 100% 0%, 100% 80%, 60% 80%, 50% 100%, 40% 80%, 0 80%)";
    const LEFT_NOTCH_TOOLTIP_POLYGON =
        "polygon(100% 0%, 70% 0%, 28% 0%, 0% 0%, 0% 26%, 0% 52%, 0% 81%, 7% 81%, 19% 100%, 29% 81%, 80% 81%, 100% 81%, 100% 53%, 100% 24%)";
    const RIGHT_NOTCH_TOOLTIP_POLYGON =
        "polygon(100% 0%, 70% 0%, 28% 0%, 0% 0%, 0% 26%, 0% 52%, 0% 82%, 26% 81%, 71% 81%, 82% 100%, 92% 81%, 100% 81%, 100% 53%, 100% 24%)";
    switch (type) {
        case "LEFT":
            return LEFT_NOTCH_TOOLTIP_POLYGON;
        case "RIGHT":
            return RIGHT_NOTCH_TOOLTIP_POLYGON;
        default:
            return CENTER_NOTCH_TOOLTIP_POLYGON;
    }
};

const Tooltip = React.forwardRef(({ children, minHeight = "63px", bg = "grey", polygonType, ...rest }, forwardRef) => {
    return (
        <Box
            bg={bg}
            ref={forwardRef}
            minHeight={minHeight}
            {...rest}
            style={{
                clipPath: getPolygonByType(polygonType)
            }}
        >
            {children}
        </Box>
    );
});

Tooltip.propTypes = {
    ...Box.propTypes
};

export default Tooltip;
