import BaseEndPoint from "@sisuwellness/api-client/src/BaseEndPoint";

export default class Ectosense extends BaseEndPoint {
    constructor(client) {
        super(client);
    }

    get = (url, queryStringParameters = null) => {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        const queryString = this.generateQueryString(queryStringParameters);
        return this._send(this.getUrlPath(url) + queryString, settings);
    };

    /**
     * @param {string} value summary_report_file
     * @return {Promise} url to download sleep report
     */
    generateSleepReport = value => {
        // eslint-disable-next-line camelcase
        return this.get("/ectosense/reports", { summary_report_file: value });
    };
}
