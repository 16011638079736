import React from "react";
import PropTypes from "prop-types";

import FlexWithGap from "components/Flex";
import { Card, Text } from "rebass";

export const TrendLegend = ({ legends = [], ...rest }) => (
    <FlexWithGap jc="center" gap={["4px", "30px"]} fd={["column", "row"]} ai={["flex-start", "center"]} {...rest}>
        {legends.map(({ color, label }, ix) => (
            <FlexWithGap key={ix} ai="center" gap="8px" data-testid="legend">
                <Card bg={color} borderRadius="50%" size="8px"></Card>
                <Text fontWeight="300" fontSize="12px" color="hpPrimaryPurple" letterSpacing="-0.12px">
                    {label}
                </Text>
            </FlexWithGap>
        ))}
    </FlexWithGap>
);

TrendLegend.propTypes = {
    legends: PropTypes.array
};
