import React, { Component } from "react";
import PropTypes from "prop-types";
import Attribute from "../Attribute";
import { withTheme } from "styled-components";
import { Guidelines } from "@sisuwellness/utilities";
import { withTranslation } from "react-i18next";

class BloodPressure extends Component {
    static displayName = "Blood Pressure Attribute";
    static propTypes = {
        t: PropTypes.func,
        theme: PropTypes.any.isRequired,
        systolic: PropTypes.number,
        diastolic: PropTypes.number,
        date: PropTypes.string
    };

    render() {
        const { t, systolic, diastolic, theme, date, ...rest } = this.props;
        let guideline = null;
        let guidelineStyle = null;
        let isDisabled = true;

        if (systolic && diastolic) {
            isDisabled = false;
            guideline = new Guidelines.BloodPressure.NationalHeartFoundationAustralia(systolic, diastolic);
            guidelineStyle = theme.guidelines.NationalHeartFoundationAustralia[guideline.key];
        }

        return (
            <Attribute
                {...rest}
                t={t}
                colour={guidelineStyle ? guidelineStyle.flat.hex : null}
                gradientCSS={guidelineStyle ? guidelineStyle.gradientCSS : null}
                guidelineRating={guidelineStyle ? t(guidelineStyle.label) : null}
                guidelineRatingInfo={guideline ? t(guideline.rangeInfo) : null}
                title={this.props.t("web_components.cards.attributes.blood_pressure.title")}
                date={date}
                primaryStatUnit={"mmHg"}
                primaryStatValue={isDisabled ? null : parseInt(systolic) + "/" + parseInt(diastolic)}
                icon={theme.icons.types.bloodPressure}
            />
        );
    }
}

export default withTranslation()(withTheme(BloodPressure));
