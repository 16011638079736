import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { KeyboardStyledButton } from "./KeyboardStyles";

export default class KeyboardButton extends PureComponent {
    static propTypes = {
        value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]),
        classes: PropTypes.string,
        onClick: PropTypes.func,
        onPressed: PropTypes.func,
        onReleased: PropTypes.func,
        autofocus: PropTypes.bool,
        isDisabled: PropTypes.bool,
        backgroundColor: PropTypes.string,
        color: PropTypes.string,
        minWidth: PropTypes.string
    };

    static defaultProps = {
        classes: "",
        autofocus: false,
        isDisabled: false
    };

    handleClick = () => (this.props.onClick ? this.props.onClick(this.props.value) : null);
    handlePressed = () => (this.props.onPressed ? this.props.onPressed(this.props.value) : null);
    handleReleased = () => (this.props.onReleased ? this.props.onReleased(this.props.value) : null);

    render() {
        return (
            <KeyboardStyledButton
                type="button"
                className={`${this.props.classes}`}
                onClick={this.props.isDisabled || !this.props.onClick ? null : this.handleClick}
                onMouseDown={this.props.isDisabled ? null : this.handlePressed}
                onMouseUp={this.props.isDisabled ? null : this.handleReleased}
                autoFocus={this.props.autofocus}
                disabled={this.props.isDisabled}
                backgroundColor={this.props.backgroundColor}
                color={this.props.color}
                minWidth={this.props.minWidth}
                data-testid={`keyboard-button-${this.props.value}`}
            >
                {this.props.value}
            </KeyboardStyledButton>
        );
    }
}
