import React from "react";
import Popup from "reactjs-popup";

import { Box } from "rebass";
import styled from "styled-components";
import { layout, border, shadow, background, position } from "styled-system";

const StyledPopup = styled(Popup)`
    // use your custom style for ".popup-overlay"
    &-overlay {
        background-color: rgba(55, 33, 104, 0.8);
    }
    // use your custom style for ".popup-content"
    &-content {
        max-height: 90vh;
        overflow-y: auto;
        ${layout}
    }
`;

const NotificationModal = React.forwardRef(({ children, ...rest }, forwardRef) => {
    return (
        <StyledPopup ref={forwardRef} width={["94%", "50%"]} {...rest} modal>
            {children}
        </StyledPopup>
    );
});

NotificationModal.propTypes = {
    ...StyledPopup.propTypes
};

const StyledBody = styled(Box)`
    border-style: solid;
    border-width: 0px;
    border-radius: 10px;
    box-shadow: 0 24px 32px 0 rgba(8, 35, 48, 0.12);
    border-color: ${({ theme }) => theme.colours.blackTints600.hex};

    background-size:  cover;
    background-repeat: no-repeat;

    ${position}
    ${border}
    ${shadow}
    ${background}
`;

export const StyledModalBody = ({ children, ...rest }) => (
    <StyledBody
        bg="white"
        borderWidth="0px"
        p={["15px", "40px"]}
        boxSizing={["", "none"]}
        boxShadow={["", "none"]}
        minWidth={["100%", "60%"]}
        {...rest}
    >
        {children}
    </StyledBody>
);

StyledModalBody.propTypes = {
    ...StyledBody.propTypes
};

export { StyledModalBody as ModalBody };
export default NotificationModal;
