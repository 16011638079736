import React from "react";
import { Box, Card, Flex, Text } from "rebass";
import styled from "styled-components";
import { position } from "styled-system";

// ----------------------------------------------------------------------------

import { ModalPrimaryButton } from "components/CommonComponents/Button";
import useConsumeRegistrationToken from "hooks/useConsumeRegistrationToken";

// ----------------------------------------------------------------------------

import { useTranslation } from "react-i18next";

const FullViewContainer = styled(Box)`
    width: 100%;
    z-index: 9999;
    overflow: hidden;
    ${position};
`;

const CardContainer = styled(Card)`
    height: auto;
    background: white;
    border-radius: 10px;
`;

const CorporateSubscriptionSuccessfulPopup = () => {
    const [isCorporateSubscriptionSuccessful] = useConsumeRegistrationToken();
    const { t } = useTranslation();

    if (!isCorporateSubscriptionSuccessful) return null;
    document.querySelector("body").style.overflow = "hidden";
    return (
        <FullViewContainer position="fixed" height="200%" bg="white">
            <FullViewContainer height="100%" bg="rgba(55, 33, 104, 0.8)">
                <Flex justifyContent="center" alignItems="center" height="100vh">
                    <CardContainer p={["16px", "24px", "40px"]}>
                        <Text
                            minWidth="200px"
                            maxWidth="574px"
                            textAlign="center"
                            color="hpPrimaryPurple"
                            fontSize={["18px", "28px"]}
                        >
                            {t("members_portal_web.components.corporate_subscription.successful.welcome")}
                        </Text>
                        <ModalPrimaryButton
                            onClick={() => window.location.reload()}
                            display="block"
                            mx="auto"
                            mt="16px"
                        >
                            {t("members_portal_web.components.corporate_subscription.successful.refresh")}
                        </ModalPrimaryButton>
                    </CardContainer>
                </Flex>
            </FullViewContainer>
        </FullViewContainer>
    );
};

export default CorporateSubscriptionSuccessfulPopup;
