import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Survey from "../Survey/Survey";
import Finished from "./PageTypes/Finished";
import Consent from "../Consents/Consent";
import { Flex } from "rebass";

const StyledUserJourney = styled(Flex)`
    min-height: 80vh;
`;

export default class UserJourney extends Component {
    static displayName = "User Journey";
    static propTypes = {
        model: PropTypes.object.isRequired,
        onComplete: PropTypes.func.isRequired,
        onPageResolved: PropTypes.func.isRequired,
        onShowPage: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            userJourney: props.model,
            currentUserJourneyPageId: null
        };

        this._isHRA = props.model.isHRA;

        this.handleUserJourneyPageResolved = this.handleUserJourneyPageResolved.bind(this);
        this.handleConsentCompleted = this.handleConsentCompleted.bind(this);
    }

    componentDidMount() {
        // let userJourney = new UserJourneyModel(this.props.schema, this.props.response);
        let currentUserJourneyPageId = null;

        const nextPage = this.state.userJourney.getNextPage();
        if (nextPage) {
            currentUserJourneyPageId = nextPage.getUserJourneyPageId();
            this.props.onShowPage(currentUserJourneyPageId);
        }

        this.setState({
            // userJourney,
            currentUserJourneyPageId
        });
    }

    handleUserJourneyPageResolved() {
        let nextPage = this.state.userJourney.getNextPage();
        let currentUserJourneyPageId = this.state.currentUserJourneyPageId;

        if (nextPage) {
            currentUserJourneyPageId = nextPage.getUserJourneyPageId();
            this.props.onPageResolved();
            this.props.onShowPage(currentUserJourneyPageId);
        } else {
            currentUserJourneyPageId = null;
            this.props.onComplete();
        }

        this.setState({
            currentUserJourneyPageId
        });
    }

    /**
     * Update the user journey page to be resolved when the Consent hits a complete state
     */
    handleConsentCompleted() {
        let currentPage = this.state.userJourney.getPageByUserJourneyPageId(this.state.currentUserJourneyPageId);
        currentPage.setResolved(true);

        this.handleUserJourneyPageResolved();
    }

    /**
     *
     * @param userJourneyPage {UserJourneyPage}
     */
    getComponentForPage(userJourneyPage) {
        let component = null;
        switch (true) {
            case userJourneyPage.getPageType().isTypeSurvey():
                component = (
                    <Survey
                        key={userJourneyPage.getUserJourneyPageId()}
                        model={userJourneyPage.getSurvey()}
                        onComplete={this.handleUserJourneyPageResolved}
                    />
                );
                break;

            case userJourneyPage.getPageType().isTypeConsent():
                component = (
                    <Consent
                        key={userJourneyPage.getUserJourneyPageId()}
                        response={userJourneyPage.getUserJourney().getResponse()}
                        consent={userJourneyPage.getConfigurationForPageType()}
                        onComplete={this.handleConsentCompleted}
                    />
                );
                break;

            case userJourneyPage.getPageType().isTypeFinished():
                component = (
                    <Finished
                        isHra={this._isHRA}
                        key={userJourneyPage.getUserJourneyPageId()}
                        userJourneyPage={userJourneyPage}
                        schema={userJourneyPage.getConfigurationForPageType()}
                        onComplete={this.handleUserJourneyPageResolved}
                    />
                );
                break;
            default:
            // do nothing;
        }

        return component;
    }

    render() {
        if (!this.state.userJourney) {
            return null;
        }

        let currentPage = this.state.userJourney.getPageByUserJourneyPageId(this.state.currentUserJourneyPageId);

        if (!currentPage) {
            return null;
        }

        const styledPropsForHRA = this._isHRA
            ? {
                  py: ["0px", "", "24px"],
                  px: ["0px", "", "76px"],
                  alignItems: "flex-start"
              }
            : {};

        let pageTypeComponent = this.getComponentForPage(currentPage);
        return (
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
                <StyledUserJourney
                    alignItems={["flex-start", "center"]}
                    justifyContent="center"
                    width="100%"
                    {...styledPropsForHRA}
                >
                    {pageTypeComponent}
                </StyledUserJourney>
            </Flex>
        );
    }
}
