export default class DateConverter {
    /**
     * Given a from date and to date (both moment objects), generates the difference in years
     *
     * @param {moment.Moment} fromDate
     * @param {moment.Moment} toDate
     * @return {Number}
     */
    static calculateYearsDifference(fromDate, toDate) {
        return toDate.diff(fromDate, "years");
    }
}
