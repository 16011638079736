/*
    Reference:
        George A Kaysen study “Estimation of total-body and limb muscle mass in hemodialysis patients by using multi-frequency bio-impedance spectroscopy”
*/
export const calculateKaysensMuscleMass = (R0, Rinf, z50kHz, heightCm, weightKg, age, gender) => {
    const h2 = heightCm * heightCm;

    const rECV = R0;
    const rICV = (R0 * Rinf) / (R0 - Rinf);

    const ECV = (0.287 * h2) / rECV + 0.034 * weightKg + 2.709;
    const TBW = (0.502 * h2) / z50kHz + 0.091 * weightKg + 3.515;
    const ICV = TBW - ECV;

    const icvEffect = 0.331 * ICV;
    const genderEffect = gender.toLowerCase() === "male" ? 2.77 : 0;
    const weightEffect = 0.18 * weightKg;
    const ageEffect = -0.113 * age;
    const muscleMassKg = 9.52 + icvEffect + genderEffect + weightEffect + ageEffect;
    const muscleMassPercentage = 100 * (muscleMassKg / weightKg);

    return { rECV, rICV, ECV, TBW, ICV, muscleMassKg, muscleMassPercentage };
};
