import { useAsyncData } from "@sisuwellness/utilities/hooks";
import { getHealthChecksAPI } from "actions/health-checks";
import { isEmpty } from "lodash-es";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import LoadingView from "components/LoadingView";
import { consolidateMetricData } from "utilities/commonUtils";

const withLatestHealthCheck = Component => props => {
    const {
        auth: { citizen },
        healthCheck: { healthChecks }
    } = useSelector(state => state);

    const dispatch = useDispatch();

    const [{ isStarted, isLoading }] = useAsyncData(() => isEmpty(healthChecks) && dispatch(getHealthChecksAPI()), []);

    if (!isStarted || isLoading) {
        return <LoadingView />;
    }

    const data = consolidateMetricData(citizen, healthChecks);

    const extraProps = {
        ...props,
        healthChecks,
        latestHealthCheck: data
    };

    return <Component {...extraProps} />;
};

export default withLatestHealthCheck;
