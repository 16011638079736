import { useState, useEffect, useReducer } from "react";
import { handleResponse } from "../../front-end-utils/errors";

const FETCH_INIT = "FETCH_INIT";
const FETCH_SUCCESS = "FETCH_SUCCESS";
const FETCH_FAILURE = "FETCH_FAILURE";

const fetchReducer = (state, { type, payload }) => {
    switch (type) {
        case FETCH_INIT:
            return { ...state, isLoading: true, isError: false, error: null, isStarted: true };
        case FETCH_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isError: false,
                data: payload
            };
        case FETCH_FAILURE:
            return {
                ...state,
                isLoading: false,
                isError: true,
                error: payload
            };
    }
};

/**
 * useAsyncData hook for conveniently fetching async data and returns error, loading state
 *
 * @param {Function} Object - An asynchronous action that needs to be resolved
 * @param {Object<string, any>|Array<Object<string, any>>} initialData - Default data set
 * @returns {[{isLoading: boolean, isError: boolean, data: Object<string, any>|Array<Object<string, any>>}, Function]} returnValue
 */
const useAsyncData = (asyncFunc, initialData) => {
    const [state, dispatch] = useReducer(fetchReducer, {
        isLoading: false,
        isError: false,
        data: initialData,
        isStarted: false
    });

    const [newAsyncFunc, setNewAsyncFunc] = useState(null);

    const refetchData = asyncFunc => {
        if (typeof asyncFunc == "function") {
            setNewAsyncFunc(() => asyncFunc);
        } else {
            setNewAsyncFunc(!newAsyncFunc);
        }
    };

    useEffect(() => {
        let didCancel = false;

        const fetchData = async () => {
            dispatch({ type: FETCH_INIT });

            try {
                const response = await (typeof newAsyncFunc == "function" ? newAsyncFunc() : asyncFunc());
                const result = await (typeof response.succeeded == "function" ? handleResponse(response) : response);

                if (!didCancel) {
                    dispatch({ type: FETCH_SUCCESS, payload: result });
                }
            } catch (error) {
                if (!didCancel) {
                    dispatch({ type: FETCH_FAILURE, payload: error });
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [newAsyncFunc]);

    return [state, refetchData];
};

export default useAsyncData;
