import { Box, Flex } from "rebass";
import styled from "styled-components";
import { typography } from "styled-system";

export const InputFieldTheme = (isCustomInput, length) => {
    return {
        container: {
            width: "100%",
            height: "100%",
            display: "flex",
            padding: "0px 10px",
            alignItems: "center",
            background: "transparent"
        },
        containerOpen: {},
        input: {
            border: 0,
            width: "100%",
            height: "100%",
            outline: "none",
            caretColor: "black",
            color: isCustomInput ? "transparent" : "",
            background: isCustomInput ? "transparent" : "white"
        },
        inputOpen: {},
        inputFocused: {},
        suggestionsContainer: {
            width: "101%",
            height: `${50 * length}px`,
            maxHeight: "400px",
            background: "white",
            overflow: `hidden ${50 * length > 400 ? "auto" : "hidden"}`,
            position: "absolute",
            padding: "0",
            left: "-2px",
            top: "102%",
            zIndex: "1"
        },
        suggestionsContainerOpen: {},
        suggestionsList: {
            padding: 0,
            listStyleType: "none"
        },
        suggestion: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "49px",
            cursor: "pointer",
            userSelect: "none"
        },
        suggestionFirst: {},
        suggestionHighlighted: {},
        sectionContainer: {},
        sectionContainerFirst: {},
        sectionTitle: {}
    };
};

export const InputContainer = styled(Flex)`
    border-radius: 5px;
    position: relative;
    box-shadow: ${props => props.error && "0 8px 8px 0 rgba(255, 100, 124, 0.08)"};
    border: 1px solid ${props => (props.error ? "#ff647c" : "rgba(102, 102, 102, 0.24)")};

    input {
        z-index: 1;
        font-size: 13px;
        font-weight: 300;
        position: relative;

        ::placeholder {
            color: #877aa4;
        }
    }

    &::before {
        left: 0;
        top: -26px;
        font-size: 15px;
        font-weight: 600;
        line-height: 1.33;
        position: absolute;
        content: attr(label);
    }

    ${props => props.customStyles}
`;

export const LeftChild = styled.div`
    width: fit-content;
    height: 30px;
`;

export const RightChild = styled.div`
    width: fit-content;
    height: 30px;
`;

export const ErrorText = styled(Box)`
    text-align: left;
    font-size: 11px;
    line-height: 1.18;
    color: #ff647c;
`;

export const Container = styled(Box)`
    ${typography}
`;
