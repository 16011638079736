import React from "react";
import PropTypes from "prop-types";

import { Text } from "rebass";
import { useHistory } from "react-router";

// --------------------------------------------------------------------------------------------

import FlexWithGap from "components/Flex";
import { StatusCard } from "@sisuwellness/ui/src/components/Cards";
import Pie from "@sisuwellness/ui/src/components/Pie";
import { StatusLabel as Status } from "@sisuwellness/ui/src/components/Labels";
import { SecondaryButton } from "@sisuwellness/ui/src/components/Buttons";

import { BtnBox, CustomBox, CustomLabel } from "./styled-components";
import { useTranslation } from "react-i18next";

// --------------------------------------------------------------------------------------------

export const HealthCardIcon = ({ icon, children }) => (
    <FlexWithGap size="100px" minWidth="100px" ai="center" jc="center">
        <CustomBox
            size="100px"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            backgroundSize="contain"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundImage={`url(${icon})`}
        >
            {children}
        </CustomBox>
    </FlexWithGap>
);
HealthCardIcon.propTypes = {
    icon: PropTypes.string,
    children: PropTypes.node
};

// --------------------------------------------------------------------------------------------

export const HealthCardProgress = ({ progress = 0, indicator = {}, children, value }) => (
    <Pie progress={value === 0 ? 0 : progress} progressFgColor={indicator.bg} size={100}>
        {children}
    </Pie>
);
HealthCardProgress.propTypes = {
    progress: PropTypes.number,
    indicator: PropTypes.shape({
        fg: PropTypes.string,
        bg: PropTypes.string,
        label: PropTypes.string
    }),
    children: PropTypes.node,
    value: PropTypes.any
};

// --------------------------------------------------------------------------------------------

export const HealthCardStatus = ({ indicator = {}, fontProps = {}, ...rest }) => {
    const { t } = useTranslation();

    return (
        <StatusCard bg={indicator.bg} {...rest}>
            <Status color="white" fs={16} mt="3px" {...fontProps}>
                {t(
                    `words.${indicator.label}`
                        .split(" ")
                        .join("_")
                        .toLowerCase(),
                    indicator.label
                )}
            </Status>
        </StatusCard>
    );
};
HealthCardStatus.propTypes = {
    ...StatusCard.propTypes,
    indicator: PropTypes.shape({
        fg: PropTypes.string,
        bg: PropTypes.string,
        label: PropTypes.string
    }),
    fontProps: PropTypes.object
};

// --------------------------------------------------------------------------------------------

export const HealthCardRecommendation = ({ text, ...rest }) => (
    <CustomLabel color="blackTints700" {...rest}>
        {text}
    </CustomLabel>
);
HealthCardRecommendation.propTypes = { text: PropTypes.string };

// --------------------------------------------------------------------------------------------

export const HealthCardCallToAction = ({ link, url, isRiskHigh, ...rest }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleOnClick = () => {
        link && history.push(link);
        url && window.open(url, "_blank");
    };

    const alertColor = isRiskHigh ? "hpDarkPink" : "hpPrimaryPurple";
    return link || url ? (
        <BtnBox width="100%" px="32px" mb="16px">
            <SecondaryButton
                width="100%"
                minHeight="40px"
                color={alertColor}
                onClick={handleOnClick}
                borderColor={alertColor}
                backgroundColor="transparent"
                {...rest}
            >
                {isRiskHigh ? t("words.attention") : t("words.learn_more")}
            </SecondaryButton>
        </BtnBox>
    ) : null;
};
HealthCardCallToAction.propTypes = {
    link: PropTypes.string,
    url: PropTypes.string,
    isRiskHigh: PropTypes.bool
};

// --------------------------------------------------------------------------------------------

export const HealthCardTextValue = ({ value, unit, unitProps, valueProps }) => (
    <>
        {value && (
            <Text fontSize="22px" fontWeight="500" {...valueProps}>
                {value}
            </Text>
        )}
        {unit && (
            <Text fontSize="13px" {...unitProps}>
                {unit}
            </Text>
        )}
    </>
);
HealthCardTextValue.propTypes = {
    value: PropTypes.any,
    unit: PropTypes.string,
    unitProps: PropTypes.shape({ ...Text.propTypes }),
    valueProps: PropTypes.shape({ ...Text.propTypes })
};

// --------------------------------------------------------------------------------------------
