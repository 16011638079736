import React, { useContext } from "react";
import PropTypes from "prop-types";

// ------------------------------------------------------------

import { Flex, Box, Text } from "rebass";
import styled, { ThemeContext, createGlobalStyle } from "styled-components";
import { position } from "styled-system";

// ------------------------------------------------------------

import Popup from "components/PortalModal/popup";
import { PORTAL_IMAGE } from "constants/imagePath";
import { mediaQueryForCss } from "utilities/mediaQuery";
import { StyledButtonCross } from "components/PortalModal/styled";
import { media } from "@sisuwellness/web-components/themes";
import { QRISK3 } from "constants/trend-charts";
import { useTranslation } from "react-i18next";

// ------------------------------------------------------------

const StyledText = styled(Text)`
    p {
        font-size: 13px;
        margin: 0;
        color: ${props => props.theme.colours.flat.blackTint.hex};
        ${mediaQueryForCss({
            "font-size": ["", "", "11px"]
        })}
        a {
            font-size: 13px;
            color: ${props => props.theme.colours.flat.blackTint.hex};
            ${mediaQueryForCss({
                "font-size": ["", "", "11px"]
            })}
        }
    }
`;

const StyledFlex = styled(Flex)`
    border-radius: 10px;
    background-color: ${props => props.theme.colours.flat.white.hex};
    border: solid 1px ${props => props.theme.colours.blackTints600.rgb};

    & > * + * {
        margin-left: 20px;

        ${media.mobile`
            margin-left: 10px;
        `}
    }

    ${position}
`;

const HideScrollbarElement = createGlobalStyle`
   ::-webkit-scrollbar {
        width: 0px;
        background: transparent;
    }

    @media print{@page {size: landscape}}
`;

const CustomBox = styled(Box)`
    overflow-y: auto;
    & > * + * {
        margin-top: 16px;
    }
`;

// ------------------------------------------------------------

const RecommendationPopup = ({ data, type, closeModal, modalIsOpen, heading = "" }) => {
    const { t } = useTranslation();
    const theme = useContext(ThemeContext);
    return (
        <>
            <HideScrollbarElement />
            <Popup closeModal={closeModal} modalIsOpen={modalIsOpen} hideHeader>
                <Box data-testid="range-popup">
                    <Flex width="100%" justifyContent="space-between" mb="15px">
                        <Text color={theme.colours.hpPrimaryPurple.hex} fontSize="28px">
                            {heading || t("members_portal_web.components.progress_view.modal_heading_default")}
                        </Text>
                        <StyledButtonCross
                            onClick={closeModal}
                            src={`${PORTAL_IMAGE}/menu-close.svg`}
                            alt={"cross"}
                            data-testid="close-modal"
                        />
                    </Flex>
                    {type == QRISK3 ? (
                        <Text
                            mb="16px"
                            fontSize="13px"
                            fontWeight="300"
                            color="blackTints700"
                            minWidth={["70px", "75px"]}
                        >
                            {t("members_portal_web.components.progress_view.qrisk_explanation")}
                        </Text>
                    ) : (
                        <></>
                    )}
                    <CustomBox height="450px">
                        {data.map(({ color, label, range, recommendation }, index) => (
                            <StyledFlex
                                p="15px"
                                width="100%"
                                key={`${type}-popup-${index}`}
                                height={["170px", "110px"]}
                                alignItems="center"
                            >
                                <Text
                                    minWidth={["70px", "75px"]}
                                    fontSize="12px"
                                    fontWeight="600"
                                    fontFamily="Open Sans"
                                >
                                    {range}
                                    <br /> {t(label).toUpperCase()}
                                </Text>
                                <Box bg={color} minWidth="4px" width="4px" height="100%" borderRadius="4px" />
                                <StyledText dangerouslySetInnerHTML={{ __html: t(recommendation) }}></StyledText>
                            </StyledFlex>
                        ))}
                    </CustomBox>
                </Box>
            </Popup>
        </>
    );
};

RecommendationPopup.propTypes = {
    data: PropTypes.array,
    type: PropTypes.string,
    modalIsOpen: PropTypes.bool,
    closeModal: PropTypes.func,
    heading: PropTypes.string
};

export default RecommendationPopup;
