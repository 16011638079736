import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Flex } from "rebass";

const StyledCell = styled(Flex)`
    border-bottom: ${props => (props.showBorder ? props.theme.table.cell.border : "")};
    background-color: ${props => props.backgroundColor};
    font-family: "Proxima Nova", Arial, sans-serif;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    height: 100%;
    font-size: ${props => (props.fontSize ? props.fontSize : "0.875rem")};
`;

const Cell = ({ children, ...rest }) => <StyledCell {...rest}>{children}</StyledCell>;

Cell.propTypes = {
    children: PropTypes.node.isRequired,
    showBorder: PropTypes.bool,
    backgroundColor: PropTypes.bool,
    ...Flex.propTypes
};

Cell.defaultProps = {
    p: "10px",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    showBorder: true
};

export default React.memo(Cell);
