import BaseEndPoint from "../BaseEndPoint";

export default class CustomerRequests extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/client-addresses/" + path);
    }

    /**
     * Creates a staff member with specified values for a given Customer Address
     *
     * @param {int} clientAddressId
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} externalStaffReference
     * @return {Promise<Response>}
     */
    createStaffMember(clientAddressId, firstName, lastName, externalStaffReference) {
        return this.post(`${clientAddressId}/staff`, {
            firstName,
            lastName,
            externalStaffReference
        });
    }

    /**
     * Retrieves all staff members for a given Client Address
     *
     * @param {int} clientAddressId
     * @return {Promise<Response>}
     */
    retrieveStaffMembers(clientAddressId) {
        const settings = {
            ...this._getDefaultFetchSettings(),
            method: "GET"
        };

        return this._send(this.getUrlPath(clientAddressId), settings);
    }

    /**
     * Updates a staff member with specified values
     *
     * @param {int} clientAddressId
     * @param {int} clientAddressStaffId
     * @param {String} firstName
     * @param {String} lastName
     * @param {String} externalStaffReference
     * @return {Promise<Response>}
     */
    updateStaffMember(clientAddressId, clientAddressStaffId, firstName, lastName, externalStaffReference) {
        return this.put(`${clientAddressId}/staff/${clientAddressStaffId}`, {
            firstName,
            lastName,
            externalStaffReference
        });
    }

    /**
     * Deletes a staff member from a given Client Address
     *
     * @param {int} clientAddressId
     * @param {int} clientAddressStaffId
     * @return {Promise<Response>}
     */
    deleteStaffMember(clientAddressId, clientAddressStaffId) {
        return this.delete(`${clientAddressId}/staff/${clientAddressStaffId}`);
    }
}
