import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Flex } from "rebass";
import InputField from "./InputField";
import QuestionIcon from "../QuestionIcon";
import theme from "../../../../themes";
import QuestionTitle from "../Title";
import Help from "../Help";
import { Errors } from "../../../Forms";

const Divider = styled.div`
    font-size: ${props => props.theme.text.heading4.sizeRem};
    margin: 0 25px;
`;

const QuestionHelp = styled(Help)`
    margin-bottom: 0;
`;

const MultipleTexts = ({
    id,
    title,
    iconType,
    helpText,
    divider = "\u2013",
    jointCharacter = "",
    fields = [],
    onFocusChanged = () => {},
    onValuesChanged = () => {},
    errors = []
}) => {
    const inputRefs = fields.map(() => useRef(null));
    const [values, setValues] = useState([]);
    useEffect(() => {
        if (inputRefs[0]) {
            onFocusChanged(inputRefs[0].current);
        }

        return () => {};
    }, []);

    const onChange = (index, value) => {
        const newValues = [...values];
        newValues[index] = value;
        setValues(newValues);
        onValuesChanged(buildAnswers(newValues));
    };

    const onMatch = index => {
        if (inputRefs[index].current && index + 1 < fields.length) {
            inputRefs[index + 1].current.focus();
            onFocusChanged(inputRefs[index + 1].current);
        }
    };

    const onFocus = index => {
        if (inputRefs[index] && inputRefs[index].current) {
            onFocusChanged(inputRefs[index].current);
        }
    };

    const buildAnswers = values => {
        const combined = values.reduce((acc, curr) => `${acc}${jointCharacter}${curr}`);
        return { values, combined };
    };

    const buildFields = () => {
        return fields.map((field, index) => (
            <React.Fragment key={`input-field-${index}`}>
                <InputField
                    id={field.id}
                    label={field.label}
                    placeholder={field.placeholder}
                    value={values[index]}
                    onChange={value => onChange(index, value)}
                    regex={field.regex}
                    onMatch={() => onMatch(index)}
                    ref={inputRefs[index]}
                    proportion={field.proportion}
                    onFocus={ref => onFocus(index, ref)}
                    autoFocus={index === 0}
                />
                {index < fields.length - 1 && <Divider key={`divider-${index}`}>{divider}</Divider>}
            </React.Fragment>
        ));
    };

    const buildValidationErrors = () => {
        const hasError = errors && errors.length;
        return hasError ? <Errors rounded={true}>{errors}</Errors> : null;
    };

    return (
        <Flex flexDirection={"column"} alignItems="center" justifyContent={"flex-start"} overflow={"auto"}>
            {buildValidationErrors()}
            {iconType && <QuestionIcon type={theme.icons.types[iconType]} />}
            {title && <QuestionTitle htmlFor={id}>{title}</QuestionTitle>}
            {helpText && <QuestionHelp>{helpText}</QuestionHelp>}
            <Flex alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
                {buildFields()}
            </Flex>
        </Flex>
    );
};

MultipleTexts.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    iconType: PropTypes.string,
    helpText: PropTypes.string,
    divider: PropTypes.any,
    jointCharacter: PropTypes.string,
    onFocusChanged: PropTypes.func,
    onValuesChanged: PropTypes.func,
    errors: PropTypes.array,
    fields: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            label: PropTypes.string,
            placeholder: PropTypes.string,
            regex: PropTypes.object,
            proportion: PropTypes.number
        })
    )
};

export default withTheme(MultipleTexts);
