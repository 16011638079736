import React, { Component, Fragment } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Choice as ChoiceButton } from "../Buttons";
import QuestionTitle from "./Title";
import Help from "./Help";
import { Errors } from "../../Forms";
import theme from "../../../themes";
import Icon from "../../Icons/Icon";
import Scroll from "react-scroll";
import QuestionIcon from "./QuestionIcon";
import MediaQuery from "../../MediaQuery/index";
import { withTranslation } from "react-i18next";

const StyledQuestionIcon = styled(QuestionIcon)`
    flex: 0 0 auto;
`;

const QuestionGridContainer = styled.div`
    flex-direction: column;
    justify-content: flex-start;
    align-self: center;
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    overflow: auto;
`;

const StyledScrollContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-content: center;
    margin-left: 5em;
    position: static;
    height: ${props => (props.healthStationMode ? "53vh" : "auto")};
`;

const StyledSideBySideContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-self: center;
`;

const ChoiceGridContainer = styled(Scroll.Element)`
    display: flex;
    margin-right: 1em;
    flex-direction: column;
    padding: 0em;
    align-items: center;
    max-width: 100%;
    position: static;
    overflow-y: hidden;
    justify-content: center;
`;

const ChoiceStackedContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    max-width: 100%;
    justify-content: ${props => (props.size > 3 && !props.displayOption ? "flex-start" : "center")};
    padding: 1em 0;
    overflow: auto;
`;

const StyledULSideBySide = styled.ul`
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: auto;
    align-self: center;
    height: ${props => (props.healthStationMode ? "50vh" : "auto")};
`;

const StyledULStacked = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 2;
    flex-basis: auto;
    justify-content: center;
    align-items: baseline;
    list-style: none;
    margin: auto;
    padding: 0;
`;

let ArrowUp = styled(Icon)`
    display: flex;
    transform: rotate(90deg);
    align-self: center;
`;

const ArrowDown = styled(Icon)`
    display: flex;
    transform: rotate(270deg);
    align-self: center;
`;

const StyleArrowUp = styled.div`
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
`;

const StyleArrowDown = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

class Choice extends Component {
    static displayName = "Choice Question";
    static propTypes = {
        id: PropTypes.string.isRequired,
        model: PropTypes.object.isRequired,
        selected: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onDeselect: PropTypes.func.isRequired,
        autoFocus: PropTypes.bool,
        errors: PropTypes.any,
        t: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.handleSelect = this.handleSelect.bind(this);
        this.handleDeselect = this.handleDeselect.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.getContainerId = this.getContainerId.bind(this);

        this.state = {
            healthStationMode: props.model
                .getQuestionGroup()
                .getSurvey()
                .getHealthStationMode(),
            arrowUp: false,
            choiceLength: 2
        };
    }

    handleSelect(choiceText) {
        this.props.onSelect(choiceText);
    }

    handleDeselect(choiceText) {
        this.props.onDeselect(choiceText);
    }

    /**
     * Returns a unique DOM id. need to use the id prop to ensure that the scroll mechanism is isolated to the consumer
     *
     * @returns {string}
     */
    getContainerId() {
        return "choiceContainer" + this.props.model.getQuestionId() + this.props.id;
    }

    handleClick(offset, showUpArrow = false) {
        Scroll.animateScroll.scrollMore(offset, {
            duration: 300,
            delay: 0,
            offset,
            smooth: true,
            containerId: this.getContainerId(),
            isDynamic: false // fixed content elements
        });

        if (!this.state.arrowUp && showUpArrow) {
            this.setState({ arrowUp: showUpArrow });
        }
    }

    render() {
        //Icon.
        let { model } = this.props;
        let getIcon = this.props.model.getQuestionIcon();
        let helpText = null;
        if (this.props.model.getHelpText()) {
            helpText = <Help>{this.props.t(this.props.model.getHelpText())}</Help>;
        }

        const choiceList = Array.isArray(this.props.model.getChoices())
            ? this.props.model.getChoices()
            : Object.values(this.props.model.getChoices());

        const totalChoices = choiceList.length;
        // const displayType  = this.props.model.getDisplayOption();

        let choiceButtonSize = this.props.model.getChoiceSize() ? this.props.model.getChoiceSize() : "medium";

        let choiceLength = choiceList.length;
        let choices = choiceList.map((choice, index) => {
            let selected = this.props.selected.reduce(
                (accumulator, selectedValue) => accumulator + (selectedValue === choice.label ? 1 : 0),
                0
            );
            return (
                <Fragment key={index}>
                    <MediaQuery devices={["desktop", "tablet"]} key={this.props.model.getQuestionId()}>
                        <ChoiceButton
                            isFirst={index === 0}
                            isLast={index + 1 === totalChoices}
                            size={choiceButtonSize}
                            selected={selected > 0}
                            onSelect={this.handleSelect}
                            onDeselect={this.handleDeselect}
                            schema={choice}
                            isFullWidth={false}
                        />
                    </MediaQuery>
                    <MediaQuery devices={["mobile"]} key={this.props.model.getQuestionId() + "mobile"}>
                        <ChoiceButton
                            isFirst={index === 0}
                            isLast={index + 1 === totalChoices}
                            size={choiceButtonSize}
                            selected={selected > 0}
                            onSelect={this.handleSelect}
                            onDeselect={this.handleDeselect}
                            schema={choice}
                            isFullWidth={true}
                        />
                    </MediaQuery>
                </Fragment>
            );
        });

        // make the choices list view
        // max is 4 for Station resolution
        if (model.isDisplayOptionSideBySide()) {
            choices = choices.map((choice, index) => <li key={index}>{choice}</li>);
            choices = (
                <StyledULSideBySide healthStationMode={this.state.healthStationMode}>{choices}</StyledULSideBySide>
            );
        } else if (this.state.healthStationMode && choices.length > 2 && choices.length < 5) {
            // max is 4 for Station resolution
            choices = choices.map((choice, index) => <li key={index}>{choice}</li>);
            choices = (
                <StyledULStacked className={"list"} healthStationMode={this.state.healthStationMode}>
                    {choices}
                </StyledULStacked>
            );
        }

        const hasErrors = this.props.errors && this.props.errors.length;
        let validationErrors = null;
        if (hasErrors) {
            validationErrors = <Errors rounded={true}>{this.props.t(this.props.errors)}</Errors>;
        }

        let topScrollArrow = null;
        if (this.state.arrowUp) {
            topScrollArrow = (
                <StyleArrowUp onClick={() => this.handleClick(-150)}>
                    <ArrowUp type={theme.icons.types.arrow} size={theme.icons.sizes.normal} />
                </StyleArrowUp>
            );
        }

        // FIXME: too simplistic of a condition
        let bottomScrollArrow = null;
        if (choiceLength > 3) {
            bottomScrollArrow = (
                <StyleArrowDown onClick={() => this.handleClick(150, true)}>
                    <ArrowDown type={theme.icons.types.arrow} size={theme.icons.sizes.normal} />
                </StyleArrowDown>
            );
        }

        let icon = null;
        if (getIcon) {
            icon = <StyledQuestionIcon type={theme.icons.types[getIcon]} />;
        }

        let output = null;
        if (model.isDisplayOptionStacked()) {
            output = (
                <StyledContainer data-testid={`survey-question-choice-stacked`}>
                    {validationErrors}
                    {icon}
                    <QuestionTitle hasErrors={hasErrors} htmlFor={this.props.id}>
                        {this.props.t(this.props.model.getDisplayText())}
                    </QuestionTitle>
                    {helpText}
                    <ChoiceStackedContainer size={choiceLength} displayOption={true}>
                        {choices}
                    </ChoiceStackedContainer>
                </StyledContainer>
            );
        } else {
            output = (
                <StyledSideBySideContainer
                    className={"side-by-side-container"}
                    data-testid={"survey-question-choice-side-by-side"}
                >
                    {validationErrors}
                    <QuestionGridContainer>
                        {icon}
                        <QuestionTitle hasErrors={hasErrors} htmlFor={this.props.id}>
                            {this.props.t(this.props.model.getDisplayText())}
                        </QuestionTitle>
                        {helpText}
                    </QuestionGridContainer>
                    <StyledScrollContainer healthStationMode={this.state.healthStationMode}>
                        {topScrollArrow}
                        <ChoiceGridContainer id={this.getContainerId()}>{choices}</ChoiceGridContainer>
                        {bottomScrollArrow}
                    </StyledScrollContainer>
                </StyledSideBySideContainer>
            );
        }

        return output;
    }
}

export default withTranslation()(Choice);
