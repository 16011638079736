import calculateColeParameters from "./ColeModel";
import calculateKaysensMuscleMass from "./KaysenModel";

export const calculateMuscleMass = (impedanceVector, heightCm, weightKg, age, gender) => {
    const coleCalculationsResults = calculateColeParameters(impedanceVector);
    const [R0, Rinf] = coleCalculationsResults.parameterValues;

    const muscleMassResult = calculateKaysensMuscleMass(R0, Rinf, impedanceVector[1], heightCm, weightKg, age, gender);

    return { ...muscleMassResult, coleCalculationsResults };
};
