import React from "react";
import PropTypes from "prop-types";
import { ResponsiveImage, ResponsiveFlex, ResponsiveBox, withPrintable } from "@sisuwellness/web-components";

const PrintHeader = ({ printable }) => (
    <>
        {printable ? (
            <ResponsiveFlex data-printid="print-header" alignItems="center" justifyContent="space-between">
                <ResponsiveBox height="40px">
                    <ResponsiveImage src="/images/sisu-health-logo.png" alt="SiSU Logo" height="100%" />
                </ResponsiveBox>
            </ResponsiveFlex>
        ) : null}
    </>
);

PrintHeader.propTypes = {
    printable: PropTypes.bool
};

export default withPrintable(PrintHeader);
