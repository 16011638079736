import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { Flex } from "rebass";
import { Choice } from "../Buttons";
import theme from "../../../themes";
import QuestionTitle from "./Title";
import QuestionIcon from "./QuestionIcon";
import Help from "./Help";
import { useTranslation } from "react-i18next";

const MultipleAnswer = ({ id, model, onAnswersChange }) => {
    const { t } = useTranslation();

    const handleSelect = text => {
        const updated = [...answers, text];
        setAnswers(updated);
        onAnswersChange(updated);
    };

    const handleDeselect = text => {
        const updated = answers.filter(a => a !== text);
        setAnswers(updated);
        onAnswersChange(updated);
    };

    const choices = model.getChoices();
    const title = model.getDisplayText();
    const iconType = model.getQuestionIcon();
    const helpText = model.getHelpText();
    const [answers, setAnswers] = useState([]);
    return (
        <Flex alignItems="center" justifyContent={"flex-start"} flexDirection={"column"} overflow={"auto"}>
            {iconType && <QuestionIcon type={theme.icons.types[iconType]} />}
            <QuestionTitle htmlFor={id}>{t(title)}</QuestionTitle>
            {helpText && <Help>{t(helpText)}</Help>}
            <Flex alignItems="center" flexDirection={"column"} overflow={"auto"}>
                {choices.map((choice, index) => (
                    <Choice
                        key={index}
                        size={"large"}
                        selected={answers.some(a => a === choice.label)}
                        schema={choice}
                        onSelect={handleSelect}
                        onDeselect={handleDeselect}
                    />
                ))}
            </Flex>
        </Flex>
    );
};

MultipleAnswer.propTypes = {
    id: PropTypes.any,
    model: PropTypes.object,
    onAnswersChange: PropTypes.func
};

export default withTheme(MultipleAnswer);
