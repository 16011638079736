/**
 * A curried function to match size with desired value for each size, streamlined in one object
 * @param {Object<string, any>} sizeToMatch A object that return desired result for different size
 * @returns {(size: string) => any} A function that take size and return matched result
 */
export function matchSize(
    sizeToMatch = {
        small: "",
        medium: "",
        large: ""
    }
) {
    return function(size) {
        return sizeToMatch[size];
    };
}

/**
 * An util function to convert SiSU typographic configuration to Grommet one
 * @param {Object<string, Object<string, any>>} sisuTypo SiSU typography object
 * @returns {Object<string, any>} Grommet typography theme object
 */
export function convertSisuTypographyToGrommet(sisuTypo) {
    const baseObject = {
        font: {},
        level: {}
    };

    for (let i = 1; i <= 6; i++) {
        baseObject.level[i] = {
            large: { size: sisuTypo[`heading${i}`].size, height: "inherit" },
            medium: { size: sisuTypo[`heading${i}`].size, height: "inherit" },
            small: { size: sisuTypo[`heading${i}`].size, height: "inherit" },
            xlarge: { size: sisuTypo[`heading${i}`].size, height: "inherit" }
        };
    }

    return baseObject;
}

/**
 * An util function to convert SiSU typographic configuration to Rebass one
 * @param {Object<string, Object<string, any>>} sisuTypo SiSU typography object
 * @returns {Array<string>} Rebass typography theme object
 */
export function convertSisuTypographyToRebass(sisuTypo) {
    const baseObject = [];
    Object.keys(sisuTypo)
        .reverse()
        .forEach(key => {
            const sizeRem = sisuTypo[key].sizeRem;
            baseObject.push(sizeRem);
            baseObject[key] = sizeRem;
        });

    return baseObject;
}

/**
 * Util function to convert from rbg to rgba
 * @param  {Array<number>} rbg - rgb arrya
 * @param  {number} a - alpha value
 * @returns {string} rgba CSS styling
 */
export function rgba(rbg = [], a = 1) {
    return `rgba(${rbg.join()},${a})`;
}

/**
 * Generate global typography style
 *
 * @param {Object<string, any>} - Theme object to generate
 * @returns {string} - Generated global style
 */
export function generateGlobalTypographyStyle({ text = {} }) {
    const headingStyle = Object.keys(text)
        .filter(key => key.includes("heading"))
        .map(
            key => `
                ${key.replace("heading", "h")} {
                    font-size: ${text[key].sizeRem};
                }}
            `
        );

    const finalStyle = `
        ${headingStyle} 
        p {
            font-size: ${text.paragraph.sizeRem};
        }
    `;

    return finalStyle;
}
