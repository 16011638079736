import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled.button`
    font-weight: normal;
    font-size: ${props => props.theme.text.heading4.sizeRem};
    background-color: ${props => props.theme.colours.flat.white.hex};
    color: ${props => props.theme.colours.flat.darkGray.hex};
    border-radius: 0.2em;
    padding: 0.5em 1.2em;
    min-width: 6em;
    border: 2px solid ${props => props.theme.colours.flat.lightGray.hex};
    visibility: ${props => (props.visible ? "visible" : "hidden")};

    :hover {
        background-color: ${props => props.theme.colours.flat.white.hex};
        ${props => props.theme.shadowCss.elevated()};
    }

    :active {
        background-color: ${props => props.theme.colours.flat.white.hex};
        font-family: "Proxima Nova Semibold", Arial, sans-serif;
        ${props => props.theme.shadowCss.elevated()};
    }

    :focus {
        outline: none;
    }

    :disabled {
        color: ${props => props.theme.colours.flat.gray.hex};
    }
`;

export default class Back extends Component {
    static displayName = "Back Button";
    static propTypes = {
        onClick: PropTypes.func.isRequired,
        visible: PropTypes.bool.isRequired,
        children: PropTypes.string.isRequired
    };

    render() {
        return (
            <StyledButton onClick={this.props.onClick} visible={this.props.visible}>
                {this.props.children}
            </StyledButton>
        );
    }
}
