import styled from "styled-components";
import Icon from "../../Icons/Icon";
import theme from "../../../themes";

const QuestionIcon = styled(Icon).attrs(() => ({
    size: theme.icons.sizes.question,
    colour: theme.colours.flat.darkGray.hex
}))``;

export default QuestionIcon;
