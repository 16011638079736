import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Paragraph from "../../Survey/QuestionTypes/Help";

const HelpText = styled(Paragraph)`
    font-size: ${props => props.theme.text.heading5.sizeRem};
    color: ${props => props.theme.colours.flat.darkGray.hex};
    text-align: center;
    user-select: none;
`;

const Help = ({ children, ...rest }) => {
    return <HelpText {...rest}>{children}</HelpText>;
};

Help.propTypes = {
    children: PropTypes.node
};

export default withTheme(Help);
