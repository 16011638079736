import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { Flex } from "rebass";
import { PORTAL_IMAGE } from "constants/imagePath";
import { background } from "styled-system";

export const PrimaryCards = styled(Flex)`
    position: relative;
    border-radius: 5px;

    ${background}
`;

const StyledImage = styled(Flex)`
    left: 0;
    bottom: 0;
    width: 100%; 
    height: 100%;
    position: absolute;
    background-size: cover;
    background-image: url("${PORTAL_IMAGE}/premium/billing-status.svg");
`;

const OverviewCard = ({ children, ...rest }) => (
    <PrimaryCards
        flexGrow={1}
        flexBasis="169px"
        p={["16px", "", "24px"]}
        minHeight={["184px", "130px", "165px"]}
        {...rest}
    >
        <StyledImage p={["16px", "", "24px"]} alignItems="center" />
        {children}
    </PrimaryCards>
);

OverviewCard.propTypes = {
    ...PrimaryCards.propTypes,
    children: PropTypes.any
};

export default OverviewCard;
