import React from "react";
import PropTypes from "prop-types";
import MultipleTexts from "../../Survey/QuestionTypes/MultipleTexts";
import { Flex, Box } from "../../Flex";
import Title from "../Title";
import { useTranslation } from "react-i18next";

const Fields = [
    {
        id: "medicare-expiry-1",
        label: "Month",
        value: "",
        placeholder: "01",
        regex: /^0[1-9]|10|11|12$/g,
        proportion: 1
    },
    {
        id: "medicare-expiry-2",
        label: "Year",
        value: "",
        placeholder: "2020",
        regex: /^20[0-9]{2}$/g,
        proportion: 1.5
    }
];
const MedicareExpiry = ({
    title,
    helpText = "",
    errors = [],
    onFocusChanged = () => {},
    onValuesChanged = () => {}
}) => {
    const { t } = useTranslation();

    const componentTitle = title ? title : t("web-components.components.medicare.expiry.default_title");

    return (
        <Flex fd="column" ai="center" jc="center" flex="1">
            <Title mb="1rem">{componentTitle}</Title>
            <Box width="20rem">
                <MultipleTexts
                    helpText={helpText}
                    fields={Fields}
                    onFocusChanged={e => onFocusChanged(e)}
                    onValuesChanged={e => onValuesChanged(e)}
                    errors={errors}
                    divider={"/"}
                    jointCharacter={"-"}
                />
            </Box>
        </Flex>
    );
};

MedicareExpiry.propTypes = {
    title: PropTypes.string,
    helpText: PropTypes.string,
    onFocusChanged: PropTypes.func,
    onValuesChanged: PropTypes.func,
    errors: PropTypes.array
};

export default MedicareExpiry;
