/**
 * @param {object} fields fields with css properties
 * @description parse css properties for different media queries
 */
const populateFields = fields => {
    let fieldsDesktop = {};
    let fieldsTablet700 = {};
    let fieldsTablet600 = {};
    let fieldsMobile400 = {};

    Object.keys(fields).map(key => {
        if (!fields[key]) return;

        if (typeof fields[key][0] !== "undefined" || fields[key][0] !== "null") {
            fieldsDesktop[key] = fields[key][0];
        }
        if (typeof fields[key][1] !== "undefined" || fields[key][1] !== "null") {
            fieldsTablet700[key] = fields[key][1];
        }
        if (typeof fields[key][2] !== "undefined" || fields[key][2] !== "null") {
            fieldsTablet600[key] = fields[key][2];
        }
        if (typeof fields[key][3] !== "undefined" || fields[key][3] !== "null") {
            fieldsMobile400[key] = fields[key][3];
        }
        return key;
    });

    return [fieldsDesktop, fieldsTablet700, fieldsTablet600, fieldsMobile400];
};

/**
 * @param {object} fields fields with css properties
 * @description create media query as css-type class strings
 * @example
 *
 * body {
 *  width: 10px
 * }
 */
export const mediaQueryForCss = fields => {
    const populatedFields = populateFields(fields);
    return `
    @media screen and (max-width: 1199px)${JSON.stringify(populatedFields[0])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    @media screen and (max-width: 1024px)${JSON.stringify(populatedFields[0])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    @media screen and (max-width: 991px)${JSON.stringify(populatedFields[0])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    @media screen and (max-width: 768px)${JSON.stringify(populatedFields[1])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    @media screen and (max-width: 600px)${JSON.stringify(populatedFields[2])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    @media screen and (max-width: 375px)${JSON.stringify(populatedFields[3])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    `;
};

export const mqCssForIntermediateScreens = fields => {
    const populatedFields = populateFields(fields);
    return `
    @media (min-width: 376px) and (max-width: 420px)${JSON.stringify(populatedFields[0])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    @media (min-width: 421px) and (max-width: 550px)${JSON.stringify(populatedFields[1])
        .replace(/"/g, "")
        .replace(/,/g, ";")}
    `;
};

export const mqIntermediateScreens = fields => {
    const populatedFields = populateFields(fields);
    return {
        "@media (min-width: 376px) and (max-width: 420px)": { ...populatedFields[0] },
        "@media (min-width: 421px) and (max-width: 550px)": { ...populatedFields[1] }
    };
};

/**
 * @param {object} fields fields with css properties
 * @description create media query as css-type class objects
 * @example
 *
 * body: {
 *  backgroundSize: '10px 10px'
 * }
 */
export const mediaQuery = fields => {
    const populatedFields = populateFields(fields);

    return {
        "@media screen and (max-width: 1199px)": { ...populatedFields[0] },
        "@media screen and (max-width: 1024px)": { ...populatedFields[0] },
        "@media screen and (max-width: 991px)": { ...populatedFields[0] },
        "@media screen and (max-width: 768px)": { ...populatedFields[1] },
        "@media screen and (max-width: 600px)": { ...populatedFields[2] },
        "@media screen and (max-width: 375px)": { ...populatedFields[3] }
    };
};

/**
 * @param {object} fields fields with css properties
 * @param {Array} size screen sizes to target
 * @description create media query as css-type class objects for any given sizes
 * @example
 *
 * body: {
 *  backgroundSize: '10px 10px'
 * }
 */
export const mediaQueryForSize = (fields, size) => {
    if (!size || (size && !size.length)) return;
    let tempFields = {};
    let tempProps = {};
    Object.keys(fields).map(key => {
        if (fields[key] && fields[key].length) {
            fields[key].map((value, index2) => {
                return (tempProps[index2] = { ...tempProps[index2], [key]: value });
            });
        }
        return key;
    });
    size.map((x, index) => (tempFields[`@media screen and (max-width: ${x}px)`] = tempProps[index]));
    return tempFields;
};

/**
 *  @description checks if key presses are of specific type
 */
export const keyPress = {
    isBackSpace: event => event.keyCode === 8,
    isNumeric: event => event.keyCode >= 48 && event.keyCode <= 57,
    isTabKey: event => event.keyCode === 9,
    isRightArrowKey: event => event.keyCode === 39,
    isSelectKeyCombination: event => event.ctrlKey && (event.keyCode === 65 || event.keyCode === 97)
};
