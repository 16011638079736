import React from "react";
import { path } from "ramda";
import { Cell, Header } from "./commonElements";
import { t } from "i18next";

export const diabetesRiskDef = iconTypes => ({
    minWidth: 92,
    accessor: "ausdriskScoreValue",
    Cell: row => <Cell row={row} value={path(["value"], row)} />,
    Header: () => <Header label={t("words.diabetes_risk")} iconType={iconTypes.diabetesRisk} />
});
