import React from "react";
import styled from "styled-components";

const StyledA = styled.a`
    color: inherit;
    text-decoration: none;

    :active {
        text-decoration: none;
    }

    :visited {
        text-decoration: none;
    }

    ${props =>
        props.disabled
            ? `
        color: ${props.theme.colours.flat.gray.hex} !important;
        pointer-events: none;
        user-select: none;
    `
            : ``}
`;

const AnchorLink = props => {
    return <StyledA {...props} />;
};

export default AnchorLink;
