import React from "react";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { createGlobalStyle } from "styled-components";
import { media } from "../../themes";

const ModalTransition = createGlobalStyle`
    .ReactModal__Overlay {
        opacity: 0;
        transition: all ${props => `${props.animationDuration}ms`} ease-in-out;
    }

    .ReactModal__Overlay--after-open {
        opacity: 1
    }

    .ReactModal__Overlay--before-close {
        opacity: 0
    }

    .ReactModal__Content {
        top: 0;
        bottom: 0;
        transition: all ${props => `${props.animationDuration}ms`} ease-in-out;
        transform: translateX(150%);
        ${props => props.theme.shadowCss.rest()};

        ${media.tablet`
            left: 0 !important;
        `};  
    }

    .ReactModal__Content--after-open {
        transform: translateX(0%)
    }

    .ReactModal__Content--before-close {
        transform: translateX(150%);
    }
`;

const Modal = ({ isOpen, animationDuration, children, ...rest }) => {
    return (
        <>
            <ModalTransition animationDuration={animationDuration} />
            <ReactModal
                appElement={document.getElementById("root")}
                closeTimeoutMS={animationDuration}
                isOpen={isOpen}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0, 0, 0, 0.15)"
                    },
                    content: {
                        top: "0",
                        bottom: "0",
                        right: "0",
                        left: "40%",
                        borderRadius: "0px",
                        border: "none",
                        padding: "0px"
                    }
                }}
                {...rest}
            >
                {children}
            </ReactModal>
        </>
    );
};

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    animationDuration: PropTypes.number,
    children: PropTypes.node.isRequired
};

Modal.defaultProps = {
    isOpen: false,
    animationDuration: 300
};

export default Modal;
