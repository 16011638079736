import BaseEndPoint from "../BaseEndPoint";

export default class Ads extends BaseEndPoint {
    constructor(client) {
        super(client);

        this.getUrlPath = this.getUrlPath.bind(this);
    }

    getUrlPath(path) {
        return super.getUrlPath("/ads/" + path);
    }

    /**
     * @param {number} campaignId
     * @param {string} adSlotCode
     * @param {object} criteria
     * @param {array} replacements
     * @returns {*}
     */
    getAdContent(campaignId, adSlotCode, criteria, replacements) {
        return this.post("", {
            campaignId,
            adSlotCode,
            criteria,
            replacements
        });
    }
}
