import React from "react";
import PropTypes from "prop-types";
import { pathOr } from "ramda";
import APIClient from "utilities/APIClient";
import { Loading, WYSIWYG } from "@sisuwellness/web-components";
import PageContainer from "components/PageContainer";
import { useAsyncData } from "@sisuwellness/utilities/hooks";
import { DEFAULT_LOCALE } from "../../constants";
import { useTranslation } from "react-i18next";

const TermsOfUse = ({ match }) => {
    const { t } = useTranslation();
    const localeTag = pathOr(DEFAULT_LOCALE, ["params", "locale"], match);

    const [{ data, isLoading, isStarted }] = useAsyncData(() => APIClient.consents.termsOfUse(localeTag), {
        html: ""
    });

    if (!isStarted || isLoading) {
        return <Loading title={t("words.loading") + "..."} />;
    }

    return (
        <PageContainer>
            <WYSIWYG dangerouslySetInnerHTML={{ __html: data.html }} />
        </PageContainer>
    );
};

TermsOfUse.propTypes = {
    match: PropTypes.object
};

export default TermsOfUse;
