import React, { Component } from "react";
import PropTypes from "prop-types";
import OneQuestionAtATime from "./OneQuestionAtATime";

class QuestionGroup extends Component {
    static defaultProps = {
        touchKeyboard: false
    };

    handleOnComplete(model) {
        this.props.onComplete(model);
    }

    canAskNextQuestion(question) {
        return this.props.canAskNextQuestion ? this.props.canAskNextQuestion(question) : true;
    }

    render() {
        let questionGroupToRender = null;
        switch (true) {
            case this.props.model.isDisplayTypeOneQuestionAtATime():
                questionGroupToRender = (
                    <OneQuestionAtATime
                        model={this.props.model}
                        onComplete={model => this.handleOnComplete(model)}
                        canAskNextQuestion={question => this.canAskNextQuestion(question)}
                        touchKeyboard={this.props.touchKeyboard}
                        onChangeCurrentQuestion={this.props.onChangeCurrentQuestion}
                        getComponentForQuestionType={this.props.getComponentForQuestionType}
                    />
                );
                break;

            default:
                throw new Error("Unsupported displayType [" + this.props.model.displayType + "]");
        }

        return questionGroupToRender;
    }
}

QuestionGroup.displayName = "Survey Question Group";
QuestionGroup.propTypes = {
    model: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
    canAskNextQuestion: PropTypes.func,
    onChangeCurrentQuestion: PropTypes.func.isRequired,
    example: PropTypes.object,
    touchKeyboard: PropTypes.bool,
    getComponentForQuestionType: PropTypes.func
};

export default QuestionGroup;
