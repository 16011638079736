import React from "react";
import PropTypes from "prop-types";

import { Card } from "rebass";

// ------------------------------------------------------------------------

const SquaredCard = React.forwardRef(({ children, ...rest }, forwardRef) => (
    <Card ref={forwardRef} width="346px" height="324px" p="32px" pb="24px" bg="#f9fbff" {...rest}>
        {children}
    </Card>
));

SquaredCard.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};

export default SquaredCard;
