// Health check reducer
export const SET_HEALTH_CHECKS = "SET_HEALTH_CHECKS";

// Health station reducer
export const SET_HEALTH_STATIONS = "SET_HEALTH_STATIONS";

export const SET_PAGINATION_STATS = "SET_PAGINATION_STATS";
export const SET_PAGINATION_HEALTH_CHECKS = "SET_PAGINATION_HEALTH_CHECKS";
export const SET_PAGINATION_HEALTH_CHECKS_FETCH_STATUS = "SET_PAGINATION_HEALTH_CHECKS_FETCH_STATUS";

// Device location resolution actions
export const SET_DEVICE_LOCATION = "SET_DEVICE_LOCATION";
