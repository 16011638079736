import BaseValidator from "./BaseValidator";

export default class TextValidator extends BaseValidator {
    /**
     *
     * @param value
     * @return {Array}
     */
    validate(value) {
        let errors = [];
        if (typeof value !== "string") {
            errors.push(value + " is not a string");
        }

        return errors;
    }
}
