import { ACTIVITY, BODY_FAT, BODY_MASS_INDEX, MUSCLE_MASS, WEIGHT } from "constants/trend-charts";
import { TYPE_PROPS_MAP } from "utilities/trend-chart/type-props-map";

const getTooltipValues = (type, data) => {
    // TODO: if target value to change to st and pounds for weight metric
    // eslint-disable-next-line
    const { guideline, isUnitMetric, currentValue } = data;

    const props = TYPE_PROPS_MAP[type] || {};

    let unit = props.metricUnit ?? "",
        tooltipValues = {};

    if (!isUnitMetric) unit = props.imperialUnit ? props.imperialUnit.toLowerCase() : unit;

    tooltipValues = { value: currentValue, target: "", unit };

    switch (type) {
        case WEIGHT: {
            let value = isUnitMetric ? guideline.setUnitToMetric() : guideline.setUnitToImperial();
            let target = guideline.targetWeight;
            return { ...tooltipValues, value, target, unit };
        }

        case BODY_MASS_INDEX:
            return { ...tooltipValues, target: 24 };

        case ACTIVITY:
            return { value: "", target: "", unit: "" };

        case BODY_FAT:
            return { ...tooltipValues, unit: "%" };

        case MUSCLE_MASS:
            return { ...tooltipValues, unit: "%" };

        default:
            return tooltipValues;
    }
};

export default getTooltipValues;
