import PropTypes from "prop-types";

import { PrimaryButton } from "./styled";
import { Box } from "rebass";

PrimaryButton.propTypes = {
    ...Box.propTypes,
    disabledColor: PropTypes.string,
    disabledBorderColor: PropTypes.string,
    disabledBackgroundColor: PropTypes.string
};

export default PrimaryButton;
