import React, { useState } from "react";
import PropTypes from "prop-types";

//------------------------------------------------------------------------------

import { default as Provider } from "components/Context/messageContext";
import { MESSAGE_ERROR, MESSAGE_GENERAL, MESSAGE_SUCCESS } from "constants/messages";

//------------------------------------------------------------------------------

const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState({});
    const [isDismissible, setIsDismissible] = useState(false);

    /**
     * sets message state wrt to type
     * @param {MESSAGE_ERROR | MESSAGE_GENERAL | MESSAGE_SUCCESS | "null"} type specifies the type of message
     * @param {string} msg specifies the message corresponding to the type
     */
    const handleMessage = (type, msg, isDismissible = false) => {
        handleDissmissible(isDismissible);
        setMessage([MESSAGE_ERROR, MESSAGE_GENERAL, MESSAGE_SUCCESS].includes(type) ? { [type]: msg } : {});
    };

    /**
     * sets flag for dissmissible state
     * @param {boolean} flag
     */
    const handleDissmissible = (flag = false) => setIsDismissible(flag);

    return (
        <Provider
            value={{
                message: message,
                handleMessage: handleMessage,
                isDismissible: isDismissible
            }}
        >
            {children}
        </Provider>
    );
};

MessageProvider.propTypes = {
    children: PropTypes.any.isRequired
};

export default MessageProvider;
