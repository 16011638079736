import React from "react";
import PropTypes from "prop-types";
import { ErrorText } from "./";
import styled from "styled-components";

const StyledTextArea = styled.textarea`
    font-size: ${props => props.theme.text.heading4.sizeRem};
    padding: 0.5em;
    width: 100%;
    border: ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.gray.hex)} 1px solid;
    color: ${props => props.theme.colours.flat.gray.hex};
    box-sizing: border-box;

    ::placeholder {
        color: ${props => props.theme.colours.flat.gray.hex};
    }

    :focus {
        resize: none;
        outline: none;
        border: ${props => (props.errors ? props.theme.colours.flat.rose.hex : props.theme.colours.flat.gray.hex)} 1px
            solid;
        color: ${props => props.theme.colours.flat.black.hex};
    }
`;

const TextArea = ({ errors, ...rest }) => (
    <>
        <StyledTextArea {...rest} errors={errors} />
        {errors && <ErrorText>{errors}</ErrorText>}
    </>
);

TextArea.propTypes = {
    errors: PropTypes.string
};

export default TextArea;
