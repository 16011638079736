import React from "react";
import PropTypes from "prop-types";

import { Card, Flex, Box } from "rebass";
import styled from "styled-components";
import { compose, layout, border, flex, position } from "styled-system";

const layoutSpecs = {
    normal: {
        card: { p: "32px", borderRadius: "10px", height: "388px", flexBasis: "345px", flexGrow: 1 }
    },
    small: {
        card: { p: "24px", borderRadius: "10px", height: "297px", flexBasis: "264px", flexGrow: 1 }
    }
};

const StyledCard = styled(Card)(
    compose(
        layout,
        border,
        flex,
        position
    )
);

const SummaryCard = ({ cardHeader, cardBody, cardFooter, scale = "small", ...rest }) => (
    <StyledCard bg="rgba(231, 238, 255, 0.25)" position="relative" {...layoutSpecs[scale]?.card} {...rest}>
        <Flex flexDirection="column" height="100%" alignItems="center" textAlign="center">
            {cardHeader && <Box width="100%">{cardHeader}</Box>}
            {cardBody && <Box size="100%">{cardBody}</Box>}
            {cardFooter && (
                <Box width="100%" display="block" mt="auto" mx="auto">
                    {cardFooter}
                </Box>
            )}
        </Flex>
    </StyledCard>
);

SummaryCard.propTypes = {
    cardBody: PropTypes.any,
    cardHeader: PropTypes.any,
    cardFooter: PropTypes.any,
    scale: PropTypes.oneOf(["normal", "small"])
};

export default SummaryCard;
