import React from "react";
import { default as CustomSelect } from "components/Select";
import { Text } from "rebass";

const Select = props => {
    return (
        <Text width="100%" color="blackTints700" fontSize="13px" fontWeight="light">
            <CustomSelect {...props} />
        </Text>
    );
};

export default Select;
