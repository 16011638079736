import React from "react";
import PropTypes from "prop-types";

import { Link } from "@sisuwellness/web-components";
import { NavHeading, NavImage, NavItem, NavText } from "./styled";
import { useTranslation } from "react-i18next";

export const LinkCard = ({ item }) => {
    const { t } = useTranslation();

    return (
        <Link to={`${item.linkTo}`}>
            <NavItem item={item}>
                <div>
                    <NavHeading>{t(item.heading, item.heading)}</NavHeading>
                    <NavText>{t(item.text, item.text)}</NavText>
                </div>
                <NavImage src={item.image}></NavImage>
            </NavItem>
        </Link>
    );
};

LinkCard.propTypes = {
    item: PropTypes.object
};
