import styled from "styled-components";
import Flex from "components/Flex";

export const CircularCard = styled(Flex).attrs({ ai: "center", jc: "center" })`
    min-width: 65px;
    background: white;
    border-radius: 50%;
`;

export const LabelBox = styled(Flex).attrs({ ai: "center", jc: "center" })`
    height: 28px;
    font-size: 15px;
    min-width: 100px;
    border-radius: 2px;
    background-color: white;
    border: solid 1px ${({ theme }) => theme.colours.hpPrimaryPurple.hex};
`;

export const StyledSwtich = styled(Flex)`
    height: 28px;
    width: 100px;
    font-size: 15px;
    background: white;
    border: 1px solid ${({ theme }) => theme.colours.hpPrimaryPurple.hex};
`;

export const SwitchTabs = styled(Flex).attrs({ ai: "center", jc: "center" })`
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: ${({ isActive, theme }) => (isActive ? "white" : theme.colours.hpPrimaryPurple.hex)};
    background: ${({ isActive, theme }) => (isActive ? theme.colours.hpPrimaryPurple.hex : "transparent")};
`;
