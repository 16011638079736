import { CountryHelper, GooglePlaces } from "@sisuwellness/utilities/User";
import { setDeviceLocation } from "actions/device-location";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const DEFAULT_COUNTRY_NAME = "Australia";
export const getDeviceCountryCode = async (pos, geocoder) => {
    const response = await geocoder.geocode({ location: pos });
    const result = response?.results?.map(GooglePlaces.getAddressComponents)?.[0];
    const countryName = result?.country ?? DEFAULT_COUNTRY_NAME;
    var country = CountryHelper.getCountryByName(countryName);
    if (!country.code) country = CountryHelper.getCountryByName(DEFAULT_COUNTRY_NAME);

    return country.code;
};

const DeviceLocationResolution = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };

                    if (window.google) {
                        const geocoder = new window.google.maps.Geocoder();
                        getDeviceCountryCode(pos, geocoder).then(countryCode =>
                            dispatch(setDeviceLocation(countryCode))
                        );
                    } else {
                        handleLocationError(true);
                    }
                },
                () => handleLocationError(true),
                { maximumAge: 60000, timeout: 5000, enableHighAccuracy: true }
            );
        } else {
            // Browser doesn't support Geolocation
            handleLocationError(false);
        }

        function handleLocationError(browserHasGeolocation) {
            // eslint-disable-next-line no-console
            console.error(
                browserHasGeolocation
                    ? "Error: The Geolocation service failed."
                    : "Error: Your browser doesn't support geolocation."
            );
        }
    }, [navigator.geolocation, window.google]);

    return <></>;
};

export default DeviceLocationResolution;
