import React from "react";
import PropTypes from "prop-types";

import * as R from "ramda";
import { Box, Flex, Image, Text } from "rebass";
import { withTheme } from "styled-components";

import styled from "styled-components";
import { isBoolean } from "lodash-es";

//........................................................

import {
    BLOOD_PRESSURE,
    BODY_FAT,
    BODY_MASS_INDEX,
    DIABETES,
    HEART_RATE,
    QRISK3,
    ALCOHOL_RISK,
    TOTAL_CHOLESTEROL,
    HDL_CHOLESTEROL,
    NON_FASTING_GLUCOSE,
    ACTIVITY,
    STRESS_PSS4,
    WEIGHT,
    HRA_LIFESTYLE_SCORE,
    HRA_SOCIAL_SCORE,
    HRA_EMOTIONAL_SCORE,
    HEIGHT,
    SMOKING
} from "constants/trend-charts";
import { HealthCardForDashboard as HealthCard } from "components/HealthCard";
import AdSlotView from "components/AdSlotView";
import HealthMetrics from "utilities/metrics";
import { PORTAL_IMAGE } from "constants/imagePath";
import { AD_SLOT_CODES } from "@sisuwellness/utilities/CampaignAd";
import { GridLayout, BroughtToByCard } from "../styled";

import {
    pickAttribute,
    consolidateMetricData,
    actualAgeRecordedAtTheTimeOfLastHealthCheck
} from "utilities/commonUtils";
import { Guidelines } from "@sisuwellness/utilities";
import { PremiumFeature, usePremiumFeature } from "components/PremiumFeatures";
import MetricCardArrangement from "components/Templates/MetricCards";
import { calculateHeartAge } from "utilities/premium";
import CategorisedWidget from "./categorisedWidget";
import { MuscleMassDashboardCard } from "components/Templates/OutcomeCards/MuscleMass";
import { useTranslation } from "react-i18next";

//........................................................

export const CustomGridLayout = styled(GridLayout)`
    @media screen and (max-width: 1350px) and (min-width: 1024px) {
        width: "80%";
    }
`;

//........................................................

const HealthHubMetrics = ({ citizen, campaignId, theme, setisCardPresent, healthChecks, ...rest }) => {
    const { canBePremium, isPremium } = usePremiumFeature();
    const { t } = useTranslation();

    // Utils
    const toNumber = val => (val || val === 0 ? Number(val) : null);
    const parseIntWith10Radix = val => (val ? parseInt(val, 10) : null);

    const latestData = consolidateMetricData(citizen, healthChecks);

    const sys = R.compose(
        toNumber,
        pickAttribute(["sys"])
    )(latestData);

    const dia = R.compose(
        toNumber,
        pickAttribute(["dia"])
    )(latestData);

    const weight = R.compose(
        toNumber,
        pickAttribute(["weight"])
    )(latestData);

    const height = R.compose(
        toNumber,
        pickAttribute(["height"])
    )(latestData);

    const bmi = R.compose(
        toNumber,
        pickAttribute(["bmi"])
    )(latestData);

    const bodyFatPercent = R.compose(
        toNumber,
        pickAttribute(["bodyFatPercent"])
    )(latestData);

    const hr = R.compose(
        toNumber,
        pickAttribute(["hr"])
    )(latestData);

    const ausdriskScoreValue = R.compose(
        parseIntWith10Radix,
        pickAttribute(["ausdriskScoreValue"])
    )(latestData);

    const alcoholScore = R.compose(
        parseIntWith10Radix,
        pickAttribute(["alcoholScore"])
    )(latestData);

    const qriskScore = R.compose(
        toNumber,
        parseFloat,
        pickAttribute(["qriskScore"])
    )(latestData);

    const pss4Score = R.compose(
        toNumber,
        parseFloat,
        pickAttribute(["pss4Score"])
    )(latestData);

    const isSmoker = pickAttribute(["isSmoker"], latestData);

    const HRASocialScore = R.compose(
        toNumber,
        parseFloat,
        pickAttribute(["socialScore", "value"])
    )(latestData);

    const HRAEmotionalScore = R.compose(
        toNumber,
        parseFloat,
        pickAttribute(["emotionalScore", "value"])
    )(latestData);

    const HRALifestyleScore = R.compose(
        toNumber,
        parseFloat,
        pickAttribute(["lifestyleIndex", "value"])
    )(latestData);

    const activityRating = pickAttribute(["activityRating"])(latestData);
    const totalCholesterol = pickAttribute(["totalCholesterol"], latestData);
    const hdlCholesterol = pickAttribute(["hdlCholesterol"], latestData);
    const nonFastingGlucose = pickAttribute(["nonFastingGlucose"], latestData);
    const sleepReportResults = pickAttribute(["sleepReportResults"], citizen);

    const lifestyle = {
        healthRiskScore: pickAttribute(["analytics", "healthRiskScore"], citizen),
        segmentCount: pickAttribute(["comparisons", "healthRiskScore", "total"], citizen),
        gender: pickAttribute(["analytics", "gender"], citizen),
        ageGroup: pickAttribute(["analyticsComplex", "ageGroup"], citizen),
        percentile: pickAttribute(["comparisons", "healthRiskScore", "percentile"], citizen),
        date: pickAttribute(["analytics", "updatedAt"], citizen)
    };

    const recommendations = pickAttribute(["recommendations"], latestData) || [];
    recommendations.push({
        risk: HEIGHT,
        text: Guidelines.Height.SiSU.recommendations()
    });
    latestData["recommendations"] = recommendations;

    const { heartAge = null, rating = {} } = calculateHeartAge({
        ...latestData,
        age: actualAgeRecordedAtTheTimeOfLastHealthCheck(healthChecks)
    });
    const heartAgeRecommendation = R.filter(R.propEq("risk", "HEART_AGE"), recommendations)[0] || { textPortal: "" };

    const healthCheck = {
        ...latestData,
        HRASocialScore,
        HRAEmotionalScore,
        HRALifestyleScore,
        recommendations
    };

    const cardiovascularWidgets = [],
        bodyWeightAndCompositionWidget = [],
        metabolicHealthWidgets = [],
        mentalHealthWidgets = [],
        hraWidgets = [];

    // Body Weight and Composition Widgets

    if (Number.isFinite(weight))
        bodyWeightAndCompositionWidget.push(index => (
            <HealthMetrics type={WEIGHT} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="weight-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(height))
        bodyWeightAndCompositionWidget.push(index => (
            <HealthMetrics type={HEIGHT} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="height-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(bodyFatPercent))
        bodyWeightAndCompositionWidget.push(index => (
            <HealthMetrics type={BODY_FAT} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="body-fat-metric" />;
                }}
            </HealthMetrics>
        ));

    if (bmi)
        bodyWeightAndCompositionWidget.push(index => (
            <HealthMetrics type={BODY_MASS_INDEX} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="bmi-metric" />;
                }}
            </HealthMetrics>
        ));

    const muscleMass = pickAttribute(["muscleMass"], latestData);
    if (muscleMass && canBePremium)
        bodyWeightAndCompositionWidget.push(index => (
            <MuscleMassDashboardCard key={index} order={index} value={latestData} isPremium={isPremium} />
        ));

    // Cardiovascular Widgets

    // Check if recommendation for BP is Present ( Esp. for HRA BP )
    const BPRecommendations = R.find(R.propEq("risk", BLOOD_PRESSURE), recommendations || []);
    if ((Number.isFinite(sys) && Number.isFinite(dia)) || BPRecommendations)
        cardiovascularWidgets.push(index => (
            <HealthMetrics type={BLOOD_PRESSURE} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="blood-pressure-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(hr))
        cardiovascularWidgets.push(index => (
            <HealthMetrics type={HEART_RATE} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="heart-rate-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(qriskScore))
        cardiovascularWidgets.push(index => (
            <HealthMetrics type={QRISK3} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="qrisk-metric" />;
                }}
            </HealthMetrics>
        ));

    if (isBoolean(isSmoker))
        cardiovascularWidgets.push(index => (
            <HealthMetrics type={SMOKING} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="smoking-metric" />;
                }}
            </HealthMetrics>
        ));

    if (totalCholesterol)
        cardiovascularWidgets.push(index => (
            <HealthMetrics type={TOTAL_CHOLESTEROL} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="cholesterol-metric" />;
                }}
            </HealthMetrics>
        ));

    if (hdlCholesterol)
        cardiovascularWidgets.push(index => (
            <HealthMetrics type={HDL_CHOLESTEROL} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="hdl-cholesterol-metric" />;
                }}
            </HealthMetrics>
        ));

    // Metabolic Health

    if (Number.isFinite(ausdriskScoreValue))
        metabolicHealthWidgets.push(index => (
            <HealthMetrics type={DIABETES} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="diabetes-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(alcoholScore))
        metabolicHealthWidgets.push(index => (
            <HealthMetrics type={ALCOHOL_RISK} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="alcohol-metric" />;
                }}
            </HealthMetrics>
        ));

    if (activityRating)
        metabolicHealthWidgets.push(index => (
            <HealthMetrics type={ACTIVITY} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="activity-metric" />;
                }}
            </HealthMetrics>
        ));

    if (nonFastingGlucose)
        metabolicHealthWidgets.push(index => (
            <HealthMetrics type={NON_FASTING_GLUCOSE} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="nonfasting-glucose-metric" />;
                }}
            </HealthMetrics>
        ));

    // Mental Health Widgets

    if (Number.isFinite(pss4Score))
        mentalHealthWidgets.push(index => (
            <HealthMetrics type={STRESS_PSS4} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="pss4-metric" />;
                }}
            </HealthMetrics>
        ));

    // HRA Widgets

    if (Number.isFinite(HRALifestyleScore))
        hraWidgets.push(index => (
            <HealthMetrics type={HRA_LIFESTYLE_SCORE} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="hra-lifestyle-score-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(HRASocialScore))
        hraWidgets.push(index => (
            <HealthMetrics type={HRA_SOCIAL_SCORE} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="hra-social-score-metric" />;
                }}
            </HealthMetrics>
        ));

    if (Number.isFinite(HRAEmotionalScore))
        hraWidgets.push(index => (
            <HealthMetrics type={HRA_EMOTIONAL_SCORE} healthCheck={healthCheck} key={index}>
                {data => {
                    setisCardPresent(true);
                    return <HealthCard order={index} metric={data} data-testid="hra-emotional-score-metric" />;
                }}
            </HealthMetrics>
        ));

    return (
        <Box data-testid="health-hub-metrics" {...rest}>
            <Flex
                width="100%"
                mt={["0px", "16px"]}
                mb="16px"
                flexDirection={["column-reverse", "row"]}
                justifyContent="space-between"
                alignItems={["left", "center"]}
            >
                <Text fontSize="34px" color={theme.colours.hpPrimaryPurple}>
                    {t("members_portal_web.views.health_hub.metrics.text_specs.title")}
                </Text>
                <BroughtToByCard my={["30px", "0"]}>
                    <Text fontSize="15px" fontWeight="normal" color={theme.colours.hpPrimaryPurple}>
                        {t("members_portal_web.views.health_hub.metrics.text_specs.brought_to_you")}
                    </Text>
                    <Image src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`} />
                </BroughtToByCard>
            </Flex>

            <PremiumFeature forceRender>
                {props => (
                    <MetricCardArrangement
                        {...props}
                        lifestyle={lifestyle}
                        sleepReportResults={sleepReportResults}
                        heartAge={{ rating, heartAge, text: heartAgeRecommendation.textPortal }}
                        tdee={latestData}
                    />
                )}
            </PremiumFeature>

            {!!bodyWeightAndCompositionWidget.length && (
                <CategorisedWidget
                    id="body-weight-widget"
                    title={t("members_portal_web.views.health_hub.metrics.bc_weight")}
                    widgetList={bodyWeightAndCompositionWidget}
                />
            )}

            {!!cardiovascularWidgets.length && (
                <CategorisedWidget
                    id="cardiovascular-widget"
                    title={t("members_portal_web.views.health_hub.metrics.cardio")}
                    widgetList={cardiovascularWidgets}
                />
            )}

            {!!metabolicHealthWidgets.length && (
                <CategorisedWidget
                    id="metabolic-health-widget"
                    title={t("members_portal_web.views.health_hub.metrics.meta")}
                    widgetList={metabolicHealthWidgets}
                />
            )}

            {!!mentalHealthWidgets.length && (
                <CategorisedWidget
                    id="mental-health-widget"
                    title={t("members_portal_web.views.health_hub.metrics.mental_health")}
                    widgetList={mentalHealthWidgets}
                />
            )}

            {/* Temporarily disable the HRA until it can be translated */}
            {!!hraWidgets.length && (
                <CategorisedWidget
                    id="hra-widget"
                    title={t("members_portal_web.views.health_hub.metrics.hra")}
                    widgetList={hraWidgets}
                />
            )}

            <Flex flexWrap="wrap" alignItems="flex-start" justifyContent="center" mb="160px">
                <AdSlotView adSlotCode={AD_SLOT_CODES.PORTAL_HEALTH_HUB_FOOTER} campaignId={campaignId} criteria={{}} />
            </Flex>
        </Box>
    );
};

HealthHubMetrics.propTypes = {
    citizen: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    campaignId: PropTypes.number,
    setisCardPresent: PropTypes.func,
    isCardPresent: PropTypes.bool,
    healthChecks: PropTypes.array
};

export default withTheme(HealthHubMetrics);
