import React from "react";
import { Text } from "rebass";
import { NormalLabel } from "@sisuwellness/ui/src/components/Labels";

// Translate?

// Errors
export const REQUEST_FAILED_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.request_failed";
export const NO_NETWORK_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.no_network";
export const INVALID_EMAIL_ADDRESS_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.invalid_email_address";
export const INVALID_REG_LINK_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.invalid_reg_link";
export const NOT_REGISTERED_EMAIL_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.not_registered_email";
export const INCORRECT_PASSWORD_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.incorrect_password";
export const START_END_DATE_REQUIRED_ERROR_MESSAGE =
    "members_portal_web.constants.messages.errors.start_end_date_required";
export const EXPIRED_SESSION_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.expired_session";
export const INVALID_ACCESS_ERROR_MESSAGE = "members_portal_web.constants.messages.errors.invalid_access";

// Identify view
export const CHECK_EMAIL_ACTIVATION_LINK_MESSAGE = "members_portal_web.constants.messages.check_email_activation_link";

// Login view
export const PASSWORD_RESET_SUCCESS_MESSAGE = "members_portal_web.constants.messages.password_reset_success";

// Message types
export const MESSAGE_ERROR = "error";
export const MESSAGE_GENERAL = "general";
export const MESSAGE_SUCCESS = "success";

export const LOADING = {
    MESSAGE: "members_portal_web.constants.messages.loading.message",
    WAITING: "members_portal_web.constants.messages.loading.waiting"
};

export const ERROR_MESSAGES = {
    VALIDATION: {
        MOBILE_NUMBER: {
            AUSTRALIA: "members_portal_web.constants.messages.errors.validation.mobile_number.australia",
            UNITED_KINGDOM: "members_portal_web.constants.messages.errors.validation.mobile_number.united_kingdom"
        }
    }
};

export const HEART_AGE = {
    MODAL: {
        CALCULATE: {
            HEADING: "members_portal_web.constants.messages.heart_age_modal.calculate.heading",
            CONTENT: t => (
                <>
                    <NormalLabel fontSize="18px" fontWeight="300" color="blackTints700" mb="1em">
                        {t("members_portal_web.constants.messages.heart_age_modal.calculate.content.0")}
                    </NormalLabel>
                    <NormalLabel fontSize="18px" fontWeight="300" color="blackTints700">
                        {t("members_portal_web.constants.messages.heart_age_modal.calculate.content.1")}
                    </NormalLabel>
                </>
            )
        },
        HEART_AGE_INFO: {
            HEADING: "members_portal_web.constants.messages.heart_age_modal.information.heading",
            CONTENT: t => (
                <>
                    <Text mb="1em">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.0")}
                    </Text>
                    <Text mb="1em">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.1")}
                    </Text>
                    <Text mb="1em">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.2")}
                        <Text as="sup" fontSize="13px">
                            1 2 3
                        </Text>
                        .
                    </Text>
                    <Text mb="1em">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.3")}
                    </Text>
                    <Text mb="1em" fontSize="13px">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.4")}
                    </Text>
                    <Text mb="1em" fontSize="13px">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.5")}
                    </Text>
                    <Text fontSize="13px">
                        {t("members_portal_web.constants.messages.heart_age_modal.information.content.6")}
                    </Text>
                </>
            )
        },
        HEART_STROKE: {
            HEADING: "members_portal_web.constants.messages.heart_age_modal.stroke.heading",
            CONTENT: t => (
                <>
                    <Text mb="1em">{t("members_portal_web.constants.messages.heart_age_modal.stroke.content.0")}</Text>
                    <Text mb="1em">{t("members_portal_web.constants.messages.heart_age_modal.stroke.content.1")}</Text>
                    {t("members_portal_web.constants.messages.heart_age_modal.stroke.content.2")}
                </>
            )
        }
    }
};
