import React from "react";
import { path } from "ramda";
import { Cell, Header } from "./commonElements";
import { t } from "i18next";

const handleCellConstruct = row => {
    const heartRate = path(["value"], row);
    return <Cell value={heartRate} unit="bpm" row={row} />;
};

export const heartRateDef = iconTypes => ({
    minWidth: 92,
    accessor: "hr",
    Cell: handleCellConstruct,
    Header: () => <Header label={t("words.heart_rate")} iconType={iconTypes.heartRate} />
});
