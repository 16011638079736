import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledG = styled.g`
    @keyframes highlightTransition {
        from {
            transform: scale(1);
        }
        to {
            transform: scale(1.5);
        }
    }

    circle {
        animation-duration: 0.5s;
        ${props => (props.highlight ? `animation-name: highlightTransition; transform: scale(1.5); z-index: 100;` : ``)}
    }
`;

const CustomSymbol = ({ size, borderWidth, borderColor, color, highlight }) => (
    <StyledG highlight={highlight}>
        <circle fill={color} r={size / 2} strokeWidth={borderWidth} stroke={borderColor} />
    </StyledG>
);

CustomSymbol.propTypes = {
    size: PropTypes.number.isRequired,
    borderWidth: PropTypes.number.isRequired,
    borderColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    highlight: PropTypes.bool.isRequired
};

export default CustomSymbol;
