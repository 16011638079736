import React from "react";
import PropTypes from "prop-types";

import { useLocation } from "react-router-dom";
import { withTheme } from "styled-components";
import { Image } from "rebass";

// ----------------------------------------------------------------------------

import routes from "constants/routes";
import DropDownMenu from "./DropDownMenu";

import { isRouteStatic } from "utilities/leftNav";
import { Link, MediaQuery } from "@sisuwellness/web-components";
import { HeaderContainer, NavButton, MobileLogo, LanguageContainer } from "./styled";
import { PORTAL_IMAGE } from "constants/imagePath";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "components/LanguageDropdown";

// ----------------------------------------------------------------------------

const AuthenticatedHeader = ({ visible, setVisible, handleTranslate }) => {
    const { t } = useTranslation();
    const currentRoute = useLocation().pathname.toLowerCase();

    const toggleMenu = () => {
        return setVisible(!visible);
    };

    const isHra = currentRoute.includes("health-risk-assessment")

    return (
        <>
            <MediaQuery devices={["mobile", "tablet"]}>
                <HeaderContainer>
                    <Image
                        mx="20px"
                        size="28px"
                        src={`${PORTAL_IMAGE}/hamburger.svg`}
                        alt="Ham Menu"
                        onClick={handleTranslate}
                    />
                    <MobileLogo ml="calc(50vw - 80px)">
                        <Link to={"/"}>
                            <img data-testid="mobile-header-logo" src={`${PORTAL_IMAGE}/logo-mobile.svg`} />
                        </Link>
                    </MobileLogo>
                    {!isHra && (<LanguageContainer>
                        <LanguageDropdown iconOnly openLeft />
                    </LanguageContainer> )}
                </HeaderContainer>
            </MediaQuery>
            <MediaQuery devices={["desktop"]}>
                <HeaderContainer>
                    {!isRouteStatic(currentRoute) && (
                        <NavButton data-testid="menu-toggle-button" onClick={toggleMenu}>
                            <span>{t("words.menu")}</span>
                            <Image
                                css={{ transform: `scale(${visible ? "-1" : "1"})` }}
                                src={`${PORTAL_IMAGE}/authenticatedHeader/arrow_right.svg`}
                            />
                        </NavButton>
                    )}
                    <Link to={routes.healthHub} style={{ alignSelf: "center" }}>
                        <img data-testid="header-logo" src={`${PORTAL_IMAGE}/authenticatedHeader/Logo.svg`} />
                    </Link>
                    <DropDownMenu />
                </HeaderContainer>
            </MediaQuery>
        </>
    );
};

AuthenticatedHeader.propTypes = {
    theme: PropTypes.object.isRequired,
    show: PropTypes.bool,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    handleTranslate: PropTypes.func
};

AuthenticatedHeader.defaultProps = {
    show: true
};

export default withTheme(AuthenticatedHeader);
