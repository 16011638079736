import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n.use(HttpApi)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: "en-GB",
        load: "currentOnly",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: process.env.REACT_APP_I18N_CDN_URL
        },
        returnObjects: true,
        react: {
            useSuspense: false
        }
    });

export default i18n;
