import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Icon from "../Icons/Icon";

const CheckboxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: ${props => props.mt};
    margin-bottom: ${props => props.mb};
`;

const CheckboxBox = styled.div`
    flex: 0 0 32px;
    width: 32px;
    height: 32px;
    background-color: ${props => props.theme.colours.flat.white.hex};
    border-radius: ${props => props.theme.defaults.borderRadius};
    border: 2px solid ${props => props.theme.colours.flat.darkGray.hex};
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        background-color: ${props => props.theme.colours.flat.blue.hex};
        border: 2px solid ${props => props.theme.colours.flat.blue.hex};
    }
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    padding-left: 15px;
    font-size: ${props => props.theme.text.paragraph.sizeRem};
    font-weight: ${props => props.theme.text.paragraph.weight};
    color: ${props => props.theme.colours.flat.darkGray.hex};
    user-select: none;
`;

class Checkbox extends Component {
    static displayName = "Checkbox";

    static propTypes = {
        theme: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
        label: PropTypes.string,
        checked: PropTypes.bool.isRequired,
        onSelected: PropTypes.func.isRequired,
        className: PropTypes.string,
        mt: PropTypes.string,
        mb: PropTypes.string
    };

    static defaultProps = {
        mt: 0,
        mb: 0
    };

    constructor(props) {
        super(props);

        this.state = {
            checked: props.checked || false
        };
    }

    onSelected = () => {
        const { onSelected, checked: checkedProp } = this.props;
        const { checked: checkedState } = this.state;

        let isChecked = checkedProp;
        // Make the component controlled if no checked props is provided
        if (checkedProp == null) {
            isChecked = !checkedState;
            this.setState({
                checked: !checkedState
            });
        }

        if (typeof onSelected == "function") {
            onSelected(isChecked);
        }
    };

    render() {
        const { className, checked: checkedProp, label, name, theme, mt, mb } = this.props;
        const { checked: checkedState } = this.state;
        const isChecked = checkedProp == null ? checkedState : checkedProp;

        return (
            <CheckboxContainer mt={mt} mb={mb} onClick={this.onSelected} className={className}>
                <CheckboxBox className={isChecked ? "active" : ""}>
                    <Icon
                        type={theme.icons.types.checkMark}
                        colour={theme.colours.flat.white.hex}
                        size={theme.icons.sizes.normal}
                        maxSize={"100%"}
                    />
                </CheckboxBox>
                {label ? (
                    <>
                        <CheckboxLabel htmlFor={`checkbox-${name}`}>{label}</CheckboxLabel>
                        <input id={`checkbox-${name}`} name={name} type={"hidden"} value={isChecked} />
                    </>
                ) : null}
            </CheckboxContainer>
        );
    }
}

export default withTheme(Checkbox);
