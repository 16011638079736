import React from "react";

import AutosuggestHighlightMatch from "autosuggest-highlight/match";
import AutosuggestHighlightParse from "autosuggest-highlight/parse";
import throttle from "lodash-es/throttle";
import styled from "styled-components";
import { mediaQueryForCss } from "utilities/mediaQuery";

// ------------------------------------------------------------------------

export const SuggestionItem = styled.div`
    width: 100%;
    text-align: left !important;
    position: relative;
    font-family: ${props => props.theme.fontFamily.openSans};
    .item-main {
        padding-left: 20px;
        font-size: 14px;
        color: ${props => props.theme.colours.blackTints800.hex};
        ${mediaQueryForCss({
            "font-size": ["", "", "12px"]
        })}
    }
    .item-sub {
        padding-left: 20px;
        font-size: 10px;
        font-weight: 600;
        color: ${props => props.theme.colours.blackTints400.hex};
    }
    .item-seperator {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: rgba(102, 102, 102, 0.08);
        bottom: -11px;
    }
`;

// ------------------------------------------------------------------------

const getGoogleSuggestionsSetup = (autoCompleteService, placesService, autoCompleteSessionToken) => {
    const handleGoogleScript = google => {
        if (google.maps.places && autoCompleteService.current == null) {
            autoCompleteService.current = new google.maps.places.AutocompleteService();
            placesService.current = new google.maps.places.PlacesService(document.createElement("div"));
            refreshSessionToken(google);
        }
    };

    const refreshSessionToken = google => {
        if (google) autoCompleteSessionToken.current = new google.maps.places.AutocompleteSessionToken();
    };

    const fetchPredictions = throttle((request, callback) => {
        autoCompleteService.current.getPlacePredictions(request, callback);
    }, 200);

    const fetchDetails = throttle((request, callback) => {
        placesService.current.getDetails(request, callback);
    }, 200);

    const getPostalCode = (placeId, callback) => {
        if (!placeId) return;
        fetchDetails(
            {
                fields: ["address_components"],
                placeId: placeId,
                sessionToken: autoCompleteSessionToken.current
            },
            results => {
                const object = results;
                let postalCode = "";
                object &&
                    object["address_components"] &&
                    object["address_components"].forEach(obj => {
                        if (obj.types && obj.types.length) {
                            const tempArr = obj.types.filter(type => type === "postal_code");
                            if (tempArr.length) {
                                postalCode = obj["long_name"];
                            }
                        }
                    });
                callback(postalCode);
                refreshSessionToken();
            }
        );
    };

    const handleOnSuggestionsFetch = (searchValue, setResultsCallback, country = "AU") => {
        if (!searchValue) return;
        fetchPredictions(
            {
                input: searchValue,
                componentRestrictions: { country: [country] },
                sessionToken: autoCompleteSessionToken.current
            },
            result => setResultsCallback(result ? (result.length ? result : []) : [])
        );
    };

    const handleGetSuggestionValue = suggestion => {
        return suggestion;
    };

    const handleRenderSuggestion = (suggestion, { query }) => {
        const matches = AutosuggestHighlightMatch(suggestion.description, query);
        const parts = AutosuggestHighlightParse(suggestion.description, matches);

        return (
            <SuggestionItem data-testid="suggestion-item">
                <div className="item-main">
                    {parts.map(part => {
                        return (
                            <span
                                key={`${part.text}${Math.random() * 100000}`}
                                style={{ fontWeight: part.highlight ? "700" : "400" }}
                            >
                                {part.text}
                            </span>
                        );
                    })}
                </div>
                <div className="item-seperator"></div>
            </SuggestionItem>
        );
    };

    return {
        getPostalCode,
        handleGoogleScript,
        handleOnSuggestionsFetch,
        handleGetSuggestionValue,
        handleRenderSuggestion
    };
};

export default getGoogleSuggestionsSetup;
