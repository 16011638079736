import React from "react";

import styled from "styled-components";
import { Flex, Image, Text } from "rebass";

//---------------------------------------------------------------------------------------------

import { theme } from "@sisuwellness/web-components";
import { PORTAL_IMAGE } from "constants/imagePath";
import UnderstandingImpact from "components/Templates/Premium/UnderstandingImpact";
import UpgradeSiSUBtn from "components/CommonComponents/Button/UpgradeSiSUBtn";
import { LOCKED_BODY_COMPOSITION } from "constants/constants.scripts";
import FlexWithGap from "components/Flex";
import { useTranslation } from "react-i18next";

//---------------------------------------------------------------------------------------------

const LockedImage = styled(Flex)`
    position: relative;
    align-items: center;
    background-size: cover;
    justify-content: center;
`;

const StyledPicture = styled.picture`
    filter: blur(2px);
    -webkit-filter: blur(2px);
`;

const LockedBodyComposition = props => {
    const { t } = useTranslation();

    return (
        <FlexWithGap fd="column" gap="32px" with="100%" {...props}>
            <FlexWithGap fd={["column", "row"]} gap={["8px", "32px"]} with="100%">
                <LockedImage width={["100%", "50%"]}>
                    <StyledPicture id="picture-element">
                        <source
                            media={`(max-width:${theme.mediaQuerySizes.mobile}px)`}
                            srcSet={`${PORTAL_IMAGE}/premium/bodyComposition/blurred-bcomp-mobile.svg`}
                        />
                        <source
                            media={`(max-width:${theme.mediaQuerySizes.tablet}px)`}
                            srcSet={`${PORTAL_IMAGE}/premium/bodyComposition/blurred-bcomp-tablet.svg`}
                        />
                        <Image
                            width="100%"
                            alt="phone-mockup"
                            src={`${PORTAL_IMAGE}/premium/bodyComposition/blurred-bcomp-desktop.svg`}
                        />
                    </StyledPicture>
                    <UpgradeSiSUBtn width="250px" position="absolute">
                        {t(LOCKED_BODY_COMPOSITION.CTA)}
                    </UpgradeSiSUBtn>
                </LockedImage>
                <Flex
                    flexGrow={1}
                    flexDirection="column"
                    alignItems="center"
                    bg="hpCardBackground"
                    py={["32px", "", "44px"]}
                >
                    <Text fontSize={["22px", "", "34px"]} color="hpPrimaryPurple" mb="32px">
                        {t("words.body_composition")}
                    </Text>
                    <picture>
                        <source
                            media={`(max-width:${theme.mediaQuerySizes.tablet}px)`}
                            srcSet={`${PORTAL_IMAGE}/premium/bodyComposition/locked-bcomp-mobile.svg`}
                        />
                        <Image
                            width="100%"
                            alt="phone-mockup"
                            src={`${PORTAL_IMAGE}/premium/bodyComposition/locked-bcomp-desktop.svg`}
                        />
                    </picture>
                </Flex>
            </FlexWithGap>
            <UnderstandingImpact metricType={LOCKED_BODY_COMPOSITION.UNDERSTANDING_IMPACT} />
        </FlexWithGap>
    );
};

LockedBodyComposition.propTypes = {};

export default LockedBodyComposition;
