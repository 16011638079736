/* eslint-disable camelcase */
import React, { useState } from "react";
import PropTypes from "prop-types";

//------------------------------------------------------------------------------

import APIClient from "utilities/APIClient";
import { MESSAGE_ERROR } from "constants/messages";
import {
    isoToDate,
    timeDifference,
    treatAsUTC,
    addDays,
    modifyResposePromise,
    getErrorMessage,
    moment
} from "utilities/commonUtils";
import { useMessageBanner } from "components/ContextProviders";

import SleepCardUI from "./sleepCardUI";
import SleepReport from "./sleepUtility";
import { useTranslation } from "react-i18next";

//------------------------------------------------------------------------------

const SleepCard = ({ citizen }) => {
    const { t } = useTranslation();
    const { handleMessage } = useMessageBanner();

    const today = moment().toDate();

    const sleepReport = new SleepReport(citizen.sleepReportResults);

    const [loading, setLoading] = useState(false);

    const onFailure = error => handleMessage(MESSAGE_ERROR, t(getErrorMessage(error)));

    const handleSubmit = async () => {
        setLoading(true);

        modifyResposePromise(APIClient.ectosense.generateSleepReport(sleepReport.summary))
            .then(SleepReport.downloadReport)
            .catch(onFailure)
            .finally(() => setLoading(false));
    };

    const dateDifference = timeDifference(treatAsUTC(today), treatAsUTC(sleepReport.latestNightlyReportStartTime));

    const recommendationText =
        parseInt(dateDifference) >= 3
            ? t("members_portal_web.components.templates.metric_cards.sleep.recommendation_text.ready", {
                  submitDate: isoToDate(sleepReport.latestNightlyReportStartTime)
              })
            : t("members_portal_web.components.templates.metric_cards.sleep.recommendation_text.processing", {
                  submitDate: isoToDate(sleepReport.latestNightlyReportStartTime),
                  readyDate: isoToDate(addDays(isoToDate(sleepReport.latestNightlyReportStartTime), 3))
              });

    const isDisabled = !sleepReport.isProcessed || loading;

    return (
        <SleepCardUI
            onClick={handleSubmit}
            isActionDisabled={isDisabled}
            ahiScore={sleepReport.ahiScore}
            processed={sleepReport.isProcessed}
            recommendationText={recommendationText}
        />
    );
};

SleepCard.propTypes = {
    citizen: PropTypes.object.isRequired
};

export default SleepCard;
